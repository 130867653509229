/* eslint-disable react-hooks/exhaustive-deps */
import {
  toggleDropboard,
  toggleAddEvent,
  closeNotifsBoard,
  closeAddEvent,
  getEvents,
} from "../../../redux/eventManagement/eventManagement";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideBarCompetition from "./SiderBarCompetition";
import { logoutUser } from "redux/auth/authSlice";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import SpinnerTiny from "../../../utils/SpinnerTiny";
import { closeForms } from "redux/organizerScheduling/organizerSchedulingSlice";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { PulseLoader } from "react-spinners";
import userManagementNav from "../dummyData/userManagementNav.json";
import { closeDropboard } from "redux/eventManagement/eventManagement";

const LeftSidebar = () => {
  const {
    openDropboard,
    eventsData,
    addEvent,
    editEvent,
    addToFavePending,
    removeFromFavePending,
  } = useSelector((state) => state.eventManagement);
  const { addCategory, addEntry, addGrandAward, addOverallsAward } =
    useSelector((state) => state.organizerScheduling);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname;
  const { event_name } = useParams();

  //to get competition name
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { status, data } = useFirestoreQueryDoc("organizers", user?.uid);

  const { status: accStatus, data: allComps } = useQueryAllDocs("competitions");
  const currentComp = allComps?.filter((x) => x?.compId === user?.uid)[0];

  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const fav = currentComp?.events?.filter((item) => item?.favorite === true);
    setFavorites(fav);
  }, [addToFavePending, removeFromFavePending, accStatus]);

  const [eventSelect, setEventSelect] = useState(false);
  function toggleEventSelect() {
    setEventSelect((prev) => !prev);
  }

  const [openReports, setOpenReports] = useState(false);
  function toggleOpenReports() {
    setOpenReports((prev) => !prev);
  }

  const [openUserManagement, setOpenUserManagement] = useState(false);
  function toggleOpenUserManagement() {
    setOpenUserManagement((prev) => !prev);
  }

  const [confirmLogout, setconfirmLogout] = useState(false);
  function toggleconfirmLogout() {
    setconfirmLogout((prev) => !prev);
  }

  useEffect(() => {
    dispatch(closeAddEvent());
    dispatch(closeForms());
    setEventSelect(false);
  }, [currentPage, dispatch]);

  const accountDetails = allComps?.filter((item) => item?.compId === user?.uid);

  //to check if account exists
  if (accStatus === "success" && accountDetails?.length === 0) {
    dispatch(logoutUser());
  }

  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openStudioSettings, setOpenStudioSettings] = useState(false);
  function toggleStudioSettings() {
    setOpenStudioSettings((prev) => !prev);
  }

  const [openDancerSettings, setOpenDancerSettings] = useState(false);
  function toggleDancerSettings() {
    setOpenDancerSettings((prev) => !prev);
  }

  const [openJudgeSettings, setOpenJudgeSettings] = useState(false);
  function toggleJudgeSettings() {
    setOpenJudgeSettings((prev) => !prev);
  }

  const [openAccountingSettings, setOpenAccountingSettings] = useState(false);
  function toggleAccountingSettings() {
    setOpenAccountingSettings((prev) => !prev);
  }

  return (
    <>
      {/* medium to large screens UI */}
      {/* medium to large screens UI */}
      {/* medium to large screens UI */}

      <div className="sm:w-[205px] lg:w-[250px] h-screen pl-3 pr-1 lg:px-6 py-7 bg-[#1c1c1c] sm:text-[.8rem] lg:text-[.85rem] fixed left-0 top-0 border-r-2 border-[#282929] z-[999] sm:flex flex-col hidden overflow-y-auto">
        {/* logo */}
        <div
          onClick={() => navigate("/organizer/event-selection")}
          className="flex items-center gap-3 cursor-pointer"
        >
          <img alt="user" src="/images/ByeWind.png" className="w-6 h-6" />
          {status === "success" ? (
            <h1 className="text-white">{data?.competitionName}</h1>
          ) : status === "loading" ? (
            <PulseLoader color="#94a4fd" size={5} />
          ) : null}
        </div>

        {/* nav */}
        {!addEvent &&
          !editEvent &&
          !addEntry &&
          !addCategory &&
          !addGrandAward &&
          !addGrandAward &&
          !addOverallsAward && (
            <>
              <nav className="mt-6">
                {/* favorites/recently */}
                <div>
                  <div className="flex gap-4 ">
                    <p className="text-white/40 cursor-pointer">Favorites</p>
                    <p className="text-white/20">Recently</p>
                  </div>
                </div>

                {/* favorites list */}
                <div className="mt-3">
                  {favorites?.length > 0 ? (
                    favorites?.map((item, index) => {
                      return (
                        <div
                          item={item}
                          key={index}
                          onClick={() => {
                            dispatch(closeNotifsBoard());
                            navigate(
                              `/organizer/event/${item?.event_name?.replace(
                                / /g,
                                "-"
                              )}`
                            );
                          }}
                          className="flex gap-2 items-center text-white/80 mb-2 cursor-pointer hover:bg-[#282929] p-1 rounded-lg transition-all duration-300"
                        >
                          <div className="w-2 h-2 bg-white/20 rounded-full"></div>
                          <p>{item?.event_name}</p>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-white/50 flex gap-3 items-center">
                      <div className="w-2 h-2 bg-white/20 rounded-full"></div>
                      <p>No favorites</p>
                    </div>
                  )}
                </div>
              </nav>

              {/* Dashboards */}
              <nav className="mt-6">
                <div>
                  <div className="">
                    <p className="text-white/40">Dashboards</p>
                  </div>
                </div>

                {/* Dashboards list */}
                <div className="mt-3">
                  <div
                    onClick={toggleEventSelect}
                    className="flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                  >
                    <img
                      alt="arrow"
                      src="/images/ArrowDown.png"
                      className={`w-5 h-5 ${
                        eventSelect && "rotate-[180deg]"
                      } transition-all duration-300`}
                    />
                    <div className="flex gap-2 items-center">
                      <img
                        alt="building"
                        src="/images/Buildings.png"
                        className="w-5 h-5"
                      />

                      <p>Event Selection</p>
                    </div>
                  </div>

                  {eventSelect && (
                    <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                      <div
                        onClick={() => navigate("/organizer/event-selection")}
                        className="text-white/80 hover:bg-white/20 bg-[#282929]/70 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                      >
                        <p className="">View all</p>
                      </div>
                      {eventsData &&
                        eventsData?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() =>
                                navigate(
                                  `/organizer/event/${item?.event_name?.replace(
                                    / /g,
                                    "-"
                                  )}`
                                )
                              }
                              className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                            >
                              <p className="">{item?.event_name}</p>
                            </div>
                          );
                        })}
                      <div
                        onClick={() => dispatch(toggleAddEvent())}
                        className="flex gap-2 items-center text-white/80 hover:bg-white/20 bg-[#282929]/70 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                      >
                        <img
                          alt="building"
                          src="/images/icons8-add-30.png"
                          className="w-5 h-5"
                        />

                        <p className="">Add Event</p>
                      </div>
                    </div>
                  )}

                  {currentPage?.includes("event/") && (
                    <>
                      <div
                        onClick={() => {
                          navigate(
                            `/organizer/event/${event_name?.replace(/ /g, "-")}`
                          );
                        }}
                        className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-8 rounded-lg transition-all duration-300 cursor-pointer relative ${
                          currentPage ===
                            `/organizer/event/${event_name?.replace(
                              / /g,
                              "-"
                            )}` && "bg-[#282929]"
                        }`}
                      >
                        {currentPage ===
                          `/organizer/event/${event_name?.replace(
                            / /g,
                            "-"
                          )}` && (
                          <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                        )}
                        <div className="flex gap-2 items-center">
                          <img
                            alt="building"
                            src="/images/ChartPieSlice.png"
                            className="w-5 h-5"
                          />

                          <p>Organizer</p>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          navigate(
                            `/organizer/event/${event_name?.replace(
                              / /g,
                              "-"
                            )}/tabulator`
                          );
                        }}
                        className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-8 rounded-lg transition-all duration-300 cursor-pointer relative ${
                          currentPage ===
                            `/organizer/event/${event_name?.replace(
                              / /g,
                              "-"
                            )}/tabulator` && "bg-[#282929]"
                        }`}
                      >
                        {currentPage ===
                          `/organizer/event/${event_name?.replace(
                            / /g,
                            "-"
                          )}/tabulator` && (
                          <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                        )}
                        <div className="flex gap-2 items-center">
                          <img
                            alt="building"
                            src="/images/PokerChip.png"
                            className="w-5 h-5"
                          />

                          <p>Tabulator</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </nav>

              {/* Competition */}
              {currentPage?.includes("event/") && (
                <SideBarCompetition
                  eventSelect={eventSelect}
                  openReports={openReports}
                  toggleOpenReports={toggleOpenReports}
                  openUserManagement={openUserManagement}
                  toggleOpenUserManagement={toggleOpenUserManagement}
                />
              )}

              {/* global */}
              {
                <nav className="mt-6">
                  {/* Pages */}
                  <div>
                    <div className="">
                      <p className="text-white/40">Global Settings</p>
                    </div>
                  </div>

                  {/* Pages list */}
                  <div className="mt-3 text-[.85rem]">
                    <div
                      onClick={() => {
                        toggleOpenUserManagement();
                      }}
                      className={`flex gap-2 mt-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
                        currentPage?.includes("/user-management") &&
                        "bg-[#282929]"
                      }`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openUserManagement && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="card"
                          src="/images/IdentificationBadge.png"
                          className="w-5 h-5"
                        />

                        <p>User Management</p>
                      </div>
                    </div>
                    {(openUserManagement ||
                      currentPage?.includes("user-management") ||
                      currentPage?.includes("studio-owners") ||
                      currentPage?.includes("dancers") ||
                      currentPage?.includes("judges") ||
                      currentPage?.includes("all-emcees") ||
                      currentPage?.includes("all-stage-managers")) && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        {userManagementNav?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                navigate(
                                  `/organizer/event/${event_name?.replace(
                                    / /g,
                                    "-"
                                  )}/${item?.link}`
                                );
                                dispatch(closeDropboard());
                              }}
                              className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                            >
                              <p className="">{item?.title}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    <div
                      onClick={() => {
                        toggleStudioSettings();
                      }}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openStudioSettings && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Studio Owner Settings</p>
                      </div>
                    </div>
                    {(openStudioSettings ||
                      currentPage?.includes("score-visibility-global") ||
                      currentPage?.includes("div-visibility-global")) && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/score-visibility-global"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Score Visibility</p>
                        </div>
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/div-visibility-global"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Division Rank Visibility</p>
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() => {
                        toggleDancerSettings();
                      }}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openDancerSettings && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Dancer Settings</p>
                      </div>
                    </div>
                    {(openDancerSettings ||
                      currentPage?.includes("global-top-scorers")) && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/global-top-scorers"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Top Scores by Genre Visibility</p>
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() => {
                        toggleJudgeSettings();
                      }}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openJudgeSettings && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Judge Settings</p>
                      </div>
                    </div>
                    {(openJudgeSettings ||
                      currentPage?.includes("default-scoring-values") ||
                      currentPage?.includes("studio-code-global")) && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/default-scoring-values"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Default Scoring Values </p>
                        </div>
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/studio-code-global"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Studio Code Visibility</p>
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() => navigate("/organizer/global-settings")}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Competition Settings</p>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        toggleAccountingSettings();
                      }}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openAccountingSettings && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Accounting Settings</p>
                      </div>
                    </div>
                    {(openAccountingSettings ||
                      currentPage?.includes("accounting-invoice-settings")) && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        <div
                          onClick={() => {
                            dispatch(closeDropboard());
                            navigate(
                              "/organizer/global-settings/accounting-invoice-settings"
                            );
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Invoice and Receipt Details</p>
                        </div>
                      </div>
                    )}
                  </div>
                </nav>
              }
            </>
          )}
        <div className="w-full h-[300px] mb-auto"></div>

        <div
          onClick={toggleconfirmLogout}
          className={`w-full flex gap-2 items-center text-white/80 bg-[#282929] hover:bg-red-500/60 px-6 py-1 mb-2 mt-20 rounded-lg transition-all duration-300 cursor-pointer relative`}
        >
          <div className="flex gap-2 items-center font-medium">
            <img
              alt="building"
              src="/images/icons8-logout-48.png"
              className="w-5 h-5"
            />
            <p>Sign Out</p>
          </div>
        </div>

        {/* logo */}
        <img
          alt="logo"
          src="/images/Logo.png"
          className="w-[130px] h-auto mt-10"
        />
      </div>

      {/* mobile and small screens UI */}
      {/* mobile and small screens UI */}
      {/* mobile and small screens UI */}
      {openDropboard && (
        <div className="w-[70%] h-screen pl-6 pr-4 py-5 bg-[#1c1c1c] text-[.85rem] fixed left-0 top-0 border-r-2 border-[#282929] z-[999] flex flex-col sm:hidden slideR overflow-y-auto">
          {/* logo  */}
          <div className="mb-5 flex justify-between">
            <div
              onClick={() => dispatch(toggleDropboard())}
              className="w-6 h-6 flex justify-center items-center bg-white/80 rounded-full cursor-pointer"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-4 h-4"
              />
            </div>
            <img alt="user" src="/images/Sun.png" className="w-6 h-6" />
          </div>
          <div
            onClick={() => {
              dispatch(toggleDropboard());
              navigate("/organizer/event-selection");
            }}
            className="flex item-center gap-3"
          >
            <img alt="user" src="/images/ByeWind.png" className="w-6 h-6" />

            {status === "success" ? (
              <h1 className="text-white text-[.9rem] mr-auto">
                {data?.competitionName}
              </h1>
            ) : status === "loading" ? (
              <SpinnerTiny />
            ) : null}
          </div>

          {/* nav  */}
          {!addEvent && !editEvent && (
            <>
              <nav className="mt-8">
                {/* favorites/recently  */}
                <div>
                  <div className="flex gap-4 ">
                    <p className="text-white/40 cursor-pointer">Favorites</p>
                    <p className="text-white/20">Recently</p>
                  </div>
                </div>

                {/* favorites list  */}
                <div className="mt-3">
                  {favorites?.length > 0 ? (
                    favorites?.map((item, index) => {
                      return (
                        <div
                          item={item}
                          key={index}
                          onClick={() => {
                            dispatch(toggleDropboard());
                            navigate(
                              `/organizer/event/${item?.event_name?.replace(
                                / /g,
                                "-"
                              )}`
                            );
                          }}
                          className="flex gap-2 items-center text-white/80 mb-2 cursor-pointer hover:bg-[#282929] p-1 rounded-lg transition-all duration-300"
                        >
                          <div className="w-2 h-2 bg-white/20 rounded-full"></div>
                          <p>{item?.event_name}</p>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-white/50 flex gap-3 items-center">
                      <div className="w-2 h-2 bg-white/20 rounded-full"></div>
                      <p>No favorites</p>
                    </div>
                  )}
                </div>
              </nav>

              {/* Dashboards  */}
              <nav className="mt-6">
                <div>
                  <div className="">
                    <p className="text-white/40">Dashboards</p>
                  </div>
                </div>

                {/* Dashboards list  */}
                <div className="mt-3 text-white/80">
                  <div
                    onClick={toggleEventSelect}
                    className="flex gap-2 items-center mb-2"
                  >
                    <img
                      alt="arrow"
                      src="/images/ArrowDown.png"
                      className={`w-5 h-5 ${
                        eventSelect && "rotate-[180deg]"
                      } transition-all duration-300`}
                    />
                    <div className="flex gap-2 items-center cursor-pointer">
                      <img
                        alt="building"
                        src="/images/Buildings.png"
                        className="w-5 h-5"
                      />

                      <p>Event Selection</p>
                    </div>
                  </div>
                  {eventSelect && (
                    <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                      <div
                        onClick={() => {
                          dispatch(toggleDropboard());
                          navigate("/organizer/event-selection");
                        }}
                        className="text-white/80 hover:bg-white/20 bg-[#282929]/70 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                      >
                        <p className="">View all</p>
                      </div>
                      {eventsData &&
                        eventsData?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                navigate(
                                  `/organizer/event/${item?.event_name?.replace(
                                    / /g,
                                    "-"
                                  )}`
                                );
                                dispatch(toggleDropboard());
                              }}
                              className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                            >
                              <p className="">{item?.event_name}</p>
                            </div>
                          );
                        })}
                      <div className="flex gap-2 items-center text-white/80 hover:bg-white/20 bg-[#282929]/70 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer">
                        <img
                          alt="building"
                          src="/images/icons8-add-30.png"
                          className="w-5 h-5"
                        />

                        <p
                          onClick={() => {
                            dispatch(toggleAddEvent());
                            dispatch(toggleDropboard());
                            toggleEventSelect();
                          }}
                          className=""
                        >
                          Add Event
                        </p>
                      </div>
                    </div>
                  )}

                  {currentPage?.includes("event/") && (
                    <>
                      <div
                        onClick={() => {
                          dispatch(toggleDropboard());
                          navigate(
                            `/organizer/event/${event_name?.replace(/ /g, "-")}`
                          );
                        }}
                        className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-8 rounded-lg transition-all duration-300 cursor-pointer relative ${
                          currentPage ===
                            `/organizer/event/${event_name?.replace(
                              / /g,
                              "-"
                            )}` && "bg-[#282929]"
                        }`}
                      >
                        {currentPage ===
                          `/organizer/event/${event_name?.replace(
                            / /g,
                            "-"
                          )}` && (
                          <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                        )}
                        <div className="flex gap-2 items-center">
                          <img
                            alt="building"
                            src="/images/ChartPieSlice.png"
                            className="w-5 h-5"
                          />

                          <p>Organizer</p>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          dispatch(toggleDropboard());
                          navigate(
                            `/organizer/event/${event_name?.replace(
                              / /g,
                              "-"
                            )}/tabulator`
                          );
                        }}
                        className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-8 rounded-lg transition-all duration-300 cursor-pointer relative ${
                          currentPage ===
                            `/organizer/event/${event_name?.replace(
                              / /g,
                              "-"
                            )}/tabulator` && "bg-[#282929]"
                        }`}
                      >
                        {currentPage ===
                          `/organizer/event/${event_name?.replace(
                            / /g,
                            "-"
                          )}/tabulator` && (
                          <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                        )}
                        <div className="flex gap-2 items-center">
                          <img
                            alt="building"
                            src="/images/PokerChip.png"
                            className="w-5 h-5"
                          />

                          <p>Tabulator</p>
                        </div>
                      </div>
                      {/* <div
                        onClick={() => {
                          dispatch(toggleDropboard());
                          navigate(
                            `/organizer/event/${event_name?.replace(
                              / /g,
                              "-"
                            )}/award`
                          );
                        }}
                        className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-8 rounded-lg transition-all duration-300 cursor-pointer relative ${
                          currentPage ===
                            `/organizer/event/${event_name?.replace(
                              / /g,
                              "-"
                            )}/award` && "bg-[#282929]"
                        }`}
                      >
                        {currentPage ===
                          `/organizer/event/${event_name?.replace(
                            / /g,
                            "-"
                          )}/award` && (
                          <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                        )}
                        <div className="flex gap-2 items-center">
                          <img
                            alt="building"
                            src="/images/trophy.png"
                            className="w-5 h-5 text-white/80 bg-slate-300 rounded-full"
                          />

                          <p>Award</p>
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              </nav>

              {/* Competition */}
              {currentPage?.includes("event/") && (
                <SideBarCompetition
                  eventSelect={eventSelect}
                  openReports={openReports}
                  toggleOpenReports={toggleOpenReports}
                  openUserManagement={openUserManagement}
                  toggleOpenUserManagement={toggleOpenUserManagement}
                />
              )}

              {/* global */}
              {!currentPage?.includes("event/") && (
                <nav className="mt-6">
                  {/* Pages */}
                  <div>
                    <div className="">
                      <p className="text-white/40">Global Settings</p>
                    </div>
                  </div>

                  {/* Pages list */}
                  <div className="mt-3 text-[.85rem]">
                    <div
                      onClick={() => {
                        toggleOpenUserManagement();
                      }}
                      className={`flex gap-2 mt-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
                        currentPage?.includes("/user-management") &&
                        "bg-[#282929]"
                      }`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openUserManagement && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="card"
                          src="/images/IdentificationBadge.png"
                          className="w-5 h-5"
                        />

                        <p>User Management</p>
                      </div>
                    </div>
                    {(openUserManagement ||
                      currentPage?.includes("user-management") ||
                      currentPage?.includes("studio-owners") ||
                      currentPage?.includes("dancers") ||
                      currentPage?.includes("judges") ||
                      currentPage?.includes("all-emcees") ||
                      currentPage?.includes("all-stage-managers")) && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        {userManagementNav?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                navigate(
                                  `/organizer/event/${event_name?.replace(
                                    / /g,
                                    "-"
                                  )}/${item?.link}`
                                );
                                dispatch(closeDropboard());
                              }}
                              className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                            >
                              <p className="">{item?.title}</p>
                            </div>
                          );
                        })}
                      </div>
                    )}

                    <div
                      onClick={() => {
                        toggleStudioSettings();
                      }}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openStudioSettings && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Studio Owner Settings</p>
                      </div>
                    </div>
                    {openStudioSettings && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/score-visibility-global"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Score Visibility</p>
                        </div>
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/div-visibility-global"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Division Rank Visibility</p>
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() => {
                        toggleDancerSettings();
                      }}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openDancerSettings && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Dancer Settings</p>
                      </div>
                    </div>
                    {openDancerSettings && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/global-top-scorers"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Top Scores by Genre Visibility</p>
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() => {
                        toggleJudgeSettings();
                      }}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openJudgeSettings && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Judge Settings</p>
                      </div>
                    </div>
                    {openJudgeSettings && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/default-scoring-values"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Default Scoring Values </p>
                        </div>
                        <div
                          onClick={() => {
                            navigate(
                              "/organizer/global-settings/studio-code-global"
                            );
                            dispatch(closeDropboard());
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Studio Code Visibility</p>
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() => navigate("/organizer/global-settings")}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Competition Settings</p>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        toggleAccountingSettings();
                      }}
                      className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-5 h-5 ${
                          openAccountingSettings && "rotate-[180deg]"
                        } transition-all duration-300`}
                      />
                      <div className="flex gap-2 items-center">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Accounting Settings</p>
                      </div>
                    </div>
                    {openAccountingSettings && (
                      <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
                        <div
                          onClick={() => {
                            dispatch(closeDropboard());
                            navigate(
                              "/organizer/global-settings/accounting-invoice-settings"
                            );
                          }}
                          className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                        >
                          <p className="">Invoice and Receipt Details</p>
                        </div>
                      </div>
                    )}
                  </div>
                </nav>
              )}
            </>
          )}
          <div className="w-full h-[200px] mb-[200px]"></div>

          <div
            onClick={() => {
              dispatch(toggleDropboard());
              toggleconfirmLogout();
            }}
            _
            className={`w-full flex gap-2 items-center text-white/80 bg-[#282929] hover:bg-red-500 px-6 py-1 mb-4 rounded-lg transition-all duration-300 cursor-pointer relative`}
          >
            <div className="flex gap-2 items-center font-medium">
              <img
                alt="building"
                src="/images/icons8-logout-48.png"
                className="w-5 h-5"
              />
              <p>Sign Out</p>
            </div>
          </div>

          {/* logo  */}
          <img
            alt="logo"
            src="/images/Logo.png"
            className="w-[130px] h-auto mt-auto"
          />
        </div>
      )}
      {openDropboard && (
        <div
          onClick={() => dispatch(toggleDropboard())}
          className="w-full h-screen block sm:hidden fixed left-0 top-0 bg-black/60 z-[200] appear"
        ></div>
      )}

      {confirmLogout && (
        <div className="w-screen h-screen fixed top-0 left-0 z-[999] px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg">
            <p className="w-full text-[1rem] text-white text-center pb-3 border-b border-red-400">
              Confirm Sign Out?
            </p>

            <div className="mt-4">
              <button
                onClick={toggleconfirmLogout}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => dispatch(logoutUser())}
                className="text-[.9rem] px-8 py-2 rounded-md bg-red-400 hover:bg-red-400/60 text-white transition-all duration-300"
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeftSidebar;
