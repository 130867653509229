export default function arrangeEntriesByCategories(categories, entries) {
  const categoryNamesSet = new Set(
    categories?.map((category) => category?.category_name)
  );
  const arrangedEntries = [];

  categories?.forEach((category) => {
    const categoryName = category?.category_name;

    const entriesWithCategory = entries?.filter(
      (entry) => entry?.category_name === categoryName
    );

    // Check if entriesWithCategory is defined before using it
    if (entriesWithCategory) {
      entriesWithCategory.sort((a, b) => a?.sort_id - b?.sort_id);
      arrangedEntries.push(...entriesWithCategory);
      categoryNamesSet.delete(categoryName);
    }
  });

  // If there are any uncategorized entries, add them to the end of the arrangedEntries array
  const uncategorizedEntries =
    entries?.filter((entry) => !categoryNamesSet?.has(entry?.category_name)) ||
    [];
  arrangedEntries?.push(...uncategorizedEntries);

  return arrangedEntries;
}
