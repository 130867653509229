import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import { unScratchCategory } from "redux/routineScheduling/routineSchedulingSlice";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const CategoryDropdownCont = ({
  showMoveCategory,
  toggleMoveCate,
  showMoveCategoryBefore,
  toggleMoveCateBefore,
  sortedCategories,
  selectedCategory,
  category,
  scratchCategory,
  currentEvent,
  openDeleteMod,
  user,
  rearrangeCategories,
  loading,
  handleSplitCategory,
  splitCategoryPending,
  combineCategoryPending,
  handleCombineCategory,
  rearrangeCategoriesBefore,
}) => {
  const dispatch = useDispatch();

  const categoryWithouCurrent = sortedCategories?.filter(
    (itm) => itm?.id !== selectedCategory?.id
  );

  return (
    <>
      {showMoveCategory &&
        (loading ? (
          <div className="w-full h-[200px] flex justify-center items-center gap-6 mb-auto text-white/80">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        ) : (
          <ul className="h-[170px] flex flex-col overflow-y-auto overflow-x-hidden">
            <li className="w-full flex gap-2 items-center cursor-pointer">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleMoveCate();
                }}
                className="w-fit rounded-md bg-white/10 hover:bg-white/20 py-1 pl-2 pr-5 mb-2 text-white/60 text-[.75rem] cursor-pointer flex items-center"
              >
                <img alt="" className="w-5 h-5" src="/images/leftArrow.png" />
                Cancel
              </button>{" "}
              <p className="font-bold text-white/50">To after:</p>
            </li>

            {categoryWithouCurrent?.map((itm, idx, arr) => {
              const sessionsBeforeCurrent = arr
                ?.slice(0, idx)
                ?.filter((x) => x?.break_type === "session");
              return (
                <li
                  key={idx}
                  onClick={(e) => {
                    e.stopPropagation();
                    rearrangeCategories(selectedCategory?.id, itm?.id);
                  }}
                  className={`px-1 py-[3px] hover:bg-[#94a4fd]/30 text-[.7em] font-medium cursor-pointer border-b border-white/20`}
                >
                  {capitalizeFirstLetter(itm?.category_name)}{" "}
                  {itm?.break_type === "session" &&
                    sessionsBeforeCurrent?.length + 1}
                  {itm?.split && !itm?.category_name?.includes("(") && (
                    <span className="">(A)</span>
                  )}
                </li>
              );
            })}
            {categoryWithouCurrent?.length === 0 && (
              <div className="w-full h-full border border-white/10 rounded-lg flex justify-center items-center text-white/50 p-1">
                No available categories for repositioning after the current one.
              </div>
            )}
          </ul>
        ))}

      {showMoveCategoryBefore &&
        (loading ? (
          <div className="w-full h-[200px] flex justify-center items-center gap-6 mb-auto text-white/80">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        ) : (
          <ul className="h-[170px] flex flex-col overflow-y-auto overflow-x-hidden">
            <li className="w-full flex gap-2 items-center cursor-pointer">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleMoveCateBefore();
                }}
                className="w-fit rounded-md bg-white/10 hover:bg-white/20 py-1 pl-2 pr-5 mb-2 text-white/60 text-[.75rem] cursor-pointer flex items-center"
              >
                <img alt="" className="w-5 h-5" src="/images/leftArrow.png" />
                Cancel
              </button>{" "}
              <p className="font-bold text-white/50">To before:</p>
            </li>

            {categoryWithouCurrent?.map((itm, idx, arr) => {
              const sessionsBeforeCurrent = arr
                ?.slice(0, idx)
                ?.filter((x) => x?.break_type === "session");
              return (
                <li
                  key={idx}
                  onClick={(e) => {
                    e.stopPropagation();
                    rearrangeCategoriesBefore(selectedCategory?.id, itm?.id);
                  }}
                  className={`px-1 py-[3px] hover:bg-[#94a4fd]/30 text-[.7em] font-medium cursor-pointer border-b border-white/20`}
                >
                  {capitalizeFirstLetter(itm?.category_name)}{" "}
                  {itm?.break_type === "session" &&
                    sessionsBeforeCurrent?.length + 1}
                  {itm?.split && !itm?.category_name?.includes("(") && (
                    <span className="">(A)</span>
                  )}
                </li>
              );
            })}
            {categoryWithouCurrent?.length === 0 && (
              <div className="w-full h-full border border-white/10 rounded-lg flex justify-center items-center text-white/50 p-1">
                No available categories for repositioning after the current one.
              </div>
            )}
          </ul>
        ))}

      {!showMoveCategory && !showMoveCategoryBefore && (
        <ul className="flex flex-col gap-1">
          <li
            onClick={(e) => {
              e.stopPropagation();
              toggleMoveCateBefore();
            }}
            className="w-full rounded-md bg-white/10 hover:bg-white/20 py-1 px-2 text-white/50 text-[.85rem] cursor-pointer flex gap-2 items-center justify-between"
          >
            <p>
              Move {category?.type === "category" && "category"} to BEFORE...
            </p>
            <img
              alt=""
              src="/images/icons8-up-white.png"
              className="w-4 h-auto"
            />
          </li>
          <li
            onClick={(e) => {
              e.stopPropagation();
              toggleMoveCate();
            }}
            className="w-full rounded-md bg-white/10 hover:bg-white/20 py-1 px-2 text-white/50 text-[.85rem] cursor-pointer flex gap-2 items-center justify-between mt-2"
          >
            <p>
              Move {category?.type === "category" && "category"} to AFTER...
            </p>
            <img
              alt=""
              src="/images/icons8-down-white.png"
              className="w-4 h-auto"
            />
          </li>

          {category?.type === "category" && (
            <>
              {!category?.is_scratched && (
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!splitCategoryPending && !combineCategoryPending) {
                      category?.split
                        ? handleCombineCategory()
                        : handleSplitCategory();
                    }
                  }}
                  className="flex gap-2 items-center cursor-pointer py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
                >
                  <img alt="" src="/images/splitCat.png" className="w-3 h-3" />{" "}
                  {splitCategoryPending || combineCategoryPending ? (
                    <PulseLoader color="#94a4fd" size={10} />
                  ) : category?.split ? (
                    <p>Combine Category</p>
                  ) : (
                    <p>Split Category</p>
                  )}
                </li>
              )}
              {!category?.is_scratched && (
                <li
                  onClick={() =>
                    dispatch(
                      scratchCategory({
                        compId: user?.uid,
                        no_id: category?.no_id,
                        currentEvent,
                      })
                    )
                  }
                  className="flex gap-2 items-center cursor-pointer py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
                >
                  <img
                    alt=""
                    src="/images/scratchCat.png"
                    className="w-3 h-3"
                  />{" "}
                  Scratch Category
                </li>
              )}
              {category?.is_scratched && (
                <li
                  onClick={() =>
                    dispatch(
                      unScratchCategory({
                        compId: user?.uid,
                        no_id: category?.no_id,
                        currentEvent,
                      })
                    )
                  }
                  className="flex gap-2 items-center cursor-pointer py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
                >
                  <img
                    alt=""
                    src="/images/scratchCat.png"
                    className="w-3 h-3"
                  />{" "}
                  Unscratch Category
                </li>
              )}
              <li
                onClick={openDeleteMod}
                className="flex gap-2 items-center cursor-pointer py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
              >
                <img alt="" src="/images/deleteCat.png" className="w-3 h-3" />{" "}
                Delete Category
              </li>
            </>
          )}
        </ul>
      )}
    </>
  );
};

export default CategoryDropdownCont;
