/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PrintSchedulePage from "./PrintSchedulePage";
import ScrollToTop from "./ScrollToTop";
import { useFetchScheduleDocs } from "components/hooks/useFetchScheduleDocs";
import { removeDuplicateObjectsByProperty } from "utils/removeDuplicatesByProperty";

const PrintScheduleCont = () => {
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem("event_name") || "";
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  //to get realtime events from firestore
  const { data } = useQueryAllDocs("competitions");
  const currentComp = data?.filter((item) => item?.compId === compId)[0];
  const currentEvent = currentComp?.events?.filter(
    (item) => item.event_name?.replace(/ /g, "-") === event_name
  )[0];

  //to get all entries
  const { data: allEntriesRaw } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  const myEntriesStudio = allEntriesRaw?.filter(
    (entry) =>
      entry?.studio_selected[0]?.toLowerCase() ===
      studioData?.studioName?.toLowerCase()
  );

  //to get all categories
  const { status: entryStatus, data: eventCategories } = useFetchScheduleDocs(
    "competitions",
    compId,
    "categories",
    "event_uid",
    currentEvent?.event_uid
  );

  const category_nameArr = myEntriesStudio?.map((x) => x?.category_name);

  const categoriesRaw = eventCategories
    ?.filter((y) => category_nameArr?.includes(y?.category_name))
    ?.concat(eventCategories?.filter((z) => z?.type !== "category"));

  let sortedCategoriesRaw = categoriesRaw?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  const [sortedCategories, setSortedCategories] = useState([]);

  // console.log("sortedCategories", sortedCategories);

  useEffect(() => {
    if (entryStatus === "success") {
      let arr = removeDuplicateObjectsByProperty(
        sortedCategoriesRaw,
        "category_name"
      );
      setSortedCategories(arr);
    }
  }, [entryStatus, eventCategories]);

  const [showNotes, setShowNotes] = useState("");

  //to get all entries unique to this dance studio
  const { data: studioData } = useFirestoreQueryDoc("studio_owners", user?.uid);
  return (
    <>
      {/* the invoice page overlay */}
      <section className="w-full h-screen fixed top-0 left-0">
        <PrintSchedulePage
          currentComp={currentComp}
          studioData={studioData}
          currentEvent={currentEvent}
          showNotes={showNotes}
          setShowNotes={setShowNotes}
          sortedCategories={sortedCategories}
          allEntries={myEntriesStudio}
        />
      </section>

      <ScrollToTop />
    </>
  );
};

export default PrintScheduleCont;
