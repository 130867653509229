/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import { getEvents } from "../../redux/eventManagement/eventManagement";
import { useDispatch } from "react-redux";
import ScrollToTop from "./ScrollToTop";
import { useEffect, useState } from "react";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { PulseLoader } from "react-spinners";
import AddEventContainer from "./components/AddEventContainer";
import TopScorersSettingsModal from "./components/TopScorersSettingsModal";

const TopScorersByGenreSettings = () => {
  const {
    addEvent,
    deleteEventSuccess,
    editEventSuccess,
    addToFaveSuccess,
    removeFromFaveSuccess,
    openNotifsBoard,
    updateNowPending,
    currentEvent,
  } = useSelector((state) => state.eventManagement);

  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteEventSuccess,
    editEventSuccess,
    addToFaveSuccess,
    removeFromFaveSuccess,
  ]);

  //=====================================================================================to get realtime events from firestore
  const { status, data } = useQueryAllDocs("competitions");
  const organizerComp = data?.filter((item) => item?.compId === user?.uid)[0];
  const realtimeEvents = organizerComp?.events?.filter(
    (x) => x?.event_uid === currentEvent?.event_uid
  );
  const [selectEvent, setSelectEvent] = useState(null);
  const selectedEvent = realtimeEvents?.filter(
    (x) => x?.event_uid === selectEvent?.event_uid
  )[0];
  function toggleSelect(item) {
    setSelectEvent((prev) => (prev ? null : item));
  }

  const [checkboxData, setcheckboxData] = useState({});

  // console.log("selectedEvent", selectedEvent);

  useEffect(() => {
    if (organizerComp) {
      selectEvent?.genre_visibility
        ? setcheckboxData(selectEvent?.genre_visibility)
        : setcheckboxData(organizerComp?.danceGenres);
    }
  }, [organizerComp, selectEvent]);

  const checkboxes = Object.keys(checkboxData);
  // const allfalse = Object.values(checkboxData)?.filter(
  //   (x) => x?.toString() === "true"
  // );

  const handleCheckboxChange = (event, item) => {
    const { id, checked } = event.target;

    setcheckboxData((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  //=========================================================to handle select all and select none
  function selectAll() {
    setAllPropertiesToTrue(checkboxData);
  }
  function selectNone() {
    setAllPropertiesToFalse(checkboxData);
  }

  function setAllPropertiesToTrue(inputObject) {
    const resultObject = {};
    for (const key in inputObject) {
      if (inputObject?.hasOwnProperty(key)) {
        resultObject[key] = true;
      }
    }
    setcheckboxData(resultObject);
  }
  function setAllPropertiesToFalse(inputObject) {
    const resultObject = {};
    for (const key in inputObject) {
      if (inputObject?.hasOwnProperty(key)) {
        resultObject[key] = false;
      }
    }
    setcheckboxData(resultObject);
  }

  //======================================================to handle update now
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function getCurrentFormattedTime() {
    const currentDate = new Date();
    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const amOrPm = hours >= 12 ? "pm" : "am";

    // Convert 24-hour time to 12-hour time
    const formattedHours = hours % 12 || 12;
    const formattedTime = `${month} ${day}, ${year} ${formattedHours}:${String(
      minutes
    ).padStart(2, "0")}${amOrPm}`;

    return formattedTime;
  }

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}

            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[200px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Top Scores by Genre Visibility
              </h1>

              <div className="w-full flex flex-col gap-3">
                <div className="w-full flex justify-between items-center px-2">
                  <p>Event</p>
                  <p>Action</p>
                </div>

                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {realtimeEvents?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="w-full flex justify-between items-center bg-white/10 p-3 rounded-lg text-[.85rem]"
                    >
                      <p>{item?.event_name}</p>
                      <button
                        onClick={() => toggleSelect(item)}
                        // disabled={updateJudgeSettingsPending}
                        className={`px-4 rounded-full text-[.85rem] hover:opacity-75 bg-[#94a4fd] text-black`}
                      >
                        Make Changes
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {selectedEvent && (
        <TopScorersSettingsModal
          toggleSelect={toggleSelect}
          selectedEvent={selectedEvent}
          user={user}
          getCurrentFormattedTime={getCurrentFormattedTime}
          updateNowPending={updateNowPending}
          selectAll={selectAll}
          selectNone={selectNone}
          checkboxes={checkboxes}
          checkboxData={checkboxData}
          handleCheckboxChange={handleCheckboxChange}
          setSelectEvent={setSelectEvent}
        />
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default TopScorersByGenreSettings;
