/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import AddEventContainer from "./components/AddEventContainer";
import AddGenreForm from "./components/AddGenreForm";
import AwardUpdate from "./components/AwardUpdate";
import AddClassificationForm from "./components/AddClassificationForm";
import AddSpecialTag from "./components/AddSpecialTag";
import AddSpecialEntry from "./components/AddSpecialEntry";
import AddEntry from "./components/AddEntry";
import UpdatePrice from "./components/UpdatePrice";
import AddDivision from "./components/AddDivision";

const GlobalSettings = () => {
  const { addEvent, openNotifsBoard } = useSelector(
    (state) => state.eventManagement
  );
  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { status, data: organizerData } = useFirestoreQueryDoc(
    "competitions",
    user?.uid
  );

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <h2 className="mb-6 text-white/80 text-[1.25rem] font-medium">
              Competition Settings (Global)
            </h2>

            <div className="w-full flex flex-col gap-6">
              <AddGenreForm
                organizerData={organizerData}
                user={user}
                status={status}
              />
              <AwardUpdate
                organizerData={organizerData}
                user={user}
                status={status}
              />
              <AddClassificationForm
                organizerData={organizerData}
                user={user}
                status={status}
              />
              <AddDivision
                organizerData={organizerData}
                user={user}
                status={status}
              />
              <AddSpecialTag
                organizerData={organizerData}
                user={user}
                status={status}
              />
              <UpdatePrice
                organizerData={organizerData}
                user={user}
                status={status}
              />
              <AddEntry
                organizerData={organizerData}
                user={user}
                status={status}
              />
              <AddSpecialEntry
                organizerData={organizerData}
                user={user}
                status={status}
              />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default GlobalSettings;
