/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import {
  clearSuccess,
  toggleEditDancers,
} from "redux/danceStudio/danceStudioSlice";
import { useDispatch } from "react-redux";
import DancerTableRow from "./DancerTableRow";
import { deleteDancers } from "redux/danceStudio/danceStudioSlice";
import { PulseLoader } from "react-spinners";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import moment from "moment";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const DancersTable = ({
  allDancers,
  searchQuery,
  searchResults,
  currentEvent,
  status,
  sortDown,
}) => {
  const { deleteDancersSuccess, deleteDancersPending } = useSelector(
    (state) => state.danceStudio
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId, entryTypes, specialEntryTypes } = danceStudioCompData.value;

  console.log(specialEntryTypes);

  const { data: studioData } = useFirestoreQueryDoc("studio_owners", user?.uid);

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  const eventEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === currentEvent?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  // function calculateAge(dateOfBirth) {
  //   if (dateOfBirth) {
  //     const today = new Date();
  //     const birthDate = new Date(dateOfBirth);
  //     let age = today.getFullYear() - birthDate.getFullYear();
  //     const monthDifference = today.getMonth() - birthDate.getMonth();
  //     if (
  //       monthDifference < 0 ||
  //       (monthDifference === 0 && today.getDate() < birthDate.getDate())
  //     ) {
  //       age--;
  //     }
  //     return age;
  //   }
  // }
  function calculateAge(dateOfBirth) {
    // Check if the date string is in "dd-mm-yyyy" or "yyyy-mm-dd" format
    const regex1 = /^(\d{2})-(\d{2})-(\d{4})$/;
    const regex2 = /^(\d{4})-(\d{2})-(\d{2})$/;

    let birthDate;
    if (regex1.test(dateOfBirth)) {
      // If the date string is in "dd-mm-yyyy" format, convert it to a Date object
      const [day, month, year] = dateOfBirth.split("-");
      birthDate = new Date(year, month - 1, day);
    } else if (regex2.test(dateOfBirth)) {
      // If the date string is in "yyyy-mm-dd" format, convert it to a Date object
      const [year, month, day] = dateOfBirth.split("-");
      birthDate = new Date(year, month - 1, day);
    } else {
      // If the date string is not in a recognized format, return null
      return null;
    }

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  function calculateAge2(dateOfBirth, currentYear) {
    // Split the dateOfBirth string into year, month, and day parts
    const dobParts = dateOfBirth.split("-");
    const dobYear = parseInt(dobParts[0], 10);
    const dobMonth = parseInt(dobParts[1], 10);
    const dobDay = parseInt(dobParts[2], 10);

    // Create Date objects for the date of birth and January 1st of the current year
    const birthDate = new Date(dobYear, dobMonth - 1, dobDay);
    const januaryFirst = new Date(currentYear, 0, 1);

    // Calculate the age by subtracting the birthDate from January 1st
    const ageInMilliseconds = januaryFirst - birthDate;
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Taking leap years into account

    return Math.floor(ageInYears);
  }

  function convertDateFormat(dateString) {
    // Check if the date string is in "yyyy-mm-dd" format
    const regex = /^(\d{4})-(\d{2})-(\d{2})$/;
    if (regex.test(dateString)) {
      return dateString; // Return the date as is if it's already in the desired format
    }

    // Try to parse the date in different formats
    const dateFormats = ["M/D/YY", "YYYY-MM-DD", "MM-DD-YYYY"];
    for (const format of dateFormats) {
      const parsedDate = moment(dateString, format, true); // Parse the date with strict parsing
      if (parsedDate.isValid()) {
        return parsedDate.format("YYYY-MM-DD");
      }
    }

    return dateString; // Return the original date string if none of the formats match
  }

  const dispatch = useDispatch();

  let arranged = allDancers && [...allDancers];

  // eslint-disable-next-line array-callback-return
  arranged?.sort((a, b) => {
    const parseCustomDate = (dateString) => {
      // Replace the comma with an empty string to remove it
      const formattedDate = dateString.replace(",", "");

      // Parse the date using the Date constructor
      const date = new Date(formattedDate);

      // Check if the date is valid
      if (!isNaN(date)) {
        return date.getTime();
      } else {
        // Handle cases where the date couldn't be parsed
        return 0;
      }
    };

    const dateA = parseCustomDate(a.last_update);
    const dateB = parseCustomDate(b.last_update);
    // console.log("dateA", dateA);

    if (sortDown === "oldest") {
      return dateA - dateB; // Oldest to newest
    } else if (sortDown === "latest") {
      return dateB - dateA; // Newest to oldest
    } else if (sortDown === "alphabet") {
      const nameA = a.first_name.toUpperCase();
      const nameB = b.first_name.toUpperCase();

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    } else if (sortDown === "gender") {
      const genderA = a.gender.toUpperCase();
      const genderB = b.gender.toUpperCase();

      return genderA < genderB ? -1 : genderA > genderB ? 1 : 0;
    } else if (sortDown === "class") {
      const classA = a.classification.toUpperCase();
      const classB = b.classification.toUpperCase();

      return classA < classB ? -1 : classA > classB ? 1 : 0;
    } else if (sortDown === "age") {
      const ageA = calculateAge(convertDateFormat(a?.date_of_birth));
      const ageB = calculateAge(convertDateFormat(b?.date_of_birth));

      return ageA < ageB ? -1 : ageA > ageB ? 1 : 0;
    }
  });

  const [dancerIndex, setdancerIndex] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  const [confirmDelete, setConfirmDelete] = useState(false);
  function toggleDelete() {
    setConfirmDelete((prev) => !prev);
  }
  function closeDancerMod() {
    setSuccessDelete(false);
    setConfirmDelete(false);
  }

  useEffect(() => {
    if (deleteDancersSuccess) {
      setConfirmDelete(false);
      setSuccessDelete(true);
      const timeoutId = setTimeout(() => {
        setSuccessDelete(false);
        dispatch(clearSuccess());
        // dispatch(getDancers({ uid: user?.uid }));
        // window.location.reload();
      }, 1500);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDancersSuccess]);

  const [deleteId, setDeleteId] = useState(null);

  // const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [showNewRow, setShowNewRow] = useState(false);

  //to handle registrations table pagination
  // eslint-disable-next-line no-unused-vars

  const [dancersDataPag, setdancersDataPag] = useState();
  useEffect(() => {
    setdancersDataPag(arranged);
  }, [status, sortDown, deleteDancersSuccess]);

  const [pageNumber, setPageNumber] = useState(0);

  const rowPerPage = 8;
  const pagesVisited = pageNumber * rowPerPage;

  const displayRows = dancersDataPag
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      return (
        <DancerTableRow
          row={row}
          key={index}
          setdancerIndex={setdancerIndex}
          toggleEditDancers={toggleEditDancers}
          toggleDelete={toggleDelete}
          confirmDelete={confirmDelete}
          calculateAge={calculateAge}
          calculateAge2={calculateAge2}
          dancerIndex={dancerIndex}
          arranged={arranged}
          // compId={compId}
          setDeleteId={setDeleteId}
          currentEvent={currentEvent}
          convertDateFormat={convertDateFormat}
          eventEntries={eventEntries}
          // setHoveredRowIndex={setHoveredRowIndex}
          setShowNewRow={setShowNewRow}
        />
      );
    });

  const displaySearchedRows = searchResults
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      return (
        <DancerTableRow
          row={row}
          key={index}
          setdancerIndex={setdancerIndex}
          toggleEditDancers={toggleEditDancers}
          toggleDelete={toggleDelete}
          confirmDelete={confirmDelete}
          calculateAge={calculateAge}
          calculateAge2={calculateAge2}
          dancerIndex={dancerIndex}
          arranged={arranged}
          // compId={compId}
          setDeleteId={setDeleteId}
          currentEvent={currentEvent}
          convertDateFormat={convertDateFormat}
          eventEntries={eventEntries}
          // setHoveredRowIndex={setHoveredRowIndex}
          setShowNewRow={setShowNewRow}
        />
      );
    });

  const pageCount = Math.ceil(dancersDataPag?.length / rowPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // console.log("event_uid", currentEvent);

  return (
    <>
      {
        <div className="w-full h-full mt-5 pb-10 overflow-auto">
          {!searchQuery && (
            <table className="min-w-[600px] w-full text-[.85rem] table2">
              <thead className="text-white/40 text-left border-b border-white/30">
                <tr className="whitespace-nowrap">
                  <th>Dancer Name</th>
                  <th>Gender</th>
                  <th>Classification</th>
                  {/* <th>Special Tags</th> */}
                  <th>Date of birth</th>
                  <th>Comp. Age</th>
                  <th>Notes</th>
                  <th>Last Update</th>
                  <th>Scheduling Status</th>
                </tr>
              </thead>
              {arranged?.length > 8 ? (
                <tbody className="text-white/80">{displayRows}</tbody>
              ) : (
                <tbody className="transition-all duration-500">
                  {arranged?.map((row, index) => {
                    return (
                      <DancerTableRow
                        row={row}
                        key={index}
                        setdancerIndex={setdancerIndex}
                        toggleEditDancers={toggleEditDancers}
                        toggleDelete={toggleDelete}
                        confirmDelete={confirmDelete}
                        calculateAge={calculateAge}
                        calculateAge2={calculateAge2}
                        dancerIndex={dancerIndex}
                        arranged={arranged}
                        // compId={compId}
                        setDeleteId={setDeleteId}
                        currentEvent={currentEvent}
                        convertDateFormat={convertDateFormat}
                        eventEntries={eventEntries}
                        // setHoveredRowIndex={setHoveredRowIndex}
                        setShowNewRow={setShowNewRow}
                      />
                    );
                  })}
                </tbody>
              )}
            </table>
          )}

          {searchQuery && searchResults && (
            <table className="min-w-[600px] w-full text-[.85rem] table2">
              <thead className="text-white/40 text-left border-b border-white/30">
                <tr className="whitespace-nowrap">
                  <th>Dancer Name</th>
                  <th>Gender</th>
                  <th>Classification</th>
                  <th>Date of birth</th>
                  <th>Comp. Age</th>
                  <th>Number of Entries</th>
                  <th>Last Update</th>
                  <th>Scheduling Status</th>
                </tr>
              </thead>
              {searchResults.length > 8 ? (
                <tbody className="text-white/80">{displaySearchedRows}</tbody>
              ) : (
                <tbody className="transition-all duration-500">
                  {searchResults?.map((row, index) => {
                    return (
                      <DancerTableRow
                        row={row}
                        key={index}
                        setdancerIndex={setdancerIndex}
                        toggleEditDancers={toggleEditDancers}
                        toggleDelete={toggleDelete}
                        confirmDelete={confirmDelete}
                        calculateAge={calculateAge}
                        calculateAge2={calculateAge2}
                        dancerIndex={dancerIndex}
                        arranged={arranged}
                        // compId={compId}
                        setDeleteId={setDeleteId}
                        currentEvent={currentEvent}
                        convertDateFormat={convertDateFormat}
                        eventEntries={eventEntries}
                        // setHoveredRowIndex={setHoveredRowIndex}
                        setShowNewRow={setShowNewRow}
                      />
                    );
                  })}
                </tbody>
              )}
            </table>
          )}
          {searchQuery && searchResults?.length === 0 && (
            <p className="w-full py-8 text-center">No results found</p>
          )}

          {status === "loading" && (
            <div className="bg-[#1c1c1c] w-full min-h-[260px] flex justify-center items-center">
              <PulseLoader color="#94a4fd" size={10} />
            </div>
          )}
          {arranged?.length < 1 && status === "success" && (
            <p className="w-full h-[100px] flex items-center justify-center text-center mt-10 text-[1rem] text-white/50 border border-white/10 rounded-lg">
              Dancers are not yet registered for this competition..{" "}
            </p>
          )}
          {arranged?.length > 0 && !searchQuery && (
            <div className="text-[.75rem] text-white/40 mt-4 lg:">
              Scroll horizontally to view more, if needed
            </div>
          )}
        </div>
      }
      {/* pagination */}
      {(dancersDataPag?.length > 8 || searchResults?.length > 8) && (
        <div className="flex gap-2 mt-4 justify-end">
          <ReactPaginate
            previousLabel={
              <img
                alt="user"
                src="/images/leftArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            nextLabel={
              <img
                alt="user"
                src="/images/rightArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={
              "h-6 flex items-center gap-2 mt-4 justify-end text-[.95rem] pag"
            }
            activeClassName={"rounded-lg bg-white/10"}
          />
        </div>
      )}

      {confirmDelete && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/80 flex justify-center mod">
          <div className="w-full sm:w-[450px] h-fit p-5 bg-[#282929] rounded-lg flex flex-col gap-5 justify-center items-center scale shadow-lg box">
            <div className="w-full text-[.85em] sm:text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 relative">
              <p>
                Confirm Delete Dancer:{" "}
                <span className="font-bold">
                  {capitalizeFirstLetter(deleteId?.first_name)}{" "}
                  {capitalizeFirstLetter(deleteId?.last_name)}
                </span>
              </p>
              <div
                onClick={() => toggleDelete()}
                className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-[-10px] md:top-0 right-[-10px] md:right-3"
              >
                <img
                  alt="user"
                  src="/images/icons8-close-50.png"
                  className="w-3 h-3"
                />
              </div>
            </div>
            <button
              onClick={() => {
                dispatch(
                  deleteDancers({
                    ...deleteId,
                    uid: user?.uid,
                    compId,
                    entryTypes,
                    event_uid: currentEvent?.event_uid,
                    specialEntryTypes: specialEntryTypes?.tags,
                  })
                )?.then(() => setConfirmDelete(false));
              }}
              className="w-full px-2 py-3 rounded-lg bg-red-500 hover:bg-red-500/60 text-white"
              disabled={deleteDancersPending}
            >
              {deleteDancersPending ? "Deleting..." : `Delete`}
            </button>
          </div>
        </div>
      )}

      {successDelete && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <div
              onClick={() => {
                closeDancerMod();
              }}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white">
              Successfully deleted dancer!
            </p>
          </div>
        </div>
      )}

      {showNewRow && (
        <div className="w-full h-full fixed top-0 left-0 bg-black/80 flex justify-center py-[100px] px-3 z-[50]">
          <div className="w-full md:w-[500px] h-fit rounded-xl bg-[#282929] px-4 py-5 flex flex-col gap-4 text-white scale relative">
            <div
              onClick={() => {
                setShowNewRow(null);
              }}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <h2 className="font-medium text-[1.2rem] text-center border-b border-white/80 pb-2">
              Dancer:{" "}
              {`${capitalizeFirstLetter(
                showNewRow?.first_name
              )} ${capitalizeFirstLetter(showNewRow?.last_name)}`}
            </h2>
            <div
              onClick={() => {
                dispatch(
                  toggleEditDancers({
                    first_name: showNewRow?.first_name,
                    last_name: showNewRow?.last_name,
                    dancer_uid: showNewRow?.dancer_uid,
                  })
                );
                setShowNewRow(null);
              }}
              className="w-full p-3 text-[#94a4fd] whitespace-nowrap cursor-pointer hover:bg-[#94a4fd]/30 bg-white/10 rounded-lg"
            >
              Edit Dancer
            </div>
            <div
              onClick={() => {
                toggleDelete();
                setDeleteId({
                  first_name: showNewRow?.first_name,
                  last_name: showNewRow?.last_name,
                  dancer_uid: showNewRow?.dancer_uid,
                });
                setShowNewRow(null);
              }}
              className="w-full p-3 text-red-400 whitespace-nowrap cursor-pointer hover:bg-red-500/30 bg-white/10 rounded-lg"
            >
              Delete Dancer
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DancersTable;
