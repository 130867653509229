// Importing necessary tools from Redux and organizer service
import { createAction, createSlice } from '@reduxjs/toolkit';
import { addNewGenre } from 'services/organizerService';

// actions to reset all state
export const resetState = createAction('resetState');

// Initial state for genres, set to all false
const initialGenres = {
  Acro: false,
  Ballet: false,
  'Character Ballet': false,
  Contemporary: false,
  'Hip Hop': false,
  Jazz: false,
  Lyrical: false,
  Pointe: false,
  'Song & Dance': false,
  Modern: false,
  'Musical Theatre': false,
  Open: false,
  Tap: false,
};

// Slice for dance genres
const danceGenresSlice = createSlice({
  name: 'genres',
  initialState: initialGenres,
  reducers: {
    // Reducer to add genre
    addGenre: (state, action) => {
      state[action.payload] = true;
    },
    // Reducer to remove genre
    removeGenre: (state, action) => {
      state[action.payload] = false;
    },
  },
  extraReducers: (builder) => {
    // Reducer to handle fulfillment of addNewGenre action
    builder.addCase(addNewGenre.fulfilled, (state, action) => {
      state[action.payload] = true;
    });
  },
});

// Initial state for divisions
const initialDivisionState = [];

const divisionsSlice = createSlice({
  name: 'divisions',
  initialState: initialDivisionState,
  reducers: {
    addDivision: (state, action) => {
      const divisionName = action.payload.divisionName;
      const isDuplicate = state.some(
        (division) => division.divisionName === divisionName
      );
      if (!isDuplicate) {
        state.push(action.payload);
      }
    },
    removeDivision: (state, action) => {
      return state.filter((_, index) => index !== action.payload);
    },
  },
});

// Initial state for competition Level
const competitionLevelState = [];

// Slice for competition Level

const competitionLevelSlice = createSlice({
  name: 'competitionLevel',
  initialState: competitionLevelState,
  reducers: {
    addLevel: (state, action) => {
      const levelName = action.payload.levelName;
      const isDuplicated = state.some((level) => level.levelName === levelName);

      if (!isDuplicated) {
        state.push(action.payload);
      }
    },
    removeLevel: (state, action) => {
      return state.filter((level, index) => index !== action.payload);
    },
  },
});

// Initial state for entryTypes
const entryTypeState = [];

// Slice for entryTypes

const entryTypeSlice = createSlice({
  name: 'entryType',
  initialState: entryTypeState,
  reducers: {
    addEntryType: (state, action) => {
      const entryTypeName = action.payload.entryTypeName;
      const isDuplicated = state.some(
        (entry) => entry.entryTypeName === entryTypeName
      );

      if (!isDuplicated) {
        state.push(action.payload);
      }
    },
    removeEntryType: (state, action) => {
      return state.filter((type, index) => index !== action.payload);
    },
  },
});

// Initial state for specialEntry
const specialEntryState = {
  tags: [],
};

// Slice for special entryTypes
const specialEntrySlice = createSlice({
  name: 'specialEntryType',
  initialState: specialEntryState,
  reducers: {
    addSpecialEntry: (state, action) => {
      state.tags?.push(action.payload);
    },
    removeSpecialEntry: (state, action) => {
      state.tags?.splice(action.payload, 1); // Remove the entry at the specified index
    },
  },
});

// initial state for judges criteria
const initialJudgeCriteriaState = [];

// Slice for judges criteria
const judgeCriteriaSlice = createSlice({
  name: 'judgeCriteria',
  initialState: initialJudgeCriteriaState,
  reducers: {
    addJudgeCriteria: (state, action) => {
      const {
        judgesCriteria,
        judgesPercent,
        shortCode,
        isTieBreaker,
        requiresQualification,
      } = action.payload;
      let sum = state.reduce(
        (acc, criteria) => acc + parseInt(criteria.judgesPercent),
        0
      );
      if (sum + parseInt(judgesPercent) <= 100) {
        // check if the name is duplicated
        const isJudgesCriteriaDuplicated = state.some(
          (criteria) => criteria.judgesCriteria === judgesCriteria
        );
        const isShortCodeDuplicated = state.some(
          (criteria) => criteria.shortCode === shortCode
        );
        if (!isJudgesCriteriaDuplicated && !isShortCodeDuplicated) {
          state.push({
            judgesCriteria,
            judgesPercent,
            shortCode,
            isTieBreaker,
            requiresQualification,
          });
        }
      }
    },
    removeJudgeCriteria: (state, action) => {
      return state.filter((type, index) => index !== action.payload);
    },
  },
});

// initial state for deduction criteria
const initialDeductionCriteriaState = [];

// Slice for deduction criteria
const deductionCriteriaSlice = createSlice({
  name: 'deductionCriteria',
  initialState: initialDeductionCriteriaState,
  reducers: {
    addDeductionCriteria: (state, action) => {
      const deductionCriteria = action.payload.deductionCriteria;
      const shortCode = action.payload.shortCode;

      const isDeductionCriteriaDuplicated = state.some(
        (criteria) => criteria.deductionCriteria === deductionCriteria
      );
      const isShortCodeDuplicated = state.some(
        (criteria) => criteria.shortCode === shortCode
      );

      if (!isDeductionCriteriaDuplicated && !isShortCodeDuplicated) {
        state.push(action.payload);
      }
    },
    removeDeductionCriteria: (state, action) => {
      return state.filter((type, index) => index !== action.payload);
    },
  },
});

// initial state for award calculation
const initialAward = [];

// Slice for award calculation

const awardSlice = createSlice({
  name: 'awards',
  initialState: initialAward,
  reducers: {
    addAward: (state, action) => {
      const awardName = action.payload.awardName;
      const code = action.payload.code;
      const isDuplicated = state.some((award) => award.awardName === awardName);
      const isCodeDuplicated = state.some((award) => award.code === code);

      if (!isDuplicated && !isCodeDuplicated) {
        state.push(action.payload);
      }
    },
    removeAward: (state, action) => {
      return state.filter((type, index) => index !== action.payload);
    },
  },
});

// initial state for Scheduling
const initialSchedule = {
  splitCategories: '',
  customTags: [],
};

// Slice for Scheduling
const scheduleSlice = createSlice({
  name: 'scheduling',
  initialState: initialSchedule,
  reducers: {
    addScheduleCustomTag: (state, action) => {
      const customTag = action.payload;
      const isDuplicated = state.customTags.some((tag) => tag === customTag);

      if (!isDuplicated) {
        state.customTags.push(action.payload);
      }
    },
    removeScheduleCustomTag: (state, action) => {
      state.customTags = state.customTags.filter(
        (type, index) => index !== action.payload
      );
    },
    updateSplitCategories: (state, action) => {
      state.splitCategories = action.payload;
    },
  },
});

// initial state for Scheduling
const initialPricing = {
  pricingCategories: '',
};

// slice for pricing
const pricingSlice = createSlice({
  name: 'pricing',
  initialState: initialPricing,
  reducers: {
    addPriceSelect: (state, action) => {
      state.pricingCategories = action.payload;
    },
  },
});

// Slice for formCompletedSlice
const formCompletedSlice = createSlice({
  name: 'formCompleted',
  initialState: {
    value: false,
  },
  reducers: {
    setFormCompleted: (state, action) => {
      return { ...state, value: action.payload };
    },
  },
});

//Slice for setting Organizer CustomClaim
const organizerCustomClaimSlice = createSlice({
  name: 'organizerCustomClaim',
  initialState: {
    value: false,
  },
  reducers: {
    setOrganizerCustomClaim: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Exporting actions
export const { addDivision, removeDivision } = divisionsSlice.actions;
export const { addGenre, removeGenre } = danceGenresSlice.actions;
export const { addLevel, removeLevel } = competitionLevelSlice.actions;
export const { addEntryType, removeEntryType } = entryTypeSlice.actions;
export const { addSpecialEntry, removeSpecialEntry } =
  specialEntrySlice.actions;
export const { addJudgeCriteria, removeJudgeCriteria } =
  judgeCriteriaSlice.actions;
export const { addDeductionCriteria, removeDeductionCriteria } =
  deductionCriteriaSlice.actions;
export const { addAward, removeAward } = awardSlice.actions;
export const {
  addScheduleCustomTag,
  removeScheduleCustomTag,
  updateBreakTime,
  updateSplitCategories,
} = scheduleSlice.actions;
export const { addPriceSelect, addRate } = pricingSlice.actions;
export const { setFormCompleted } = formCompletedSlice.actions;
export const { setOrganizerCustomClaim } = organizerCustomClaimSlice.actions;

// Exporting reducers
export const danceGenresReducer = danceGenresSlice.reducer;
export const eligibilityDivisionReducer = divisionsSlice.reducer;
export const competitionLevelReducer = competitionLevelSlice.reducer;
export const entryTypeReducer = entryTypeSlice.reducer;
export const specialEntryReducer = specialEntrySlice.reducer;
export const judgeCriteriaReducer = judgeCriteriaSlice.reducer;
export const deductionCriteriaReducer = deductionCriteriaSlice.reducer;
export const awardReducer = awardSlice.reducer;
export const scheduleReducer = scheduleSlice.reducer;
export const pricingReducer = pricingSlice.reducer;
export const formCompletedReducer = formCompletedSlice.reducer;
export const organizerCustomClaimReducer = organizerCustomClaimSlice.reducer;
