export default function splitArrayAtSession(sortedCategories) {
  if (!Array.isArray(sortedCategories) || sortedCategories.length === 0) {
    return [];
  }

  const result = [];
  let currentArray = [];
  let sessionFound = false;

  for (const item of sortedCategories) {
    if (!sessionFound) {
      if (item?.break_type === "session") {
        sessionFound = true;
      } else {
        continue;
      }
    }

    if (item.break_type === "session") {
      if (currentArray?.length > 0) {
        result.push(currentArray);
      }
      currentArray = [];
    } else {
      currentArray?.push(item);
    }
  }

  if (currentArray?.length > 0) {
    result.push(currentArray);
  }

  return result;
}
