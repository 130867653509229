/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import moment from "moment";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import TabulatorEntry from "./TabulatorEntry";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import sumTimeValues from "utils/sumTimeValues";
import calculateEntryStartTime from "utils/calculateEntryStartTime";

const TabulatorCategory = ({
  category,
  index,
  allEntries,
  sortedCategories,
  currentEvent,
  toggleNotes,
  showNotes,
  calculatePosition,
  calculateTotalScore,
  findMatchingAward,
  awards,
  liveEntry,
  currentEntryDocId,
  selectEntry,
  setShowNames,
  showNames,
  allScores,
}) => {
  const memoizedSelectEntry = useCallback(selectEntry, []);

  const myEntries = allEntries?.filter(
    (entry) => entry?.category_name === category?.category_name
  );

  let myEntriesSorted = myEntries?.sort((a, b) => a?.sort_id - b?.sort_id);

  function removeDuplicates(array) {
    const uniqueArray = [];
    const seenObjects = new Set();

    for (const obj of array) {
      // Serialize the object to JSON to use it as a unique identifier
      const serializedObj = JSON.stringify(obj);

      if (!seenObjects.has(serializedObj)) {
        seenObjects.add(serializedObj);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  }

  //===============================================================================to calc dynamic entry count of all entries in routine
  const categories = sortedCategories?.filter((l) => l?.type === "category");

  const allEntriesWithoutLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  )?.filter((k) => k?.locked_entry === false);

  const allEntriesWithLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  );

  function entryCount(itm) {
    const allEntriesBeforeLocked = allEntriesWithLocked
      ?.slice(
        0,
        findIndexByProperty(allEntriesWithLocked, "doc_id", itm?.doc_id)
      )
      ?.filter((m) => m?.locked_entry === false);

    if (itm?.locked_entry) {
      return `${
        allEntriesBeforeLocked?.length === 0
          ? 1
          : allEntriesBeforeLocked?.length
      }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
    } else {
      return (
        findIndexByProperty(allEntriesWithoutLocked, "doc_id", itm?.doc_id) + 1
      );
    }
  }

  //============================================================================================new entry time calculation

  // to get all categories before current category
  const categoriesBeforeCurrent = sortedCategories?.slice(0, index) || [];

  const allBufferBeforeCurrent = categoriesBeforeCurrent?.filter(
    (cate) => cate?.type === "buffer"
  );
  const timeToAdd = allBufferBeforeCurrent?.reduce((sum, obj) => {
    if (obj.type === "buffer") {
      const bufferValue = Number(obj.buffer);
      if (!isNaN(bufferValue)) {
        return sum + bufferValue;
      }
    }
    return sum;
  }, 0);

  function addMinutesToTime(inputTime, minutesToAdd) {
    const timeMoment = moment(inputTime, "h:mm A");
    const endTime = timeMoment.clone().add(minutesToAdd, "minutes");
    const formattedEndTime = endTime.format("h:mm A");
    return formattedEndTime;
  }

  const startTime = currentEvent?.days[0]?.startTime;
  const initialTime = moment(startTime).utc().format("h:mm A");

  //to get the entry count of all entries in routine
  function entryTime(itm) {
    const idx = findIndexByProperty(
      removeDuplicates(allEntriesWithoutLocked),
      "doc_id",
      itm?.doc_id
    );
    if (idx === 0) {
      return addMinutesToTime(initialTime, timeToAdd);
    } else {
      const allEntriesBeforeCurrent = removeDuplicates(
        allEntriesWithoutLocked
      )?.slice(
        0,
        findIndexByProperty(
          removeDuplicates(allEntriesWithoutLocked),
          "doc_id",
          itm?.doc_id
        )
      );

      const allTimeBeforeCurrent = sumTimeValues(allEntriesBeforeCurrent);
      const startTime = calculateEntryStartTime(
        initialTime,
        allTimeBeforeCurrent,
        itm
      );

      return addMinutesToTime(startTime, timeToAdd);
    }
  }

  const sessionsBeforeCurrent = categoriesBeforeCurrent?.filter(
    (item) => item?.break_type === "session"
  );

  return (
    <div
      onClick={() => setShowNames(null)}
      className={`relative ${
        (category?.type === "buffer" || category?.break_type === "break") &&
        "hidden"
      }`}
    >
      {category?.is_scratched && (
        <div className="w-full h-full bg-stone-700/40 absolute top-0 left-0"></div>
      )}
      <div
        className={`w-full min-w-[900px] p-3 lg:text-center font-bold cursor-pointer relative border-y border-gray-500/10 flex items-center ${
          category?.type === "buffer"
            ? "bg-[#4f2d54]"
            : category?.break_type === "session"
            ? "bg-[#42125f]"
            : category?.break_type?.includes("overall") ||
              category?.break_type?.includes("award")
            ? "bg-black/20"
            : "bg-[#282929]"
        } ${
          category?.break_type?.includes("overall") ||
          category?.break_type?.includes("award")
            ? "uppercase"
            : ""
        }  ${
          myEntriesSorted?.length === 0 &&
          category?.type === "category" &&
          "hidden"
        }`}
      >
        <h2
          className={`${
            category?.is_scratched ? "text-white/50" : "text-white"
          } flex gap-3 items-center lg:justify-center lg:mx-auto`}
        >
          {category?.is_scratched && (
            <img alt="" src="/images/scratchCat.png" className="w-5 h-3" />
          )}
          {`${capitalizeFirstLetter(category?.category_name)}`}
          {category?.split && !category?.category_name?.includes("(") && (
            <span className="">(A)</span>
          )}
          {category?.type === "category" && (
            <span className="">({myEntriesSorted?.length})</span>
          )}
          {/* to dynamically render overalls or grand award tags */}
          {/*========================================================================= to dynamically render overalls or grand award tags */}
          {category?.break_type?.includes("overalls_") && (
            <span className="">
              {category?.break_type?.split("_")[1] === "overall"
                ? "OVERALLS"
                : category?.break_type?.split("_")[1] === "grand"
                ? "GRAND OVERALLS"
                : "CUSTOM REPORT"}
            </span>
          )}
          {/* to dynamically render sessions count */}
          {category?.break_type === "session" && (
            <span className="">
              {" "}
              {sessionsBeforeCurrent?.length === 0
                ? 1
                : sessionsBeforeCurrent?.length + 1}
            </span>
          )}
        </h2>
      </div>
      {/* entries container */}
      <div className="w-full">
        {myEntriesSorted?.map((item, idx) => {
          const notesWithoutChoreoAndSpecial = item?.notes?.filter(
            (note) => note?.type !== "Studio Director Note"
          );
          return (
            <TabulatorEntry
              key={idx}
              idx={idx}
              item={item}
              entryTime={entryTime}
              entryCount={entryCount}
              category={category}
              toggleNotes={toggleNotes}
              showNotes={showNotes}
              calculatePosition={calculatePosition}
              calculateTotalScore={calculateTotalScore}
              myEntries={myEntries}
              findMatchingAward={findMatchingAward}
              awards={awards}
              liveEntry={liveEntry}
              currentEntryDocId={currentEntryDocId}
              selectEntry={memoizedSelectEntry}
              setShowNames={setShowNames}
              showNames={showNames}
              myEntriesSorted={myEntriesSorted}
              allScores={allScores}
              notesWithoutChoreoAndSpecial={notesWithoutChoreoAndSpecial}
              currentEvent={currentEvent}
              // calculateTiedPosition={calculateTiedPosition}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TabulatorCategory;
