import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { registerJudge } from 'services/authenticationService';
import {
  addNoteService,
  deleteNoteService,
  editNoteService,
  presentScoreService,
  submitScoreService,
  unpresentScoreService,
} from 'services/judgeService';

const initialState = {
  competitions: [],
  currentComp: null,
  currentEvent: null,
  entriesList: [],

  regJudgeSuccess: null,
  regJudgeFail: null,
  regJudgePending: false,

  submitScoreSuccess: null,
  submitScoreFail: null,
  submitScorePending: false,

  presentScoreSuccess: null,
  presentScoreFail: null,
  presentScorePending: false,

  unpresentScoreSuccess: null,
  unpresentScoreFail: null,
  unpresentScorePending: false,

  addNoteSuccess: null,
  addNoteFail: null,
  addNotePending: false,

  deleteNoteSuccess: null,
  deleteNoteFail: null,
  deleteNotePending: false,

  editNoteSuccess: null,
  editNoteFail: null,
  editNotePending: false,
};

//===============================to handle get all entries async func
export const regJudge = createAsyncThunk(
  'judgeSlice/regJudge',
  async (data) => {
    const result = await registerJudge(data);
    console.log(result);
    return result;
  }
);

export const submitScore = createAsyncThunk(
  'judgeSlice/submitScore',
  async (data) => {
    const result = await submitScoreService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle present async func
export const presentScore = createAsyncThunk(
  'judgeSlice/presentScore',
  async (data) => {
    const result = await presentScoreService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle unpresent async func
export const unpresentScore = createAsyncThunk(
  'judgeSlice/unpresentScore',
  async (data) => {
    const result = await unpresentScoreService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add note async func
export const addNote = createAsyncThunk('judgeSlice/addNote', async (data) => {
  const result = await addNoteService(data);
  console.log(result);
  return result;
});

//===============================to handle delete note async func
export const deleteNote = createAsyncThunk(
  'judgeSlice/deleteNote',
  async (data) => {
    const result = await deleteNoteService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit note async func
export const editNote = createAsyncThunk(
  'judgeSlice/editNote',
  async (data) => {
    const result = await editNoteService(data);
    console.log(result);
    return result;
  }
);

export const judgeSlice = createSlice({
  name: 'judge',
  initialState,
  reducers: {
    saveJudgeData: (state, action) => {
      state.regData.push(action.payload);
    },
    clearSuccess: (state, action) => {
      state.regJudgeSuccess = null;
      state.submitScoreSuccess = null;
      state.addNoteSuccess = null;
      state.deleteNoteSuccess = null;
      state.editNoteSuccess = null;
    },
    setCompetitions: (state, action) => {
      state.competitions = action.payload;
    },
    setCurrentComp: (state, action) => {
      state.currentComp = action.payload;
    },
    setCurrentEvent: (state, action) => {
      state.currentEvent = action.payload;
    },
    setEntriesList: (state, action) => {
      state.entriesList = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      //cases for getting dancers
      .addCase(regJudge.pending, (state) => {
        state.regJudgePending = true;
      })
      .addCase(regJudge.fulfilled, (state, action) => {
        state.regJudgePending = false;
        state.regJudgeSuccess = action.payload;
      })
      .addCase(regJudge.rejected, (state, action) => {
        state.regJudgePending = false;
      })
      .addCase(submitScore.pending, (state) => {
        state.submitScorePending = true;
      })
      .addCase(submitScore.fulfilled, (state, action) => {
        state.submitScorePending = false;
        state.submitScoreSuccess = action.payload;
      })
      .addCase(submitScore.rejected, (state, action) => {
        state.submitScorePending = false;
      })
      //cases for scratch entry
      .addCase(presentScore.pending, (state) => {
        state.presentScorePending = true;
      })
      .addCase(presentScore.fulfilled, (state, action) => {
        state.presentScoreSuccess = action.payload;
        state.presentScorePending = false;
      })
      .addCase(presentScore.rejected, (state, action) => {
        state.performanceReady = action.payload;
        state.presentScorePending = false;
      })
      //cases for unscratch entry
      .addCase(unpresentScore.pending, (state) => {
        state.unpresentScorePending = true;
      })
      .addCase(unpresentScore.fulfilled, (state, action) => {
        state.unpresentScoreSuccess = action.payload;
        state.unpresentScorePending = false;
      })
      .addCase(unpresentScore.rejected, (state, action) => {
        state.unpresentScoreFail = action.payload;
        state.unpresentScorePending = false;
      })
      //cases for add note
      .addCase(addNote.pending, (state) => {
        state.addNotePending = true;
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.addNoteSuccess = action.payload;
        state.addNotePending = false;
      })
      .addCase(addNote.rejected, (state, action) => {
        state.addNoteFail = action.payload;
        state.addNotePending = false;
      })
      //cases for delete note
      .addCase(deleteNote.pending, (state) => {
        state.deleteNotePending = true;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.deleteNoteSuccess = action.payload;
        state.deleteNotePending = false;
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.deleteNoteFail = action.payload;
        state.deleteNotePending = false;
      })
      //cases for delete note
      .addCase(editNote.pending, (state) => {
        state.editNotePending = true;
      })
      .addCase(editNote.fulfilled, (state, action) => {
        state.editNoteSuccess = action.payload;
        state.editNotePending = false;
      })
      .addCase(editNote.rejected, (state, action) => {
        state.editNoteFail = action.payload;
        state.editNotePending = false;
      });
  },
});

export const {
  saveJudgeData,
  clearSuccess,
  setCompetitions,
  setCurrentComp,
  setCurrentEvent,
  setEntriesList,
} = judgeSlice.actions;

export default judgeSlice.reducer;
