import { useSelector } from 'react-redux';
import Footer from './components/Footer';
import Header from './components/Header';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import ScrollToTop from './ScrollToTop';
import { useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { useQueryAllDocs } from 'components/hooks/useQueryAllDocs';
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import { useQueryAllSubCollectionDocs } from 'components/hooks/useQueryAllSubCollectionDocs';
import MusicList from './components/MusicList';

const Musics = () => {
  const { addEvent, openNotifsBoard } = useSelector(
    (state) => state.danceStudio
  );

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem('event_name') || '';
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  //to get realtime events from firestore
  const { data } = useQueryAllDocs('competitions');
  const currentComp = data?.filter((item) => item?.compId === compId)[0];
  const currentEvent = currentComp?.events?.filter(
    (item) => item.event_name?.replace(/ /g, '-') === event_name
  )[0];

  //to get all entries unique to this dance studio
  const { data: studioData } = useFirestoreQueryDoc('studio_owners', user?.uid);

  const { data: allEntries } = useQueryAllSubCollectionDocs(
    'competitions',
    compId,
    'entries'
  );

  const myEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === currentEvent?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? 'lg:pr-[310px]' : 'lg:pr-[32px]'
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex flex-wrap gap-4 justify-between items-center text-white/90 text-[1rem] mb-6">
              <div>
                <h2>
                  {capitalizeFirstLetter(currentEvent?.event_name)}{' '}
                  {currentEvent?.year.split(' to ')[0]}
                </h2>
              </div>
            </div>

            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[300px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
              <MusicList uniqueEntries={myEntries} compId={compId} />
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default Musics;
