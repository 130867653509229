/* eslint-disable react-hooks/exhaustive-deps */
import {
  runAroundService,
  revertRunAroundService,
  dancerStartService,
  revertDancerStartService,
  isCheckedPropsService,
  revertIsCheckedPropsService,
  checkInService,
  revertCheckInService,
  setCurrentSessionIndex,
} from "redux/emcee/emceeSlice";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import localStorage from "redux-persist/es/storage";
import { db } from "services/firebase";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import Header from "../emcee/components/Header";
import {
  collection,
  query,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
  where,
  getDocs,
} from "firebase/firestore";
import StageManagerCate from "./components/StageManagerCate";
import { PulseLoader } from "react-spinners";
import Spinner from "utils/Spinner";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ScrollToTop } from ".";
import EmceeEditEntry from "components/emcee/components/EmceeEditEntry";
import { useSelector } from "react-redux";
import { removeDuplicateObjectsByProperty } from "utils/removeDuplicatesByProperty";
import updateSpecialEntries from "utils/updateSpecialEntries";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";

// Custom droppable component to work around React StrictMode
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const StageManager = () => {
  const { status, data } = useQueryAllDocs("competitions");
  // const loading = status === "loading";

  const { currentSessionIndex } = useSelector((state) => state.emcee);

  const { data: allOrganizers } = useQueryAllDocs("organizers");

  const dispatch = useDispatch();

  const { competitionName, event_name } = useParams();
  const currentComp = data?.filter(
    (item) => item?.competitionName === competitionName
  )[0];

  const currentOrg = allOrganizers?.filter(
    (item) => item?.competitionName === competitionName
  )[0];

  const currentEvent = currentComp?.events?.filter(
    (item) => item?.event_name?.trim() === event_name
  )[0];

  const compId = currentComp
    ? currentComp?.compId
    : localStorage.getItem("compId");

  const [entryTypes, setEntryTypes] = useState([]);
  const [sortedCategories, setSortedCategories] = useState([]);
  const [allEntries, setAllEntries] = useState([]);
  const [eventCategories, seteventCategories] = useState([]);

  const [allOveralls, setAllOveralls] = useState([]);
  const [loading, setloading] = useState(false);

  const { data: allCategories } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "categories"
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);
        // Fetch competition data using compId
        const compDocRef = doc(db, "competitions", compId);
        const compDocSnapshot = await getDoc(compDocRef);

        if (compDocSnapshot.exists()) {
          const compData = compDocSnapshot.data();
          const { entryTypes } = compData;
          setEntryTypes(entryTypes);
        } else {
          console.log("Competition document not found.");
        }
        // Fetch and listen for real-time updates to categories
        const categoriesQuery = query(
          collection(db, "competitions", compId, "categories"),
          where("event_uid", "==", currentEvent?.event_uid) // Filter by event_uid
        );

        const unsubscribeCategories = onSnapshot(
          categoriesQuery,
          (snapshot) => {
            const categoriesData = snapshot.docs.map((doc) => doc.data());

            seteventCategories(categoriesData);

            const sortedCategories = categoriesData.sort(
              (a, b) => a?.sort_id - b?.sort_id
            );
            setSortedCategories(sortedCategories);
          }
        );

        // Fetch and listen for real-time updates to entries
        const entriesQuery = query(
          collection(db, "competitions", compId, "entries"),
          where("event_uid", "==", currentEvent?.event_uid) // Filter by event_uid
        );
        const unsubscribeEntries = onSnapshot(entriesQuery, (snapshot) => {
          const entriesData = snapshot.docs.map((doc) => doc.data());

          setAllEntries(entriesData);
        });

        // Fetch and listen for real-time updates to overalls
        const overallsQuery = query(
          collection(db, "competitions", compId, "reports")
        );
        const unsubscribeOveralls = onSnapshot(overallsQuery, (snapshot) => {
          const overallData = snapshot.docs.map((doc) => doc.data());
          setAllOveralls(overallData);
        });

        return () => {
          unsubscribeCategories();
          unsubscribeEntries();
          unsubscribeOveralls();
        };
      } catch (error) {
        // console.error("Error fetching data:", error);
      } finally {
        setloading(false);
      }
    };

    fetchData();
  }, [compId, currentEvent]);

  const category_nameArr = allEntries?.map((x) => x?.category_name);

  const categoriesRaw = eventCategories
    ?.filter((y) => category_nameArr?.includes(y?.category_name))
    ?.concat(eventCategories?.filter((z) => z?.type !== "category"));

  let sortedCategoriesRaw = categoriesRaw?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  useEffect(() => {
    if (!loading) {
      let arr = removeDuplicateObjectsByProperty(
        sortedCategoriesRaw,
        "category_name"
      );
      setSortedCategories(arr);
    }
  }, [status, eventCategories, allEntries]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Fetch competition data using compId
  //       const compDocRef = doc(db, "competitions", compId);
  //       const compDocSnapshot = await getDoc(compDocRef);

  //       if (compDocSnapshot.exists()) {
  //         const compData = compDocSnapshot.data();
  //         const { entryTypes } = compData;
  //         setEntryTypes(entryTypes);
  //       } else {
  //         console.log("Competition document not found.");
  //       }
  //       // Fetch and listen for real-time updates to categories
  //       const categoriesQuery = query(
  //         collection(db, "competitions", compId, "categories")
  //       );

  //       const unsubscribeCategories = onSnapshot(
  //         categoriesQuery,
  //         (snapshot) => {
  //           const categoriesData = snapshot.docs.map((doc) => doc.data());
  //           //  filter categories by event_uid
  //           const eventCate = categoriesData.filter(
  //             (cat) => cat?.event_uid === currentEvent?.event_uid
  //           );
  //           seteventCategories(eventCate);

  //           const sortedCategories = eventCate.sort(
  //             (a, b) => a?.sort_id - b?.sort_id
  //           );
  //           setSortedCategories(sortedCategories);
  //         }
  //       );

  //       // Fetch and listen for real-time updates to entries
  //       const entriesQuery = query(
  //         collection(db, "competitions", compId, "entries")
  //       );
  //       const unsubscribeEntries = onSnapshot(entriesQuery, (snapshot) => {
  //         const entriesData = snapshot.docs.map((doc) => doc.data());
  //         const filtered = entriesData?.filter(
  //           (x) => x?.event_uid === currentEvent?.event_uid
  //         );
  //         setAllEntries(filtered);
  //       });

  //       // Fetch and listen for real-time updates to overalls
  //       const overallsQuery = query(
  //         collection(db, "competitions", compId, "reports")
  //       );
  //       const unsubscribeOveralls = onSnapshot(overallsQuery, (snapshot) => {
  //         const overallData = snapshot.docs.map((doc) => doc.data());
  //         setAllOveralls(overallData);
  //       });

  //       return () => {
  //         unsubscribeCategories();
  //         unsubscribeEntries();
  //         unsubscribeOveralls();
  //       };
  //     } catch (error) {
  //       // console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [compId, currentEvent]);

  //=============================================================to refresh category names

  //to assign sort id
  const assignSortId = () => {
    const maxSortId = Math.max(
      ...sortedCategories?.map((obj) => obj.sort_id || 0),
      0
    );
    const newSortId = maxSortId + 1;

    return newSortId;
  };

  // console.log("allEntries", allEntries);
  const [isInitialMount, setIsInitialMount] = useState(true);

  useEffect(() => {
    if (isInitialMount && currentEvent && status === "success") {
      updateSpecialEntries(
        allEntries,
        eventCategories,
        compId,
        allCategories,
        assignSortId,
        currentEvent
      );
      setIsInitialMount(false);
    }
  }, [isInitialMount, currentEvent, status]);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  // =========================== to handle session ===========================
  // const [currentSessionIndex, setCurrentSessionIndex] = useState(0);
  const essen = sortedCategories?.filter((it) => it?.break_type === "session");

  const id1 = essen?.filter((_, ind) => ind === currentSessionIndex)[0]?.id;
  const id2 = essen?.filter((_, ind) => ind === currentSessionIndex + 1)[0]?.id;

  function currentSessionCategories() {
    const result = [];
    let isBetweenIds = false;

    for (const category of sortedCategories) {
      if (category.id === id1) {
        isBetweenIds = true;
      }

      if (isBetweenIds) {
        result.push(category);
      }

      if (id2 !== null && id2 !== undefined && category.id === id2) {
        break;
      }
    }

    return result;
  }

  function removeLastSessionBreak(arr) {
    if (arr.length === 0) {
      return arr;
    }

    const lastItem = arr[arr.length - 1];

    if (lastItem.break_type === "session") {
      arr.pop();
    }

    return arr;
  }

  const showPreviousSession = () => {
    if (currentSessionIndex > 0) {
      dispatch(setCurrentSessionIndex(currentSessionIndex - 1));
    }
  };

  const showNextSession = () => {
    if (currentSessionIndex < essen.length - 1) {
      dispatch(setCurrentSessionIndex(currentSessionIndex + 1));
    }
  };

  // Function to handle the check in of an entry
  const handleCheckIn = (item) => {
    if (!item?.checkedIn) {
      dispatch(
        checkInService({
          compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    } else {
      dispatch(
        revertCheckInService({
          compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    }
  };
  // Function to handle the run around of an entry
  const handleRunAround = (item) => {
    if (!item?.isRunAround) {
      dispatch(
        runAroundService({
          compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    } else {
      dispatch(
        revertRunAroundService({
          compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    }
  };
  // Function to handle the run around of an entry
  const handleDancerStart = (item) => {
    if (!item?.isDancerStarts) {
      dispatch(
        dancerStartService({
          compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    } else {
      dispatch(
        revertDancerStartService({
          compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    }
  };

  // Function to handle the isCheckProps of an entry
  const handleCheckProps = (item) => {
    if (!item?.isCheckedProps) {
      dispatch(
        isCheckedPropsService({
          compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    } else {
      dispatch(
        revertIsCheckedPropsService({
          compId,
          doc_id: item?.doc_id,
          entry_name: item?.entry_name,
          currentEvent,
        })
      );
    }
  };

  const [selectedEntry, setSelectedEntry] = useState(null);
  const [showDropdownRow, setShowDropdownRow] = useState(null);
  const [loadingEntry, setLoadingEntry] = useState(false);
  const [showMoveEntry, setShowMoveEntry] = useState(false);

  const [consecutiveBuffer, setConsecutiveBuffer] = useState(false);

  const closeDropdown = () => {
    setShowDropdownRow(null);
    setShowMoveEntry(false);
    if (selectedEntry !== null || showDropdownRow !== null) {
      setSelectedEntry(null);
      setShowDropdownRow(null);
    }
  };

  let oldSorted = eventCategories?.sort((a, b) => a?.sort_id - b?.sort_id);

  //===============================================================================================to handle dnd for categories
  const handleDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination || source?.index === destination?.index) {
      return;
    }

    const updatedCategory = Array.from(sortedCategories);
    const draggedRow = updatedCategory[source.index];

    // Remove the dragged category from its original position
    updatedCategory?.splice(source.index, 1);

    // Insert the dragged category at the destination index
    updatedCategory?.splice(destination.index, 0, draggedRow);

    // Update the sort_id for all categories after the dragged category
    const maxSortUd = updatedCategory?.reduce((maxSortId, obj) => {
      return obj.sort_id > maxSortId ? obj.sort_id : maxSortId;
    }, updatedCategory[0]?.sort_id);

    updatedCategory.forEach((category, index) => {
      if (index >= destination.index) {
        category.sort_id = index + 1 + maxSortUd;
      }
    });

    // setSortedCategories(updatedCategory);

    // Check if the draggedRow is of type "buffer"
    if (draggedRow.type === "buffer") {
      const prevItem = updatedCategory[destination.index - 1];
      const nextItem = updatedCategory[destination.index + 1];
      const itemAfterNext = updatedCategory[destination.index + 2];
      const itemBeforePrev = updatedCategory[destination.index - 2];

      if (prevItem?.type === "buffer" || nextItem?.type === "buffer") {
        setConsecutiveBuffer(true);
        setTimeout(() => {
          setConsecutiveBuffer(false);
          window.location.reload();
        }, 1500);
        // Revert the drag event and return the items to their original position
        setSortedCategories(oldSorted);
        return;
      }

      if (nextItem?.type === "break" && itemAfterNext?.type === "buffer") {
        setConsecutiveBuffer(true);
        setTimeout(() => {
          setConsecutiveBuffer(false);
          window.location.reload();
        }, 1500);
        // Revert the drag event and return the items to their original position
        // sortedCategories = Array.from(oldSorted);
        setSortedCategories(oldSorted);
        return;
      }

      if (prevItem?.type === "break" && itemBeforePrev?.type === "buffer") {
        setConsecutiveBuffer(true);
        setTimeout(() => {
          setConsecutiveBuffer(false);
          window.location.reload();
        }, 1500);
        // Revert the drag event and return the items to their original position
        // sortedCategories = Array.from(oldSorted);
        setSortedCategories(oldSorted);

        return;
      }
    }
    setSortedCategories(updatedCategory);

    // Update the categories in firestore
    updatedCategory?.forEach(async (category) => {
      try {
        // Find the Firestore document based on a unique field value
        const collectionRef = collection(
          db,
          "competitions",
          compId,
          "categories"
        );

        const whereQuery =
          category?.type === "category"
            ? where("no_id", "==", category.no_id)
            : where("doc_id", "==", category.doc_id);

        const querySnapshot = query(collectionRef, whereQuery);
        const categoriesSnapshot = await getDocs(querySnapshot);

        if (categoriesSnapshot.docs?.length === 1) {
          // Assuming there is only one matching document, update it
          const docRef = categoriesSnapshot.docs[0].ref;

          await updateDoc(docRef, { sort_id: category.sort_id });
        } else if (categoriesSnapshot.docs?.length > 1) {
          console.error("Error: multiple documents match the unique field.");
          console.log(category?.category_name);
        } else {
          console.error("Error: Document not found");
          console.log(category?.category_name);
        }
      } catch (error) {
        console.error("Error updating category sort_id:", error);
      }
    });
  };

  //=======================================================================to handle enable and disable dnd for categories and entries

  const [lockedSchedule, setLockedSchedule] = useState(false);

  const [confrmDrag, setConfirmDrag] = useState(false);
  const [notifyDrag, setNotifyDrag] = useState(false);

  function toggleConfirmDrag() {
    setConfirmDrag((prev) => !prev);
    // setConfirmDragCat(false);
    setNotifyDrag(true);
    setTimeout(() => {
      setNotifyDrag(false);
    }, 2000);
  }

  function notifyLocked() {
    setLockedSchedule(true);
    setTimeout(() => {
      setLockedSchedule(false);
    }, 2000);
  }

  const [allowDrag, setAllowDrag] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const [entryToEdit, setEntryToEdit] = useState({});
  // console.log("entryToEdit", entryToEdit);

  const handleOpenEdit = (item) => {
    setOpenEdit((prev) => !prev);
    setEntryToEdit(item);
  };

  const handleCloseEdit = () => {
    setOpenEdit((prev) => !prev);
    setEntryToEdit({});
  };

  //=========================================================================to toggle between entries and awards
  const [showAwards, setShowAwards] = useState(false);

  const [allScores, setAllScores] = useState([]);

  const awards = currentOrg?.awards;

  // console.log("awards", awards);

  function findMatchingAward(awards, totalScore) {
    for (let i = 0; i < awards?.length; i++) {
      const award = awards[i];
      if (totalScore >= award.minScore && totalScore <= award.maxScore) {
        return award;
      }
    }
    return null;
  }

  useEffect(() => {
    Promise.resolve(compId).then((id) => {
      const unsubscribe = onSnapshot(
        query(collection(db, "competitions", id, "scores")),
        (snapshot) => {
          const data = snapshot.docs.map((doc) => doc.data());
          setAllScores(data);
        }
      );

      return () => {
        unsubscribe();
      };
    });
  }, [compId, showAwards]);

  useEffect(() => {
    setAllScores(data);
  }, [data, showAwards]);

  const calculateAverageScore = (arr, key) => {
    if (Array.isArray(arr)) {
      const filteredArray = arr?.filter((itm) => itm?.technique);
      const array = key === "technique" ? filteredArray : arr;
      const totalSum = array?.reduce((sum, obj) => sum + Number(obj[key]), 0);
      const average = totalSum / array?.length;
      const averageFormatted = Number(average)?.toFixed(2);

      return averageFormatted;
    }
  };

  function getDeduction(arr) {
    const lastCharacters = arr?.map((str) => {
      if (str?.length > 0) {
        return Number(str?.slice(-1));
      } else {
        return "";
      }
    });

    const ded = lastCharacters?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return ded;
  }

  const calculateTotalScore = (entry) => {
    const scores = allScores?.filter(
      (item) =>
        item?.doc_id === entry?.doc_id &&
        item?.event_name === currentEvent?.event_name?.trim()
    );

    const allDeductions = scores
      ?.flatMap((obj) => obj.deductions)
      ?.filter((item) => item !== "none");

    const sum =
      [
        Number(calculateAverageScore(scores, "technique")),
        Number(calculateAverageScore(scores, "creativity")),
        Number(calculateAverageScore(scores, "staging")),
        Number(calculateAverageScore(scores, "execution")),
        Number(calculateAverageScore(scores, "performance")),
      ]?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) -
      getDeduction(allDeductions);

    const truncatedScore = parseFloat(sum?.toFixed(2));

    return isNaN(truncatedScore) ? "- -" : truncatedScore;
  };

  const calculateHighestScore = (categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    let highestScore = 0;

    filteredEntries?.forEach((entry) => {
      const scores = allScores?.filter(
        (item) =>
          item?.doc_id === entry?.doc_id &&
          item?.event_name === currentEvent?.event_name?.trim()
      );

      const totalScoreRaw = scores?.reduce(
        (sum, score) => sum + Number(score?.total || 0),
        0
      );

      const totalScore = totalScoreRaw / scores?.length;

      if (totalScore > highestScore) {
        highestScore = totalScore;
      }
    });

    return highestScore;
  };

  const calculatePosition = (entry, categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );

    const highestScore = calculateHighestScore(filteredEntries);
    const entryScore = calculateTotalScore(entry);
    // Sort the scores in descending order
    const sortedScores = filteredEntries
      .map((entry) => calculateTotalScore(entry))
      .sort((a, b) => b - a);

    // Find the position of the current entry's score in the sorted scores array
    const position = sortedScores?.indexOf(entryScore) + 1;

    if (!highestScore || entryScore === "- -") {
      return "- -";
    } else if (entryScore === highestScore) {
      return `1st`;
    } else if (position === 1) {
      return `1st`;
    } else if (position === 2) {
      return `2nd`;
    } else if (position === 3) {
      return `3rd`;
    } else {
      return `${position}th`;
    }
  };

  const [showNames, setShowNames] = useState(null);

  const eachRoutineCategory =
    status === "success" &&
    removeLastSessionBreak(currentSessionCategories())?.map(
      (category, index) => {
        return (
          <Draggable
            key={category?.id}
            draggableId={category?.id}
            index={index}
            // isDragDisabled={
            //   !allowDrag ||
            //   showAwards ||
            //   ((currentEvent?.locked || loading) && !confrmDrag)
            // }
            isDragDisabled={true}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.draggableProps}>
                {/* Wrap RoutineCategory with a div to prevent drag event propagation */}
                <div {...provided.dragHandleProps}>
                  <StageManagerCate
                    key={index}
                    index={index}
                    category={category}
                    allEntries={allEntries}
                    sortedCategories={sortedCategories}
                    closeDropdown={closeDropdown}
                    showDropdownRow={showDropdownRow}
                    setShowDropdownRow={setShowDropdownRow}
                    currentEvent={currentEvent}
                    entryTypes={entryTypes}
                    selectedEntry={selectedEntry}
                    setSelectedEntry={setSelectedEntry}
                    handleCheckIn={handleCheckIn}
                    handleRunAround={handleRunAround}
                    handleDancerStart={handleDancerStart}
                    handleCheckProps={handleCheckProps}
                    compId={compId}
                    setLoadingEntry={setLoadingEntry}
                    loadingEntry={loadingEntry}
                    loading={loading}
                    confrmDrag={confrmDrag}
                    notifyLocked={notifyLocked}
                    setAllowDrag={setAllowDrag}
                    showMoveEntry={showMoveEntry}
                    setShowMoveEntry={setShowMoveEntry}
                    handleOpenEdit={handleOpenEdit}
                    currentSessionIndex={currentSessionIndex}
                    showAwards={showAwards}
                    calculatePosition={calculatePosition}
                    calculateTotalScore={calculateTotalScore}
                    findMatchingAward={findMatchingAward}
                    awards={awards}
                    allScores={allScores}
                    setShowNames={setShowNames}
                    showNames={showNames}
                    allOveralls={allOveralls}
                    currentComp={currentComp}
                  />
                </div>
              </div>
            )}
          </Draggable>
        );
      }
    );

  return (
    <>
      <Header allEntries={allEntries} sortedCategories={sortedCategories} />
      {openEdit && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            // closeAll();
          }}
          className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col"
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center justify-center text-white/80 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name?.trim())}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>{" "}
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add entries form */}
            <div className="pb-10">
              <EmceeEditEntry
                compId={compId}
                entryToEdit={entryToEdit}
                currentEvent={currentEvent}
                handleCloseEdit={handleCloseEdit}
              />
            </div>
          </div>
          <ScrollToTop />
        </div>
      )}

      {!openEdit && (
        <main className="w-full h-screen overflow-y-auto overflow-x-hidden bg-[#1C1C1C] px-3 md:px-10 py-[100px] relative text-white">
          <button
            onClick={goBack}
            className="px-5 py-1 bg-white/80 hover:bg-white/50 text-black rounded-lg text-[.75rem] md:text-[1rem]"
          >
            Back
          </button>
          {loading && (
            <div className="w-full h-[200px] flex justify-center items-center border border-white/10 rounded-lg mt-5">
              <PulseLoader color="#94a4fd" size={10} />
            </div>
          )}
          {!loading && (
            <>
              <div className="w-full flex flex-col">
                <div className="w-full flex justify-center text-white mt-4">
                  {status !== "loading" && (
                    <div className="flex flex-col items-center gap-1 text-[1.5rem] md:text-[1.75rem] font-bold">
                      {status === "loading" ? (
                        <Spinner />
                      ) : (
                        <>
                          <ul className="flex border py-0 cursor-pointer">
                            <li
                              onClick={() => setShowAwards(false)}
                              className={`px-2 border-r text-[20px] hover:bg-[#94a4fd]/40 ${
                                !showAwards && "bg-white/40"
                              }`}
                            >
                              Entries
                            </li>
                            <li
                              onClick={() => setShowAwards(true)}
                              className={`text-[20px] px-2 hover:bg-[#94a4fd]/40 ${
                                showAwards && "bg-white/40"
                              }`}
                            >
                              Awards
                            </li>
                          </ul>
                          <span className="text-[18px]">
                            {capitalizeFirstLetter(
                              currentEvent?.event_name?.trim()
                            )}
                          </span>
                          <div className="flex gap-2 text-center cursor-pointer">
                            <img
                              src="/images/leftArrow.png"
                              alt="left icon"
                              className="w-8 h-8"
                              onClick={showPreviousSession}
                            />
                            {essen?.length > 0 ? (
                              essen?.map((session, index) => {
                                // console.log("session", session);
                                return (
                                  <div
                                    key={session.id}
                                    style={{
                                      display:
                                        index === currentSessionIndex
                                          ? "flex"
                                          : "none",
                                    }}
                                  >
                                    <span className="text-[18px]">
                                      Session {index + 1}
                                    </span>
                                  </div>
                                );
                              })
                            ) : (
                              <div>
                                <span className="text-[18px]">
                                  No sessions available
                                </span>
                              </div>
                            )}
                            <img
                              src="/images/rightArrow.png"
                              alt="right icon"
                              className="w-8 h-8"
                              onClick={showNextSession}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {currentEvent?.locked && !showAwards && (
                <div className="flex flex-col gap-2">
                  <div
                    onClick={toggleConfirmDrag}
                    className="w-fit text-[.85rem] text-[#94a4fd] p-1 hover:bg-[#94a4fd]/30 bg-white/10 hover:px-2 rounded-md cursor-pointer"
                  >
                    {confrmDrag ? "Disable drag & drop" : "Enable drag & drop"}
                    <br />
                  </div>

                  {/* <div
                  onClick={toggleConfirmDrag}
                  className="w-fit text-[.85rem] text-[#94a4fd] p-1 hover:bg-[#94a4fd]/30 bg-white/10 hover:px-2 rounded-md cursor-pointer"
                >
                  {confrmDrag
                    ? "Disable drag & drop categories"
                    : "Enable drag & drop categories?"}
                  <br />
                </div> */}
                </div>
              )}
              {/*  */}
              <section className="w-full flex gap-2 mt-4">
                {sortedCategories && sortedCategories?.length > 0 && (
                  <div className="w-full min-h-[300px] text-[1.1em] py-3 overflow-auto">
                    <div className="sm:w-full lg:min-w-[1200px]">
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <StrictModeDroppable droppableId="cat-list">
                          {(provided) => (
                            <div
                              className="w-full relative"
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              onMouseDown={(e) => e.stopPropagation()}
                            >
                              {/* {(consecutiveBuffer || lockedSchedule) &&
                                !confrmDrag && (
                                  <div className="w-[250px] p-3 rounded-lg border border-red-500 text-[.85rem] z-[2] fixed top-[100px] left-[50%] bg-[#282929] flex gap-3 items-center scale">
                                    <img
                                      alt=""
                                      src="/images/icons8-cross-30.png"
                                      className="w-6 h-6"
                                    />{" "}
                                    {lockedSchedule
                                      ? "Schedule is locked!"
                                      : "Consecutive time buffers!"}
                                  </div>
                                )} */}

                              {notifyDrag && (
                                <div
                                  className={`w-[250px] p-3 rounded-lg border  text-[.85rem] z-[2] fixed top-[100px] left-[50%] bg-[#282929] flex gap-3 items-center scale ${
                                    confrmDrag
                                      ? "border-green-400"
                                      : "border-red-500"
                                  }`}
                                >
                                  <img
                                    alt=""
                                    src={
                                      confrmDrag
                                        ? "/images/icons8-check-green.png"
                                        : "/images/icons8-cross-30.png"
                                    }
                                    className="w-6 h-6"
                                  />{" "}
                                  {confrmDrag
                                    ? "Drag & drop enabled!"
                                    : "Drag & drop disabled!"}
                                </div>
                              )}

                              {/* {notifyDragCat && (
                              <div
                                className={`w-[250px] p-3 rounded-lg border  text-[.85rem] z-[2] fixed top-[100px] left-[50%] bg-[#282929] flex gap-3 items-center scale ${
                                  confrmDragCat
                                    ? "border-green-400"
                                    : "border-red-500"
                                }`}
                              >
                                <img
                                  alt=""
                                  src={
                                    confrmDragCat
                                      ? "/images/icons8-check-green.png"
                                      : "/images/icons8-cross-30.png"
                                  }
                                  className="w-6 h-6"
                                />{" "}
                                {confrmDragCat
                                  ? "Categories drag & drop enabled!"
                                  : "Categories drag & drop disabled!"}
                              </div>
                            )} */}
                              {eachRoutineCategory}
                              {provided.placeholder}
                            </div>
                          )}
                        </StrictModeDroppable>
                      </DragDropContext>
                    </div>
                  </div>
                )}
                {sortedCategories?.length === 0 &&
                  !loading &&
                  essen?.length > 0 && (
                    <div className="w-full h-[200px] border border-white/20 rounded-lg flex justify-center items-center text-white/50 mt-8">
                      <p>No sessions available...</p>
                    </div>
                  )}
                {sortedCategories?.length === 0 &&
                  !loading &&
                  essen?.length === 0 && (
                    <div className="w-full h-[200px] border border-white/20 rounded-lg flex justify-center items-center text-white/50 mt-8">
                      <p>No entries in this event...</p>
                    </div>
                  )}
              </section>
            </>
          )}
        </main>
      )}
    </>
  );
};

export default StageManager;
