// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: "AIzaSyArFhwNlhDFOwj3Juy-cj8QpinpC0QbWfs",
  authDomain: "dance-competition-management.firebaseapp.com",
  projectId: "dance-competition-management",
  storageBucket: "dance-competition-management.appspot.com",
  messagingSenderId: "789118893645",
  appId: "1:789118893645:web:7ec0386330bdf2b689a604",
};

// Initialize Firebase and get a reference to the services
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);
const auth = getAuth(app);

// if (window.location.hostname.includes("localhost")) {
//   connectFirestoreEmulator(db, "localhost", 8080);
//   connectFunctionsEmulator(functions, "localhost", 5001);
// }
// // Emulator setup for Auth
// if (window.location.hostname.includes("localhost")) {
//   connectAuthEmulator(auth, "http://localhost:9099");
// }

export { app, db, functions, auth, storage, analytics };
