import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  sendEmailVerification,
  // signOut,
} from "firebase/auth";
import { auth, db, functions } from "./firebase";
import { httpsCallable } from "firebase/functions";
import { setOrganizerCustomClaim } from "redux/organizerRegistration/organizerSlices";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const setCustomClaim = httpsCallable(functions, "setCustomClaim");
const sendWelcomeEmail = httpsCallable(functions, "sendWelcomeEmail");

export const registerOrganizer = createAsyncThunk(
  "auth/registerOrganizer",
  async ({ email, password, competitionName }, thunkAPI) => {
    // Check if competitionName already exists
    const competitionQuery = query(
      collection(db, "competitions"),
      where("competitionName", "==", competitionName)
    );
    const querySnapshot = await getDocs(competitionQuery);
    if (!querySnapshot.empty) {
      throw new Error(
        "Competition name already exists. Please choose a different name."
      );
    }
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const role = "organizer"; // Set this to 'organizer' role
    try {
      const response = await setCustomClaim({ uid: user.uid, role });
      const emailResponse = await sendWelcomeEmail(email, competitionName);

      //to send email verification
      const actionCodeSettings = {
        url: "https://thescorebox.ca/",
      };
      sendEmailVerification(auth.currentUser, actionCodeSettings).then(() => {
        console.log(
          "Email verification sent. Check your inbox and verify your email."
        );
      });

      console.log(response.data.message);
      console.log(emailResponse);

      // Dispatch action from within the async thunk. THANKS to redux tool kits
      thunkAPI.dispatch(setOrganizerCustomClaim(true));
    } catch (error) {
      console.error("Error setting custom claim:", error.message);
    }

    const organizerUserData = {
      uid: user.uid,
      email: user.email,
      competitionName: competitionName,
      formCompleted: false,
      timestamp: serverTimestamp(),
      judge_settings: {
        default_value: 0,
        increment_value: 0.01,
      },
    };

    const competitionData = {
      competitionName: competitionName,
      timestamp: serverTimestamp(),
    };

    await setDoc(doc(db, "organizers", user.uid), organizerUserData);
    await setDoc(doc(db, "competitions", user.uid), competitionData);

    await user.getIdToken(true);
    return {
      uid: user.uid,
    };
  }
);

// login service
export const login = async (email, password) => {
  try {
    // if (email === "info@versastyle.ca") {
    //   const userData = {
    //     uid: "clH7Gurn66WUeCE8l6Ufa0SYFZW2",
    //     displayName: "",
    //     email: "info@versastyle.ca",
    //   };

    //   return {
    //     user: userData,
    //     role: "organizer",
    //     formCompleted: true,
    //     emailVerified: true,
    //     creationTime: "Thu, 2 Jun 2023 16:00:00 GMT",
    //   };
    // }

    const { user } = await signInWithEmailAndPassword(auth, email, password);
    const { role, formCompleted } = await getUserRoleAndFormCompletedStatus(
      user
    );
    // Extract the necessary data from the Firebase user object
    const userData = {
      uid: user.uid,
      displayName: user.displayName,
      email: user.email,
      // Add any other necessary properties
    };

    return {
      user: userData,
      role,
      formCompleted,
      emailVerified: user?.emailVerified,
      creationTime: user?.metadata?.creationTime,
    };
  } catch (error) {
    console.log("service error", error);
    throw new Error(error.message);
  }
};

//logout service
export const logout = async () => {
  try {
    await signOut(auth);
    return { success: true };
  } catch (error) {
    console.error("Error logging out:", error);
    return { success: false };
  }
};

//to send password reset link
export const resetPassword = async (email) => {
  const actionCodeSettings = {
    url: "https://thescorebox.ca/password-reset-success",
  };
  try {
    await sendPasswordResetEmail(auth, email, actionCodeSettings);

    return { success: true };
  } catch (error) {
    console.error("Reset password error:", error);
    return { success: false, message: error?.message };
  }
};

//to send email verification link
export const sendVerificationEmail = async () => {
  const actionCodeSettings = {
    url: "https://thescorebox.ca",
  };
  try {
    await sendEmailVerification(auth.currentUser, actionCodeSettings);

    return { success: true };
  } catch (error) {
    console.error("email verification send error:", error);
    return { success: false, message: error?.message };
  }
};

// Similarly, you can handle registerStudioOwner actions here

export const registerStudioOwner = createAsyncThunk(
  "auth/registerOrganizer",
  async ({ email, password, studioName }, thunkAPI) => {
    console.log("REGISTERING STUDIO OWNER!!!!!!!!!!!!!!!!!!!");
    // Check if studioName already exists
    const studioQuery = query(
      collection(db, "studio_owners"),
      where("studioName", "==", studioName)
    );
    const querySnapshot = await getDocs(studioQuery);
    if (!querySnapshot.empty) {
      throw new Error(
        "Studio name already exists. Please choose a different name."
      );
    }
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    const role = "danceStudioOwner"; // Set this to 'danceStudioOwner' role

    try {
      const response = await setCustomClaim({ uid: user.uid, role });
      const emailResponse = await sendWelcomeEmail({
        email,
        studioName: capitalizeFirstLetter(studioName),
      });
      //to send email verification
      const actionCodeSettings = {
        url: "https://thescorebox.ca/",
      };
      sendEmailVerification(auth.currentUser, actionCodeSettings).then(() => {
        console.log(
          "Email verification sent. Check your inbox and verify your email."
        );
      });

      console.log(response.data.message);
      console.log(emailResponse);
    } catch (error) {
      console.error("Error", error.message);
    }

    const studioUserData = {
      uid: user.uid,
      email: user.email,
      studioName: studioName,
      formCompleted: false,
      timestamp: serverTimestamp(),
    };

    await setDoc(doc(db, "studio_owners", user.uid), studioUserData);

    await user.getIdToken(true);
    return {
      uid: user.uid,
    };
  }
);

//================to register judges
export const registerJudge = async (data) => {
  try {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      data?.email,
      data?.password
    );

    const role = "judge";

    const response = await setCustomClaim({ uid: user.uid, role });
    console.log(response.data.message);

    const judgesRef = doc(db, "judges", user.uid);
    delete data.password;
    await setDoc(judgesRef, { ...data, uid: user?.uid });

    await user.getIdToken(true);
    return {
      uid: user.uid,
      success: true,
    };
  } catch (error) {
    console.error("Error registering user:", error);
    return { success: false, message: error };
  }
};

//================to register emcee
export const registerEmcee = async (data) => {
  try {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      data?.email,
      data?.password
    );

    const role = "emcee";

    const response = await setCustomClaim({ uid: user.uid, role });
    console.log(response.data.message);

    const emceesRef = doc(db, "emcees", user.uid);
    delete data.password;
    await setDoc(emceesRef, { ...data, formCompleted: true });

    await user.getIdToken(true);
    return {
      uid: user.uid,
      success: true,
    };
  } catch (error) {
    console.error("Error registering user:", error);
    return { success: false, message: error.message };
  }
};

//================ set the roles and completed status update
export const getUserRoleAndFormCompletedStatus = async (user) => {
  if (!user) {
    throw new Error("User not logged in");
  }

  const tokenResult = await user.getIdTokenResult();
  const role = tokenResult.claims.role;

  if (!role) {
    throw new Error("User has no custom claim");
  }

  const docRef = doc(
    db,
    role === "organizer"
      ? "organizers"
      : role === "danceStudioOwner"
      ? "studio_owners"
      : role === "judge"
      ? "judges"
      : "emcees",
    user.uid
  );
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    throw new Error("User data not found in the database");
  }

  const formCompleted = docSnap.data().formCompleted;
  return { role, formCompleted };
};
