/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { PulseLoader } from "react-spinners";
import ScheduleCategory from "./components/ScheduleCategory";
import { useEffect, useState } from "react";
import { useFetchScheduleDocs } from "components/hooks/useFetchScheduleDocs";
import InfiniteLoader from "react-window-infinite-loader";
import { VariableSizeList } from "react-window";
import { removeDuplicateObjectsByProperty } from "utils/removeDuplicatesByProperty";

const ScheduleStudio = () => {
  const { addEvent, openNotifsBoard } = useSelector(
    (state) => state.danceStudio
  );

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem("event_name") || "";
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  //to get this dance studio data
  const { data: studioData } = useFirestoreQueryDoc("studio_owners", user?.uid);
  //to get realtime events from firestore
  const { data } = useQueryAllDocs("competitions");
  const currentComp = data?.filter((item) => item?.compId === compId)[0];
  const currentEvent = currentComp?.events?.filter(
    (item) => item.event_name?.replace(/ /g, "-") === event_name
  )[0];

  //to get all entries
  const { data: allEntries } = useFetchScheduleDocs(
    "competitions",
    compId,
    "entries",
    "event_uid",
    currentEvent?.event_uid
  );

  const myEntriesStudio = allEntries?.filter(
    (entry) =>
      entry?.studio_selected[0]?.toLowerCase() ===
      studioData?.studioName?.toLowerCase()
  );

  //to get all categories
  const { status: entryStatus, data: eventCategories } = useFetchScheduleDocs(
    "competitions",
    compId,
    "categories",
    "event_uid",
    currentEvent?.event_uid
  );

  const category_nameArr = allEntries?.map((x) => x?.category_name);

  const categoriesRaw = eventCategories
    ?.filter((y) => category_nameArr?.includes(y?.category_name))
    ?.concat(eventCategories?.filter((z) => z?.type !== "category"));

  let sortedCategoriesRaw = categoriesRaw?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  const [sortedCategories, setSortedCategories] = useState([]);

  // console.log("sortedCategories", sortedCategories);

  useEffect(() => {
    if (entryStatus === "success") {
      let arr = removeDuplicateObjectsByProperty(
        sortedCategoriesRaw,
        "category_name"
      );
      setSortedCategories(arr);
    }
  }, [entryStatus, eventCategories]);

  // //to get all categories
  // const { status, data: eventCategories } = useFetchScheduleDocs(
  //   "competitions",
  //   compId,
  //   "categories",
  //   "event_uid",
  //   currentEvent?.event_uid
  // );

  // const category_nameArr = myEntriesStudio?.map((x) => x?.category_name);

  // const categoriesRaw = eventCategories
  //   ?.filter((y) => category_nameArr?.includes(y?.category_name))
  //   ?.concat(eventCategories?.filter((z) => z?.type !== "category"));

  // let sortedCategories = categoriesRaw?.sort((a, b) => a?.sort_id - b?.sort_id);

  const [showNotes, setShowNotes] = useState("");

  const [showSchedule, setShowSchedule] = useState(null);
  const [showNames, setShowNames] = useState(null);
  const handleViewSchedule = () => {
    setShowSchedule(true);
  };

  // console.log("showNames", showNames);
  //===================================================to efficiently render only the visible categories
  const getItemSize = (index) => {
    const category = sortedCategories?.[index];
    const myEntries = myEntriesStudio?.filter(
      (x) => x?.category_name === category?.category_name
    );

    const entryCount = myEntries?.length;
    let height = 0;

    //height based on the number of entries
    if (entryCount === 0) {
      height = 50;
    } else if (entryCount === 1) {
      height = entryCount * 100;
    } else if (entryCount === 2) {
      height = entryCount * 75;
    } else if (entryCount === 3) {
      height = entryCount * 70;
    } else if (entryCount === 4) {
      height = entryCount * 60;
    } else if (entryCount === 5) {
      height = entryCount * 60;
    } else {
      height = entryCount * 55;
    }

    return height;
  };

  // Define itemCount based on the length of sortedCategories
  const itemCount = sortedCategories?.length;

  // Define the renderRow function similar to befo100
  const renderRow = ({ index, style }) => {
    const category = sortedCategories[index];

    // Pass the index to getItemSize function
    const rowHeight = getItemSize(index);

    const categoriesBeforeCurrent = sortedCategories?.slice(0, index) || [];

    const sessionsBeforeCurrent = categoriesBeforeCurrent?.filter(
      (item) => item?.break_type === "session"
    );

    const myEntries = allEntries?.filter(
      (entry) =>
        entry?.category_name === category?.category_name &&
        entry?.studio_selected[0] === studioData?.studioName
    );

    let myEntriesSorted = myEntries?.sort((a, b) => a?.sort_id - b?.sort_id);

    return (
      <div style={{ ...style, height: rowHeight }}>
        <ScheduleCategory
          key={index}
          index={index}
          category={category}
          myEntriesSorted={myEntriesSorted}
          sessionsBeforeCurrent={sessionsBeforeCurrent}
          currentEvent={currentEvent}
          showNotes={showNotes}
          setShowNotes={setShowNotes}
          sortedCategories={sortedCategories}
          allEntries={allEntries}
          setShowNames={setShowNames}
          showNames={showNames}
        />
      </div>
    );
  };

  const isItemLoaded = (index) => {
    // logic to determine if item at index is loaded
  };

  const loadMoreItems = (startIndex, stopIndex) => {
    // logic to load more items
  };
  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          className={`w-full min-h-screen text-white/80 sm:pl-[200px] lg:pl-[250px] sm:pr-[0px]  ${
            openNotifsBoard ? "lg:pr-[280px]" : "lg:pr-[0px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex flex-wrap gap-4 justify-between items-center text-white/90 text-[1rem] mb-6 px-5">
              <div>
                <h2>
                  {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                  {currentEvent?.year.split(" to ")[0]} - Schedule
                </h2>
              </div>
            </div>
            {!currentEvent?.locked && showSchedule && (
              <div className="flex flex-col gap-2 ml-6">
                <div className="text-[.9rem] text-[#94a4fd]">
                  Schedule Is Not Available
                </div>
                <div className="text-[.9rem] text-[#94a4fd] mb-3">
                  The schedule with entry numbers and stage time for this event
                  is not yet available. <br /> The competition owner will notify
                  you once they have made it available.
                </div>
              </div>
            )}

            {!showSchedule && !currentEvent?.locked && (
              <section className="w-full flex lg:flex-row flex-col gap-5 p-4 bg-gradient-to-b from-transparent to-white/10 pb-[200px] lg:pb-[300px] relative">
                <div className="w-full sm:w-[300px] mx-auto lg:mx-0 bg-white/40 p-3 rounded-full">
                  <img
                    alt=""
                    src="/images/unavailable.png"
                    className="w-full h-auto"
                  />
                </div>
                <div className="w-full lg:w-[50%] text-[1.5rem] md:text-[2rem] text-white/80 font-bold flex flex-col gap-3 md:pt-7 px-5">
                  <h2>Schedule Is Not Available</h2>
                  <p className="text-[1.15rem] font-normal">
                    The schedule for this event is not yet available. The
                    competition owner will notify you once they have made it
                    available.
                  </p>
                  <p className="text-[1.15rem] font-normal">
                    You can view the schedule without Times and Entry numbers
                  </p>
                  <button
                    onClick={handleViewSchedule}
                    className={`w-fit text-[.85rem] px-5 py-2 rounded-full text-[#1c1c1c] transition-all duration-300 bg-[#94a4fd] hover:bg-[#94a4fd]/60`}
                  >
                    View Schedule
                  </button>
                </div>

                {/* svg waves */}
                <svg
                  className="w-full absolute bottom-[-1px] left-0 z-0 hidden md:block"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#1c1c1c"
                    fillOpacity="1"
                    d="M0,256L60,256C120,256,240,256,360,245.3C480,235,600,213,720,176C840,139,960,85,1080,90.7C1200,96,1320,160,1380,192L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                  ></path>
                </svg>

                {/* mobile wave */}
                <svg
                  className="w-full absolute bottom-[-1px] left-0 z-0 block md:hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1440 320"
                >
                  <path
                    fill="#1c1c1c"
                    fillOpacity="1"
                    d="M0,256L60,256C120,256,240,256,360,245.3C480,235,600,213,720,176C840,139,960,85,1080,90.7C1200,96,1320,160,1380,192L1440,224L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
                  ></path>
                </svg>
              </section>
            )}

            {(currentEvent?.locked || showSchedule) && (
              <section className="w-full flex flex-col gap-2 px-5">
                {entryStatus === "success" && (
                  <div className="w-full lg:w-[85%] text-[.9em] pt-3 pb-20 overflow-x-auto overflow-y-hidden relative">
                    <div className="w-full mb-3 text-right">
                      <button
                        onClick={() =>
                          window.open(
                            `/studio/event/${event_name.replace(
                              / /g,
                              "-"
                            )}/schedule/print`,
                            "_blank"
                          )
                        }
                        className={`w-fit ml-auto text-[.85rem] px-5 py-1 mr-2 rounded-full text-[#1c1c1c] transition-all duration-300 bg-[#94a4fd] hover:bg-[#94a4fd]/60`}
                      >
                        Print
                      </button>
                    </div>

                    {/* The schedule table */}
                    <div className="w-full text-[.9em] pb-5 pt-[60px] overflow-x-auto overflow-y-hidden relative border border-white/20 rounded-lg">
                      {/* The schedule table */}
                      <div className="flex gap-3 absolute top-3 left-3">
                        <button
                          // onClick={() => scrollToTop()}
                          className="text-[.85rem] px-8 py-1 mr-2 rounded-full bg-gray-300/80 hover:bg-gray-300/50 flex gap-1 items-center text-[#1c1c1c] transition-all duration-300"
                        >
                          Entries
                          <p className="font-bold">{myEntriesStudio?.length}</p>
                        </button>
                        {/* <button
                          // onClick={() => scrollToBottom()}
                          className="text-[.85rem] px-5 py-1 mr-2 rounded-full bg-gray-300/80 hover:bg-gray-300/50 flex gap-1 items-center text-[#1c1c1c] transition-all duration-300"
                        >
                          Categories
                          <p className="font-bold">{eventCategories?.length}</p>
                        </button> */}
                      </div>

                      {/* {eachRoutineCategory} */}
                      <InfiniteLoader
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                      >
                        {({ onItemsRendered, ref }) => (
                          <VariableSizeList
                            id="tray"
                            itemCount={itemCount}
                            itemSize={getItemSize}
                            onItemsRendered={onItemsRendered}
                            ref={(list) => {
                              ref(list);
                              // listRef.current = list;
                            }}
                            height={800} // Set a height for the list
                            width="100%" // Set width to 100% or a specific width
                          >
                            {renderRow}
                          </VariableSizeList>
                        )}
                      </InfiniteLoader>
                    </div>

                    {sortedCategories?.length === 0 &&
                      entryStatus !== "loading" && (
                        <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
                          <h4>No Routines Yet...</h4>
                        </div>
                      )}
                  </div>
                )}

                {/* to show spinner on page load */}
                {entryStatus === "loading" && (
                  <div className="w-full md:w-[85%]  text-[.9em] pt-3 pb-20 overflow-x-auto overflow-y-hidden">
                    <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
                      <PulseLoader color="#94a4fd" size={10} />
                    </div>
                  </div>
                )}
              </section>
            )}
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default ScheduleStudio;
