import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { registerEmcee } from "services/authenticationService";

import {
  checkInEntryService,
  revertCheckInEntryService,
  performanceReadyEntryService,
  revertPerformanceReadyEntryService,
  revertRunAroundEntryService,
  runAroundEntryService,
  revertDancerStartEntryService,
  dancerStartEntryService,
  revertIsCheckPropsEntryService,
  isCheckedPropsEntryService,
  addNoteService,
  deleteNoteService,
  announceEntryService,
  // editNoteService,
} from "services/emceeService";

const initialState = {
  competitions: [],
  currentComp: null,
  currentEvent: null,
  entriesList: [],

  regEmceeSuccess: null,
  regEmceeFail: null,
  regEmceePending: false,

  checkInServiceSuccess: null,
  checkInServiceFail: null,
  checkInServicePending: false,

  revertCheckInServiceSuccess: null,
  revertCheckInServiceFail: null,
  revertCheckInServicePending: false,

  performanceReadySuccess: null,
  performanceReadyFail: null,
  performanceReadyPending: false,

  revertPerformanceReadySuccess: null,
  revertPerformanceReadyFail: null,
  revertPerformanceReadyPending: false,

  runAroundServiceSuccess: null,
  runAroundServiceFail: null,
  runAroundServicePending: false,

  revertRunAroundServiceSuccess: null,
  revertRunAroundServiceFail: null,
  revertRunAroundServicePending: false,

  dancerStartServiceSuccess: null,
  dancerStartServiceFail: null,
  dancerStartServicePending: false,

  revertDancerStartServiceSuccess: null,
  revertDancerStartServiceFail: null,
  revertDancerStartServicePending: false,

  isCheckedPropsServiceSuccess: null,
  isCheckedPropsServiceFail: null,
  isCheckedPropsServicePending: false,

  revertIsCheckPropsServiceSuccess: null,
  revertIsCheckPropsServiceFail: null,
  revertIsCheckPropsServicePending: false,

  addNoteSuccess: null,
  addNoteFail: null,
  addNotePending: false,

  deleteNoteSuccess: null,
  deleteNoteFail: null,
  deleteNotePending: false,

  // editNoteSuccess: null,
  // editNoteFail: null,
  // editNotePending: false,

  currentSessionIndex: 0,
};

//===============================to handle get all entries async func
export const regEmcee = createAsyncThunk(
  "emceeSlice/regEmcee",
  async (data) => {
    const result = await registerEmcee(data);
    console.log(result);
    return result;
  }
);

//===============================to handle check in async func
export const checkInService = createAsyncThunk(
  "emceeSlice/checkInService",
  async (data) => {
    const result = await checkInEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle revert check in async func
export const revertCheckInService = createAsyncThunk(
  "emceeSlice/revertCheckInService",
  async (data) => {
    const result = await revertCheckInEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle check in async func
export const announceEntry = createAsyncThunk(
  "emceeSlice/announceEntry",
  async (data) => {
    const result = await announceEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle performance ready async func
export const performanceReady = createAsyncThunk(
  "emceeSlice/performanceReady",
  async (data) => {
    const result = await performanceReadyEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle revert performance ready async func
export const revertPerformanceReady = createAsyncThunk(
  "emceeSlice/revertPerformanceReady",
  async (data) => {
    const result = await revertPerformanceReadyEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle run around async func
export const runAroundService = createAsyncThunk(
  "emceeSlice/runAroundService",
  async (data) => {
    const result = await runAroundEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle revert run around async func
export const revertRunAroundService = createAsyncThunk(
  "emceeSlice/revertRunAroundService",
  async (data) => {
    const result = await revertRunAroundEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle dancer start async func
export const dancerStartService = createAsyncThunk(
  "emceeSlice/dancerStartService",
  async (data) => {
    const result = await dancerStartEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle revert dancer start async func
export const revertDancerStartService = createAsyncThunk(
  "emceeSlice/revertDancerStartService",
  async (data) => {
    const result = await revertDancerStartEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle is checked props async func
export const isCheckedPropsService = createAsyncThunk(
  "emceeSlice/isCheckedPropsService",
  async (data) => {
    const result = await isCheckedPropsEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle revert is checked props async func
export const revertIsCheckedPropsService = createAsyncThunk(
  "emceeSlice/revertIsCheckedPropsService",
  async (data) => {
    const result = await revertIsCheckPropsEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add note async func
export const addNote = createAsyncThunk("emceeSlice/addNote", async (data) => {
  const result = await addNoteService(data);
  console.log(result);
  return result;
});

//===============================to handle delete note async func
export const deleteNote = createAsyncThunk(
  "emceeSlice/deleteNote",
  async (data) => {
    const result = await deleteNoteService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit note async func
// export const editNote = createAsyncThunk(
//   'emceeSlice/editNote',
//   async (data) => {
//     const result = await editNoteService(data);
//     console.log(result);
//     return result;
//   }
// );

//======================= slice ===============================
const emceeSlice = createSlice({
  name: "emcee",
  initialState,
  reducers: {
    saveEmceeData: (state, action) => {
      state.regData.push(action.payload);
    },
    clearSuccess: (state, action) => {
      state.regEmceeSuccess = null;
      state.addNoteSuccess = null;
      state.deleteNoteSuccess = null;
      // state.editNoteSuccess = null;
    },
    setCompetitions: (state, action) => {
      state.competitions = action.payload;
    },
    setCurrentComp: (state, action) => {
      state.currentComp = action.payload;
    },
    setCurrentEvent: (state, action) => {
      state.currentEvent = action.payload;
    },
    setEntriesList: (state, action) => {
      state.entriesList = action.payload;
    },
    setCurrentSessionIndex: (state, action) => {
      state.currentSessionIndex = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      //cases for emcee data
      .addCase(regEmcee.pending, (state) => {
        state.regEmceePending = true;
      })
      .addCase(regEmcee.fulfilled, (state, action) => {
        state.regEmceePending = false;
        state.regEmceeSuccess = action.payload;
      })
      .addCase(regEmcee.rejected, (state, action) => {
        state.regEmceePending = false;
      })
      //cases for checkIn entry
      .addCase(checkInService.pending, (state) => {
        state.checkInServicePending = true;
      })
      .addCase(checkInService.fulfilled, (state, action) => {
        state.checkInServiceSuccess = action.payload;
        state.checkInServicePending = false;
      })
      .addCase(checkInService.rejected, (state, action) => {
        state.checkInServiceFail = action.payload;
        state.checkInServicePending = false;
      })
      //cases for revert CheckIn entry
      .addCase(revertCheckInService.pending, (state) => {
        state.revertCheckInServicePending = true;
      })
      .addCase(revertCheckInService.fulfilled, (state, action) => {
        state.revertCheckInServiceSuccess = action.payload;
        state.revertCheckInServicePending = false;
      })
      .addCase(revertCheckInService.rejected, (state, action) => {
        state.revertCheckInServiceFail = action.payload;
        state.revertCheckInServicePending = false;
      })

      //cases for performance ready
      .addCase(performanceReady.pending, (state) => {
        state.performanceReadyPending = true;
      })
      .addCase(performanceReady.fulfilled, (state, action) => {
        state.performanceReadySuccess = action.payload;
        state.performanceReadyPending = false;
      })
      .addCase(performanceReady.rejected, (state, action) => {
        state.performanceReadyFail = action.payload;
        state.performanceReadyPending = false;
      })

      //cases for revert performance ready
      .addCase(revertPerformanceReady.pending, (state) => {
        state.revertPerformanceReadyPending = true;
      })
      .addCase(revertPerformanceReady.fulfilled, (state, action) => {
        state.revertPerformanceReadySuccess = action.payload;
        state.revertPerformanceReadyPending = false;
      })
      .addCase(revertPerformanceReady.rejected, (state, action) => {
        state.revertPerformanceReadyFail = action.payload;
        state.revertPerformanceReadyPending = false;
      })

      //cases for run around
      .addCase(runAroundService.pending, (state) => {
        state.runAroundServicePending = true;
      })
      .addCase(runAroundService.fulfilled, (state, action) => {
        state.runAroundServiceSuccess = action.payload;
        state.runAroundServicePending = false;
      })
      .addCase(runAroundService.rejected, (state, action) => {
        state.runAroundServiceFail = action.payload;
        state.runAroundServicePending = false;
      })

      //cases for revert run around
      .addCase(revertRunAroundService.pending, (state) => {
        state.revertRunAroundServicePending = true;
      })
      .addCase(revertRunAroundService.fulfilled, (state, action) => {
        state.revertRunAroundServiceSuccess = action.payload;
        state.revertRunAroundServicePending = false;
      })
      .addCase(revertRunAroundService.rejected, (state, action) => {
        state.revertRunAroundServiceFail = action.payload;
        state.revertRunAroundServicePending = false;
      })

      //cases for dancer start
      .addCase(dancerStartService.pending, (state) => {
        state.dancerStartServicePending = true;
      })
      .addCase(dancerStartService.fulfilled, (state, action) => {
        state.dancerStartServiceSuccess = action.payload;
        state.dancerStartServicePending = false;
      })
      .addCase(dancerStartService.rejected, (state, action) => {
        state.dancerStartServiceFail = action.payload;
        state.dancerStartServicePending = false;
      })

      //cases for revert dancer start
      .addCase(revertDancerStartService.pending, (state) => {
        state.revertDancerStartServicePending = true;
      })
      .addCase(revertDancerStartService.fulfilled, (state, action) => {
        state.revertDancerStartServiceSuccess = action.payload;
        state.revertDancerStartServicePending = false;
      })
      .addCase(revertDancerStartService.rejected, (state, action) => {
        state.revertDancerStartServiceFail = action.payload;
        state.revertDancerStartServicePending = false;
      })

      //cases for is checked props
      .addCase(isCheckedPropsService.pending, (state) => {
        state.isCheckedPropsServicePending = true;
      })
      .addCase(isCheckedPropsService.fulfilled, (state, action) => {
        state.isCheckedPropsServiceSuccess = action.payload;
        state.isCheckedPropsServicePending = false;
      })
      .addCase(isCheckedPropsService.rejected, (state, action) => {
        state.isCheckedPropsServiceFail = action.payload;
        state.isCheckedPropsServicePending = false;
      })

      //cases for revert is checked props
      .addCase(revertIsCheckedPropsService.pending, (state) => {
        state.revertIsCheckPropsServicePending = true;
      })
      .addCase(revertIsCheckedPropsService.fulfilled, (state, action) => {
        state.revertIsCheckPropsServiceSuccess = action.payload;
        state.revertIsCheckPropsServicePending = false;
      })
      .addCase(revertIsCheckedPropsService.rejected, (state, action) => {
        state.revertIsCheckPropsServiceFail = action.payload;
        state.revertIsCheckPropsServicePending = false;
      })

      //cases for add note
      .addCase(addNote.pending, (state) => {
        state.addNotePending = true;
      })
      .addCase(addNote.fulfilled, (state, action) => {
        state.addNoteSuccess = action.payload;
        state.addNotePending = false;
      })
      .addCase(addNote.rejected, (state, action) => {
        state.addNoteFail = action.payload;
        state.addNotePending = false;
      })
      //cases for delete note
      .addCase(deleteNote.pending, (state) => {
        state.deleteNotePending = true;
      })
      .addCase(deleteNote.fulfilled, (state, action) => {
        state.deleteNoteSuccess = action.payload;
        state.deleteNotePending = false;
      })
      .addCase(deleteNote.rejected, (state, action) => {
        state.deleteNoteFail = action.payload;
        state.deleteNotePending = false;
      });
    //cases for delete note
    // .addCase(editNote.pending, (state) => {
    //   state.editNotePending = true;
    // })
    // .addCase(editNote.fulfilled, (state, action) => {
    //   state.editNoteSuccess = action.payload;
    //   state.editNotePending = false;
    // })
    // .addCase(editNote.rejected, (state, action) => {
    //   state.editNoteFail = action.payload;
    //   state.editNotePending = false;
    // });
  },
});

export const {
  saveEmceeData,
  clearSuccess,
  setCompetitions,
  setCurrentComp,
  setCurrentEvent,
  setEntriesList,
  setCurrentSessionIndex,
} = emceeSlice.actions;

export default emceeSlice.reducer;
