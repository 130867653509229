export default function filterObjectsBetween(arrayOfObjects, item1, item2) {
  // If item1 is null or undefined, set it to the first item in the array
  if (item1 === null || item1 === undefined) {
    item1 = arrayOfObjects[0];
  }

  // Find the index of item1 in the array
  const index1 = arrayOfObjects.indexOf(item1);

  // Find the index of item2 in the array
  const index2 = arrayOfObjects.indexOf(item2);

  // If item1 or item2 is not found in the array, return an empty array
  if (index1 === -1 || index2 === -1) {
    return [];
  }

  // Determine the start and end indices for the filtered objects
  const startIndex = Math.min(index1, index2);
  const endIndex = Math.max(index1, index2);

  // Use slice to extract the objects between item1 and item2
  const filteredObjects = arrayOfObjects.slice(startIndex + 1, endIndex);

  return filteredObjects;
}
