import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNote, clearSuccess, deleteNote } from "redux/emcee/emceeSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const MessageNoteCard = ({
  compId,
  item,
  setActiveNoteIndex,
  activeNoteIndex,
}) => {
  const {
    addNotePending,
    addNoteSuccess,
    deleteNotePending,
    deleteNoteSuccess,
  } = useSelector((state) => state.emcee);

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  const [data, setData] = useState({
    compId: compId,
    id: item?.id,
    doc_id: item?.doc_id,
    note: {
      emcee_id: user?.uid,
      note: "",
      type: "Emcee Manager Note",
      note_uid: generateUID(),
    },
  });

  const dispatch = useDispatch();

  function handleSend() {
    if (data.note.note) {
      dispatch(addNote(data));
    }
  }

  const [deletingItem, setDeletingItem] = useState("");
  function handleDelete(delData) {
    setDeletingItem(delData);
    if (delData) {
      dispatch(
        deleteNote({
          ...data,
          note: delData,
          doc_id: item?.doc_id,
        })
      );
    }
  }

  useEffect(() => {
    if (addNoteSuccess?.success || deleteNoteSuccess?.success) {
      setTimeout(() => {
        dispatch(clearSuccess());
        setDeletingItem("");
        setData({
          ...data,
          note: { ...data?.note, note: "", note_uid: generateUID() },
        });
      }, 3000);
    }
  }, [addNoteSuccess, deleteNoteSuccess, data, dispatch]);

  const specialNotes = item?.notes?.filter((note) => {
    return note?.emcee_id === user?.uid && note?.type === "Emcee Manager Note";
  });

  const [showDelete, setShowDelete] = useState("");
  function handleHover(index) {
    setShowDelete(index);
  }
  function handleMouseOut() {
    setShowDelete("");
  }

  const textareaRef = useRef(null);

  useEffect(() => {
    if (activeNoteIndex === item?.id) {
      textareaRef?.current?.focus();
    }
  }, [activeNoteIndex, item]);

  return (
    <>
      <img
        alt="note"
        src={`${
          specialNotes?.length > 0
            ? "/images/NotePencil-green.png"
            : "/images/NotePencil.png"
        }`}
        className="w-5 h-5 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setActiveNoteIndex((prevActiveId) =>
            prevActiveId === item?.id ? null : item?.id
          ); // Toggle the active note ID when clicked
        }}
      />
      {activeNoteIndex === item?.id && (
        <div
          onClick={(e) => e.stopPropagation()}
          className="w-[250px] h-fit p-5 md:py-3 md:px-5 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg z-[999]"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
              setActiveNoteIndex(null);
            }}
            className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
          >
            <img
              alt="user"
              src="/images/icons8-close-50.png"
              className="w-3 h-3"
            />
          </div>
          {/* <h3 className="font-normal text-[.85rem]">Note</h3> */}
          <div className="w-full h-fit">
            {specialNotes?.map((item, index) => {
              return (
                <div
                  key={item?.note_uid}
                  onMouseOver={() => handleHover(index)}
                  onMouseOut={handleMouseOut}
                  className="relative"
                >
                  <div
                    key={item?.note_uid}
                    className={`w-full mb-1 p-3 text-[.9em] rounded-lg ${
                      (deleteNotePending || deleteNoteSuccess?.success) &&
                      deletingItem === item
                        ? "bg-red-400/20"
                        : "bg-white/10"
                    }`}
                  >
                    {deleteNotePending && deletingItem === item ? (
                      <p>Deleting...</p>
                    ) : (
                      <p
                        className={`${
                          showDelete ? "w-[85%]" : "w-full"
                        } whitespace-normal`}
                      >
                        {capitalizeFirstLetter(item?.note)}
                      </p>
                    )}
                    {deleteNoteSuccess?.success && deletingItem === item && (
                      <p>Deleted!</p>
                    )}
                  </div>
                  {showDelete === index && (
                    <img
                      alt="trash"
                      src="/images/trash.png"
                      className="w-5 h-5 cursor-pointer hover:scale-[1.2] absolute top-3 right-1"
                      onClick={() => handleDelete(item)}
                    />
                  )}
                </div>
              );
            })}
            {addNoteSuccess?.success ? (
              <div className="w-full p-2 border border-blue-500/70 text-[.9em] text-center">
                Message sent Successfully!
              </div>
            ) : (
              <form className="w-full h-full text-right">
                {specialNotes?.length > 0 && (
                  <h4 className="w-full text-center text-[.85em] mb-2">
                    Add another note
                  </h4>
                )}
                <p className="text-xs text-white/80">
                  characters: {100 - data?.note.note?.length}/100
                </p>
                <textarea
                  ref={textareaRef}
                  id="notes"
                  value={data?.note.note}
                  onChange={(e) => {
                    setData({
                      ...data,
                      note: { ...data.note, note: e.target.value },
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      handleSend(e);
                    }
                  }}
                  maxLength={100}
                  className={`w-full h-[80px] border outline-none text-white bg-inherit ${
                    data?.note.note?.length === 100
                      ? "border-red-400"
                      : "border-white/30"
                  }`}
                />
                {data?.note.note?.length === 100 && (
                  <p className="text-xs text-red-500 dark:text-red-400">
                    Max character length reached
                  </p>
                )}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleSend();
                  }}
                  disabled={addNotePending || !data.note.note}
                  className="px-4 py-1 rounded-md bg-blue-500 hover:bg-blue-400 text-[.75rem] flex gap-2 items-center"
                >
                  {addNotePending ? "Sending..." : "Send"}
                  {addNotePending && <ButtonLoadingSpinner />}
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(MessageNoteCard);
