/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PulseLoader } from "react-spinners";
import { editScore } from "redux/organizerScheduling/organizerSchedulingSlice";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const TabulatorTable = ({
  currentEntryScores,
  calculateAverageScore,
  categories,
  selectEntry,
  allDeductions,
}) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { getCategoryPending, editScorePending } = useSelector(
    (state) => state.organizerScheduling
  );

  const [rowData, setRowData] = useState([...currentEntryScores]);

  const [clicked, setClicked] = useState(Array(rowData?.length).fill(false));

  const handleClick = (index) => {
    const updatedClicked = [...clicked];
    updatedClicked[index] = true;
    setClicked(updatedClicked);
    setRowData([...currentEntryScores]);
  };

  const handleCancel = (index) => {
    const updatedClicked = [...clicked];
    updatedClicked[index] = false;
    setClicked(updatedClicked);
    setRowData([...currentEntryScores]);
  };

  const handleInputChange = (index, category, value) => {
    const updatedRowData = JSON.parse(JSON.stringify(rowData));
    updatedRowData[index][category] = value;
    setRowData(updatedRowData);
  };

  function calculateTotal(sliderValues) {
    const values = Object.values(sliderValues);
    const total = values.reduce((sum, value) => sum + parseFloat(value), 0);
    const totalFormatted = total.toFixed(2); // Format total to two decimal places
    return totalFormatted;
  }

  const dispatch = useDispatch();
  const handleSubmit = async (index) => {
    if (
      rowData[index]?.technique &&
      rowData[index]?.creativity &&
      rowData[index]?.staging &&
      rowData[index]?.execution &&
      rowData[index]?.performance
    ) {
      const newScores = {
        technique: rowData[index]?.technique,
        creativity: rowData[index]?.creativity,
        staging: rowData[index]?.staging,
        execution: rowData[index]?.execution,
        performance: rowData[index]?.performance,
      };
      dispatch(
        editScore({
          rowData,
          index,
          compId: user?.uid,
          entry_name: rowData[index]?.entry_name,
          doc_id: rowData[index]?.doc_id,
          event_name: rowData[index]?.event_name,
          judge_id: rowData[index]?.judge_id,
          total: calculateTotal(newScores),
        })
      );
      handleCancel(index);
    }
  };

  useEffect(() => {
    setRowData([...currentEntryScores]);
    if (rowData?.length > 0) {
      selectEntry(rowData[0]?.doc_id);
    }
  }, [editScorePending]);

  //to conditionally render the update button
  const renderSubmitButton = (index) => {
    const rowDataItem = rowData[index];
    const currentEntryScoresItem = currentEntryScores[index];

    const areValuesEqual = Object.keys(rowDataItem).every(
      (key) => rowDataItem[key] === currentEntryScoresItem[key]
    );

    if (!areValuesEqual) {
      return (
        <button
          onClick={() => handleSubmit(index)}
          className="px-3 py-[2px] text-white text-[.75rem] rounded-lg bg-[#94a4fd]/50 hover:bg-[#94a4fd]/80 leading-tight"
        >
          Update
        </button>
      );
    }
    return null;
  };

  const sum =
    [
      Number(calculateAverageScore(currentEntryScores, "technique")),
      Number(calculateAverageScore(currentEntryScores, "creativity")),
      Number(calculateAverageScore(currentEntryScores, "staging")),
      Number(calculateAverageScore(currentEntryScores, "execution")),
      Number(calculateAverageScore(currentEntryScores, "performance")),
    ]?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) -
    getDeduction();

  function getDeduction() {
    const lastCharacters = allDeductions?.map((str) => {
      if (str?.length > 0) {
        return Number(str?.slice(-1));
      } else {
        return "";
      }
    });

    const ded = lastCharacters?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return ded;
  }

  const { data: allJudges } = useQueryAllDocs("judges");

  function getJudgeName(id) {
    const judge = allJudges?.filter((x) => x?.uid === id)[0]?.first_name;
    return judge;
  }

  // console.log("currentEntryScores", currentEntryScores);
  return (
    <>
      {editScorePending || getCategoryPending ? (
        <div className="w-full h-[100px] flex justify-center items-center">
          <PulseLoader color="#94a4fd" size={10} />
        </div>
      ) : (
        <table className="min-w-[360px] w-full text-[.8em]">
          <thead className="text-white/40 text-left border-b border-white/30">
            <tr>
              <th></th>
              <th>Total</th>
              <th>T/F</th>
              <th>C/O</th>
              <th>S/D</th>
              <th>E/D</th>
              <th>P/E</th>
            </tr>
          </thead>
          <tbody className="text-white/80">
            <tr>
              <td>All</td>
              {/* <td>{calculateAverageScore(currentEntryScores, "total")}</td> */}
              <td>{sum?.toFixed(2)}</td>

              <td>{calculateAverageScore(currentEntryScores, "technique")}</td>
              <td>{calculateAverageScore(currentEntryScores, "creativity")}</td>
              <td>{calculateAverageScore(currentEntryScores, "staging")}</td>
              <td>{calculateAverageScore(currentEntryScores, "execution")}</td>
              <td>
                {calculateAverageScore(currentEntryScores, "performance")}
              </td>
            </tr>
            {currentEntryScores
              ?.sort((a, b) => {
                const nameA = getJudgeName(a?.judge_id)?.toLowerCase();
                const nameB = getJudgeName(b?.judge_id)?.toLowerCase();

                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              })
              ?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td className="whitespace-nowrap">
                        {capitalizeFirstLetter(getJudgeName(item?.judge_id))}
                      </td>
                      <td className="rounded-lg">
                        <p>{item?.total}</p>
                      </td>
                      <td
                        className="cursor-pointer hover:bg-[#94a4fd]/40 rounded-lg"
                        onClick={() => handleClick(index)}
                      >
                        {clicked[index] ? (
                          <input
                            type="text"
                            inputMode="numeric"
                            pattern="^(0|[1-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              if (e.target.value > 20) {
                                e.target.value = "20";
                              }
                            }}
                            value={rowData[index]?.technique}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "technique",
                                e.target.value
                              )
                            }
                            className="w-[50px] h-fit bg-[#282929]"
                          />
                        ) : (
                          <p>{item?.technique || "N/Q"}</p>
                        )}
                      </td>
                      <td
                        className="cursor-pointer hover:bg-[#94a4fd]/40 rounded-lg"
                        onClick={() => handleClick(index)}
                      >
                        {clicked[index] ? (
                          <input
                            type="text"
                            inputMode="numeric"
                            pattern="^(0|[1-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              if (e.target.value > 20) {
                                e.target.value = "20";
                              }
                            }}
                            value={rowData[index]?.creativity}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "creativity",
                                e.target.value
                              )
                            }
                            className="w-[50px] h-fit bg-[#282929]"
                          />
                        ) : (
                          <p>{item?.creativity}</p>
                        )}
                      </td>
                      <td
                        className="cursor-pointer hover:bg-[#94a4fd]/40 rounded-lg"
                        onClick={() => handleClick(index)}
                      >
                        {clicked[index] ? (
                          <input
                            type="text"
                            inputMode="numeric"
                            pattern="^(0|[1-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              if (e.target.value > 20) {
                                e.target.value = "20";
                              }
                            }}
                            value={rowData[index]?.staging}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "staging",
                                e.target.value
                              )
                            }
                            className="w-[50px] h-fit bg-[#282929]"
                          />
                        ) : (
                          <p>{item?.staging}</p>
                        )}
                      </td>
                      <td
                        className="cursor-pointer hover:bg-[#94a4fd]/40 rounded-lg"
                        onClick={() => handleClick(index)}
                      >
                        {clicked[index] ? (
                          <input
                            type="text"
                            inputMode="numeric"
                            pattern="^(0|[1-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              if (e.target.value > 20) {
                                e.target.value = "20";
                              }
                            }}
                            value={rowData[index]?.execution}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "execution",
                                e.target.value
                              )
                            }
                            className="w-[50px] h-fit bg-[#282929]"
                          />
                        ) : (
                          <p>{item?.execution}</p>
                        )}
                      </td>
                      <td
                        className="cursor-pointer hover:bg-[#94a4fd]/40 rounded-lg"
                        onClick={() => handleClick(index)}
                      >
                        {clicked[index] ? (
                          <input
                            type="text"
                            inputMode="numeric"
                            pattern="^(0|[1-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9.]/g,
                                ""
                              );
                              if (e.target.value > 20) {
                                e.target.value = "20";
                              }
                            }}
                            value={rowData[index]?.performance}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "performance",
                                e.target.value
                              )
                            }
                            className="w-[50px] h-fit bg-[#282929]"
                          />
                        ) : (
                          <p>{item?.performance}</p>
                        )}
                      </td>
                    </tr>
                    {clicked[index] && (
                      <tr>
                        <td>
                          <button
                            onClick={() => handleCancel(index)}
                            className="px-3 py-[2px] bg-white/50 hover:bg-white/80 text-[.75rem] rounded-lg text-black leading-tight"
                          >
                            Cancel
                          </button>
                        </td>
                        <td>{renderSubmitButton(index)}</td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
          </tbody>
        </table>
      )}
    </>
  );
};

export default TabulatorTable;
