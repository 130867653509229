import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleAddEvent } from "../../../redux/eventManagement/eventManagement";
import {
  toggleAddEntry,
  toggleViewDeleted,
  toggleAddCategory,
} from "redux/organizerScheduling/organizerSchedulingSlice";

const BreadCrumb = () => {
  const { addEvent } = useSelector((state) => state.eventManagement);
  const { addEntry, viewDeleted, addCategory } = useSelector(
    (state) => state.organizerScheduling
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  let currentPage = location.pathname;
  const { event_name, studio_id, presetUid } = useParams();

  return (
    <div className="text-[.85em]">
      {/* if current path is at event selection */}
      {!addEvent && currentPage === "/organizer/event-selection" && (
        <h1>Event Selection</h1>
      )}

      {/* if current path is at add event */}
      {addEvent && (
        <h1 className="flex gap-3 flex-wrap">
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => {
              navigate("/organizer/event-selection");
              dispatch(toggleAddEvent());
            }}
          >
            All Events
          </span>
          <span>/</span>
          <span>Add Event</span>
        </h1>
      )}

      {/* if current path is at event details */}
      {currentPage === `/organizer/event/${event_name?.replace(/ /g, "-")}` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span>Event: {event_name?.replace(/-/g, " ")}</span>
          </h1>
        )}

      {/* if current path is at competition settings */}
      {currentPage?.includes("competition-settings") && !addEvent && (
        <h1 className="flex gap-3 flex-wrap">
          <span>
            Competition Settings - Event: {event_name?.replace(/-/g, " ")}
          </span>
        </h1>
      )}

      {/* if current path is at global settings */}
      {currentPage?.includes("global-settings") && !addEvent && (
        <h1 className="flex gap-3 flex-wrap">
          <span>Global Settings</span>
        </h1>
      )}

      {/* if current path is at user management */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/user-management` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span>User Management Settings</span>
            <span>/</span>
            <span>Studio Access</span>
          </h1>
        )}

      {/* if current path is at studio owners */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/studio-owners` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span>User Management</span>
            <span>/</span>
            <span>Studio-owners</span>
          </h1>
        )}

      {/* if current path is at dancers */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/dancers` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span>User Management</span>
            <span>/</span>
            <span>Dancers</span>
          </h1>
        )}

      {/* if current path is at judges */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/judges` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span>User Management</span>
            <span>/</span>
            <span>Judges</span>
          </h1>
        )}

      {/* if current path is at emcees */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/all-emcees` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span>User Management</span>
            <span>/</span>
            <span>all-emcees</span>
          </h1>
        )}

      {/* if current path is at all-stage-managers */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/all-stage-managers` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span>User Management</span>
            <span>/</span>
            <span>stage managers</span>
          </h1>
        )}

      {/* if current path is at registrations */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/registrations` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Registrations</span>
          </h1>
        )}

      {/* if current path is at registrations dancers */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/registrations-dancers` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Registrations - Dancers</span>
          </h1>
        )}

      {/* if current path is at music */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/music` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Music</span>
          </h1>
        )}

      {/* if current path is at accounting overview */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/accounting-overview` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Accounting: Overview</span>
          </h1>
        )}

      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/accounting-overview/${studio_id}` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Accounting: Overview - Details</span>
          </h1>
        )}

      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/accounting-invoice` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Accounting: Overview</span>
          </h1>
        )}

      {/* if current path is at tabulator */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/tabulator` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Tabulator</span>
          </h1>
        )}

      {/* if current path is at award */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/award` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Award</span>
          </h1>
        )}

      {/* if current path is at schedule */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/schedule` &&
        !addEvent &&
        !addEntry &&
        !viewDeleted &&
        !addCategory && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Schedule</span>
          </h1>
        )}

      {/* if current path is at schedule */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/schedule-move-category` &&
        !addEvent &&
        !addEntry &&
        !viewDeleted &&
        !addCategory && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Schedule</span>
          </h1>
        )}

      {/* if current path is at schedule - add entry */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/schedule` &&
        addEntry && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                dispatch(toggleAddEntry());
                navigate(
                  `/organizer/event/${event_name.replace(/ /g, "-")}/schedule`
                );
              }}
            >
              schedule
            </span>
            <span>/</span>
            <span>Add Entry</span>
          </h1>
        )}

      {/* if current path is at schedule - view deleted */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/schedule` &&
        viewDeleted && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                dispatch(toggleViewDeleted());
                navigate(
                  `/organizer/event/${event_name.replace(/ /g, "-")}/schedule`
                );
              }}
            >
              Schedule
            </span>
            <span>/</span>
            <span>View Deleted</span>
          </h1>
        )}

      {/* if current path is at schedule - add category */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(/ /g, "-")}/schedule` &&
        addCategory && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                dispatch(toggleAddCategory());
                navigate(
                  `/organizer/event/${event_name.replace(/ /g, "-")}/schedule`
                );
              }}
            >
              Schedule
            </span>
            <span>/</span>
            <span>Add Category</span>
          </h1>
        )}

      {/* if current path is at session awards */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/report-session-awards` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Pre-sets</span>
          </h1>
        )}
      {/* if current path is at session awards */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/report-session-awards/${presetUid}` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Overall award - Details</span>
          </h1>
        )}

      {/* if current path is at session awards */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/report-overall-awards` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Overall awards</span>
          </h1>
        )}

      {/* if current path is at session awards */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/report-grand-awards` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Grand awards</span>
          </h1>
        )}

      {/* if current path is at custom reports */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/report-custom-reports` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Custom reports</span>
          </h1>
        )}

      {/* if current path is at award labels */}
      {currentPage ===
        `/organizer/event/${event_name?.replace(
          / /g,
          "-"
        )}/report-award-labels` &&
        !addEvent && (
          <h1 className="flex gap-3 flex-wrap">
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => navigate("/organizer/event-selection")}
            >
              Event Selection
            </span>
            <span>/</span>
            <span
              className="text-white/50 cursor-pointer"
              onClick={() => {
                navigate(`/organizer/event/${event_name.replace(/ /g, "-")}`);
              }}
            >
              Event: {event_name?.replace(/-/g, " ")}
            </span>
            <span>/</span>
            <span>Award labels</span>
          </h1>
        )}
    </div>
  );
};

export default BreadCrumb;
