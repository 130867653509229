import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const { openNotifsBoard } = useSelector((state) => state.danceStudio);

  const navigate = useNavigate();
  return (
    <footer
      className={`w-full pl-[30px] py-7 mt-16 flex flex-col-reverse gap-3 sm:flex-row justify-between items-center text-white/30 text-[.85rem] ${
        openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[30px]"
      }`}
    >
      <p>© 2023 ScoreBox</p>
      <ul className="flex gap-6 items-center">
        <li
          onClick={() => navigate("/studio/contact")}
          className="hover:text-white cursor-pointer"
        >
          Contact Us
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
