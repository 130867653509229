import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/arrow_back.png";
import ImgWrap from "../UI/ImgWrapper";
import Container from "components/UI/Container";
import { useDispatch } from "react-redux";
import { resetPsw } from "redux/auth/authSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { useSelector } from "react-redux";

export default function ForgotPsw() {
  const { auth } = useSelector((state) => state.persistedReducer);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [response, setResponse] = useState(null);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email.trim() === "") {
      setEmailError("Email is required");
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError("Invalid email");
      return;
    }

    try {
      dispatch(resetPsw(email)).then((res) => {
        setResponse(res?.payload);
        console.log("res", res);
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="flex flex-row flex-wrap h-[100vh]">
      <div className="lg:w-[40%] lg:flex hidden">
        {/* Left Components With Background Image */}
        <ImgWrap className="bg-[url('../public/Frame9.png')]" />
      </div>
      <div className="lg:w-[60%] w-full">
        {/* The forgot password right content is located here */}
        <Container className="flex flex-wrap">
          <div className="flex flex-row justify-between w-full">
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => {
                navigate("/");
                setResponse(null);
                setEmail("");
              }}
            >
              <img
                src={BackIcon}
                className="w-[9.73px] h-[16.5px] mr-[14px]"
                alt="backIcon"
              />
              <span className="text-base text-[#8692A6]">Back</span>
            </div>
          </div>

          <div className="lg:pl-[63px] md:pl-[63px] pt-[14%]">
            {response === null && (
              <>
                <h1 className="text-3xl font-bold text-[#000000] dark:text-white mb-7">
                  Forgot Password
                </h1>
                <p className="text-lg font-normal text-[#8692A6]">
                  Please enter your email to receive password reset information
                </p>
              </>
            )}
          </div>

          {response === null ? (
            <form
              onSubmit={handleSubmit}
              className="lg:ml-[63px] md:ml-[63px] flex flex-col mt-[33px] border-base lg:w-[60%] md:w-[60%]"
            >
              <div className="form-control w-full">
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={handleEmailChange}
                  className={`input input-bordered ${
                    emailError ? "border-red-500" : "border-[#959FB1]"
                  } w-full text-sm font-medium text-primary`}
                />
                {emailError && (
                  <p className="text-sm text-red-500">{emailError}</p>
                )}
              </div>

              <div className="items-center mt-10 form-control">
                <button
                  disabled={auth?.status === "pending"}
                  className="h-[3rem] bg-[#1565D8] hover:bg-[#1565D8]/70 rounded-[5px] text-white capitalize w-80 flex justify-between items-center px-4 text-center"
                  type="submit"
                >
                  <span className="w-full">
                    {auth?.status === "pending" ? "Processing..." : "Submit"}
                  </span>
                  {auth?.status === "pending" && <ButtonLoadingSpinner />}{" "}
                </button>
              </div>
            </form>
          ) : response?.success === true ? (
            <div className="lg:ml-[63px] md:ml-[63px] flex flex-col mt-[33px] border-base lg:w-[60%] md:w-[60%] pt-[14%]">
              <div className="w-full flex flex-col gap-3 text-[#8692A6] justify-center text-center">
                <h2 className="text-3xl font-bold text-[#000000] dark:text-white mb-2">
                  Password Reset Email Sent
                </h2>
                <img
                  alt="success"
                  src="/images/icons8-checkmark-64.png"
                  className="w-14 h-14 mx-auto"
                />
                <p>
                  A password reset link has been successfully sent to your email
                  address.
                </p>
                <p>
                  Please check your inbox and follow the instructions to reset
                  your password.
                </p>
                <p>
                  If you do not see it in your inbox, it may be in your junk
                  mail folder
                </p>
              </div>
              <div className="items-center mt-10 form-control">
                <button
                  onClick={() => {
                    navigate("/");
                    setResponse(null);
                    setEmail("");
                  }}
                  className="h-[3rem] bg-[#1565D8] hover:bg-[#1565D8]/70 rounded-[5px] text-white capitalize w-80 flex justify-between items-center px-4 text-center"
                >
                  <span className="w-full">Back To Home</span>
                </button>
              </div>
            </div>
          ) : (
            <div className="lg:ml-[63px] md:ml-[63px] flex flex-col mt-[33px] border-base lg:w-[60%] md:w-[60%] pt-[14%]">
              {!response?.message?.includes("auth/too-many-requests") ? (
                <div className="w-full flex flex-col gap-3 text-[#8692A6] justify-center text-center">
                  <h2 className="text-3xl font-bold text-[#000000] mb-2">
                    Email Not Found
                  </h2>
                  <p>
                    We're sorry, but the email address ({email}) you provided
                    was not found in our user records.
                  </p>
                  <p>
                    If you believe this is a mistake, please double-check the
                    email you entered or consider creating a new account.
                  </p>
                </div>
              ) : (
                <div className="w-full flex flex-col gap-3 text-[#8692A6] justify-center text-center">
                  <h2 className="text-3xl font-bold text-[#000000] mb-2">
                    Password Reset Unavailable
                  </h2>
                  <p>
                    We apologize for the inconvenience, but the password reset
                    service is currently unavailable due to a high volume of
                    requests.
                  </p>
                  <p>
                    Please wait a few minutes before attempting to reset your
                    password again. This short delay is in place to ensure the
                    security and stability of our systems.
                  </p>
                </div>
              )}
              {!response?.message?.includes("auth/too-many-requests") && (
                <div className="items-center mt-10 form-control">
                  <button
                    onClick={() => {
                      setResponse(null);
                      setEmail("");
                    }}
                    className="h-[3rem] bg-[#1565D8] hover:bg-[#1565D8]/70 rounded-[5px] text-white capitalize w-80 flex justify-between items-center px-4 text-center"
                  >
                    <span className="w-full">Enter correct email</span>
                  </button>
                </div>
              )}

              {response?.message?.includes("auth/too-many-requests") && (
                <div className="items-center mt-10 form-control">
                  <button
                    onClick={() => {
                      setResponse(null);
                      setEmail("");
                      navigate("/");
                    }}
                    className="h-[3rem] bg-[#1565D8] hover:bg-[#1565D8]/70 rounded-[5px] text-white capitalize w-80 flex justify-between items-center px-4 text-center"
                  >
                    <span className="w-full">Back to home</span>
                  </button>
                </div>
              )}
            </div>
          )}
        </Container>
      </div>
    </div>
  );
}
