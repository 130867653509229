import React from "react";
import { Image, PDFViewer } from "@react-pdf/renderer";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import moment from "moment";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import calculateEntryStartTime from "utils/calculateEntryStartTime";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import sumTimeValues from "utils/sumTimeValues";

const PrintSchedulePage = ({
  studioData,
  currentEvent,
  sortedCategories,
  allEntries,
  currentComp,
}) => {
  const startTime = moment(currentEvent?.days[0].startTime)
    .local()
    .format("MMM D");

  const endTime = moment(
    currentEvent?.days[currentEvent?.days?.length - 1]?.endTime
  )
    .local()
    .format("MMM D");

  const filteredCategoriesStu = sortedCategories
    ?.map((itm) => {
      const myEntries = allEntries?.filter(
        (entry) =>
          entry?.category_name === itm?.category_name &&
          entry?.studio_selected[0] === studioData?.studioName
      );

      return myEntries?.length > 0 && itm?.type === "category"
        ? itm
        : itm?.type !== "category" && myEntries?.length === 0
        ? itm
        : null;
    })
    ?.filter((x) => x !== null);

  const filteredCategoriesOrg = sortedCategories
    ?.map((itm) => {
      const myEntries = allEntries?.filter(
        (entry) => entry?.category_name === itm?.category_name
      );

      return myEntries?.length > 0 && itm?.type === "category"
        ? itm
        : itm?.type !== "category" && myEntries?.length === 0
        ? itm
        : null;
    })
    ?.filter((x) => x !== null);

  const filteredCategories = studioData
    ? filteredCategoriesStu
    : filteredCategoriesOrg;

  return (
    <>
      <PDFViewer style={{ width: "100%", minHeight: "100vh" }}>
        <Document>
          <Page size="A4" style={styles.page}>
            <Text style={styles.title}>
              {capitalizeFirstLetter(currentComp?.competitionName)}:{" "}
              {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
              {currentEvent?.year.split(" to ")[0]}
            </Text>
            <Text style={styles.title2}>
              {capitalizeFirstLetter(currentEvent?.venue)}
            </Text>
            <Text style={styles.title2}>
              {capitalizeFirstLetter(currentEvent?.address)}
            </Text>
            <Text style={styles.title2}>
              {capitalizeFirstLetter(currentEvent?.city)},{" "}
              {capitalizeFirstLetter(currentEvent?.state)}
            </Text>
            <Text style={styles.title2}>
              {currentEvent?.postal_code},{" "}
              {capitalizeFirstLetter(currentEvent?.country)}
            </Text>
            <Text style={styles.title2}>
              {currentEvent?.days?.length === 1
                ? `${startTime}, ${currentEvent?.year}`
                : `${startTime} - ${endTime} ${currentEvent?.year}`}
            </Text>
            <Text
              style={{
                textAlign: "start",
                marginBottom: "5px",
                marginTop: 15,
                fontSize: 12,
              }}
            >
              Legend
            </Text>
            <View style={styles.sectionComment}>
              <Text style={styles.legend}>
                <Image
                  style={{ width: "10px", height: "10px" }}
                  alt="arrow"
                  src={
                    studioData?.isDancerStarts
                      ? "/images/PersonGreen.png"
                      : "/images/Person.png"
                  }
                  className={`w-4 h-4`}
                />{" "}
                = Starts on/off stage
              </Text>
              <Text style={styles.legend}>
                <Image
                  style={{ width: "10px", height: "10px" }}
                  alt="arrow"
                  src={
                    studioData?.isCheckedProps
                      ? "/images/ClockGreen.png"
                      : "/images/Clock.png"
                  }
                  className={`w-4 h-4`}
                />{" "}
                = Prop / set piece
              </Text>

              <Text style={styles.legend}>
                <Image
                  style={{ width: "10px", height: "10px" }}
                  alt="runaround"
                  src={
                    studioData?.isRunAround
                      ? "/images/runaround-green.png"
                      : "/images/runaround.png"
                  }
                  className={`w-4 h-4`}
                />{" "}
                = Run Around
              </Text>
            </View>
            {filteredCategories?.map((category, index) => {
              const categoriesBeforeCurrent =
                filteredCategories?.slice(0, index) || [];

              const sessionsBeforeCurrent = categoriesBeforeCurrent?.filter(
                (item) => item?.break_type === "session"
              );

              const myEntriesStu = allEntries?.filter(
                (entry) =>
                  entry?.category_name === category?.category_name &&
                  entry?.studio_selected[0] === studioData?.studioName
              );

              const myEntriesOrg = allEntries?.filter(
                (entry) => entry?.category_name === category?.category_name
              );

              const myEntries = studioData ? myEntriesStu : myEntriesOrg;

              let myEntriesSorted = myEntries?.sort(
                (a, b) => a?.sort_id - b?.sort_id
              );

              function removeDuplicates(array) {
                const uniqueArray = [];
                const seenObjects = new Set();

                for (const obj of array) {
                  // Serialize the object to JSON to use it as a unique identifier
                  const serializedObj = JSON.stringify(obj);

                  if (!seenObjects.has(serializedObj)) {
                    seenObjects.add(serializedObj);
                    uniqueArray.push(obj);
                  }
                }

                return uniqueArray;
              }

              //============================================================================================new entry time calculation

              // to get all categories before current category
              //   const categoriesBeforeCurrent = filteredCategories?.slice(0, index) || [];

              const allBufferBeforeCurrent = categoriesBeforeCurrent?.filter(
                (cate) => cate?.type === "buffer"
              );
              const timeToAdd = allBufferBeforeCurrent?.reduce((sum, obj) => {
                if (obj.type === "buffer") {
                  const bufferValue = Number(obj.buffer);
                  if (!isNaN(bufferValue)) {
                    return sum + bufferValue;
                  }
                }
                return sum;
              }, 0);

              if (category?.type === "buffer") {
                return null; // Don't render "buffer" categories
              }

              function addMinutesToTime(inputTime, minutesToAdd) {
                const timeMoment = moment(inputTime, "h:mm A");
                const endTime = timeMoment.clone().add(minutesToAdd, "minutes");
                const formattedEndTime = endTime.format("h:mm A");
                return formattedEndTime;
              }

              const startTime = currentEvent?.days[0]?.startTime;
              const initialTime = moment(startTime).utc().format("h:mm A");

              //to get the entry count of all entries in routine
              function entryTime(itm) {
                const idx = findIndexByProperty(
                  removeDuplicates(allEntriesWithoutLocked),
                  "doc_id",
                  itm?.doc_id
                );
                if (idx === 0) {
                  return addMinutesToTime(initialTime, timeToAdd);
                } else {
                  const allEntriesBeforeCurrent = removeDuplicates(
                    allEntriesWithoutLocked
                  )?.slice(
                    0,
                    findIndexByProperty(
                      removeDuplicates(allEntriesWithoutLocked),
                      "doc_id",
                      itm?.doc_id
                    )
                  );

                  const allTimeBeforeCurrent = sumTimeValues(
                    allEntriesBeforeCurrent
                  );
                  const startTime = calculateEntryStartTime(
                    initialTime,
                    allTimeBeforeCurrent,
                    itm
                  );

                  return addMinutesToTime(startTime, timeToAdd);
                }
              }

              //===============================================================================to calc dynamic entry count of all entries in routine
              const categories = filteredCategories?.filter(
                (l) => l?.type === "category"
              );
              const allEntriesWithoutLocked = arrangeEntriesByCategories(
                categories,
                allEntries
              )?.filter((k) => k?.locked_entry === false);

              const allEntriesWithLocked = arrangeEntriesByCategories(
                categories,
                allEntries
              );

              function entryCount(itm) {
                const allEntriesBeforeLocked = allEntriesWithLocked
                  ?.slice(
                    0,
                    findIndexByProperty(
                      allEntriesWithLocked,
                      "doc_id",
                      itm?.doc_id
                    )
                  )
                  ?.filter((m) => m?.locked_entry === false);

                if (itm?.locked_entry) {
                  return `${
                    allEntriesBeforeLocked?.length === 0
                      ? 1
                      : allEntriesBeforeLocked?.length
                  }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
                } else {
                  return (
                    findIndexByProperty(
                      allEntriesWithoutLocked,
                      "doc_id",
                      itm?.doc_id
                    ) + 1
                  );
                }
              }
              return (
                <View key={index} style={styles.sectionTable}>
                  <View
                    style={
                      category?.break_type === "session"
                        ? { ...styles.tableRow1, backgroundColor: "#42125f" }
                        : { ...styles.tableRow1, backgroundColor: "#282929" }
                    }
                  >
                    <Text style={styles.tableHeader}>
                      {" "}
                      {capitalizeFirstLetter(category?.category_name)}{" "}
                      {category?.split &&
                        !category?.category_name?.includes("(") && (
                          <span className="">(A)</span>
                        )}
                      {category?.type === "category" && (
                        <Text className="">({myEntriesSorted?.length})</Text>
                      )}{" "}
                      {/*========================================================================= to dynamically render overalls or grand award tags */}
                      {category?.break_type?.includes("overalls_") && (
                        <Text className="">
                          {category?.break_type?.split("_")[1] === "overall"
                            ? "OVERALLS"
                            : category?.break_type?.split("_")[1] === "grand"
                            ? "GRAND OVERALLS"
                            : ""}
                        </Text>
                      )}
                      {/* to dynamically render sessions count */}
                      {category?.break_type === "session" && (
                        <Text className="">
                          {" "}
                          {sessionsBeforeCurrent?.length === 0
                            ? 1
                            : sessionsBeforeCurrent?.length + 1}
                        </Text>
                      )}
                    </Text>
                  </View>

                  {/* row */}
                  {myEntriesSorted?.map((row, uselessIdx) => {
                    const time = row?.locked_entry
                      ? "AFTER LOCK"
                      : entryTime(row);

                    return (
                      <View key={uselessIdx} style={styles.tableRow4}>
                        {studioData ? (
                          <Text style={styles.tableHeader2}>
                            {currentEvent?.locked
                              ? row?.locked_entry_time || time
                              : !currentEvent?.locked && "TBD"}{" "}
                          </Text>
                        ) : (
                          <Text style={styles.tableHeader2}>
                            {currentEvent?.locked
                              ? row?.locked_entry_time
                              : time}
                          </Text>
                        )}

                        {studioData ? (
                          <Text style={styles.tableHeader2}>
                            {currentEvent?.locked
                              ? row?.locked_entry_count || entryCount(row)
                              : !currentEvent?.locked && "TBD"}
                          </Text>
                        ) : (
                          <Text style={styles.tableHeader2}>
                            {currentEvent?.locked
                              ? row?.locked_entry_count
                              : entryCount(row)}
                          </Text>
                        )}

                        <Text style={styles.tableHeader2}>
                          {capitalizeFirstLetter(row?.entry_name)}
                        </Text>

                        <Text style={styles.tableHeader2}>
                          {row?.studio_letter}
                        </Text>

                        <View style={styles.tableHeader3}>
                          {row?.dancers_selected?.map(
                            (dancer, index, array) => (
                              <React.Fragment key={index}>
                                <Text>
                                  {capitalizeFirstLetter(dancer?.first_name)}{" "}
                                  {capitalizeFirstLetter(dancer?.last_name)}
                                </Text>
                                {index < array?.length - 1 && <Text>, </Text>}
                              </React.Fragment>
                            )
                          )}
                        </View>

                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: "#475569",
                            padding: "2px",
                            borderRadius: "4px",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <Image
                            style={{ width: "12px", height: "12px" }}
                            alt="arrow"
                            src={
                              row?.isDancerStarts
                                ? "/images/PersonGreen.png"
                                : "/images/Person.png"
                            }
                            className={`w-5 h-5 ${
                              category?.is_scratched && "opacity-50"
                            }`}
                          />
                          <Image
                            style={{ width: "12px", height: "12px" }}
                            alt="arrow"
                            src={
                              row?.isCheckedProps
                                ? "/images/ClockGreen.png"
                                : "/images/Clock.png"
                            }
                            className={`w-5 h-5 ${
                              category?.is_scratched ? "opacity-50" : ""
                            }`}
                          />
                          <Image
                            style={{ width: "12px", height: "12px" }}
                            alt="runaround"
                            src={
                              row?.isRunAround
                                ? "/images/runaround-green.png"
                                : "/images/runaround.png"
                            }
                            className={`w-5 h-5 ${
                              category?.is_scratched ? "opacity-50" : ""
                            }`}
                          />
                        </View>
                      </View>
                    );
                  })}
                </View>
              );
              // }
              // // If the category has no entries, don't render it
              // return null;
            })}
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default PrintSchedulePage;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },

  title2: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    textAlign: "center",
  },

  sectionTable: {
    marginTop: 0,
  },

  tableRow1: {
    flexDirection: "row",
    borderBottom: "1pt solid #000000",
    alignItems: "center",
    padding: 5,
    fontSize: 10,
    color: "white",
    marginTop: 5,
  },
  tableRow3: {
    flexDirection: "row",
    borderBottom: "1pt solid #000000",
    alignItems: "center",
    padding: 5,
    marginTop: 2,
  },
  tableRow4: {
    flexDirection: "row",
    borderBottom: "1pt solid #282929",
    alignItems: "center",
    padding: 5,
    fontSize: 10,
  },

  awards: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: 10,
  },
  awardsHeader: {
    flex: 1,
    textAlign: "start",
    letterSpacing: ".85px",
  },

  tableRow2: {
    flexDirection: "row",
    alignItems: "center",
  },

  tableHeader: {
    fontSize: 10,
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
    textTransform: "uppercase",
  },

  tableHeader2: {
    fontSize: 7,
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
    hyphenationFactor: 0, // Disable hyphenation
  },

  tableHeader3: {
    fontSize: 7,
    fontWeight: "bold",
    flex: 1,
    textAlign: "start",
    marginRight: 5,
    flexDirection: "row",
    flexWrap: "wrap",
  },

  sectionComment: {
    width: "200px",
    // minHeight: "100px",
    padding: "5px",
    marginBottom: 5,
    marginRight: "auto",
    border: "1px solid black",
  },
  legend: {
    fontSize: 9,
    letterSpacing: ".8px",
  },
});
