import { useSelector } from 'react-redux';
import Footer from './components/Footer';
import Header from './components/Header';
import LeftSidebar from './components/LeftSidebar';
import RightSidebar from './components/RightSidebar';
import AddEventForm from './components/AddEventForm';
import ScrollToTop from './ScrollToTop';
import StudioRegTable from './components/StudioRegTable';
import ChartCard from './components/ChartCard';
import ChartCardRoutine from './components/ChartCardRoutine';

import { useMemo, useState, useEffect } from 'react';
import Spinner from 'utils/Spinner';
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import { useQueryAllSubCollectionDocs } from 'components/hooks/useQueryAllSubCollectionDocs';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { useFetchScheduleDocs } from 'components/hooks/useFetchScheduleDocs';
import entriesData from './dummyData/eventDetailsEntries.json';

const EventDetails = () => {
  const { addEvent, currentEvent, openNotifsBoard } = useSelector(
    (state) => state.eventManagement
  );

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { user: userDetails } = useSelector(
    (state) => state.persistedReducer.auth
  );

  //to get realtime events details from firestore
  const { status, data: organizerComp } = useFirestoreQueryDoc(
    'competitions',
    user?.uid
  );
  //to memorize chart data keys from firestore - NOT from local persist
  const chartDataKeys = useMemo(() => {
    if (organizerComp) {
      return {
        entryTypes: organizerComp.entryTypes,
        eligibilityDivisions: organizerComp.eligibilityDivisions,
        competitionLevel: organizerComp.competitionLevel,
        danceGenres: organizerComp.danceGenres,
      };
    }
    return {
      entryTypes: [],
      eligibilityDivisions: [],
      competitionLevel: [],
      danceGenres: [],
    };
  }, [organizerComp]);

  //to retrieve the needed keys from chartDataKeys
  const dataArray = Object.values(chartDataKeys);

  //to get access to all organizer data - (will be moved to redux state later)
  const { data: dataOrg } = useFirestoreQueryDoc('organizers', user?.uid);
  const studioRegistrations = dataOrg?.studio_registrations;

  const studiosWithAccess = studioRegistrations?.filter((item1) =>
    currentEvent?.access?.some((item2) => item2?.studio_id === item1.studio_id)
  );

  const { data: allPayment } = useQueryAllSubCollectionDocs(
    'competitions',
    user?.uid,
    'payment_history'
  );

  // filter the payment based on the current event
  const paidStudios = allPayment?.filter(
    (each) => each?.event_uid === currentEvent?.event_uid
  );

  // function to calculate total revenue for paid studios
  const totalRevenue = paidStudios?.reduce((acc, payment) => {
    const paidAmount = parseInt(payment?.amount?.replace('$', ''));
    return acc + paidAmount;
  }, 0);

  // Format the total revenue as a currency string
  const formattedTotalRevenue = `$${totalRevenue?.toFixed(2)}`;

  //to get all entries
  const { data: eventEntries } = useFetchScheduleDocs(
    'competitions',
    user?.uid,
    'entries',
    'event_uid',
    currentEvent?.event_uid
  );

  function getAllDancers(arr) {
    const allSelected = [];

    if (arr && Array.isArray(arr)) {
      for (const obj of arr) {
        if (obj.dancers_selected && Array.isArray(obj.dancers_selected)) {
          allSelected.push(...obj.dancers_selected);
        }
      }
    }

    return allSelected;
  }

  const [taxRate, setTaxRate] = useState(0.0);
  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);

  useEffect(() => {
    if (organizerComp) {
      const { entryTypes, pricing, events } = organizerComp;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);

      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
    }
  }, [organizerComp, currentEvent]);

  const allEntryTypes = entryTypes?.concat(
    organizerComp?.specialEntryTypes?.tags || []
  );

  // ====================================================================================Calculate the totalAmount
  const newTotalAmount = allEntryTypes
    ?.reduce((total, row) => {
      let rowPricingOption;
      if (row?.entryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.entryTypeName)
        );
      } else if (row.specialEntryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.specialEntryTypeName)
        );
      }
      const rowEntries = eventEntries?.filter(
        (itm) => itm?.entryType === row?.entryTypeName
      );

      const flatAmount =
        rowEntries?.length > 0
          ? rowPricingOption?.[row?.entryTypeName] * rowEntries?.length
          : 0.0;

      const rowAmount =
        rowPricingOption?.selectedOption?.toLowerCase() === 'flat'
          ? Number(flatAmount)
          : getAllDancers(rowEntries)?.length *
            Number(rowPricingOption?.[row?.entryTypeName]);

      return total + (rowAmount || 0);
    }, 0)
    .toFixed(2);

  function calcTotalAfterTax(amount, percentage) {
    const result = (amount * percentage) / 100;
    return result.toFixed(2);
  }

  const totalAfterTax =
    Number(newTotalAmount) + Number(calcTotalAfterTax(newTotalAmount, taxRate));

  function calculateTotalPayments(arr) {
    if (!arr || !Array.isArray(arr)) {
      return 'Invalid input';
    }

    let totalAmount = 0;

    for (const obj of arr) {
      if (obj.amount) {
        totalAmount += parseFloat(obj.amount);
      }
    }

    return totalAmount.toFixed(2);
  }

  const amountOwing = totalAfterTax - calculateTotalPayments(paidStudios);
  // Format the total revenue as a currency string
  const formattedAmountOwing = `$${amountOwing?.toFixed(2)}`;

  // console.log('eventEntries....', eventEntries);

  const displayRows = entriesData?.map((itm, ind) => {
    const myEntriesNormal = eventEntries?.filter(
      (x) =>
        x?.age_division?.toLowerCase() === itm?.age_division &&
        x?.classification?.toLowerCase() === itm?.classification
    )?.length;

    // const myEntriesSpecial = eventEntries?.filter((y) =>
    //   y?.category_name?.includes(itm?.age_division)
    // )?.length;

    const myEntriesSpecial = eventEntries?.filter((y) => {
      if (Array.isArray(y?.category_name)) {
        // Check if y.category_name is an array before using includes
        return y.category_name.includes(itm?.age_division);
      } else {
        // Handle the case where y.category_name is not an array
        return false;
      }
    })?.length;

    return (
      <tr
        key={ind}
        className="border-b border-white/10 whitespace-nowrap hover:bg-white/20"
      >
        <td>{capitalizeFirstLetter(itm?.age_division)}</td>
        <td>{capitalizeFirstLetter(itm?.classification) || '-'}</td>
        <td>{itm?.type === 'special' ? myEntriesSpecial : myEntriesNormal}</td>
      </tr>
    );
  });

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name)}{' '}
                {currentEvent?.year.split(' to ')[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full min-h-[400px] text-white/80">
              <div className="w-full flex md:flex-row flex-col gap-6">
                <div className="w-full md:w-1/2 min-h-[250px] rounded-xl grid grid-cols-2 gap-6">
                  <div className="text-[#1c1c1c] bg-[#E3F5FF] rounded-xl p-3 sm:p-4 lg:p-[10%]">
                    <p className="text-[.75rem] font-bold mb-4">
                      Studio Registrations
                    </p>
                    <div className="flex flex-wrap justify-between items-center gap-2">
                      <div className="text-[1.45rem] font-bold">
                        {status === 'loading' ? (
                          <Spinner />
                        ) : studiosWithAccess ? (
                          studiosWithAccess?.length
                        ) : (
                          0
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-[#1c1c1c] bg-[#F7F9FB] rounded-xl p-3 sm:p-4 lg:p-[10%]">
                    <p className="text-[.75rem] font-bold mb-4">Entries</p>
                    <div className="flex flex-wrap justify-between items-center gap-2">
                      <div className="text-[1.45rem] font-bold">
                        {status === 'loading' ? (
                          <Spinner />
                        ) : (
                          eventEntries?.length
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-[#1c1c1c] bg-[#F7F9FB] rounded-xl p-3 sm:p-4 lg:p-[10%]">
                    <p className="text-[.75rem] font-bold mb-4">Revenue</p>
                    <div className="flex flex-col sm:flex-row flex-wrap justify-between sm:items-center gap-2">
                      {/* <p className="text-[1.45rem] font-bold">
                        {formattedTotalRevenue}
                      </p> */}
                      <div className="text-[1.45rem] font-bold">
                        {status === 'loading' ? (
                          <Spinner />
                        ) : (
                          !isNaN(totalRevenue) && formattedTotalRevenue
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-[#1c1c1c] bg-[#E5ECF6] rounded-xl p-3 sm:p-4 lg:p-[10%]">
                    <p className="text-[.75rem] font-bold mb-4">Owing</p>
                    <div className="flex flex-wrap justify-between items-center gap-2">
                      <div className="text-[1.45rem] font-bold">
                        {status === 'loading' ? (
                          <Spinner />
                        ) : (
                          !isNaN(amountOwing) && formattedAmountOwing
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full md:w-1/2 min-h-[250px] bg-[#282929] rounded-xl flex flex-col gap-5 p-7">
                  <h2 className="font-bold">Progress</h2>
                  <p>
                    Registration:{' '}
                    <span className="font-bold">
                      {/* {!currentEvent?.locked ? "Open" : "Closed"} */}
                      {!currentEvent?.locked ? 'Unlocked' : 'Locked'}
                    </span>
                  </p>
                  <p>
                    Schedule:{' '}
                    <span className="font-bold">
                      {' '}
                      {/* {currentEvent?.locked ? "Published" : "Unpublished"} */}
                      {currentEvent?.locked ? 'Available' : 'Unavailable'}
                    </span>
                  </p>
                  <p>
                    Music:{' '}
                    <span className="font-bold">
                      {' '}
                      {currentEvent?.isMusicDownload
                        ? 'Downloaded'
                        : 'Not Downloaded'}
                    </span>
                  </p>
                </div>
              </div>

              <div className="w-full min-h-[300px] flex md:flex-row flex-col gap-6 mt-6">
                <div className="w-full min-h-[250px] md:min-h-[300px] md:w-3/4 bg-[#282929] rounded-xl flex flex-col gap-5 p-5 md:p-[2%]">
                  <h2 className="font-bold">Studio Registrations</h2>
                  <div className="w-full h-full overflow-auto flex flex-col">
                    {/* studio registrations table */}
                    {studiosWithAccess?.length > 0 ? (
                      <StudioRegTable
                        studioRegistrations={studiosWithAccess}
                        currentEvent={currentEvent}
                      />
                    ) : (
                      <div className="w-full min-h-[200px] flex justify-center items-center text-white/50 border border-white/10 rounded-lg">
                        No studios registered yet..
                      </div>
                    )}

                    {studiosWithAccess?.length > 0 && (
                      <div className="text-[.75rem] text-white/40 mt-auto">
                        Scroll horizontally to view more, if needed
                      </div>
                    )}
                  </div>
                </div>
                {studiosWithAccess?.length > 0 ? (
                  <ChartCardRoutine
                    userDetails={userDetails}
                    currentEvent={currentEvent}
                  />
                ) : (
                  <div className="w-full min-h-[200px] flex justify-center items-center text-white/50 border border-white/10 rounded-lg">
                    No studios registered yet..
                  </div>
                )}
              </div>

              <div className="w-full min-h-[300px] flex md:flex-row flex-col gap-6 mt-6">
                {dataArray?.map((item, index) => {
                  return (
                    <ChartCard
                      item={item}
                      key={index}
                      index={index}
                      dataArray={dataArray}
                      extractedEntries={eventEntries}
                      userDetails={userDetails}
                    />
                  );
                })}
              </div>

              <div className="w-full md:w-[75%] overflow-x-auto mt-6 bg-[#282929] rounded-lg p-4">
                <table className="min-w-[300px] w-full text-[.85rem] table2">
                  <thead className="text-white/40 text-left border-b border-white/30">
                    <tr className="whitespace-nowrap">
                      <th>Age Division</th>
                      <th>Classification</th>
                      <th># Entries</th>
                    </tr>
                  </thead>
                  <tbody className="text-white/80">{displayRows}</tbody>
                </table>
              </div>
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>Add Event</h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddEventForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default EventDetails;
