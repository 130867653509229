/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  clearSuccess,
  submitOverallAward,
} from "redux/eventManagement/eventManagement";
import { toggleAddOverallsAwards } from "redux/organizerScheduling/organizerSchedulingSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const AddOverallsAwardForm = ({
  competitionLevel,
  entryTypes,
  danceGenres,
  scheduling,
}) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { submitOverallAwardPending, submitOverallAwardSuccess } = useSelector(
    (state) => state.eventManagement
  );

  const { data } = useFirestoreQueryDoc("organizers", user?.uid);
  let studios = data?.studio_registrations;

  const trueGenreKeys = Object.keys(danceGenres)
    .filter((key) => danceGenres[key] === true)
    .map((item) => item.toLowerCase());

  const dispatch = useDispatch();

  const timestamp = new Date().getTime();

  function formatTimeStamp(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedTime = `${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }, ${month} ${day}, ${year}`;
    return formattedTime;
  }

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  const [formData, setFormData] = useState({
    compId: user.uid,
    award_name: "",
    awardsNumber: 0,
    // eligible: 0,
    classification: [],
    entryType: [],
    genre: [],
    min_age: 0,
    max_age: 0,
    special_tags: [],
    studio_selected: [],
    timestamp: formatTimeStamp(timestamp),
    award_uid: generateUID(),
  });

  // console.log("formData", formData);

  function handleFormChange(event) {
    const { id, value } = event.target;

    setValidationError(false);
    setFormData((prev) => {
      return {
        ...prev,
        [id]: value,
      };
    });
  }

  const tags = scheduling?.customTags;

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const updatedspecial_tags = checked
      ? [...formData.special_tags, value]
      : formData.special_tags.filter((checkbox) => checkbox !== value);
    setFormData({ ...formData, special_tags: updatedspecial_tags });
  };

  //==============================================================to manage selecting and removing studios
  const [selectedStudios, setSelectedStudios] = useState([]);

  const [openStudios, setOpenStudios] = useState(false);
  function toggleOpenStudios() {
    setValidationError(false);
    setOpenStudios((prev) => !prev);
  }

  const handleStudioChange = (event) => {
    setSelectedStudios([event.target.value]);
  };

  function handleRemoveStudio() {
    setSelectedStudios([]);
  }

  //================================================================to manage selecting and removing classification
  const [selectedClassification, setSelectedClassification] = useState([]);

  const [openClassification, setOpenClassification] = useState(false);
  function toggleOpenClassification() {
    setValidationError(false);
    setOpenClassification((prev) => !prev);
  }

  const handleClassificationChange = (event) => {
    const valueToAdd = event.target.value;

    // Check if the selectedClassification includes "ALL" and the new value is not "ALL"
    if (selectedClassification.includes("ALL") && valueToAdd !== "ALL") {
      setSelectedClassification((prev) =>
        prev.filter((item) => item !== "ALL")
      );
    }

    // Check if the selectedClassification doesn't include "ALL" and the new value is "ALL"
    if (!selectedClassification.includes("ALL") && valueToAdd === "ALL") {
      setSelectedClassification(["ALL"]);
    }

    // Check if the valueToAdd is not already in the selectedClassification array
    if (!selectedClassification.includes(valueToAdd) && valueToAdd !== "ALL") {
      setSelectedClassification((prev) => [...prev, valueToAdd]);
    }
  };

  function handleRemoveClassification(item) {
    const newArr = selectedClassification?.filter((level) => level !== item);
    setSelectedClassification(newArr);
  }

  //================================================================to manage selecting and removing EntryType
  const [selectedEntryType, setSelectedEntryType] = useState([]);

  const [openEntryType, setOpenEntryType] = useState(false);
  function toggleOpenEntryType() {
    setValidationError(false);
    setOpenEntryType((prev) => !prev);
  }

  const handleEntryTypeChange = (event) => {
    const valueToAdd = event.target.value;
    // Check if the selectedClassification includes "ALL" and the new value is not "ALL"
    if (selectedEntryType.includes("ALL") && valueToAdd !== "ALL") {
      setSelectedEntryType((prev) => prev.filter((item) => item !== "ALL"));
    }

    // Check if the selectedEntryType doesn't include "ALL" and the new value is "ALL"
    if (!selectedEntryType.includes("ALL") && valueToAdd === "ALL") {
      setSelectedEntryType(["ALL"]);
    }

    // Check if the valueToAdd is not already in the selectedEntryType array
    if (!selectedEntryType.includes(valueToAdd) && valueToAdd !== "ALL") {
      setSelectedEntryType((prev) => [...prev, valueToAdd]);
    }
  };

  function handleRemoveEntryType(item) {
    const newArr = selectedEntryType?.filter((level) => level !== item);
    setSelectedEntryType(newArr);
  }

  //================================================================to manage selecting and removing genre
  const [selectedGenre, setSelectedGenre] = useState([]);

  const [openGenre, setOpenGenre] = useState(false);
  function toggleOpenGenre() {
    setValidationError(false);
    setOpenGenre((prev) => !prev);
  }

  const handleGenreChange = (event) => {
    const valueToAdd = event.target.value;
    // Check if the selectedClassification includes "ALL" and the new value is not "ALL"
    if (selectedGenre.includes("ALL") && valueToAdd !== "ALL") {
      setSelectedGenre((prev) => prev.filter((item) => item !== "ALL"));
    }

    // Check if the selectedGenre doesn't include "ALL" and the new value is "ALL"
    if (!selectedGenre.includes("ALL") && valueToAdd === "ALL") {
      setSelectedGenre(["ALL"]);
    }

    // Check if the valueToAdd is not already in the selectedGenre array
    if (!selectedGenre.includes(valueToAdd) && valueToAdd !== "ALL") {
      setSelectedGenre((prev) => [...prev, valueToAdd]);
    }
  };

  function handleRemoveGenre(item) {
    const newArr = selectedGenre?.filter((level) => level !== item);
    setSelectedGenre(newArr);
  }

  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        studio_selected: selectedStudios,
        classification: selectedClassification,
        entryType: selectedEntryType,
        genre: selectedGenre,
      };
    });
  }, [
    selectedStudios,
    selectedClassification,
    selectedEntryType,
    selectedGenre,
  ]);

  //to handle form submit
  const [validationError, setValidationError] = useState(false);
  function handleSubmit(e) {
    e.preventDefault();

    if (
      formData?.award_name &&
      formData?.classification &&
      formData?.entryType &&
      formData?.awardsNumber &&
      // formData?.eligible &&
      formData.studio_selected?.length > 0 &&
      formData?.min_age &&
      formData?.max_age
    ) {
      dispatch(submitOverallAward(formData));
    } else {
      setValidationError(true);
    }
  }

  useEffect(() => {
    if (submitOverallAwardSuccess?.success) {
      setTimeout(() => {
        dispatch(clearSuccess());
        dispatch(toggleAddOverallsAwards());
      }, 2000);
    }
  }, [submitOverallAwardSuccess]);
  return (
    <>
      <form className="">
        <div className="w-full sm:w-[500px] lg:w-[60%] min-h-[600px] bg-[#282929] rounded-xl p-4 sm:p-7 mx-auto flex flex-col gap-8">
          {/* Heading */}
          <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-3">
            <h2>Add Overall Award</h2>
          </div>
          <div>
            <div
              className={`w-full p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.award_name &&
                "border border-red-500/70"
              }`}
            >
              <label
                htmlFor="award_name"
                className="text-[.75rem] text-white/40"
              >
                Award Name
              </label>
              <input
                type="text"
                id="award_name"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white placeholder:text-white/50"
                placeholder="E.g. Competitive/Elite Service"
              />
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-4">
            <div
              className={`w-full h-fit md:w-1/2 px-3 py-4 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                formData?.classification?.length === 0 &&
                "border border-red-500/70"
              }`}
            >
              <div className="flex gap-2 relative">
                <label
                  htmlFor="classification"
                  className="w-full px-1 py-1 text-[.75rem] text-white/40 cursor-pointer rounded-md"
                  onClick={toggleOpenClassification}
                >
                  Classification
                </label>
                {openClassification && (
                  <select
                    id="classification"
                    onChange={handleClassificationChange}
                    className="w-full p-2 bg-[#0c0c0d] outline-none text-white/80 absolute left-[-10px] top-10 rounded-md border border-white/50"
                    multiple
                  >
                    <option
                      className="hover:bg-[#94a4fd]/50 cursor-pointer"
                      value="ALL"
                      onClick={toggleOpenClassification}
                    >
                      All
                    </option>
                    {competitionLevel?.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="hover:bg-[#94a4fd]/50 cursor-pointer"
                          value={item?.levelName}
                          onClick={toggleOpenClassification}
                        >
                          {item?.levelName}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>

              {selectedClassification?.length > 0 && (
                <div className="w-full mt-2 border-t border-white/20 pt-2">
                  <ul className="flex gap-2 flex-wrap">
                    {selectedClassification?.map((item, index) => (
                      <li
                        className="px-2 rounded-md bg-[#94a4fd]/30 text-[.85rem] flex gap-2 items-center text-white/80"
                        key={index}
                      >
                        <span>{capitalizeFirstLetter(item)}</span>
                        <img
                          className="w-3 h-3 cursor-pointer"
                          alt="arrow"
                          src="/images/icons8-close-white-50.png"
                          onClick={() => handleRemoveClassification(item)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div
              className={`w-full h-fit md:w-1/2 px-3 py-4 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                formData?.entryType?.length === 0 &&
                "border border-red-500/70"
              }`}
            >
              <div className="flex gap-2 relative">
                <label
                  htmlFor="entryType"
                  className="w-full px-1 py-1 text-[.75rem] text-white/40 cursor-pointer rounded-md"
                  onClick={toggleOpenEntryType}
                >
                  EntryType
                </label>
                {openEntryType && (
                  <select
                    id="entryType"
                    onChange={handleEntryTypeChange}
                    className="w-full p-2 bg-[#0c0c0d] outline-none text-white/80 absolute left-[-10px] top-10 rounded-md border border-white/50 z-[2]"
                    multiple
                  >
                    <option
                      className="hover:bg-[#94a4fd]/50 cursor-pointer"
                      value="ALL"
                      onClick={toggleOpenEntryType}
                    >
                      All
                    </option>
                    {entryTypes?.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="hover:bg-[#94a4fd]/50 cursor-pointer"
                          value={item?.entryTypeName}
                          onClick={toggleOpenEntryType}
                        >
                          {item?.entryTypeName}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>

              {selectedEntryType?.length > 0 && (
                <div className="w-full mt-2 border-t border-white/20 pt-2">
                  <ul className="flex gap-2 flex-wrap">
                    {selectedEntryType?.map((item, index) => (
                      <li
                        className="px-2 rounded-md bg-[#94a4fd]/30 text-[.85rem] flex gap-2 items-center text-white/80"
                        key={index}
                      >
                        <span>{capitalizeFirstLetter(item)}</span>
                        <img
                          className="w-3 h-3 cursor-pointer"
                          alt="arrow"
                          src="/images/icons8-close-white-50.png"
                          onClick={() => handleRemoveEntryType(item)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-4">
            <div className="w-full md:w-1/2 flex gap-3">
              <div
                className={`w-[48%] h-fit p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                  validationError &&
                  !formData?.min_age &&
                  "border border-red-500/70"
                }`}
              >
                <label
                  htmlFor="min_age"
                  className="text-[.75rem] text-white/40"
                >
                  Min. Age
                </label>
                <input
                  type="number"
                  id="min_age"
                  onChange={handleFormChange}
                  className="bg-[#0c0c0d] outline-none text-white"
                  placeholder=""
                />
              </div>
              <div
                className={`w-[48%] h-fit p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                  validationError &&
                  !formData?.max_age &&
                  "border border-red-500/70"
                }`}
              >
                <label
                  htmlFor="max_age"
                  className="text-[.75rem] text-white/40"
                >
                  Max. Age
                </label>
                <input
                  type="number"
                  id="max_age"
                  onChange={handleFormChange}
                  className="bg-[#0c0c0d] outline-none text-white"
                  placeholder=""
                />
              </div>
            </div>

            <div className="w-full  md:w-1/2 p-3 rounded-xl bg-[#0c0c0d]">
              <p className="text-[.75rem] text-white/40">Special tags</p>
              <div
                className={`checkbox-container flex gap-5 mt-4 flex-wrap items-center checkbox-container`}
              >
                {tags?.map((value, index) => (
                  <div
                    key={index}
                    className="text-white/60 text-[.75em] flex items-center"
                  >
                    <input
                      type="checkbox"
                      name="checkboxes"
                      className="cursor-pointer"
                      value={value?.customTags}
                      checked={formData.special_tags.includes(
                        value?.customTags
                      )}
                      onChange={handleCheckboxChange}
                    />
                    {value?.customTags}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-4">
            <div
              className={`w-full h-fit md:w-1/2 px-3 py-4 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                formData?.genre?.length === 0 &&
                "border border-red-500/70"
              }`}
            >
              <div className="flex gap-2 relative">
                <label
                  htmlFor="genre"
                  className="w-full px-1 py-1 text-[.75rem] text-white/40 cursor-pointer rounded-md"
                  onClick={toggleOpenGenre}
                >
                  Genre
                </label>
                {openGenre && (
                  <select
                    id="genre"
                    onChange={handleGenreChange}
                    className="w-full p-2 bg-[#0c0c0d] outline-none text-white/80 absolute left-[-10px] top-10 rounded-md border border-white/50 z-[2]"
                    multiple
                  >
                    <option
                      className="hover:bg-[#94a4fd]/50 cursor-pointer"
                      value="ALL"
                      onClick={toggleOpenGenre}
                    >
                      All
                    </option>
                    {trueGenreKeys?.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="hover:bg-[#94a4fd]/50 cursor-pointer"
                          value={item}
                          onClick={toggleOpenGenre}
                        >
                          {item}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>

              {selectedGenre?.length > 0 && (
                <div className="w-full mt-2 border-t border-white/20 pt-2">
                  <ul className="flex gap-2 flex-wrap">
                    {selectedGenre?.map((item, index) => (
                      <li
                        className="px-2 rounded-md bg-[#94a4fd]/30 text-[.85rem] flex gap-2 items-center text-white/80"
                        key={index}
                      >
                        <span>{capitalizeFirstLetter(item)}</span>
                        <img
                          className="w-3 h-3 cursor-pointer"
                          alt="arrow"
                          src="/images/icons8-close-white-50.png"
                          onClick={() => handleRemoveGenre(item)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div
              className={`w-full h-fit lg:w-1/2 lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.awardsNumber &&
                "border border-red-500/70"
              }`}
            >
              <label
                htmlFor="awardsNumber"
                className="text-[.75rem] text-white/40"
              >
                Number of Awards
              </label>
              <input
                type="number"
                id="awardsNumber"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. 5"
              />
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-4">
            <div
              className={`w-full h-fit md:w-1/2 px-3 py-4 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                formData?.studio_selected?.length === 0 &&
                "border border-red-500/70"
              }`}
            >
              <div className="flex gap-2 relative">
                <label
                  htmlFor="select_studios"
                  className="w-full px-1 py-1 text-[.75rem] text-white/40 cursor-pointer rounded-md"
                  onClick={toggleOpenStudios}
                >
                  Select Studio
                </label>
                {openStudios && (
                  <select
                    id="select_studios"
                    onChange={handleStudioChange}
                    className="w-2/3 p-2 bg-[#0c0c0d] outline-none text-white/80 absolute left-[-10px] top-10 rounded-md border border-white/50"
                    multiple
                  >
                    <option
                      className="hover:bg-[#94a4fd]/50 cursor-pointer"
                      value="ALL"
                      onClick={toggleOpenStudios}
                    >
                      All
                    </option>
                    {studios?.map((item, index) => {
                      return (
                        <option
                          key={index}
                          className="hover:bg-[#94a4fd]/50 cursor-pointer"
                          value={item?.studio_name}
                          onClick={toggleOpenStudios}
                        >
                          {item?.studio_name}
                        </option>
                      );
                    })}
                  </select>
                )}
              </div>

              {selectedStudios?.length > 0 && (
                <div className="w-full mt-2 border-t border-white/20 pt-2">
                  <ul className="flex gap-2 flex-wrap">
                    {selectedStudios?.map((item, index) => (
                      <li
                        className="px-2 rounded-md bg-[#94a4fd]/30 text-[.85rem] flex gap-2 items-center text-white/80"
                        key={index}
                      >
                        <span>{item}</span>
                        <img
                          className="w-3 h-3 cursor-pointer"
                          alt="arrow"
                          src="/images/icons8-close-white-50.png"
                          onClick={() => handleRemoveStudio(item)}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            {/* <div
              className={`w-full h-fit lg:w-1/2 lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.eligible &&
                'border border-red-500/70'
              }`}
            >
              <label htmlFor="eligible" className="text-[.75rem] text-white/40">
                Number of Eligible
              </label>
              <input
                type="number"
                id="eligible"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. 5"
              />
            </div> */}
          </div>
          <div>
            {validationError && (
              <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-xl">
                Please fill all required fields!
              </div>
            )}
          </div>
        </div>
        <div className="w-full sm:w-[500px] lg:w-[60%] mx-auto mt-7 flex gap-4 justify-end">
          <button
            disabled={submitOverallAwardPending}
            onClick={() => dispatch(toggleAddOverallsAwards())}
            className="text-[.85rem] px-5 py-1 rounded-lg bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
          >
            Discard
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            disabled={submitOverallAwardPending}
            className="text-[.9rem] px-5 py-1 rounded-lg bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] flex justify-between gap-2 items-center whitespace-nowrap"
          >
            {submitOverallAwardPending ? "Creating..." : "Create Award"}
            {submitOverallAwardPending && <ButtonLoadingSpinner />}
          </button>
        </div>
      </form>

      {submitOverallAwardSuccess?.success && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white text-center">
              Overall Award Added Successfully!{" "}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AddOverallsAwardForm;
