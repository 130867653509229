import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import React from "react";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import removeDuplicates from "utils/removeDuplicates";

const GrandAwardEntryAwards = ({
  idx,
  item,
  entryCount,
  category,
  calculatePosition,
  calculateTotalScore,
  myEntries,
  currentEvent,
  allScores,
  setShowNames,
  showNames,
  entriesToBeAwarded,
  handleAnnounce,
}) => {
  // const myScore = allScores?.filter((x) => x?.doc_id === item?.doc_id);
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: userData } = useFirestoreQueryDoc("emcees", user?.uid);

  //========================================================================to handle tied positions/scores
  function avgTechnique(entry) {
    const myScore = allScores?.filter((x) => x?.doc_id === entry?.doc_id);

    const totalTechnique = myScore?.reduce(
      (acc, item) => acc + Number(item.technique),
      0
    );
    const averageTechnique = totalTechnique / myScore?.length;
    return Number(averageTechnique).toFixed(2);
  }

  const otherScores = entriesToBeAwarded?.filter(
    (y) =>
      calculateTotalScore(y) === calculateTotalScore(item) &&
      y?.entry_name !== item?.entry_name
  );

  const scoresWithCurrent = entriesToBeAwarded?.filter(
    (y) => calculateTotalScore(y) === calculateTotalScore(item)
  );

  function getTiedPositionArray() {
    let tiedScores = [];
    scoresWithCurrent?.forEach((x) => {
      return tiedScores?.push(Number(avgTechnique(x))?.toFixed(2));
    });

    return tiedScores?.sort((a, b) => b - a);
  }

  const tiedTechnique = getTiedPositionArray()?.filter(
    (z) => z === avgTechnique(item)
  );

  const myTiedIndex = getTiedPositionArray()?.findIndex(
    (y) => y === avgTechnique(item)
  );

  const myTiedPosition = () => {
    const pos = Number(
      calculatePosition(item, entriesToBeAwarded)?.split("")[0]
    );
    if (myTiedIndex + pos === 1) {
      return "1st";
    } else if (myTiedIndex + pos === 2) {
      return "2nd";
    } else if (myTiedIndex + pos === 3) {
      return "3rd";
    } else if (myTiedIndex + pos > 3) {
      return `${myTiedIndex + pos}th`;
    }
  };

  const scoredEntries = myEntries?.filter(
    (x) => !isNaN(calculateTotalScore(x))
  );

  const entriesBeforeCurrent =
    scoredEntries
      ?.sort((a, b) => calculateTotalScore(b) - calculateTotalScore(a))
      ?.slice(0, scoredEntries?.indexOf(item)) || [];

  function getPrevTrueTied() {
    let arr = [];
    entriesBeforeCurrent?.forEach((entry) => {
      const dupScores = entriesBeforeCurrent?.filter(
        (y) => calculateTotalScore(y) === calculateTotalScore(entry)
      );
      function getTiedPositionArray() {
        let tiedScores = [];
        dupScores?.forEach((x) => {
          return tiedScores?.push(Number(avgTechnique(x))?.toFixed(2));
        });

        return tiedScores?.sort((a, b) => b - a);
      }

      const tiedTech = getTiedPositionArray()?.filter(
        (z) => z === avgTechnique(entry)
      );

      if (dupScores?.length > 1 && !(tiedTech?.length <= 1)) {
        arr.push(dupScores);
      }
    });
    return removeDuplicates(arr);
  }

  const positionAfterTrueTiesRaw =
    Number(calculatePosition(item, scoredEntries)?.slice(0, -2)) -
    (getPrevTrueTied()[0]?.length || 0) +
    1;

  const positionAfterTrueTies = () => {
    if (positionAfterTrueTiesRaw === 1) {
      return `1st`;
    } else if (positionAfterTrueTiesRaw === 2) {
      return `2nd`;
    } else if (positionAfterTrueTiesRaw === 3) {
      return `3rd`;
    } else {
      return `${positionAfterTrueTiesRaw}th`;
    }
  };

  const positionAfterTrueTiesRaw2 =
    Number(calculatePosition(item, scoredEntries)?.slice(0, -2)) -
    (getPrevTrueTied()[0]?.length || 0) +
    myTiedIndex +
    1;

  const positionAfterTrueTies2 = () => {
    if (positionAfterTrueTiesRaw2 === 1) {
      return `1st`;
    } else if (positionAfterTrueTiesRaw2 === 2) {
      return `2nd`;
    } else if (positionAfterTrueTiesRaw2 === 3) {
      return `3rd`;
    } else {
      return `${positionAfterTrueTiesRaw2}th`;
    }
  };

  const position =
    getPrevTrueTied()?.length > 0
      ? positionAfterTrueTies()
      : calculatePosition(item, scoredEntries);

  return (
    <>
      <div
        className={`w-full min-w-[800px] sm:min-w-0 flex p-3 border-b border-white/10 relative text-[.75rem] lg:text-[.85rem] ${
          category?.is_scratched ? "text-white/30" : "text-white"
        }`}
      >
        {item?.is_scratched && (
          <div className="w-full h-full bg-stone-700/60 absolute top-0 left-0"></div>
        )}
        <div className="flex gap-5 lg:gap-[50px] mr-auto lg:w-[40%]">
          <img
            alt="arrow"
            src={`${
              item?.is_scratched
                ? "/images/scratchCat.png"
                : item?.announced
                ? "/images/announced-green.png"
                : "/images/announced-gray.png"
            }`}
            className={`w-4 lg:w-6 opacity-70 ${
              item?.is_scratched ? "lg:h-4 h-3 mt-1" : "lg:h-6 h-4"
            } ${category?.is_scratched && "opacity-50 h-3"} ${
              userData?.role === "emcee" && "cursor-pointer"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              userData?.role === "emcee" && handleAnnounce(item);
            }}
          />
          <p className={`whitespace-nowrap w-[60px] lg:w-[100px]`}>
            {scoredEntries?.includes(item)
              ? otherScores?.length === 0 && (
                  <span className={`text-white`}>
                    {item?.is_scratched ? "N/A" : position}
                  </span>
                )
              : "- -"}
            {otherScores?.length > 0 &&
              !isNaN(avgTechnique(item)) &&
              myTiedPosition() && (
                <span className={`text-white`}>
                  {`${
                    tiedTechnique?.length <= 1
                      ? positionAfterTrueTies2()
                      : myTiedPosition()
                  }`}
                  <span className="text-[.7rem] text-gray-300">
                    {" "}
                    ~ ({tiedTechnique?.length <= 1 ? "TIE" : "TRUE TIE"})
                  </span>
                </span>
              )}{" "}
          </p>{" "}
          <p className="text-white mr-3 w-12">
            {!isNaN(calculateTotalScore(item))
              ? Number(calculateTotalScore(item))?.toFixed(2)
              : "- -"}{" "}
          </p>
          <p className="w-[120px] lg:w-[200px] ml-auto">
            {capitalizeFirstLetter(item?.studio_selected[0])}
          </p>
        </div>

        <div className="w-[55%] lg:w-[50%] flex gap-5 lg:gap-[50px]">
          <div
            onClick={(e) => {
              e.stopPropagation();
              setShowNames(item);
            }}
            className={`mr-auto whitespace-nowrap w-[200px] px-2 rounded-md relative ${
              item?.dancers_selected?.length > 3 && !showNames
                ? "truncate hover:bg-[#94a4fd]/40 cursor-pointer"
                : item?.dancers_selected?.length > 3 && showNames
                ? "truncate"
                : ""
            }`}
          >
            {item?.dancers_selected?.map((dancer, index) => (
              <React.Fragment key={index}>
                {index > 0 && ", "}
                <span>
                  {capitalizeFirstLetter(dancer?.first_name)}{" "}
                  {capitalizeFirstLetter(dancer?.last_name)}
                </span>
              </React.Fragment>
            ))}
            {item?.id === showNames?.id &&
              showNames?.dancers_selected?.length > 3 && (
                <ul className="w-[220px] bg-[#282929] rounded-md p-2 flex flex-col z-[3]">
                  {showNames?.dancers_selected?.map((x, i) => {
                    return (
                      <li key={i} className="w-full border-b border-white/10">
                        {x?.first_name} {x?.last_name}
                      </li>
                    );
                  })}
                </ul>
              )}
          </div>
          <div className="mr-auto flex gap-10">
            <p className="w-5">
              {currentEvent?.locked
                ? item?.locked_entry_count
                : entryCount(item)}
            </p>
            <p className="w-[120px] lg:w-[200px]">
              {capitalizeFirstLetter(item?.entry_name)}
            </p>
          </div>
          {userData?.role === "stage-manager" && (
            <p className="ml-auto">{item?.dancers_selected?.length}</p>
          )}{" "}
        </div>
      </div>
    </>
  );
};

export default GrandAwardEntryAwards;
