import CompetitionCard from "./components/CompetitionCard";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setCompetitionssData } from "redux/danceStudio/danceStudioSlice";
import AddDancerForm from "./components/AddDancerForm";
import { PulseLoader } from "react-spinners";

const CompetitionSelectionStudio = () => {
  const { selectCompPending, removeCompPending, addDancer, openNotifsBoard } =
    useSelector((state) => state.danceStudio);

  // to call the query  all docs hook
  const { status, data } = useQueryAllDocs("competitions");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCompetitionssData(data));
  }, [dispatch, data]);

  const availableComps = data?.filter((item) => item?.eligibilityDivisions);

  const [popup, setPopup] = useState(null);

  return (
    <>
      <LeftSidebar />
      <Header />
      <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
        <h1 className="text-2xl md:text-4xl font-bold my-8 text-red-600">
          We are currently undergoing maintenance. Please check back later.
        </h1>
      </div>
      // <ScrollToTop />
      <Footer />
    </>
  );

  // return (
  //   <>
  //     <LeftSidebar />
  //     <Header />
  //     {openNotifsBoard && <RightSidebar />}
  //     {!addDancer && (
  //       <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
  //         <div className="mb-auto">
  //           <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
  //             <h2>Competitions</h2>
  //             <img
  //               alt="arrow"
  //               src="/images/ArrowDown.png"
  //               className="w-4 h-4"
  //             />
  //           </div>

  //           {/* Competitions container */}
  //           <div className="w-full md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] flex flex-col gap-6 mb-auto text-white/80">
  //             {status === 'success' &&
  //             !selectCompPending &&
  //             !removeCompPending ? (
  //               availableComps?.map((item, index) => {
  //                 return (
  //                   <CompetitionCard
  //                     item={item}
  //                     key={index}
  //                     index={index}
  //                     setPopup={setPopup}
  //                     popup={popup}
  //                   />
  //                 );
  //               })
  //             ) : (
  //               <div className="w-full h-[200px] flex justify-center items-center gap-6 mb-auto text-white/80">
  //                 <PulseLoader color="#94a4fd" size={10} />
  //               </div>
  //             )}
  //           </div>

  //           {availableComps?.length === 0 && (
  //             <div className="w-full h-[200px] flex justify-center items-center border border-white/20 rounded-lg text-white/50">
  //               No competitions yet..
  //             </div>
  //           )}
  //         </div>
  //         <ScrollToTop />
  //         <Footer />
  //       </div>
  //     )}

  //     {addDancer && (
  //       <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
  //         <div className="mb-auto">
  //           {/* Heading */}
  //           <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
  //             <img
  //               alt="arrow"
  //               src="/images/ArrowDown.png"
  //               className="w-4 h-4"
  //             />
  //           </div>

  //           {/* Add event form */}
  //           <div className="">
  //             <AddDancerForm />
  //           </div>
  //         </div>
  //         <ScrollToTop />
  //         <Footer />
  //       </div>
  //     )}
  //   </>
  // );
};

export default CompetitionSelectionStudio;
