import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Components
import ImgWrap from '../UI/ImgWrapper';
import Container from '../UI/Container';
import Logo from '../../assets/Logo.png';
import Button from '../Form/Button';
import JudgesCriteria from './Scoring/JudgesCriteria';
import Deduction from './Scoring/Deduction';
import Award from './Scoring/Award';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const Scoring = () => {
  const navigate = useNavigate();
  const { judgeCriteria, awards, deductionCriteria } = useSelector(
    (state) => state.persistedReducer
  );

  const goToPreviousPage = () => {
    navigate('/organizer/eligibility');
  };

  const goToNextPage = () => {
    const totalPercent = judgeCriteria.reduce(
      (sum, { judgesPercent }) => sum + parseInt(judgesPercent),
      0
    );

    if (
      totalPercent === 100 &&
      awards.length > 0 &&
      deductionCriteria.length > 0
    ) {
      navigate('/organizer/scheduling');
    } else if (totalPercent < 100) {
      toast.info('Total percentage of judging criteria must be 100.');
    } else {
      toast.info('Please fill all fields .');
    }
  };

  return (
    <div className="flex flex-row flex-wrap h-[100vh] w-full">
      <div className="lg:flex hidden lg:w-[40%]">
        {/* Left content with logo and background image */}
        <ImgWrap className="bg-[url('../public/Frame6.png')]" />
      </div>
      <div className="lg:w-[60%] w-full">
        <Container className="flex flex-wrap lg:px-[6%]">
          <div className="flex flex-row justify-between w-full lg:justify-end">
            <div className="block lg:hidden">
              <Link to="/">
                <img
                  src={Logo}
                  alt="Logo"
                  className="cursor-pointer w-[90px] h-[25px]"
                />
              </Link>
            </div>
            <div className="flex flex-col items-end">
              <span className="text-sm font-medium text-[#BDBDBD]">
                STEP 04/06
              </span>
              <h4 className="text-base font-semibold text-[#8692A6">
                Organizer Registration
              </h4>
            </div>
          </div>

          <div className="flex flex-col flex-wrap pt-[5%] w-full">
            <div className="lg:pl-[5%] mt-[3rem] md:mt-[2rem]">
              <h1 className="mb-3 text-3xl font-bold text-[#000000]">
                Scoring
              </h1>
              <p className="text-lg font-normal text-[#8692A6]">
                Determining how entries are scored
              </p>
              <div className="border-t border-base w-[60%] mt-5"></div>
            </div>

            <div className="flex flex-wrap mt-5 lg:pl-[5%]">
              {/* The segment pertaining to age divisions */}

              <JudgesCriteria />

              {/* End of the segment pertaining to age divisions */}

              {/* The segment pertaining to age divisions */}

              <Deduction />

              {/* End of the segment pertaining to age divisions */}

              {/* The segment pertaining to age divisions */}

              <Award />

              {/* End of the segment pertaining to age divisions */}
              <div className="flex flex-row justify-between items-center mt-[25%] mb-[15px] w-full">
                <div className="w-5/12 lg:ml-9 md:ml-9 lg:w-fit md:w-fit">
                  <span className="text-sm font-medium text-error">
                    *Judging Criteria must add up to 100%
                  </span>
                </div>
                <div className="w-2/4 lg:w-fit md:w-fit flex">
                  <Button
                    text="Previous"
                    handleOnClick={goToPreviousPage}
                    className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium mr-3 h-8"
                  />
                  <Button
                    text="Next"
                    handleOnClick={goToNextPage}
                    className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium h-8"
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Scoring;
