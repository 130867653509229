import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { TabulatorTable } from "..";
import TabulatorUpdateTable from "./TabulatorUpdateTable";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import React, { useState } from "react";

const TabulatorRightside = ({
  // showLiveTable,
  // setShowLiveTable,
  liveEntry,
  calculateAverageScore,
  showTable,
  unselectEntry,
  currentEntryDocId,
  selectEntry,
  sortedCategories,
  eventScores,
  findMatchingAward,
  awards,
  arrangedEntries,
}) => {
  //   console.log("eventScores", eventScores);

  const [showLiveTable, setShowLiveTable] = useState(true);

  const selectedEntry = arrangedEntries?.filter(
    (x) => x?.doc_id === currentEntryDocId
  )[0];

  const currentUpdateEntryScores = eventScores?.filter(
    (item) => item?.doc_id === liveEntry?.doc_id
  );

  const currentEntryScores = eventScores?.filter(
    (item) => item?.doc_id === currentEntryDocId
  );

  const matchingAward = findMatchingAward(
    awards,
    Number(calculateAverageScore(currentEntryScores, "total"))
  );

  const matchingAwardLive = findMatchingAward(
    awards,
    Number(calculateAverageScore(currentUpdateEntryScores, "total"))
  );

  const allDeductions = currentEntryScores
    ?.flatMap((obj) => obj.deductions)
    ?.filter((item) => item !== "none");

  const scoreWithDed = currentEntryScores?.filter((x) =>
    Array.isArray(x?.deductions)
  );

  const allLiveDeductions = currentUpdateEntryScores
    ?.flatMap((obj) => obj.deductions)
    ?.filter((item) => item !== "none");

  const scoreWithDedLive = currentUpdateEntryScores?.filter((x) =>
    Array.isArray(x?.deductions)
  );

  const { data: allJudges } = useQueryAllDocs("judges");

  function getJudgeName(id) {
    const judge = allJudges?.filter((x) => x?.uid === id)[0]?.first_name;
    return judge;
  }

  const [showJudgeDed, setShowJudgeDed] = useState(false);

  return (
    <div
      className={`w-full relative  overflow-y-auto bg-black/30 md:bg-transparent ${
        !showLiveTable && !showTable ? "h-[10px]" : "h-screen"
      }`}
    >
      {!showLiveTable && (
        <div
          onClick={() => setShowLiveTable(true)}
          className="bg-red-500/60 text-white p-2 rounded-md w-fit text-[.85rem] fixed top-[80px] md:top-24 right-4 md:right-8 cursor-pointer hover:border hover:border-white/60"
        >
          <p>Current Entry</p>
        </div>
      )}
      {/* <div className="sticky top-[80px] md:top-24"> */}
      <div className="">
        {liveEntry && showLiveTable && (
          <div className="bg-[#282929] w-full min-h-[350px] rounded-xl mb-3 px-4 pt-5 pb-3 flex flex-col gap-4 slide">
            <div
              onClick={() => {
                setShowLiveTable(false);
              }}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-2 right-2"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <h3 className="text-red-500">
              Current Entry: {capitalizeFirstLetter(liveEntry?.entry_name)}
            </h3>
            {currentUpdateEntryScores?.length > 0 ? (
              <>
                <div className="w-full min-h-[150px] overflow-auto">
                  <TabulatorUpdateTable
                    currentUpdateEntryScores={currentUpdateEntryScores}
                    currentEntryScores={currentEntryScores}
                    calculateAverageScore={calculateAverageScore}
                    eventScores={eventScores}
                    allLiveDeductions={allLiveDeductions}
                  />
                </div>
                <div className="text-white">
                  <p>Deductions</p>
                  <div className="w-full flex gap-3 flex-wrap mt-2">
                    {allLiveDeductions?.length > 0 ? (
                      allLiveDeductions?.map((item, index) => {
                        const dedScore = scoreWithDedLive?.filter((y) =>
                          y?.deductions?.includes(item)
                        );

                        const judgesNames = dedScore?.map((z) =>
                          getJudgeName(z?.judge_id)
                        );

                        return (
                          <div className="relative w-fit" key={index}>
                            <div
                              onMouseOver={() => setShowJudgeDed(true)}
                              onMouseOut={() => setShowJudgeDed(false)}
                              className="text-[.75rem] bg-red-500/20 px-3 py-1 rounded-lg border border-white/10 hover:border-white cursor-pointer"
                            >
                              {capitalizeFirstLetter(item)?.replace(/_/g, " -")}
                              %
                            </div>
                            {showJudgeDed && (
                              <div className="w-fit h-fit px-3 py-1 text-[.75rem] text-white bg-black rounded-md absolute top-[-30px] left-0">
                                {judgesNames?.map((judge, idx) => (
                                  <p key={idx}>
                                    {capitalizeFirstLetter(judge)}
                                    {idx === judgesNames?.length - 1 ? "" : ","}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <p className="w-full border-b border-white/30 text-white/70">
                        None
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <p className="relative top-[5px]">Award</p>
                  <p className="text-[2rem] font-normal text-white">
                    {matchingAwardLive?.awardName
                      ? capitalizeFirstLetter(matchingAwardLive?.awardName)
                      : "None"}
                  </p>
                </div>
              </>
            ) : (
              <div className="w-full h-[300px] flex justify-center items-center text-white/50">
                <p>No scores for this entry yet...</p>
              </div>
            )}
          </div>
        )}

        {/* second table below */}
        {showTable && (
          <div className="relative bg-[#282929] w-full min-h-[350px] rounded-xl mb-3 px-4 pt-5 pb-3 flex flex-col gap-4 slide">
            <div
              onClick={() => {
                unselectEntry();
              }}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-2 right-2"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <h3 className="text-[#94a4fd]">
              Editting Entry: {capitalizeFirstLetter(selectedEntry?.entry_name)}
            </h3>
            {currentEntryScores?.length > 0 ? (
              <>
                <div className="w-full min-h-[150px] overflow-auto">
                  <TabulatorTable
                    currentEntryScores={currentEntryScores}
                    calculateAverageScore={calculateAverageScore}
                    selectEntry={selectEntry}
                    categories={sortedCategories}
                    allDeductions={allDeductions}
                  />
                </div>
                <div className="text-white">
                  <p>Deductions</p>
                  <div className="w-full flex gap-3 flex-wrap mt-2">
                    {allDeductions?.length > 0 ? (
                      allDeductions?.map((item, index) => {
                        const dedScore = scoreWithDed?.filter((y) =>
                          y?.deductions?.includes(item)
                        );

                        const judgesNames = dedScore?.map((z) =>
                          getJudgeName(z?.judge_id)
                        );

                        return (
                          <div className="relative w-fit" key={index}>
                            <div
                              onMouseOver={() => setShowJudgeDed(true)}
                              onMouseOut={() => setShowJudgeDed(false)}
                              className="text-[.75rem] bg-red-500/20 px-3 py-1 rounded-lg border border-white/10 hover:border-white cursor-pointer"
                            >
                              {capitalizeFirstLetter(item)?.replace(/_/g, " -")}
                              %
                            </div>
                            {showJudgeDed && (
                              <div className="w-fit h-fit px-3 py-1 text-[.75rem] text-white bg-black rounded-md absolute top-[-30px] left-0">
                                {judgesNames?.map((judge, idx) => (
                                  <p key={idx}>
                                    {capitalizeFirstLetter(judge)}
                                    {idx === judgesNames?.length - 1 ? "" : ","}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        );
                      })
                    ) : (
                      <p className="w-full border-b border-white/30 text-white/70">
                        None
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <p className="relative top-[5px]">Award</p>
                  <p className="text-[2rem] font-normal text-white">
                    {matchingAward?.awardName
                      ? capitalizeFirstLetter(matchingAward?.awardName)
                      : "None"}
                  </p>
                </div>
              </>
            ) : (
              <div className="w-full h-[300px] flex justify-center items-center text-white/50">
                <p>No scores for this entry yet...</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TabulatorRightside;
