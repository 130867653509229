import React from "react";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import removeDuplicates from "utils/removeDuplicates";

const TabulatorEntry = ({
  idx,
  item,
  entryTime,
  entryCount,
  category,
  toggleNotes,
  showNotes,
  calculatePosition,
  calculateTotalScore,
  myEntries,
  findMatchingAward,
  awards,
  liveEntry,
  currentEntryDocId,
  selectEntry,
  setShowNames,
  showNames,
  allScores,
  myEntriesSorted,
  notesWithoutChoreoAndSpecial,
  currentEvent,
}) => {
  //========================================================================to handle tied positions/scores
  function avgTechnique(entry) {
    const myScore = allScores?.filter((x) => x?.doc_id === entry?.doc_id);

    const totalTechnique = myScore?.reduce(
      (acc, item) => acc + Number(item.technique),
      0
    );
    const averageTechnique = totalTechnique / myScore?.length;
    return Number(averageTechnique).toFixed(2);
  }

  const otherScores = myEntriesSorted?.filter(
    (y) =>
      calculateTotalScore(y) === calculateTotalScore(item) &&
      y?.entry_name !== item?.entry_name
  );

  const scoresWithCurrent = myEntriesSorted?.filter(
    (y) => calculateTotalScore(y) === calculateTotalScore(item)
  );

  function getTiedPositionArray() {
    let tiedScores = [];
    scoresWithCurrent?.forEach((x) => {
      return tiedScores?.push(Number(avgTechnique(x))?.toFixed(2));
    });

    return tiedScores?.sort((a, b) => b - a);
  }

  const tiedTechnique = getTiedPositionArray()?.filter(
    (z) => z === avgTechnique(item)
  );

  const myTiedIndex = getTiedPositionArray()?.findIndex(
    (y) => y === avgTechnique(item)
  );

  const myTiedPosition = () => {
    const pos = Number(calculatePosition(item, myEntries)?.split("")[0]);
    // console.log("pos", calculatePosition(item, myEntries));

    if (myTiedIndex + pos === 1) {
      return "1st";
    } else if (myTiedIndex + pos === 2) {
      return "2nd";
    } else if (myTiedIndex + pos === 3) {
      return "3rd";
    } else if (myTiedIndex + pos > 3) {
      return `${myTiedIndex + pos}th`;
    }
  };

  const scoredEntries = myEntries?.filter(
    (x) => !isNaN(calculateTotalScore(x))
  );

  const entriesBeforeCurrent =
    scoredEntries
      ?.sort((a, b) => calculateTotalScore(b) - calculateTotalScore(a))
      ?.slice(0, scoredEntries?.indexOf(item)) || [];

  function getPrevTrueTied() {
    let arr = [];
    entriesBeforeCurrent?.forEach((entry) => {
      const dupScores = entriesBeforeCurrent?.filter(
        (y) => calculateTotalScore(y) === calculateTotalScore(entry)
      );
      function getTiedPositionArray() {
        let tiedScores = [];
        dupScores?.forEach((x) => {
          return tiedScores?.push(Number(avgTechnique(x))?.toFixed(2));
        });

        return tiedScores?.sort((a, b) => b - a);
      }

      const tiedTech = getTiedPositionArray()?.filter(
        (z) => z === avgTechnique(entry)
      );

      if (dupScores?.length > 1 && !(tiedTech?.length <= 1)) {
        arr.push(dupScores);
      }
    });
    return removeDuplicates(arr);
  }

  const positionAfterTrueTiesRaw =
    Number(calculatePosition(item, scoredEntries)?.slice(0, -2)) -
    getPrevTrueTied()?.length;

  const positionAfterTrueTies = () => {
    if (positionAfterTrueTiesRaw === 1) {
      return `1st`;
    } else if (positionAfterTrueTiesRaw === 2) {
      return `2nd`;
    } else if (positionAfterTrueTiesRaw === 3) {
      return `3rd`;
    } else {
      return `${positionAfterTrueTiesRaw}th`;
    }
  };

  const position =
    getPrevTrueTied()?.length > 0
      ? positionAfterTrueTies()
      : calculatePosition(item, scoredEntries);

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
          selectEntry(item?.doc_id);
          setShowNames(null);
        }}
        className={`w-full flex p-3 border-b border-white/10 relative cursor-pointer hover:bg-[#94a4fd]/10 text-[.75em] ${
          currentEntryDocId === item?.doc_id && "bg-[#94a4fd]/20"
        } 
      ${liveEntry?.doc_id === item?.doc_id && "bg-red-600/20"} 
      ${category?.is_scratched ? "text-white/30" : "text-white"}`}
      >
        {/* {item?.special_tags?.length > 0 && (
          <div className="absolute top-[-15px] left-0 px-3 border border-white/40 rounded-lg text-white/60 text-[.75em] flex gap-2 bg-[#1c1c1c] z-[2]">
            {item?.special_tags?.map((tag, ind) => {
              return (
                <p key={ind}>
                  {tag}
                  {ind !== item?.special_tags?.length - 1 && (
                    <span className="ml-2">|</span>
                  )}
                </p>
              );
            })}
          </div>
        )}
        {item?.special_tags?.length > 0 && (
          <div className="w-[50%] bg-white/20 h-[1px] absolute top-[-6px]"></div>
        )} */}
        {item?.is_scratched && (
          <div className="w-full h-full bg-stone-700/60 absolute top-0 left-0"></div>
        )}
        <div className="flex gap-[50px] mr-auto">
          <div className="gap-2 flex items-center">
            <img
              alt="arrow"
              src={`${
                item?.is_scratched
                  ? "/images/scratchCat.png"
                  : item?.checkedIn
                  ? "/images/CheckSquareOffset.png"
                  : "/images/CheckSquareOffset2.png"
              }`}
              className={`w-4 ${item?.is_scratched ? "h-3 mt-1" : "h-4"} ${
                category?.is_scratched && "opacity-50 h-3"
              }`}
            />
            <img
              alt="arrow"
              src={`${
                item?.is_scratched
                  ? "/images/scratchCat.png"
                  : item?.performance_ready
                  ? "/images/checkmark.png"
                  : "/images/Checkmark2.png"
              }`}
              className={`w-4 ${item?.is_scratched ? "h-3 mt-1" : "h-4"} ${
                category?.is_scratched ? "opacity-50 h-3" : "opacity-70"
              }`}
            />
          </div>
          <p
            className={`min-w-[70px] whitespace-nowrap ${
              item?.locked_entry && "text-white/40 text-[.8em]"
            }`}
          >
            {item?.locked_entry ? "AFTER LOCK" : entryTime(item)}
          </p>
          <p className="min-w-[30px]">
            {currentEvent?.locked ? item?.locked_entry_count : entryCount(item)}
          </p>
          <p className="w-[120px] lg:w-[200px]">
            {capitalizeFirstLetter(item?.entry_name)}
          </p>
        </div>
        <div className="w-[55%] flex gap-10">
          <p className="">{item?.studio_letter}</p>
          <div
            onClick={(e) => {
              item?.dancers_selected?.length > 1 && e.stopPropagation();
              setShowNames((prev) => (prev ? null : item));
            }}
            className={`mr-auto whitespace-nowrap w-[200px] px-2 rounded-md relative ${
              item?.dancers_selected?.length > 1 && !showNames
                ? "truncate hover:bg-[#94a4fd]/40 cursor-pointer"
                : item?.dancers_selected?.length > 1 && showNames
                ? "truncate"
                : ""
            }`}
          >
            {item?.dancers_selected?.map((dancer, index) => (
              <React.Fragment key={index}>
                {index > 0 && ", "}
                <span>
                  {capitalizeFirstLetter(dancer?.first_name)}{" "}
                  {capitalizeFirstLetter(dancer?.last_name)}
                </span>
              </React.Fragment>
            ))}
            {item?.id === showNames?.id &&
              showNames?.dancers_selected?.length > 1 && (
                <ul className="w-[220px] bg-[#282929] rounded-md p-2 flex flex-col z-[3]">
                  {showNames?.dancers_selected?.map((x, i) => {
                    return (
                      <li key={i} className="w-full border-b border-white/10">
                        {capitalizeFirstLetter(x?.first_name)}{" "}
                        {capitalizeFirstLetter(x?.last_name)}
                      </li>
                    );
                  })}
                </ul>
              )}
          </div>

          <p className={`whitespace-nowrap w-[120px]`}>
            {otherScores?.length === 0 && (
              <span
                className={`${
                  (calculatePosition(item, myEntries)?.includes("st") ||
                    calculatePosition(item, myEntries)?.includes("nd") ||
                    calculatePosition(item, myEntries)?.includes("rd")) &&
                  myEntries?.length >= 3
                    ? "text-red-500"
                    : "text-white"
                }`}
              >
                {item?.is_scratched ? "N/A" : position}
              </span>
            )}
            {otherScores?.length > 0 && !isNaN(avgTechnique(item)) && (
              <span
                className={`${
                  myTiedPosition()?.includes("th") && myEntries?.length >= 3
                    ? "text-white"
                    : !myTiedPosition()?.includes("th") &&
                      myEntries?.length >= 3
                    ? "text-red-500"
                    : "text-white"
                }`}
              >
                {`${myTiedPosition()}`}
                <span className="text-gray-300">
                  {" "}
                  ~ ({tiedTechnique?.length <= 1 ? "TIE" : "TRUE TIE"})
                </span>{" "}
              </span>
            )}{" "}
            <span className="text-white">
              -{" "}
              {capitalizeFirstLetter(
                findMatchingAward(awards, Number(calculateTotalScore(item)))
                  ?.code || "- -"
              )}
            </span>
          </p>
          <p className="">{item?.dancers_selected?.length}</p>

          <div className="flex gap-1 items-start">
            <img
              alt="person"
              src={
                item?.isDancerStarts
                  ? "/images/PersonGreen.png"
                  : "/images/Person.png"
              }
              className="w-4 h-4"
            />
            <img
              alt="checked"
              src={
                item?.isCheckedProps
                  ? "/images/ClockGreen.png"
                  : "/images/Clock.png"
              }
              className="w-4 h-4"
            />
            <img
              alt="arrow"
              src={
                item?.isRunAround
                  ? "/images/runaround-green.png"
                  : "/images/runaround.png"
              }
              className={`w-4 h-4`}
            />

            <img
              alt="arrow"
              onClick={(e) => {
                e.stopPropagation();
                if (item?.notes) {
                  toggleNotes(item);
                }
              }}
              src={`${
                notesWithoutChoreoAndSpecial?.length > 0
                  ? "/images/NotePencil-green.png"
                  : "/images/NotePencil.png"
              }`}
              className={`w-4 h-4 ${
                category?.is_scratched ? "opacity-50" : ""
              }`}
            />
          </div>
        </div>

        {/* to display the notes dropdown */}
        {notesWithoutChoreoAndSpecial?.length > 0 && showNotes === item && (
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-[250px] bg-[#282929] p-3 rounded-lg absolute top-0 right-0 font-normal shadow-lg z-[3] flex flex-col gap-3 scale"
          >
            <h3 className="text-[.9rem] text-center">Notes</h3>
            <div
              onClick={(e) => {
                e.stopPropagation();
                toggleNotes(item);
              }}
              className="w-4 h-4 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-1 right-1"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-[10px] h-[10px]"
              />
            </div>
            {notesWithoutChoreoAndSpecial?.map((note, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-col gap-1 items-center text-[.85rem] py-1 px-2 rounded-lg hover:bg-white/20 bg-white/10"
                >
                  <p className="w-full whitespace-nowrap truncate font-bold">
                    {capitalizeFirstLetter(note?.type)}
                  </p>
                  <p className="w-full">{capitalizeFirstLetter(note?.note)}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default TabulatorEntry;
