/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import AddEventForm from "./components/AddEventForm";
import ScrollToTop from "./ScrollToTop";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import FormattingTable from "./components/FormattingTable";
import { useEffect, useState } from "react";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import Spinner from "./components/Spinner";
import {
  clearSuccess,
  deleteLabel,
  submitLabel,
} from "redux/eventManagement/eventManagement";
import { useDispatch } from "react-redux";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
// import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";

const AwardLabels = () => {
  const {
    addEvent,
    openNotifsBoard,
    currentEvent,
    submitLabelPending,
    submitLabelSuccess,
    deleteLabelPending,
    deleteLabelSuccess,
  } = useSelector((state) => state.eventManagement);

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const [formData, setFormData] = useState({
    entry_name: "entry_name",
    entry_num: "entry_num",
    studio_code: "studio_code",
    dancers: "dancers",
    num_dancers: "num_dancers",
    age_division: "age_division",
    classification: "classification",
    genre: "genre",
    entry_type: "entry_type",
    special_tag: "special_tag",
  });

  // console.log("formData", formData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const { data: compData } = useFirestoreQueryDoc("competitions", user?.uid);

  const [entryTypes, setentryTypes] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes } = compData;
      setentryTypes(entryTypes);
    }
  }, [compData]);

  const navigate = useNavigate();
  const { event_name } = useParams();

  const code = `<<${formData?.age_division}>> <<${formData?.genre}>> <<${formData?.entry_type}>>\n <<${formData?.studio_code}>> #<<${formData?.entry_num}>> ”<<${formData?.entry_name}>>” \n <<${formData?.num_dancers}>> Dancers`;

  const [codeValue, setCodeValue] = useState(code);
  const [codeErr, setCodeErr] = useState("");
  useEffect(() => {
    setCodeValue(code);
  }, [formData, code]);

  function handleCodeChange(e) {
    setCodeValue(e.target.value);
    setCodeErr("");
  }

  const handleKeyDown = (e) => {
    const allowedCharacters = /^[a-zA-Z0-9, _:"#.><-]*$/;
    const key = e.key;

    if (!allowedCharacters.test(key) && key !== "Backspace") {
      e.preventDefault();
    }
  };

  function notifyCodeErr() {
    if (checkedEntryTypes?.length === 0) {
      setCodeErr("Please select one or more entry types");
    } else {
      setCodeErr(
        "Total entries with selected entry type must be greater than 0"
      );
    }
  }

  //to get all categories
  const { status, data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  //to get categories of current event
  const extractedEntries = allEntries?.filter((item) => {
    return item?.event_uid === currentEvent?.event_uid;
  });

  const [checkedEntryTypes, setCheckedEntryTypes] = useState([]);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCodeErr("");
    if (checked) {
      setCheckedEntryTypes((prevCheckedEntryTypes) => [
        ...prevCheckedEntryTypes,
        id,
      ]);
    } else {
      setCheckedEntryTypes((prevCheckedEntryTypes) =>
        prevCheckedEntryTypes.filter((item) => item !== id)
      );
    }
  };

  const entriesToPrint = extractedEntries?.filter((item) => {
    return checkedEntryTypes?.includes(item?.entryType);
  });

  const [labelFormat, setLabelFormat] = useState([]);

  useEffect(() => {
    const regex = /<<(.*?)>>/g;
    const matches = codeValue.match(regex);
    if (matches) {
      setLabelFormat(matches.map((match) => match.slice(2, -2)));
    }
    // console.log("labelFormat", labelFormat);
  }, [codeValue]);

  const defaultFormatted = `Senior Hip Hop Large Group \n Studio A #389 "Flash Point” \n 12 Dancers`;
  const [formattedCode, setFormattedCode] = useState(defaultFormatted);
  // console.log("formattedCode", formattedCode);

  useEffect(() => {
    if (checkedEntryTypes?.length === 0) {
      setFormattedCode(defaultFormatted);
    }
  }, [checkedEntryTypes]);

  useEffect(() => {
    if (entriesToPrint?.length > 0 && labelFormat?.length > 0 && codeValue) {
      const formattedCodes = entriesToPrint?.map((item) => {
        return labelFormat?.reduce((acc, label) => {
          if (item) {
            let value = capitalizeFirstLetter(item[label]);

            if (label === "entry_type") {
              value = capitalizeFirstLetter(item["entryType"]);
            } else if (label === "studio_code") {
              value = `Studio ${item["studio_letter"]}`;
            } else if (label === "dancers") {
              value = item["dancers_selected"].join(", ");
            } else if (label === "special_tag") {
              value = item["special_tags"].join(", ");
            } else if (label === "entry_num") {
              value = item["entry_count"].toString().padStart(3, "0");
            } else if (label === "num_dancers") {
              value = item["dancers_selected"]?.length || 0;
            }

            return acc?.replace(`<<${label}>>`, `${value}`);
          }
          return acc;
        }, codeValue);
      });

      setFormattedCode(formattedCodes);
    }
  }, [labelFormat, codeValue, status, checkedEntryTypes]);

  // console.log("formattedCode", formattedCode);

  const dispatch = useDispatch();

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  const timestamp = new Date().getTime();

  function formatTimeStamp(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedTime = `${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }, ${month} ${day}, ${year}`;
    return formattedTime;
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (
        entriesToPrint?.length > 0 &&
        !formattedCode[0]?.includes("undefined") &&
        !formattedCode[0]?.includes(">") &&
        !formattedCode[0]?.includes("<")
      ) {
        dispatch(
          submitLabel({
            formattedCode,
            compId: user?.uid,
            label_uid: generateUID(),
            timestamp: formatTimeStamp(timestamp),
          })
        );
      } else if (
        formattedCode[0]?.includes("undefined") ||
        formattedCode[0]?.includes(">") ||
        formattedCode[0]?.includes("<")
      ) {
        setCodeErr(
          "Invalid label format, label must not contain undefined or invalid characters '<', '>' "
        );
      }
    } catch (error) {
      console.log("error creating label", error);
    }
  }

  const { data } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "reports"
  );
  // console.log("data", data);

  const createdLabels =
    data?.filter((item) => item?.id === "award_labels") || [];

  const [openLabel, setOpenLabel] = useState(null);
  function openLabelMod(label) {
    setOpenLabel(label);
  }
  function closeLabelMod() {
    setOpenLabel(null);
  }

  useEffect(() => {
    if (submitLabelSuccess?.success) {
      setTimeout(() => {
        dispatch(clearSuccess());
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 2000);
    }

    if (deleteLabelSuccess?.success) {
      dispatch(clearSuccess());
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      closeLabelMod();
    }
  }, [submitLabelSuccess, deleteLabelSuccess]);

  // console.log("createdLabels", createdLabels);

  function printDiv() {
    const labelDiv = document.getElementById("label");
    if (labelDiv) {
      const options = {
        margin: 10,
        filename: "label.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
      };

      html2pdf().from(labelDiv).set(options).save();
    }
  }

  // console.log(openLabel?.item?.formattedCode);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name?.trim())}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full md:max-w-[85%] lg:max-w-[60%] md:min-w-[450px] mb-auto text-white">
              {status === "loading" ? (
                <div className="w-full h-[100px] flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                createdLabels[0]?.labels?.length > 0 && (
                  <div className="w-full bg-[#282929] p-6 rounded-lg flex flex-col gap-4 mb-7">
                    <h3 className="text-[1.25rem] font-bold">Created Labels</h3>
                    <div className="w-full mt-3 flex flex-col md:flex-row gap-4">
                      {createdLabels[0]?.labels?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => openLabelMod({ item, index })}
                            className="max-w-[250px] md:min-w-[200px] text-[.9em] bg-white/80 p-4 md:px-6 rounded-lg text-black hover:scale-[1.1] cursor-pointer"
                          >
                            {item?.formattedCode[0]
                              ?.split("\n")
                              .map((line, index) => (
                                <div key={index}>{line}</div>
                              ))}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )
              )}

              {status === "loading" ? (
                <div className="w-full h-[150px] bg-[#282929] p-6 rounded-lg flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                <div className="w-full bg-[#282929] p-6 rounded-lg flex flex-col gap-4">
                  <h3 className="w-full text-center text-[1.25rem] font-bold">
                    Creating Labels
                  </h3>

                  <h4 className="text-[1.1rem] font-bold mt-3">Entry Types</h4>
                  <div
                    className={`w-fit min-h-fit max-h-[100px] flex flex-col flex-wrap gap-3 ${
                      codeErr &&
                      !codeErr?.includes("Invalid label format") &&
                      "border border-red-500/80 rounded-lg p-2"
                    }`}
                  >
                    {entryTypes?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="min-w-fit w-[150px] flex items-center whitespace-nowrap"
                        >
                          <input
                            type="checkbox"
                            id={item?.entryTypeName}
                            className="cursor-pointer mr-2"
                            checked={checkedEntryTypes?.includes(
                              item?.entryTypeName
                            )}
                            onChange={handleCheckboxChange}
                          />

                          <label
                            htmlFor={item?.entryTypeName}
                            className="text-white/50 cursor-pointer"
                          >
                            {item?.entryTypeName}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  {checkedEntryTypes?.length > 0 && (
                    <p className=" text-white/70 text-[.85rem] mb-3">
                      Total number entries with selected entry type:{" "}
                      <span className="font-bold">
                        {entriesToPrint?.length}
                      </span>
                    </p>
                  )}

                  <h4 className="text-[1.1rem] font-bold mt-3">
                    Label Formatting Legend
                  </h4>
                  <div className="w-full flex md:flex-row flex-col flex-wrap gap-6">
                    <div className="w-full md:w-[48%] md:min-w-[300px] h-[400px]">
                      <FormattingTable
                        formData={formData}
                        handleInputChange={handleInputChange}
                      />
                    </div>
                    <div className="w-full md:w-[48%] md:min-w-[300px] mt-20 lg:mt-0">
                      <h5 className="font-medium mb-4">Code</h5>
                      <div className="relative">
                        <textarea
                          value={codeValue}
                          onChange={(e) => handleCodeChange(e)}
                          onKeyDown={(e) => handleKeyDown(e)}
                          style={{ resize: "none" }}
                          className={`w-full min-w-[250px] min-h-[110px] bg-black/90 p-4 rounded-xl text-white/40 outline-none border focus:border-[#94a4fd]/60 ${
                            codeErr ? "border-red-500/80" : "border-black"
                          }`}
                        />
                        {codeErr && (
                          <p className="text-[.85rem] text-red-500">
                            {codeErr}
                          </p>
                        )}
                        {(checkedEntryTypes?.length === 0 ||
                          entriesToPrint?.length === 0) && (
                          <div
                            onClick={notifyCodeErr}
                            className="w-full h-full absolute top-0 left-0"
                          ></div>
                        )}
                      </div>

                      {checkedEntryTypes?.length > 0 && (
                        <div className="w-full max-w-[300px] md:min-w-[250px] text-[1rem] bg-white p-4 md:px-6 rounded-lg text-black mt-10">
                          {formattedCode[0]?.split("\n").map((line, index) => (
                            <div key={index}>{line}</div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="w-full mt-7 flex gap-4 justify-end">
                    <button
                      onClick={() => {
                        navigate(
                          `/organizer/event/${event_name.replace(
                            / /g,
                            "-"
                          )}/report-session-awards`
                        );
                      }}
                      className="text-[.85rem] px-6 py-1 rounded-full bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
                    >
                      Discard
                    </button>
                    <button
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      disabled={submitLabelPending || codeErr}
                      className="text-[.9rem] px-6 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] flex justify-between gap-2 items-center whitespace-nowrap"
                    >
                      {submitLabelPending ? "Creating..." : "Create"}
                      {submitLabelPending && <ButtonLoadingSpinner />}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>Add Event</h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddEventForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {submitLabelSuccess?.success && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white text-center">
              Label Created Successfully!{" "}
            </p>
          </div>
        </div>
      )}

      {openLabel && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] md:w-fit min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg">
            <div className="w-full text-[1rem] text-white text-center pb-3 border-b border-[#c5c4c4]/50 relative">
              <p className="font-bold">Label {openLabel?.index + 1}</p>
              <div
                onClick={closeLabelMod}
                className="p-1 rounded-full bg-white/70 absolute right-2 top-0 cursor-pointer"
              >
                <img
                  alt=""
                  src="/images/icons8-close-50.png"
                  className="w-4 h-4"
                />
              </div>
            </div>

            <div className="w-full mt-4 flex gao-3">
              <button
                disabled={deleteLabelPending}
                onClick={() => {
                  dispatch(
                    deleteLabel({
                      compId: user?.uid,
                      doc: "award_labels",
                      label_uid: openLabel?.item?.label_uid,
                    })
                  );
                }}
                className="text-[.85rem] px-6 py-1 rounded-full bg-red-500 hover:bg-red-400 text-white transition-all duration-300 flex gap-2 items-center mr-5"
              >
                {deleteLabelPending ? "Deleting..." : " Delete"}
                {deleteLabelPending && <ButtonLoadingSpinner />}
              </button>
              {/* <button
                onClick={printDiv}
                className="text-[.85rem] px-6 py-1 mr-3 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300 flex gap-2 items-center"
              >
                Print Label
              </button> */}
              <button
                onClick={printDiv}
                className="text-[.85rem] px-6 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300 flex gap-2 items-center"
              >
                Download PDF
              </button>
            </div>

            <div className="w-full bg-[#282929] rounded-lg flex flex-col gap-4 overflow-auto">
              <div
                id="label"
                className="w-[1000px] mt-3 flex gap-2 bg-white rounded-lg p-4 flex-wrap"
              >
                {openLabel?.item?.formattedCode?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => openLabelMod({ item, index })}
                      className="w-full max-w-[240px] md:min-w-[200px] border border-gray-300 bg-white p-4 rounded-lg text-black text-[.9em]"
                    >
                      {item?.split("\n").map((line, index) => (
                        <div key={index}>{line}</div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AwardLabels;
