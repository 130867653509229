import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import ScoresTable from "./ScoresTable";

const ScoresCategory = ({
  category,
  index,
  categoryEntries,
  studioData,
  eventScores,
  calculatePosition,
  allEntries,
  allCategories,
  currentEvent,
  setShowNotes,
  showNotes,
  calculateAverageScore,
  calculateTotalScore,
}) => {
  //===============================================================================to calc dynamic entry count of all entries in routine

  const eventCategories = allCategories?.filter(
    (cat) => cat?.event_uid === currentEvent?.event_uid
  );
  let sortedCategories = eventCategories?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  const categories = sortedCategories?.filter((l) => l?.type === "category");

  const allEntriesWithoutLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  )?.filter((k) => k?.locked_entry === false);

  const allEntriesWithLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  );

  // console.log("allEntriesWithLocked", allEntriesWithLocked);

  function entryCount(itm) {
    const allEntriesBeforeLocked = allEntriesWithLocked
      ?.slice(
        0,
        findIndexByProperty(allEntriesWithLocked, "doc_id", itm?.doc_id)
      )
      ?.filter((m) => m?.locked_entry === false);

    if (itm?.locked_entry) {
      return `${
        allEntriesBeforeLocked?.length === 0
          ? 1
          : allEntriesBeforeLocked?.length
      }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
    } else {
      return (
        findIndexByProperty(allEntriesWithoutLocked, "doc_id", itm?.doc_id) + 1
      );
    }
  }

  return (
    <div className={`relative`} key={index}>
      {/* {category?.is_scratched && (
        <div className="w-full h-full bg-stone-700/40 absolute top-0 left-0"></div>
      )} */}
      <div className="w-full p-3 bg-[#282929] lg:text-center font-bold relative false">
        <h2
          className={`text-white flex gap-3 items-center lg:justify-center uppercase`}
        >
          {/* {category?.is_scratched && (
            <img alt="" src="/images/scratchCat.png" className="w-5 h-3" />
          )} */}
          {category} DIVISION
          {/* <span className="ml-[0.25rem]">({categoryEntryCount})</span> */}
        </h2>
      </div>
      <ScoresTable
        entries={categoryEntries}
        studioData={studioData}
        eventScores={eventScores}
        calculatePosition={calculatePosition}
        categoryEntries={categoryEntries}
        allEntries={allEntries}
        entryCount={entryCount}
        currentEvent={currentEvent}
        category={category}
        showNotes={showNotes}
        setShowNotes={setShowNotes}
        calculateAverageScore={calculateAverageScore}
        calculateTotalScore={calculateTotalScore}
      />
    </div>
  );
};

export default ScoresCategory;
