import { useSelector } from "react-redux";
import EventCard from "./components/EventCard";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import {
  toggleAddEvent,
  getEvents,
} from "../../redux/eventManagement/eventManagement";
import { useDispatch } from "react-redux";
import AddEventForm from "./components/AddEventForm";
import ScrollToTop from "./ScrollToTop";
import EditEventForm from "./components/EditEventForm";
import { useEffect } from "react";
import { auth } from "../../services/firebase";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { PulseLoader } from "react-spinners";

const EventSelection = () => {
  const {
    addEvent,
    deleteEventSuccess,
    editEventSuccess,
    editEvent,
    editID,
    addToFaveSuccess,
    removeFromFaveSuccess,
    openNotifsBoard,
  } = useSelector((state) => state.eventManagement);

  //getting the user ID from auth
  // const user = auth?.currentUser;
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteEventSuccess,
    editEventSuccess,
    addToFaveSuccess,
    removeFromFaveSuccess,
  ]);

  //to get realtime events from firestore
  const { status, data } = useQueryAllDocs("competitions");
  const organizerComp = data?.filter((item) => item?.compId === user?.uid)[0];
  const realtimeEvents = organizerComp?.events;

  // console.log('realtimeEvents', realtimeEvents);
  const sortEventsByStartDate = (events) => {
    return events?.sort((a, b) => {
      const startDateA = new Date(a.days[0]?.startTime);
      const startDateB = new Date(b.days[0]?.startTime);
      return startDateA - startDateB;
    });
  };

  // Inside your EventSelection component, before returning the JSX
  const sortedEvents = sortEventsByStartDate(realtimeEvents);

  //to get current event being editteda
  const eventToEdit = editID
    ? realtimeEvents.filter((item) => item.event_uid === editID)[0]
    : null;

  // console.log("realtimeEvents", realtimeEvents);

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && !editEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            <div className="block sm:hidden">
              <h2 className="text-white/80 mb-4 text-end">Event Selection</h2>
              {/* <div className="flex gap-3 items-center justify-end mt-[-12px]">
                <img
                  alt="user"
                  src="/images/Star.png"
                  className="w-4 h-4 cursor-pointer"
                />
              </div> */}
            </div>
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>All Events</h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Event container */}
            <div className="w-full lg:max-w-[85%] lg:min-w-[600px] grid grid-flow-row gap-5 sm:gap-8 grid-cols-1 md:grid-cols-2 grid-auto-rows-auto mb-auto relative">
              {realtimeEvents &&
                realtimeEvents?.map((item, index) => {
                  return (
                    <EventCard
                      item={item}
                      key={index}
                      index={index}
                      organizerComp={organizerComp}
                    />
                  );
                })}
              {status === "loading" ? (
                <div className="bg-[#1c1c1c] w-full min-h-[260px] flex justify-center items-center absolute top-0 left-0">
                  <PulseLoader color="#94a4fd" size={20} />
                </div>
              ) : (
                <div className="min-w-[200px] min-h-[260px] max-w-full bg-[#282929] pl-6 pr-4 py-4 flex items-center justify-center rounded-lg text-white">
                  <button
                    onClick={() => dispatch(toggleAddEvent())}
                    className="text-[.85rem] px-5 py-1 mr-4 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
                  >
                    Add Event
                  </button>
                </div>
              )}
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>Add Event</h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddEventForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the edit event pop up page */}

      {editEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>
                Edit Event: {eventToEdit?.event_name}{" "}
                {eventToEdit?.year?.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <EditEventForm eventToEdit={eventToEdit} />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default EventSelection;
