/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "services/firebase";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";

const JudgeQualifications = () => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const [formErr, setformErr] = useState("");

  const { competitionName } = useParams();
  const { data } = useQueryAllDocs("organizers");

  const selectedCompetitionName = competitionName?.replace(/_/g, " ");
  const allGenres = mergeDanceGenresObjects(data, selectedCompetitionName);

  const formattedGenres = {};
  for (const key in allGenres) {
    let formattedKey = key.toLowerCase().replace(/ /g, "_");
    if (formattedKey === "song_&_dance") {
      formattedKey = "song_and_dance";
    }
    formattedGenres[formattedKey] = true;
  }
  const checkboxData = deriveArrayFromMergedGenres(formattedGenres);

  const [qualifications, setQualifications] = useState(formattedGenres);
  useEffect(() => {
    setQualifications(formattedGenres);
  }, [data]);

  // console.log("qualifications", qualifications);

  const handleQualificationChange = (event, item) => {
    const { id, checked } = event.target;

    setformErr("");

    setQualifications((prevQualifications) => ({
      ...prevQualifications,
      [id]: checked,
    }));
  };

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (Object.values(qualifications).some((value) => value === true)) {
      try {
        setLoading(true);

        await updateDoc(doc(db, "judges", user?.uid), {
          qualifications,
          formCompleted: true,
        });

        console.log("Qualifications saved successfully!");
        navigate(`/judge/${competitionName}`);
      } catch (error) {
        // Handle errors
        console.error("Error saving qualifications:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setformErr("Select at least one dance genre");
    }
  };

  // console.log('allGenres:', allGenres);
  function mergeDanceGenresObjects(objectsArray, competitionName) {
    const selectedCompetition = objectsArray?.find(
      (entry) => entry.competitionName === competitionName
    );

    if (!selectedCompetition) {
      return {}; // Return an empty object if the competition is not found.
    }

    const mergedGenres = { ...selectedCompetition.danceGenres };

    return mergedGenres;
  }

  function deriveArrayFromMergedGenres(mergedGenres) {
    return Object.keys(mergedGenres).map((key) => ({
      id: key?.replace(/ /g, "_").toLowerCase(),
      label: key.charAt(0).toLowerCase() + key.slice(1).replace(/_/g, " "),
    }));
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <main className="w-full h-screen overflow-y-auto bg-[#1C1C1C] p-5 flex justify-center items-center relative text-white/60">
      <button
        onClick={goBack}
        className="px-5 py-1 bg-white/80 hover:bg-white/50 text-black rounded-lg text-[.75rem] md:text-[1rem] absolute top-5 md:top-12 left-3"
      >
        Back
      </button>
      <div className="w-full md:w-[500px] min-h-[500px] flex flex-col gap-4 md:gap-5">
        <h1 className="text-white text-[1.5rem] md:text-[2rem] font-medium">
          Qualifications
        </h1>
        <p>
          Select ALL the Dance Genres that you are qualified to judge: TECHNIQUE{" "}
        </p>
        <form className="mt-8" onSubmit={handleSubmit}>
          <div className="w-full h-[320px] flex flex-col flex-wrap gap-3">
            {checkboxData?.map((checkbox) => (
              <div className="flex items-center" key={checkbox.id}>
                <input
                  type="checkbox"
                  id={checkbox.id}
                  className="mr-2"
                  checked={qualifications[checkbox.id]}
                  onChange={(e) =>
                    handleQualificationChange(e, qualifications[checkbox.id])
                  }
                />
                <label htmlFor={checkbox.id} className="text-white/50">
                  {checkbox.label}
                </label>
              </div>
            ))}
          </div>

          {formErr && <p className="text-red-500">{formErr}</p>}

          <div className="w-full text-right">
            <button
              onClick={handleSubmit}
              disabled={loading}
              className="p-4 w-[70%] min-w-fit bg-blue-600 text-white rounded-md mt-6 flex justify-between gap-2 items-center"
            >
              <span className="w-full">
                {loading ? "Processing..." : "Submit"}
              </span>
              {loading && <ButtonLoadingSpinner />}
            </button>
          </div>
        </form>
      </div>
    </main>
  );
};

export default JudgeQualifications;
