import moment from "moment";
import React from "react";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import calculateEntryStartTime from "utils/calculateEntryStartTime";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import removeDuplicates from "utils/removeDuplicates";
import sumTimeValues from "utils/sumTimeValues";
import MessageNoteCard from "./MessageNoteCard";
import { useDispatch } from "react-redux";
import { setCurrentSessionIndex } from "redux/emcee/emceeSlice";

const EmceeSearchResult = ({
  searchResults,
  searchTerm,
  clearSearch,
  currentEvent,
  allEntries,
  sortedCategories,
  compId,
  setDisplayName,
}) => {
  const dispatch = useDispatch();

  return (
    <div
      className={`w-full h-screen bg-[#1c1c1c]/90 fixed top-[64px] md:top-[80px] left-0 text-white/80 sm:px-[20px] lg:pl-[35px] pr-[2px] overflow-auto lg:pr-[32px] z-[3]`}
    >
      <div className="w-full h-[50%] min-h-[300px] bg-[#1c1c1c] rounded-b-xl shadow-lg flex flex-col overflow-auto border border-white/20 relative pb-[70px]">
        <div className="border-b border-[#282929] p-5 text-white uppercase flex justify-between">
          {/* <h2>Entries Search</h2> */}
          {searchResults.length > 0 && (
            <h1 className="w-fit text-[#94a4fd] text-[.9em]">
              All results for "<span className="font-bold">{searchTerm}</span>"
            </h1>
          )}
          <div
            onClick={clearSearch}
            className="w-6 h-6 flex justify-center items-center bg-white/80 rounded-full cursor-pointer"
          >
            <img
              alt="user"
              src="/images/icons8-close-50.png"
              className="w-4 h-4"
            />
          </div>
        </div>
        <div className="w-full flex flex-col text-[.9em] mb-auto">
          {searchResults?.length > 0 ? (
            searchResults.map((item, idx) => {
              //===============================================================================to calc dynamic entry count of all entries in routine
              const categories = sortedCategories?.filter(
                (l) => l?.type === "category"
              );

              const allEntriesWithoutLocked = arrangeEntriesByCategories(
                categories,
                allEntries
              )?.filter((k) => k?.locked_entry === false);

              const allEntriesWithLocked = arrangeEntriesByCategories(
                categories,
                allEntries
              );

              // console.log("allEntriesWithLocked", allEntriesWithLocked);

              function entryCount(itm) {
                const allEntriesBeforeLocked = allEntriesWithLocked
                  ?.slice(
                    0,
                    findIndexByProperty(
                      allEntriesWithLocked,
                      "doc_id",
                      itm?.doc_id
                    )
                  )
                  ?.filter((m) => m?.locked_entry === false);

                if (itm?.locked_entry) {
                  return `${
                    allEntriesBeforeLocked?.length === 0
                      ? 1
                      : allEntriesBeforeLocked?.length
                  }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
                } else {
                  return (
                    findIndexByProperty(
                      allEntriesWithoutLocked,
                      "doc_id",
                      itm?.doc_id
                    ) + 1
                  );
                }
              }

              //============================================================================================new entry time calculation

              // to get all categories before current category
              const categoriesBeforeCurrent =
                sortedCategories?.slice(
                  0,
                  findIndexByProperty(
                    sortedCategories,
                    "category_name",
                    item?.category_name
                  )
                ) || [];

              const allBufferBeforeCurrent = categoriesBeforeCurrent?.filter(
                (cate) => cate?.type === "buffer"
              );

              const timeToAdd = allBufferBeforeCurrent?.reduce((sum, obj) => {
                if (obj.type === "buffer") {
                  const bufferValue = Number(obj.buffer);
                  if (!isNaN(bufferValue)) {
                    return sum + bufferValue;
                  }
                }
                return sum;
              }, 0);

              function addMinutesToTime(inputTime, minutesToAdd) {
                const timeMoment = moment(inputTime, "h:mm A");
                const endTime = timeMoment.clone().add(minutesToAdd, "minutes");
                const formattedEndTime = endTime.format("h:mm A");
                return formattedEndTime;
              }

              const startTime = currentEvent?.days[0]?.startTime;
              const initialTime = moment(startTime).utc().format("h:mm A");

              //to get the entry count of all entries in routine
              function entryTime(itm) {
                const idx = findIndexByProperty(
                  removeDuplicates(allEntriesWithoutLocked),
                  "doc_id",
                  itm?.doc_id
                );
                if (idx === 0) {
                  return addMinutesToTime(initialTime, timeToAdd);
                } else {
                  const allEntriesBeforeCurrent = removeDuplicates(
                    allEntriesWithoutLocked
                  )?.slice(
                    0,
                    findIndexByProperty(
                      removeDuplicates(allEntriesWithoutLocked),
                      "doc_id",
                      itm?.doc_id
                    )
                  );

                  const allTimeBeforeCurrent = sumTimeValues(
                    allEntriesBeforeCurrent
                  );
                  const startTime = calculateEntryStartTime(
                    initialTime,
                    allTimeBeforeCurrent,
                    itm
                  );

                  return addMinutesToTime(startTime, timeToAdd);
                }
              }

              const time = item?.locked_entry ? "AFTER LOCK" : entryTime(item);

              const scrollToSource = (id) => {
                clearSearch();
                const sessionElement = document.getElementById(id);
                if (sessionElement) {
                  sessionElement.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });

                  setDisplayName(
                    `Entry: ${capitalizeFirstLetter(
                      item?.entry_name
                    )} scrolled into view`
                  );
                  setTimeout(() => {
                    setDisplayName("");
                  }, 3000);
                }
              };

              const allSessionsBeforeCurrent = categoriesBeforeCurrent?.filter(
                (cate) => cate?.break_type === "session"
              );

              return (
                <div
                  key={idx}
                  onClick={() => {
                    dispatch(
                      setCurrentSessionIndex(
                        allSessionsBeforeCurrent?.length - 1
                      )
                    );
                    setTimeout(() => {
                      scrollToSource(item?.category_name);
                    }, 1000);
                  }}
                  className={`w-full min-w-[800px] sm:min-w-0 flex p-3 border-b border-white/10 hover:bg-[#94a4fd]/30 relative text-[.75rem] lg:text-[.85rem] cursor-pointer text-white`}
                >
                  {item?.is_scratched && (
                    <div className="w-full h-full bg-stone-700/60 absolute top-0 left-0"></div>
                  )}
                  <div className="flex gap-5 lg:gap-[50px] mr-auto">
                    <div className="flex gap-2 lg:gap-4">
                      <img
                        alt="arrow"
                        src={`${
                          item?.is_scratched
                            ? "/images/scratchCat.png"
                            : item?.checkedIn
                            ? "/images/CheckSquareOffset.png"
                            : "/images/CheckSquareOffset2.png"
                        }`}
                        className={`lg:w-5 w-4 cursor-pointer ${
                          item?.is_scratched ? "lg:h-4 h-3 mt-1" : "lg:h-5 h-4"
                        }`}
                      />

                      <img
                        alt="arrow"
                        src={`${
                          item?.is_scratched
                            ? "/images/scratchCat.png"
                            : item?.performance_ready
                            ? "/images/checkmark.png"
                            : "/images/Checkmark2.png"
                        }`}
                        className={`lg:w-5 w-4 cursor-pointer ${
                          item?.is_scratched ? "lg:h-4 h-3 mt-1" : "lg:h-5 h-4"
                        }`}
                      />
                    </div>
                    <p
                      className={`min-w-[65px] whitespace-nowrap ${
                        item?.locked_entry && "text-white/40 text-[.8em]"
                      }`}
                    >
                      {currentEvent?.locked ? item?.locked_entry_time : time}
                    </p>
                    <p className="min-w-[30px]">
                      {currentEvent?.locked
                        ? item?.locked_entry_count
                        : entryCount(item)}
                    </p>
                    <p className="w-[120px] lg:w-[200px]">
                      {capitalizeFirstLetter(item?.entry_name)}
                    </p>
                  </div>
                  <div className="w-[50%] flex gap-5 lg:gap-[50px]">
                    <p className="">{item?.studio_letter}</p>
                    <div
                      className={`mr-auto whitespace-nowrap w-[250px] px-2 rounded-md relative ${
                        item?.dancers_selected?.length > 3 ? "truncate" : ""
                      }`}
                    >
                      {item?.dancers_selected?.map((dancer, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && ", "}
                          <span>
                            {capitalizeFirstLetter(dancer?.first_name)}{" "}
                            {capitalizeFirstLetter(dancer?.last_name)}
                          </span>
                        </React.Fragment>
                      ))}
                    </div>

                    <div className="flex gap-1 items-center">
                      <img
                        alt="person"
                        src={
                          item?.isDancerStarts
                            ? "/images/PersonGreen.png"
                            : "/images/Person.png"
                        }
                        className="lg:w-5 lg:h-5 w-4 h-4"
                      />
                      <img
                        alt="arrow"
                        src={
                          item?.isRunAround
                            ? "/images/runaround-green.png"
                            : "/images/runaround.png"
                        }
                        className={`lg:w-5 lg:h-5 w-4 h-4`}
                      />
                      <img
                        alt="checked"
                        src={
                          item?.isCheckedProps
                            ? "/images/ClockGreen.png"
                            : "/images/Clock.png"
                        }
                        className="lg:w-5 lg:h-5 w-4 h-4"
                      />
                      <MessageNoteCard item={item} compId={compId} />
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-center pt-[80px] text-white/60 text-[1rem]">
              No results for keyword "
              <span className="font-bold">{searchTerm}</span>"
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmceeSearchResult;
