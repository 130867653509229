import { useDispatch } from "react-redux";
import {
  saveGenreVisibility,
  saveLiveUpdate,
  updateNow,
} from "redux/eventManagement/eventManagement";

const TopScorersSettingsModal = ({
  toggleSelect,
  selectedEvent,
  user,
  getCurrentFormattedTime,
  updateNowPending,
  selectAll,
  selectNone,
  checkboxes,
  checkboxData,
  handleCheckboxChange,
  setSelectEvent,
}) => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => toggleSelect()}
      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999] text-white/80 overflow-y-auto"
    >
      <div className="rounded-lg p-6 w-full lg:w-1/2 min-w-[300px] h-[200px] mx-auto">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-[#282929] rounded-lg p-6 relative scale"
        >
          <div
            className="w-6 h-6 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
            onClick={() => toggleSelect()}
          >
            <img
              alt="user"
              src="/images/icons8-close-50.png"
              className="w-4 h-4"
            />
          </div>
          <h2 className="font-bold mb-3">Event: {selectedEvent?.event_name}</h2>
          <div className="w-full my-5 border-y border-white/30 pt-3">
            {/* <p>Toggle top scorers update mode</p> */}
            <p>
              <span className="text-white/60">Current update mode:</span>{" "}
              {selectedEvent?.live_update ? "LIVE" : "MANUAL"}
            </p>

            <div className="w-full flex gap-3 items-center mb-4 mt-2">
              <button
                onClick={() => {
                  dispatch(
                    saveLiveUpdate({
                      uid: user?.uid,
                      event_uid: selectedEvent?.event_uid,
                      live_update: true,
                    })
                  );
                }}
                className={`text-[.8rem] leading-tight px-3 py-1 rounded-full text-[#1c1c1c] transition-all duration-300 hover:bg-[#94a4fd]/60 flex items-center gap-2 ${
                  selectedEvent?.live_update ? "bg-[#94a4fd]" : "bg-white/60"
                }`}
              >
                Live Update
                {selectedEvent?.live_update && (
                  <img
                    alt=""
                    src="/images/icons8-check-48.png"
                    className="w-5 h-4"
                  />
                )}
              </button>
              <button
                onClick={() => {
                  dispatch(
                    saveLiveUpdate({
                      uid: user?.uid,
                      event_uid: selectedEvent?.event_uid,
                      live_update: false,
                    })
                  );
                }}
                className={`text-[.8rem] leading-tight px-3 py-1 rounded-full text-[#1c1c1c] transition-all duration-300 hover:bg-[#94a4fd]/60 flex items-center gap-2 ${
                  !selectedEvent?.live_update ? "bg-[#94a4fd]" : "bg-white/60"
                }`}
              >
                Manual Update
                {!selectedEvent?.live_update && (
                  <img
                    alt=""
                    src="/images/icons8-check-48.png"
                    className="w-5 h-4"
                  />
                )}
              </button>
            </div>

            {!selectedEvent?.live_update && (
              <p>
                <span className="text-white/60">Last updated:</span>{" "}
                {selectedEvent?.update_timestamp}
              </p>
            )}
            {!selectedEvent?.live_update && (
              <button
                onClick={() => {
                  dispatch(
                    updateNow({
                      compId: user?.uid,
                      uid: user?.uid,
                      event_name: selectedEvent?.event_name,
                      event_uid: selectedEvent?.event_uid,
                      update_timestamp: getCurrentFormattedTime(),
                    })
                  );
                }}
                disabled={updateNowPending}
                className={`text-[.8rem] leading-tight mt-2 px-5 py-1 mb-4 rounded-full text-[#1c1c1c] transition-all duration-300 bg-[#94a4fd] hover:bg-[#94a4fd]/60`}
              >
                {updateNowPending ? "Updating..." : "Update Now"}
              </button>
            )}
          </div>
          <p>Check genres to display its entries on top scorers screen</p>

          <div className="w-full flex gap-3 items-center my-4">
            <button
              onClick={() => {
                selectAll();
              }}
              className={`text-[.8rem] leading-tight px-5 py-1 rounded-full text-[#1c1c1c] transition-all duration-300 bg-[#94a4fd] hover:bg-[#94a4fd]/60`}
            >
              Select All
            </button>
            <button
              onClick={() => {
                selectNone();
              }}
              className={`text-[.8rem] leading-tight px-5 py-1 rounded-full text-[#1c1c1c] transition-all duration-300 bg-white/60 hover:bg-white/30`}
            >
              Select None
            </button>
          </div>

          <div className="w-full lg:h-[220px] lg:max-h-[420px] grid grid-cols-2 md:grid-cols-3 lg:flex flex-col flex-wrap gap-3 my-5">
            {checkboxes?.map((checkbox, idx) => (
              <div className="flex items-center" key={idx}>
                <input
                  type="checkbox"
                  id={checkbox}
                  className="mr-2 cursor-pointer"
                  checked={checkboxData?.[checkbox]}
                  onChange={(e) => handleCheckboxChange(e, checkboxData[idx])}
                />
                <label
                  htmlFor={checkbox}
                  className={`cursor-pointer ${
                    checkboxData?.[checkbox] ? "text-white/80" : "text-white/50"
                  }`}
                >
                  {checkbox}
                </label>
              </div>
            ))}
          </div>
          <button
            onClick={() => {
              dispatch(
                saveGenreVisibility({
                  uid: user?.uid,
                  event_uid: selectedEvent?.event_uid,
                  checkboxData,
                })
              )?.then(() => setSelectEvent(null));
            }}
            // disabled={allfalse?.length === 0}
            className={`text-[.9rem] px-8 py-1 rounded-full text-[#1c1c1c] transition-all duration-300 bg-[#94a4fd] hover:bg-[#94a4fd]/60`}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopScorersSettingsModal;
