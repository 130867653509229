/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  submitEvent,
  toggleOffEventAdded,
  toggleAddEvent,
} from "redux/eventManagement/eventManagement";

const AddEventForm = () => {
  const { eventAddedMod, addEventPending, addEventSuccess } = useSelector(
    (state) => state.eventManagement
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { data } = useQueryAllDocs("competitions");
  const organizerComp = data?.filter((item) => item?.compId === user?.uid)[0];

  const timestamp = new Date().getTime();

  const [formData, setFormData] = useState({
    uid: user.uid,
    event_name: "",
    days: [],
    year: "",
    city: "",
    address: "",
    venue: "",
    tax_rate: "",
    state: "",
    postal_code: "",
    country: "",
    studios_registered: 0,
    estimated_entries: 0,
    entries_submitted: 0,
    favorite: false,
    isMusicDownload: false,
    isRegistrationOpen: true,
    musicSubmittedCount: 0,
    timestamp: formatTimeStamp(timestamp),
    locked: false,
    event_uid: generateUID(),
    scores_visibility: true,
    studio_visibility: true,
    genre_visibility: organizerComp?.danceGenres || {},
    access: [],
    live_update: true,
    update_timestamp: "",
    rank_visibility: true,
  });

  // console.log("formData", formData);

  const currentyear = Number(
    formData?.timestamp?.split(", ")[
      formData?.timestamp?.split(", ")?.length - 1
    ]
  );

  //to handle form submit
  const [validationError, setValidationError] = useState("");
  function handleSubmit(e) {
    e.preventDefault();

    if (
      formData?.event_name &&
      formData.days?.length > 0 &&
      !formData.year.includes("undefined") &&
      formData?.city &&
      formData?.address &&
      formData?.state &&
      formData?.country &&
      formData?.postal_code &&
      formData?.tax_rate &&
      Number(formData?.year) >= currentyear
    ) {
      // dispatch(addNewEvent(formData));
      const data = { ...formData, event_name: formData?.event_name?.trim() };
      dispatch(submitEvent({ data, uid: user?.uid }));
    } else if (Number(formData?.year) < currentyear) {
      setValidationError(`Start date cannot be before ${currentyear}`);
    } else {
      setValidationError("Please fill all required fields!");
    }
  }

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [dates, setDates] = useState([]);
  // console.log("dates", dates);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [addDayErr, setAddDayErr] = useState("");

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
    setValidationError("");
    setAddDayErr("");
  };

  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
    setValidationError("");
    setAddDayErr("");
  };

  const handleStartDateChange = (event) => {
    setSelectedStartDate(event.target.value);
    setValidationError("");
    setAddDayErr("");
  };

  //==========================================================================to handle date select
  const handleAddDatesClick = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0]; // Get current date in 'YYYY-MM-DD' format

    if (
      selectedStartDate !== "" &&
      startTime !== "" &&
      endTime !== "" &&
      Number(startTime?.replace(/:/g, "")) < endTime?.replace(/:/g, "") &&
      selectedStartDate?.split("-")[0]?.length < 5 &&
      selectedStartDate >= currentDate // Check if selectedStartDate is today or in the future
    ) {
      // Check if selectedStartDate is already added
      if (dates.length > 0) {
        const lastStartDate = new Date(dates[dates.length - 1].startTime)
          .toISOString()
          .split("T")[0];
        const selectedStartDateWithoutTime = new Date(selectedStartDate)
          .toISOString()
          .split("T")[0];

        if (selectedStartDateWithoutTime === lastStartDate) {
          setAddDayErr("A day with this start date is already added.");
          return;
        }
      }

      //convert start time to iso string
      const startTimeIsoString = new Date(
        `${selectedStartDate}T${startTime}:00Z`
      ).toISOString();

      //convert end time to iso string
      const endTimeIsoString = new Date(
        `${selectedStartDate}T${endTime}:00Z`
      ).toISOString();

      setDates([
        ...dates,
        {
          // startDate: selectedStartDate,
          startTime: startTimeIsoString,
          endTime: endTimeIsoString,
        },
      ]);
      setSelectedStartDate("");
      setStartTime("");
      setEndTime("");
      setValidationError("");
      setAddDayErr(""); // Clear any previous error message
    } else if (!selectedStartDate) {
      setAddDayErr("Add a start date");
    } else if (!startTime) {
      setAddDayErr("Add a start time");
    } else if (!endTime) {
      setAddDayErr("Add an end time");
    } else if (
      Number(startTime?.replace(/:/g, "")) >= endTime?.replace(/:/g, "")
    ) {
      setAddDayErr("End time cannot be earlier or equal to start time");
    } else if (selectedStartDate?.split("-")[0]?.length > 4) {
      setAddDayErr(`Invalid year: "${selectedStartDate?.split("-")[0]}"`);
    } else if (selectedStartDate < currentDate) {
      setAddDayErr("Selected date should be today or in the future");
    }
  };

  const handleRemoveDateClick = (dateToRemove) => {
    setDates(dates.filter((date) => date !== dateToRemove));
  };

  function formatTimeStamp(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedTime = `${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }, ${month} ${day}, ${year}`;
    return formattedTime;
  }

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        days: dates,
        year: dates[0]?.startTime?.split("-")[0],
      };
    });
  }, [dates]);

  function handleFormChange(event) {
    const { id, value } = event.target;
    if ((id === "event_name" || id === "city") && /\d/.test(value)) {
      // Check if the value contains a number
      return; // Exit the function without updating the state
    }

    setValidationError("");
    setFormData((prev) => {
      return {
        ...prev,
        [id]: value?.trim(),
      };
    });
  }

  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (addEventSuccess) {
      setSuccess(true);
      const timeoutId = setTimeout(() => {
        setSuccess(false);
        dispatch(toggleOffEventAdded());
        dispatch(toggleAddEvent());
        navigate("/organizer/event-selection");
        window.location.reload();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [addEventSuccess]);
  return (
    <>
      <form className="">
        <div className="w-full sm:min-w-[400px] lg:w-[60%] md:min-w-[550px] min-h-[600px] bg-[#282929] rounded-xl p-4 sm:p-7 mx-auto flex flex-col gap-8">
          <div>
            <div
              className={`w-full lg:w-[80%] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.event_name &&
                "border border-red-500/70"
              }`}
            >
              <label
                htmlFor="event_name"
                className="text-[.75rem] text-white/40"
              >
                Event Name
              </label>
              <input
                type="text"
                id="event_name"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Versastyle Ottawa"
              />
            </div>
          </div>
          <div>
            <div
              className={`w-full lg:w-[80%] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.venue &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="venue" className="text-[.75rem] text-white/40">
                Venue
              </label>
              <input
                type="text"
                id="venue"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Scotia Bank Theatre"
              />
            </div>
          </div>
          <div>
            <div
              className={`w-full lg:w-[80%] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.address &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="address" className="text-[.75rem] text-white/40">
                Address
              </label>
              <input
                type="text"
                id="address"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. 123 Fake St."
              />
            </div>
          </div>

          <div className="w-full lg:w-[80%] flex lg:flex-row flex-col flex-wrap gap-3 justify-between ">
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError && !formData?.city && "border border-red-500/70"
              }`}
            >
              <label htmlFor="city" className="text-[.75rem] text-white/40">
                City
              </label>
              <input
                type="text"
                id="city"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Ottawa"
              />
            </div>
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.state &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="state" className="text-[.75rem] text-white/40">
                State/province
              </label>
              <input
                type="text"
                id="state"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Ontario"
              />
            </div>
          </div>
          <div className="w-full lg:w-[80%] flex lg:flex-row flex-col flex-wrap gap-3 justify-between">
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.postal_code &&
                "border border-red-500/70"
              }`}
            >
              <label
                htmlFor="postal_code"
                className="text-[.75rem] text-white/40"
              >
                Postal code
              </label>
              <input
                type="text"
                id="postal_code"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="M1M M2M"
              />
            </div>
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.country &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="country" className="text-[.75rem] text-white/40">
                Country
              </label>
              <input
                type="text"
                id="country"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. Canada"
              />
            </div>
          </div>
          <div className="w-full lg:w-[80%] flex lg:flex-row flex-col flex-wrap gap-3 justify-between ">
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.estimated_entries &&
                "border border-red-500/70"
              }`}
            >
              <label
                htmlFor="estimated_entries"
                className="text-[.75rem] text-white/40"
              >
                Max # of Total Entries
              </label>
              <input
                type="number"
                id="estimated_entries"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. 900"
              />
            </div>
            <div
              className={`w-full lg:w-[46%] lg:min-w-[180px] p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.tax_rate &&
                "border border-red-500/70"
              }`}
            >
              <label htmlFor="tax_rate" className="text-[.75rem] text-white/40">
                Tax rate
              </label>
              <input
                type="number"
                id="tax_rate"
                onChange={handleFormChange}
                className="bg-[#0c0c0d] outline-none text-white"
                placeholder="E.g. 15%"
              />
            </div>
          </div>
          <div>
            {/* <p className="text-[.85rem] text-white/50 mb-2">
              NOTE: Add dates in order of start to finish
            </p> */}
            {/* <p className="text-white">Day {dates.length + 1}</p> */}
            <div className="w-full flex flex-col gap-3">
              <div className="w-full md:w-1/2 flex flex-col gap-3">
                <div
                  className={`w-full p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                    addDayErr === "Add a start date" &&
                    "border border-red-400/70"
                  }`}
                >
                  <label
                    htmlFor="start"
                    className="text-[.75rem] text-white/40"
                  >
                    Date
                  </label>
                  <div className="flex gap-2 items-center mt-1">
                    <input
                      type="date"
                      id="start"
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      className="w-full bg-[#0c0c0d] outline-none text-white mr-auto"
                      placeholder="Select date & time"
                    />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/2 flex flex-col gap-3">
                <div
                  className={`w-full p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                    addDayErr === "Add a start time" &&
                    "border border-red-400/70"
                  }`}
                >
                  <label
                    htmlFor="startTime"
                    className="text-[.75rem] text-white/40"
                  >
                    Start time:
                  </label>
                  <input
                    type="time"
                    id="startTime"
                    value={startTime}
                    onChange={handleStartTimeChange}
                    className="bg-[#0c0c0d] outline-none text-white w-full mt-1"
                  />
                </div>

                <div
                  className={`w-full p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 relative ${
                    addDayErr === "Add an end time" &&
                    "border border-red-400/70"
                  }`}
                >
                  <label
                    htmlFor="endTime"
                    className="text-[.75rem] text-white/40"
                  >
                    End time:
                  </label>
                  <input
                    type="time"
                    id="endTime"
                    value={endTime}
                    onChange={handleEndTimeChange}
                    className="bg-[#0c0c0d] outline-none text-white w-full mt-1"
                  />
                </div>
              </div>
            </div>
            {addDayErr && (
              <p className="text-red-400 text-[.85rem] mt-1">{addDayErr}</p>
            )}
            {validationError && dates?.length === 0 && (
              <p className="text-red-400 text-[.85rem] mt-1">
                Add at least one day
              </p>
            )}
            <button
              onClick={handleAddDatesClick}
              className="text-[.9rem] px-5 py-1 mt-2 mb-4 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300"
            >
              Add Day{" "}
            </button>
            <br />
            {dates
              ?.sort((a, b) => {
                // Parse dates and compare them
                const dateA = new Date(a?.startTime);
                const dateB = new Date(b?.startTime);
                return dateA - dateB;
              })
              .map((date, index) => (
                <div
                  key={index}
                  className="w-fit text-white/80 text-[.85rem] bg-[#0c0c0d] pl-3 pr-7 py-4 my-2 rounded-xl flex flex-col flex-wrap gap-2 relative"
                >
                  <p className="leading-[.75rem]">
                    Date:{" "}
                    <span className="font-bold">
                      {date?.startTime?.split("T")[0]}
                    </span>
                  </p>
                  <p className="leading-[.75rem]">
                    Time:{" "}
                    <span className="font-bold">
                      {`${moment(date?.startTime)
                        .utc()
                        .format("HH:mm")} - ${moment(date?.endTime)
                        .utc()
                        .format("HH:mm")}`}
                    </span>
                  </p>
                  <div
                    onClick={() => handleRemoveDateClick(date)}
                    className="w-4 h-4 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-1 right-1"
                  >
                    <img
                      alt="user"
                      src="/images/icons8-close-50.png"
                      className="w-3 h-3"
                    />
                  </div>
                </div>
              ))}
            {validationError && (
              <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-xl">
                {validationError}
              </div>
            )}
          </div>
        </div>
        <div className="w-full sm:w-[500px] lg:w-[60%] mx-auto mt-7 text-end">
          <button
            onClick={(e) => {
              e.preventDefault();
              dispatch(toggleAddEvent());
            }}
            className="text-[.85rem] px-5 py-1 mr-4 rounded-lg bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
          >
            Discard
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            disabled={addEventPending}
            className="text-[.9rem] px-5 py-1 rounded-lg bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300"
          >
            {addEventPending ? "Adding..." : "Save Changes"}
          </button>
        </div>
      </form>

      {/* the submitting loader */}
      {success && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white text-center">
              Successfully Added Event:{" "}
              <span className="font-bold">{formData.event_name}</span>
            </p>
          </div>
        </div>
      )}

      {/* //the event addded successfully modal */}
      {eventAddedMod && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <div
              onClick={() => {
                dispatch(toggleOffEventAdded());
                dispatch(toggleAddEvent());
              }}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white">
              New event:{" "}
              <span className="font-bold">{formData.event_name}</span> created
              successfully.
              <br /> We hope your event is a great success!
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default AddEventForm;
