import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { closeDropboard } from "redux/eventManagement/eventManagement";
import reportsNav from "../dummyData/reportsNav.json";
// import userManagementNav from "../dummyData/userManagementNav.json";
import { useState } from "react";

const SideBarCompetition = ({
  eventSelect,
  toggleOpenReports,
  openReports,
  openUserManagement,
  toggleOpenUserManagement,
}) => {
  const location = useLocation();
  const currentPage = location.pathname;
  const navigate = useNavigate();
  const { event_name } = useParams();
  const dispatch = useDispatch();

  const [openReg, setOpenReg] = useState(false);
  function toggleReg() {
    setOpenReg((prev) => !prev);
  }

  const [openSettings, setOpenSettings] = useState(false);
  function toggleSettings() {
    setOpenSettings((prev) => !prev);
  }

  return (
    <>
      {" "}
      <nav className="mt-6 text-[.85rem]">
        <div>
          <div className="">
            <p className="text-white/40">Competition</p>
          </div>
        </div>

        <div className="mt-3">
          <div
            onClick={() => {
              toggleReg();
            }}
            className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
              currentPage?.includes("/registrations") && "bg-[#282929]"
            }`}
          >
            {currentPage?.includes("/registrations") && (
              <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
            )}
            <img
              alt="arrow"
              src="/images/ArrowDown.png"
              className={`w-5 h-5 ${
                openReg && "rotate-[180deg]"
              } transition-all duration-300`}
            />
            <div className="flex gap-2 items-center">
              <img
                alt="card"
                src="/images/IdentificationCard.png"
                className="w-5 h-5"
              />

              <p>Registrations</p>
            </div>
          </div>
          {(openReg || currentPage?.includes("registrations")) && (
            <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
              <div
                onClick={() => {
                  navigate(
                    `/organizer/event/${event_name?.replace(
                      / /g,
                      "-"
                    )}/registrations`
                  );
                  dispatch(closeDropboard());
                }}
                className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
              >
                <p className="">Studios</p>
              </div>
              <div
                onClick={() => {
                  navigate(
                    `/organizer/event/${event_name?.replace(
                      / /g,
                      "-"
                    )}/registrations-dancers`
                  );
                  dispatch(closeDropboard());
                }}
                className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
              >
                <p className="">Dancers</p>
              </div>
            </div>
          )}

          <div
            onClick={() => {
              dispatch(closeDropboard());
              navigate(
                `/organizer/event/${event_name?.replace(/ /g, "-")}/schedule`
              );
            }}
            className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
              currentPage?.includes("/schedule") && "bg-[#282929]"
            }`}
          >
            {currentPage?.includes("/schedule") && (
              <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
            )}
            <div className="flex gap-2 items-center">
              <img
                alt="building"
                src="/images/BookOpen.png"
                className="w-5 h-5"
              />

              <p>Schedule</p>
            </div>
          </div>

          <div
            onClick={() => {
              toggleOpenReports();
            }}
            className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
              currentPage?.includes("/report") && "bg-[#282929]"
            }`}
          >
            {currentPage?.includes("/report") && (
              <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
            )}
            <img
              alt="arrow"
              src="/images/ArrowDown.png"
              className={`w-5 h-5 ${
                openReports && "rotate-[180deg]"
              } transition-all duration-300`}
            />
            <div className="flex gap-2 items-center">
              <img
                alt="building"
                src="/images/Notebook.png"
                className="w-5 h-5"
              />

              <p>Reports</p>
            </div>
          </div>
          {(openReports ||
            currentPage?.includes("-award") ||
            currentPage?.includes("-report")) && (
            <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
              {reportsNav?.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      navigate(
                        `/organizer/event/${event_name?.replace(/ /g, "-")}/${
                          item?.link
                        }`
                      );
                      dispatch(closeDropboard());
                    }}
                    className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                  >
                    <p className="">{item?.title}</p>
                  </div>
                );
              })}
            </div>
          )}

          <div
            onClick={() => {
              dispatch(closeDropboard());
              navigate(
                `/organizer/event/${event_name?.replace(/ /g, "-")}/music`
              );
            }}
            className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 py-1 pr-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
              currentPage?.includes("/music") && "bg-[#282929]"
            }`}
          >
            {currentPage?.includes("/music") && (
              <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
            )}
            <div className="flex gap-2 items-center">
              <img alt="card" src="/images/MusicNote.png" className="w-5 h-5" />

              <p>Music</p>
            </div>
          </div>

          <div
            onClick={() => {
              toggleSettings();
            }}
            className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
              currentPage?.includes("/competition-settings") && "bg-[#282929]"
            }`}
          >
            {currentPage?.includes("/competition-settings") && (
              <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
            )}
            <img
              alt="arrow"
              src="/images/ArrowDown.png"
              className={`w-5 h-5 ${
                openSettings && "rotate-[180deg]"
              } transition-all duration-300`}
            />
            <div className="flex gap-2 items-center">
              <img
                alt="building"
                src="/images/GearSix.png"
                className="w-5 h-5"
              />
              <p>Settings</p>
            </div>
          </div>
          {(openSettings || currentPage?.includes("competition-settings")) && (
            <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
              <div
                onClick={() => {
                  navigate(
                    `/organizer/event/${event_name?.replace(
                      / /g,
                      "-"
                    )}/competition-settings/event-scores-visibility`
                  );
                  dispatch(closeDropboard());
                }}
                className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
              >
                <p className="">Event Score Visibility</p>
              </div>
              <div
                onClick={() => {
                  navigate(
                    `/organizer/event/${event_name?.replace(
                      / /g,
                      "-"
                    )}/competition-settings/event-studio-code`
                  );
                  dispatch(closeDropboard());
                }}
                className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
              >
                <p className="">Event Studio Code & Name Visibility (Judges)</p>
              </div>
              <div
                onClick={() => {
                  navigate(
                    `/organizer/event/${event_name?.replace(
                      / /g,
                      "-"
                    )}/competition-settings/div-rank-visibility`
                  );
                  dispatch(closeDropboard());
                }}
                className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
              >
                <p className="">Division Rank Visibility (Studios)</p>
              </div>
              <div
                onClick={() => {
                  navigate(
                    `/organizer/event/${event_name?.replace(
                      / /g,
                      "-"
                    )}/competition-settings/genre-top-scorers`
                  );
                  dispatch(closeDropboard());
                }}
                className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
              >
                <p className="">Top Scores by Genre</p>
              </div>
            </div>
          )}
        </div>
      </nav>
      {/* user */}
      {/* <nav className="mt-6 text-[.85rem]">
        <div>
          <div className="">
            <p className="text-white/40">User</p>
          </div>
        </div>

        <div
          onClick={() => {
            toggleOpenUserManagement();
          }}
          className={`flex gap-2 mt-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
            currentPage?.includes("/user-management") && "bg-[#282929]"
          }`}
        >
          {/* {currentPage?.includes("/user-management") && (
            <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
          )} 
          <img
            alt="arrow"
            src="/images/ArrowDown.png"
            className={`w-5 h-5 ${
              openUserManagement && "rotate-[180deg]"
            } transition-all duration-300`}
          />
          <div className="flex gap-2 items-center">
            <img
              alt="card"
              src="/images/IdentificationBadge.png"
              className="w-5 h-5"
            />

            <p>User Management</p>
          </div>
        </div>
        {(openUserManagement ||
          currentPage?.includes("user-management") ||
          currentPage?.includes("studio-owners") ||
          currentPage?.includes("dancers") ||
          currentPage?.includes("judges") ||
          currentPage?.includes("all-emcees") ||
          currentPage?.includes("all-stage-managers")) && (
          <div className="ml-7 pl-3 border-l-2 border-white/20 text-[.75rem]">
            {userManagementNav?.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    navigate(
                      `/organizer/event/${event_name?.replace(/ /g, "-")}/${
                        item?.link
                      }`
                    );
                    dispatch(closeDropboard());
                  }}
                  className="text-white/80 hover:bg-white/20 bg-[#282929]/70  p-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer"
                >
                  <p className="">{item?.title}</p>
                </div>
              );
            })}
          </div>
        )}
      </nav> */}
      {/* accounting */}
      <nav className="mt-6 text-[.85rem]">
        <div>
          <div className="">
            <p className="text-white/40">Accounting</p>
          </div>
        </div>
        <div className="mt-3">
          <div
            onClick={() => {
              dispatch(closeDropboard());
              navigate(
                `/organizer/event/${event_name?.replace(
                  / /g,
                  "-"
                )}/accounting-overview`
              );
            }}
            className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 py-1 pr-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
              currentPage?.includes("/accounting-overview") && "bg-[#282929]"
            }`}
          >
            {currentPage?.includes("/accounting-overview") && (
              <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
            )}
            <div className="flex gap-2 items-center">
              <img alt="card" src="/images/Notebook.png" className="w-5 h-5" />

              <p>Overview</p>
            </div>
          </div>
          {/* <div
            onClick={() => {
              dispatch(closeDropboard());
              navigate(
                `/organizer/event/${event_name?.replace(
                  / /g,
                  "-"
                )}/accounting-invoice`
              );
            }}
            className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] pl-8 py-1 pr-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative ${
              currentPage?.includes("/accounting-invoice") && "bg-[#282929]"
            }`}
          >
            {currentPage?.includes("/accounting-invoice") && (
              <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
            )}
            <div className="flex gap-2 items-center">
              <img alt="building" src="/images/Gear.png" className="w-5 h-5" />

              <p>Settings</p>
            </div>
          </div> */}
        </div>
      </nav>
    </>
  );
};

export default SideBarCompetition;
