import React, { useState, useEffect } from "react";
import { PulseLoader } from "react-spinners";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { db, storage } from "../../../services/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { updateDoc, doc } from "firebase/firestore";

const MusicList = ({ uniqueEntries, compId }) => {
  const [loading, setLoading] = useState(true);
  const [musicEntries, setMusicEntries] = useState([]);
  const [selected, setSelected] = useState({});
  // music file upload
  const [musicFile, setMusicFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(null);

  const musicUpload = async () => {
    if (musicFile === null) return;

    try {
      setLoading(true);
      setUploadProgress(0);
      const storageRef = ref(storage, `music/${musicFile.name}`);

      const uploadTask = uploadBytesResumable(storageRef, musicFile);

      uploadTask.on("state_changed", (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress(progress);
      });

      await uploadTask;

      const url = await getDownloadURL(storageRef);

      // Construct the data object for updating the Firestore document
      const updateData = {
        music_url: url,
      };

      // Update the selected entry's music_url in Firestore
      if (selected?.doc_id) {
        const entryDocRef = doc(
          db,
          "competitions",
          compId,
          "entries",
          selected.doc_id
        );
        await updateDoc(entryDocRef, updateData);

        // Close the music upload section and update state
        setSelected({});
        setUploadComplete(musicFile.name);
      }
      setLoading(false);

      // Reset musicFile state after successful upload
      setMusicFile(null);
      // Reload the page to reflect the changes
      // window.location.reload();
    } catch (error) {
      console.error("Error uploading music:", error);
      setLoading(false);
    }
  };

  // extracting the music url from the Data
  const musicUrl = selected?.music_url
    ? selected?.music_url
        ?.slice(
          selected?.music_url?.indexOf("%") + 3,
          selected?.music_url?.indexOf("?")
        )
        .replace(/%20/g, " ")
    : null;

  const handleClick = (item) => {
    setSelected(item);
  };

  const handleClose = () => {
    setSelected({});
  };

  useEffect(() => {
    // Simulating an asynchronous data fetch
    setTimeout(() => {
      const filteredEntries = uniqueEntries?.filter(
        (musicEntry) => musicEntry.music_url
      );
      setMusicEntries(filteredEntries);
      setLoading(false);
    }, 2000);
  }, [uniqueEntries]);

  // Extracting the music name from the Data
  const getMusicName = (musicUrl) => {
    if (musicUrl) {
      const startIndex = musicUrl.indexOf("%") + 3;
      const endIndex = musicUrl.indexOf("?");
      return musicUrl.slice(startIndex, endIndex).replace(/%20/g, " ");
    }
    return null;
  };

  if (loading && musicEntries?.length === 0) {
    return (
      <div className="w-full h-[200px] flex justify-center items-center border border-white/10 rounded-lg">
        <PulseLoader color="#94a4fd" size={10} />
      </div>
    );
  }

  if (musicEntries?.length === 0) {
    return (
      <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
        <h4>No music added for this event yet</h4>
      </div>
    );
  }

  const entriesWithNoMusic = uniqueEntries?.filter(
    (musicEntry) => !musicEntry.music_url
  );

  const sortedEntriesWithNoMusic = entriesWithNoMusic?.sort((a, b) =>
    a.entry_name.localeCompare(b.entry_name)
  );

  // Sort musicEntries alphabetically by entryName
  const sortedMusicEntries = musicEntries?.sort((a, b) =>
    a.entry_name.localeCompare(b.entry_name)
  );

  return (
    <div className="w-full mt-4">
      <h2 className="text-lg font-semibold mb-2 text-white">
        Entries with Music
      </h2>
      {sortedMusicEntries?.map((musicItem, index) => {
        const musicUrl = musicItem.music_url;
        const musicName = getMusicName(musicUrl);
        const entryName = musicItem.entry_name;

        return (
          <div
            key={musicItem.id}
            onClick={() => handleClick(musicItem)}
            className="flex lg:flex-row gap-4 lg:items-center rounded-lg shadow-md w-full lg:py-3 lg:px-5 px-8 py-[0.25rem] mb-2  bg-white/10 xs:flex-col xs:items-start xs:p-[0.5rem] cursor-pointer hover:bg-white/20"
          >
            <p className="text-sm">{index + 1}.</p>
            <h3 className="text-sm mr-auto">
              <span className="font-semibold  pr-2"> Entry Name: </span>
              {capitalizeFirstLetter(entryName)}
            </h3>
            <p className="text-sm">
              <span className="font-semibold italic pr-2">Music: </span>{" "}
              {musicName}
            </p>
          </div>
        );
      })}
      {/* entries without music */}
      {sortedEntriesWithNoMusic?.length > 0 && (
        <div className="w-full mt-4">
          <h2 className="text-lg font-semibold mb-2 text-white">
            Entries without Music
          </h2>
          {sortedEntriesWithNoMusic?.map((entry, index) => (
            <div
              key={entry.id}
              onClick={() => handleClick(entry)}
              className="flex lg:flex-row gap-4 lg:items-center rounded-lg shadow-md w-full lg:py-3 lg:px-5 px-8 py-[0.25rem] mb-2  bg-white/10 xs:flex-col xs:items-start xs:p-[0.5rem] cursor-pointer hover:bg-white/20"
            >
              <p className="text-sm">{index + 1}.</p>
              <h3 className="text-sm mr-auto">
                <span className="font-semibold  pr-2"> Entry Name: </span>
                {capitalizeFirstLetter(entry.entry_name)}
              </h3>
              <p className="text-sm">
                <span className="font-semibold italic pr-2">Music: </span>{" "}
                {entry.music_url
                  ? getMusicName(entry.music_url)
                  : "No Music Uploaded"}
              </p>
            </div>
          ))}
        </div>
      )}

      {selected?.entry_name && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 text-center">
              {capitalizeFirstLetter(selected?.entry_name)} -{" "}
              <span className="text-white/80">
                {selected?.music_url ? "Edit Music" : "Add music"}
              </span>
            </p>

            <div className="w-full flex flex-col gap-3">
              <h2 className="font-bold text-white/80">Upload Music</h2>
              {musicUrl ? (
                <p className="font-bold text-white/50">
                  Music uploaded: {musicUrl}
                </p>
              ) : (
                <p className="font-bold text-white/50">No music uploaded</p>
              )}
              <div
                className={`w-full p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500  `}
              >
                <input
                  type="file"
                  id="music"
                  accept="audio/*"
                  onChange={(e) => setMusicFile(e.target.files[0])}
                  className="w-[70%] p-2 bg-[#0c0c0d] outline-none text-white rounded-xl"
                  placeholder="Select file"
                />
              </div>
              {uploadComplete && <p>Upload Complete: {uploadComplete}</p>}
            </div>

            <div className="flex mt-4 w-full text-center items-center justify-end">
              <button
                onClick={handleClose}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                type="button"
                className="flex w-fit text-[.9rem] px-8 py-2 rounded-md bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300"
                disabled={loading}
                onClick={musicUpload}
              >
                {loading ? (
                  <>
                    <span className="text-white mr-2">
                      Uploading {uploadProgress}%
                    </span>
                    <ButtonLoadingSpinner className="ml-2" />
                  </>
                ) : selected?.music_url ? (
                  "Update Music"
                ) : (
                  "Upload Music"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MusicList;
