import React from "react";

export default function Container(props) {
  return (
    <div
      className={`container lg:px-[40px] md:px-[40px] px-2 py-[40px] mx-auto ${
        props.className ? props.className : ""
      }`}
    >
      {props.children}
    </div>
  );
}
