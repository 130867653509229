/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import AddEventContainer from "./components/AddEventContainer";
import ScrollToTop from "./ScrollToTop";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import TabulatorCategory from "./components/TabulatorCategory";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { PulseLoader } from "react-spinners";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "services/firebase";
import TabulatorRightside from "./components/TabulatorRightside";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import ViewAwardsCont from "./components/ViewAwardsCont";
import { removeDuplicateObjectsByProperty } from "utils/removeDuplicatesByProperty";
import { useFetchScheduleDocs } from "components/hooks/useFetchScheduleDocs";
import ReactPaginate from "react-paginate";

const Tabulator = () => {
  const { addEvent, openNotifsBoard, currentEvent } = useSelector(
    (state) => state.eventManagement
  );

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { data: compData } = useFirestoreQueryDoc("competitions", user?.uid);
  const [entryTypes, setentryTypes] = useState([]);
  useEffect(() => {
    if (compData) {
      const { entryTypes } = compData;
      setentryTypes(entryTypes);
    }
  }, [compData]);

  //to get all categories
  // const { status, data: allCategories } = useQueryAllSubCollectionDocs(
  //   "competitions",
  //   user?.uid,
  //   "categories"
  // );

  //to get all entries
  const { status, data: eventEntries } = useFetchScheduleDocs(
    "competitions",
    user?.uid,
    "entries",
    "event_uid",
    currentEvent?.event_uid
  );

  // console.log("allEntries", allEntries);

  //to get all categories
  const { status: entryStatus, data: eventCategories } = useFetchScheduleDocs(
    "competitions",
    user?.uid,
    "categories",
    "event_uid",
    currentEvent?.event_uid
  );

  const category_nameArr = eventEntries?.map((x) => x?.category_name);

  const categoriesRaw = eventCategories
    ?.filter((y) => category_nameArr?.includes(y?.category_name))
    ?.concat(eventCategories?.filter((z) => z?.type !== "category"));

  let sortedCategoriesRaw = categoriesRaw?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  const [sortedCategories, setSortedCategories] = useState([]);

  // console.log("sortedCategories", sortedCategories);

  useEffect(() => {
    if (status === "success" && entryStatus === "success") {
      let arr = removeDuplicateObjectsByProperty(
        sortedCategoriesRaw,
        "category_name"
      );
      setSortedCategories(arr);
    }
  }, [entryStatus, status, eventCategories]);

  // //to get all entries
  // const { status: entryStatus, data: allEntries } = useFetchScheduleDocs(
  //   "competitions",
  //   user?.uid,
  //   "entries",
  //   "event_uid",
  //   currentEvent?.event_uid
  // );

  // const eventEntries = allEntries?.filter(
  //   (each) => each?.event_uid === currentEvent?.event_uid
  // );

  // //to get all categories
  // const { status, data: eventCategories } = useFetchScheduleDocs(
  //   "competitions",
  //   user?.uid,
  //   "categories",
  //   "event_uid",
  //   currentEvent?.event_uid
  // );

  // const category_nameArr = allEntries?.map((x) => x?.category_name);

  // const categoriesRaw = eventCategories
  //   ?.filter((y) => category_nameArr?.includes(y?.category_name))
  //   ?.concat(eventCategories?.filter((z) => z?.type !== "category"));

  // let sortedCategoriesRaw = categoriesRaw?.sort(
  //   (a, b) => a?.sort_id - b?.sort_id
  // );

  // const [sortedCategories, setSortedCategories] = useState([]);

  // // console.log("sortedCategories", sortedCategories);

  // useEffect(() => {
  //   if (status === "success") {
  //     let arr = removeDuplicateObjectsByProperty(
  //       sortedCategoriesRaw,
  //       "category_name"
  //     );
  //     setSortedCategories(arr);
  //   }
  // }, [entryStatus, status, eventCategories]);

  const [showNotes, setShowNotes] = useState("");
  const toggleNotes = (entry) => {
    setShowNotes(showNotes ? "" : entry);
  };

  const closeDropdown = () => {
    setShowNotes("");
  };

  // const eventCategories = allCategories
  //   ?.filter(
  //     (each) =>
  //       each?.event_uid === currentEvent?.event_uid && each?.type === "category"
  //   )
  //   ?.sort((a, b) => a?.sort_id - b?.sort_id);

  const arrangedEntries = removeDuplicates(
    arrangeEntriesByCategories(eventCategories, eventEntries)
  );

  const allPerfromed = arrangedEntries?.filter((x) => x?.performance_ready);

  const liveEntry = allPerfromed[allPerfromed?.length - 1];

  //==================================================================================to handle scores calculation
  const [scoresData, setScoresData] = useState([]);

  // const liveEntry = eventEntries?.filter(
  //   (item) => item?.is_presenting === true
  // )[0];

  const { data } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "scores"
  );

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "competitions", user?.uid, "scores")),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setScoresData(data);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [user?.uid]);

  useEffect(() => {
    setScoresData(data);
  }, [data]);

  const eventScores = scoresData?.filter(
    (item) => item?.event_name === currentEvent?.event_name
  );

  const [showTable, setShowTable] = useState(false);
  // const [showLiveTable, setShowLiveTable] = useState(true);

  const [currentEntryDocId, setCurrentEntryDocId] = useState("");

  function selectEntry(entry_name) {
    setShowTable(true);
    setCurrentEntryDocId(entry_name);
  }

  function unselectEntry() {
    setShowTable(false);
    setCurrentEntryDocId("");
  }

  const calculateAverageScore = (arr, key) => {
    if (Array.isArray(arr)) {
      const filteredArray = arr?.filter((itm) => itm?.technique);
      const array = key === "technique" ? filteredArray : arr;
      const totalSum = array?.reduce((sum, obj) => sum + Number(obj[key]), 0);
      const average = totalSum / array?.length;
      const averageFormatted = Number(average)?.toFixed(2);

      return averageFormatted;
    }
  };

  const { data: organizerData } = useFirestoreQueryDoc("organizers", user?.uid);

  const awards = organizerData?.awards;
  function findMatchingAward(awards, totalScore) {
    for (let i = 0; i < awards?.length; i++) {
      const award = awards[i];
      if (totalScore >= award.minScore && totalScore <= award.maxScore) {
        return award;
      }
    }
    return null;
  }

  function getDeduction(arr) {
    const lastCharacters = arr?.map((str) => {
      if (str?.length > 0) {
        return Number(str?.slice(-1));
      } else {
        return "";
      }
    });

    const ded = lastCharacters?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return ded;
  }

  const calculateTotalScore = (entry) => {
    const scores = eventScores?.filter(
      (item) =>
        item?.doc_id === entry?.doc_id &&
        item?.event_name === currentEvent?.event_name?.trim()
    );

    const allDeductions = scores
      ?.flatMap((obj) => obj.deductions)
      ?.filter((item) => item !== "none");

    const sum =
      [
        Number(calculateAverageScore(scores, "technique")),
        Number(calculateAverageScore(scores, "creativity")),
        Number(calculateAverageScore(scores, "staging")),
        Number(calculateAverageScore(scores, "execution")),
        Number(calculateAverageScore(scores, "performance")),
      ]?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) -
      getDeduction(allDeductions);

    const truncatedScore = parseFloat(sum?.toFixed(2));

    return isNaN(truncatedScore) ? "- -" : truncatedScore;
  };

  const calculateHighestScore = (categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    let highestScore = 0;

    filteredEntries?.forEach((entry) => {
      const scores = eventScores?.filter(
        (item) =>
          item?.doc_id === entry?.doc_id &&
          item?.event_name === currentEvent?.event_name
      );

      const totalScoreRaw = scores?.reduce(
        (sum, score) => sum + Number(score?.total || 0),
        0
      );

      const totalScore = totalScoreRaw / scores?.length;

      if (totalScore > highestScore) {
        highestScore = totalScore;
      }
    });

    return highestScore;
  };

  function removeDuplicates(array) {
    if (array) {
      const uniqueArray = [];
      const seenObjects = new Set();

      for (const obj of array) {
        // Serialize the object to JSON to use it as a unique identifier
        const serializedObj = JSON.stringify(obj);

        if (!seenObjects.has(serializedObj)) {
          seenObjects.add(serializedObj);
          uniqueArray.push(obj);
        }
      }

      return uniqueArray;
    }
  }

  const calculatePosition = (entry, categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    const highestScore = calculateHighestScore(filteredEntries);
    const entryScore = calculateTotalScore(entry);
    // Sort the scores in descending order
    const sortedScores = filteredEntries
      .map((entry) => calculateTotalScore(entry))
      .sort((a, b) => b - a);

    // Find the position of the current entry's score in the sorted scores array
    const position = sortedScores?.indexOf(entryScore) + 1;

    if (!highestScore || entryScore === "- -") {
      return "- -";
    } else if (entryScore === highestScore) {
      return `1st`;
    } else if (position === 1) {
      return `1st`;
    } else if (position === 2) {
      return `2nd`;
    } else if (position === 3) {
      return `3rd`;
    } else {
      return `${position}th`;
    }
  };

  const [showNames, setShowNames] = useState(null);

  const [viewAwards, setViewAwards] = useState(false);
  function toggleView() {
    setViewAwards((prev) => !prev);
  }

  //===================================================to efficiently paginate the tabulator
  const [pageNumber, setPageNumber] = useState(0);

  const categoryPerPage = 15;
  const pagesVisited = pageNumber * categoryPerPage;

  const eachCat = sortedCategories
    ?.slice(pagesVisited, pagesVisited + categoryPerPage)
    ?.map((category, index) => (
      <TabulatorCategory
        key={index}
        index={index}
        category={category}
        allEntries={eventEntries}
        sortedCategories={sortedCategories}
        currentEvent={currentEvent}
        toggleNotes={toggleNotes}
        showNotes={showNotes}
        entryTypes={entryTypes}
        calculatePosition={calculatePosition}
        calculateTotalScore={calculateTotalScore}
        findMatchingAward={findMatchingAward}
        awards={awards}
        liveEntry={liveEntry}
        currentEntryDocId={currentEntryDocId}
        selectEntry={selectEntry}
        setShowNames={setShowNames}
        showNames={showNames}
        allScores={eventScores}
      />
    ));

  const pageCount = Math.ceil(sortedCategories?.length / categoryPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {/* event details page */}
      {!addEvent && (
        <div
          onClick={() => {
            closeDropdown();
            setShowNames(null);
          }}
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto flex flex-col justify-between">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h1>
                {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h1>
              {/* <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              /> */}
            </div>
            {/* section for tabulator category */}
            {!viewAwards && (
              <>
                {(status === "loading" || entryStatus === "loading") && (
                  <div className="flex justify-center items-center h-[400px]">
                    <PulseLoader color="#94a4fd" size={15} />
                  </div>
                )}
                {sortedCategories &&
                  sortedCategories.length > 0 &&
                  status === "success" && (
                    <section className="w-full flex gap-7 justify-between">
                      <div className="w-full md:min-w-[30%] lg:w-[75%] text-[.9em] py-3 pb-[100px]">
                        <div className="w-full overflow-x-auto">
                          <div className="min-w-[1000px] w-full">{eachCat}</div>
                        </div>

                        {sortedCategories?.length > 15 && (
                          <div className="flex gap-2 justify-end mt-10">
                            <ReactPaginate
                              previousLabel={
                                <img
                                  alt="user"
                                  src="/images/leftArrow.png"
                                  className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/20 border border-white/30"
                                />
                              }
                              nextLabel={
                                <img
                                  alt="user"
                                  src="/images/rightArrow.png"
                                  className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/20 border border-white/30"
                                />
                              }
                              pageCount={pageCount}
                              onPageChange={changePage}
                              containerClassName={
                                "h-6 flex items-center gap-2 justify-end text-[.95rem] pag"
                              }
                              activeClassName={"rounded-lg bg-white/20"}
                            />
                          </div>
                        )}
                        <button
                          onClick={() => toggleView()}
                          className="text-[.85rem] px-8 py-1 mr-2 mt-10 whitespace-nowrap rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
                        >
                          View Awards
                        </button>
                      </div>

                      {/* right section */}
                      {/* {(showTable || liveEntry) && ( */}
                      <div className="fixed lg:top-[110px] right-0 overflow-y-auto w-[280px] lg:w-[21%] h-fit">
                        <TabulatorRightside
                          // showLiveTable={showLiveTable}
                          // setShowLiveTable={setShowLiveTable}
                          liveEntry={liveEntry}
                          calculateAverageScore={calculateAverageScore}
                          showTable={showTable}
                          unselectEntry={unselectEntry}
                          currentEntryDocId={currentEntryDocId}
                          selectEntry={selectEntry}
                          sortedCategories={sortedCategories}
                          eventScores={eventScores}
                          awards={awards}
                          findMatchingAward={findMatchingAward}
                          arrangedEntries={arrangedEntries}
                        />
                      </div>
                      {/* )} */}
                    </section>
                  )}

                {status === "success" &&
                  entryStatus === "success" &&
                  sortedCategories?.length === 0 && (
                    <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
                      <h4>No Routines Yet...</h4>
                    </div>
                  )}
              </>
            )}

            {viewAwards && (
              <ViewAwardsCont
                toggleView={toggleView}
                currentEvent={currentEvent}
                eventEntries={eventEntries}
              />
            )}
          </div>
          {/* section for tabulator category */}
          <ScrollToTop />
          {/* <Footer /> */}
        </div>
      )}
      {/* the add event pop up page */}
      {addEvent && <AddEventContainer />}
    </>
  );
};

export default Tabulator;
