export default function sumTimeValues(allEntriesBeforeCurrent) {
  let totalSeconds = 0;

  // Calculate the total seconds from the time values
  allEntriesBeforeCurrent?.forEach((entry) => {
    const [hours, minutes, seconds] = entry?.performance_time
      .split(":")
      .map(Number);
    totalSeconds += hours * 3600 + minutes * 60 + seconds;
  });

  // Convert the total seconds back to the "hh:mm:ss" format
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  // Format the result
  const formattedSum = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return formattedSum;
}
