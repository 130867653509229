import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { setFormCompleted } from 'redux/organizerRegistration/organizerSlices';

export const useAuth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is logged in
        if (user.formCompleted) {
          dispatch(setFormCompleted(true));
        } else {
          dispatch(setFormCompleted(false));
        }
      } else {
        // User is logged out
        dispatch(setFormCompleted(false));
      }
    });

    // Cleanup the listener
    return () => {
      unsubscribe();
    };
  }, [dispatch]);
};
