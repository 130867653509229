import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "services/firebase";
import { debounce } from "lodash";

// Helper function to update a single category in Firestore
const updateCategoryInFirestore = async (category, user) => {
  const collectionRef = collection(db, "competitions", user?.uid, "categories");
  const whereQuery = where("no_id", "==", category.no_id);
  const querySnapshot = query(collectionRef, whereQuery);
  const categoriesSnapshot = await getDocs(querySnapshot);

  if (categoriesSnapshot.docs?.length === 1) {
    const docRef = categoriesSnapshot.docs[0].ref;
    await updateDoc(docRef, { sort_id: category.sort_id });
  } else {
    console.error("Error: Document not found =>", category?.category_name);
  }
};

// Debounce function to limit the frequency of Firestore updates
const debouncedFirestoreUpdate = debounce(
  async (categories, user, setLoading) => {
    try {
      setLoading(true);
      for (const category of categories) {
        await updateCategoryInFirestore(category, user);
      }
      console.log("Background updates successful");
      localStorage.removeItem("affectedItems");
      setLoading(false);
    } catch (error) {
      console.error("Error updating category sort_id:", error);
    }
  },
  1000
); // Adjust the delay as needed

// Export the debounced function
export const performBackgroundUpdate2 = async (
  categories,
  user,
  setLoading
) => {
  debouncedFirestoreUpdate(categories, user, setLoading);
};
