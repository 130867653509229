import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import eventsDataJson from "../../components/DanceStudio/dummyData/eventsData.json";
// import competitionsDataJson from "../../components/DanceStudio/dummyData/competition.json";

import {
  getEntriesService,
  // addEntriesService,
  // deleteEntriesService,
  // editEntriesService,
  getDancersService,
  addDancersService,
  deleteDancersService,
  editDancersService,
  updateSelectedCompetition,
  removeSelectedCompetition,
  addScheduleEntryService,
  deleteScheduleEntryService,
  // editScheduleEntryService,
  editAccountDetailsService,
  editEntryService,
  addEstEntriesService,
  requestAgeOverrideService,
  sendContactMessageService,
} from "../../services/danceStudioService";
import { sendVerificationEmail } from "services/authenticationService";

const initialState = {
  searchResults: [],
  openDropboard: false,
  openSearch: false,
  openNotifsBoard: false,
  eventsData: [],
  competitionssData: [],
  addDancer: false,
  addDancerValidationError: null,
  addEntries: false,
  editEntries: false,
  editID: null,
  catID: "",
  editDancers: false,
  editIdDancers: null,
  addEntriesValidationError: null,
  dancerAddedMod: false,
  addDancerSuccess: "",
  addDancerFail: "",
  addDancerPending: false,
  dancerData: [],
  entriesData: [],
  notifications: [],

  getEntriesSuccess: null,
  getEntriesFail: null,
  getEntriesPending: false,
  addEntriesSuccess: null,
  addEntriesFail: null,
  addEntriesPending: false,
  deleteEntriesSuccess: null,
  deleteEntriesFail: null,
  deleteEntriesPending: false,
  editEntriesSuccess: null,
  editEntriesFail: null,
  editEntriesPending: false,

  dancersData: [],
  getDancersSuccess: null,
  getDancersFail: null,
  getDancersPending: false,
  addDancersSuccess: null,
  addDancersFail: null,
  addDancersPending: false,
  deleteDancersSuccess: null,
  deleteDancersFail: null,
  deleteDancersPending: false,
  editDancersSuccess: null,
  editDancersFail: null,
  editDancersPending: false,

  editAccountSuccess: null,
  editAccountFail: null,
  editAccountPending: false,

  overrideAgeSuccess: null,
  overrideAgeFail: null,
  overrideAgePending: false,

  sendContactSuccess: null,
  sendContactFail: null,
  sendContactPending: false,

  sendVerificationSuccess: null,
  sendVerificationFail: null,
  sendVerificationPending: false,

  competitionsData: [],
  selectCompSuccess: false,
  selectCompPending: false,
  removeCompSuccess: false,
  removeCompPending: false,

  printOptions: {},
  verifyEmailSent: false,
};
// console.log(dancerDataJson);

//to handle CRUD operatins for dancers
//===============================to handle get all entries async func
export const getDancers = createAsyncThunk(
  "danceStudioSlice/getDancers",
  async (data) => {
    const result = await getDancersService(data);
    return result;
  }
);

//===============================to handle add entries async func
export const addDancers = createAsyncThunk(
  "danceStudioSlice/addDancers",
  async (data) => {
    const result = await addDancersService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add entries async func
export const deleteDancers = createAsyncThunk(
  "danceStudioSlice/deleteDancers",
  async (data) => {
    const result = await deleteDancersService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit entries async func
export const editDancers = createAsyncThunk(
  "danceStudioSlice/editDancers",
  async (data) => {
    const result = await editDancersService(data);
    console.log(result);
    return result;
  }
);

//to handle CRUD operatins for entries
//===============================to handle get all entries async func
export const getEntries = createAsyncThunk(
  "danceStudioSlice/getEntries",
  async (data) => {
    const result = await getEntriesService(data);
    return result;
  }
);

//===============================to handle add entries async func
export const addEntries = createAsyncThunk(
  "danceStudioSlice/addEntries",
  async (data) => {
    // const result = await addEntriesService(data);
    const result = await addScheduleEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add entries async func
export const deleteEntries = createAsyncThunk(
  "danceStudioSlice/deleteEntries",
  async (data) => {
    const result = await deleteScheduleEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit entries async func
export const editEntries = createAsyncThunk(
  "danceStudioSlice/editEntries",
  async (data) => {
    const result = await editEntryService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle select comp async func
export const selectComp = createAsyncThunk(
  "danceStudioSlice/selectComp",
  async (data) => {
    const result = await updateSelectedCompetition(data);
    console.log(result);
    return result;
  }
);

//===============================to handle age division override request
export const overrideAge = createAsyncThunk(
  "danceStudioSlice/overrideAge",
  async (data) => {
    const result = await requestAgeOverrideService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle select comp async func
export const addEst = createAsyncThunk(
  "danceStudioSlice/addEst",
  async (data) => {
    const result = await addEstEntriesService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle select comp async func
export const removeComp = createAsyncThunk(
  "danceStudioSlice/removeComp",
  async (data) => {
    const result = await removeSelectedCompetition(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit account async func
export const editAccount = createAsyncThunk(
  "danceStudioSlice/editAccount",
  async (data) => {
    const result = await editAccountDetailsService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle send contact async func
export const sendContact = createAsyncThunk(
  "danceStudioSlice/sendContact",
  async (data) => {
    const result = await sendContactMessageService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle send email verif async func
export const sendVerification = createAsyncThunk(
  "danceStudioSlice/sendVerification",
  async () => {
    const result = await sendVerificationEmail();
    console.log(result);
    return result;
  }
);

export const danceStudioSlice = createSlice({
  name: "danceStudio",
  initialState,
  reducers: {
    //to toggle open and close left sidebar on mobile
    toggleDropboard: (state) => {
      return { ...state, openDropboard: !state.openDropboard };
    },
    //to toggle close left sidebar on mobile
    closeDropboard: (state) => {
      return { ...state, openDropboard: false };
    },
    //to toggle open and close search field
    toggleSearch: (state) => {
      return { ...state, openSearch: !state.openSearch };
    },
    //to toggle open and close notifs board on mobile and small screens
    toggleNotifsBoard: (state) => {
      return { ...state, openNotifsBoard: !state.openNotifsBoard };
    },
    closeNotifsBoard: (state) => {
      return { ...state, openNotifsBoard: false };
    },

    setEventsData: (state, action) => {
      const serializedEventsData = action.payload?.map((event) => {
        const { timestamp, ...rest } = event;
        const serializedTimestamp = {
          seconds: timestamp.seconds,
          nanoseconds: timestamp.nanoseconds,
        };
        return {
          ...rest,
          timestamp: serializedTimestamp,
        };
      });

      return { ...state, eventsData: serializedEventsData };
    },

    search: (state, action) => {
      const searchTerm = action.payload.toLowerCase();
      state.searchResults = state.eventsData?.filter((event) =>
        event.event_name.toLowerCase().includes(searchTerm)
      );
    },

    setCompetitionssData: (state, action) => {
      const serializedCompetitionsData = action.payload?.map((comp) => {
        const { timestamp, ...rest } = comp;
        const serializedTimestamp = timestamp
          ? {
              seconds: timestamp.seconds,
              nanoseconds: timestamp.nanoseconds,
            }
          : null;
        return {
          ...rest,
          timestamp: serializedTimestamp,
        };
      });

      return { ...state, competitionsData: serializedCompetitionsData };
    },

    searchCompetitions: (state, action) => {
      const searchTerm = action.payload.toLowerCase();
      // console.log("state.competitionsData", state.competitionsData);
      state.searchResults = state.competitionsData?.filter((comp) =>
        comp.competitionName.toLowerCase().includes(searchTerm)
      );
    },

    // Sort dancers by newest
    sortDancers: (state) => {
      state.dancersData.sort((a, b) => {
        const dateA = new Date(a.date_created);
        const dateB = new Date(b.date_created);
        return dateB - dateA;
      });
    },

    //to toggle open and close add event pop up
    toggleAddDancer: (state) => {
      return {
        ...state,
        addDancer: !state.addDancer,
      };
    },
    //to toggle close add event pop up
    closeAddDancer: (state) => {
      return {
        ...state,
        addDancer: false,
        addEntries: false,
        editEntries: false,
        editDancers: false,
      };
    },
    //to add new dancer
    addNewDancer: (state, action) => {
      const newArr = [action.payload, ...state.dancerData];
      state.dancerData = newArr;
    },

    setVerifyState: (state, action) => {
      state.verifyEmailSent = true;
    },

    closeVerifyState: (state, action) => {
      state.verifyEmailSent = false;
    },

    //to toggle open and close
    toggleAddEntries: (state) => {
      return {
        ...state,
        addEntries: !state.addEntries,
        addEntriesSuccess: null,
      };
    },
    //to add new entry
    addNewEntry: (state, action) => {
      const newArr = [action.payload, ...state.entriesData];
      state.entriesData = newArr;
    },
    //to toggle open and close edit entry
    toggleEditEntries: (state, action) => {
      return {
        ...state,
        editEntries: !state.editEntries,
        editID: action.payload?.editID,
        catID: action.payload?.catID,
        editEntriesSuccess: null,
      };
    },
    //to toggle open and close edit entry
    toggleEditDancers: (state, action) => {
      return {
        ...state,
        editDancers: !state.editDancers,
        editIdDancers: action.payload?.dancer_uid,
      };
    },
    // to search for dancers
    searchDancers: (state, action) => {
      const searchQuery = action.payload.toLowerCase();
      if (searchQuery.trim() === "") {
        return { ...state, searchResults: [] }; // Clear searchResults when search query is empty
      } else {
        const searchResults = state.dancersData.filter((dancer) => {
          return (
            dancer.first_name.toLowerCase().includes(searchQuery) ||
            dancer.last_name.toLowerCase().includes(searchQuery)
          );
        });
        return { ...state, searchResults };
      }
    },
    setEntriesData: (state, action) => {
      return { ...state, entriesData: action.payload };
    },
    // search for entries
    searchEntries: (state, action) => {
      const searchQuery = action.payload.toLowerCase();
      // console.log("searchQuery", searchQuery);
      if (searchQuery?.trim() === "") {
        return { ...state, searchResults: [] }; // Clear searchResults when search query is empty
      } else {
        const searchResults = state.entriesData?.filter((entry) => {
          return entry.entry_name?.toLowerCase().includes(searchQuery);
        });
        return { ...state, searchResults };
      }
    },
    updateDancersData: (state, action) => {
      state.dancersData = action.payload;
    },
    updateEntriesData: (state, action) => {
      state.entriesData = action.payload;
    },
    clearSearchResult: (state, action) => {
      state.searchResults = [];
    },

    setNotifications: (state, action) => {
      return { ...state, notifications: action.payload };
    },
    setDancers: (state, action) => {
      return { ...state, dancersData: action.payload };
    },

    // setPrintOptions: (state, action) => {
    //   return { ...state, dancersData: action.payload };
    // },

    clearSuccess: (state, action) => {
      return {
        ...state,
        addDancersSuccess: null,
        editDancersSuccess: null,
        deleteDancersSuccess: null,
        editDancers: false,
        addDancer: false,
        addEntriesSuccess: null,
        editEntriesSuccess: null,
        deleteEntriesSuccess: null,
        addEntries: false,
        editEntries: false,
        editAccountSuccess: null,
        overrideAgeSuccess: null,
        sendContactSuccess: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      //cases for getting dancers
      .addCase(getDancers.pending, (state) => {
        state.dancersData = [];
        state.getDancersPending = true;
      })
      .addCase(getDancers.fulfilled, (state, action) => {
        state.dancersData = action.payload;
        state.getDancersPending = false;
      })
      .addCase(getDancers.rejected, (state, action) => {
        state.dancersData = [];
        state.getDancersPending = false;
      })
      //cases for add dancers
      .addCase(addDancers.pending, (state) => {
        state.addDancersPending = true;
      })
      .addCase(addDancers.fulfilled, (state, action) => {
        state.addDancersSuccess = action.payload;
        state.addDancersPending = false;
      })
      .addCase(addDancers.rejected, (state, action) => {
        state.addDancersFail = action.payload;
        state.addDancersPending = false;
      })
      //cases for deleting dancers
      .addCase(deleteDancers.pending, (state) => {
        state.deleteDancersPending = true;
      })
      .addCase(deleteDancers.fulfilled, (state, action) => {
        state.deleteDancersSuccess = action.payload;
        state.deleteDancersPending = false;
      })
      .addCase(deleteDancers.rejected, (state, action) => {
        state.deleteDancersFail = action.payload;
        state.deleteDancersPending = false;
      })
      //cases for editing dancers
      .addCase(editDancers.pending, (state) => {
        state.editDancersPending = true;
      })
      .addCase(editDancers.fulfilled, (state, action) => {
        state.editDancersSuccess = action.payload;
        state.editDancersPending = false;
      })
      .addCase(editDancers.rejected, (state, action) => {
        state.editDancersFail = action.payload;
        state.editDancersPending = false;
      })

      //cases for getting entries
      .addCase(getEntries.pending, (state) => {
        state.entriesData = [];
        state.getEntriesPending = true;
      })
      .addCase(getEntries.fulfilled, (state, action) => {
        state.entriesData = action.payload;
        state.getEntriesPending = false;
      })
      .addCase(getEntries.rejected, (state, action) => {
        state.entriesData = [];
        state.getEntriesPending = false;
      })
      //cases for adding event
      .addCase(addEntries.pending, (state) => {
        state.addEntriesPending = true;
      })
      .addCase(addEntries.fulfilled, (state, action) => {
        state.addEntriesSuccess = action.payload;
        state.addEntriesPending = false;
      })
      .addCase(addEntries.rejected, (state, action) => {
        state.addEntriesFail = action.payload;
        state.addEntriesPending = false;
      })
      //cases for deleting event
      .addCase(deleteEntries.pending, (state) => {
        state.deleteEntriesPending = true;
      })
      .addCase(deleteEntries.fulfilled, (state, action) => {
        state.deleteEntriesSuccess = action.payload;
        state.deleteEntriesPending = false;
      })
      .addCase(deleteEntries.rejected, (state, action) => {
        state.deleteEntriesFail = action.payload;
        state.deleteEntriesPending = false;
      })
      //cases for editing event
      .addCase(editEntries.pending, (state) => {
        state.editEntriesPending = true;
      })
      .addCase(editEntries.fulfilled, (state, action) => {
        state.editEntriesSuccess = action.payload;
        state.editEntriesPending = false;
      })
      .addCase(editEntries.rejected, (state, action) => {
        state.editEntriesFail = action.payload;
        state.editEntriesPending = false;
      })
      .addCase(selectComp.pending, (state) => {
        state.selectCompPending = true;
      })
      .addCase(selectComp.fulfilled, (state, action) => {
        state.selectCompSuccess = action.payload;
        state.selectCompPending = false;
      })
      .addCase(removeComp.pending, (state) => {
        state.removeCompPending = true;
      })
      .addCase(removeComp.fulfilled, (state, action) => {
        state.removeCompSuccess = action.payload;
        state.removeCompPending = false;
      })
      //cases for editing account
      .addCase(editAccount.pending, (state) => {
        state.editAccountPending = true;
      })
      .addCase(editAccount.fulfilled, (state, action) => {
        state.editAccountSuccess = action.payload;
        state.editAccountPending = false;
      })
      .addCase(editAccount.rejected, (state, action) => {
        state.editAccountFail = action.payload;
        state.editAccountPending = false;
      })
      //cases for override age
      .addCase(overrideAge.pending, (state) => {
        state.overrideAgePending = true;
      })
      .addCase(overrideAge.fulfilled, (state, action) => {
        state.overrideAgeSuccess = action.payload;
        state.overrideAgePending = false;
      })
      .addCase(overrideAge.rejected, (state, action) => {
        state.overrideAgeFail = action.payload;
        state.overrideAgePending = false;
      })
      //cases for send contact
      .addCase(sendContact.pending, (state) => {
        state.sendContactPending = true;
      })
      .addCase(sendContact.fulfilled, (state, action) => {
        state.sendContactSuccess = action.payload;
        state.sendContactPending = false;
      })
      .addCase(sendContact.rejected, (state, action) => {
        state.sendContactFail = action.payload;
        state.sendContactPending = false;
      })
      //cases for send email verification
      .addCase(sendVerification.pending, (state) => {
        state.sendVerificationPending = true;
      })
      .addCase(sendVerification.fulfilled, (state, action) => {
        state.sendVerificationSuccess = action.payload;
        state.sendVerificationPending = false;
      })
      .addCase(sendVerification.rejected, (state, action) => {
        state.sendVerificationFail = action.payload;
        state.sendVerificationPending = false;
      });
  },
});

const competitionsSlice = createSlice({
  name: "competions",
  initialState: {
    value: null,
    _persist: {
      // Exclude the slice from persistence
      manualPersist: true,
    },
  },
  reducers: {
    setCompetitionsData: (state, action) => {
      state.value = action.payload;
    },
    clearCompetitionsData: (state, action) => {
      state.value = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleDropboard,
  closeDropboard,
  toggleSearch,
  toggleNotifsBoard,
  closeNotifsBoard,
  toggleAddDancer,
  toggleAddEntries,
  addNewDancer,
  addNewEntry,
  toggleEditEntries,
  setEdittedEntry,
  toggleEditDancers,
  closeAddDancer,
  searchDancers,
  searchEntries,
  search,
  setEventsData,
  setEntriesData,
  updateDancersData,
  updateEntriesData,
  searchCompetitions,
  setCompetitionssData,
  clearSuccess,
  clearSearchResult,
  setNotifications,
  setDancers,
  setVerifyState,
  closeVerifyState,
} = danceStudioSlice.actions;
export const { setCompetitionsData, clearCompetitionsData } =
  competitionsSlice.actions;
export default danceStudioSlice.reducer;
export const competitionsSliceReducer = competitionsSlice.reducer;
