/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import ScrollToTop from "./ScrollToTop";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { PulseLoader } from "react-spinners";

const DancersFeeDetails = () => {
  const { eventsData, openNotifsBoard } = useSelector(
    (state) => state.danceStudio
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { event_name, dancer_uid } = useParams();

  const currentEvent = eventsData?.filter(
    (item) => item.event_name.replace(/ /g, "-") === event_name
  )[0];

  const currentStudio = currentEvent?.access?.filter(
    (itm) => itm?.studio_id === user?.uid
  )[0];

  //==========================================================================to get all entrytypes & pricing

  const { status: compStatus, data: compData } = useFirestoreQueryDoc(
    "competitions",
    compId
  );

  const [taxRate, setTaxRate] = useState(0.0);

  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);

      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
    }
  }, [compData, compStatus]);

  const allEntryTypes = entryTypes?.concat(
    compData?.specialEntryTypes?.tags || []
  );

  //====================================================================================to get all entries
  const { status, data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  const studioEntries = allEntries?.filter(
    (each) => each?.studio_selected[0] === currentStudio?.studio_name
  );

  const studioEntriesWithDancer = studioEntries?.filter((entry) =>
    entry?.dancers_selected.some(
      (dancerSelected) => dancerSelected?.dancer_uid === dancer_uid
    )
  );

  // ====================================================================================Calculate the totalAmount for the specific dancer
  const newTotalAmountForDancer = allEntryTypes
    ?.reduce((total, row) => {
      let rowPricingOption;
      if (row?.entryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.entryTypeName)
        );
      } else if (row?.specialEntryTypeName) {
        rowPricingOption = pricing?.find((obj) =>
          obj.hasOwnProperty(row.specialEntryTypeName)
        );
      }
      // const rowEntriesForDancer = studioEntriesWithDancer?.filter(
      //   (itm) =>
      //     itm?.entryType === row?.entryTypeName ||
      //     itm?.entryType === row?.specialEntryTypeName
      // );

      // to calculate special entries and normal entries
      function rowEntries1(arr) {
        const result = arr?.filter(
          (itm) =>
            itm?.entryType === row?.entryTypeName &&
            !itm?.category_name?.includes("impulse") &&
            !itm?.category_name?.includes("remix") &&
            !itm?.category_name?.includes("unity") &&
            !itm?.category_name?.includes("overtime")
        );
        return result;
      }

      function rowEntriesSpecial(arr) {
        const result = arr?.filter((x) =>
          x?.category_name
            ?.toLowerCase()
            ?.includes(row?.specialEntryTypeName?.toLowerCase())
        );
        return result;
      }

      const rowEntriesForDancer =
        row?.specialEntryTypeName?.includes("impulse") ||
        row?.specialEntryTypeName?.includes("remix") ||
        row?.specialEntryTypeName?.includes("unity") ||
        row?.specialEntryTypeName?.includes("overtime")
          ? rowEntriesSpecial(studioEntriesWithDancer)
          : rowEntries1(studioEntriesWithDancer);

      const flatAmount =
        rowEntriesForDancer?.length > 0
          ? rowPricingOption?.[row?.entryTypeName] ||
            rowPricingOption?.[row?.specialEntryTypeName] *
              rowEntriesForDancer?.length
          : 0.0;

      const rowAmount =
        rowPricingOption?.selectedOption?.toLowerCase() === "flat"
          ? Number(flatAmount)
          : rowEntriesForDancer?.length *
            Number(
              rowPricingOption?.[row?.entryTypeName] ||
                rowPricingOption?.[row?.specialEntryTypeName]
            );

      return total + (rowAmount || 0);
    }, 0)
    .toFixed(2);

  function calcTotalAfterTax(amount, percentage) {
    const result = (amount * percentage) / 100;
    return result.toFixed(2);
  }

  const totalAfterTaxForDancer =
    Number(newTotalAmountForDancer) +
    Number(calcTotalAfterTax(newTotalAmountForDancer, taxRate));

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {status === "loading" && (
        <div className="w-full h-screen bg-[#1c1c1c] px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] pt-[80px] sm:pt-[110px]">
          <div className="w-full h-[200px] flex justify-center items-center gap-6 mb-auto text-white/80">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        </div>
      )}

      {status === "success" && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          {" "}
          <div className="mb-auto">
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {currentEvent?.event_name} {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full min-h-[400px]">
              {/* another section */}
              <section className="w-full xl:w-[80%] mt-7 pb-2">
                <div className="flex items-center gap-3">
                  <aside>
                    <div>
                      <h3 className="pl-2 text-lg text-white/80">
                        {capitalizeFirstLetter(
                          studioEntriesWithDancer?.[0]?.dancers_selected[0]
                            ?.first_name
                        )}{" "}
                        {capitalizeFirstLetter(
                          studioEntriesWithDancer?.[0]?.dancers_selected[0]
                            ?.last_name
                        )}
                      </h3>
                    </div>
                    <h3 className="pl-2 mb-6 text-lg text-white/80">
                      Total Entries: {studioEntriesWithDancer?.length}
                    </h3>
                  </aside>
                  <button
                    onClick={() => {
                      window.open(
                        `/studio/event/${event_name.replace(
                          / /g,
                          "-"
                        )}/dancers-fees/${dancer_uid}/invoice`,
                        "_blank"
                      );
                    }}
                    className="w-fit h-fit px-8 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[0.85rem] hover:opacity-75"
                  >
                    Print
                  </button>
                </div>

                <div className="w-full overflow-x-auto">
                  <table className="min-w-[600px] w-full text-[.85rem] table2 transition-all duration-300">
                    <thead className="text-white/40 text-left border-b border-white/30">
                      <tr className="whitespace-nowrap">
                        <th>Entry Type</th>
                        <th>Entries</th>
                        <th>Rate</th>
                        <th>Price</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody className="text-white/80">
                      {allEntryTypes?.map((row, idx) => {
                        const rowEntries = studioEntriesWithDancer?.filter(
                          (itm) =>
                            itm?.entryType === row?.entryTypeName ||
                            itm?.entryType === row?.specialEntryTypeName
                        );

                        let rowPricingOption;
                        if (row.entryTypeName) {
                          rowPricingOption = pricing?.find((obj) =>
                            obj.hasOwnProperty(row?.entryTypeName)
                          );
                        } else if (row?.specialEntryTypeName) {
                          rowPricingOption = pricing?.find((obj) =>
                            obj.hasOwnProperty(row?.specialEntryTypeName)
                          );
                        }

                        const flatAmount =
                          rowEntries?.length > 0
                            ? rowPricingOption?.[
                                row?.entryTypeName || row?.specialEntryTypeName
                              ] * rowEntries?.length
                            : 0.0;

                        const rowAmount =
                          rowPricingOption?.selectedOption?.toLowerCase() ===
                          "flat"
                            ? Number(flatAmount)?.toFixed(2)
                            : (
                                rowEntries?.length *
                                Number(
                                  rowPricingOption?.[
                                    row?.entryTypeName ||
                                      row?.specialEntryTypeName
                                  ]
                                )
                              ).toFixed(2);

                        return (
                          <tr
                            key={idx}
                            className="border-b border-white/10 whitespace-nowrap"
                          >
                            <td className="flex items-center">
                              {capitalizeFirstLetter(
                                row?.entryTypeName || row?.specialEntryTypeName
                              )}
                            </td>
                            <td>{rowEntries?.length}</td>
                            <td>
                              {rowPricingOption?.selectedOption === "perDancer"
                                ? "Per Dancer"
                                : "Flat"}
                            </td>
                            <td>
                              $
                              {
                                rowPricingOption?.[
                                  row.entryTypeName || row.specialEntryTypeName
                                ]
                              }
                            </td>
                            <td>${rowAmount}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tr>
                      <td colSpan="4"></td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        Sub Total
                      </td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        ${newTotalAmountForDancer}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="4"></td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        Tax Rate
                      </td>
                      <td className="relative">
                        <div className="text-white font-bold w-[60px] h-[30px] bg-black/50 border border-white/10 rounded-lg p-4">
                          {taxRate}%
                        </div>
                      </td>
                    </tr>
                    <tr className="border-b border-white/10 whitespace-nowrap cursor-pointer">
                      <td colSpan="4"></td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        Total
                      </td>
                      <td className="text-white text-md font-bold whitespace-nowrap">
                        ${totalAfterTaxForDancer?.toFixed(2)}
                      </td>
                    </tr>
                  </table>
                </div>
              </section>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}
    </>
  );
};

export default DancersFeeDetails;
