import { Chart as ChartJS, Legend, ArcElement, Tooltip } from "chart.js";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { Doughnut } from "react-chartjs-2";
import { useSelector } from "react-redux";

ChartJS.register(ArcElement, Legend, Tooltip);

const ChartCard = ({ userDetails, currentEvent }) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const myEntries = allEntries?.filter(
    (itm) => itm?.event_uid === currentEvent?.event_uid
  );

  const totalStudioEstEntries = currentEvent?.access?.reduce(
    (accumulator, currentValue) => {
      if (currentValue.hasOwnProperty("estimated_entries")) {
        return accumulator + Number(currentValue.estimated_entries);
      }
      return accumulator;
    },
    0
  );

  const submitted = myEntries?.length;
  const holds = totalStudioEstEntries - submitted;

  const available =
    Number(currentEvent?.estimated_entries) - (submitted + holds);

  // Function to calculate the percentage
  const calculatePercentage = (value) => {
    return (
      ((value / Number(currentEvent?.estimated_entries)) * 100).toFixed(2) + "%"
    );
  };

  const chartData = {
    labels: ["Submitted", "Holds", "Available"],
    datasets: [
      {
        data: [myEntries?.length, holds, available],
        backgroundColor: ["#95A4FC", "#B1E3FF", "#c3ecc5"],
        hoverOffset: 4,
        borderColor: ["#95A4FC", "#B1E3FF", "#c3ecc5"],
      },
    ],
  };

  const chartOptions = {
    // Add your chart options here
    plugins: { legend: { display: false } },
    maintainAspectRatio: false,
    responsive: true,
    cutout: "70%",
    layout: { padding: 0 },
    elements: { arc: { borderWidth: 0 } },
    tooltips: {
      enabled: false,
    },
  };

  const isDataEmpty = chartData?.datasets[0]?.data?.every((data) => data === 0);

  return (
    <div className="w-full min-w-[130px] md:w-1/4 min-h-[150px] md:min-h-[300px] md:h-fit bg-[#282929] rounded-xl flex flex-col gap-5 p-5 md:p-[2%]">
      <h2 className="font-bold">Total Entries</h2>
      {isDataEmpty ? (
        <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
          <h4 className="text-[.8rem] md:text-[.9rem] text-center font-light">
            No data yet... <br />{" "}
            {userDetails?.role === "organizer"
              ? "There are no entries added for this event"
              : "Subscribe to a studio to see the statistics here"}
          </h4>
        </div>
      ) : (
        <div className="w-full flex flex-wrap gap-3 md:block">
          <div className="w-1/2 md:w-full">
            <Doughnut
              data={chartData}
              options={chartOptions}
              width={150}
              height={150}
            />
          </div>
          <div className="text-center text-white mt-4">
            {chartData?.labels.map((label, index) => (
              <div
                key={index}
                className="text-[.9rem] md:text-[.75em] font-light flex gap-3 items-center mb-3"
              >
                <div className="flex gap-1 items-center">
                  <div
                    className={`w-[6px] h-[6px] rounded-full`}
                    style={{
                      backgroundColor:
                        chartData?.datasets[0]?.backgroundColor[index],
                    }}
                  ></div>
                  <p>{label}</p>
                </div>
                <p className="text-[.8em] lg:text-[.9em] font-medium whitespace-nowrap">
                  {index === 0
                    ? myEntries?.length
                    : index === 1
                    ? holds
                    : available}{" "}
                  ({calculatePercentage(chartData.datasets[0].data[index])})
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChartCard;
