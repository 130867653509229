/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import AddEventForm from "./components/AddEventForm";
import ScrollToTop from "./ScrollToTop";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { useDispatch } from "react-redux";
import {
  clearSuccess,
  deleteCustom,
  submitCustom,
} from "redux/eventManagement/eventManagement";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import Spinner from "./components/Spinner";

const CustomReports = () => {
  const {
    addEvent,
    openNotifsBoard,
    currentEvent,
    submitCustomPending,
    submitCustomSuccess,
    deleteCustomPending,
    deleteCustomSuccess,
  } = useSelector((state) => state.eventManagement);
  const navigate = useNavigate();
  const { event_name } = useParams();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  const timestamp = new Date().getTime();

  function formatTimeStamp(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedTime = `${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }, ${month} ${day}, ${year}`;
    return formattedTime;
  }

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    compId: user?.uid,
    request_uid: generateUID(),
    timestamp: formatTimeStamp(timestamp),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (formData?.title && formData?.description) {
        dispatch(submitCustom(formData));
      }
    } catch (error) {
      console.log("error submit grand award", error);
    }
  }

  useEffect(() => {
    if (submitCustomSuccess?.success) {
      setFormData((prev) => {
        return {
          ...prev,
          title: "",
          description: "",
          request_uid: generateUID(),
        };
      });
      setTimeout(() => {
        dispatch(clearSuccess());
      }, 2000);
    }

    if (deleteCustomSuccess?.success) {
      dispatch(clearSuccess());
      closePendingMod();
    }
  }, [submitCustomSuccess, deleteCustomSuccess]);

  const { status, data } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "reports"
  );

  const pendingRequests = data?.filter((item) => item?.id === "custom_request");

  const [openPending, setOpenPending] = useState(null);
  function openPendingMod(request) {
    setOpenPending(request);
  }
  function closePendingMod() {
    setOpenPending(null);
  }

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full md:max-w-[85%] lg:max-w-[60%] md:min-w-[450px] mb-auto text-white">
              {status === "loading" ? (
                <div className="w-full h-[100px] flex justify-center items-center">
                  <Spinner />
                </div>
              ) : (
                pendingRequests[0]?.requests?.length > 0 &&
                !deleteCustomPending && (
                  <div className="w-full bg-[#282929] p-6 rounded-lg flex flex-col gap-4 mb-7">
                    <h3 className="text-[1.25rem] font-bold">
                      Pending Requests
                    </h3>
                    <div className="w-full mt-3 flex gap-3">
                      {pendingRequests[0]?.requests?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            onClick={() => openPendingMod(item)}
                            className="w-[200px] border border-white/60 rounded-md p-3 text-white/80 bg-[#323232] hover:scale-[1.1] transition-all duration-300 cursor-pointer"
                          >
                            <p className="font-bold">
                              {capitalizeFirstLetter(item?.title)}
                            </p>
                            <p className="text-white/70 whitespace-nowrap truncate">
                              {item?.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )
              )}

              <div className="w-full bg-[#282929] p-6 rounded-lg flex flex-col gap-4">
                <h3 className="text-[1.25rem] font-bold">
                  Custom Reports Request Form
                </h3>
                <p>
                  Custom Reports that cannot be created using our “Add Award”
                  screen are created by our team upon request. Please what kind
                  of report is required.
                  <br />
                  <br /> E.g. I require a custom ”Top Studio Award”. Each studio
                  will calculate a score determined by the average of their top
                  three soloist scores, top three duo/trio scores and top four
                  group scores. Each studios’ average total be compared to
                  determine the winner.
                </p>

                <form className="w-full" onSubmit={(e) => handleSubmit(e)}>
                  <div className="w-full bg-black rounded-lg p-4">
                    <input
                      type="text"
                      className="w-full placeholder:text-white/50 mb-3 bg-inherit outline-none"
                      placeholder="Title"
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      required
                    />
                    <textarea
                      className="w-full h-[100px] placeholder:text-white/40 bg-inherit outline-none"
                      placeholder="Description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className="w-full mt-7 flex gap-4 justify-end">
                    <button
                      // disabled={submitGrandAwardPending}
                      onClick={() => {
                        navigate(
                          `/organizer/event/${event_name.replace(
                            / /g,
                            "-"
                          )}/report-session-awards`
                        );
                      }}
                      className="text-[.85rem] px-6 py-1 rounded-full bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
                    >
                      Discard
                    </button>
                    <button
                      type="submit"
                      disabled={submitCustomPending}
                      className="text-[.9rem] px-6 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] flex justify-between gap-2 items-center whitespace-nowrap"
                    >
                      {submitCustomPending ? "Requesting..." : "Request"}
                      {submitCustomPending && <ButtonLoadingSpinner />}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>Add Event</h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddEventForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {submitCustomSuccess?.success && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white text-center">
              Requested Successfully!{" "}
            </p>
          </div>
        </div>
      )}

      {openPending && (
        <div
          // onClick={(e) => {
          //   closePendingMod(e);
          // }}
          className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start"
        >
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <div className="w-full text-[1rem] text-white text-center pb-3 border-b border-[#c5c4c4]/50 relative">
              <p>{capitalizeFirstLetter(openPending?.title)}</p>
              <div
                onClick={(e) => {
                  closePendingMod(e);
                }}
                className="p-1 rounded-full bg-white/70 absolute right-2 top-0 cursor-pointer"
              >
                <img
                  alt=""
                  src="/images/icons8-close-50.png"
                  className="w-4 h-4"
                />
              </div>
            </div>
            <p className="w-full mt-0 text-white pb-5  border-b border-[#c5c4c4]/50">
              {openPending?.description}
            </p>
            <button
              disabled={deleteCustomPending}
              onClick={() => {
                dispatch(
                  deleteCustom({
                    compId: user?.uid,
                    doc: "custom_request",
                    request_uid: openPending?.request_uid,
                  })
                );
              }}
              className="text-[.85rem] px-6 py-1 rounded-full bg-red-500 hover:bg-red-400 text-white transition-all duration-300 flex gap-2 items-center"
            >
              {deleteCustomPending ? "Deleting..." : " Delete Request"}
              {deleteCustomPending && <ButtonLoadingSpinner />}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomReports;
