import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";

const assignSortId = (array) => {
  if (array && Array.isArray(array) && array.length > 0) {
    const maxSortId = Math.max(...array.map((obj) => obj?.sort_id || 0));
    const newSortId = maxSortId + 1;

    return newSortId;
  } else {
    // If the allEntries array is empty or not valid, start with sort_id 1
    return 1;
  }
};

function generateUniqueId() {
  const timestamp = new Date().getTime().toString();
  const randomNum = Math.floor(Math.random() * 900000000000) + 100000000000;
  const uniqueId = `${timestamp}${randomNum}`;
  return uniqueId.substring(0, 15);
}

function generateno_id() {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uid = "";
  for (let i = 0; i < 32; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    uid += chars.charAt(randomIndex);
  }
  return uid;
}

//========================================================to add entries on organizer
export const addEntryService = async (data) => {
  try {
    const entryCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const categoryCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );

    // Create the entry document and get the generated document ID
    const newEntryDocRef = await addDoc(entryCollectionRef, {
      ...data?.formData,
    });

    // Get the document ID generated by Firebase and update the doc_id field
    const docId = newEntryDocRef.id;
    await updateDoc(newEntryDocRef, { doc_id: docId, id: docId });

    // Create the category document only if it doesn't already exist
    const categoryQuerySnapshot = await getDocs(categoryCollectionRef);
    let categoryExists = false;
    let allCats = [];
    categoryQuerySnapshot.forEach((doc) => {
      const categoryData = doc.data();
      allCats?.push(doc?.data());
      if (
        categoryData?.category_name?.replace(/[/\s]/g, "_") ===
          data?.formData?.category_name?.replace(/[/\s]/g, "_") &&
        categoryData?.event_uid === data?.formData?.event_uid
      ) {
        categoryExists = true;
      }
    });

    if (!categoryExists) {
      // Create the category document
      const newId = generateno_id();
      await setDoc(doc(categoryCollectionRef, newId), {
        category_name: data?.formData?.category_name,
        sort_id: assignSortId(allCats),
        id: generateUniqueId(),
        is_scratched: false,
        event_uid: data?.formData?.event_uid,
        type: "category",
        no_id: newId,
      });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=======================================================to scratch category
export const scratchCategoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );

    const querySnapshotCategories = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotCategories.docs.map(async (doc) => {
        if (doc.id === data?.no_id) {
          await updateDoc(doc.ref, { is_scratched: true });
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=======================================================to scratch category
export const unScratchCategoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );

    const querySnapshotCategories = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotCategories.docs.map(async (doc) => {
        if (doc.id === data?.no_id) {
          await updateDoc(doc.ref, { is_scratched: false });
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//============================================================================================to delete category
export const deleteCategoryService = async (data) => {
  try {
    // Delete matching documents in the "categories" collection
    const categoriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );
    const querySnapshotCategories = await getDocs(categoriesCollectionRef);
    const deletedCategoriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "deleted_categories"
    );

    const docIdToCheck = data?.no_id;

    await Promise.all(
      querySnapshotCategories.docs.map(async (docSnapshot) => {
        if (docSnapshot.id === docIdToCheck) {
          // Delete the document from "categories" collection
          await deleteDoc(docSnapshot.ref);

          // Check if the document with the given ID already exists in "deleted_categories" subcollection
          const existingDeletedDocSnapshot = await getDoc(
            doc(deletedCategoriesCollectionRef, docIdToCheck)
          );

          if (!existingDeletedDocSnapshot.exists()) {
            // Add the deleted document to "deleted_categories" subcollection
            await addDoc(deletedCategoriesCollectionRef, docSnapshot.data());
          } else {
            console.log(
              `Document with ID ${docIdToCheck} already exists in "deleted_categories".`
            );
          }
        }
      })
    );

    // Delete matching documents in the "entries" collection
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const querySnapshotEntries = await getDocs(entriesCollectionRef);
    const deletedEntriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "deleted_entries"
    );

    const docIdToCheckEntry = data?.doc_id.replace(/[/\s]/g, "_");

    await Promise.all(
      querySnapshotEntries.docs.map(async (docSnapshot) => {
        const entryData = docSnapshot.data();
        if (
          entryData?.category_name?.replace(/[/\s]/g, "_") ===
            docIdToCheckEntry &&
          entryData?.event_uid === data?.event_uid
        ) {
          // Delete the document from "entries" collection
          await deleteDoc(docSnapshot.ref);

          // Check if the document with the given ID already exists in "deleted_entries" subcollection
          const existingDeletedDocSnapshotEntry = await getDoc(
            doc(deletedEntriesCollectionRef, docIdToCheckEntry)
          );

          if (!existingDeletedDocSnapshotEntry.exists()) {
            // Add the deleted document to "deleted_entries" subcollection
            await addDoc(deletedEntriesCollectionRef, entryData);
          } else {
            console.log(
              `Document with ID ${docIdToCheckEntry} already exists in "deleted_entries".`
            );
          }
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//============================================================to delete entry
export const deleteEntryService = async (data) => {
  try {
    if (!data || !data.compId || !data.doc_id || !data.category_name) {
      throw new Error("Invalid input data");
    }

    const deletedEntriesCollectionRef = collection(
      db,
      "competitions",
      data.compId,
      "deleted_entries"
    );
    const collectionRef = collection(
      db,
      "competitions",
      data.compId,
      "entries"
    );
    const querySnapshotEntries = await getDocs(collectionRef);
    const addDeletedDocsPromises = [];

    const allDeletedCategoryDocRef = collection(
      db,
      "competitions",
      data.compId,
      "deleted_categories"
    );

    const categoryQuerySnapshot = await getDocs(allDeletedCategoryDocRef);
    let allCats = [];
    let myCats = [];
    categoryQuerySnapshot.forEach((doc) => {
      allCats?.push(doc?.data());
      if (doc?.data()?.event_uid === data?.event_uid) {
        myCats?.push(doc?.data()?.category_name);
      }
    });

    // const categorySnapshot = await getDoc(deletedCategoryDocRef);
    if (myCats?.includes(data?.category_name)) {
      // The document already exists, no need to create one.
    } else {
      const newId = generateno_id();
      const deletedCategoryData = {
        category_name: data?.category_name,
        sort_id: assignSortId(allCats),
        id: generateUniqueId(),
        is_scratched: false,
        event_uid: data?.event_uid,
        type: "category",
        split: false,
        no_id: newId,
      };
      await setDoc(doc(allDeletedCategoryDocRef, newId), deletedCategoryData);
    }

    querySnapshotEntries.docs.forEach((doc) => {
      if (doc.id === data.doc_id) {
        const deletedDocData = doc.data();
        const addDeletedDocPromise = addDoc(
          deletedEntriesCollectionRef,
          deletedDocData
        );
        addDeletedDocsPromises.push(addDeletedDocPromise);
        const deletePromise = deleteDoc(doc.ref);
        addDeletedDocsPromises.push(deletePromise);
      }
    });

    await Promise.all(addDeletedDocsPromises);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=======================================================to edit entry
export const editEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const categoryCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );

    const docRef = doc(collectionRef, data?.formData?.doc_id);
    await updateDoc(docRef, data?.formData);

    // Create the category document only if it doesn't already exist
    const categoryQuerySnapshot = await getDocs(categoryCollectionRef);
    let categoryExists = false;
    let allCats = [];
    categoryQuerySnapshot.forEach((doc) => {
      const categoryData = doc.data();
      allCats?.push(doc?.data());
      if (
        categoryData?.category_name ===
          data?.formData?.category_name?.replace(/[/\s]/g, "_") &&
        categoryData?.event_uid === data?.formData?.event_uid
      ) {
        categoryExists = true;
      }
    });

    if (!categoryExists) {
      // Create the category document
      const newId = generateno_id();

      await setDoc(doc(categoryCollectionRef, newId), {
        category_name: data?.formData?.category_name,
        sort_id: assignSortId(allCats),
        id: generateUniqueId(),
        is_scratched: false,
        event_uid: data?.formData?.event_uid,
        type: "category",
        no_id: newId,
      });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=======================================================to scratch entry
export const scratchEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    const querySnapshotEntries = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotEntries.docs.map(async (doc) => {
        if (doc.id === data?.doc_id) {
          await updateDoc(doc.ref, { is_scratched: true });
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=======================================================to unscratch entry
export const unScratchEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    const querySnapshotEntries = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotEntries.docs.map(async (doc) => {
        if (doc.id === data?.doc_id) {
          await updateDoc(doc.ref, { is_scratched: false });
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=====================================================================to add break
export const addBreakService = async (data) => {
  try {
    const categoryCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );

    const categoryQuerySnapshot = await getDocs(categoryCollectionRef);
    let allCats = [];
    categoryQuerySnapshot.forEach((doc) => {
      allCats.push(doc.data());
    });

    // Create the break document
    const breakData = {
      category_name: data?.break,
      sort_id: assignSortId(allCats),
      id: generateUniqueId(),
      event_uid: data?.event_uid,
      type: "break",
      break_type: data?.break_type,
      no_id: generateno_id(),
    };

    // Add the document and get the generated document ID
    const newDocRef = await addDoc(categoryCollectionRef, breakData);
    const newDocId = newDocRef.id;

    // Update the "doc_id" field with the newly generated document ID
    await setDoc(
      doc(categoryCollectionRef, newDocId),
      { doc_id: newDocId },
      { merge: true }
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================================================================to delete break
export const deleteBreakService = async (data) => {
  try {
    const categoryCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );

    // Delete the break document with the specified doc_id
    const docRef = doc(categoryCollectionRef, data?.doc_id);
    await deleteDoc(docRef);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=====================================================================to add break
export const addBufferService = async (data) => {
  try {
    const categoryCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );

    const categoryQuerySnapshot = await getDocs(categoryCollectionRef);
    let allCats = [];
    categoryQuerySnapshot.forEach((doc) => {
      allCats.push(doc.data());
    });

    // Create the break document
    const bufferData = {
      category_name: `time buffer (${data?.buffer} minutes)`,
      buffer: data?.buffer,
      sort_id: assignSortId(allCats),
      id: generateUniqueId(),
      event_uid: data?.event_uid,
      type: "buffer",
      no_id: generateno_id(),
    };

    // Add the document and get the generated document ID
    const newDocRef = await addDoc(categoryCollectionRef, bufferData);
    const newDocId = newDocRef.id;

    // Update the "doc_id" field with the newly generated document ID
    await setDoc(
      doc(categoryCollectionRef, newDocId),
      { doc_id: newDocId },
      { merge: true }
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//============================================================to handle delete all routines in view deleted
export const deleteAllDeletedDocsService = async (data) => {
  try {
    const deletedEntriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "deleted_entries"
    );
    const deletedCategoriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "deleted_categories"
    );

    // Query for documents with matching "event_uid" in "deleted_entries" collection
    const deletedEntriesQuery = query(
      deletedEntriesCollectionRef,
      where("event_uid", "==", data?.event_uid)
    );
    const deletedEntriesQuerySnapshot = await getDocs(deletedEntriesQuery);
    const deleteEntriesPromises = deletedEntriesQuerySnapshot.docs.map((doc) =>
      deleteDoc(doc.ref)
    );

    // Query for documents with matching "event_uid" in "deleted_categories" collection
    const deletedCategoriesQuery = query(
      deletedCategoriesCollectionRef,
      where("event_uid", "==", data?.event_uid)
    );
    const deletedCategoriesQuerySnapshot = await getDocs(
      deletedCategoriesQuery
    );
    const deleteCategoriesPromises = deletedCategoriesQuerySnapshot.docs.map(
      (doc) => deleteDoc(doc.ref)
    );

    // Execute all delete promises in parallel
    await Promise.all([...deleteEntriesPromises, ...deleteCategoriesPromises]);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============== add payment history
export const addPaymentHistoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "payment_history"
    );

    // Create the payment history document and get the generated document ID
    const newPaymentDocRef = await addDoc(collectionRef, {
      ...data?.formData,
    });
    // Get the document ID generated by Firebase and update the doc_id field
    const docId = newPaymentDocRef.id;
    await updateDoc(newPaymentDocRef, { doc_id: docId, id: docId });

    return { success: true };
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while adding the payment.",
    };
  }
};

//================ delete payment history
export const deletePaymentHistoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "payment_history"
    );

    // Delete the payment history document with the specified doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    await deleteDoc(docRef);

    return { success: true };
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while deleting the payment.",
    };
  }
};

//================ edit payment history
export const editPaymentHistoryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "payment_history"
    );

    const docRef = doc(collectionRef, data?.formData?.doc_id);
    await updateDoc(docRef, data?.formData);

    return { success: true };
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while editing the payment.",
    };
  }
};

//================ add payment Invoice and Receipt Details
export const addPaymentInvoiceReceiptService = async (data) => {
  try {
    const docRef = doc(db, "competitions", data?.compId);

    const formData = {
      ...data?.formData,
    };

    await updateDoc(docRef, {
      billing_details: formData,
    });

    return {
      success: true,
      message: "Billing receipt added successfully.",
    };
  } catch (error) {
    return {
      success: false,
      message: "An error occurred while adding the billing receipt.",
      error: error.message,
    };
  }
};

//==============================================to spit category
export const splitCategoryService = async (data) => {
  try {
    const categoryCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );

    console.log("data", data);

    const categoryQuerySnapshot = await getDocs(categoryCollectionRef);
    let allCats = [];
    categoryQuerySnapshot.forEach((doc) => {
      allCats?.push(doc?.data());
    });

    // Create split category
    const newId = generateno_id();

    await setDoc(doc(categoryCollectionRef, newId), {
      category_name: data?.category_name,
      // sort_id: assignSortId(allCats),
      sort_id: Number(data?.main_sort_id) + 0.5,
      id: generateUniqueId(),
      is_scratched: false,
      event_uid: data?.event_uid,
      type: "category",
      split: true,
      no_id: newId,
    });

    // Update the main category
    const querySnapshot = query(
      categoryCollectionRef,
      where("no_id", "==", data?.no_id)
    );
    const categoriesSnapshot = await getDocs(querySnapshot);
    const docRef = categoriesSnapshot.docs[0].ref;

    await updateDoc(docRef, {
      split: true,
    });

    // Get entries with matching main_category value
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const entriesQuerySnapshot = await getDocs(entriesCollectionRef);

    const matchingEntries = [];
    entriesQuerySnapshot.forEach((doc) => {
      const entry = doc?.data();
      if (
        entry?.category_name === data?.main_category &&
        entry?.event_uid === data?.event_uid
      ) {
        matchingEntries.push({ id: doc.id, ...entry });
      }
    });

    // Update the category_name value for the last half of matching entries
    const sortedMatchingEntries = matchingEntries?.sort(
      (a, b) => a?.sort_id - b?.sort_id
    );
    const halfIndex = Math.ceil(sortedMatchingEntries?.length / 2);
    const entriesToUpdate = sortedMatchingEntries?.slice(halfIndex);

    await Promise.all(
      entriesToUpdate?.map(async (entry) => {
        const entryDocRef = doc(entriesCollectionRef, entry.id);
        await updateDoc(entryDocRef, { category_name: data?.category_name });
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const combineCategoryService = async (data) => {
  try {
    const categoryCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );

    // Delete the split category document
    console.log(data?.splitCategoryName?.replace(/[/\s]/g, "_"));
    const categoryDocRef = doc(
      categoryCollectionRef,
      data?.splitCategoryName?.replace(/[/\s]/g, "_")
    );
    await deleteDoc(categoryDocRef);

    // Update the main category
    const querySnapshot = query(
      categoryCollectionRef,
      where("no_id", "==", data?.main_no_id)
    );
    const categoriesSnapshot = await getDocs(querySnapshot);
    const docRef = categoriesSnapshot.docs[0].ref;

    await updateDoc(docRef, {
      split: false,
    });

    // Get entries with matching category_name value
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const entriesQuerySnapshot = await getDocs(entriesCollectionRef);

    const matchingEntries = [];
    entriesQuerySnapshot.forEach((doc) => {
      const entry = doc?.data();
      if (
        entry?.category_name === data?.splitCategoryName &&
        entry?.event_uid === data?.event_uid
      ) {
        matchingEntries.push({ id: doc.id, ...entry });
      }
    });

    // Update the category_name value for matching entries
    await Promise.all(
      matchingEntries.map(async (entry) => {
        const entryDocRef = doc(entriesCollectionRef, entry.id);
        await updateDoc(entryDocRef, { category_name: data?.newCategoryName });
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const restoreDeleteEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "deleted_entries"
    );

    const querySnapshotEntries = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotEntries.docs.map(async (doc) => {
        if (doc?.data().doc_id === data?.doc_id) {
          await deleteDoc(doc.ref);
        }
      })
    );

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
