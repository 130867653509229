import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PulseLoader } from 'react-spinners';
import {
  clearSuccess,
  updatePriceData,
} from '../../../redux/eventManagement/eventManagement';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const UpdatePrice = ({ status, user, organizerData }) => {
  const { updatePriceDataSuccess, updatePriceDataPending } = useSelector(
    (state) => state.eventManagement
  );
  const dispatch = useDispatch();
  const pricingData = organizerData?.pricing?.pricingCategories;

  // Function to transform pricing data into an array of objects
  const transformPricingData = (data) => {
    const transformedData = [];

    if (data) {
      for (const item of data) {
        for (const key in item) {
          if (key !== 'selectedOption') {
            transformedData.push({
              categoryName: key,
              categoryPrice: item[key],
            });
          }
        }
      }
    }

    return transformedData;
  };

  const [updatedPricing, setUpdatedPricing] = useState([]); // Store updated data
  // console.log("updatedPricing", updatedPricing);
  const [edit, setEdit] = useState(false);
  function toggleEdit() {
    setEdit((prev) => !prev);
    if (!edit) {
      setUpdatedPricing([...pricingData]);
    }
  }

  const handlePriceChange = (index, e) => {
    const { id, value } = e.target;
    const updatedPricingCopy = [...updatedPricing];
    updatedPricingCopy[index] = {
      ...updatedPricingCopy[index],
      [id]: value,
    };
    setUpdatedPricing(updatedPricingCopy);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatePrice = updatedPricing?.map((price) => {
      const obj = { ...price };
      delete obj?.selectedOption;
      const cateName = Object.keys(obj)[0];
      const valueName = Object.values(obj)[0];
      return {
        [cateName]: valueName,
        selectedOption: price?.selectedOption,
      };
    });
    // console.log("updateprice", updatePrice);
    dispatch(updatePriceData({ compId: user?.uid, updatePrice }));
  };

  useEffect(() => {
    if (updatePriceDataSuccess?.success) {
      setEdit(false);
      setTimeout(() => {
        dispatch(clearSuccess());
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 2000);
    }
  }, [updatePriceDataSuccess, dispatch]);

  return (
    <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
      <h1 className="w-full border-b pb-2 font-bold border-white/30">
        All Pricing Values
      </h1>
      {status === 'loading' && (
        <div className="w-full h-[150px] flex justify-center items-center">
          <PulseLoader color="#94a4fd" size={10} />
        </div>
      )}
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">
        {Array.isArray(pricingData) ? (
          transformPricingData(pricingData).map((price, index) => (
            <div
              key={index}
              className="bg-[#1c1c1c] rounded-lg py-1 px-4 text-sm text-white flex items-center flex-wrap"
            >
              <div className="flex items-center">
                {/* <div className="w-[20px] h-[20px] rounded-full bg-[#94a4fd] mr-3"></div> */}
                <p className="lg:text-[.8rem]">
                  {capitalizeFirstLetter(price?.categoryName)}
                </p>
              </div>
              <div style={{ margin: 'auto', marginRight: 'inherit' }}>
                {edit ? (
                  <input
                    type="text"
                    // value={updatedPricing?.categoryPrice || ''}
                    id={price?.categoryName}
                    defaultValue={price?.categoryPrice}
                    onChange={(e) => handlePriceChange(index, e)}
                    className="bg-[#1c1c1c] rounded-lg p-2 text-sm text-white flex items-center m-auto w-[80px]"
                  />
                ) : (
                  price?.categoryPrice
                )}
              </div>
            </div>
          ))
        ) : (
          <p>No pricing data available.</p>
        )}
      </div>
      {updatePriceDataSuccess?.success && (
        <p className="text-[#94a4fd] ">Price Updated Successfully!!</p>
      )}
      <div className="flex gap-2 justify-end">
        <button
          onClick={toggleEdit}
          disabled={updatePriceDataPending}
          className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
        >
          {edit ? 'Cancel Edit' : 'Edit Values'}
        </button>

        {edit && (
          <button
            onClick={handleSubmit}
            disabled={updatePriceDataPending}
            className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
          >
            {updatePriceDataPending ? (
              <p className="flex gap-2 items-center">
                Submitting.. <ButtonLoadingSpinner />
              </p>
            ) : (
              'Submit'
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default UpdatePrice;
