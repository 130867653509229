import {
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { functions, db } from "./firebase";
import moment from "moment";
import { updateAllDocs } from "utils/updateAllDocs";
import { httpsCallable } from "firebase/functions";
import { calculateAge } from "utils/CalculateAge";

function filteredEntryTypes(array, number) {
  return array.filter(
    (obj) => obj.minDancers <= number && obj.maxDancers >= number
  );
}
// to filter Specialentrytypes
function filteredSpecialEntryTypes(array, number) {
  return array?.filter(
    (obj) => obj.specialMinDancers <= number && obj.specialMaxDancers >= number
  );
}

function generateno_id() {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uid = "";
  for (let i = 0; i < 32; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    uid += chars.charAt(randomIndex);
  }
  return uid;
}

function calculateAverage(numbers) {
  if (numbers.length === 0) {
    return 0;
  }

  const sum = numbers.reduce((total, num) => total + num, 0);
  const average = sum / numbers.length;

  return Math.floor(average);
}

function filteredAgeDivision(array, number) {
  return array?.filter((obj) => obj.minAge <= number && obj.maxAge >= number);
}

const sendContactMessage = httpsCallable(functions, "sendContactMessage");

//========================================================to send event access granted email
export const sendContactMessageService = async (data) => {
  const { senderEmail, studioName, title, description } = data;

  try {
    const response = await sendContactMessage({
      senderEmail,
      studioName,
      title,
      description,
    });

    return { success: true, response };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

//=========================COMPETITION SELECT=============================
//=========================COMPETITION SELECT=============================
export const updateSelectedCompetition = async (data) => {
  try {
    //to add to selected competitions array in studio owner collection
    const studioOwnerDocRef = doc(db, "studio_owners", data.uid);
    const snapshot = await getDoc(studioOwnerDocRef);

    const selectedCompetitions = snapshot.data().selected_competitions;
    await updateDoc(studioOwnerDocRef, {
      selected_competitions: selectedCompetitions
        ? arrayUnion(data.competitionName)
        : [data.competitionName],
    });

    // Update studios_registered field in competitions collection
    const compDocRef = doc(db, "competitions", data?.compId.replace(/ /g, ""));
    const compSnapshot = await getDoc(compDocRef);
    const events = compSnapshot.data().events;
    const updatedEvents = events?.map((event) => ({
      ...event,
      studios_registered: (event.studios_registered || 0) + 1,
    }));

    await updateDoc(compDocRef, {
      events: updatedEvents,
    });

    //to add to studios_registration array in organizers collection
    const organizerDocRef = doc(
      db,
      "organizers",
      data?.compId.replace(/ /g, "")
    );
    const snapshotOrg = await getDoc(organizerDocRef);

    const studioRegistrations = snapshotOrg.data().studio_registrations;
    await updateDoc(organizerDocRef, {
      studio_registrations: studioRegistrations
        ? arrayUnion(data.studioRegDetails)
        : [data.studioRegDetails],
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to remove selected comps

export const removeSelectedCompetition = async (data) => {
  try {
    //to remove all entries added by the studion in this competition
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    const entryDocs = await getDocs(entriesCollectionRef);

    const deletePromises = entryDocs.docs.map((entryDoc) => {
      const entryData = entryDoc.data(); // Retrieve the data for the current entry

      if (entryData?.studio_selected[0] === data?.studioName) {
        return deleteDoc(entryDoc.ref);
      }
    });

    // Wait for all delete operations to complete
    await Promise.all(deletePromises);

    //to remove from selected competitions array in studio owner collection
    const studioOwnerDocRef = doc(db, "studio_owners", data.uid);
    await getDoc(studioOwnerDocRef);

    await updateDoc(studioOwnerDocRef, {
      selected_competitions: arrayRemove(data.competitionName),
    });

    // Update studios_registered field in competitions collection
    const compDocRef = doc(db, "competitions", data?.compId.replace(/ /g, ""));
    const compSnapshot = await getDoc(compDocRef);
    const events = compSnapshot.data().events;
    const updatedEvents = events?.map((event) => ({
      ...event,
      studios_registered:
        event.studios_registered === 0 ? 0 : event.studios_registered - 1,
    }));

    await updateDoc(compDocRef, {
      events: updatedEvents,
    });

    //to remove from studio_registrations array in organizer collection
    const orgDocRef = doc(db, "organizers", data?.compId.replace(/ /g, ""));
    const snapshot = await getDoc(orgDocRef);
    const studioRegistrations = snapshot?.data().studio_registrations;
    const updatedStudioRegistrations = studioRegistrations?.filter(
      (item) => item.studio_id !== data?.uid
    );

    await updateDoc(orgDocRef, {
      studio_registrations: updatedStudioRegistrations,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=========================DANCERS=============================
//=========================DANCERS=============================

//=============================== to get all existing dancers
export const getDancersService = async (data) => {
  try {
    const dancersCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "dancers"
    );
    const entriesQuerySnapshot = await getDocs(
      query(dancersCollectionRef, where("studioId", "==", data?.uid))
    );
    return entriesQuerySnapshot.docs.map((doc) => doc.data());
  } catch (error) {
    throw error;
  }
};

//=========================================to generate uid
function generateUID() {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uid = "";
  for (let i = 0; i < 8; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    uid += chars.charAt(randomIndex);
  }
  return uid;
}
function generateTimestamp() {
  const now = new Date();
  const isoString = now.toISOString();
  return isoString;
}

//=============================== to add dancers
export const addDancersService = async (data) => {
  try {
    const dancersCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "dancers"
    );

    await setDoc(doc(dancersCollectionRef), data?.formData);

    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `New dancer added. Dancer ${data?.formData?.first_name} ${data?.formData?.last_name} has been added by you.`,
      read: false,
      timestamp: generateTimestamp(),
      type: "dancer",
      user_id: data?.uid,
    };

    await setDoc(doc(notifsCollectionRef), notif);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to delete dancers

export const deleteDancersService = async (data) => {
  try {
    const dancersCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "dancers"
    );
    const q = query(
      dancersCollectionRef,
      where("dancer_uid", "==", data?.dancer_uid)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      await deleteDoc(doc.ref);
    }

    // // To delete the dancer from all entries it existed in
    // const entriesCollectionRef = collection(
    //   db,
    //   "competitions",
    //   data?.compId,
    //   "entries"
    // );
    // const q2 = query(
    //   entriesCollectionRef,
    //   where("event_uid", "==", data?.event_uid)
    // );

    // const querySnapshotEntries = await getDocs(q2);

    // const batch = writeBatch(db);
    // const entriesToDelete = [];

    // querySnapshotEntries.docs.forEach((entryDoc) => {
    //   const entryData = entryDoc.data();
    //   const dancersSelected = entryData.dancers_selected;
    //   const updatedDancersSelected = dancersSelected.filter(
    //     (dancer) => dancer.dancer_uid !== data?.dancer_uid
    //   );

    //   const newEntryType = filteredEntryTypes(
    //     data?.entryTypes,
    //     updatedDancersSelected?.length
    //   )[0]?.entryTypeName;

    //   const newEntryTypeSpecial = filteredSpecialEntryTypes(
    //     data?.specialEntryTypes,
    //     updatedDancersSelected?.length
    //   )[0]?.specialEntryTypeName;

    //   const string = entryData?.category_name;
    //   const normalName = `${entryData?.age_division}~${entryData?.classification}~${entryData?.genre}~${newEntryType}`;
    //   const tag = entryData?.special_tags?.[0]
    //     ?.replace(/ /g, "_")
    //     ?.toLowerCase();

    //   const categoryName = string?.includes("impulse")
    //     ? `impulse~${newEntryType}`
    //     : string?.includes("remix")
    //     ? `${newEntryTypeSpecial}`
    //     : string?.includes("unity")
    //     ? `${newEntryTypeSpecial}`
    //     : string?.includes("overtime")
    //     ? `${newEntryTypeSpecial}`
    //     : normalName + entryData?.special_tags?.length > 0
    //     ? `~[${tag}]`
    //     : "";

    //   const entryTypeNew = string?.includes("impulse")
    //     ? `${newEntryTypeSpecial}`
    //     : string?.includes("remix")
    //     ? `${newEntryTypeSpecial}`
    //     : string?.includes("unity")
    //     ? `${newEntryTypeSpecial}`
    //     : string?.includes("overtime")
    //     ? `${newEntryTypeSpecial}`
    //     : `${newEntryType}`;

    //   if (updatedDancersSelected.length === 0) {
    //     entriesToDelete.push(entryDoc.ref);
    //   } else {
    //     console.log("updating categories");
    //     batch.update(entryDoc.ref, {
    //       dancers_selected: updatedDancersSelected,
    //       entryType: entryTypeNew,
    //       category_name: categoryName,
    //     });
    //   }
    // });

    // for (const entryRef of entriesToDelete) {
    //   batch.delete(entryRef);
    // }

    // await batch.commit();

    // const querySnapshotEntriesNew = await getDocs(entriesCollectionRef);

    // // To handle categories
    // const categoriesCollectionRef = collection(
    //   db,
    //   "competitions",
    //   data?.compId,
    //   "categories"
    // );
    // const q3 = query(
    //   categoriesCollectionRef,
    //   where("event_uid", "==", data?.event_uid)
    // );
    // const existingCategoriesSnapshot = await getDocs(q3);

    // let allCats = [];
    // existingCategoriesSnapshot.forEach((doc) => {
    //   doc?.data()?.event_uid === data?.event_uid && allCats?.push(doc?.data());
    // });

    // const existingCategories = existingCategoriesSnapshot.docs.map((doc) => {
    //   return (
    //     doc?.data()?.event_uid === data?.event_uid && doc.data().category_name
    //   );
    // });

    // querySnapshotEntriesNew.docs.forEach((entryDoc) => {
    //   const entryData = entryDoc.data();

    //   const newId = generateno_id();

    //   if (!existingCategories.includes(entryData?.category_name)) {
    //     console.log("creating new category");

    //     setDoc(doc(categoriesCollectionRef, newId), {
    //       category_name: entryData?.category_name,
    //       sort_id: assignSortId(allCats),
    //       id: generateUniqueId(),
    //       is_scratched: false,
    //       event_uid: data?.event_uid,
    //       type: "category",
    //       no_id: newId,
    //     });
    //   }
    // });

    // To handle notifications
    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `Dancer deleted. Dancer ${data?.first_name} ${data?.last_name} has been deleted by you.`,
      read: false,
      timestamp: generateTimestamp(),
      type: "dancer",
      user_id: data?.uid,
    };

    await setDoc(doc(notifsCollectionRef), notif);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to edit dancers
export const editDancersService = async (data) => {
  try {
    const dancersCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "dancers"
    );

    const q = query(
      dancersCollectionRef,
      where("dancer_uid", "==", data?.formData?.dancer_uid)
    );

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      await updateDoc(doc.ref, data?.formData);
    }

    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `Dancer editted. Dancer ${data?.formData?.first_name} ${data?.formData?.last_name} has been editted by you.`,
      read: false,
      timestamp: generateTimestamp(),
      type: "dancer",
      user_id: data?.uid,
    };

    await setDoc(doc(notifsCollectionRef), notif);

    // To edit all entries age division the dancer it existed in
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    // const querySnapshotEntries = await getDocs(entriesCollectionRef);

    const q2 = query(
      entriesCollectionRef,
      where("event_uid", "==", data?.event_uid)
    );

    const querySnapshotEntries = await getDocs(q2);

    querySnapshotEntries.forEach(async (doc) => {
      const entryData = doc?.data();

      const isDancerExistEntry = entryData?.dancers_selected?.filter(
        (x) => x?.dancer_uid === data?.formData?.dancer_uid
      );
      // && !entryData?.isSpecialEntry
      if (isDancerExistEntry?.length > 0 && !entryData?.isSpecialEntry) {
        const newDancersSelected = entryData?.dancers_selected
          ?.filter((x) => x?.dancer_uid !== data?.formData?.dancer_uid)
          ?.concat([data?.formData]);

        const dobArray = newDancersSelected?.map((obj) => obj.date_of_birth);
        const ageArray = dobArray?.map((age) => calculateAge(age));
        const averageAge = calculateAverage(ageArray);

        const newAge = filteredAgeDivision(
          data?.eligibilityDivisions,
          averageAge
        )[0]?.divisionName;

        const newCategoryName = [newAge]
          ?.concat(entryData?.category_name?.split("~")?.slice(1))
          ?.join("~");

        updateDoc(doc.ref, {
          age_division: newAge,
          category_name: newCategoryName,
        });
      }
    });

    // To handle categories
    const categoriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "categories"
    );
    // const existingCategoriesSnapshot = await getDocs(categoriesCollectionRef);

    const q3 = query(
      categoriesCollectionRef,
      where("event_uid", "==", data?.event_uid)
    );
    const existingCategoriesSnapshot = await getDocs(q3);

    // console.log("existingCategories", existingCategories);
    let allCats = [];
    existingCategoriesSnapshot.forEach((doc) => {
      doc?.data()?.event_uid === data?.event_uid && allCats?.push(doc?.data());
    });

    const existingCategories = existingCategoriesSnapshot.docs.map((doc) => {
      return (
        doc?.data()?.event_uid === data?.event_uid && doc.data().category_name
      );
    });

    querySnapshotEntries.docs.forEach((entryDoc) => {
      const entryData = entryDoc.data();

      const newId = generateno_id();

      if (!existingCategories.includes(entryData?.category_name)) {
        console.log("creating new category");

        setDoc(doc(categoriesCollectionRef, newId), {
          category_name: entryData?.category_name,
          sort_id: assignSortId(allCats),
          id: generateUniqueId(),
          is_scratched: false,
          event_uid: data?.event_uid,
          type: "category",
          no_id: newId,
        });
      }
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=========================ENTRIES=============================
//=========================ENTRIES=============================

//=============================== to get all existing entries
export const getEntriesService = async (data) => {
  try {
    const dancersCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const dancersQuerySnapshot = await getDocs(
      query(dancersCollectionRef, where("studioId", "==", data?.uid))
    );
    return dancersQuerySnapshot.docs.map((doc) => doc.data());
  } catch (error) {
    throw error;
  }
};

//=============================== to add entries
export const addEntriesService = async (data) => {
  try {
    // Update entries collection
    await setDoc(
      doc(collection(db, "competitions", data?.compId, "entries")),
      data?.formData
    );

    // increment_entries submitted field
    const compDocRef = doc(db, "competitions", data?.compId.replace(/ /g, ""));
    const compSnapshot = await getDoc(compDocRef);
    const events = compSnapshot.data().events;
    const updatedEvents = events?.map((event) => ({
      ...event,
      entries_submitted: (event.entries_submitted || 0) + 1,
    }));

    await updateDoc(compDocRef, {
      events: updatedEvents,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//================================to add minutes to entries after existing
async function addMinutesToEntries(data) {
  const collectionRef = collection(
    db,
    "competitions",
    data?.compId,
    "schedule_dancers"
  );
  const querySnapshotCategories = await getDocs(collectionRef);

  querySnapshotCategories.forEach(async (doc) => {
    // Check if the document matches any object in the existingCategory array
    if (
      data?.categoriesAfterExisting.some(
        (category) => category.sort_id === doc.data()?.sort_id
      )
    ) {
      const entries = doc.data()?.entries || [];

      const updatedEntries = entries.map((entry) => {
        if (entry.time) {
          const newTime = moment(entry.time, "hh:mm A")
            .add(data?.minutesToAddCat, "minutes")
            .format("hh:mm A");
          return { ...entry, time: newTime };
        }
        return entry;
      });

      await updateDoc(doc.ref, {
        entries: updatedEntries,
      });
    }
  });
}

//=============================================================================to add entry
export const addScheduleEntryService = async (data) => {
  console.log("data", data);
  try {
    const scheduleCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    // Query the documents based on multiple field values
    const querySnapshot = await getDocs(
      query(
        scheduleCollectionRef,
        where("age_division", "==", data?.formData.age_division),
        where("classification", "==", data?.formData.classification),
        where("entryType", "==", data?.formData.entryType),
        where("genre", "==", data?.formData.genre),
        where("event_id", "==", data?.formData.event_id)
      )
    );

    // Query all the documents in the collection
    const querySnapshotAll = await getDocs(scheduleCollectionRef);

    let err = "";

    // Check if any matching entry exists in any document
    for (const doc of querySnapshotAll.docs) {
      try {
        const documentData = doc.data();
        const entries = documentData.entries || [];

        const existingEntry = entries.find((item) => {
          return (
            item.entry_name.toLowerCase() ===
              data?.formData.entry_name.toLowerCase() &&
            item.event_id.toLowerCase() ===
              data?.formData.event_id.toLowerCase()
          );
        });

        if (existingEntry) {
          throw new Error("Entry already exists");
        }
      } catch (error) {
        err = error;
        throw new Error(error.message);
      }
    }

    // Check if any matching document exists
    if (querySnapshot.empty) {
      // Create a new document with the specified ID and fields
      console.log("creating new document to add entry - studio");
      await setDoc(
        doc(
          scheduleCollectionRef,
          `category_${assignSortId(querySnapshotAll)}`
        ),
        {
          age_division: data?.formData.age_division,
          classification: data?.formData.classification,
          entryType: data?.formData.entryType,
          genre: data?.formData.genre,
          entries: [data.formData],
          id: data?.uniqueId,
          compId: data?.compId,
          event_id: data?.event_id,
          sort_id: Number(assignSortId(querySnapshotAll)),
          category: true,
          is_scratched: false,
          doc_id: `category_${assignSortId(querySnapshotAll)}`,
        }
      );
    } else {
      // Iterate over the query snapshot results
      console.log("adding entry to existing document - studio");

      querySnapshot.forEach(async (doc) => {
        const documentRef = doc.ref;

        try {
          // Get the current value of the entries field
          const documentData = (await doc.data()) || {};
          const currentEntries = documentData.entries || [];

          // Add the new data to the entries array
          const updatedEntries = [...currentEntries, data.formData];

          // Update the document's entries field with the updated array
          await updateDoc(documentRef, { entries: updatedEntries });

          const { message } = await updateAllDocs(
            scheduleCollectionRef,
            data?.entryTypes,
            data?.currentEvent
          );
          console.log(message);
        } catch (error) {
          err = error;
          throw new Error(error.message);
        }
      });
    }

    //update notifs
    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `New entry added. Entry ${data?.formData?.entry_name} has been added by you.`,
      read: false,
      timestamp: generateTimestamp(),
      type: "entry",
      user_id: data?.uid,
      for: data?.compId,
      for_message: `New entry added. Entry ${data?.formData?.entry_name} has been added by ${data?.formData?.studio_selected[0]}.`,
    };

    await setDoc(doc(notifsCollectionRef), notif);

    // increment_entries submitted field
    const compDocRef = doc(db, "competitions", data?.compId.replace(/ /g, ""));
    const compSnapshot = await getDoc(compDocRef);
    const events = compSnapshot.data().events;
    const updatedEvents = events?.map((event) => ({
      ...event,
      entries_submitted: (event.entries_submitted || 0) + 1,
    }));

    await updateDoc(compDocRef, {
      events: updatedEvents,
    });

    // Add code to update "time" field values
    if (data?.categoriesAfterExisting?.length > 0) {
      await addMinutesToEntries(data);
    }

    return err !== ""
      ? { success: false, message: "entry exists" }
      : { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

// function padNumberWithZeros(number) {
//   const desiredDigits = 4;
//   const numString = number.toString();
//   const numDigits = numString.length;

//   if (numDigits >= desiredDigits) {
//     return numString;
//   }

//   const zerosToAdd = desiredDigits - numDigits;
//   return "0".repeat(zerosToAdd) + numString;
// }

//================================to subtract minutes to entries after existing entry
async function subtractMinutesFromEntries(data) {
  const collectionRef = collection(
    db,
    "competitions",
    data?.compId,
    "schedule_dancers"
  );
  const querySnapshotCategories = await getDocs(collectionRef);

  for (const doc of querySnapshotCategories.docs) {
    if (
      data?.categoriesAfterExisting.some(
        (category) => category.sort_id === doc.data()?.sort_id
      )
    ) {
      const entries = doc.data()?.entries || [];

      const updatedEntries = entries.map((entry) => {
        if (entry.time) {
          const newTime = moment(entry.time, "hh:mm A")
            .subtract(data?.minutesToSubtractCat, "minutes")
            .format("hh:mm A");
          return { ...entry, time: newTime };
        }
        return entry;
      });

      await updateDoc(doc.ref, {
        entries: updatedEntries,
      });
    }
  }
}

//==========================to add entry
export const deleteScheduleEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );

    const querySnapshotCategories = await getDocs(collectionRef);

    await Promise.all(
      querySnapshotCategories.docs.map(async (doc) => {
        const categoryData = doc.data();
        if (
          categoryData.age_division === data?.age_division &&
          categoryData.classification === data?.classification &&
          categoryData.entryType === data?.entryType &&
          categoryData.genre === data?.genre
        ) {
          const entryIndex =
            categoryData.entries?.findIndex(
              (entry) => entry.doc_id === data?.doc_id
            ) ?? -1;
          if (entryIndex !== -1) {
            const updatedEntries = [...(categoryData.entries ?? [])];
            updatedEntries.splice(entryIndex, 1);

            await updateDoc(doc.ref, { entries: updatedEntries });
          }
        }
      })
    );

    // Add code to update "time" field values
    if (data?.categoriesAfterExisting?.length > 0) {
      await subtractMinutesFromEntries(data);
    }

    const { message } = await updateAllDocs(
      collectionRef,
      data?.entryTypes,
      data?.currentEvent
    );
    console.log(message);

    //update notifs
    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `Entry deleted. Entry ${data?.entry_name} has been deleted by you.`,
      read: false,
      timestamp: generateTimestamp(),
      type: "entry",
      user_id: data?.uid,
      for: data?.compId,
      for_message: `Entry deleted. Entry ${data?.entry_name} has been added by studio.`,
    };

    await setDoc(doc(notifsCollectionRef), notif);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to edit entries
export const editEntriesService = async (data) => {
  try {
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const querySnapshot = await getDocs(entriesCollectionRef);
    querySnapshot.forEach((doc) => {
      if (doc.data().doc_id === data?.formData?.doc_id) {
        updateDoc(doc.ref, data?.formData);
      }
    });
    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//to edit entry
export const editEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "schedule_dancers"
    );
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);
    const category = docSnapshot.data();

    //if its a normal edit
    if (
      category?.age_division === data?.formData?.age_division &&
      category?.classification === data?.formData?.classification &&
      category?.entryType === data?.formData?.entryType &&
      category?.genre === data?.formData?.genre
    ) {
      const entries = category?.entries;

      const updatedEntries = entries.map((entry) => {
        if (entry?.entry_count === data?.entry_count) {
          return { ...entry, ...data?.formData };
        }
        return entry;
      });
      await updateDoc(docRef, { entries: updatedEntries });
      console.log("normal edit done successfully");
    }

    //if any of the unique category prop is changed
    if (
      category?.age_division !== data?.formData?.age_division ||
      category?.classification !== data?.formData?.classification ||
      category?.entryType !== data?.formData?.entryType ||
      category?.genre !== data?.formData?.genre
    ) {
      // Check if a document with matching age_division and classification exists
      const querySnapshot = await getDocs(collectionRef);
      let matchingDocumentFound = false;

      //to first delete the entry from the prev doc
      const prevEntries = category?.entries;
      const updatedEntries = prevEntries?.filter(
        (entr) => entr?.entry_count !== data?.entry_count
      );
      updateDoc(docRef, { entries: updatedEntries });

      querySnapshot.forEach((docSnapshot) => {
        const existingCategory = docSnapshot.data();
        if (
          existingCategory?.age_division === data?.formData?.age_division &&
          existingCategory?.classification === data?.formData?.classification &&
          existingCategory?.entryType === data?.formData?.entryType &&
          existingCategory?.genre === data?.formData?.genre
        ) {
          matchingDocumentFound = true;

          const entries = docSnapshot.data()?.entries || [];
          entries.push(data?.formData);
          updateDoc(docSnapshot.ref, { entries });
          console.log("Edit in existing document done successfully");
        }
      });

      if (!matchingDocumentFound) {
        const querySnapshotAll = await getDocs(collectionRef);

        const newDocumentRef = doc(
          collectionRef,
          `category_${assignSortId(querySnapshotAll)}`
        );

        await setDoc(newDocumentRef, {
          age_division: data?.formData.age_division,
          classification: data?.formData.classification,
          entryType: data?.formData.entryType,
          genre: data?.formData.genre,
          event_id: data?.formData.event_id,
          entries: [data?.formData],
          id: generateUniqueId(),
          sort_id: Number(assignSortId(querySnapshotAll)),
          compId: data?.compId,
          category: true,
          is_scratched: false,
          doc_id: `category_${assignSortId(querySnapshotAll)}`,
        });
        console.log("document not found, New doc created");
      }
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
function generateUniqueId() {
  const timestamp = new Date().getTime().toString();
  const randomNum = Math.floor(Math.random() * 900000000000) + 100000000000;
  const uniqueId = `${timestamp}${randomNum}`;
  return uniqueId.substring(0, 15);
}

//to build sort_id for category
const assignSortId = (array) => {
  if (array && Array.isArray(array) && array.length > 0) {
    const maxSortId = Math.max(...array.map((obj) => obj?.sort_id || 0));
    const newSortId = maxSortId + 1;

    return newSortId;
  } else {
    // If the allEntries array is empty or not valid, start with sort_id 1
    return 1;
  }
};

export const editAccountDetailsService = async (data) => {
  try {
    const accountDocRef = doc(db, "studio_owners", `${data?.uid}`);
    const accountDoc = await getDoc(accountDocRef);

    if (accountDoc.exists()) {
      const updatedData = {
        ...accountDoc.data(),
        ...data?.detailsData,
      };

      await setDoc(accountDocRef, updatedData);

      return { success: true };
    } else {
      return { success: false, message: "Document does not exist" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to add estimated entries to event
export const addEstEntriesService = async (data) => {
  try {
    const organizerDocRef = doc(db, "competitions", data?.compId);
    const docSnapshot = await getDoc(organizerDocRef);
    if (docSnapshot.exists()) {
      const events = docSnapshot.data()?.events || [];
      const updatedEvents = events.map((event) => {
        return event.event_uid === data?.event_uid
          ? {
              ...event,
              access: event?.access?.map((x) =>
                x?.studio_id === data?.uid
                  ? { ...x, estimated_entries: data?.estEntries }
                  : x
              ),
            }
          : event;
      });
      await updateDoc(organizerDocRef, { events: updatedEvents });
      return { success: true };
    } else {
      throw new Error("Document not found");
    }
  } catch (error) {
    return { success: false, message: error?.message };
  }
};

//=============================== to request age division override
export const requestAgeOverrideService = async (data) => {
  try {
    const notifsCollectionRef = collection(db, "notifications");

    const notif = {
      id: generateUID(),
      message: `Studio "${data?.studio_name} "requested for an age division override for entry "${data?.entry_name}". \n New age division = "${data?.selectedAge}"`,
      read: false,
      timestamp: generateTimestamp(),
      type: "override",
      user_id: data?.compId,
    };

    await setDoc(doc(notifsCollectionRef), notif);

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
