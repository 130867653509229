import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { clearSuccess } from 'redux/eventManagement/eventManagement';
import { db } from 'services/firebase';
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';

const EditClassForm = ({ onClose, organizerData, user, compLevel }) => {
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState('');

  const [formData, setFormData] = useState({
    description: compLevel?.description,
    level_name: compLevel?.levelName,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setFormErr('');
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update form data when inputs change
  const handleChange = (e) => {
    setFormErr('');
    dispatch(clearSuccess());
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.description.trim() || !formData.level_name.trim()) {
      setFormErr('Please fill all required fields');
      return;
    } else if (organizerData && formData) {
      const organizerDocRef = doc(db, 'organizers', user?.uid);
      const competitionDocRef = doc(db, 'competitions', user?.uid);
      // Get the current document data
      const organizerDocSnap = await getDoc(organizerDocRef);
      const currentData = organizerDocSnap.data();

      // Find the index of the entry being edited

      const entryIndex = currentData.competitionLevel?.findIndex(
        (entry) => entry?.levelName === compLevel?.levelName
      );

      // Get the category_name of the special entry before making changes
      const prevName = compLevel?.levelName;

      // Update the special entry in the array
      const newOne = {
        description: formData?.description,
        levelName: formData?.level_name,
      };

      const Filtered = currentData.competitionLevel?.filter(
        (_, ind) => ind !== entryIndex
      );
      const newTags = [...Filtered, newOne];

      // Update the document with the new data
      await updateDoc(organizerDocRef, {
        competitionLevel: newTags,
      });
      await updateDoc(competitionDocRef, {
        competitionLevel: newTags,
      });

      // If the special entry's category_name has changed, delete the old entries
      if (prevName !== formData?.levelName) {
        // Reference to the "entries" sub-collection
        const entriesRef = collection(db, 'competitions', user?.uid, 'entries');

        // Get all entries with the previous category_name
        const entriesQuery = query(
          entriesRef,
          where('classification', '==', prevName)
        );

        // Get the documents that match the query
        const entryQuerySnapshot = await getDocs(entriesQuery);

        // Delete each entry that matches the query
        entryQuerySnapshot?.forEach((doc) => {
          deleteDoc(doc.ref);
        });
      }

      // Close the pop-up
      onClose();
    }

    // Set loading to false after the update is complete
    setLoading(false);
  };

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
    >
      <div className="rounded-lg p-6 w-[40%] h-[410px] mx-auto md:w-1/2 max-w-[700px] min-w-[300px]">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-[#282929] rounded-lg p-6 scale"
        >
          <form onSubmit={handleSubmit}>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                id="description"
                placeholder="Eg. description"
                value={formData.description}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="classification">Classification </label>
              <input
                type="text"
                id="level_name"
                placeholder="Eg. Novice"
                value={formData.level_name}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>

            <div className="flex gap-3  mb-6 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-8 py-1 bg-white rounded-full text-black/80 text-[0.85rem] hover:opacity-75"
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Submit'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
            {formErr && <p className="text-red-500">{formErr}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditClassForm;
