/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { collection, onSnapshot, query } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { db } from "services/firebase";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";

const TopScorers = () => {
  // const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const compId = localStorage.getItem("compId") || "";

  const { status, data: compData } = useFirestoreQueryDoc(
    "competitions",
    compId
  );
  const { event_name } = useParams();

  // console.log('compData', compData);

  const currentEvent = compData?.events?.filter(
    (item) => item?.event_name === event_name
  )[0];

  const [genreArr, setGenreArr] = useState({});

  useEffect(() => {
    if (status === "success") {
      currentEvent?.genre_visibility
        ? setGenreArr(currentEvent?.genre_visibility)
        : setGenreArr(compData?.danceGenres);
    }
  });

  const trueGenreKeys = Object.keys(genreArr)
    .filter((key) => genreArr[key] === true)
    .map((item) => item.toLowerCase());

  // console.log("trueGenreKeys", trueGenreKeys);

  const startTime = moment(currentEvent?.days[0].startTime)
    .local()
    .format("MMM D");

  const endTime = moment(
    currentEvent?.days[currentEvent?.days?.length - 1].endTime
  )
    .local()
    .format("MMM D");

  const { data: allCategories } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "categories"
  );

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    compId,
    "entries"
  );

  const eventEntries = allEntries?.filter(
    (each) => each?.event_uid === currentEvent?.event_uid
  );

  //======================================================to get scores
  const [scoresData, setScoresData] = useState([]);
  useEffect(() => {
    const pathStr = currentEvent?.live_update ? "scores" : "manual_scores";
    const unsubscribe = onSnapshot(
      query(collection(db, "competitions", compId, pathStr)),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setScoresData(data);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [currentEvent]);

  const eventScores = scoresData?.filter(
    (item) => item?.event_name === currentEvent?.event_name
  );

  // console.log("eventScores", eventScores);

  const calculateAverageScore = (arr, key) => {
    if (Array.isArray(arr)) {
      const filteredArray = arr?.filter((itm) => itm?.technique);
      const array = key === "technique" ? filteredArray : arr;
      const totalSum = array?.reduce((sum, obj) => sum + Number(obj[key]), 0);
      const average = totalSum / array?.length;
      const averageFormatted = Number(average)?.toFixed(2);

      return averageFormatted;
    }
  };

  function getDeduction(arr) {
    const lastCharacters = arr?.map((str) => {
      if (str?.length > 0) {
        return Number(str?.slice(-1));
      } else {
        return "";
      }
    });

    const ded = lastCharacters?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    return ded;
  }

  const calculateTotalScore = (entry) => {
    const scores = eventScores?.filter(
      (item) =>
        item?.doc_id === entry?.doc_id &&
        item?.event_name === currentEvent?.event_name
    );

    const allDeductions = scores
      ?.flatMap((obj) => obj.deductions)
      ?.filter((item) => item !== "none");

    const sum =
      [
        Number(calculateAverageScore(scores, "technique")),
        Number(calculateAverageScore(scores, "creativity")),
        Number(calculateAverageScore(scores, "staging")),
        Number(calculateAverageScore(scores, "execution")),
        Number(calculateAverageScore(scores, "performance")),
      ]?.reduce((accumulator, currentValue) => accumulator + currentValue, 0) -
      getDeduction(allDeductions);

    const truncatedScore = parseFloat(sum?.toFixed(2));

    return isNaN(truncatedScore) ? "- -" : truncatedScore;
  };

  const calculateHighestScore = (categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    let highestScore = 0;

    filteredEntries?.forEach((entry) => {
      const scores = eventScores?.filter(
        (item) =>
          item?.doc_id === entry?.doc_id &&
          item?.event_name === currentEvent?.event_name
      );

      const totalScoreRaw = scores?.reduce(
        (sum, score) => sum + Number(score?.total || 0),
        0
      );

      const totalScore = totalScoreRaw / scores?.length;

      if (totalScore > highestScore) {
        highestScore = totalScore;
      }
    });

    return highestScore;
  };

  const calculatePosition = (entry, categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    const highestScore = calculateHighestScore(filteredEntries);
    const entryScore = calculateTotalScore(entry);

    const sortedScores = filteredEntries
      .map((entry) => calculateTotalScore(entry))
      .sort((a, b) => b - a);

    const position = sortedScores?.indexOf(entryScore) + 1;

    if (!highestScore || entryScore === "- -") {
      return "- -";
    } else if (entryScore === highestScore) {
      return 1;
    } else if (position === 1) {
      return 1;
    } else if (position === 2) {
      return 2;
    } else if (position === 3) {
      return 3;
    } else {
      return position;
    }
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="w-full min-h-screen text-white/80 px-5 py-[50px] bg-[#1c1c1c] flex flex-col transition-all duration-700">
        {/* section holding the awards and entries */}
        <div className="w-full text-[.9em] pt-3 mb-20 overflow-x-auto overflow-y-hidden">
          {status === "loading" ? (
            <div className="w-full h-[200px] flex justify-center items-center gap-6 mb-auto text-white/80">
              <PulseLoader color="#94a4fd" size={10} />
            </div>
          ) : (
            <h2 className="text-center mb-4">
              {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
              {currentEvent?.year.split(" to ")[0]} |{" "}
              {currentEvent?.days?.length === 1
                ? `${startTime}, ${currentEvent?.year}`
                : `${startTime} - ${endTime} ${currentEvent?.year}`}
            </h2>
          )}
          <div className="lg:min-w-[1200px]">
            <button
              onClick={goBack}
              className="px-5 py-1 bg-white/80 hover:bg-white/50 text-black rounded-lg text-[.75rem] md:text-[1rem] mb-8 absolute top-4 left-3"
            >
              Back
            </button>
            <div className="w-[70%] p-3 bg-[#282929] my-4 mx-auto rounded-lg md:text-center font-bold">
              <h2 className="text-3xl">TOP SCORES BY GENRE</h2>
            </div>
            <p className="text-center">
              In No Particular Order <br />
              Last updated:{" "}
              {currentEvent?.live_update
                ? "LIVE"
                : currentEvent?.update_timestamp}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4 gap-5 overflow-x-hidden">
              {trueGenreKeys?.map((item, index) => {
                //=================================================================entrycount calc
                const eventCategories = allCategories?.filter(
                  (cat) => cat?.event_uid === currentEvent?.event_uid
                );
                let sortedCategories = eventCategories?.sort(
                  (a, b) => a?.sort_id - b?.sort_id
                );

                const categories = sortedCategories?.filter(
                  (l) => l?.type === "category"
                );

                const allEntriesWithoutLocked = arrangeEntriesByCategories(
                  categories,
                  allEntries
                )?.filter((k) => k?.locked_entry === false);

                const allEntriesWithLocked = arrangeEntriesByCategories(
                  categories,
                  allEntries
                );

                function entryCount(itm) {
                  const allEntriesBeforeLocked = allEntriesWithLocked
                    ?.slice(
                      0,
                      findIndexByProperty(
                        allEntriesWithLocked,
                        "doc_id",
                        itm?.doc_id
                      )
                    )
                    ?.filter((m) => m?.locked_entry === false);

                  if (itm?.locked_entry) {
                    return `${
                      allEntriesBeforeLocked?.length === 0
                        ? 1
                        : allEntriesBeforeLocked?.length
                    }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
                  } else {
                    return (
                      findIndexByProperty(
                        allEntriesWithoutLocked,
                        "doc_id",
                        itm?.doc_id
                      ) + 1
                    );
                  }
                }

                //=================================================
                const genreEntriesRaw = eventEntries?.filter(
                  (itm) => itm?.genre.toLowerCase() === item.toLowerCase()
                );

                const genreEntries = genreEntriesRaw?.filter((entry) =>
                  eventScores?.some((score) => score.doc_id === entry.doc_id)
                );

                const rankedEntries = genreEntries?.sort(
                  (a, b) =>
                    calculatePosition(a, genreEntries) -
                    calculatePosition(b, genreEntries)
                );

                const topEntries = rankedEntries?.slice(0, 10);

                // console.log("topEntries", topEntries);
                return (
                  <div key={index} className="w-full">
                    <div className="w-full p-3 bg-[#282929] my-4 rounded-lg md:text-center font-bold">
                      <h2 className="text-xl uppercase">TOP {item}</h2>
                    </div>
                    <div
                      className={`w-full flex flex-col flex-wrap text-[.75rem] sm:text-[.85rem] ${
                        topEntries?.length === 0 ? "h-[150px]" : "max-h-[240px]"
                      }`}
                    >
                      {topEntries?.length === 0 && (
                        <div className="w-full h-full rounded-lg flex justify-center items-center bg-[#282929]">
                          Nothing yet..
                        </div>
                      )}
                      {topEntries?.map((itm, idx) => {
                        return (
                          <div
                            key={idx}
                            className="w-1/2 flex gap-4 border-b border-white/10 p-3"
                          >
                            <p className="">
                              #
                              {currentEvent?.locked
                                ? itm?.locked_entry_count
                                : entryCount(itm)}
                            </p>
                            <p className="whitespace-nowrap">
                              {capitalizeFirstLetter(itm?.entry_name)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopScorers;
