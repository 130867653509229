const RoutineNotifiers = ({
  currentEvent,
  confrmDrag,
  consecutiveBuffer,
  lockedSchedule,
  notifyDrag,
}) => {
  return (
    <>
      {currentEvent?.locked && (
        <>
          <div className="text-[.85rem] flex gap-2 items-center">
            <span className="text-[1rem] text-white/80">
              Schedule is locked!
            </span>
            <img alt="arrow" src="/images/LockSimple.png" className="w-5 h-5" />
          </div>
          <div
            //   onClick={toggleUnlockMod}
            className="w-fit text-[.85rem] text-[#94a4fd] p-1 hover:bg-[#94a4fd]/30 bg-white/10 hover:px-2 rounded-md cursor-pointer mt-1"
          >
            Unlock?
          </div>
          <div
            //   onClick={toggleConfirmDrag}
            className="w-fit text-[.85rem] text-[#94a4fd] p-1 hover:bg-[#94a4fd]/30 bg-white/10 hover:px-2 rounded-md cursor-pointer"
          >
            {confrmDrag ? "Disable Actions" : "Enable Actions"}
            <br />
          </div>
        </>
      )}

      {(consecutiveBuffer || lockedSchedule) && !confrmDrag && (
        <div className="w-[250px] p-3 rounded-lg border border-red-500 text-[.85rem] z-[2] fixed top-[100px] left-[50%] bg-[#282929] flex gap-3 items-center scale">
          <img alt="" src="/images/icons8-cross-30.png" className="w-6 h-6" />{" "}
          {lockedSchedule
            ? "Schedule is locked! Unlock or 'enable actions'"
            : "Consecutive time buffers!"}
        </div>
      )}
      {notifyDrag && (
        <div
          className={`w-[250px] p-3 rounded-lg border  text-[.85rem] z-[2] fixed top-[100px] left-[50%] bg-[#282929] flex gap-3 items-center scale ${
            confrmDrag ? "border-green-400" : "border-red-500"
          }`}
        >
          <img
            alt=""
            src={
              confrmDrag
                ? "/images/icons8-check-green.png"
                : "/images/icons8-cross-30.png"
            }
            className="w-6 h-6"
          />{" "}
          {confrmDrag ? "Actions enabled!" : "Actions disabled!"}
        </div>
      )}
    </>
  );
};

export default RoutineNotifiers;
