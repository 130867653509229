import React from 'react';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';

const DancerTableRow = ({
  row,
  index,
  calculateAge,
  calculateAge2,
  toggleDelete,
  toggleEditDancers,
  setdancerIndex,
  setDeleteId,
  convertDateFormat,
  eventEntries,
  setShowNewRow,
  currentEvent,
}) => {
  function collectDancersSelectedItems(originalArray) {
    const collectedItems = [];

    originalArray?.forEach((item) => {
      const dancersSelected = item.dancers_selected;
      collectedItems.push(...dancersSelected);
    });

    return collectedItems;
  }

  const myDancerExists = collectDancersSelectedItems(eventEntries)?.filter(
    (it) => it?.id === row?.id
  );

  // const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  // const [showNewRow, setShowNewRow] = useState(false);
  // const hideRowTimeoutRef = useRef(null);
  // // console.log(row);

  // const handleRowMouseLeave = (event) => {
  //   const isLeavingOriginalRow =
  //     event.relatedTarget?.parentNode?.parentNode?.nodeName !== "tr";
  //   const isLeavingNewRow = event.relatedTarget?.parentNode?.nodeName !== "td";

  //   hideRowTimeoutRef.current = setTimeout(() => {
  //     if (isLeavingOriginalRow || isLeavingNewRow) {
  //       setHoveredRowIndex(null);
  //       setShowNewRow(false);
  //     }
  //   }, 500); // Set a 500ms delay before hiding the row
  // };

  // // const dispatch = useDispatch();

  // const handleRowMouseEnter = (index) => {
  //   setTimeout(() => {
  //     setHoveredRowIndex(index);
  //     setShowNewRow(true);
  //     setdancerIndex(index);
  //   }, 200);
  //   clearTimeout(hideRowTimeoutRef.current);
  // };

  // console.log("=>", convertDateFormat(row?.date_of_birth));

  return (
    <>
      <tr
        className="border-y border-white/10 hover:bg-[#94a4fd]/20 cursor-pointer relative whitespace-nowrap transition-all duration-500"
        onClick={(e) => {
          e.stopPropagation();
          setShowNewRow(row);
        }}
        // onMouseLeave={handleRowMouseLeave}
      >
        <td className="flex items-center mt-2">
          <div className="w-6 h-6 rounded-full border-4 border-white/60"></div>
          {capitalizeFirstLetter(row?.first_name)}{' '}
          {capitalizeFirstLetter(row?.last_name)}
        </td>
        <td>
          {row?.gender === 'female'
            ? 'F'
            : row?.gender === 'male'
            ? 'M'
            : row?.gender === 'transgender'
            ? 'T'
            : row?.gender === 'non-binary'
            ? 'NB'
            : 'P'}
        </td>
        <td>{capitalizeFirstLetter(row?.classification)}</td>
        {/* <td>{row?.special_tags}</td> */}
        <td>{convertDateFormat(row?.date_of_birth)}</td>
        {/* <td>{calculateAge(convertDateFormat(row?.date_of_birth))}</td> */}
        <td>
          {calculateAge2(
            convertDateFormat(row?.date_of_birth),
            currentEvent?.year
          )}
        </td>

        <td>
          <p className="max-w-[200px] whitespace-nowrap truncate">
            {row?.notes ? row?.notes : 'N/A'}
          </p>
        </td>
        <td>
          <div className="flex items-center">
            <img
              alt="arrow"
              src="/images/CalendarBlank.png"
              className="w-6 h-6"
            />
            {row?.last_update}
          </div>
        </td>
        <td>
          <div
            className={`flex items-center ${
              myDancerExists?.length === 0
                ? 'text-yellow-200'
                : myDancerExists?.length > 0
                ? 'text-green-300'
                : 'text-red-400'
            } `}
          >
            <div
              className={`w-1 h-1 rounded-full ${
                myDancerExists?.length === 0
                  ? 'bg-yellow-200'
                  : myDancerExists?.length > 0
                  ? 'bg-green-300'
                  : 'bg-red-400'
              }`}
            ></div>
            {/* {myDancerExists?.length > 0 && (
              <span className="font-medium">{myDancerExists?.length}</span>
            )} */}
            {myDancerExists?.length === 0
              ? 'Not Scheduled'
              : myDancerExists?.length > 0
              ? myDancerExists?.length + ' Scheduled'
              : 'Issue'}
          </div>
        </td>
      </tr>

      {/* {showNewRow && hoveredRowIndex === index && (
        <div className="w-full h-full fixed top-0 left-0 bg-black/80 flex justify-center py-[100px] px-3 z-[50]">
          <div className="w-full md:w-[500px] h-fit rounded-xl bg-[#282929] px-4 py-5 flex flex-col gap-4 text-white scale">
            <h2 className="font-bold text-[1.4rem] text-center border-b border-white/80 pb-2">
              Entry "", options
            </h2>
            <div
              onClick={() =>
                dispatch(
                  toggleEditDancers({
                    first_name: row?.first_name,
                    last_name: row?.last_name,
                    dancer_uid: row?.dancer_uid,
                  })
                )
              }
              className="w-full p-3 text-[#94a4fd] whitespace-nowrap cursor-pointer hover:bg-[#94a4fd]/20 rounded-lg"
            >
              Edit Dancer
            </div>
            <div
              onClick={() => {
                toggleDelete();
                setDeleteId({
                  first_name: row?.first_name,
                  last_name: row?.last_name,
                  dancer_uid: row?.dancer_uid,
                });
              }}
              className="w-full p-3 text-red-400 whitespace-nowrap cursor-pointer hover:bg-[#94a4fd]/20 rounded-lg"
            >
              Delete Dancer
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default DancerTableRow;
