import { useState } from 'react';
import { db } from 'services/firebase';
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';
import { PulseLoader } from 'react-spinners';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';

const AddGenreForm = ({ organizerData, user, status }) => {
  const [newGenre, setNewGenre] = useState('');
  const [loading, setLoading] = useState(false);

  const updateDanceGenres = async () => {
    setLoading(true);
    if (organizerData && newGenre) {
      const organizerDocRef = doc(db, 'organizers', user?.uid);
      const competitionsDocRef = doc(db, 'competitions', user?.uid);

      // Get the current document data
      const organizerDocSnap = await getDoc(organizerDocRef);
      const currentData = organizerDocSnap.data();

      // Update the danceGenres field in the document
      const updatedGenres = {
        ...currentData.danceGenres,
        [newGenre]: true,
      };

      // Update the document with the new data
      await updateDoc(organizerDocRef, { danceGenres: updatedGenres });
      await updateDoc(competitionsDocRef, { danceGenres: updatedGenres });

      // Clear the input field
      setNewGenre('');
    }
    setLoading(false);
  };

  const [edit, setEdit] = useState(false);
  const [editedGenres, setEditedGenres] = useState({});
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);

  const toggleEdit = () => {
    setEdit((prev) => !prev);
  };

  const openDeleteConfirmation = (value) => {
    setSelectedValue(value);
    setDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    const organizerDocRef = doc(db, 'organizers', user?.uid);
    const competitionsDocRef = doc(db, 'competitions', user?.uid);

    // Get the current document data
    const organizerDocSnap = await getDoc(organizerDocRef);
    const currentData = organizerDocSnap.data();

    // Clone the current danceGenres object
    const updatedGenres = { ...currentData.danceGenres };

    if (selectedValue) {
      // Remove the selected genre from the updatedGenres object
      delete updatedGenres[selectedValue];

      // Update the document with the updated danceGenres object
      await updateDoc(organizerDocRef, {
        danceGenres: updatedGenres,
      });

      // Update the competition document with the updated danceGenres object
      await updateDoc(competitionsDocRef, {
        danceGenres: updatedGenres,
      });

      if (selectedValue) {
        // Reference to the "entries" sub-collection
        const entriesRef = collection(db, 'competitions', user?.uid, 'entries');

        // Get all entries with the previous levelName
        const entriesQuery = query(
          entriesRef,
          where('genre', '==', selectedValue)
        );

        // Get the documents that match the query
        const entryQuerySnapshot = await getDocs(entriesQuery);

        // Delete each entry that matches the query
        entryQuerySnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
      }
    }
    // Set loading to false after the update is complete
    setLoading(false);

    // Close the confirmation dialog
    setDeleteConfirmation(false);
  };

  const saveEditedDanceGenres = async () => {
    setLoading(true);
    const organizerDocRef = doc(db, 'organizers', user?.uid);
    const competitionsDocRef = doc(db, 'competitions', user?.uid);

    // Get the current document data
    const organizerDocSnap = await getDoc(organizerDocRef);
    const currentData = organizerDocSnap.data();

    if (editedGenres) {
      const updatedGenres = {};

      for (const key in currentData.danceGenres) {
        if (currentData.danceGenres.hasOwnProperty(key)) {
          if (editedGenres[key]) {
            updatedGenres[editedGenres[key]] = true; // Change the key to the edited value
          } else {
            updatedGenres[key] = currentData.danceGenres[key];
          }
        }
      }

      // Check if danceGenres is defined before updating the document
      await updateDoc(organizerDocRef, { danceGenres: updatedGenres });
      await updateDoc(competitionsDocRef, { danceGenres: updatedGenres });
    }

    setEdit(false);
    setLoading(false);
  };

  return (
    <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
      <h1 className="w-full border-b pb-2 font-bold border-white/30">
        All Genres Values
      </h1>
      {status === 'loading' && (
        <div className="w-full h-[150px] flex justify-center items-center">
          <PulseLoader color="#94a4fd" size={10} />
        </div>
      )}
      <div className="grid grid-cols-3 gap-3">
        {organizerData &&
          Object?.keys(organizerData?.danceGenres)?.map((genre) => (
            <div
              key={genre}
              className="bg-[#1c1c1c] rounded-lg p-2 text-sm text-white flex items-center justify-between"
            >
              {edit ? (
                <input
                  type="text"
                  defaultValue={editedGenres[genre] || genre}
                  onChange={(e) => {
                    const updatedEditedGenres = { ...editedGenres };
                    const newGenreName = e.target.value;

                    if (newGenreName !== genre) {
                      updatedEditedGenres[genre] = newGenreName; 
                    }

                    setEditedGenres(updatedEditedGenres);
                  }}
                  className="bg-[#1c1c1c] rounded-lg px-2 py-1 text-sm text-white flex items-center justify-center w-[120px] border"
                />
              ) : (
                capitalizeFirstLetter(genre)
              )}
              {!edit && (
                <div className="flex items-center gap-3">
                  <img
                    src="/images/deleteCat.png"
                    alt="delete"
                    className="w-full h-4 cursor-pointer"
                    onClick={() => openDeleteConfirmation(genre)}
                  />
                </div>
              )}
            </div>
          ))}
      </div>
      <div className="mt-5 flex items-center justify-end">
        <input
          type="text"
          placeholder="New Genre"
          value={newGenre}
          onChange={(e) => setNewGenre(e.target.value)}
          className="input input-bordered input-sm border-white/60 rounded-[5px] h-9 w-40 mr-3 text-sm text-white/80 bg-inherit"
        />
        <button
          onClick={updateDanceGenres}
          disabled={loading}
          className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75 cursor-pointer"
        >
          {loading ? 'Processing...' : 'Add Genre'}
        </button>
      </div>
      <div className="flex gap-2 justify-end">
        <button
          onClick={toggleEdit}
          disabled={loading}
          className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
        >
          {edit ? 'Cancel Edit' : 'Edit Values'}
        </button>

        {edit && (
          <button
            onClick={saveEditedDanceGenres}
            disabled={loading}
            className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75"
          >
            Submit
          </button>
        )}
      </div>
      {/* Show confirmation dialog if isConfirmationOpen is true for deletion*/}
      {deleteConfirmation && selectedValue !== null && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50">
              Confirm Delete Genre:
              <br />
              <span className="font-bold">{`${selectedValue}`}</span>?
            </p>

            <p className="text-white/70">
              NOTE: Once you Delete this{' '}
              <span className="font-bold">{`${selectedValue}`}</span> it will be
              deleted from the events and all the entries attached to it.{' '}
              <b>NB:</b>
              take note this process is irreversible.
              <br />
              Are you sure you want to proceed with the changes?
            </p>

            <div className="mt-4 w-full text-center flex items-center justify-center">
              <button
                onClick={() => setDeleteConfirmation(false)}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-full bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                onClick={handleDelete}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Delete'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddGenreForm;
