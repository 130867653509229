/* eslint-disable no-loop-func */
import { collection, getDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "./firebase";

//==========================to checkIn entry
export const checkInEntryService = async (data) => {
  console.log("data", data);
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { checkedIn: true }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to revert checkIn entry
export const revertCheckInEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { checkedIn: false }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to performance dancer ready entry
export const performanceReadyEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { performance_ready: true }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to revert performance dancer ready entry
export const revertPerformanceReadyEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { performance_ready: false }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to announce entry
export const announceEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { announced: !docSnapshot?.data()?.announced });

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to run around dancer entry
export const runAroundEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { isRunAround: true }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to revert run around dancer entry
export const revertRunAroundEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { isRunAround: false }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to checked props dancer entry
export const isCheckedPropsEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { isCheckedProps: true }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to revert checked props dancer entry
export const revertIsCheckPropsEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { isCheckedProps: false }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to dancer start dancer entry
export const dancerStartEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );

    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { isDancerStarts: true }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to revert dancers start dancer entry
export const revertDancerStartEntryService = async (data) => {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    // Get the document by its doc_id
    const docRef = doc(collectionRef, data?.doc_id);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      await updateDoc(docRef, { isDancerStarts: false }); // Update checkedIn field directly

      return { success: true };
    } else {
      return { success: false, message: "Document not found" };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to add note
export const addNoteService = async (data) => {
  try {
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const docId = data?.doc_id;

    const docRef = doc(entriesCollectionRef, docId);
    const docSnapshot = await getDoc(docRef);

    // Get the existing note array or initialize an empty array
    let existingNote = docSnapshot.exists()
      ? docSnapshot.data().notes || []
      : [];

    // If the array is empty, add the note as the first item
    if (existingNote.length === 0) {
      existingNote = [data?.note];
    } else {
      // If the array is not empty, add the note to it
      existingNote.push(data?.note);
    }

    // Update the document with the new note array
    await updateDoc(docRef, {
      notes: existingNote,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to delete dancers
export const deleteNoteService = async (data) => {
  try {
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const docId = data?.doc_id;

    const docRef = doc(entriesCollectionRef, docId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Get the existing notes array or initialize an empty array
      const existingNotes = docSnapshot.data().notes || [];

      // Filter out the note with the specified note_uid
      const updatedNotes = existingNotes.filter(
        (note) => note.note_uid !== data?.note?.note_uid
      );

      // Update the document with the updated notes array
      await updateDoc(docRef, {
        notes: updatedNotes,
      });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
