import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { clearSuccess } from 'redux/eventManagement/eventManagement';
import { db } from 'services/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

const AwardForm = ({ onClose, organizerData, user }) => {
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState('');

  const [formData, setFormData] = useState({
    awardName: '',
    code: '',
    minScore: '',
    maxScore: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setFormErr('');
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update form data when inputs change
  const handleChange = (e) => {
    setFormErr('');
    dispatch(clearSuccess());
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      !formData.awardName.trim() ||
      !formData.code.trim() ||
      !formData.minScore ||
      !formData.maxScore
    ) {
      setFormErr('Please fill all required fields');
      return;
    } else if (organizerData && formData) {
      const compDocRef = doc(db, 'competitions', user?.uid);
      const organizerDocRef = doc(db, 'organizers', user?.uid);

      // Get the current document data
      const organizerDocSnap = await getDoc(compDocRef);
      const currentData = organizerDocSnap.data();

      // Create a new item with a numeric index
      const newItem = {
        awardName: formData?.awardName,
        code: formData?.code,
        minScore: formData?.minScore,
        maxScore: formData?.maxScore,
      };

      const updatedData = {
        ...currentData,
        awards: [...currentData?.awards, newItem],
      };

      // Update the document with the new data
      await updateDoc(compDocRef, updatedData);
      await updateDoc(organizerDocRef, updatedData);

      // Clear the input field
      setFormData({
        awardName: '',
        code: '',
        minScore: '',
        maxScore: '',
      });
    }
    setLoading(false);

    // Close the pop-up
    onClose();
  };

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
    >
      <div className="rounded-lg p-6 w-[40%] h-[410px] mx-auto md:w-1/2 max-w-[700px] min-w-[300px]">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-[#282929] rounded-lg p-6 scale"
        >
          <form onSubmit={handleSubmit}>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="divisionName"> Award Name</label>
              <input
                type="text"
                id="awardName"
                placeholder="Eg. Platinum"
                value={formData.awardName}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="divisionName"> Code </label>
              <input
                type="text"
                id="code"
                placeholder="Eg. PLT"
                value={formData.code}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="min_age">Min Score </label>
              <input
                type="number"
                id="minScore"
                placeholder="Eg. 75"
                value={formData.minScore}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="max_age">Max Score </label>
              <input
                type="number"
                id="maxScore"
                placeholder="Eg. 90"
                value={formData.maxScore}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>

            <div className="flex gap-3  mb-6 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-8 py-1 bg-white rounded-full text-black/80 text-[0.85rem] hover:opacity-75"
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Submit'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
            {formErr && <p className="text-red-500">{formErr}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default AwardForm;
