/* eslint-disable no-useless-escape */
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "services/firebase";

async function updateSpecialEntriesRaw(entriesArray, categoriesArray, compId) {
  try {
    console.log("entriesArray", entriesArray);
    // console.log("categoriesArray", categoriesArray);
    // console.log("compId", compId);
    console.log("===========update started=================");

    const entryCollectionRef = collection(
      db,
      "competitions",
      compId,
      "entries"
    );

    const categoryCollectionRef = collection(
      db,
      "competitions",
      compId,
      "categories"
    );

    entriesArray?.forEach(async (itm, ind) => {
      if (
        itm?.category_name?.includes("impulse") ||
        itm?.category_name?.includes("remix") ||
        itm?.category_name?.includes("unity") ||
        itm?.category_name?.includes("overtime")
      ) {
        console.log(ind + 1, "=======update entry continues=========");

        const stringArray = itm?.category_name?.includes("~")
          ? itm?.category_name?.split("~")
          : [itm?.category_name];
        const categoryName = itm?.category_name?.includes("impulse")
          ? `impulse~${stringArray?.[stringArray?.length - 1]}`
          : itm?.category_name?.includes("remix")
          ? "remix"
          : itm?.category_name?.includes("unity")
          ? "unity"
          : itm?.category_name?.includes("overtime")
          ? "overtime"
          : itm?.category_name;

        // console.log("categoryName", categoryName);

        await updateDoc(doc(entryCollectionRef, itm?.id), {
          category_name: categoryName,
        });
      }
    });

    categoriesArray?.forEach(async (itm, ind) => {
      if (
        itm?.category_name?.includes("impulse") ||
        itm?.category_name?.includes("remix") ||
        itm?.category_name?.includes("unity") ||
        itm?.category_name?.includes("overtime")
      ) {
        console.log(ind + 1, "=======update categories continues=========");

        const stringArray = itm?.category_name?.includes("~")
          ? itm?.category_name?.split("~")
          : [itm?.category_name];
        const categoryName = itm?.category_name?.includes("impulse")
          ? `impulse~${stringArray[stringArray?.length - 1]}`
          : itm?.category_name?.includes("remix")
          ? "remix"
          : itm?.category_name?.includes("unity")
          ? "unity"
          : itm?.category_name?.includes("overtime")
          ? "overtime"
          : itm?.category_name;
        // console.log("categoryName", categoryName);

        await updateDoc(doc(categoryCollectionRef, itm.id), {
          category_name: categoryName,
        });
      }
    });
  } catch (error) {
    console.log("error updating elements", error);
  }
}

// async function updateAll(
//   compId,
//   entriesArray,
//   categoriesArray,
//   allCategories,
//   assignSortId,
//   currentEvent
// ) {
//   try {
//     console.log("currentEvent?.event_uid", currentEvent?.event_uid);

//     // console.log("===========update started=================");
//     const categoryStringsEntr = entriesArray?.map((x) => x?.category_name);
//     // console.log("categoryStringsEntr", categoryStringsEntr);
//     const categoryStringsCate = categoriesArray?.map((x) => x?.category_name);

//     const noCategoryStrings = categoryStringsEntr?.filter(
//       (item) => !categoryStringsCate?.includes(item)
//     );
//     const removeDuplicates = (strings) => {
//       const uniqueStrings = Array.from(new Set(strings));
//       return uniqueStrings;
//     };

//     const categoryCollectionRef = collection(
//       db,
//       "competitions",
//       compId,
//       "categories"
//     );

//     removeDuplicates(noCategoryStrings)?.forEach(async (string, ind) => {
//       // console.log(ind + 1, "=======update continues=========");
//       const newId = generateno_id();

//       const stringArray = string?.includes("~") ? string?.split("~") : [string];
//       const categoryName = string?.includes("impulse")
//         ? `impulse~${stringArray[stringArray?.length - 1]}`
//         : string?.includes("remix")
//         ? "remix"
//         : string?.includes("unity")
//         ? "unity"
//         : string?.includes("overtime")
//         ? "overtime"
//         : string;

//       // console.log("currentEvent?.event_uid", currentEvent?.event_uid);

//       await setDoc(doc(categoryCollectionRef, newId), {
//         category_name: categoryName,
//         sort_id: allCategories?.length + ind + 1,
//         id: generateUniqueId(),
//         is_scratched: false,
//         event_uid: currentEvent?.event_uid,
//         type: "category",
//         no_id: newId,
//       });
//     });
//   } catch (error) {
//     console.log("error updating categories", error);
//   }
// }

async function updateCustomTags(
  compId,
  entriesArray,
  assignSortId,
  currentEvent,
  categoriesArray
) {
  try {
    // console.log("===========update custom tags started=================");

    const taggedEntries = entriesArray?.filter(
      (x) => Array?.isArray(x?.special_tags) && x?.special_tags?.length > 0
    );
    const taggedEntriesNotCate = taggedEntries?.filter((y) => {
      const categoryNameArr = y?.category_name?.split("~");
      const cateLastItem = categoryNameArr[categoryNameArr?.length - 1]
        ?.replace(/[\[\]]/g, "")
        ?.toLowerCase();
      const tag = y?.special_tags?.[0]?.replace(/ /g, "_")?.toLowerCase();

      if (cateLastItem === tag) {
        return false;
      } else {
        return true;
      }
    });

    const removeDuplicates = (strings) => {
      const uniqueStrings = Array.from(new Set(strings));
      return uniqueStrings;
    };

    const entryCollectionRef = collection(
      db,
      "competitions",
      compId,
      "entries"
    );

    const categoryCollectionRef = collection(
      db,
      "competitions",
      compId,
      "categories"
    );

    const NewCateArr = taggedEntriesNotCate?.map((z) => {
      const tag = z?.special_tags?.[0]?.replace(/ /g, "_")?.toLowerCase();
      const newCategoryName = `${z?.category_name}~[${tag}]`;
      return newCategoryName;
    });

    taggedEntriesNotCate?.forEach(async (itm, ind) => {
      const tag = itm?.special_tags?.[0]?.replace(/ /g, "_")?.toLowerCase();
      const newCategoryName = `${itm?.category_name}~[${tag}]`;
      await updateDoc(doc(entryCollectionRef, itm.id), {
        category_name: newCategoryName,
      });
    });

    removeDuplicates(NewCateArr)?.forEach(async (string, ind) => {
      // console.log(ind + 1, "=======update continues=========");
      const newId = generateno_id();

      // console.log("string", string);

      await setDoc(doc(categoryCollectionRef, newId), {
        category_name: string,
        sort_id: assignSortId(categoriesArray),
        id: generateUniqueId(),
        is_scratched: false,
        event_uid: currentEvent?.event_uid,
        type: "category",
        no_id: newId,
      });
    });
  } catch (error) {
    console.log("error updating custom tags", error);
  }
}

function generateno_id() {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uid = "";
  for (let i = 0; i < 32; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    uid += chars.charAt(randomIndex);
  }
  return uid;
}

//to generate a unique id for each new category
function generateUniqueId() {
  const timestamp = new Date().getTime().toString();
  const randomNum = Math.floor(Math.random() * 900000000000) + 100000000000;
  const uniqueId = `${timestamp}${randomNum}`;
  return uniqueId.substring(0, 15);
}

async function updateSpecialEntries(
  entriesArray,
  categoriesArray,
  compId,
  assignSortId,
  currentEvent
) {
  // await updateAll(
  //   compId,
  //   entriesArray,
  //   categoriesArray,
  //   assignSortId,
  //   currentEvent
  // );
  await updateSpecialEntriesRaw(entriesArray, categoriesArray, compId);
  await updateCustomTags(
    compId,
    entriesArray,
    assignSortId,
    currentEvent,
    categoriesArray
  );
}

export default updateSpecialEntries;
