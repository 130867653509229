/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { getCategory } from "redux/organizerScheduling/organizerSchedulingSlice";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
// import removeDuplicates from "utils/removeDuplicates";

const RegistrationsTable = ({
  studiosWithAccess,
  allEntries,
  allDancers,
  status,
  filteredStudios,
  searchQuery,
}) => {
  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { currentEvent } = useSelector((state) => state.eventManagement);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategory({ compId: user?.uid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedStudio, setSelectedStudio] = useState(null);

  function handleSelect(item) {
    setSelectedStudio((prev) => (prev ? null : item));
  }

  const { data: studiosData } = useQueryAllDocs("studio_owners");
  const myStudio = studiosData?.filter(
    (x) => x?.uid === selectedStudio?.studio_id
  )[0];

  const myStudioAccess = currentEvent?.access?.filter(
    (y) => y?.studio_id === selectedStudio?.studio_id
  )[0];

  const { data: allPayment } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "payment_history"
  );

  const myEntries = allEntries?.filter(
    (a) => a?.studio_selected[0] === selectedStudio?.studio_name
  );

  // const allDancers2 = (() => {
  //   let arr = [];
  //   myEntries?.forEach((x) => arr?.push(...x?.dancers_selected));
  //   return removeDuplicates(arr);
  // })();

  // Calculate the total number of dancers for the current studio
  const totalDancersRaw = allDancers?.filter(
    (x) => x?.studioId === selectedStudio?.studio_id
  );

  // const myDancers1 = allDancers2?.filter((obj1) => {
  //   return totalDancersRaw?.some((obj2) => obj2.dancer_uid === obj1.dancer_uid);
  // });

  // const myDancers = removeDuplicatesByProperty(myDancers1, "dancer_uid");

  function getAllDancers(arr) {
    const allSelected = [];

    if (arr && Array?.isArray(arr)) {
      for (const obj of arr) {
        if (obj.dancers_selected && Array?.isArray(obj.dancers_selected)) {
          allSelected.push(...obj.dancers_selected);
        }
      }
    }

    return allSelected;
  }

  // function removeDuplicatesByProperty(arr, property) {
  //   const uniqueObjects = {};
  //   const result = [];

  //   for (const item of arr) {
  //     const key = item[property];

  //     if (!uniqueObjects[key]) {
  //       uniqueObjects[key] = true;
  //       result.push(item);
  //     }
  //   }

  //   return result;
  // }

  const parseCustomDate = (dateString) => {
    // Replace the comma with an empty string to remove it
    const formattedDate = dateString.replace(",", "");

    // Parse the date using the Date constructor
    const date = new Date(formattedDate);

    // Check if the date is valid
    if (!isNaN(date)) {
      return date.getTime();
    } else {
      // Handle cases where the date couldn't be parsed
      return 0;
    }
  };

  //==================================================================================tp get comp data
  const { status: compStatus, data: compData } = useFirestoreQueryDoc(
    "competitions",
    user?.uid
  );
  const [taxRate, setTaxRate] = useState(0.0);

  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);
      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
    }
  }, [compData, compStatus]);

  const studioEntries = allEntries?.filter(
    (each) =>
      each?.studio_selected[0] === selectedStudio?.studio_name &&
      each?.event_uid === currentEvent?.event_uid
  );

  const studioPayment = allPayment?.filter(
    (itm) =>
      itm?.studio_id === selectedStudio?.studio_id &&
      itm?.event_uid === currentEvent?.event_uid
  );

  // ====================================================================================Calculate the totalAmount owning
  const newTotalAmount = entryTypes
    ?.reduce((total, row) => {
      const rowPricingOption = pricing?.find((obj) =>
        obj.hasOwnProperty(row?.entryTypeName)
      );
      const rowEntries = studioEntries?.filter(
        (itm) => itm?.entryType === row?.entryTypeName
      );

      const flatAmount =
        rowEntries?.length > 0
          ? rowPricingOption?.[row?.entryTypeName] * rowEntries?.length
          : 0.0;

      const rowAmount =
        rowPricingOption?.selectedOption?.toLowerCase() === "flat"
          ? Number(flatAmount)
          : getAllDancers(rowEntries)?.length *
            Number(rowPricingOption?.[row?.entryTypeName]);

      return total + (rowAmount || 0);
    }, 0)
    .toFixed(2);

  function calcTotalAfterTax(amount, percentage) {
    const result = (amount * percentage) / 100;
    return result.toFixed(2);
  }

  const totalAfterTax =
    Number(newTotalAmount) + Number(calcTotalAfterTax(newTotalAmount, taxRate));

  function calculateTotalPayments(arr) {
    if (!arr || !Array?.isArray(arr)) {
      return "Invalid input";
    }

    let totalAmount = 0;

    for (const obj of arr) {
      if (obj.amount) {
        totalAmount += parseFloat(obj.amount);
      }
    }

    return totalAmount.toFixed(2);
  }

  const amountOwing = totalAfterTax - calculateTotalPayments(studioPayment);

  const [pageNumber, setPageNumber] = useState(0);

  const rowPerPage = 8;
  const pagesVisited = pageNumber * rowPerPage;

  const displayRows = (searchQuery ? filteredStudios : studiosWithAccess)
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index, arr) => {
      const submittedEntries = allEntries?.filter(
        (item) =>
          item?.studio_selected[0] === row?.studio_name &&
          item?.event_uid === currentEvent?.event_uid
      );

      // const months = [
      //   "January",
      //   "February",
      //   "March",
      //   "April",
      //   "May",
      //   "June",
      //   "July",
      //   "August",
      //   "September",
      //   "October",
      //   "November",
      //   "December",
      // ];

      // function sortEntriesByDate(arr) {
      //   if (Array.isArray(arr)) {
      //     const arrCopy = arr?.map((item) => ({ ...item }));

      //     arrCopy?.forEach((item) => {
      //       const dateParts = item?.last_update?.split(", ");
      //       if (dateParts.length === 3) {
      //         const [time, monthDay, year] = dateParts;
      //         const [month, day] = monthDay.split(" ");
      //         const monthIndex = months.indexOf(month);
      //         if (monthIndex !== -1) {
      //           item.last_update = new Date(
      //             `${year} ${monthIndex + 1} ${day} ${time}`
      //           );
      //         } else {
      //           console.error("Invalid month:", month);
      //         }
      //       } else {
      //         console.error("Invalid date format:", item.last_update);
      //       }
      //     });

      //     const sortByLastUpdate = (a, b) => {
      //       return b?.last_update - a?.last_update;
      //     };

      //     return arrCopy?.sort(sortByLastUpdate);
      //   }
      // }

      // function formatLastUpdate(date) {
      //   if (date) {
      //     const hours = date?.getHours().toString().padStart(2, "0");
      //     const minutes = date?.getMinutes().toString().padStart(2, "0");
      //     const month = months[date?.getMonth()];
      //     const day = date?.getDate();
      //     const year = date?.getFullYear();

      //     return `${hours}:${minutes}, ${month} ${day}, ${year}`;
      //   }
      // }

      // const sortedEntries = sortEntriesByDate(submittedEntries);

      const sortedEntries = [...submittedEntries]?.sort((a, b) => {
        const dateA = parseCustomDate(a.last_update);
        const dateB = parseCustomDate(b.last_update);

        return dateB - dateA;
      });
      // const sortedEntries2 = [...(submittedEntries || [])]?.sort((a, b) => )

      // console.log("sortedEntriesRaw", sortedEntriesRaw);

      const currentStudio = currentEvent?.access?.filter(
        (x) => x?.studio_id === row?.studio_id
      )[0];

      // const allDancers2 = (() => {
      //   let arr = [];
      //   submittedEntries?.forEach((x) => arr?.push(...x?.dancers_selected));
      //   return removeDuplicatesByProperty(arr, "dancer_uid");
      // })();

      // Calculate the total number of dancers for the current studio
      const totalDancersRaw = allDancers?.filter(
        (x) => x?.studioId === row?.studio_id
      );

      // const totalDancers = allDancers2?.filter((obj1) => {
      //   return totalDancersRaw?.some(
      //     (obj2) => obj2.dancer_uid === obj1.dancer_uid
      //   );
      // });

      const assignedStudioLetter = (() => {
        const matchingStudioIndex =
          row?.studio_name &&
          arr?.findIndex((itm) => itm?.studio_name === row?.studio_name);
        if (matchingStudioIndex >= 0) {
          return String.fromCharCode(65 + matchingStudioIndex); // 65 corresponds to the ASCII code for 'A'
        } else {
          return undefined;
        }
      })();

      const latestEntry = sortedEntries?.[0];

      const lastUpdateF = submittedEntries?.filter(
        (x) => x?.id === latestEntry?.id
      )[0]?.last_update;

      // const lastUpdateF = formatLastUpdate(sortedEntries?.[0]?.last_update);
      const lastUpdate = lastUpdateF ? lastUpdateF : row?.last_update;

      console.log(row?.studio_name, lastUpdateF);

      return (
        <tr
          key={index}
          onClick={() => handleSelect(row)}
          className="border-b border-white/10 whitespace-nowrap cursor-pointer hover:bg-white/20"
        >
          <td>
            <div className="flex items-center">
              <div className="w-6 h-6 border-4 border-white/60 rounded-full"></div>
              {capitalizeFirstLetter(row?.first_name)}{" "}
              {capitalizeFirstLetter(row?.last_name)}
            </div>
          </td>
          <td>{capitalizeFirstLetter(row?.studio_name)}</td>
          <td>{assignedStudioLetter}</td>
          <td>{row?.email}</td>
          <td>{submittedEntries?.length}</td>
          <td>{currentStudio?.estimated_entries || 0}</td>
          {/* <td>{totalDancers?.length}</td> */}
          <td>{totalDancersRaw?.length}</td>
          {/* <td>
            <div className="flex items-center">
              <img
                alt="arrow"
                src="/images/CalendarBlank.png"
                className="w-6 h-6"
              />
              {row?.last_update}
            </div>
          </td> */}
          <td>
            <div className="flex items-center">
              <img
                alt="arrow"
                src="/images/CalendarBlank.png"
                className="w-6 h-6"
              />
              {lastUpdate}
              {/* {lastUpdate ? lastUpdate : row?.last_update} */}
              {/* {sortedEntries?.length > 0
                ? formatLastUpdate(sortedEntries[0]?.last_update)
                : row?.last_update} */}
            </div>
          </td>
          <td>
            <div
              className={`flex items-center ${
                row?.scheduling_status === "Open"
                  ? "text-yellow-200"
                  : row?.scheduling_status === "closed"
                  ? "text-gray-500"
                  : row?.scheduling_status === "completed"
                  ? "text-green-300"
                  : "text-red-400"
              } `}
            >
              <div
                className={`w-1 h-1 rounded-full ${
                  row?.scheduling_status === "Open"
                    ? "bg-yellow-200"
                    : row?.scheduling_status === "closed"
                    ? "bg-gray-500"
                    : row?.scheduling_status === "completed"
                    ? "bg-green-300"
                    : "bg-red-400"
                }`}
              ></div>
              {row?.scheduling_status}
            </div>
          </td>
        </tr>
      );
    });

  const pageCount = Math.ceil(studiosWithAccess?.length / rowPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div className="w-full h-full mt-5 pb-2 overflow-auto">
        {status !== "loading" && studiosWithAccess?.length !== 0 && (
          <table className="min-w-[600px] w-full text-[.85rem] table2">
            <thead className="text-white/40 text-left border-b border-white/30">
              <tr className="whitespace-nowrap">
                <th>User</th>
                <th>Dance Studio</th>
                <th>Studio Letter</th>
                <th>Email Address</th>
                <th>Submitted Entries</th>
                <th>Est. Entries</th>
                <th># Dancers</th>
                <th>Last Update</th>
                <th>Scheduling Status</th>
              </tr>
            </thead>
            <tbody className="text-white/80">{displayRows}</tbody>
          </table>
        )}

        {(studiosWithAccess?.length < 1 || !studiosWithAccess) &&
          status === "success" && (
            <div className="w-full min-h-[200px] flex justify-center items-center text-[.85rem] text-white/60 mt-4 border border-white/10 rounded-xl">
              Nothing yet...
            </div>
          )}
        {status === "loading" && (
          <div className="w-full min-h-[200px] flex justify-center items-center text-[.85rem] text-white/60 mt-4 border border-white/10 rounded-xl">
            <PulseLoader color="#94a4fd" size={15} />
          </div>
        )}
        {studiosWithAccess?.length > 0 && (
          <div className="text-[.75rem] text-white/40 mt-4 lg:">
            Scroll horizontally to view more, if needed
          </div>
        )}
      </div>
      {/* pagination */}
      {studiosWithAccess?.length > 8 && (
        <div className="flex gap-2 mt-4 justify-end">
          <ReactPaginate
            previousLabel={
              <img
                alt="user"
                src="/images/leftArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            nextLabel={
              <img
                alt="user"
                src="/images/rightArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={
              "h-6 flex items-center gap-2 mt-4 justify-end text-[.95rem] pag"
            }
            activeClassName={"rounded-lg bg-white/10"}
          />
        </div>
      )}

      {selectedStudio && (
        <div
          onClick={() => {
            handleSelect();
          }}
          className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
        >
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="rounded-lg p-6 w-full md:w-1/2 max-w-[700px] min-w-[300px] h-[500px] mx-auto"
          >
            <div className="bg-[#282929] rounded-lg p-6 relative scale">
              <div
                onClick={() => {
                  handleSelect();
                }}
                className="w-6 h-6 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-3 right-3"
              >
                <img
                  alt="user"
                  src="/images/icons8-close-50.png"
                  className="w-4 h-4"
                />
              </div>
              <h3 className="text-lg font-semibold mb-4 border-b border-white/30 py-2 text-white/80">
                Studio Information:{" "}
                {capitalizeFirstLetter(selectedStudio?.studio_name)}
              </h3>
              <ul className="space-y-3 text-white/80">
                <li className="w-full flex gap-3 items-center">
                  <span className="text-white/50">Studio owner:</span>
                  <span>
                    {capitalizeFirstLetter(selectedStudio?.first_name)}{" "}
                    {capitalizeFirstLetter(selectedStudio?.last_name)}
                  </span>
                </li>
                <li>
                  <span className="text-white/50">Studio name: </span>{" "}
                  <span>
                    {capitalizeFirstLetter(selectedStudio?.studio_name)}
                  </span>
                </li>
                <li>
                  <span className="text-white/50">Email: </span>{" "}
                  <span>{selectedStudio?.email}</span>
                </li>

                <li>
                  <span className="text-white/50">Address: </span>{" "}
                  <span>{myStudio?.addressLine1}</span>
                </li>
                <li>
                  <span className="text-white/50">Phone number: </span>{" "}
                  <span>{myStudio?.phone}</span>
                </li>
                <li>
                  <span className="text-white/50">Website: </span>{" "}
                  <a
                    href={myStudio?.website}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:text-blue-400 hover:underline"
                  >
                    {myStudio?.website}
                  </a>
                </li>

                <li>
                  <span className="text-white/50">
                    Estimated number of entries:{" "}
                  </span>
                  <span>{myStudioAccess?.estimated_entries}</span>
                </li>
                <li>
                  <span className="text-white/50"># Entries submitted: </span>
                  <span>{myEntries?.length}</span>
                </li>
                <li>
                  <span className="text-white/50"># Dancers: </span>
                  {/* <span>{myDancers?.length}</span> */}
                  <span>{totalDancersRaw?.length}</span>
                </li>
                <li>
                  <span className="text-white/50">Amount paid: </span>
                  <span>${calculateTotalPayments(studioPayment)}</span>
                </li>
                <li>
                  <span className="text-white/50">Amount owing: </span>
                  <span>${amountOwing > 0 ? amountOwing?.toFixed(2) : 0}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationsTable;
