/* eslint-disable react-hooks/exhaustive-deps */
import GrandAwardEntryAwards from "components/EventManagement/components/GrandAwardEntryAwards";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import removeDuplicates from "utils/removeDuplicates";

const AwardOverallsCate = ({
  award,
  myOverallsData,
  allEntries,
  currentComp,
  allScores,
  calculatePosition,
  calculateTotalScore,
  entryTime,
  entryCount,
  category,
  findMatchingAward,
  awards,
  currentEvent,
  myEntriesSorted,
  setShowNames,
  showNames,
  handleAnnounce,
}) => {
  const [overallClass, setOverallClass] = useState([]);
  const [overallEntryType, setOverallEntryType] = useState([]);
  const [overallGenre, setOverallGenre] = useState([]);
  const [overallStudio, setOverallStudio] = useState([]);
  const [overallAgeDivMax, setOverallAgeDivMax] = useState([]);
  const [overallAgeDivMin, setOverallAgeDivMin] = useState([]);

  useEffect(() => {
    if (myOverallsData) {
      setOverallClass(award?.classification?.map((x) => x?.toLowerCase()));
      setOverallEntryType(award?.entryType?.map((x) => x?.toLowerCase()));
      setOverallGenre(award?.genre?.map((x) => x?.toLowerCase()));
      setOverallStudio(award?.studio_selected?.map((x) => x?.toLowerCase()));
      setOverallAgeDivMax([Number(award?.max_age)]);
      setOverallAgeDivMin([Number(award?.min_age)]);
    }
  }, [myOverallsData]);

  function filterEntriesByOverallClass() {
    if (overallClass?.includes("all") || overallClass?.includes("multiple")) {
      return allEntries;
    } else {
      return allEntries?.filter((entry) =>
        overallClass?.includes(entry?.classification)
      );
    }
  }
  const filteredClass = filterEntriesByOverallClass() || [];

  //   console.log("filteredClass", overallClass, filteredClass);

  function filterEntriesByOverallEntryType() {
    if (
      overallEntryType?.includes("all") ||
      overallEntryType?.includes("multiple")
    ) {
      return filteredClass;
    } else {
      return filteredClass?.filter((entry) =>
        overallEntryType?.includes(entry?.entryType)
      );
    }
  }
  const filteredEntryType = filterEntriesByOverallEntryType();

  function filterEntriesByOverallGenre() {
    if (overallGenre?.includes("all") || overallGenre?.includes("multiple")) {
      return filteredEntryType;
    } else {
      return filteredEntryType?.filter((entry) =>
        overallGenre?.includes(entry?.genre)
      );
    }
  }
  const filteredGenre = filterEntriesByOverallGenre();

  function filterEntriesByOverallStudio() {
    if (overallStudio?.includes("all") || overallStudio?.includes("multiple")) {
      return filteredGenre;
    } else {
      return filteredGenre?.filter((entry) =>
        overallStudio?.includes(entry?.studio_selected[0]?.toLowerCase())
      );
    }
  }
  const filteredStudio = filterEntriesByOverallStudio();

  const ageDiv = currentComp?.eligibilityDivisions?.filter(
    (x) =>
      Number(x?.maxAge) <= overallAgeDivMax[0] &&
      Number(x?.minAge) >= overallAgeDivMin[0]
  );

  const myAgeDivisions = ageDiv?.map((x) => x?.divisionName?.toLowerCase());
  // console.log("myAgeDivisions", myAgeDivisions);

  function avgTechnique(entry) {
    const myScore = allScores?.filter(
      (x) => x?.doc_id === entry?.doc_id
    );

    const totalTechnique = myScore?.reduce(
      (acc, item) => acc + Number(item.technique),
      0
    );
    const averageTechnique = totalTechnique / myScore?.length;
    return Number(averageTechnique).toFixed(2);
  }

  function filterEntriesByOverallAge() {
    return filteredStudio?.filter((entry) =>
      myAgeDivisions?.includes(entry?.age_division)
    );
  }

  const scoredEntries = filterEntriesByOverallAge()
    ?.filter((x) => !isNaN(calculateTotalScore(x)))
    ?.sort((a, b) => {
      const scoreA = calculateTotalScore(a);
      const scoreB = calculateTotalScore(b);

      if (scoreA === scoreB) {
        // If the total scores are the same, compare by avgTechnique(item)
        const avgTechniqueA = avgTechnique(a);
        const avgTechniqueB = avgTechnique(b);

        return avgTechniqueA - avgTechniqueB;
      }

      return scoreA - scoreB;
    });

  //to handle true ties
  const entriesToBeAwarded = (() => {
    const scores = scoredEntries?.map((x) => Number(calculateTotalScore(x)));
    const tailored = removeDuplicates(scores)?.slice(
      -Number(award?.awardsNumber)
    );
    let entriesWithScores = [];

    scoredEntries?.forEach((itm, idx) => {
      const score = Number(calculateTotalScore(itm));
      const scoreExist = tailored?.filter((y) => y === score);
      if (scoreExist?.length > 0) {
        entriesWithScores?.push(itm);
      }
    });
    return entriesWithScores;
  })();

  const scores = scoredEntries?.map((x) => Number(calculateTotalScore(x)));
  function containsDuplicates() {
    return new Set(scores)?.size !== scores?.length;
  }

  const trueTiedEntries = entriesToBeAwarded
    ?.map((entr) => {
      const scoresWithCurrent = entriesToBeAwarded?.filter(
        (y) => calculateTotalScore(y) === calculateTotalScore(entr)
      );

      function getTiedPositionArray() {
        let tiedScores = [];
        scoresWithCurrent?.forEach((x) => {
          return tiedScores?.push(Number(avgTechnique(x))?.toFixed(2));
        });

        return tiedScores?.sort((a, b) => b - a);
      }

      const tiedTechnique = getTiedPositionArray()?.filter(
        (z) => z === avgTechnique(entr)
      );

      return tiedTechnique?.length > 1 ? entr : null;
    })
    ?.filter((z) => z !== null);

  // object to store the grouped results
  const groupedByTotalScore = {};

  trueTiedEntries?.forEach((obj) => {
    const totalScore = calculateTotalScore(obj);

    if (!groupedByTotalScore[totalScore]) {
      groupedByTotalScore[totalScore] = [];
    }

    groupedByTotalScore[totalScore].push(obj);
  });

  const resultArray = Object.values(groupedByTotalScore) || [];

  const numberToSlice =
    Number(award?.awardsNumber) + trueTiedEntries?.length - resultArray?.length;

  return (
    <>
      <div className="w-full min-w-[800px] sm:min-w-0 bg-[#282929]/50 flex flex-wrap p-3 border-b border-white/10 relative text-[.75rem] lg:text-[.85rem] justify-center gap-2">
        {capitalizeFirstLetter(award?.award_name)}
        <span className="">
          ({award?.awardsNumber} award{award?.awardsNumber !== "1" && "s"}
          {containsDuplicates() && " + TIES"})
        </span>
      </div>
      {entriesToBeAwarded?.length === 0 && (
        <div className="w-full min-w-[800px] sm:min-w-0 flex flex-wrap p-3 border-b border-white/10 relative text-[.75rem] lg:text-[.85rem] justify-center gap-2 text-white/40">
          Nothing yet...
        </div>
      )}
      {entriesToBeAwarded?.slice(-numberToSlice)?.map((ent, ind) => {
        return (
          <GrandAwardEntryAwards
            key={ind}
            idx={ind}
            item={ent}
            entryTime={entryTime}
            entryCount={entryCount}
            category={category}
            calculatePosition={calculatePosition}
            calculateTotalScore={calculateTotalScore}
            findMatchingAward={findMatchingAward}
            myEntries={entriesToBeAwarded}
            awards={awards}
            currentEvent={currentEvent}
            allScores={allScores}
            myEntriesSorted={myEntriesSorted}
            setShowNames={setShowNames}
            showNames={showNames}
            entriesToBeAwarded={entriesToBeAwarded}
            handleAnnounce={handleAnnounce}
          />
        );
      })}
    </>
  );
};

export default AwardOverallsCate;
