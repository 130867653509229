import React, { useEffect, useState } from "react";
import moment from "moment";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import StageManagerEntry from "./StageManagerEntry";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import sumTimeValues from "utils/sumTimeValues";
import calculateEntryStartTime from "utils/calculateEntryStartTime";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "services/firebase";
import GrandAwardEntry from "./GrandAwardEntry";
import groupObjectsByEntryType from "utils/groupObjectsByEntryType";
import AwardOverallsCate from "components/emcee/components/AwardOverallsCate";

// Custom droppable component to work around React StrictMode
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const StageManagerCate = ({
  index,
  category,
  allEntries,
  compId,
  sortedCategories,
  closeDropdown,
  setShowDropdownRow,
  showDropdownRow,
  currentEvent,
  setSelectedEntry,
  selectedEntry,
  handlePerformanceReady,
  handleCheckIn,
  handleRunAround,
  handleDancerStart,
  handleCheckProps,
  setLoadingEntry,
  loadingEntry,
  confrmDrag,
  notifyLocked,
  setAllowDrag,
  showMoveEntry,
  setShowMoveEntry,
  loading,
  handleOpenEdit,
  currentSessionIndex,
  showAwards,
  calculatePosition,
  calculateTotalScore,
  findMatchingAward,
  awards,
  allScores,
  setShowNames,
  showNames,
  allOveralls,
  currentComp,
}) => {
  const myEntries = allEntries?.filter(
    (entry) => entry?.category_name === category?.category_name
  );
  let myEntriesSorted = myEntries?.sort((a, b) => a?.sort_id - b?.sort_id);

  let myEntriesSortedAward = [...myEntries]?.sort(
    (a, b) =>
      calculatePosition(b, myEntries)[0] - calculatePosition(a, myEntries)[0]
  );

  function removeDuplicates(array) {
    const uniqueArray = [];
    const seenObjects = new Set();

    for (const obj of array) {
      // Serialize the object to JSON to use it as a unique identifier
      const serializedObj = JSON.stringify(obj);

      if (!seenObjects.has(serializedObj)) {
        seenObjects.add(serializedObj);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  }

  //===============================================================================to calc dynamic entry count of all entries in routine
  const categories = sortedCategories?.filter((l) => l?.type === "category");

  const allEntriesWithoutLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  )?.filter((k) => k?.locked_entry === false);

  const allEntriesWithLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  );

  // console.log("allEntriesWithLocked", allEntriesWithLocked);

  function entryCount(itm) {
    const allEntriesBeforeLocked = allEntriesWithLocked
      ?.slice(
        0,
        findIndexByProperty(allEntriesWithLocked, "doc_id", itm?.doc_id)
      )
      ?.filter((m) => m?.locked_entry === false);

    if (itm?.locked_entry) {
      return `${
        allEntriesBeforeLocked?.length === 0
          ? 1
          : allEntriesBeforeLocked?.length
      }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
    } else {
      return (
        findIndexByProperty(allEntriesWithoutLocked, "doc_id", itm?.doc_id) + 1
      );
    }
  }

  //============================================================================================new entry time calculation

  // to get all categories before current category
  const categoriesBeforeCurrent = sortedCategories?.slice(0, index) || [];

  const allBufferBeforeCurrent = categoriesBeforeCurrent?.filter(
    (cate) => cate?.type === "buffer"
  );
  const timeToAdd = allBufferBeforeCurrent?.reduce((sum, obj) => {
    if (obj.type === "buffer") {
      const bufferValue = Number(obj.buffer);
      if (!isNaN(bufferValue)) {
        return sum + bufferValue;
      }
    }
    return sum;
  }, 0);

  function addMinutesToTime(inputTime, minutesToAdd) {
    const timeMoment = moment(inputTime, "h:mm A");
    const endTime = timeMoment.clone().add(minutesToAdd, "minutes");
    const formattedEndTime = endTime.format("h:mm A");
    return formattedEndTime;
  }

  const startTime = currentEvent?.days[0]?.startTime;
  const initialTime = moment(startTime).utc().format("h:mm A");

  //to get the entry count of all entries in routine
  function entryTime(itm) {
    const idx = findIndexByProperty(
      removeDuplicates(allEntriesWithoutLocked),
      "doc_id",
      itm?.doc_id
    );
    if (idx === 0) {
      return addMinutesToTime(initialTime, timeToAdd);
    } else {
      const allEntriesBeforeCurrent = removeDuplicates(
        allEntriesWithoutLocked
      )?.slice(
        0,
        findIndexByProperty(
          removeDuplicates(allEntriesWithoutLocked),
          "doc_id",
          itm?.doc_id
        )
      );

      const allTimeBeforeCurrent = sumTimeValues(allEntriesBeforeCurrent);
      const startTime = calculateEntryStartTime(
        initialTime,
        allTimeBeforeCurrent,
        itm
      );

      return addMinutesToTime(startTime, timeToAdd);
    }
  }

  //===============================================================================================to handle dnd for entries
  const handleDragEnd = async (result) => {
    // setLoadingEntry(true);
    closeDropdown();
    const { source, destination } = result;

    if (!destination || source?.index === destination?.index) {
      return;
    }

    const updatedEntries = Array.from(myEntriesSorted);
    const draggedRow = updatedEntries[source.index];

    // Remove the dragged entry from its original position
    updatedEntries.splice(source.index, 1);

    // Insert the dragged entry at the destination index
    updatedEntries.splice(destination.index, 0, draggedRow);

    // Update the sort_id for all categories after the dragged entry
    const maxSortUd = updatedEntries?.reduce((maxSortId, obj) => {
      return obj.sort_id > maxSortId ? obj.sort_id : maxSortId;
    }, updatedEntries[0]?.sort_id);

    // Update the sort_id for all categories after the dragged entry
    updatedEntries.forEach((category, index) => {
      if (index >= destination.index) {
        category.sort_id = index + 1 + maxSortUd;
      }
    });

    myEntriesSorted = updatedEntries;

    //update docuents on firestore here

    myEntriesSorted?.forEach(async (entry) => {
      // setLoading(true);
      try {
        // Update the Firestore document with the new sort_id using updateDoc function
        const collectionRef = collection(db, "competitions", compId, "entries");
        const docRef = doc(collectionRef, entry?.doc_id);
        await updateDoc(docRef, { sort_id: entry.sort_id });
        console.log({ success: true });
      } catch (error) {
        console.error("Error updating entries sort_id:", error);
      }
    });

    // setLoadingEntry(true);
    closeDropdown();
  };

  const rearrangeCategories = async (id1, id2) => {
    const sourceIndex = sortedCategories?.findIndex(
      (category) => category?.id === id1
    );

    const destinationIndex1 = sortedCategories?.findIndex(
      (category) => category?.id === id2
    );

    const destinationIndex2 =
      sortedCategories?.findIndex((category) => category?.id === id2) + 1;

    const destinationIndex =
      sourceIndex > destinationIndex1 ? destinationIndex2 : destinationIndex1;

    const updatedCategory = Array.from(sortedCategories);
    const draggedRow = updatedCategory[sourceIndex];

    console.log("sortedCategories", sortedCategories);

    // Remove the moved category from its original position
    updatedCategory?.splice(sourceIndex, 1);

    // Insert the moved category at the destination index
    updatedCategory?.splice(destinationIndex, 0, draggedRow);

    // Update the sort_id for all categories after the moved category
    const maxSortUd = updatedCategory?.reduce((maxSortId, obj) => {
      return obj?.sort_id > maxSortId ? obj?.sort_id : maxSortId;
    }, updatedCategory[0]?.sort_id);

    updatedCategory?.forEach((category, index) => {
      if (index >= destinationIndex && category) {
        category.sort_id = index + 1 + maxSortUd;
      }
    });

    sortedCategories = updatedCategory;

    updatedCategory?.forEach(async (category) => {
      try {
        // Find the Firestore document based on a unique field value
        const collectionRef = collection(
          db,
          "competitions",
          compId,
          "categories"
        );

        const whereQuery =
          category?.type === "category"
            ? where("no_id", "==", category.no_id)
            : where("doc_id", "==", category.doc_id);

        const querySnapshot = query(collectionRef, whereQuery);
        const categoriesSnapshot = await getDocs(querySnapshot);

        if (categoriesSnapshot.docs?.length === 1) {
          // Assuming there is only one matching document, update it
          const docRef = categoriesSnapshot.docs[0].ref;

          await updateDoc(docRef, { sort_id: category.sort_id });
        } else if (categoriesSnapshot.docs?.length > 1) {
          console.error("Error: multiple documents match the unique field.");
          console.log(category?.category_name);
        } else {
          console.error("Error: Document not found");
          console.log(category?.category_name);
        }
      } catch (error) {
        console.error("Error updating category sort_id:", error);
      } finally {
        setSelectedEntry(null);
      }
    });

    // Update the categories in firestore
    // updatedCategory?.forEach(async (category) => {
    //   try {
    //     // Update the Firestore document with the new sort_id using updateDoc function
    //     const collectionRef = collection(
    //       db,
    //       "competitions",
    //       compId,
    //       "categories"
    //     );

    //     let docRef;
    //     let docRef2;

    //     if (category?.type === "category") {
    //       // console.log(category?.category_name);
    //       docRef = doc(
    //         collectionRef,
    //         category?.category_name?.replace(/[/\s]/g, "_")
    //       );
    //       docRef2 = doc(
    //         collectionRef,
    //         category?.category_name?.replace(/\//g, "_")
    //       );
    //     } else if (category?.type !== "category") {
    //       docRef = doc(collectionRef, category?.doc_id);
    //     }

    //     const docSnapshot = await getDoc(docRef);

    //     if (docSnapshot.exists()) {
    //       await updateDoc(docRef, { sort_id: category.sort_id });
    //     } else {
    //       await updateDoc(docRef2, { sort_id: category.sort_id });
    //     }

    //     // await updateDoc(docRef, { sort_id: category.sort_id });
    //     // console.log({ success: true });
    //   } catch (error) {
    //     console.error("Error updating category sort_id:", error);
    //   } finally {
    //     setSelectedEntry(null);
    //   }
    // });
  };

  // Map each category to draggable component
  const eachEntry = (!showAwards ? myEntriesSorted : myEntriesSortedAward)?.map(
    (item, idx) => {
      // console.log(item?.entry_name, item?.id);
      return (
        <Draggable
          key={item?.id}
          draggableId={`${item?.id}`}
          index={idx}
          isDragDisabled={
            (currentEvent?.locked || loadingEntry || showAwards) && !confrmDrag
          }
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              // onClick={() => setLoadingEntry(true)}
            >
              {!showAwards ? (
                <StageManagerEntry
                  key={idx}
                  idx={idx}
                  item={item}
                  compId={compId}
                  entryTime={entryTime}
                  entryCount={entryCount}
                  category={category}
                  selectedEntry={selectedEntry}
                  handlePerformanceReady={handlePerformanceReady}
                  handleCheckIn={handleCheckIn}
                  handleRunAround={handleRunAround}
                  handleDancerStart={handleDancerStart}
                  handleCheckProps={handleCheckProps}
                  closeDropdown={closeDropdown}
                  showDropdownRow={showDropdownRow}
                  setShowDropdownRow={setShowDropdownRow}
                  setSelectedEntry={setSelectedEntry}
                  currentEvent={currentEvent}
                  setLoadingEntry={setLoadingEntry}
                  loading={loading}
                  allEntries={allEntries}
                  myEntriesSorted={myEntriesSorted}
                  setShowMoveEntry={setShowMoveEntry}
                  showMoveEntry={showMoveEntry}
                  rearrangeCategories={rearrangeCategories}
                  sortedCategories={sortedCategories}
                  handleOpenEdit={handleOpenEdit}
                  setShowNames={setShowNames}
                  showNames={showNames}
                />
              ) : (
                <GrandAwardEntry
                  key={idx}
                  idx={idx}
                  item={item}
                  entryTime={entryTime}
                  entryCount={entryCount}
                  category={category}
                  calculatePosition={calculatePosition}
                  calculateTotalScore={calculateTotalScore}
                  findMatchingAward={findMatchingAward}
                  myEntries={myEntries}
                  awards={awards}
                  currentEvent={currentEvent}
                  allScores={allScores}
                  myEntriesSorted={myEntriesSorted}
                  setShowNames={setShowNames}
                  showNames={showNames}
                />
              )}
            </div>
          )}
        </Draggable>
      );
    }
  );

  //================================================================to handle awards
  const allOveralls2 = allOveralls.filter(
    (item) => !item.hasOwnProperty("labels")
  );
  const overallData = category?.break_type?.includes("overalls_grand")
    ? allOveralls2[1]?.presets
    : category?.break_type?.includes("overalls_overall")
    ? allOveralls2[2]?.presets
    : allOveralls2[0]?.presets;

  const myOverallsData = overallData?.filter(
    (x) =>
      x?.overall_name === category?.category_name ||
      x?.grand_name === category?.category_name ||
      x?.custom_name === category?.category_name
  )[0];

  const awardOverall = myOverallsData?.selections?.map((award, awardIndex) => {
    return (
      <AwardOverallsCate
        key={awardIndex}
        award={award}
        myOverallsData={myOverallsData}
        allEntries={allEntries}
        currentComp={currentComp}
        allScores={allScores}
        calculatePosition={calculatePosition}
        calculateTotalScore={calculateTotalScore}
        entryTime={entryTime}
        entryCount={entryCount}
        category={category}
        findMatchingAward={findMatchingAward}
        awards={awards}
        currentEvent={currentEvent}
        myEntriesSorted={myEntriesSorted}
        setShowNames={setShowNames}
        showNames={showNames}
      />
    );
  });

  //================================================================================to get top studio
  function groupEntriesByStudio() {
    const groupedObjects = {};

    for (const obj of allEntries) {
      const studioSelected = obj.studio_selected[0];

      // Check if a group for this studioSelected value exists, if not, create one
      if (!groupedObjects[studioSelected]) {
        groupedObjects[studioSelected] = [];
      }

      // Push the object into the corresponding group
      groupedObjects[studioSelected].push(obj);
    }

    return groupedObjects;
  }

  function calcAverage(numbers) {
    if (numbers?.length === 0) {
      return 0;
    }

    const sum = numbers?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    const average = sum / numbers?.length;

    return average;
  }

  function findMaxValueKey(obj) {
    let maxKey = null;
    let maxValue = -Infinity;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (typeof value === "number" && value > maxValue) {
          maxKey = key;
          maxValue = value;
        }
      }
    }

    if (maxKey !== null) {
      const result = {};
      result[maxKey] = maxValue;
      return result;
    }

    return null; // Return null if no numeric values were found in the object
  }

  const groupedEntries = groupEntriesByStudio();

  function getScores() {
    const scoreObject = {};

    for (const key of Object.keys(groupedEntries)) {
      const originalArray = groupedEntries[key];

      const scoreArr = originalArray
        ?.map((obj) => calculateTotalScore(obj))
        ?.filter((x) => x !== "- -")
        ?.sort((a, b) => b - a)
        ?.slice(0, 10);

      scoreObject[key] = calcAverage(scoreArr);
    }

    return scoreObject;
  }

  const topStudioObject = findMaxValueKey(getScores()) || {};
  const topStudio = Object.keys(topStudioObject) || [];
  const topStudioScore = Object.values(topStudioObject)[0];

  //=================================================================================to get top studio (versastyle)

  function sumArrayItems(inputObject) {
    const result = {};
    if (inputObject) {
      for (const key in inputObject) {
        if (inputObject.hasOwnProperty(key)) {
          const originalArray = inputObject[key];

          // Convert array items to numbers and calculate the sum
          const sum = originalArray.reduce(
            (acc, item) => acc + parseFloat(item),
            0
          );

          // Store the sum in the result object
          result[key] = Number(sum?.toFixed(2));
        }
      }
    }
    return result;
  }

  function removePropertiesWithZero(inputObject) {
    const result = {};

    for (const key in inputObject) {
      if (inputObject.hasOwnProperty(key)) {
        const valueArray = inputObject[key];

        const studioEntries = allEntries?.filter(
          (x) => x?.studio_selected[0] === key
        );

        // Check if the value array does not include "0.00" and has at least 20 entries
        if (!valueArray.includes("0.00") && studioEntries?.length >= 20) {
          result[key] = valueArray;
        }
      }
    }

    return result;
  }

  const groupedObjEntries = groupObjectsByEntryType(
    groupedEntries,
    calculateTotalScore,
    calcAverage
  );

  const eligibleStudios = removePropertiesWithZero(groupedObjEntries);

  const resultObj = sumArrayItems(eligibleStudios);

  const topStudioObjectV = findMaxValueKey(resultObj) || {};
  const topStudioV = Object?.keys(topStudioObjectV) || [];
  const topStudioScoreV = Object?.values(topStudioObjectV)[0]?.toFixed(2);

  const awardArray =
    category?.break_type?.split("_")[1] === "custom" &&
    !category?.category_name?.includes("(")
      ? topStudio
      : category?.break_type?.split("_")[1] === "custom" &&
        category?.category_name?.includes("(")
      ? topStudioV
      : [];

  const awardEntry = awardArray?.map((ent, ind) => {
    return (
      <React.Fragment key={ind}>
        <div
          className={`w-full min-w-[800px] sm:min-w-0 flex p-3 border-b border-white/10 relative text-[.75rem] lg:text-[.85rem] justify-center gap-12 ${
            category?.is_scratched ? "text-white/30" : "text-white"
          }`}
        >
          <p>{capitalizeFirstLetter(ent)}</p>
          <p>
            {category?.category_name?.includes("(")
              ? topStudioScoreV
              : topStudioScore}
          </p>
        </div>
      </React.Fragment>
    );
  });

  return (
    <div
      id={category?.category_name}
      onClick={(e) => {
        e?.stopPropagation();
        currentEvent?.locked && notifyLocked();
      }}
      className={`relative ${category?.type === "buffer" && "hidden"} ${
        category?.break_type?.includes("overalls") && showAwards && "mt-5"
      } ${
        myEntriesSorted?.length === 0 &&
        category?.type === "category" &&
        "hidden"
      } border-y border-white/10 lg:text-[1.1rem] text-[.9rem] w-full overflow-x-auto`}
    >
      {category?.is_scratched && (
        <div className="w-full h-full bg-stone-700/40 absolute top-0 left-0"></div>
      )}
      <div
        onMouseEnter={(e) => {
          e.stopPropagation();
          setAllowDrag(true);
        }}
        onMouseLeave={(e) => {
          e.stopPropagation();
          setAllowDrag(false);
        }}
        className={`w-full p-3 bg-[#282929] text-center font-bold relative ${
          category?.type === "buffer"
            ? "bg-[#4f2d54]"
            : category?.break_type === "session"
            ? "bg-[#42125f]"
            : category?.break_type?.includes("overall") ||
              category?.break_type?.includes("award")
            ? "bg-black/20"
            : "bg-[#282929]"
        } ${
          category?.break_type?.includes("overall") ||
          category?.break_type?.includes("award")
            ? "uppercase"
            : ""
        }`}
      >
        <h2
          className={`${
            category?.is_scratched ? "text-white/50" : "text-white"
          } flex gap-1 items-center justify-center text-[.9rem]`}
        >
          {category?.is_scratched && (
            <img alt="" src="/images/scratchCat.png" className="w-5 h-3" />
          )}
          {`${capitalizeFirstLetter(
            category?.category_name?.replace(/_/g, " ")
          )}`}
          {category?.split && !category?.category_name?.includes("(") && (
            <span className="">(A)</span>
          )}
          {category?.type === "category" && (
            <span className="ml-[0.25rem]">({myEntriesSorted?.length})</span>
          )}{" "}
          {/*========================================================================= to dynamically render overalls or grand award tags */}
          {category?.break_type?.includes("overalls_") && (
            <span className="">
              {category?.break_type?.split("_")[1] === "overall"
                ? "OVERALLS"
                : category?.break_type?.split("_")[1] === "grand"
                ? "GRAND OVERALLS"
                : "CUSTOM REPORT"}
            </span>
          )}
          {/* to dynamically render sessions count */}
          {category?.break_type === "session" && (
            <span className="">
              {" "}
              {currentSessionIndex + 1}
              {/* {sessionsBeforeCurrent?.length === 0
                ? 1
                : sessionsBeforeCurrent?.length + 1} */}
            </span>
          )}
        </h2>
      </div>
      {/* entries container */}
      <div className="w-full">
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={() => {
            console.log("loadingEntry", loadingEntry); // Add this line
            closeDropdown();
          }}
        >
          <StrictModeDroppable droppableId="entry-list">
            {(provided) => (
              <div
                className="w-full relative"
                ref={provided.innerRef}
                {...provided.droppableProps}
                onMouseDown={(e) => e.stopPropagation()}
              >
                {category?.break_type?.includes("overalls") &&
                category?.break_type?.split("_")[1] === "custom" &&
                showAwards
                  ? awardEntry
                  : category?.break_type?.includes("overalls") &&
                    category?.break_type?.split("_")[1] !== "custom" &&
                    showAwards
                  ? awardOverall
                  : eachEntry}
                {provided.placeholder}

                {category?.category_name?.includes("(") &&
                  category?.break_type?.split("_")[1] === "custom" &&
                  topStudioV?.length === 0 &&
                  showAwards && (
                    <div className="w-full min-w-[800px] sm:min-w-0 flex p-3 border-b border-white/10 relative text-[.75rem] lg:text-[.85rem] justify-center gap-12">
                      No eligible studio
                    </div>
                  )}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default StageManagerCate;
