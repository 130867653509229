import moment from "moment";
// import "moment-duration-format";

export default function calculateEntryStartTime(
  inputTime,
  durationString,
  extraTime
) {
  const timeMoment = moment(inputTime, "h:mm A");
  const duration = moment.duration(durationString);
  const endTime = timeMoment.add(duration);

  // Parse the extraTime parameter
  //   const extraDuration = moment.duration(extraTime);
  //   const finalEndTime = endTime.add(extraDuration);

  const formattedEndTime = endTime.format("h:mm A");
  return formattedEndTime;
}

// export default function calculateEntryStartTime(
//   inputTime,
//   durationString,
//   item
// ) {
//   const timeMoment = moment(inputTime, "h:mm A");
//   const duration = moment.duration(durationString);

//   const entryDuration = moment.duration(item?.performance_time);
//   const endTime = item?.locked_entry
//     ? timeMoment.add(duration).subtract(entryDuration)
//     : timeMoment.add(duration);

//   const formattedEndTime = endTime.format("h:mm A");
//   return formattedEndTime;
// }
