/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import { getEvents } from "../../redux/eventManagement/eventManagement";
import ScrollToTop from "./ScrollToTop";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { PulseLoader } from "react-spinners";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import StudioSelect from "./components/StudioSelect";
import {
  enableAccess,
  disableAccess,
} from "../../redux/eventManagement/eventManagement";
import AddEventContainer from "./components/AddEventContainer";
import { sendAccessGrantedEmailService } from "services/organizerService";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import moment from "moment";

const UserManagement = () => {
  const { addEvent, openNotifsBoard } = useSelector(
    (state) => state.eventManagement
  );

  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //=====================================================================================to get realtime events from firestore
  const { status, data } = useQueryAllDocs("competitions");
  const organizerComp = data?.filter((item) => item?.compId === user?.uid)[0];
  const realtimeEvents = organizerComp?.events;

  const { data: organizerData } = useFirestoreQueryDoc("organizers", user?.uid);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showStudioPopup, setShowStudioPopup] = useState(false);

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setShowStudioPopup(true);
  };

  const handleClosePopup = () => {
    setShowStudioPopup(false);
  };

  const handleEnableAccess = async (studio) => {
    const userEmail = studio?.email;
    const compName = capitalizeFirstLetter(organizerComp?.competitionName);
    const event_name = selectedEvent?.event_name;

    const startTime = moment(selectedEvent?.days[0].startTime)
      .local()
      .format("MMM D");
    const endTime = moment(
      selectedEvent?.days[selectedEvent?.days?.length - 1].endTime
    )
      .local()
      .format("MMM D");

    const date =
      selectedEvent?.days?.length === 1
        ? `${startTime}, ${selectedEvent?.year}`
        : `${startTime} - ${endTime} ${selectedEvent?.year}`;

    dispatch(
      enableAccess({
        compId: selectedEvent?.uid,
        event_uid: selectedEvent?.event_uid,
        access: studio,
      })
    ).then(() =>
      sendAccessGrantedEmailService({ userEmail, compName, event_name, date })
    );
  };

  const handleDisableAccess = (studio) => {
    dispatch(
      disableAccess({
        compId: selectedEvent?.uid,
        event_uid: selectedEvent?.event_uid,
        access: studio,
      })
    );
  };

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <h2 className="mb-6 text-white/80 text-[1.25rem] font-medium">
              User Management Event Settings
            </h2>
            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[200px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Studio Access
              </h1>

              <div className="w-full flex flex-col gap-3">
                <div className="w-full flex justify-between items-center px-2">
                  <p>Events</p>
                  {/* <p>Access</p> */}
                </div>

                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {realtimeEvents?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="w-full flex justify-between items-center bg-white/10 p-3 rounded-lg text-[.85rem] cursor-pointer hover:bg-white/20"
                      onClick={() => handleEventClick(item)}
                    >
                      <p>{item?.event_name}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {showStudioPopup && selectedEvent && (
        <StudioSelect
          event={selectedEvent}
          organizerData={organizerData}
          onClose={handleClosePopup}
          handleEnableAccess={handleEnableAccess}
          handleDisableAccess={handleDisableAccess}
          realtimeEvents={realtimeEvents}
        />
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default UserManagement;
