/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import AddEventForm from "./components/AddEventForm";
import ScrollToTop from "./ScrollToTop";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleAddGrandAwards } from "redux/organizerScheduling/organizerSchedulingSlice";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import {
  clearSuccess,
  submitGrand,
} from "redux/eventManagement/eventManagement";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { useNavigate } from "react-router-dom";
import GrandAwardsTable from "./components/GrandAwardsTable";
import AddGrandAwardForm from "./components/AddGrandAwardsForm";
import EditGrandAwardForm from "./components/EditGrandAwardForm";
import ReportSortContainer from "./components/ReportSortedContainer";

const GrandAwardsReport = () => {
  const {
    addEvent,
    openNotifsBoard,
    currentEvent,
    submitGrandPending,
    submitGrandSuccess,
  } = useSelector((state) => state.eventManagement);

  const { addGrandAward, editGrandAward } = useSelector(
    (state) => state.organizerScheduling
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { data: compData } = useFirestoreQueryDoc("competitions", user?.uid);
  const [competitionLevel, setCompetitionLevel] = useState([]);
  const [eligibilityDivisions, seteligibilityDivisions] = useState([]);
  const [scheduling, setscheduling] = useState([]);
  const [entryTypes, setentryTypes] = useState([]);
  const [danceGenres, setdanceGenres] = useState([]);

  useEffect(() => {
    if (compData) {
      const {
        competitionLevel,
        eligibilityDivisions,
        scheduling,
        entryTypes,
        danceGenres,
      } = compData;
      setCompetitionLevel(competitionLevel);
      seteligibilityDivisions(eligibilityDivisions);
      setscheduling(scheduling);
      setentryTypes(entryTypes);
      setdanceGenres(danceGenres);
    }
  }, [compData]);

  const [selections, setSelections] = useState([]);
  const [grandName, setgrandName] = useState("");
  const [validationErr, setValidationErr] = useState("");

  const totalAwards = selections?.reduce(
    (accumulator, current) => accumulator + Number(current.awardsNumber),
    0
  );

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  //data to submit
  const presetData = {
    grand_name: grandName,
    status: "unscheduled",
    no_of_awards: totalAwards,
    selections,
    compId: user?.uid,
    preset_uid: generateUID(),
  };

  // console.log("presetData", presetData);

  async function handleSubmit() {
    try {
      if (grandName && selections?.length > 0) {
        dispatch(submitGrand(presetData));
      } else if (!grandName) {
        setValidationErr("Grand award name is required");
      } else if (selections?.length === 0) {
        setValidationErr("Select at least one award");
      }
    } catch (error) {
      console.log("error submit grand award", error);
    }
  }

  useEffect(() => {
    if (submitGrandSuccess?.success) {
      setSelections([]);
      setgrandName("");
      setTimeout(() => {
        dispatch(clearSuccess());
        navigate(
          `/organizer/event/${currentEvent?.event_name.replace(
            / /g,
            "-"
          )}/report-session-awards`
        );
      }, 2000);
    }
  }, [submitGrandSuccess]);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [sortDown, setSortDown] = useState("class");

  const handleSortClick = () => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && !addGrandAward && !editGrandAward && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setHoveredRowIndex(null);
          }}
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            <div className="w-full md:max-w-full md:min-w-[450px] mb-auto text-white/80">
              <div
                className={`w-full p-4 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                  validationErr === "Grand award name is required" &&
                  "border border-red-500/80"
                }`}
              >
                <label
                  htmlFor="award_name"
                  className="text-[.75rem] text-white/40"
                >
                  Grand Award Name
                </label>
                <input
                  type="text"
                  id="award_name"
                  onChange={(e) => {
                    setgrandName(e.target.value);
                    setValidationErr("");
                  }}
                  className="bg-[#0c0c0d] outline-none text-white placeholder:text-white/50 mt-1"
                  placeholder="Grand Name"
                />
              </div>
              {validationErr && (
                <p className="text-red-500/80 mt-2">{validationErr}</p>
              )}

              {selections?.length > 0 && (
                <>
                  <h2 className="text-white/80 mt-5">Selections:</h2>
                  <div className="w-full mt-2 flex gap-3 flex-wrap">
                    {selections?.map((item, index) => {
                      const handleRemoveSelection = () => {
                        setSelections((prevSelections) =>
                          prevSelections.filter((_, i) => i !== index)
                        );
                      };
                      return (
                        <div
                          key={index}
                          className="w-fit h-fit text-[.9rem] text-black px-3 py-1 rounded-md bg-[#94a4fd] flex gap-2 items-center"
                        >
                          <p>{item?.award_name}</p>

                          {/* remove button */}
                          <div
                            className="p-1 rounded-full bg-red-500 cursor-pointer"
                            onClick={handleRemoveSelection}
                          >
                            <img
                              alt=""
                              src="/images/icons8-close-48.png"
                              className="w-3 h-3"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}

              <div className="w-full min-h-[200px] mt-[70px]">
                <div className="w-full p-2 bg-[#282929] rounded-lg flex justify-between">
                  <div className="flex gap-2 items-center">
                    <img
                      alt="arrow"
                      src="/images/plus.png"
                      className="w-7 h-7 cursor-pointer"
                      onClick={() => dispatch(toggleAddGrandAwards())}
                    />

                    <div className="relative">
                      <img
                        alt="arrow"
                        src="/images/updown.png"
                        className="w-7 h-7 cursor-pointer"
                        onClick={handleSortClick}
                      />
                      {showDropdown && (
                        <ReportSortContainer
                          setSortDown={setSortDown}
                          sortDown={sortDown}
                        />
                      )}
                    </div>
                  </div>
                  {/* search */}
                  <div
                    className={`h-[30px] flex mr-2 ml-auto border border-white/20 rounded-lg`}
                  >
                    <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
                      <img
                        alt="user"
                        src="/images/Search.png"
                        className="w-5 h-5 cursor-pointer"
                      />
                    </div>
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
                    />
                  </div>
                </div>
                {/* awards table */}
                <GrandAwardsTable
                  selections={selections}
                  setSelections={setSelections}
                  setValidationErr={setValidationErr}
                  searchQuery={searchQuery}
                  hoveredRowIndex={hoveredRowIndex}
                  setHoveredRowIndex={setHoveredRowIndex}
                  sortDown={sortDown}
                  currentEvent={currentEvent}
                />
                <button
                  onClick={() => dispatch(toggleAddGrandAwards())}
                  className="w-fit text-[.85rem] px-5 py-1 mr-4 mt-5 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
                >
                  Add Award
                </button>
                <div className="w-full flex flex-col items-end gap-3 mt-4">
                  {validationErr && (
                    <p className="text-red-500">{validationErr}</p>
                  )}
                  <button
                    onClick={handleSubmit}
                    disabled={submitGrandPending}
                    className="w-fit text-[.85rem] px-5 py-2 rounded-md bg-blue-600 hover:bg-blue-600/60 text-white transition-all duration-300 flex justify-between gap-2 items-center whitespace-nowrap"
                  >
                    {submitGrandPending
                      ? "Creating..."
                      : "Create Overall Pre Set"}
                    {submitGrandPending && <ButtonLoadingSpinner />}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {addGrandAward && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>
                {" "}
                {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>
            </div>

            {/* Add event form */}
            <div className="">
              <AddGrandAwardForm
                competitionLevel={competitionLevel}
                eligibilityDivisions={eligibilityDivisions}
                scheduling={scheduling}
                entryTypes={entryTypes}
                danceGenres={danceGenres}
              />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {editGrandAward && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>
                {" "}
                {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                {currentEvent?.year?.split(" to ")[0]}
              </h2>
            </div>

            {/* Add event form */}
            <div className="">
              <EditGrandAwardForm
                competitionLevel={competitionLevel}
                eligibilityDivisions={eligibilityDivisions}
                scheduling={scheduling}
                entryTypes={entryTypes}
                danceGenres={danceGenres}
              />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
              <h2>Add Event</h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>

            {/* Add event form */}
            <div className="">
              <AddEventForm />
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {submitGrandSuccess?.success && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white text-center">
              Grand Preset Created Successfully!{" "}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default GrandAwardsReport;
