/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";

import {
  addDoc,
  collection,
  updateDoc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  toggleAddDancer,
  addDancers,
  clearSuccess,
} from "redux/danceStudio/danceStudioSlice";
import { db } from "services/firebase";
import "../../../utils/checkbox.css";
import * as XLSX from "xlsx";

const AddDancerForm = () => {
  const { addDancersSuccess, addDancersPending } = useSelector(
    (state) => state.danceStudio
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { status, data } = useFirestoreQueryDoc("studio_owners", user?.uid);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { event_name } = useParams();

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId, competitionLevel } = danceStudioCompData.value;
  // console.log(competitionLevel);

  const timestamp = new Date().getTime();

  function formatTimeStamp(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const month = date.toLocaleString("default", { month: "long" });
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedTime = `${hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }, ${month} ${day}, ${year}`;
    return formattedTime;
  }

  // const tags = scheduling?.customTags;

  function generateUID() {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let uid = "";
    for (let i = 0; i < 8; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      uid += chars.charAt(randomIndex);
    }
    return uid;
  }

  const [formData, setFormData] = useState({
    studioId: user?.uid,
    studio_name: data?.studioName,
    first_name: "",
    last_name: "",
    classification: "",
    date_of_birth: "",
    gender: "",
    notes: "",
    last_update: formatTimeStamp(timestamp),
    entries: 0,
    scheduling_status: "Not scheduled",
    // userImg: 'https://i.pravatar.cc/150?img=1',
    dancer_uid: generateUID(),
  });

  useEffect(() => {
    if (status === "success") {
      setFormData((prev) => {
        return {
          ...prev,
          studio_name: data?.studioName,
        };
      });
    }
  }, [status]);

  function convertDateFormat(dateString) {
    const regex = /^(\d{2})-(\d{2})-(\d{4})$/;
    const regexAlt = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
    const regexYMD = /^(\d{4})-(\d{2})-(\d{2})$/;

    if (regex.test(dateString)) {
      const parts = dateString.split("-");
      const year = parts[2];
      let month = parts[1];
      let day = parts[0];

      // Swap day and month if month > day
      if (parseInt(month) > 12) {
        [month, day] = [day, month];
      }

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    } else if (regexAlt.test(dateString)) {
      const parts = dateString.split("/");
      return `${parts[2]}-${parts[1].padStart(2, "0")}-${parts[0].padStart(
        2,
        "0"
      )}`;
    } else if (regexYMD.test(dateString)) {
      const parts = dateString.split("-");
      const year = parts[0];
      let month = parts[1];
      let day = parts[2];

      // Swap day and month if month > day
      if (parseInt(month) > parseInt(day)) {
        [month, day] = [day, month];
      }

      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }

    return dateString;
  }

  //to handle form submit
  const [dancerExist, setDancerExist] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [dobErr, setDobErr] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    setDancerExist(false);

    const today = new Date().setHours(0, 0, 0, 0);
    const dob = new Date(convertDateFormat(formData.date_of_birth)).setHours(
      0,
      0,
      0,
      0
    );
    if (
      formData?.first_name &&
      formData?.last_name &&
      formData?.classification &&
      formData?.date_of_birth &&
      dob < today &&
      formData?.gender &&
      dob <= today
    ) {
      dispatch(
        addDancers({
          formData,
          compId: compId,
          uid: user?.uid,
        })
      );
    } else if (dob >= today) {
      setDobErr(true);
    } else {
      setValidationError("Please fill all required fields");
    }
  }

  function handleFormChange(event) {
    const { id, value } = event.target;
    setValidationError(false);
    setDancerExist(false);
    setDobErr(false);
    if ((id === "first_name" || id === "last_name") && /\d/.test(value)) {
      // Check if the value contains a number
      return; // Exit the function without updating the state
    }

    if (id === "date_of_birth") {
      // Format the date to "yyyy-mm-dd" before setting it in the state
      const [year, month, day] = value?.split("-");
      const formattedDate = `${year}-${month?.padStart(2, "0")}-${day?.padStart(
        2,
        "0"
      )}`;

      setFormData((prev) => {
        return {
          ...prev,
          [id]: formattedDate,
        };
      });
    } else {
      setFormData((prev) => {
        return {
          ...prev,
          [id]: value,
        };
      });
    }
  }

  const { event_name } = useParams();

  //to close dancers added modal
  const [success, setSuccess] = useState(false);
  useEffect(() => {
    if (addDancersSuccess?.success) {
      setSuccess(true);
      const timeoutId = setTimeout(() => {
        setSuccess(false);
        dispatch(toggleAddDancer());
        navigate(`/studio/event/${event_name?.replace(/ /g, "-")}/dancers`);
        dispatch(clearSuccess());
      }, 1500);
      // window.location.reload();
      return () => clearTimeout(timeoutId);
    } else if (!addDancersSuccess) {
      setDancerExist(false);
    } else {
      setDancerExist(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addDancersSuccess, dispatch]);

  //==============================================================to handle csv upload
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const [skippedRows, setSkippedRows] = useState([]);
  const [showSkippedRowsMessage, setShowSkippedRowsMessage] = useState(false);
  const [xlxsContent, setxlxsContent] = useState("");
  // console.log("xlxsContent", xlxsContent);

  function convertFileToCSV(file) {
    const reader = new FileReader();

    reader.onload = function (e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming there is only one sheet in the Excel file
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the worksheet to CSV
      const csv = XLSX.utils.sheet_to_csv(worksheet);

      // Set the CSV data to the state
      setxlxsContent(csv);
    };

    reader.readAsArrayBuffer(file);
  }

  const allowedTypes = [
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  useEffect(() => {
    if (selectedFile2) {
      convertFileToCSV(selectedFile2);

      if (allowedTypes.includes(selectedFile2.type) && xlxsContent) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const contents =
            selectedFile2?.type === "text/csv" ? e.target.result : xlxsContent;
          // const contents = e.target.result;

          const lines = contents.split("\n");

          const expectedHeadings = [
            "first_name",
            "last_name",
            "classification",
            "dob",
            "gender",
          ];
          const headings = lines[0]
            .toLowerCase()
            .split(",")
            .map((heading) => heading.trim());

          if (
            headings?.length >= 5 &&
            expectedHeadings?.every((heading) => headings.includes(heading))
          ) {
            setSelectedFile(selectedFile2);
            setFileError(null);
          } else {
            setSelectedFile(null);
            setFileError(
              "Invalid file data, check example file for file data structure."
            );
          }
        };
        reader.readAsText(selectedFile2);
      } else {
        setSelectedFile(null);
        setFileError("Please upload a CSV or Excel file.");
      }
    }
  }, [selectedFile2, xlxsContent]);

  async function handleFileSelect(event) {
    const file = event.target.files[0];
    setSelectedFile2(file);
  }

  function isValidDateFormat(rawDate) {
    // Regular expressions for the specified date formats
    const regex1 = /^\d{4}[-/]\d{2}[-/]\d{2}$/; // yyyy-mm-dd
    const regex2 = /^\d{4}[//]\d{2}[//]\d{2}$/; // yyyy/mm/dd

    if (regex1.test(rawDate) || regex2.test(rawDate)) {
      return true;
    } else {
      return false;
    }
  }

  const genders = [
    "male",
    "female",
    "transgender",
    "non-binary",
    "prefer not to respond",
  ];
  const [rows, setRows] = useState([]);

  async function processCSVData(selectedFile) {
    const reader = new FileReader();

    reader.onload = async (e) => {
      // const contents = e.target.result;
      const contents =
        selectedFile2?.type === "text/csv" ? e.target.result : xlxsContent;

      const rows = contents.split("\n")?.filter((a) => a !== "");
      setRows(rows);

      setUploading(true);

      const csvDataArray = [];

      try {
        // Start loop from index 1 to skip the first row
        for (let i = 1; i < rows.length; i++) {
          const row = rows[i];
          const rowData = row.split(",");

          const classExists = competitionLevel?.filter(
            (it) =>
              it?.levelName?.toLowerCase() === rowData[2]?.trim()?.toLowerCase()
          );

          const genderExists = genders?.filter(
            (it) => it?.toLowerCase() === rowData[4]?.trim()?.toLowerCase()
          );

          const rawDate = rowData?.[3]?.trim();
          const formattedDate = convertDateFormat(rawDate); // Convert date format

          console.log("rawDate", rawDate);

          // Check if rowData contains empty values for essential columns
          if (
            rowData.length >= 5 &&
            rowData[0].trim() &&
            rowData[1].trim() &&
            rowData[2].trim() &&
            rowData[3].trim() &&
            rowData[4].trim() &&
            classExists?.length > 0 &&
            genderExists?.length > 0 &&
            isValidDateFormat(rawDate)
          ) {
            const csvData = {
              studioId: user?.uid,
              studio_name: data?.studioName,
              first_name: rowData[0].trim().toLowerCase(),
              last_name: rowData[1].trim().toLowerCase(),
              classification: rowData[2].trim().toLowerCase(),
              date_of_birth: formattedDate,
              gender: rowData[4].trim().toLowerCase(),
              notes: rowData.length >= 6 ? rowData[5].trim() : "", // Additional note
              last_update: formatTimeStamp(timestamp),
              entries: 0,
              scheduling_status: "Not scheduled",
              dancer_uid: generateUID(),
            };

            csvDataArray.push(csvData);
          } else {
            console.log("Skipping incomplete row:", row);
            setSkippedRows((prevSkippedRows) => [...prevSkippedRows, i]);
            // Set the state to show the message
            setShowSkippedRowsMessage(true);
          }
        }

        const dancersCollectionRef = collection(
          db,
          "competitions",
          compId,
          "dancers"
        );

        for (const csvData of csvDataArray) {
          // Check if the dancer already exists based on first_name, last_name, and date_of_birth
          const existingDancerQuery = query(
            dancersCollectionRef,
            where("first_name", "==", csvData.first_name),
            where("last_name", "==", csvData.last_name),
            where("date_of_birth", "==", csvData.date_of_birth)
          );

          const existingDancerSnapshot = await getDocs(existingDancerQuery);

          if (existingDancerSnapshot.empty) {
            // No matching dancer found, add a new dancer
            await addDoc(dancersCollectionRef, csvData);
          } else {
            // Matching dancer found, update their information
            existingDancerSnapshot.forEach((doc) => {
              updateDoc(doc.ref, csvData);
            });
          }
        }

        setUploadComplete(true);
        setUploading(false);
      } catch (error) {
        console.error("Error uploading CSV data:", error);
      }
    };

    reader.readAsText(selectedFile);
  }

  function closeComplete() {
    dispatch(toggleAddDancer());
    setShowSkippedRowsMessage(false);
    setUploadComplete(false);
  }

  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    setShowPopup((showPopup) => !showPopup);
  };

  return (
    <>
      <form className="">
        <div className="w-full sm:w-[500px] lg:w-[60%] lg:min-w-[550px] bg-[#282929] rounded-xl p-4 sm:p-7 mx-auto flex flex-col gap-4">
          <h2 className="font-bold text-white/80">Add Dancer</h2>
          <div className="flex md:flex-row flex-col gap-4">
            <div
              className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.first_name &&
                "border border-red-500/60"
              }`}
            >
              <div>
                <label
                  htmlFor="first_name"
                  className="text-[.75rem] text-white/40"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="first_name"
                  onChange={handleFormChange}
                  className="w-full bg-[#0c0c0d] outline-none text-white"
                  placeholder="E.g. John"
                />
              </div>
            </div>
            <div
              className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.last_name &&
                "border border-red-500/60"
              }`}
            >
              <div>
                <label
                  htmlFor="last_name"
                  className="text-[.75rem] text-white/40"
                >
                  Last name
                </label>
                <input
                  type="text"
                  id="last_name"
                  onChange={handleFormChange}
                  className="w-full bg-[#0c0c0d] outline-none text-white"
                  placeholder="E.g. Doe"
                />
              </div>
            </div>
          </div>

          <div className="flex md:flex-row flex-col gap-4">
            <div
              className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.classification &&
                "border border-red-500/60"
              }`}
            >
              <label
                htmlFor="classification"
                className="text-[.75rem] text-white/40"
              >
                Classification
              </label>
              <select
                id="classification"
                defaultValue={"DEFAULT"}
                onChange={handleFormChange}
                className="w-full bg-[#0c0c0d] outline-none text-white/70 mt-2 cursor-pointer"
              >
                <option value="DEFAULT" disabled hidden>
                  Select
                </option>
                {competitionLevel?.map((item, index) => {
                  return (
                    <option key={index} value={item?.levelName}>
                      {item?.levelName}
                    </option>
                  );
                })}
              </select>
            </div>

            <div
              className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
                validationError &&
                !formData?.date_of_birth &&
                "border border-red-500/60"
              }`}
            >
              <label
                htmlFor="date_of_birth"
                className="text-[.75rem] text-white/40"
              >
                Date of birth
              </label>
              <div className="flex gap-2 items-center mt-1">
                <input
                  type="date"
                  id="date_of_birth"
                  onChange={handleFormChange}
                  className="w-full bg-[#0c0c0d] outline-none text-white/70 mr-auto"
                />
              </div>
            </div>
          </div>

          <div
            className={`w-full md:w-1/2 p-3 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 ${
              validationError && !formData?.gender && "border border-red-500/60"
            }`}
          >
            <label htmlFor="gender" className="text-[.75rem] text-white/40">
              Gender
            </label>
            <select
              id="gender"
              defaultValue={"DEFAULT"}
              onChange={handleFormChange}
              className="w-full bg-[#0c0c0d] mt-2 outline-none text-white/70 cursor-pointer"
            >
              <option value="DEFAULT" disabled hidden>
                Select
              </option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="non-binary">Non-Binary/Non-Conforming</option>
              <option value="prefer not to respond">
                Prefer not to respond
              </option>
              <option value="other">Other – Please indicate in notes</option>
            </select>
          </div>

          <div className="w-full p-3 rounded-xl bg-[#0c0c0d]">
            <label htmlFor="notes" className="text-[.75rem] text-white/40">
              Additional Notes
            </label>
            <textarea
              id="notes"
              onChange={handleFormChange}
              className="w-full bg-[#0c0c0d] outline-none text-white"
            />
          </div>

          {dancerExist && (
            <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-xl">
              An error occured
            </div>
          )}
          {dobErr && (
            <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-xl">
              Invalid date of birth
            </div>
          )}
          {validationError && (
            <div className="w-full text-red-400 text-[.85rem] border border-red-500 bg-red-400/10 mt-5 px-3 py-2 rounded-xl">
              {validationError}
            </div>
          )}
          <div className="w-full mx-auto mt-7 text-end">
            <button
              onClick={(e) => {
                e.preventDefault();
                setValidationError(false);
                setDancerExist(false);
                setDobErr(false);
                dispatch(toggleAddDancer());
                setFileError(false);
              }}
              className="text-[.85rem] px-5 py-1 mr-4 rounded-lg bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
            >
              Discard
            </button>
            <button
              onClick={(e) => {
                handleSubmit(e);
              }}
              disabled={addDancersPending}
              className="text-[.9rem] px-5 py-1 rounded-lg bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300"
            >
              {addDancersPending ? "Adding..." : "Save Changes"}
            </button>
          </div>
        </div>
      </form>
      {/* csv file start */}
      <div className="relative">
        <div className="w-full sm:w-[500px] lg:w-[60%] lg:min-w-[550px] bg-[#282929] rounded-xl p-4 mt-7 sm:p-7 mx-auto flex flex-col gap-4">
          <h2 className="font-bold text-white/80">Upload CSV or Excel</h2>
          <p className="text-[.95rem] text-white/70">
            Please prepare a file following the guidelines provided{" "}
            <button
              onClick={togglePopup}
              className="font-bold text-[#94a4fd] underline cursor-pointer"
            >
              here
            </button>
          </p>
          <form>
            <input
              type="file"
              id="csv"
              onChange={handleFileSelect}
              className="w-[73%] p-2 bg-[#0c0c0d] rounded-lg outline-none cursor-pointer text-white mr-3"
            />
            {fileError && (
              <p className="text-red-400 text-[.85rem]">{fileError}</p>
            )}
            <button
              disabled={!selectedFile || fileError || uploading}
              onClick={(e) => {
                e.preventDefault();
                selectedFile && processCSVData(selectedFile);
              }}
              className="w-fit text-[1.1rem] px-5 py-1 mt-4 rounded-lg bg-[#94a4fd] hover:bg-[#94a4fd]/80 text-[#1c1c1c] transition-all duration-300 cursor-pointer"
            >
              {uploading ? "Uploading..." : "Upload"}
            </button>
          </form>
        </div>

        {showPopup && (
          <div
            onClick={togglePopup}
            className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-70 flex justify-center items-center z-50 px-3"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="bg-[#282929] rounded-lg p-4 w-full md:w-[70%] max-h-[70vh] overflow-y-auto scale text-[.85rem]"
            >
              <h2 className="font-bold text-white/70">Upload Guidelines</h2>
              <p className="text-white/80 mt-3">
                You can download an example CSV file{" "}
                <a
                  href="/file/example.csv"
                  download
                  className="font-bold text-[#94a4fd] underline cursor-pointer"
                >
                  here
                </a>{" "}
                for reference.
              </p>
              <p className="text-white/60 mt-4 mb-6">
                To upload the CSV or Excel file correctly, please follow these
                guidelines to help you understand the file structure and how to
                populate it correctly:
              </p>
              <ul className="list-disc list-inside text-white/60 mb-6">
                <li className="mb-2">
                  Ensure your file is in either CSV or Excel format.
                </li>
                <li className="mb-2">
                  The first row should contain column headers with the following
                  names:{" "}
                  <span className="text-[#94a4fd]">
                    "first_name", "last_name", "classification", "dob",
                    "gender", and "additional_note" (Optional)
                  </span>
                </li>
                <li className="mb-2">
                  <span className="font-medium">Classification Options: </span>
                  Please ensure each classification data should be any of these{" "}
                  <span className="text-[#94a4fd]">
                    {competitionLevel?.map((itm, idx) => {
                      return `"${itm?.levelName}"${
                        idx !== competitionLevel?.length - 1 ? "," : ""
                      } `;
                    })}
                  </span>
                </li>
                <li className="mb-2">
                  <span className="font-medium">Gender Options: </span>Please
                  ensure each gender data should be any of these options{" "}
                  <span className="text-[#94a4fd]">
                    "Male", "Female", "Transgender", "Non-binary", "prefer not
                    to respond"
                  </span>
                </li>
                <li className="mb-2">
                  <span className="font-medium">Date of Birth format: </span>
                  Please ensure the date format is this format only:
                  <span className="text-[#94a4fd]">"yyyy-mm-dd"</span>
                </li>
              </ul>
              <p className="text-white/80 mb-2">
                Any row data that does not follow the above guidelines will be
                skipped.
              </p>

              <button
                onClick={togglePopup}
                className="text-sm mt-4 px-3 py-1 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition-colors duration-300"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>

      {/* csv file end */}

      {/* the submitting loader */}
      {success && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
            <img
              alt="success"
              src="/images/icons8-checkmark-64.png"
              className="w-10 h-10"
            />
            <p className="text-[1rem] text-white text-center">
              Successfully Added Dancer:{" "}
              <span className="font-bold">
                {formData.first_name} {formData.last_name}{" "}
              </span>
            </p>
          </div>
        </div>
      )}

      {uploadComplete && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit max-h-[70vh] overflow-y-auto p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg relative">
            <div
              onClick={() => closeComplete()}
              className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-2 right-2"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <div className="w-full flex items-center gap-3">
              <img
                alt="success"
                src="/images/icons8-checkmark-64.png"
                className="w-8 h-8"
              />
              <p className="text-[1rem] text-white text-center">
                Upload Complete!
              </p>
            </div>

            {showSkippedRowsMessage && (
              <div className="mb-4 text-white/80">
                <div>
                  {skippedRows?.length === rows?.length - 1 ? (
                    "All rows were skipped!"
                  ) : (
                    <div>
                      <p>
                        The following rows were omitted: (Total{" "}
                        {skippedRows?.length})
                      </p>
                      {skippedRows?.map((x, y) => {
                        return <li key={y}>Row {Number(x) + 1}</li>;
                      })}
                    </div>
                  )}
                  <br />
                  <span className="font-bold">
                    The rows were skipped due to one or all of these:
                  </span>
                  <br />
                  Incomplete data non-conformity with competition
                  classifications
                  <br /> A mismatch with available gender options <br /> Invalid
                  date format.
                  <br />
                  <br /> Please check the CSV upload guidelines.
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AddDancerForm;
