const EmceeEntrySearchCont = ({
  studioSearchTerm,
  setStudioSearchTerm,
  toggleOpenSearchStudio,
  filteredStudios,
  setSelectedStudios,
  setSelectedDancers,
}) => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="w-full h-full p-2 bg-inherit relative flex gap-2"
    >
      <input
        type="text"
        className="w-[80%] outline-none border border-white/20 px-2 py-1 bg-inherit text-white rounded-md text-[.85rem] placeholder:text-white/30"
        placeholder="Search studios"
        value={studioSearchTerm}
        onChange={(e) => setStudioSearchTerm(e.target.value)}
      />
      {/* the close search studios button button */}
      <div
        onClick={(e) => {
          e.stopPropagation();
          toggleOpenSearchStudio();
          setStudioSearchTerm('');
        }}
        className="w-7 h-7 flex justify-center items-center p-1  absolute top-[-5px] right-0 bg-white/30 rounded-full cursor-pointer hover:bg-[#94a4fd]/50"
      >
        <img
          alt="user"
          src="/images/icons8-close-white-50.png"
          className="w-4 h-4"
        />
      </div>

      {studioSearchTerm && (
        <ul
          onClick={(e) => e.stopPropagation()}
          className="w-full min-h-[80px] max-h-[150px] text-[.85rem] text-white overflow-y-auto absolute left-0 top-[60px] bg-black/90 border border-white/20 rounded-md p-3"
        >
          {filteredStudios?.length > 0 ? (
            filteredStudios?.map((studio, idx) => {
              return (
                <li
                  key={idx}
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedStudios([studio?.studio_name]);
                    setStudioSearchTerm('');
                    setSelectedDancers([]);
                  }}
                  className="w-full p-1 cursor-pointer hover:bg-[#94a4fd]/30"
                >
                  {studio?.studio_name}
                </li>
              );
            })
          ) : (
            <li className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
              No results found
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default EmceeEntrySearchCont;
