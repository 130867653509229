import React, { useState } from "react";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import MessageNoteCard from "./MessageNoteCard";
import { collection, doc, updateDoc } from "firebase/firestore";
import { db } from "services/firebase";
import EmceeEntryCount from "./EmceeEntryCount";

const EmceeEntryList = ({
  idx,
  item,
  loading,
  entryTime,
  compId,
  entryCount,
  category,
  selectedEntry,
  handlePerformanceReady,
  handleCheckIn,
  handleRunAround,
  handleDancerStart,
  handleCheckProps,
  closeDropdown,
  setShowDropdownRow,
  showDropdownRow,
  currentEvent,
  setSelectedEntry,
  setLoadingEntry,
  myEntriesSorted,
  allEntries,
  showMoveEntry,
  setShowMoveEntry,
  rearrangeCategories,
  sortedCategories,
  handleOpenEdit,
  setShowNames,
  showNames,
}) => {
  const toggleDropdown = () => {
    setShowDropdownRow(item);
    // setSelectedEntry(null);
  };

  const time = item?.locked_entry ? "AFTER LOCK" : entryTime(item);

  function toggleMoveEntry() {
    setShowMoveEntry((prev) => !prev);
  }

  const categoryEntriesWithoutCurrent = allEntries?.filter(
    (itm) => itm?.category_name === category?.category_name && itm !== item
  );

  const rearrangeENtries = async (id1, id2) => {
    setLoadingEntry(true);
    closeDropdown();

    const sourceIndex = myEntriesSorted?.findIndex(
      (entry) => entry?.id === id1
    );
    const destinationIndex = myEntriesSorted?.findIndex(
      (entry) => entry?.id === id2
    );

    const updatedEntries = Array.from(myEntriesSorted);
    const draggedRow = updatedEntries[sourceIndex];

    // Remove the dragged entry from its original position
    updatedEntries.splice(sourceIndex, 1);

    // Insert the dragged entry at the destination index
    updatedEntries.splice(destinationIndex, 0, draggedRow);

    // Update the sort_id for all categories after the dragged entry
    const maxSortUd = updatedEntries?.reduce((maxSortId, obj) => {
      return obj.sort_id > maxSortId ? obj.sort_id : maxSortId;
    }, updatedEntries[0]?.sort_id);

    updatedEntries.forEach((entry, index) => {
      if (index >= destinationIndex) {
        entry.sort_id = index + 1 + maxSortUd;
      }
    });

    myEntriesSorted = updatedEntries;

    //update docuents on firestore here
    myEntriesSorted?.forEach(async (entry) => {
      try {
        // Update the Firestore document with the new sort_id using updateDoc function
        const collectionRef = collection(db, "competitions", compId, "entries");
        const docRef = doc(collectionRef, entry?.doc_id);
        await updateDoc(docRef, { sort_id: entry?.sort_id });
        console.log({ success: true });
      } catch (error) {
        console.error("Error updating entries sort_id:", error);
      }
    });

    setLoadingEntry(true);
    closeDropdown();
  };

  const [activeNoteIndex, setActiveNoteIndex] = useState(null);

  return (
    <div
      className={`w-full min-w-[800px] sm:min-w-0 flex p-3 border-b border-white/10 relative text-[.75rem] lg:text-[.85rem] cursor-pointer ${
        category?.is_scratched ? "text-white/50" : "text-white"
      } ${showDropdownRow === item && !category?.is_scratched && "mb-20"}`}
      onClick={(e) => {
        e.stopPropagation();
        showDropdownRow === item ? closeDropdown() : toggleDropdown();
        setShowNames(null);
      }}
    >
      {item?.is_scratched && (
        <div className="w-full h-full bg-stone-700/60 absolute top-0 left-0"></div>
      )}
      <div className="flex gap-5 lg:gap-[50px] mr-auto">
        <div className="flex gap-2 lg:gap-4">
          <img
            alt="arrow"
            src={`${
              item?.is_scratched
                ? "/images/scratchCat.png"
                : item?.checkedIn
                ? "/images/CheckSquareOffset.png"
                : "/images/CheckSquareOffset2.png"
            }`}
            className={`lg:w-5 w-4 cursor-pointer ${
              item?.is_scratched ? "lg:h-4 h-3 mt-1" : "lg:h-5 h-4"
            } ${category?.is_scratched && "opacity-50 h-3"}`}
            onClick={(e) => {
              e.stopPropagation();
              handleCheckIn(item);
            }}
          />

          <img
            alt="arrow"
            src={`${
              item?.is_scratched
                ? "/images/scratchCat.png"
                : item?.performance_ready
                ? "/images/checkmark.png"
                : "/images/Checkmark2.png"
            }`}
            className={`lg:w-5 w-4 cursor-pointer ${
              item?.is_scratched ? "lg:h-4 h-3 mt-1" : "lg:h-5 h-4"
            } ${category?.is_scratched && "opacity-50 h-3"}`}
            onClick={(e) => {
              e.stopPropagation();
              handlePerformanceReady(item);
            }}
          />
        </div>
        <p
          className={`min-w-[65px] whitespace-nowrap ${
            item?.locked_entry && "text-white/40 text-[.8em]"
          }`}
        >
          {currentEvent?.locked ? item?.locked_entry_time : time}
        </p>
        <p className="min-w-[30px]">
          {currentEvent?.locked ? item?.locked_entry_count : entryCount(item)}
        </p>
        <p className="w-[120px] lg:w-[200px]">
          {capitalizeFirstLetter(item?.entry_name)}
        </p>
      </div>
      <div className="w-[50%] flex gap-5 lg:gap-[50px]">
        <p className="">{item?.studio_letter}</p>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowNames((prev) => (prev ? null : item));
          }}
          className={`mr-auto whitespace-nowrap w-[250px] px-2 rounded-md relative ${
            item?.dancers_selected?.length > 3 && !showNames
              ? "truncate hover:bg-[#94a4fd]/40 cursor-pointer"
              : item?.dancers_selected?.length > 3 && showNames
              ? "truncate"
              : ""
          }`}
        >
          {item?.dancers_selected?.map((dancer, index) => (
            <React.Fragment key={index}>
              {index > 0 && ", "}
              <span>
                {capitalizeFirstLetter(dancer?.first_name)}{" "}
                {capitalizeFirstLetter(dancer?.last_name)}
              </span>
            </React.Fragment>
          ))}
          {item?.id === showNames?.id &&
            showNames?.dancers_selected?.length > 3 && (
              <ul className="w-[220px] bg-[#282929] rounded-md p-2 flex flex-col z-[3]">
                {showNames?.dancers_selected?.map((x, i) => {
                  return (
                    <li key={i} className="w-full border-b border-white/10">
                      {capitalizeFirstLetter(x?.first_name)}{" "}
                      {capitalizeFirstLetter(x?.last_name)}
                    </li>
                  );
                })}
              </ul>
            )}
        </div>

        <div className="flex gap-1 items-center">
          {activeNoteIndex !== item?.id && (
            <>
              {" "}
              <img
                alt="person"
                src={
                  item?.isDancerStarts
                    ? "/images/PersonGreen.png"
                    : "/images/Person.png"
                }
                className="lg:w-5 lg:h-5 w-4 h-4"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDancerStart(item);
                }}
              />
              <img
                alt="arrow"
                src={
                  item?.isRunAround
                    ? "/images/runaround-green.png"
                    : "/images/runaround.png"
                }
                className={`lg:w-5 lg:h-5 w-4 h-4`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleRunAround(item);
                }}
              />
              <img
                alt="checked"
                src={
                  item?.isCheckedProps
                    ? "/images/ClockGreen.png"
                    : "/images/Clock.png"
                }
                className="lg:w-5 lg:h-5 w-4 h-4"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCheckProps(item);
                }}
              />
            </>
          )}
          <MessageNoteCard
            item={item}
            compId={compId}
            activeNoteIndex={activeNoteIndex}
            setActiveNoteIndex={setActiveNoteIndex}
          />
        </div>
      </div>
      {showDropdownRow === item && !category?.is_scratched && (
        <EmceeEntryCount
          showMoveEntry={showMoveEntry}
          loading={loading}
          toggleMoveEntry={toggleMoveEntry}
          categoryEntriesWithoutCurrent={categoryEntriesWithoutCurrent}
          rearrangeENtries={rearrangeENtries}
          item={item}
          category={category}
          currentEvent={currentEvent}
          compId={compId}
          allEntries={allEntries}
          entryCount={entryCount}
          rearrangeCategories={rearrangeCategories}
          sortedCategories={sortedCategories}
          setShowDropdownRow={setShowDropdownRow}
          handleOpenEdit={handleOpenEdit}
        />
      )}
    </div>
  );
};

export default EmceeEntryList;
