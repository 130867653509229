/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from "react";

const StagingSlider = ({
  sliderValues,
  handleSliderChange,
  unblocked,
  currentEntryScoreData,
  stepValue,
  typed,
  setTyped,
}) => {
  const [typeValue, setTypeValue] = useState(false);
  const inputRef = useRef(null);

  function toggleType() {
    setTypeValue((prev) => !prev);
  }

  useEffect(() => {
    // Focus the input element when typeValue becomes true
    if (typeValue && inputRef.current) {
      inputRef.current.focus();
    }
  }, [typeValue]);

  useEffect(() => {
    if (currentEntryScoreData?.length > 0 || unblocked) {
      handleSliderChange("staging", currentEntryScoreData[0]?.staging);
    }
  }, [unblocked, stepValue]);

  return (
    <div className="flex flex-col gap-3 md:items-center px-3 py-2">
      <label htmlFor="staging" className="text-white uppercase w-[270px]">
        Staging & dynamics
      </label>
      <div className="w-full flex gap-4 items-center">
        <span>0</span>
        <div className="w-full relative">
          {/* Center line */}
          <div
            className="w-[2px] h-[120%] bg-white/50 absolute top-[50%] left-[25%] translate-x-[-50%] "
            style={{ transform: "translateY(-50%)" }}
          ></div>

          {/* 1/4 notch */}
          <div
            className="w-[2px] h-[120%] bg-white/50 absolute top-[50%] left-[50%] translate-x-[-50%]"
            style={{ transform: "translateY(-50%)" }}
          ></div>

          {/* 3/4 notch */}
          <div
            className="w-[2px] h-[120%] bg-white/50 absolute top-[50%] left-[75%] translate-x-[-50%]"
            style={{ transform: "translateY(-50%)" }}
          ></div>
          <input
            type="range"
            id="staging"
            min={0}
            max={20}
            step={stepValue}
            value={
              !unblocked && currentEntryScoreData?.length > 0
                ? currentEntryScoreData[0]?.staging
                : sliderValues.staging
            }
            onChange={(e) => {
              handleSliderChange(
                "staging",
                parseFloat(e.target.value).toFixed(2)
              );
              setTyped((prev) => [...prev, "staging"]);
            }}
            className="w-full slider"
          />
        </div>
        <span>20</span>

        <>
          {!typeValue && (
            <div
              onClick={toggleType}
              className="p-1 w-[80px] flex justify-center rounded-sm ml-4 md:ml-7 bg-[#9A9A9A]/50 text-[1.25rem] font-medium cursor-pointer"
            >
              {!currentEntryScoreData[0]?.staging
                ? sliderValues.staging
                : typed?.includes("staging")
                ? sliderValues.staging
                : currentEntryScoreData[0]?.staging}
            </div>
          )}
          {typeValue && (
            <div className=" ml-4 md:ml-7">
              <input
                ref={inputRef}
                type="text"
                inputMode="numeric"
                pattern="^(0|[1-9]\d{0,1}|1\d{0,1}|20)(\.\d{0,2})?$"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9.]/g, "");
                  if (e.target.value > 20) {
                    e.target.value = "20";
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // Prevent the default Enter behavior (e.g., form submission)
                    e.preventDefault();
                    // Update the slider value with the entered value
                    handleSliderChange(
                      "staging",
                      parseFloat(e.target.value).toFixed(2)
                    );
                    // Close the input field
                    toggleType();
                    setTyped((prev) => [...prev, "staging"]);
                  }
                }}
                onChange={(e) =>
                  handleSliderChange(
                    "staging",
                    parseFloat(e.target.value).toFixed(2)
                  )
                }
                className="w-[60px] h-[40px] border-white/30 text-white bg-white/40"
              />
              <div className="mt-1 flex w-full justify-between gap-2">
                <div
                  onClick={() => {
                    toggleType();
                    unblocked
                      ? handleSliderChange(
                          "staging",
                          currentEntryScoreData[0]?.staging
                        )
                      : handleSliderChange("staging", 0);
                  }}
                  className="w-7 h-7 flex justify-center items-center bg-white/30 cursor-pointer rounded-full"
                >
                  <img
                    alt=""
                    src="/images/icons8-cross-48.png"
                    className="w-5 h-5"
                  />
                </div>
                <div
                  onClick={() => {
                    toggleType();
                    setTyped((prev) => [...prev, "staging"]);

                    // handleSliderChange("staging", 0);
                  }}
                  className="w-7 h-7 flex justify-center items-center bg-white/30 cursor-pointer rounded-full"
                >
                  <img
                    alt=""
                    src="/images/icons8-check-48.png"
                    className="w-5 h-5"
                  />
                </div>
              </div>
            </div>
          )}
        </>
        {/* )} */}
      </div>
    </div>
  );
};

export default StagingSlider;
