import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  addDeductionCriteria,
  removeDeductionCriteria,
} from '../../../redux/organizerRegistration/organizerSlices';
import RemoveImg from '../../../assets/remove.png';

const deductionSchema = Yup.object().shape({
  deductionCriteria: Yup.string()
    .required('Required')
    .matches(/^[^0-9]*$/, 'deduction criteria should not contain numbers'),
  deductionPercent: Yup.string()
    .matches(/^[0-9]+$/, 'Only numeric values are allowed')
    .required('Required'),
  shortCode: Yup.string()
    .required('Required')
    .matches(/^[^0-9]*$/, 'short code should not contain numbers'),
});

const Deduction = () => {
  const dispatch = useDispatch();
  const { deductionCriteria } = useSelector((state) => state.persistedReducer);

  const formik = useFormik({
    initialValues: {
      deductionCriteria: '',
      deductionPercent: '',
      shortCode: '',
    },
    validationSchema: deductionSchema,

    onSubmit: (values, { resetForm }) => {
      if (formik.isValid) {
        const lowercaseValues = {
          ...values,
          deductionCriteria: values.deductionCriteria.toLowerCase(),
          shortCode: values.shortCode.toLowerCase(),
        };

        const isDuplicateDeduction = deductionCriteria.some(
          (item) => item.deductionCriteria === lowercaseValues.deductionCriteria
        );

        const isDuplicateShortCode = deductionCriteria.some(
          (item) => item.shortCode === lowercaseValues.shortCode
        );

        if (isDuplicateDeduction) {
          formik.setFieldError(
            'deductionCriteria',
            'Deduction Criteria already exists'
          );
        } else if (isDuplicateShortCode) {
          formik.setFieldError('shortCode', 'Short Code already exists');
        } else {
          dispatch(addDeductionCriteria(lowercaseValues));
          resetForm();
        }
      }
    },
  });

  const handleRemove = (index) => {
    dispatch(removeDeductionCriteria(index));
  };

  return (
    <div className="w-full mt-8 form-control sm:mt-6">
      <label className="label w-[75%]">
        <span className="mb-2 text-base font-medium label-text text-[#696F79]">
          What are your deductions criteria and percentage gets deducted?
        </span>
      </label>
      <div className="flex flex-row flex-wrap items-baseline px-1 mb-[0.75rem]">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col w-full lg:w-[75%] md:w-[75%] mr-4"
        >
          <div className="flex flex-col md:flex-row items-center justify-between w-full gap-3">
            <div className="w-full flex flex-col gap-2">
              <input
                name="deductionCriteria"
                type="text"
                placeholder="Deduction Criteria"
                onChange={formik.handleChange}
                value={formik.values.deductionCriteria}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6] ${
                  formik.errors.deductionCriteria &&
                  formik.touched.deductionCriteria &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.deductionCriteria &&
                formik.touched.deductionCriteria && (
                  <span className="text-xs text-red-500">
                    {formik.errors.deductionCriteria}
                  </span>
                )}
            </div>
            <div className="w-full flex flex-col gap-2">
              <input
                type="text"
                name="deductionPercent"
                placeholder="%"
                onChange={formik.handleChange}
                value={formik.values.deductionPercent}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.deductionPercent &&
                  formik.touched.deductionPercent &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.deductionPercent &&
                formik.touched.deductionPercent && (
                  <span className="text-xs text-red-500">
                    {formik.errors.deductionPercent}
                  </span>
                )}
            </div>
            <div className="w-full flex flex-col gap-2">
              <input
                type="text"
                name="shortCode"
                placeholder="short Code"
                onChange={formik.handleChange}
                value={formik.values.shortCode}
                className={`input input-bordered border-[#959FB1] h-[38px] w-full md:w-[100%] text-sm font-medium text-[#9FA8B8] mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.shortCode &&
                  formik.touched.shortCode &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.shortCode && formik.touched.shortCode && (
                <span className="text-xs text-red-500">
                  {formik.errors.shortCode}
                </span>
              )}
            </div>

            <button
              type="submit"
              // check if the form is valid before submitting
              className="max-h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize h-[31px] px-[4rem] ml-2 md:px-9 text-[1rem]"
            >
              add
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-row flex-wrap w-fit justify-start items-center py-2 rounded-xl cursor-pointer">
        {deductionCriteria.map((type, index) => (
          <div
            key={index}
            className="flex flex-row flex-wrap w-fit mb-[8px] mr-[10px] bg-[#FFE600] items-center p-[10px] py-2 rounded-md cursor-pointer"
          >
            <span className="text-sm font-medium text-[#8692A6] ">
              {`${type.deductionCriteria}, ${type.deductionPercent}%, ${type.shortCode}`}
            </span>
            <img
              src={RemoveImg}
              alt="remove"
              className="h-4 w-4 ml-[10px]"
              onClick={() => handleRemove(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Deduction;
