/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const StudioRegTable = ({ studioRegistrations, currentEvent }) => {
  function calculateTotalPayments(arr) {
    if (!arr || !Array?.isArray(arr)) {
      return "";
    }

    let totalAmount = 0;

    for (const obj of arr) {
      if (obj.amount) {
        totalAmount += parseFloat(obj.amount);
      }
    }

    return totalAmount.toFixed(2);
  }

  function getAllDancers(arr) {
    const allSelected = [];

    if (arr && Array?.isArray(arr)) {
      for (const obj of arr) {
        if (obj.dancers_selected && Array?.isArray(obj.dancers_selected)) {
          allSelected.push(...obj.dancers_selected);
        }
      }
    }

    return allSelected;
  }

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { data: allPayment } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "payment_history"
  );

  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const { status, data: compData } = useFirestoreQueryDoc(
    "competitions",
    user?.uid
  );
  const [taxRate, setTaxRate] = useState(0.0);

  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);

      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
    }
  }, [compData, status]);

  return (
    <table className="min-w-[600px] w-full text-[.9rem] overflow-y-auto">
      <thead className="text-white/40 text-left border-b border-white/30">
        <tr>
          <th>Studio Name</th>
          <th>Studio Letter</th>
          <th>Status</th>
          <th>Est. Entries</th>
          <th>Paid</th>
        </tr>
      </thead>
      <tbody className="text-white/80">
        {studioRegistrations?.map((item, index) => {
          const currentStudio = currentEvent?.access?.filter(
            (x) => x?.studio_id === item?.studio_id
          )[0];

          const studioPayment = allPayment?.filter(
            (itm) =>
              itm?.studio_id === item?.studio_id &&
              itm?.event_uid === currentEvent?.event_uid
          );

          // const studioEntries = allEntries?.filter(
          //   (each) =>
          //     each?.studio_selected[0] === item?.studio_name &&
          //     each?.event_uid === currentEvent?.event_uid
          // );
          const studioEntries = allEntries?.filter(
            (each) =>
              each?.studio_selected &&
              each?.studio_selected[0] === item?.studio_name &&
              each?.event_uid === currentEvent?.event_uid
          );

          const newTotalAmount = entryTypes
            ?.reduce((total, row) => {
              const rowPricingOption = pricing?.find((obj) =>
                obj.hasOwnProperty(row?.entryTypeName)
              );
              const rowEntries = studioEntries?.filter(
                (itm) => itm?.entryType === row?.entryTypeName
              );

              const flatAmount =
                rowEntries?.length > 0
                  ? rowPricingOption?.[row?.entryTypeName] * rowEntries?.length
                  : 0.0;

              const rowAmount =
                rowPricingOption?.selectedOption?.toLowerCase() === "flat"
                  ? Number(flatAmount)
                  : getAllDancers(rowEntries)?.length *
                    Number(rowPricingOption?.[row?.entryTypeName]);

              return total + (rowAmount || 0);
            }, 0)
            .toFixed(2);

          function calcTotalAfterTax(amount, percentage) {
            const result = (amount * percentage) / 100;
            return result.toFixed(2);
          }

          const totalAfterTax =
            Number(newTotalAmount) +
            Number(calcTotalAfterTax(newTotalAmount, taxRate));

          const amountOwing =
            totalAfterTax - calculateTotalPayments(studioPayment);

          const assignedStudioLetter = (() => {
            const matchingStudioIndex =
              item?.studio_name &&
              studioRegistrations?.findIndex(
                (itm) => itm?.studio_name === item?.studio_name
              );
            if (matchingStudioIndex >= 0) {
              return String.fromCharCode(65 + matchingStudioIndex); // 65 corresponds to the ASCII code for 'A'
            } else {
              return undefined;
            }
          })();

          return (
            <tr key={index}>
              <td>{capitalizeFirstLetter(item?.studio_name)}</td>
              <td>{assignedStudioLetter}</td>
              <td>
                {totalAfterTax > 0 && amountOwing <= 0.0
                  ? "Paid"
                  : calculateTotalPayments(studioPayment) > 0
                  ? "Part Payment"
                  : "Unpaid"}
              </td>
              <td>{currentStudio?.estimated_entries || 0}</td>
              <td>${calculateTotalPayments(studioPayment)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default StudioRegTable;
