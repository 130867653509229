export const removeDuplicateObjectsByProperty = (objects, property) => {
  const seen = new Set();
  return objects?.filter((obj) => {
    const propertyValue = obj?.[property];
    // Check if the type value is not equal to "session" before filtering duplicates
    if (obj.break_type !== "session") {
      return !seen?.has(propertyValue) && seen?.add(propertyValue);
    }
    return true; // Keep items with type === 'session'
  });
};
