import React, { useState } from 'react';
import AddClassForm from './AddClassForm';
import { PulseLoader } from 'react-spinners';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import EditClassForm from './EditClassForm';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { db } from 'services/firebase';
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';

const AddClassificationForm = ({ status, organizerData, user }) => {
  const compLevel = organizerData?.competitionLevel;
  const compLevelArray = Object?.values(compLevel || {});

  const [isOpen, setIsOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const openForm = (compLevel) => {
    // Set the selected special Tag for editing, or null for adding a new one
    setSelectedClass(compLevel);
    setIsOpen(false); // Close the edit form
    setIsConfirmationOpen(true); // Open the confirmation dialog
  };

  const closeForm = () => {
    setIsOpen(false);
    setIsConfirmationOpen(false); // Close the confirmation dialog when closing the form
    setSelectedClass(null); // Clear the selected special Tag when the form is closed
  };

  const continueEdit = () => {
    setIsConfirmationOpen(false); // Close the confirmation dialog
    setIsOpen(true); // Open the edit form
  };

  const openDeleteConfirmation = (selectedClass) => {
    setSelectedClass(selectedClass);
    setDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    const organizerDocRef = doc(db, 'organizers', user?.uid);
    const competitionDocRef = doc(db, 'competitions', user?.uid);

    // Get the current document data
    const organizerDocSnap = await getDoc(organizerDocRef);
    const currentData = organizerDocSnap.data();

    // Remove the selected competition level from organizerData.competitionLevel
    const updatedLevels = (currentData.competitionLevel || []).filter(
      (level) => level?.levelName !== selectedClass?.levelName
    );

    // Update the document with the new competition levels
    await updateDoc(organizerDocRef, {
      competitionLevel: updatedLevels,
    });

    // Update the competition document with the new competition levels
    await updateDoc(competitionDocRef, {
      competitionLevel: updatedLevels,
    });

    // If the level name has changed, delete the old entries
    if (selectedClass?.levelName) {
      // Reference to the "entries" sub-collection
      const entriesRef = collection(db, 'competitions', user?.uid, 'entries');

      // Get all entries with the previous levelName
      const entriesQuery = query(
        entriesRef,
        where('classification', '==', selectedClass?.levelName)
      );

      // Get the documents that match the query
      const entryQuerySnapshot = await getDocs(entriesQuery);

      // Delete each entry that matches the query
      entryQuerySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    }

    // Close the confirmation dialog
    setDeleteConfirmation(false);
    // Set loading to false after the update is complete
    setLoading(false);
  };

  return (
    <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
      <h1 className="w-full border-b pb-2 font-bold border-white/30">
        All Classifications Values
      </h1>
      {status === 'loading' && (
        <div className="w-full h-[150px] flex justify-center items-center">
          <PulseLoader color="#94a4fd" size={10} />
        </div>
      )}
      <div className="grid grid-cols-3 gap-3">
        {compLevelArray?.map((item, index) => (
          <div
            key={index}
            className="bg-[#1c1c1c] rounded-lg p-2 text-sm text-white flex items-center justify-between"
          >
            <div>
              <div className="">{capitalizeFirstLetter(item?.levelName)}</div>
            </div>
            <div className="flex items-center gap-3">
              <img
                src="/images/icons8-edit.png"
                alt="edit"
                className="w-full h-4 cursor-pointer"
                onClick={() => openForm(item)}
              />
              <img
                src="/images/deleteCat.png"
                alt="delete"
                className="w-full h-4 cursor-pointer"
                onClick={() => openDeleteConfirmation(item)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5 flex items-center justify-end">
        <button
          onClick={() => setIsOpen(true)}
          className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75 cursor-pointer"
        >
          Add
        </button>
      </div>
      {/* Show confirmation dialog if isConfirmationOpen is true */}
      {isConfirmationOpen && selectedClass !== null && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50">
              Confirm Edit Competition Level:
              <br />
              <span className="font-bold">{`${selectedClass?.levelName}`}</span>
              ?
            </p>

            <p className="text-white/70">
              NOTE: you will be redirected to the edit page to make changes to
              the Competition Level. Once you edit this{' '}
              <span className="font-bold">{`${selectedClass?.levelName}`}</span>{' '}
              ? Name it will delete all the entries attached with it.
              <br />
              Are you sure you want to proceed with the changes?
            </p>

            <div className="mt-4 w-full text-center">
              <button
                onClick={closeForm}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={continueEdit}
                className="text-[.9rem] px-8 py-2 rounded-md bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Show confirmation dialog if isConfirmationOpen is true for deletion*/}
      {deleteConfirmation && selectedClass !== null && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50">
              Confirm Delete Competition Level:
              <br />
              <span className="font-bold">{`${selectedClass?.levelName}`}</span>
              ?
            </p>

            <p className="text-white/70">
              NOTE: Once you Delete this level it will delete It from the events
              entirely and the entries attached to it. Note that this process is
              irreversible. Once you delete this{' '}
              <span className="font-bold">{`${selectedClass?.levelName}`}</span>{' '}
              ? Name it will delete all the entries attached with it.
              <br />
              Are you sure you want to proceed with the changes?
            </p>

            <div className="mt-4 w-full text-center flex items-center justify-center">
              <button
                onClick={() => setDeleteConfirmation(false)}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                onClick={handleDelete}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Delete'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Show Popup if isClassificationOpen is true */}
      {isOpen &&
        (selectedClass !== null ? (
          <EditClassForm
            compLevel={selectedClass}
            onClose={closeForm}
            user={user}
            organizerData={organizerData}
          />
        ) : (
          <AddClassForm
            onClose={closeForm}
            user={user}
            organizerData={organizerData}
          />
        ))}
    </div>
  );
};

export default AddClassificationForm;
