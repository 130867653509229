/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { Header, RightSidebar } from ".";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useFetchScheduleDocs } from "components/hooks/useFetchScheduleDocs";
import { useNavigate } from "react-router-dom";
import { PulseLoader, ClipLoader } from "react-spinners";
import MiniCategory from "./components/MiniCategory";
import { useEffect, useState } from "react";
import { VariableSizeList as List } from "react-window";
import { performBackgroundUpdate2 } from "utils/performBackgroundUpdate2";
// import MoveCategoryDnd from "./components/MoveCategoryDnd";
// import { DragDropContext, Droppable } from "react-beautiful-dnd";

// Custom droppable component to work around React StrictMode
// export const StrictModeDroppable = ({ children, ...props }) => {
//   const [enabled, setEnabled] = useState(false);

//   useEffect(() => {
//     const animation = requestAnimationFrame(() => setEnabled(true));
//     return () => {
//       cancelAnimationFrame(animation);
//       setEnabled(false);
//     };
//   }, []);

//   if (!enabled) {
//     return null;
//   }

//   return <Droppable {...props}>{children}</Droppable>;
// };

const MoveCategories = () => {
  const navigate = useNavigate();

  const { openNotifsBoard, currentEvent } = useSelector(
    (state) => state.eventManagement
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  // to get all categories
  const { status, data: eventCategories } = useFetchScheduleDocs(
    "competitions",
    user?.uid,
    "categories",
    "event_uid",
    currentEvent?.event_uid
  );

  let sortedCategoriesRaw = eventCategories?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  const [sortedCategories, setSortedCategories] = useState([]);
  const [affectedItems2, setaffectedItems2] = useState(
    JSON.parse(localStorage.getItem("affectedItems2")) || []
  );
  useEffect(() => {
    setaffectedItems2(JSON.parse(localStorage.getItem("affectedItems2")) || []);
  }, [sortedCategories]);

  useEffect(() => {
    if (status === "success") {
      // sortedCategoriesRaw?.map((x, ind, arr) => {
      //   const sessionsBeforeCurrent = arr
      //     ?.slice(0, ind)
      //     ?.filter((x) => x?.break_type === "session");
      //   if (x?.break_type === "session") {
      //     x.category_name = `session ${sessionsBeforeCurrent?.length + 1}`;
      //   }
      //   return x;
      // });
      setSortedCategories(sortedCategoriesRaw);
    }
  }, [eventCategories]);

  // console.log("sortedCategories", sortedCategories);

  const [visibleIndexes, setVisibleIndexes] = useState([]);
  const [listHeight, setListHeight] = useState(0);
  useEffect(() => {
    // Set the list height to be 80% of the screen height
    const screenHeight = window.innerHeight;
    const newHeight = Math.floor(screenHeight * 0.75);
    setListHeight(newHeight);
  }, []);

  const itemSize = (index) => {
    // You might want to adjust this based on your actual item heights
    return sortedCategories[index]?.type === "category" ? 75 : 50; // Set the height of each item
  };

  const handleVisibilityChange = (start, stop) => {
    setVisibleIndexes((prev) => {
      const newVisibleIndexes = [];
      for (let i = start; i <= stop; i++) {
        newVisibleIndexes.push(i);
      }
      return newVisibleIndexes;
    });
  };

  const [loading, setLoading] = useState(false);
  const handleSaveDrag = async () => {
    setLoading(true);
    await performBackgroundUpdate2(affectedItems2, user, setLoading)?.then(
      () => {
        localStorage.removeItem("affectedItems2");
        setaffectedItems2([]);
        setLoading(false);
      }
    );
  };

  const [back, setback] = useState(false);

  // const handleDragEnd = () => {}

  const [selectedCategory, setselectedCategory] = useState(null);

  return (
    <>
      {/* <LeftSidebar /> */}
      <Header />
      {openNotifsBoard && <RightSidebar />}

      <div
        className={`w-full min-h-screen text-white/80 px-5 sm:pl-[30px] sm:pr-[30px]  ${
          openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
        } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
      >
        <div className="mb-auto flex flex-col justify-between">
          {/* Heading */}
          <div className="flex flex-wrap gap-2 items-center text-white/90 text-[1rem] mb-6">
            <h1>
              {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
              {currentEvent?.year.split(" to ")[0]} - Move Categories
            </h1>

            <button
              onClick={() => {
                navigate(
                  `/organizer/event/${currentEvent?.event_name.replace(
                    / /g,
                    "-"
                  )}/schedule`
                );
                setback(true);
              }}
              className={`w-fit ml-auto text-[.85rem] px-5 py-1 mr-2 rounded-full flex items-center justify-center min-w-[80px] text-[#1c1c1c] transition-all duration-300 bg-gray-300 hover:bg-[#94a4fd]/60`}
            >
              {back ? <ClipLoader color="#000" size={20} /> : "Back"}
            </button>
          </div>

          <section className="w-full flex flex-col gap-2 relative">
            {status === "success" && sortedCategories?.length !== 0 && (
              <div className="w-full lg:w-[85%] text-[.9em] pb-5 pt-[20px] md:pt-0 overflow-x-auto overflow-y-hidden rounded-lg relative parentContainer">
                {/* The schedule table */}
                <div className="w-full relative">
                  <div className="flex justify-between items-center flex-wrap gap-3 w-full bg-[#1c1c1c] p-3 border border-white/20 rounded-t-lg stickyDiv transition-all duration-300">
                    {affectedItems2?.length > 0 || loading ? (
                      <button
                        onClick={handleSaveDrag}
                        className="w-[110px] h-fit text-[.85rem] rounded-full flex px-3 py-[3px] items-center justify-center text-black bg-[#94a4fd] hover:opacity-70 transition-all duration-300 whitespace-nowrap"
                      >
                        {loading ? (
                          <ClipLoader color="#000" size={20} />
                        ) : (
                          "Save Changes"
                        )}
                      </button>
                    ) : (
                      <div className="flex gap-3">
                        <button className="h-fit text-[.85rem] rounded-full flex gap-1 items-center text-white/80 transition-all duration-300">
                          Categories
                          <p className="font-bold">
                            {sortedCategories?.length}
                          </p>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                {/* <MoveCategoryDnd
                  sortedCategories={sortedCategories}
                  currentEvent={currentEvent}
                  setSortedCategories={setSortedCategories}
                  affectedItems2={affectedItems2}
                  setaffectedItems2={setaffectedItems2}
                /> */}
                <List
                  height={listHeight} // Set the height dynamically
                  itemCount={sortedCategories?.length}
                  itemSize={itemSize}
                  width="100%"
                  onItemsRendered={({
                    visibleStartIndex,
                    visibleStopIndex,
                  }) => {
                    handleVisibilityChange(visibleStartIndex, visibleStopIndex);
                  }}
                >
                  {({ index, style }) => (
                    <div style={style}>
                      {visibleIndexes.includes(index) && (
                        <>
                          <MiniCategory
                            category={sortedCategories[index]}
                            index={index}
                            sortedCategories={sortedCategories}
                            setSortedCategories={setSortedCategories}
                            affectedItems2={affectedItems2}
                            setaffectedItems2={setaffectedItems2}
                            selectedCategory={selectedCategory}
                            setselectedCategory={setselectedCategory}
                          />

                          <div className="h-[160px] w-full"></div>
                        </>
                      )}
                    </div>
                  )}
                </List>
              </div>
            )}

            {/* <DragDropContext onDragEnd={handleDragEnd2}>
                    <StrictModeDroppable droppableId="cat-list">
                      {(provided) => (
                        <div
                          className="w-full relative"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          onMouseDown={(e) => e.stopPropagation()}
                        >
                          {eachRoutineCategory}
                          {provided.placeholder}
                        </div>
                      )}
                    </StrictModeDroppable>
                  </DragDropContext> */}

            {sortedCategories?.length === 0 && status !== "loading" && (
              <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
                <h4>No Categories Yet...</h4>
              </div>
            )}

            {/* to show spinner on page load */}
            {sortedCategories?.length === 0 && status === "loading" && (
              <div className="w-full md:w-[85%]  text-[.9em] pt-3 pb-20 overflow-x-auto overflow-y-hidden">
                <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
                  <PulseLoader color="#94a4fd" size={10} />
                </div>
              </div>
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default MoveCategories;
