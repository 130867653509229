/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { PulseLoader } from "react-spinners";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";

const OverviewTable = ({
  searchedStudios,
  searchTerm,
  studiosWithAccess,
  currentEvent,
}) => {
  const navigate = useNavigate();
  const { event_name } = useParams();

  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  // const { currentEvent } = useSelector((state) => state.eventManagement);

  //==================================================================================tp get comp data
  const { status: compStatus, data: compData } = useFirestoreQueryDoc(
    "competitions",
    user?.uid
  );
  const [taxRate, setTaxRate] = useState(0.0);

  const [entryTypes, setentryTypes] = useState([]);
  const [pricing, setpricing] = useState([]);

  useEffect(() => {
    if (compData) {
      const { entryTypes, pricing, events } = compData;
      setentryTypes(entryTypes);
      setpricing(pricing?.pricingCategories);
      const currentEve = events?.filter(
        (itm) => itm?.event_uid === currentEvent?.event_uid
      )[0];
      setTaxRate(currentEve?.tax_rate || 0);
    }
  }, [compData, compStatus]);

  const allEntryTypes = entryTypes?.concat(
    compData?.specialEntryTypes?.tags || []
  );

  //====================================================================================to get all entries
  const { status, data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const { data: allPayment } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "payment_history"
  );

  function getAllDancers(arr) {
    const allSelected = [];

    if (arr && Array?.isArray(arr)) {
      for (const obj of arr) {
        if (obj.dancers_selected && Array?.isArray(obj.dancers_selected)) {
          allSelected.push(...obj.dancers_selected);
        }
      }
    }

    return allSelected;
  }

  function findLatestDate(array) {
    if (array?.length === 0 || !array) {
      return null;
    }

    let latestDate = array[0]?.date;
    for (let i = 1; i < array?.length; i++) {
      if (array[i]?.date > latestDate) {
        latestDate = array[i]?.date;
      }
    }

    return latestDate;
  }

  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(0);

  const rowPerPage = 8;
  const pagesVisited = pageNumber * rowPerPage;

  const dynamicStudioArray = searchTerm ? searchedStudios : studiosWithAccess;

  const displayRows = dynamicStudioArray
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      const currentStudio = currentEvent?.access?.filter(
        (itm) => itm?.studio_id === row?.studio_id
      )[0];

      const studioEntries = allEntries?.filter(
        (each) =>
          each?.studio_selected[0]?.toLowerCase() ===
            currentStudio?.studio_name?.toLowerCase() &&
          each?.event_uid === currentEvent?.event_uid
      );

      const studioPayment = allPayment?.filter(
        (itm) =>
          itm?.studio_id === row?.studio_id &&
          itm?.event_uid === currentEvent?.event_uid
      );

      // ====================================================================================Calculate the totalAmount owning
      const newTotalAmount = allEntryTypes
        ?.reduce((total, row) => {
          let rowPricingOption;
          if (row?.entryTypeName) {
            rowPricingOption = pricing?.find((obj) =>
              obj.hasOwnProperty(row.entryTypeName)
            );
          } else if (row.specialEntryTypeName) {
            rowPricingOption = pricing?.find((obj) =>
              obj.hasOwnProperty(row.specialEntryTypeName)
            );
          }
          // const rowEntries = studioEntries?.filter(
          //   (itm) => itm?.entryType === row?.entryTypeName
          // );

          // to calculate special entries and normal entries
          function rowEntries1(arr) {
            const result = arr?.filter(
              (itm) =>
                itm?.entryType === row?.entryTypeName &&
                !itm?.category_name?.includes("impulse") &&
                !itm?.category_name?.includes("remix") &&
                !itm?.category_name?.includes("unity") &&
                !itm?.category_name?.includes("overtime")
            );
            return result;
          }

          function rowEntriesSpecial(arr) {
            const result = arr?.filter((x) =>
              x?.category_name
                ?.toLowerCase()
                ?.includes(row?.specialEntryTypeName?.toLowerCase())
            );
            return result;
          }

          const rowEntries =
            row?.specialEntryTypeName?.includes("impulse") ||
            row?.specialEntryTypeName?.includes("remix") ||
            row?.specialEntryTypeName?.includes("unity") ||
            row?.specialEntryTypeName?.includes("overtime")
              ? rowEntriesSpecial(studioEntries)
              : rowEntries1(studioEntries);

          const flatAmount =
            rowEntries?.length > 0
              ? rowPricingOption?.[row?.entryTypeName] * rowEntries?.length
              : 0.0;

          const rowAmount =
            rowPricingOption?.selectedOption?.toLowerCase() === "flat"
              ? Number(flatAmount)
              : getAllDancers(rowEntries)?.length *
                Number(
                  rowPricingOption?.[row?.entryTypeName] ||
                    rowPricingOption?.[row?.specialEntryTypeName]
                );

          return total + (rowAmount || 0);
        }, 0)
        .toFixed(2);

      function calcTotalAfterTax(amount, percentage) {
        const result = (amount * percentage) / 100;
        return result.toFixed(2);
      }

      const totalAfterTax =
        Number(newTotalAmount) +
        Number(calcTotalAfterTax(newTotalAmount, taxRate));

      function calculateTotalPayments(arr) {
        if (!arr || !Array?.isArray(arr)) {
          return "Invalid input";
        }

        let totalAmount = 0;

        for (const obj of arr) {
          if (obj.amount) {
            totalAmount += parseFloat(obj.amount);
          }
        }

        return totalAmount.toFixed(2);
      }

      const amountOwing = totalAfterTax - calculateTotalPayments(studioPayment);

      return (
        <tr
          key={index}
          className="border-b border-white/10 whitespace-nowrap cursor-pointer hover:bg-white/10"
          onClick={() => {
            navigate(
              `/organizer/event/${event_name.replace(
                / /g,
                "-"
              )}/accounting-overview/${row?.studio_id}`
            );
          }}
        >
          <td>
            <div className="flex items-center">
              <div className="w-6 h-6 border-4 border-white/60 rounded-full"></div>
              {capitalizeFirstLetter(row?.first_name)} {row?.last_name}
            </div>
          </td>
          <td>{row?.studio_name}</td>
          <td>{row?.email}</td>
          <td>${calculateTotalPayments(studioPayment)}</td>
          <td>${amountOwing?.toFixed(2)}</td>
          <td>
            <div className="flex items-center">
              <img
                alt="arrow"
                src="/images/CalendarBlank.png"
                className="w-6 h-6"
              />
              {findLatestDate(studioPayment) || "N/A"}
            </div>
          </td>
          <td>
            <div
              className={`flex items-center ${
                row?.account_status === "Inprogress"
                  ? "text-yellow-200"
                  : row?.account_status === "completed"
                  ? "text-green-300"
                  : "text-red-400"
              } `}
            >
              <div
                className={`w-1 h-1 rounded-full ${
                  row?.account_status === "Inprogress"
                    ? "bg-yellow-200"
                    : row?.account_status === "completed"
                    ? "bg-green-300"
                    : "bg-red-400"
                }`}
              ></div>
              {row?.account_status}
            </div>
          </td>
        </tr>
      );
    });

  const pageCount = Math.ceil(studiosWithAccess?.length / rowPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  // console.log("studiosWithAccess", studiosWithAccess);

  return (
    <>
      <div className="w-full h-full mt-5 pb-2 overflow-auto">
        {status !== "loading" && studiosWithAccess?.length !== 0 && (
          <table className="min-w-[600px] w-full text-[.85rem] table2">
            <thead className="text-white/40 text-left border-b border-white/30">
              <tr className="whitespace-nowrap">
                <th>User</th>
                <th>Dance Studio</th>
                <th>Email Address</th>
                <th>Paid</th>
                <th>Owing</th>
                <th>Last Payment</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="text-white/80">{displayRows}</tbody>
          </table>
        )}

        {(studiosWithAccess?.length < 1 || !studiosWithAccess) &&
          status === "success" && (
            <div className="w-full min-h-[200px] flex justify-center items-center text-[.85rem] text-white/60 mt-4 border border-white/10 rounded-xl">
              Nothing yet...
            </div>
          )}
        {status === "loading" && (
          <div className="w-full min-h-[200px] flex justify-center items-center text-[.85rem] text-white/60 mt-4 border border-white/10 rounded-xl">
            <PulseLoader color="#94a4fd" size={10} />
          </div>
        )}
        {studiosWithAccess?.length > 0 && (
          <div className="text-[.75rem] text-white/40 mt-4 lg:">
            Scroll horizontally to view more, if needed
          </div>
        )}
      </div>
      {/* pagination */}
      {studiosWithAccess?.length > 8 && (
        <div className="flex gap-2 mt-4 justify-end">
          <ReactPaginate
            previousLabel={
              <img
                alt="user"
                src="/images/leftArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            nextLabel={
              <img
                alt="user"
                src="/images/rightArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={
              "h-6 flex items-center gap-2 mt-4 justify-end text-[.95rem] pag"
            }
            activeClassName={"rounded-lg bg-white/10"}
          />
        </div>
      )}
    </>
  );
};

export default OverviewTable;
