import React, { useState } from 'react';
import { PulseLoader } from 'react-spinners';
import SpecialEntryForm from './SpecialEntryForm';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import SpecialEntryEditForm from './SpecialEntryEditForm';
import { db } from 'services/firebase';
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';

const AddSpecialEntry = ({ status, organizerData, user }) => {
  const specialEntry = organizerData?.specialEntryTypes?.tags;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedSpecialEntry, setSelectedSpecialEntry] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const openForm = (specialEntry) => {
    // Set the selected special Tag for editing, or null for adding a new one
    setSelectedSpecialEntry(specialEntry);
    setIsOpen(false); // Close the edit form
    setIsConfirmationOpen(true); // Open the confirmation dialog
  };

  const closeForm = () => {
    setIsOpen(false);
    setIsConfirmationOpen(false); // Close the confirmation dialog when closing the form
    setSelectedSpecialEntry(null); // Clear the selected special Tag when the form is closed
  };

  const continueEdit = () => {
    setIsConfirmationOpen(false); // Close the confirmation dialog
    setIsOpen(true); // Open the edit form
  };

  const openDeleteConfirmation = (selectedSpecialEntry) => {
    setSelectedSpecialEntry(selectedSpecialEntry);
    setDeleteConfirmation(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    const organizerDocRef = doc(db, 'organizers', user?.uid);
    const competitionDocRef = doc(db, 'competitions', user?.uid);

    // Get the current document data
    const organizerDocSnap = await getDoc(organizerDocRef);
    const currentData = organizerDocSnap.data();

    // Remove the selected special tag from organizerData.customTags
    const updatedSpecialTags = currentData.specialEntryTypes?.tags?.filter(
      (tag) =>
        tag?.specialEntryTypeName !== selectedSpecialEntry?.specialEntryTypeName
    );

    // Create an object to update the "scheduling" field
    const newOne = {
      ...currentData.specialEntryTypes,
      tags: updatedSpecialTags,
    };

    // Update the document
    await updateDoc(organizerDocRef, {
      specialEntryTypes: newOne,
    });

    // Update the competition document
    await updateDoc(competitionDocRef, {
      specialEntryTypes: newOne,
    });

    // Remove the selected entry from pricingCategories
    const updatedPricingCategories =
      currentData.pricing.pricingCategories?.filter(
        (category) =>
          category?.[selectedSpecialEntry?.specialEntryTypeName] === undefined
      );

    // Update the document with the new pricingCategories
    await updateDoc(organizerDocRef, {
      pricing: {
        pricingCategories: updatedPricingCategories,
      },
    });

    // Update the competition document with the new pricingCategories
    await updateDoc(competitionDocRef, {
      pricing: {
        pricingCategories: updatedPricingCategories,
      },
    });

    // If the level name has changed, delete the old entries
    if (selectedSpecialEntry?.specialEntryTypeName) {
      // Reference to the "entries" sub-collection
      const entriesRef = collection(db, 'competitions', user?.uid, 'entries');

      // Get all entries with the previous specialEntryTypeName
      const entriesQuery = query(
        entriesRef,
        where('entryType', '==', selectedSpecialEntry?.specialEntryTypeName)
      );

      // Get the documents that match the query
      const entryQuerySnapshot = await getDocs(entriesQuery);

      // Delete each entry that matches the query
      entryQuerySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    }
    // Close the confirmation dialog
    setDeleteConfirmation(false);
    // Set loading to false after the update is complete
    setLoading(false);
  };

  return (
    <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-auto text-white/80">
      <h1 className="w-full border-b pb-2 font-bold border-white/30">
        All Special Entries Values
      </h1>
      {status === 'loading' && (
        <div className="w-full h-[150px] flex justify-center items-center">
          <PulseLoader color="#94a4fd" size={10} />
        </div>
      )}
      <div className="grid grid-cols-3 gap-3">
        {specialEntry?.map((item, index) => (
          <div
            key={index}
            onClick={() => openForm(item)}
            className="bg-[#1c1c1c] rounded-lg p-2 text-sm text-white flex items-center justify-between cursor-pointer"
          >
            <div className="">
              {capitalizeFirstLetter(item?.specialEntryTypeName)}
            </div>
            <div className="flex items-center gap-3">
              <img
                src="/images/icons8-edit.png"
                alt="edit"
                className="w-full h-4 cursor-pointer"
                onClick={() => openForm(item)}
              />
              <img
                src="/images/deleteCat.png"
                alt="delete"
                className="w-full h-4 cursor-pointer"
                onClick={() => openDeleteConfirmation(item)}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5 flex items-center justify-end">
        <button
          onClick={() => setIsOpen(true)}
          className="px-4 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[.85rem] hover:opacity-75 cursor-pointer"
        >
          Add
        </button>
      </div>
      {/* Show confirmation dialog if isConfirmationOpen is true */}
      {isConfirmationOpen && selectedSpecialEntry !== null && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50">
              Confirm Edit Special Entry Types:
              <br />
              <span className="font-bold">{`${selectedSpecialEntry?.specialEntryTypeName}`}</span>
              ?
            </p>

            <p className="text-white/70">
              NOTE: Once you edit this specialEntry it will delete all the
              entries attached with it.
              <br />
              Are you sure you want to proceed with the changes?
            </p>

            <div className="mt-4 w-full text-center">
              <button
                onClick={closeForm}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={continueEdit}
                className="text-[.9rem] px-8 py-2 rounded-md bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Show confirmation dialog if isConfirmationOpen is true for deletion*/}
      {deleteConfirmation && selectedSpecialEntry !== null && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50">
              Confirm Delete Special Entry:
              <br />
              <span className="font-bold">{`${selectedSpecialEntry?.specialEntryTypeName}`}</span>
              ?
            </p>

            <p className="text-white/70">
              NOTE: Once you Delete this SpecialEntry it will delete It from the
              events and all the entries attached to it and NB: this process is
              irreversible.
              <br />
              Are you sure you want to proceed with the changes?
            </p>

            <div className="mt-4 w-full text-center flex items-center justify-center">
              <button
                onClick={() => setDeleteConfirmation(false)}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                onClick={handleDelete}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Delete'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Show Popup if isClassificationOpen is true */}
      {isOpen &&
        (selectedSpecialEntry !== null ? (
          <SpecialEntryEditForm
            specialEntry={selectedSpecialEntry}
            onClose={closeForm}
            user={user}
            organizerData={organizerData}
          />
        ) : (
          <SpecialEntryForm
            onClose={closeForm}
            user={user}
            organizerData={organizerData}
          />
        ))}
    </div>
  );
};

export default AddSpecialEntry;
