/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import {
  collection,
  getDocs,
  // doc,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  // setDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { db } from "services/firebase";
import SpinnerTiny from "utils/SpinnerTiny";
import {
  toggleDropboard,
  toggleSearch,
  toggleNotifsBoard,
  search,
  setCurrentEvent,
  setNotifications,
} from "../../../redux/eventManagement/eventManagement";
import BreadCrumb from "./BreadCrumb";
import SearchResultTray from "./SearchResultTray";
// import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { getUser } from "utils/getUID";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { sendVerification } from "redux/danceStudio/danceStudioSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { useFetchScheduleDocs } from "components/hooks/useFetchScheduleDocs";
import { deleteDuplicateDocs } from "utils/deleteDuplicateDocs";

const Header = () => {
  const { openSearch, openNotifsBoard, notifications } = useSelector(
    (state) => state.eventManagement
  );
  const { sendVerificationPending, sendVerificationSuccess } = useSelector(
    (state) => state.danceStudio
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname;

  function openNotifsOnLargeScreen() {
    dispatch(toggleNotifsBoard());
  }

  //to get competition name
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { status, data } = useFirestoreQueryDoc("organizers", user?.uid);

  const [filterFave, setFilterFave] = useState(false);
  function toggleFave() {
    setFilterFave((prev) => !prev);
  }

  const { event_name } = useParams();

  //to get realtime events details from firestore - (will be moved to redux state later)
  const { data: compData } = useQueryAllDocs("competitions");
  const organizerComp = compData?.filter(
    (item) => item?.compId === user?.uid
  )[0];
  const currentEventRaw = organizerComp?.events?.filter(
    (item) => item?.event_name.replace(/ /g, "-") === event_name
  )[0];

  useEffect(() => {
    if (currentEventRaw?.event_name) {
      dispatch(setCurrentEvent(currentEventRaw));
      deleteDuplicateDocs(user, currentEventRaw);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEventRaw]);

  //to handle event search
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState("");

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    dispatch(search(e.target.value));
    const term = e.target.value;

    const data = organizerComp?.events?.filter((event) =>
      event.event_name.toLowerCase().includes(term?.toLowerCase())
    );
    setSearchResults(data);
  };
  function clearSearch() {
    setSearchTerm("");
    dispatch(toggleSearch());
  }

  //================================================================to listen for notifs
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "notifications"), orderBy("timestamp", "desc")),
      (snapshot) => {
        // const data = snapshot.docs.map((doc) => doc.data());
        const notifications = snapshot.docs.map((doc) => {
          const notification = doc.data();
          return {
            ...notification,
            timestamp: notification.timestamp,
          };
        });
        const myNotifs = notifications?.filter(
          (item) => item?.user_id === user?.uid || item?.for === user?.uid
        );
        dispatch(setNotifications(myNotifs));
      }
    );

    return () => {
      unsubscribe();
    };
  }, [dispatch, user?.uid]);

  const [unreadNotifs, setUnreadNotifs] = useState(false);
  useEffect(() => {
    if (notifications) {
      const unread = notifications?.filter((item) => item?.read === false);
      unread?.length > 0 ? setUnreadNotifs(true) : setUnreadNotifs(false);
    }
  }, [notifications]);

  useEffect(() => {
    const resumeFirestoreUpdates = async () => {
      const firestoreUpdates = localStorage.getItem("firestoreUpdates");

      if (firestoreUpdates && !currentPage?.includes("/schedule")) {
        const affectedItemsForFirestore = JSON.parse(firestoreUpdates);

        // Perform Firestore updates for affected items
        await Promise.all(
          affectedItemsForFirestore.map(async (category) => {
            try {
              const collectionRef = collection(
                db,
                "competitions",
                user?.uid,
                "categories"
              );

              const whereQuery =
                category?.type === "category"
                  ? where("no_id", "==", category.no_id)
                  : where("doc_id", "==", category.doc_id);

              const querySnapshot = query(collectionRef, whereQuery);
              const categoriesSnapshot = await getDocs(querySnapshot);

              if (categoriesSnapshot.docs?.length === 1) {
                const docRef = categoriesSnapshot.docs[0].ref;
                await updateDoc(docRef, { sort_id: category.sort_id });
              } else {
                console.error(
                  "Error: Document not found =>",
                  category?.category_name
                );
              }
            } catch (error) {
              console.error("Error updating category sort_id:", error);
            }
          })
        );
        // Clear localStorage entry after updates are completed
        localStorage.removeItem("firestoreUpdates");
      }
    };

    window.addEventListener("load", resumeFirestoreUpdates);

    return () => {
      window.removeEventListener("load", resumeFirestoreUpdates);
    };
  }, []);

  //===================================================to handle email verification popup for none verified users
  const [currentUser, setCurrentUser] = useState(null);
  const [openMod, setOpenMod] = useState(false);

  useEffect(() => {
    if (user?.uid !== "clH7Gurn66WUeCE8l6Ufa0SYFZW2") {
      async function checkEmailVerification() {
        try {
          const currentUser = await getUser();
          if (currentUser) {
            // User is logged in, you can use the user object here.
            setCurrentUser(currentUser);
          } else {
            console.log("User is not logged in.");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
      checkEmailVerification();
    }
  }, []);

  const [showVerif, setShowVerif] = useState(false);
  useEffect(() => {
    if (currentUser) {
      currentUser?.emailVerified ? setShowVerif(false) : setShowVerif(true);
      currentUser?.emailVerified ? setOpenMod(false) : setOpenMod(true);
    }
  }, [currentUser]);

  // const { data: eventCategories } = useFetchScheduleDocs(
  //   "competitions",
  //   user?.uid,
  //   "categories",
  //   "event_uid",
  //   currentEventRaw?.event_uid
  // );

  // const { status: entryStatus, data: allEntriesRaw } =
  //   useQueryAllSubCollectionDocs("competitions", user?.uid, "entries");
  // const allEntries = allEntriesRaw?.filter(
  //   (itm) =>
  //     itm?.event_uid === currentEventRaw?.event_uid &&
  //     itm?.studio_selected[0]?.toLowerCase() === data?.studioName?.toLowerCase()
  // );

  // function generateUniqueId() {
  //   const timestamp = new Date().getTime().toString();
  //   const randomNum = Math.floor(Math.random() * 900000000000) + 100000000000;
  //   const uniqueId = `${timestamp}${randomNum}`;
  //   return uniqueId.substring(0, 15);
  // }

  // const assignSortId = (array) => {
  //   if (array && Array.isArray(array) && array.length > 0) {
  //     const maxSortId = Math.max(...array.map((obj) => obj?.sort_id || 0));
  //     const newSortId = maxSortId + 1;

  //     return newSortId;
  //   } else {
  //     return 1;
  //   }
  // };

  // useEffect(() => {
  //   if (currentEventRaw && entryStatus === "success") {
  //     const categoryStringsEntr = allEntries?.map((x) => x?.category_name);
  //     const categoryStringsCate = eventCategories?.map((x) => x?.category_name);

  //     const noCategoryStrings = categoryStringsEntr?.filter(
  //       (item) => !categoryStringsCate?.includes(item)
  //     );

  //     console.log("noCategoryStrings", noCategoryStrings);

  //     const removeDuplicates = (strings) => {
  //       const uniqueStrings = Array.from(new Set(strings));
  //       return uniqueStrings;
  //     };

  //     const categoryCollectionRef = collection(
  //       db,
  //       "competitions",
  //       user?.uid,
  //       "categories"
  //     );

  //     // removeDuplicates(noCategoryStrings)?.forEach((string) => {
  //     //   const newId = generateno_id();
  //     //   setDoc(doc(categoryCollectionRef, newId), {
  //     //     category_name: string,
  //     //     sort_id: assignSortId(allCategories),
  //     //     id: generateUniqueId(),
  //     //     is_scratched: false,
  //     //     event_uid: currentEventRaw?.event_uid,
  //     //     type: "category",
  //     //     no_id: newId,
  //     //   });
  //     // });
  //   }
  // }, [currentEventRaw, entryStatus]);

  // function generateno_id() {
  //   const chars =
  //     "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  //   let uid = "";
  //   for (let i = 0; i < 32; i++) {
  //     const randomIndex = Math.floor(Math.random() * chars.length);
  //     uid += chars.charAt(randomIndex);
  //   }
  //   return uid;
  // }

  return (
    <>
      {showVerif && openMod && (
        <div className="w-full h-full fixed top-0 left-0 bg-black/80 flex justify-center py-[100px] px-3 z-[50]">
          {sendVerificationSuccess?.success ? (
            <div className="w-full md:w-[500px] h-fit rounded-xl bg-[#282929] px-4 py-5 flex flex-col gap-4 text-white scale">
              <h2 className="font-bold text-[1.4rem] text-center border-b border-white/80 pb-2">
                Email verification link sent successfully.
              </h2>
              <p className="text-center text-[1.2rem]">
                Check you email inbox or junk/spam to verify.
              </p>
              <p className="text-center text-white/60">
                Note: Verify your email by clicking the link sent to your inbox.
                Refresh this page to dismiss this message permanently after
                successful verification.
              </p>
              <button
                onClick={() => setOpenMod(false)}
                className="w-fit mx-auto px-5 py-1 font-medium rounded-full bg-gray-400 hover:bg-gray-400/60 text-[#1c1c1c] transition-all duration-300"
              >
                Close
              </button>
            </div>
          ) : (
            <div className="w-full md:w-[500px] h-fit rounded-xl bg-[#282929] px-4 py-5 flex flex-col gap-4 text-white scale">
              <h2 className="font-bold text-[1.4rem] text-center border-b border-white/80 pb-2">
                Your email is unverified.
              </h2>
              <p className="text-center text-[1.2rem]">
                Hello{" "}
                {compData
                  ? `${capitalizeFirstLetter(compData?.[0]?.competitionName)}`
                  : "..."}
                , your email is unverified, kindly verify now to continue.
              </p>
              <button
                onClick={() => dispatch(sendVerification())}
                className="w-fit mx-auto px-5 py-1 font-medium flex items-center gap-2 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
              >
                <span className="w-full">
                  {sendVerificationPending ? "Processing..." : "Verify Email"}
                </span>
                {sendVerificationPending && <ButtonLoadingSpinner />}{" "}
              </button>
            </div>
          )}
        </div>
      )}

      {/* desktop ui */}
      {/* desktop ui */}
      {/* desktop ui */}

      <header
        className={`w-full text-white/80 py-6  pr-[32px] ${
          openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
        } ${
          !currentPage?.includes("move-categ")
            ? "sm:pl-[232px] lg:pl-[282px]"
            : "sm:pl-[30px]"
        }
        bg-[#1c1c1c] fixed left-0 top-0 border-b-2 border-[#282929] hidden lg:flex items-center z-10 transition-all duration-700`}
      >
        {searchTerm && (
          <SearchResultTray
            currentPage={currentPage}
            openNotifsBoard={openNotifsBoard}
            searchResults={searchResults}
            searchTerm={searchTerm}
            clearSearch={clearSearch}
          />
        )}
        {!currentPage.includes("/top-score") &&
          !currentPage.includes("/stage-manager") &&
          !currentPage.includes("/emcee") && (
            <div className="flex lg:gap-4 md:gap-3 items-center mr-auto">
              {currentPage?.includes("event-selection") && (
                <>
                  {filterFave ? (
                    <img
                      onClick={toggleFave}
                      alt="user"
                      src="/images/star-filled.png"
                      className="lg:w-6 lg:h-6 md:w-4 md:h-4 cursor-pointer"
                    />
                  ) : (
                    <img
                      onClick={toggleFave}
                      alt="user"
                      src="/images/Star.png"
                      className="lg:w-6 lg:h-6 md:w-4 md:h-4 cursor-pointer"
                    />
                  )}
                </>
              )}

              {/* BreadCrumb path trail */}
              <BreadCrumb />
            </div>
          )}

        <div className="h-[30px] flex mr-4 ml-auto">
          <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
            <img
              alt="user"
              src="/images/Search.png"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <input
            type="text"
            placeholder="Search Events"
            value={searchTerm}
            onChange={handleSearch}
            className="md:w-[130px] lg:w-[160px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
          />
        </div>

        <div className="flex gap-4 items-center">
          {!openNotifsBoard && (
            <div
              onClick={() => openNotifsOnLargeScreen()}
              className="flex gap-4 items-center md:border lg:border-0 border-[#94a4fd]/60 px-2 py-1 rounded-full md:bg-[#94a4fd]/20 lg:bg-transparent cursor-pointer"
            >
              <div className="relative w-fit h-fit">
                {unreadNotifs && (
                  <div className="w-3 h-3 bg-red-500 rounded-full absolute top-[-2px] right-[-2px]"></div>
                )}
                <img
                  alt="user"
                  src="/images/Bell.png"
                  className="lg:w-6 lg:h-6 md:w-4 md:h-4 cursor-pointer"
                />
              </div>
            </div>
          )}
          {openNotifsBoard && (
            <div
              onClick={() => openNotifsOnLargeScreen()}
              className="w-6 h-6 flex justify-center items-center bg-white/80 rounded-full cursor-pointer"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-4 h-4"
              />
            </div>
          )}
        </div>
      </header>

      {/* small screens ui */}
      {/* small screens ui */}
      {/* small screens ui */}

      <header className="w-full text-white/80 py-6 md:pl-[225px] lg:pl-[282px] pr-[25px] lg:pr-[332px] bg-[#1c1c1c] fixed left-0 top-0 border-b-2 border-[#282929] hidden md:flex lg:hidden items-center z-10">
        {searchTerm && (
          <SearchResultTray
            currentPage={currentPage}
            openNotifsBoard={openNotifsBoard}
            searchResults={searchResults}
            searchTerm={searchTerm}
            clearSearch={clearSearch}
          />
        )}
        <div className="flex gap-2 items-center mr-auto">
          {currentPage?.includes("event-selection") && (
            <img
              alt="user"
              src="/images/Star.png"
              className="w-4 h-4 cursor-pointer"
            />
          )}

          {/* BreadCrumb path trail */}
          {!openSearch && <BreadCrumb />}
        </div>

        {/* <div className="h-[30px] flex mr-4">
          <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
            <img
              alt="user"
              src="/images/Search.png"
              className="w-5 h-5 cursor-pointer"
            />
          </div>
          <input
            type="text"
            placeholder="Search Events"
            value={searchTerm}
            onChange={handleSearch}
            className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
          />
        </div> */}

        {currentPage?.includes("/event") && openSearch && (
          <div className="h-[30px] flex mr-4 ml-auto">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Events"
              value={searchTerm}
              onChange={handleSearch}
              className="sm:w-[200px] w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1 placeholder:text-[.75em]"
            />
          </div>
        )}

        <div className="flex gap-3 items-center">
          <img
            onClick={() => dispatch(toggleSearch())}
            alt="user"
            src={
              openSearch
                ? "/images/icons8-close-white-50.png"
                : "/images/icons8-search-64.png"
            }
            className={`${openSearch ? "w-4 h-4" : "w-6 h-6"} cursor-pointer`}
          />

          <div
            onClick={() => dispatch(toggleNotifsBoard())}
            className="w-7 h-7 flex justify-center items-center border border-[#94a4fd]/60 rounded-full cursor-pointer relative"
          >
            {unreadNotifs && (
              <div className="w-3 h-3 bg-red-500 rounded-full absolute top-[-2px] right-[-2px]"></div>
            )}
            <img
              alt="user"
              src="/images/Bell.png"
              className="w-4 h-4 md:w-4 md:h-4"
            />
          </div>
        </div>
      </header>

      {/* Mobile UI */}
      {/* Mobile UI */}
      {/* Mobile UI */}

      <header className="w-full text-white/80 py-4 px-5 sm:pl-[235px] bg-[#1c1c1c] fixed left-0 top-0 border-b-2 border-[#282929] flex md:hidden items-center justify-between z-10 transition-all duration-300">
        {searchTerm && (
          <SearchResultTray
            currentPage={currentPage}
            openNotifsBoard={openNotifsBoard}
            searchResults={searchResults}
            searchTerm={searchTerm}
            clearSearch={clearSearch}
          />
        )}
        <img
          onClick={() => dispatch(toggleDropboard())}
          alt="menu"
          src="/images/Sidebar.png"
          className={`w-7 h-8 cursor-pointer sm:hidden ${
            !openSearch ? "mr-6" : "mr-auto"
          }`}
        />

        {!openSearch &&
          (status === "success" ? (
            <h1
              onClick={() => navigate("/organizer/event-selection")}
              className="mx-auto block sm:hidden text-white text-[.85rem] max-w-[50%] whitespace-nowrap truncate"
            >
              {data?.competitionName}
            </h1>
          ) : status === "loading" ? (
            <SpinnerTiny />
          ) : null)}

        {!openSearch && (
          <div className="mr-auto hidden sm:block">
            <BreadCrumb />
          </div>
        )}

        {openSearch && (
          <div className="h-[30px] flex sm:ml-auto mx-3 slideD">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Events"
              value={searchTerm}
              onChange={handleSearch}
              className="sm:w-[200px] w-full text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
            />
          </div>
        )}

        <div className="w-[70px] flex gap-2 items-center justify-end">
          {!openSearch && (
            <img
              onClick={() => dispatch(toggleSearch())}
              alt="user"
              src="/images/icons8-search-64.png"
              className={`${openSearch ? "w-4 h-4" : "w-6 h-6"} cursor-pointer`}
            />
          )}
          {openSearch && !searchTerm && (
            <img
              onClick={() => dispatch(toggleSearch())}
              alt="user"
              src="/images/icons8-close-white-50.png"
              className={`${openSearch ? "w-4 h-4" : "w-6 h-6"} cursor-pointer`}
            />
          )}

          <div
            onClick={() => dispatch(toggleNotifsBoard())}
            className="w-7 h-7 flex justify-center items-center border border-[#94a4fd]/60 rounded-full cursor-pointer relative"
          >
            {unreadNotifs && (
              <div className="w-3 h-3 bg-red-500 rounded-full absolute top-[-2px] right-[-2px]"></div>
            )}
            <img
              alt="user"
              src="/images/Bell.png"
              className="w-4 h-4 md:w-4 md:h-4"
            />
          </div>
        </div>
      </header>
      {/* <div className="w-full md:w-[500px] bg-black p-5 border border-white/30 rounded-xl text-white fixed shadow-xl bottom-10 right-10 z-[999]">
        The schedule page is undergoing maintenance! Please check back later!
      </div> */}
    </>
  );
};

export default Header;
