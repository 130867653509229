import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSpecialEntry,
  removeSpecialEntry,
} from '../../../redux/organizerRegistration/organizerSlices';
import RemoveImg from '../../../assets/remove.png';

const SpecialEntryTypeSchema = Yup.object().shape({
  specialEntryTypeName: Yup.string()
    .optional()
    .matches(/^[^0-9]*$/, 'specialEntryTypeName should not contain numbers'),
  specialMinDancers: Yup.number()
    .optional()
    .positive('Must be positive')
    .integer('Must be an integer')
    .max(
      Yup.ref('specialMaxDancers'),
      'Min dancers must be less than Max dancers'
    ),
  specialMaxDancers: Yup.number()
    .optional()
    .positive('Must be positive')
    .integer('Must be an integer'),
  specialMaxTime: Yup.number()
    .optional()
    .positive('Must be positive')
    .test(
      'is-valid-time',
      'required',
      (value) => value === 0 || /^\d+(\.\d+)?$/.test(value)
    ),
});

const SpecialEntry = () => {
  const dispatch = useDispatch();
  const { specialEntryTypes } = useSelector((state) => state.persistedReducer);

  const [specialTag, setSpecialTag] = useState({
    age: false,
    classification: false,
    genre: false,
    entry_type: false,
    scored: false,
  });

  const handleSpecialTagChange = (event) => {
    const { id, checked } = event.target;
    setSpecialTag((prevTag) => ({
      age: id === 'age' ? checked : prevTag.age,
      classification:
        id === 'classification' ? checked : prevTag.classification,
      genre: id === 'genre' ? checked : prevTag.genre,
      entry_type: id === 'entry_type' ? checked : prevTag.entry_type,
      scored: id === 'scored' ? checked : prevTag.scored,
    }));
  };

  const formik = useFormik({
    initialValues: {
      specialEntryTypeName: '',
      specialMinDancers: '',
      specialMaxDancers: '',
      specialMaxTime: '',
    },
    validationSchema: SpecialEntryTypeSchema,
    onSubmit: (values, { resetForm }) => {
      // Convert decimal value to the desired format (hh:mm:ss)
      const hours = Math.floor(values.specialMaxTime / 60);
      const minutes = Math.floor(values.specialMaxTime % 60);
      const seconds = Math.floor((values.specialMaxTime % 1) * 60);
      values.specialMaxTime = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      const newEntry = {
        specialEntryTypeName: values.specialEntryTypeName.toLowerCase(),
        specialMinDancers: values.specialMinDancers,
        specialMaxDancers: values.specialMaxDancers,
        specialMaxTime: values.specialMaxTime,
        tags: specialTag, // Assign the specialTag state to the tags property
      };

      dispatch(addSpecialEntry(newEntry));
      resetForm();

      // Reset the specialTag state
      setSpecialTag({
        age: false,
        classification: false,
        genre: false,
        entry_type: false,
        scored: false,
      });
    },
  });

  // convert the time from hh:mm:ss to decimal
  const convertTimeToDecimal = (maxTime) => {
    // Parse hours, minutes and seconds from maxTime
    let [hours, minutes, seconds] = maxTime?.split(':')?.map(Number);
    // Convert hours and minutes to minutes
    let totalMinutes = hours * 60 + minutes;

    if (totalMinutes < 60) {
      // If less than 60 minutes, don't show the hours
      return `${minutes?.toString().padStart(2, '0')}:${seconds
        ?.toString()
        .padStart(2, '0')}`;
    } else {
      // If 60 minutes or more, show the hours
      return `${hours?.toString().padStart(2, '0')}:${minutes
        ?.toString()
        .padStart(2, '0')}:${seconds?.toString().padStart(2, '0')}`;
    }
  };

  // function to remove added value
  const handleRemove = (index) => {
    dispatch(removeSpecialEntry(index));
  };

  return (
    <div className="w-full mt-8 form-control">
      <label className="label w-[75%]">
        <span className="mb-2 text-base font-medium label-text text-[#696F79]">
          What SPECIAL entry types does your competition have, how many dancers
          are required for it and in minutes, what the time limit? Are age,
          classification and/or genre taken into account for them? Is the entry
          scored?
        </span>
      </label>
      <div className="flex flex-row flex-wrap items-baseline px-1 mb-[0.75rem]">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col w-full lg:w-[75%] md:w-[75%] mr-4"
        >
          <div className="flex flex-col md:flex-row items-center justify-between w-full gap-3">
            <div className="w-full flex flex-col gap-2">
              <input
                name="specialEntryTypeName"
                placeholder="Entry Type Name"
                onChange={formik.handleChange}
                value={formik.values.specialEntryTypeName}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.specialEntryTypeName &&
                  formik.touched.specialEntryTypeName &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {formik.errors.specialEntryTypeName &&
                formik.touched.specialEntryTypeName && (
                  <span className="text-xs text-red-500">
                    {formik.errors.specialEntryTypeName}
                  </span>
                )}
            </div>
            <div className="flex flex-col gap-2 md:w-[33%] xs:w-full">
              <input
                type="number"
                name="specialMinDancers"
                placeholder="Min.#"
                onChange={formik.handleChange}
                value={formik.values.specialMinDancers}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.specialMinDancers &&
                  formik.touched.specialMinDancers &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {
                formik.errors.specialMinDancers &&
                  formik.touched.specialMinDancers && (
                    <span className="text-xs text-red-500">
                      {formik.errors.specialMinDancers}
                    </span>
                  ) // check if the form is valid before submitting
              }
            </div>
            <div className="flex flex-col gap-2 md:w-[33%] xs:w-full">
              <input
                type="number"
                name="specialMaxDancers"
                placeholder="Max.#"
                onChange={formik.handleChange}
                value={formik.values.specialMaxDancers}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.specialMaxDancers &&
                  formik.touched.specialMaxDancers &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {
                formik.errors.specialMaxDancers &&
                  formik.touched.specialMaxDancers && (
                    <span className="text-xs text-red-500">
                      {formik.errors.specialMaxDancers}
                    </span>
                  ) // check if the form is valid before submitting
              }
            </div>
            <div className="flex flex-col gap-2 md:w-[33%] xs:w-full">
              <input
                type="number"
                name="specialMaxTime"
                placeholder="Time"
                onChange={formik.handleChange}
                value={formik.values.specialMaxTime}
                className={`input input-bordered border-[#959FB1] h-[38px] w-[100%] text-sm font-medium text-[#9FA8B8] dark:text-white mr-[7px] placeholder-[#8692A6]  ${
                  formik.errors.specialMaxTime &&
                  formik.touched.specialMaxTime &&
                  'input-error border-[#fc8181]'
                }`}
              />
              {
                formik.errors.specialMaxTime &&
                  formik.touched.specialMaxTime && (
                    <span className="text-xs text-red-500">
                      {formik.errors.specialMaxTime}
                    </span>
                  ) // check if the form is valid before submitting
              }
            </div>
          </div>
          <div className="w-full mt-4 md:mt-[1rem] flex md:flex-row flex-wrap justify-start gap-3 xs:flex-col">
            <div className="flex items-center xs:mx-4">
              <input
                type="checkbox"
                id="age"
                className="mr-2 w-4 h-4"
                checked={specialTag.age}
                onChange={handleSpecialTagChange}
              />
              <label
                htmlFor="age"
                className="text-[#696F79] text-base font-medium"
              >
                Age
              </label>
            </div>
            <div className="flex items-center mx-4">
              <input
                type="checkbox"
                id="classification"
                className="mr-2 w-4 h-4"
                checked={specialTag.classification}
                onChange={handleSpecialTagChange}
              />
              <label
                htmlFor="classification"
                className="text-[#696F79] text-base font-medium"
              >
                Classification
              </label>
            </div>
            <div className="flex items-center mx-4">
              <input
                type="checkbox"
                id="genre"
                className="mr-2 w-4 h-4"
                checked={specialTag.genre}
                onChange={handleSpecialTagChange}
              />
              <label
                htmlFor="genre"
                className="text-[#696F79] text-base font-medium"
              >
                Genre
              </label>
            </div>
            <div className="flex items-center mx-4">
              <input
                type="checkbox"
                id="entry_type"
                className="mr-2 w-4 h-4"
                checked={specialTag.entry_type}
                onChange={handleSpecialTagChange}
              />
              <label
                htmlFor="entry_type"
                className="text-[#696F79] text-base font-medium"
              >
                Entry Type
              </label>
            </div>
            <div className="flex items-center mx-4">
              <input
                type="checkbox"
                id="scored"
                className="mr-2 w-4 h-4"
                checked={specialTag.scored}
                onChange={handleSpecialTagChange}
              />
              <label
                htmlFor="scored"
                className="text-[#696F79] text-base font-medium"
              >
                Scored
              </label>
            </div>

            <button
              type="submit"
              // check if the form is valid before submitting
              className="max-h-[3rem] bg-[#1565D8] rounded-[5px] text-white capitalize h-[31px] px-[4rem] ml-[2rem] md:ml-4 md:px-9 text-[1rem]"
            >
              add
            </button>
          </div>
        </form>
      </div>
      <div className="flex flex-row flex-wrap w-fit justify-start items-center py-2 rounded-xl cursor-pointer">
        {specialEntryTypes?.tags?.map((type, index) => (
          <div
            key={index}
            className="flex flex-row flex-wrap w-fit mb-[8px] mr-[10px] bg-[#FFE600] items-center p-[10px] py-2 rounded-md cursor-pointer"
          >
            <span className="text-sm font-medium text-[#8692A6] ">
              {`${type.specialEntryTypeName}, ${type.specialMinDancers}-${
                type.specialMaxDancers
              } dancers, ${convertTimeToDecimal(type.specialMaxTime)} ${
                type?.tags?.age ? 'Age' : ''
              } ${type?.tags?.genre ? 'Genre' : ''} ${
                type?.tags?.scored ? 'Scored' : ''
              } ${type?.tags?.entry_type ? 'Entry Type' : ''}
               ${type?.tags?.classification ? 'Classification' : ''}`}
            </span>
            <img
              src={RemoveImg}
              alt="remove"
              className="h-4 w-4 ml-[10px]"
              onClick={() => handleRemove(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SpecialEntry;
