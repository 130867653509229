import React from "react";
import { useNavigate } from "react-router-dom";
import BackIcon from "../../assets/arrow_back.png";
import ImgWrap from "../UI/ImgWrapper";
import Container from "components/UI/Container";

const PasswordResetConfirmation = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-row flex-wrap h-[100vh]">
      <div className="lg:w-[40%] lg:flex hidden">
        {/* Left Components With Background Image */}
        <ImgWrap className="bg-[url('../public/Frame9.png')]" />
      </div>
      <div className="lg:w-[60%] w-full">
        {/* The forgot password right content is located here */}
        <Container className="flex flex-wrap">
          <div className="flex flex-row justify-between w-full">
            <div
              className="flex flex-row items-center cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                src={BackIcon}
                className="w-[9.73px] h-[16.5px] mr-[14px]"
                alt="backIcon"
              />
              <span className="text-base text-[#8692A6]">Back</span>
            </div>
          </div>

          <div className="lg:pl-[63px] md:pl-[63px] pt-[14%]">
            <>
              <h1 className="text-3xl font-bold text-[#000000] dark:text-white mb-7">
                Password Reset Successful!
              </h1>
              <p className="text-lg font-normal text-[#8692A6]">
                You can now sign in with your new password.
              </p>
            </>
            <div className="items-center mt-10 form-control">
              <button
                onClick={() => {
                  navigate("/");
                }}
                className="h-[3rem] bg-[#1565D8] hover:bg-[#1565D8]/70 rounded-[5px] text-white capitalize w-80 flex justify-between items-center px-4 text-center"
              >
                <span className="w-full">Back To Home</span>
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default PasswordResetConfirmation;
