/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import RightSidebar from "./components/RightSidebar";
import {
  getEvents,
  toggleStudioVisibility,
} from "../../redux/eventManagement/eventManagement";
import { useDispatch } from "react-redux";
import ScrollToTop from "./ScrollToTop";
import { useEffect } from "react";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { PulseLoader } from "react-spinners";
import AddEventContainer from "./components/AddEventContainer";

const StudioCodeGlobal = () => {
  const {
    addEvent,
    deleteEventSuccess,
    editEventSuccess,
    addToFaveSuccess,
    removeFromFaveSuccess,
    openNotifsBoard,
  } = useSelector((state) => state.eventManagement);

  //getting the user ID from auth
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEvents(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteEventSuccess,
    editEventSuccess,
    addToFaveSuccess,
    removeFromFaveSuccess,
  ]);

  //=====================================================================================to get realtime events from firestore
  const { status, data } = useQueryAllDocs("competitions");
  const organizerComp = data?.filter((item) => item?.compId === user?.uid)[0];
  const realtimeEvents = organizerComp?.events;
  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}
      {!addEvent && (
        <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[310px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
          <div className="mb-auto">
            {/* Heading */}

            <div className="w-full p-3 md:p-5 md:max-w-[85%] lg:max-w-[70%] md:min-w-[450px] min-h-[200px] bg-[#282929] rounded-lg flex flex-col gap-6 mb-6 text-white/80">
              <h1 className="w-full border-b pb-2 font-bold border-white/30">
                Event Studio Code & Name Visibility (Global)
              </h1>

              <div className="w-full flex flex-col gap-3">
                <div className="w-full flex justify-between items-center px-2">
                  <p>Event</p>
                  <p>Visibility</p>
                </div>

                {status === "loading" && (
                  <div className="w-full h-[150px] flex justify-center items-center">
                    <PulseLoader color="#94a4fd" size={10} />
                  </div>
                )}
                {realtimeEvents?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="w-full flex justify-between items-center bg-white/10 p-3 rounded-lg text-[.85rem]"
                    >
                      <p>{item?.event_name}</p>
                      <button
                        onClick={() =>
                          dispatch(
                            toggleStudioVisibility({
                              uid: user?.uid,
                              event_uid: item?.event_uid,
                            })
                          )
                        }
                        // disabled={updateJudgeSettingsPending}
                        className={`px-4 rounded-full text-[.85rem] hover:opacity-75 ${
                          item?.studio_visibility
                            ? "bg-red-500 text-white"
                            : "bg-[#94a4fd] text-black"
                        }`}
                      >
                        {item?.studio_visibility ? "Hide" : "Show"}
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default StudioCodeGlobal;
