/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import { collection, onSnapshot, query } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { db } from "services/firebase";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import removeDuplicates from "utils/removeDuplicates";

const TopScorer = () => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { data: compData } = useFirestoreQueryDoc("competitions", user?.uid);
  const { event_name } = useParams();

  // console.log("compData", compData);

  const currentEvent = compData?.events?.filter(
    (item) => item?.event_name?.replace(/ /g, "-") === event_name
  )[0];

  const trueGenreKeys = Object.keys(compData?.danceGenres || {})
    .filter((key) => compData?.danceGenres[key] === true)
    .map((item) => item.toLowerCase());

  // console.log("trueGenreKeys", trueGenreKeys);

  const startTime = moment(currentEvent?.days[0].startTime)
    .local()
    .format("MMM D");

  const endTime = moment(
    currentEvent?.days[currentEvent?.days?.length - 1].endTime
  )
    .local()
    .format("MMM D");

  const { status, data: allCategories } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "categories"
  );

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const eventEntries = allEntries?.filter(
    (each) => each?.event_uid === currentEvent?.event_uid
  );

  // console.log("eventEntries", eventEntries);

  //======================================================to get scores
  const [scoresData, setScoresData] = useState([]);
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "competitions", user?.uid, "scores")),
      (snapshot) => {
        const data = snapshot.docs.map((doc) => doc.data());
        setScoresData(data);
      }
    );

    return () => {
      unsubscribe();
    };
  }, []);

  const eventScores = scoresData?.filter(
    (item) => item?.event_name === currentEvent?.event_name
  );

  const calculateTotalScore = (entry) => {
    const scores = eventScores?.filter(
      (item) =>
        item?.doc_id === entry?.doc_id &&
        item?.event_name === currentEvent?.event_name
    );

    const totalScore = scores?.reduce(
      (sum, score) => sum + Number(score?.total || 0),
      0
    );
    const averageScore = totalScore / scores?.length;

    const truncatedAverageScore = parseFloat(averageScore.toFixed(2));

    return isNaN(truncatedAverageScore) ? "- -" : truncatedAverageScore;
  };

  const calculateHighestScore = (categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    let highestScore = 0;

    filteredEntries?.forEach((entry) => {
      const scores = eventScores?.filter(
        (item) =>
          item?.doc_id === entry?.doc_id &&
          item?.event_name === currentEvent?.event_name
      );

      const totalScoreRaw = scores?.reduce(
        (sum, score) => sum + Number(score?.total || 0),
        0
      );

      const totalScore = totalScoreRaw / scores?.length;

      if (totalScore > highestScore) {
        highestScore = totalScore;
      }
    });

    return highestScore;
  };

  const calculatePosition = (entry, categoryEntries) => {
    const filteredEntries = categoryEntries?.filter(
      (x) => x?.is_scratched === false
    );
    const highestScore = calculateHighestScore(filteredEntries);
    const entryScore = calculateTotalScore(entry);

    const sortedScores = filteredEntries
      .map((entry) => calculateTotalScore(entry))
      .sort((a, b) => b - a);

    const position = removeDuplicates(sortedScores)?.indexOf(entryScore) + 1;

    if (!highestScore || entryScore === "- -") {
      return "- -";
    } else if (entryScore === highestScore) {
      return 1;
    } else if (position === 1) {
      return 1;
    } else if (position === 2) {
      return 2;
    } else if (position === 3) {
      return 3;
    } else {
      return position;
    }
  };

  return (
    <>
      <div className="w-full min-h-screen text-white/80 px-5 pt-[50px] bg-[#1c1c1c] flex flex-col transition-all duration-700">
        {/* section holding the awards and entries */}
        <div className="w-full text-[.9em] pt-3 mb-20 overflow-x-auto overflow-y-hidden">
          {status === "loading" ? (
            <div className="w-full h-[200px] flex justify-center items-center gap-6 mb-auto text-white/80">
              <PulseLoader color="#94a4fd" size={10} />
            </div>
          ) : (
            <h2 className="text-center mb-4">
              {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
              {currentEvent?.year.split(" to ")[0]} |{" "}
              {currentEvent?.days?.length === 1
                ? `${startTime}, ${currentEvent?.year}`
                : `${startTime} - ${endTime} ${currentEvent?.year}`}
            </h2>
          )}
          <div className="lg:min-w-[1200px]">
            <div className="w-[70%] p-3 bg-[#282929] my-4 mx-auto rounded-lg md:text-center font-bold">
              <h2 className="text-3xl">TOP SCORES BY GENRE</h2>
            </div>
            <p className="text-center">
              In No Particular Order <br />
              Updated After Each Awards Sessions
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-4 gap-5 overflow-x-hidden">
              {trueGenreKeys?.map((item, index) => {
                //=================================================================entrycount calc
                const eventCategories = allCategories?.filter(
                  (cat) => cat?.event_uid === currentEvent?.event_uid
                );
                let sortedCategories = eventCategories?.sort(
                  (a, b) => a?.sort_id - b?.sort_id
                );

                const categories = sortedCategories?.filter(
                  (l) => l?.type === "category"
                );

                const allEntriesWithoutLocked = arrangeEntriesByCategories(
                  categories,
                  allEntries
                )?.filter((k) => k?.locked_entry === false);

                const allEntriesWithLocked = arrangeEntriesByCategories(
                  categories,
                  allEntries
                );

                // console.log("allEntriesWithLocked", allEntriesWithLocked);

                function entryCount(itm) {
                  const allEntriesBeforeLocked = allEntriesWithLocked
                    ?.slice(
                      0,
                      findIndexByProperty(
                        allEntriesWithLocked,
                        "doc_id",
                        itm?.doc_id
                      )
                    )
                    ?.filter((m) => m?.locked_entry === false);

                  if (itm?.locked_entry) {
                    return `${
                      allEntriesBeforeLocked?.length === 0
                        ? 1
                        : allEntriesBeforeLocked?.length
                    }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
                  } else {
                    return (
                      findIndexByProperty(
                        allEntriesWithoutLocked,
                        "doc_id",
                        itm?.doc_id
                      ) + 1
                    );
                  }
                }

                //=================================================
                const genreEntries = eventEntries?.filter(
                  (itm) => itm?.genre.toLowerCase() === item.toLowerCase()
                );

                // const sortedEntries = genreEntries?.sort((a, b) => entryCount(a) - entryCount(b));

                const rankedEntries = genreEntries?.sort(
                  (a, b) =>
                    calculatePosition(a, genreEntries) -
                    calculatePosition(b, genreEntries)
                );

                const topEntries = rankedEntries?.slice(0, 10);

                // console.log("topEntries", topEntries);
                return (
                  <div key={index} className="w-full">
                    <div className="w-full p-3 bg-[#282929] my-4 rounded-lg md:text-center font-bold">
                      <h2 className="text-xl uppercase">TOP {item}</h2>
                    </div>
                    <div
                      className={`w-full flex flex-col flex-wrap text-[.75rem] sm:text-[.85rem] ${
                        topEntries?.length === 0 ? "h-[150px]" : "h-[240px]"
                      }`}
                    >
                      {topEntries?.length === 0 && (
                        <div className="w-full h-full border border-white/20 rounded-lg flex justify-center items-center bg-[#282929]">
                          No entries with this genre..
                        </div>
                      )}
                      {topEntries?.map((itm, idx) => {
                        return (
                          <div
                            key={idx}
                            className="w-1/2 flex gap-4 border-b border-white/10 p-3"
                          >
                            <p className="">#{entryCount(itm)}</p>
                            <p className="whitespace-nowrap">
                              {capitalizeFirstLetter(itm?.entry_name)}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopScorer;
