import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { regEmcee, clearSuccess } from "redux/emcee/emceeSlice";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import ImgWrap from "../UI/ImgWrapper";

const EmceeRegistration = () => {
  const { regEmceePending, regEmceeSuccess } = useSelector(
    (state) => state.emcee
  );

  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    formCompleted: false,
    role: "emcee",
  });

  // const [termsErr, settermsErr] = useState("");
  const [formErr, setformErr] = useState("");

  useEffect(() => {
    setformErr("");
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInputChange(event) {
    // settermsErr("");
    setformErr("");
    dispatch(clearSuccess());
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
      role: "emcee",
    }));

    if (
      formData.first_name &&
      formData.last_name &&
      formData.email &&
      formData.password
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        formCompleted: true,
        role: "emcee",
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        formCompleted: false,
      }));
    }
  }

  // console.log("formData", formData);

  function handleRegister(e) {
    e.preventDefault();
    if (
      formData?.first_name &&
      formData?.last_name &&
      formData?.email &&
      formData?.password
    ) {
      dispatch(regEmcee(formData));
    } else if (
      !formData?.first_name ||
      !formData?.last_name ||
      !formData?.email ||
      !formData?.password
    ) {
      // settermsErr("");
      setformErr("Please fill all fields");
    }
  }

  function toggleShow() {
    setShow((prev) => !prev);
  }
  // console.log(regEmceeSuccess);

  const navigate = useNavigate();
  useEffect(() => {
    if (regEmceeSuccess?.success) {
      navigate("/emcee-login");
      dispatch(clearSuccess());
      setformErr("");
    } else if (!regEmceeSuccess?.success && regEmceeSuccess !== null) {
      setformErr("Email already in use");
    } else {
      setformErr("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regEmceeSuccess]);

  const [passwordShown, setPasswordShown] = useState(false);

  const passwordVisibility = () => {
    setPasswordShown((prev) => !prev);
  };

  return (
    <>
      <div className="flex flex-row flex-wrap h-[100vh]">
        {/* The left content holds logo and background image */}
        <div className="lg:w-[40%] lg:flex hidden">
          <ImgWrap className="bg-[url('../public/emcee.png')]" />
        </div>

        {/* the container wrapper */}
        <div className="lg:w-[60%] w-full">
          <main className="w-full h-screen overflow-y-auto bg-[#1C1C1C] p-5 flex justify-center items-center relative text-white/60">
            <p className="absolute top-3 md:top-5 md:right-5 right-1">
              Already have an account?{" "}
              <Link to="/emcee-login">
                <span className="text-[#1565D8] font-bold hover:underline">
                  Login
                </span>
              </Link>
            </p>
            <div className="w-full md:w-[550px] min-h-[500px] flex flex-col">
              <h1 className="text-white text-[1.5rem] md:text-[2rem] font-medium">
                Emcee Registration
              </h1>
              <form className="mt-8">
                <div className="mb-4">
                  <label htmlFor="first_name" className="text-white/50">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    onChange={handleInputChange}
                    placeholder="E.g John"
                    className={`w-full bg-white p-4 rounded-md outline-blue-500 text-black placeholder:text-black/50 mt-2 `}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="last_name" className="text-white/50">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    onChange={handleInputChange}
                    placeholder="E.g Doe"
                    className={`w-full bg-white p-4 rounded-md outline-blue-500 text-black placeholder:text-black/50 mt-2 `}
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="text-white/50">
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    onChange={handleInputChange}
                    placeholder="E.g johndoe@email.com"
                    className={`w-full bg-white p-4 rounded-md outline-blue-500 text-black placeholder:text-black/50 mt-2 `}
                  />
                </div>
                <div className="mb-4 relative">
                  <label htmlFor="password" className="text-white/50">
                    Password
                  </label>
                  <input
                    type={show ? "text" : "password"}
                    id="password"
                    onChange={handleInputChange}
                    className={`w-full bg-white p-4 rounded-md outline-blue-500 text-black placeholder:text-black/50 mt-2 `}
                  />
                  <p
                    onClick={toggleShow}
                    className="text-blue-500 absolute bottom-4 right-3 cursor-pointer"
                  >
                    {passwordShown ? (
                      <span
                        className="material-symbols-outlined flex cursor-pointer"
                        onClick={passwordVisibility}
                      >
                        <i className="fa-regular fa-eye"></i>
                      </span>
                    ) : (
                      <span
                        className="material-symbols-outlined flex cursor-pointer"
                        onClick={passwordVisibility}
                      >
                        <i className="fa-regular fa-eye-slash"></i>
                      </span>
                    )}
                  </p>
                </div>
                {formErr && <p className="text-red-500">{formErr}</p>}
                <button
                  onClick={handleRegister}
                  disabled={regEmceePending}
                  className={`p-4 w-[70%] min-w-fit bg-blue-600 text-white rounded-md mt-6 flex justify-between items-center`}
                >
                  <span className="w-full">
                    {regEmceePending ? "Processing..." : "Submit"}
                  </span>
                  {regEmceePending && <ButtonLoadingSpinner />}
                </button>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default EmceeRegistration;
