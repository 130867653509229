import { collection, getDocs, deleteDoc } from "firebase/firestore";
import { db } from "services/firebase";

export async function deleteDuplicateDocs(user, currentEvent) {
  try {
    const collectionRef = collection(
      db,
      "competitions",
      user?.uid,
      "categories"
    );

    const categoriesSnapshot = await getDocs(collectionRef);
    const categoryNames = new Set();

    // Array to store delete promises
    const deletePromises = [];

    categoriesSnapshot.forEach((doc) => {
      const data = doc.data();
      if (
        data.event_uid === currentEvent?.event_uid &&
        data?.type === "category"
      ) {
        const categoryName = data.category_name;
        if (categoryNames.has(categoryName)) {
          // Found a duplicate, add delete promise to the array
          deletePromises.push(deleteDoc(doc.ref));
        } else {
          // Add the category name to the set to check for duplicates
          categoryNames.add(categoryName);
        }
      }
    });

    // Wait for all delete promises to resolve
    await Promise.all(deletePromises);

    console.log({ no_dups_success: true });
  } catch (error) {
    console.error(error);
  }
}

// export async function deleteDuplicateDocs(user, currentEvent) {
//   try {
//     const collectionRef = collection(
//       db,
//       "competitions",
//       user?.uid,
//       "categories"
//     );

//     const categoriesSnapshot = await getDocs(collectionRef);
//     const categoryNames = new Set();

//     categoriesSnapshot.forEach(async (doc) => {
//       const data = doc.data();
//       if (
//         data.event_uid === currentEvent?.event_uid &&
//         data?.type === "category"
//       ) {
//         const categoryName = data.category_name;
//         if (categoryNames.has(categoryName)) {
//           // Found a duplicate, delete this document
//           await deleteDoc(doc.ref);
//         } else {
//           // Add the category name to the set to check for duplicates
//           categoryNames.add(categoryName);
//         }
//       }
//     });

//     console.log({ no_dups_success: true });
//   } catch (error) {
//     console.error(error);
//   }
// }
