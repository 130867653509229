// import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import localStorage from 'redux-persist/es/storage';
// import { setCurrentComp } from "redux/judge/judgeSlice";

const EmceeCompCard = ({ item, index }) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  function linkToEvent() {
    navigate(`/emcee/${item?.competitionName}`);
    // dispatch(setCurrentComp(item));
    localStorage.setItem('compId', item?.compId);
  }
  return (
    <div className="w-full flex flex-wrap items-center gap-3 bg-[#282929] p-4 rounded-lg border border-white/10">
      <img alt="user" src={'/images/logoComp.png'} className="w-8 h-8 mr-6" />
      <h3 className="font-medium mr-auto w-[200px] truncate">
        {item?.competitionName}
      </h3>
      <div className="flex gap-2 ml-auto">
        <p
          onClick={linkToEvent}
          className="text-[#94a4fd] cursor-pointer hover:underline"
        >
          Select Competition
        </p>
      </div>
    </div>
  );
};

export default EmceeCompCard;
