import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "./firebase";

//=============================== to submit score
export const submitScoreService = async (data) => {
  try {
    const dancersCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "scores"
    );

    const docId = `${data?.event_name.replace(
      / /g,
      "_"
    )}_${data?.entry_name.replace(/ /g, "_")}_${data?.judge_id}`;
    const docRef = doc(dancersCollectionRef, docId);

    await setDoc(docRef, {
      ...data?.sliderValues,
      total: data?.total,
      judge_id: data?.judge_id,
      event_name: data?.event_name,
      entry_name: data?.entry_name,
      award_name: data?.award_name,
      deductions: data?.deductions,
      doc_id: data?.doc_id,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to present score
export const presentScoreService = async (data) => {
  try {
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const docId = data?.doc_id;

    // Update the document with the specified doc_id to set is_presenting to true
    const docRef = doc(entriesCollectionRef, docId);
    await updateDoc(docRef, {
      is_presenting: true,
    });

    // Get all other documents in the collection and update their is_presenting to false
    const querySnapshot = await getDocs(entriesCollectionRef);
    const batch = writeBatch(db);
    querySnapshot.forEach((docSnapshot) => {
      const entryId = docSnapshot.id;
      if (entryId !== docId) {
        const entryRef = doc(entriesCollectionRef, entryId);
        batch.update(entryRef, {
          is_presenting: false,
        });
      }
    });

    // Commit the batch to update all documents in one transaction
    await batch.commit();

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==========================to unprsent score
export const unpresentScoreService = async (data) => {
  try {
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const docId = data?.doc_id;

    // Update the document with the specified doc_id to set is_presenting to true
    const docRef = doc(entriesCollectionRef, docId);
    await updateDoc(docRef, {
      is_presenting: false,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to add dancers
export const addNoteService = async (data) => {
  try {
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const docId = data?.doc_id;

    const docRef = doc(entriesCollectionRef, docId);
    const docSnapshot = await getDoc(docRef);

    // Get the existing note array or initialize an empty array
    let existingNote = docSnapshot.exists()
      ? docSnapshot.data().notes || []
      : [];

    // If the array is empty, add the note as the first item
    if (existingNote.length === 0) {
      existingNote = [data?.note];
    } else {
      // If the array is not empty, add the note to it
      existingNote.push(data?.note);
    }

    // Update the document with the new note array
    await updateDoc(docRef, {
      notes: existingNote,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to delete dancers
export const deleteNoteService = async (data) => {
  try {
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const docId = data?.doc_id;

    const docRef = doc(entriesCollectionRef, docId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Get the existing notes array or initialize an empty array
      const existingNotes = docSnapshot.data().notes || [];

      // Filter out the note with the specified note_uid
      const updatedNotes = existingNotes?.filter(
        (note) => note.note_uid !== data?.note?.note_uid
      );

      // Update the document with the updated notes array
      await updateDoc(docRef, {
        notes: updatedNotes,
      });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//=============================== to edit dancers
export const editNoteService = async (data) => {
  try {
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const docId = data?.doc_id;

    const docRef = doc(entriesCollectionRef, docId);
    const docSnapshot = await getDoc(docRef);

    // Get the existing note array or initialize an empty array
    let existingNotes = docSnapshot.exists()
      ? docSnapshot.data().notes || []
      : [];

    // Find the index of the note to be updated
    const noteIndex = existingNotes?.findIndex(
      (note) => note?.note_uid === data?.note[0]?.note_uid
    );

    // If the note with the given note_id is found, update it
    if (noteIndex !== -1) {
      existingNotes[noteIndex] = {
        ...existingNotes[noteIndex],
        ...data?.note[0],
      };
    }

    // Update the document with the updated note array
    await updateDoc(docRef, {
      notes: existingNotes,
    });

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};

//==============================================to award note
export const updateNoteAwardService = async (data) => {
  try {
    const entriesCollectionRef = collection(
      db,
      "competitions",
      data?.compId,
      "entries"
    );
    const docId = data?.doc_id;
    const docRef = doc(entriesCollectionRef, docId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Get the existing notes array or initialize an empty array
      const existingNotes = docSnapshot.data().notes || [];

      // Update the "awarded" value for the specified note_uid
      const updatedNotes = existingNotes?.map((note) => {
        if (note.note_uid === data?.note_uid) {
          return { ...note, awarded: !note?.awarded };
        }
        return note;
      });

      // Update the document with the updated notes array
      await updateDoc(docRef, {
        notes: updatedNotes,
      });
    }

    return { success: true };
  } catch (error) {
    return { success: false, message: error.message };
  }
};
