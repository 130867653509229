/* eslint-disable react-hooks/exhaustive-deps */
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearSuccess } from 'redux/eventManagement/eventManagement';
import { billingDetail } from 'redux/routineScheduling/routineSchedulingSlice';
import Spinner from 'utils/Spinner';

const SettingsAccountDetails = () => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { data: billingData } = useFirestoreQueryDoc('competitions', user?.uid);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    name: '',
    street_address1: '',
    street_address_line_2: '',
    city: '',
    state_province: '',
    postal_code: '',
    phone_number: '',
    website: '',
    tax_number: '',
    other_comments: '',
  });

  const [formErr, setFormErr] = useState('');
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (billingData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...billingData.billing_details,
      }));
    }
    setLoading(false);
  }, [billingData]);

  useEffect(() => {
    setFormErr('');
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccessModalOpen) {
      const timeoutId = setTimeout(() => {
        setIsSuccessModalOpen(false);
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [isSuccessModalOpen]);

  function handleInputChange(event) {
    setFormErr('');
    dispatch(clearSuccess());
    const { id, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
    setHasUnsavedChanges(true);
  }

  const handleRegister = (e) => {
    e.preventDefault();
    if (
      formData.name &&
      formData.street_address1 &&
      formData.city &&
      formData.state_province &&
      formData.postal_code &&
      formData.phone_number &&
      formData.website &&
      formData.tax_number
    ) {
      dispatch(billingDetail({ compId: user?.uid, formData }));
      setHasUnsavedChanges(false);
      setIsSuccessModalOpen(true);
    } else {
      setFormErr('Please fill all required fields');
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <h1 className="w-full pb-2 font-bold mb-6">
        Invoice and Receipt Details
      </h1>
      <form onSubmit={handleRegister}>
        <div className="w-full flex bg-black/50 p-3 rounded-lg mb-3">
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Company Name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full bg-transparent border-none outline-none text-white/80"
          />
        </div>
        <div className="w-full flex bg-black/50 p-3 rounded-lg mb-3">
          <input
            type="text"
            id="street_address1"
            name="street_address1"
            placeholder="street_address1"
            value={formData.street_address1}
            onChange={handleInputChange}
            className="w-full bg-transparent border-none outline-none text-white/80"
          />
        </div>
        <div className="w-full flex bg-black/50 p-3 rounded-lg mb-3">
          <input
            type="text"
            id="street_address_line_2"
            name="street_address_line_2"
            placeholder="street_address_line_2"
            value={formData.street_address_line_2}
            onChange={handleInputChange}
            className="w-full bg-transparent border-none outline-none text-white/80"
          />
        </div>
        <div className="flex flex-wrap mb-3">
          <div className="w-full md:w-[49%] bg-black/50 p-3 rounded-lg mr-2 mb-3">
            <input
              type="text"
              id="city"
              name="city"
              placeholder="City"
              value={formData.city}
              onChange={handleInputChange}
              className="w-full bg-transparent border-none outline-none text-white/80"
            />
          </div>
          <div className="w-full md:w-[49%] bg-black/50 p-3 rounded-lg mb-3">
            <input
              type="text"
              id="state_province"
              name="state_province"
              placeholder="State / Province"
              value={formData.state_province}
              onChange={handleInputChange}
              className="w-full bg-transparent border-none outline-none text-white/80"
            />
          </div>
          <div className="w-full md:w-[49%] bg-black/50 p-3 rounded-lg mr-2 mb3">
            <input
              type="text"
              id="postal_code"
              name="postal_code"
              placeholder="Postal Code"
              value={formData.postal_code}
              onChange={handleInputChange}
              className="w-full bg-transparent border-none outline-none text-white/80"
            />
          </div>
          <div className="w-full md:w-[49%] bg-black/50 p-3 rounded-lg">
            <input
              type="text"
              id="phone_number"
              name="phone_number"
              placeholder="Phone Number"
              value={formData.phone_number}
              onChange={handleInputChange}
              className="w-full bg-transparent border-none outline-none text-white/80"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 bg-black/50 p-3 rounded-lg mb-3">
          <input
            type="text"
            id="website"
            name="website"
            placeholder="Website"
            value={formData.website}
            onChange={handleInputChange}
            className="w-full bg-transparent border-none outline-none text-white/80"
          />
        </div>

        <div className="w-full md:w-1/2 bg-black/50 p-3 rounded-lg mb-3">
          <input
            type="text"
            id="tax_number"
            name="tax_number"
            placeholder="Tax Number"
            value={formData.tax_number}
            onChange={handleInputChange}
            className="w-full bg-transparent border-none outline-none text-white/80"
          />
        </div>

        <div className="w-full md:w-1/2 bg-black/50 p-3 rounded-lg mb-3">
          <textarea
            id="other_comments"
            name="other_comments"
            placeholder="Other Comments"
            value={formData.other_comments}
            onChange={handleInputChange}
            className="w-full h-[8rem] resize-none bg-transparent border-none outline-none text-white/80"
          />
        </div>
        <div className="flex gap-3 justify-end mb-6">
          <button
            type="submit"
            className={`px-8 py-1 bg-[#94a4fd] rounded-full text-black/80 text-[0.85rem] hover:opacity-75 ${
              hasUnsavedChanges ? '' : 'cursor-not-allowed opacity-50'
            }`}
            disabled={!hasUnsavedChanges}
          >
            Save
          </button>
        </div>
        {isSuccessModalOpen && (
          <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
            <div className="w-full sm:w-[550px] min-h-fit p-5 md:py-8 md:px-10 bg-[#282929] rounded-lg flex gap-5 justify-start items-center scale shadow-lg relative">
              <img
                alt="success"
                src="/images/icons8-checkmark-64.png"
                className="w-10 h-10"
              />
              <p className="text-[1rem] text-white text-center">
                Billing details saved successfully
              </p>
            </div>
          </div>
        )}
        {formErr && <p className="text-red-500">{formErr}</p>}
      </form>
    </div>
  );
};

export default SettingsAccountDetails;
