/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LeftSidebar from "../components/LeftSidebar";
import RightSidebar from "../components/RightSidebar";
import ScrollToTop from "../ScrollToTop";
import { useParams, useNavigate } from "react-router-dom";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import {
  clearSuccess,
  editPreset,
} from "redux/eventManagement/eventManagement";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { PulseLoader } from "react-spinners";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import AddEventContainer from "./AddEventContainer";

const EditSessionsPage = () => {
  const { addEvent, openNotifsBoard, currentEvent } = useSelector(
    (state) => state.eventManagement
  );
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { data: comps } = useQueryAllDocs("competitions");
  const currentComp = comps?.filter((item) => item?.compId === user?.uid)[0];

  const { data: allEntriesRaw } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );

  const allEntries = allEntriesRaw?.filter(
    (x) => x?.event_uid === currentEvent?.event_uid
  );

  const { presetUid, event_name } = useParams();
  const { editPresetPending, editPresetSuccess } = useSelector(
    (state) => state.eventManagement
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status, data } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "reports"
  );

  const [presetData, setPresetData] = useState(null);
  const [presetDocName, setPresetDocName] = useState(null);
  const [presetName, setPresetName] = useState(
    presetData?.overall_name || presetData?.grand_name || ""
  );

  useEffect(() => {
    const preset = data
      ?.map((item) => item.presets)
      ?.flat()
      ?.find((preset) => preset?.preset_uid === presetUid);

    // Initialize the `checked` property in each selection
    if (preset) {
      preset?.selections.forEach((selection) => {
        selection.checked = true; // Set initial state to checked
      });
    }

    setPresetData(preset);
    // Determine the doc name, overall name, and grand name based on the data
    if (preset) {
      const docName = preset?.grand_name ? "grand_preset" : "overall_preset";
      setPresetDocName(docName);
      setPresetName(preset?.overall_name || preset?.grand_name); // Set either overall_name or grand_name based on data
    }
  }, [data, presetUid]);

  // console.log("selections", selections);

  const handleEditSubmit = () => {
    const numberAwards = selections
      ?.map((x) => Number(x?.awardsNumber))
      ?.reduce((a, b) => a + b);
    // Create an object with the updated preset data that you want to save
    const updatedPresetData = {
      ...presetData,
      overall_name: presetName,
      selections,
      no_of_awards: numberAwards,
    };

    // Dispatch the editPreset action to update the preset data
    dispatch(
      editPreset({
        compId: user?.uid,
        doc: presetDocName, // Update with the correct doc name
        preset_uid: presetUid,
        updatedPresetData,
      })
    );
  };

  const handleRemoveSelection = (index) => {
    // Create a new array of selections excluding the one at the specified index
    const updatedSelections = [...presetData?.selections];
    updatedSelections.splice(index, 1);

    // Create a copy of the current presetData and update the selections
    const updatedPresetData = {
      ...presetData,
      selections: updatedSelections,
    };

    // Update the state with the modified presetData
    setPresetData(updatedPresetData);
  };

  useEffect(() => {
    // Check if the preset edit was successful
    if (editPresetSuccess?.success) {
      // Clear the success state in Redux
      setEditted(false);
      dispatch(clearSuccess());
      // Navigate back to the homepage
      navigate(
        `/organizer/event/${event_name.replace(
          / /g,
          "-"
        )}/report-session-awards`
      );
    }
  }, [editPresetSuccess, dispatch, navigate, event_name]);

  const docName = presetData?.grand_name ? "grand_preset" : "overall_preset";
  const allAwards = data?.filter((item) => item?.id === docName)[0]?.awards;

  const sortedAwards = allAwards?.slice()?.sort((a, b) => {
    // Compare the classification fields of two objects
    const classificationA = a.classification[0];
    const classificationB = b.classification[0];

    return classificationA.localeCompare(classificationB);
  });

  const [selections, setSelections] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [editted, setEditted] = useState(false);

  useEffect(() => {
    if (!editted) {
      setSelections(presetData?.selections);
    }
  }, [status, presetData]);

  const handleCheckboxChange = (event, row) => {
    // setValidationErr("");
    setEditted(true);
    if (event.target.checked) {
      setSelections((prevSelections) => [...prevSelections, row]);
    } else {
      setSelections((prevSelections) =>
        prevSelections.filter((name) => name?.award_name !== row?.award_name)
      );
    }
  };

  const handleSelectAllChange = (event) => {
    // setValidationErr("");
    setEditted(true);
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelections(allAwards);
    } else {
      setSelections([]);
    }
  };

  return (
    <>
      <LeftSidebar />
      <Header />
      {openNotifsBoard && <RightSidebar />}

      {/* event details page */}
      {!addEvent && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700`}
        >
          <div>
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h2>
                {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                {currentEvent?.year.split(" to ")[0]}
              </h2>
              <img
                alt="arrow"
                src="/images/ArrowDown.png"
                className="w-4 h-4"
              />
            </div>
          </div>
          {/* loading check and sessions page */}
          <div className="w-full md:max-w-full md:min-w-[450px] mb-auto text-white/50">
            {status === "loading" ? (
              <div className="w-full h-[200px] border border-white/10 rounded-lg flex justify-center items-center">
                <PulseLoader color="#94a4fd" size={15} />
              </div>
            ) : (
              <>
                <div className="w-full p-4 rounded-lg bg-[#0c0c0d] flex flex-col transition-all duration-500 false">
                  <label
                    htmlFor="award_name"
                    className="text-[.75rem] text-white/40"
                  >
                    OverAll award name
                  </label>
                  <input
                    type="text"
                    id="award_name"
                    name="award_name"
                    value={presetName || ""}
                    onChange={(e) => setPresetName(e.target.value)}
                    className="bg-[#0c0c0d] outline-none text-white placeholder:text-white/50 mt-1"
                  />
                </div>
                {/* display the award name with a remove icon */}
                <h2 className="text-white/80 mt-5">Selections:</h2>
                <div className="w-full mt-2 flex gap-3 flex-wrap">
                  {selections?.map((selection, index) => (
                    <div
                      key={index}
                      className="w-fit h-fit text-[.9rem] text-black px-3 py-1 rounded-md bg-[#94a4fd] flex gap-2 items-center"
                    >
                      <p>{selection.award_name}</p>
                      <div
                        className="p-1 rounded-full bg-red-500 cursor-pointer"
                        onClick={() => handleRemoveSelection(index)}
                      >
                        <img
                          alt=""
                          src="/images/icons8-close-48.png"
                          className="w-3 h-3"
                        />
                      </div>
                    </div>
                  ))}
                </div>

                {/* display the award name with a remove icon */}
                <div className="w-full min-h-[200px] mt-[70px]">
                  <div className="w-full h-full mt-5 pb-2 overflow-auto transition-all duration-700">
                    <table className="min-w-[600px] w-full text-[.85rem] table-2">
                      <thead className="text-white/40 text-left border-b border-white/30">
                        <tr className="whitespace-nowrap">
                          <th>
                            <input
                              type="checkbox"
                              className="mr-2 cursor-pointer"
                              checked={selectAll}
                              onChange={() => {
                                handleSelectAllChange();
                              }}
                            />{" "}
                          </th>
                          <th>Award Name</th>
                          <th>Classification</th>
                          <th>Entry Type</th>
                          <th>Genre</th>
                          <th>Min. Age</th>
                          <th>Max. Age</th>
                          <th># Awards</th>
                          <th># Eligible</th>
                          <th>Special Tags</th>
                          <th>Studios</th>
                        </tr>
                      </thead>
                      <tbody className="text-white/80">
                        {/* {presetData?.selections?.map((selection, index) => { */}
                        {sortedAwards?.map((selection, index) => {
                          const {
                            classification,
                            entryType,
                            min_age,
                            max_age,
                            genre,
                          } = selection;

                          const eligibileEntriesRaw = allEntries?.filter(
                            (itm) => {
                              const matchingClass = classification?.includes(
                                "ALL"
                              )
                                ? itm?.classification
                                : classification?.includes(itm?.classification);
                              const matchingEntryType = entryType?.includes(
                                "ALL"
                              )
                                ? itm?.entryType
                                : entryType?.includes(itm?.entryType);
                              const matchingGenre = genre?.includes("ALL")
                                ? itm?.genre
                                : genre?.includes(itm?.genre);

                              return (
                                matchingClass &&
                                matchingEntryType &&
                                matchingGenre
                              );
                            }
                          );

                          const ageDiv =
                            currentComp?.eligibilityDivisions?.filter(
                              (x) =>
                                Number(x?.maxAge) <= Number(max_age) &&
                                Number(x?.minAge) >= Number(min_age)
                            );

                          const myAgeDivisions = ageDiv?.map((x) =>
                            x?.divisionName?.toLowerCase()
                          );

                          //================================================to filter by min and max age
                          function filterEntriesByAge() {
                            return eligibileEntriesRaw?.filter((entry) =>
                              myAgeDivisions?.includes(entry?.age_division)
                            );
                          }

                          const specialEntriesScored = allEntries
                            ?.map((x) => {
                              const specialArrLength =
                                currentComp?.specialEntryTypes?.tags?.filter(
                                  (y) =>
                                    y?.specialEntryTypeName === x?.entryType
                                )?.length;
                              return specialArrLength > 0 ? x : null;
                            })
                            ?.filter((z) => z !== null && z?.scored);

                          const eligibleEntries =
                            filterEntriesByAge()?.concat(specialEntriesScored);

                          //==================================================to filter by special tags
                          function filterEntriesBySpecialTag() {
                            return eligibleEntries
                              ?.map((entry) => {
                                if (selection?.special_tags?.length === 0) {
                                  return entry;
                                } else {
                                  if (Array?.isArray(entry?.special_tags)) {
                                    const entryHasTag =
                                      entry?.special_tags?.filter((x) =>
                                        selection?.special_tags?.includes(x)
                                      );

                                    return entryHasTag?.length > 0
                                      ? entry
                                      : null;
                                  }
                                }
                              })
                              ?.filter((y) => y);
                          }

                          const eligibleTagsEntries =
                            filterEntriesBySpecialTag();

                          //================================================to filter by studio
                          function filterByStudio() {
                            return eligibleTagsEntries
                              ?.map((entry) => {
                                if (
                                  selection?.studio_selected?.includes("ALL")
                                ) {
                                  return entry;
                                } else {
                                  if (Array?.isArray(entry?.studio_selected)) {
                                    const entryHasStudio =
                                      entry?.studio_selected?.filter((x) =>
                                        selection?.studio_selected?.includes(x)
                                      );

                                    return entryHasStudio?.length > 0
                                      ? entry
                                      : null;
                                  }
                                }
                              })
                              ?.filter((y) => y);
                          }

                          const finalEntries = filterByStudio();

                          const isChecked = selections?.filter(
                            (x) => x?.award_uid === selection?.award_uid
                          );

                          return (
                            <tr
                              key={index}
                              className="border-b border-white/10 cursor-pointer"
                            >
                              <td>
                                <input
                                  type="checkbox"
                                  className="mr-2 cursor-pointer"
                                  checked={selectAll || isChecked?.length > 0}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, selection)
                                  }
                                />
                              </td>
                              <td className="">
                                {capitalizeFirstLetter(selection?.award_name)}
                              </td>
                              <td>
                                {selection?.classification?.length > 1
                                  ? "Multiple"
                                  : capitalizeFirstLetter(
                                      selection?.classification[0]
                                    )}
                              </td>
                              <td>
                                {selection?.entryType?.length > 1
                                  ? "Multiple"
                                  : capitalizeFirstLetter(
                                      selection?.entryType[0]
                                    )}
                              </td>
                              <td>
                                {selection?.genre?.length > 1
                                  ? "Multiple"
                                  : selection?.genre}
                              </td>
                              <td>{selection?.min_age}</td>
                              <td>{selection?.max_age}</td>
                              <td>{selection?.awardsNumber}</td>
                              <td
                                className={`${
                                  finalEntries?.length >= 3
                                    ? "text-green-400"
                                    : "text-white"
                                }`}
                              >
                                {finalEntries?.length}
                              </td>
                              <td>
                                <p className="whitespace-nowrap truncate">
                                  {selection?.special_tags?.length === 0
                                    ? "N/A"
                                    : selection?.special_tags}
                                </p>
                              </td>
                              <td>
                                <p className="whitespace-nowrap truncate">
                                  {selection?.studio_selected?.map((x, ind) => {
                                    return `${x}${
                                      ind !==
                                      selection?.studio_selected?.length - 1
                                        ? ","
                                        : ""
                                    }`;
                                  })}
                                </p>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="text-[.75rem] text-white/40 mt-4 lg:">
                      Scroll horizontally to view more, if needed
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={handleEditSubmit}
                    className="w-fit text-[.85rem] px-5 py-1 mr-4 mt-5 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
                  >
                    {editPresetPending ? (
                      <ButtonLoadingSpinner />
                    ) : (
                      "Save Changes"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
          {/*  sessions page */}

          <ScrollToTop />
          <Footer />
        </div>
      )}

      {/* the add event pop up page */}

      {addEvent && <AddEventContainer />}
    </>
  );
};

export default EditSessionsPage;
