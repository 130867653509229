/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  toggleAddScheduleAward,
  toggleAddSession,
} from "redux/routineScheduling/routineSchedulingSlice";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import calculateEntryStartTime from "utils/calculateEntryStartTime";
import filterObjectsBetween from "utils/filterObjectsBetween";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import findSessionEntries from "utils/findSessionEntries";
import splitArrayAtSession from "utils/splitArrayAtSession";
import sumTimeValues from "utils/sumTimeValues";

const ScheduleButtons = ({
  currentEvent,
  toggleAddBreak,
  toggleAddEntry,
  toggleAddScheduleOverall,
  toggleAddTimeBuffer,
  toggleViewDeleted,
  toggleLockMod,
  sortedCategories,
  allEntries,
  setDisplaySession,
  displaySession,
}) => {
  const dispatch = useDispatch();
  const [hovered, setHovered] = useState(null);

  //===================================to check if action has been taken on alerts
  const [alertsCleared, setAlertsCleared] = useState(
    JSON.parse(localStorage.getItem("alertsCleared")) || false
  );

  const [alerts, setAlerts] = useState(
    JSON.parse(localStorage.getItem("alerts")) || []
  );
  // console.log("alerts", alerts);

  useEffect(() => {
    if (!alertsCleared) {
      setAlerts(createAlerts());
    }
  }, [alertsCleared, sortedCategories]);

  //===================================to remove alerts
  function removeAlert(index) {
    const newAlerts = alerts?.filter((_, idx) => idx !== index);
    setAlertsCleared(newAlerts);
    localStorage.setItem("alerts", JSON.stringify(newAlerts));
    localStorage.setItem("alertsCleared", JSON.stringify(true));
    setAlerts(newAlerts);
    setHovered(null);
  }

  //===================================to check if action has been taken on suggestions
  const [suggestionCleared, setsuggestionCleared] = useState(
    JSON.parse(localStorage.getItem("suggestionCleared")) || false
  );

  const [suggestion, setsuggestion] = useState(
    JSON.parse(localStorage.getItem("suggestion")) || []
  );
  // console.log("suggestion", suggestion);

  const removeDuplicateStrings = (strings) => {
    const uniqueStrings = [];

    strings.forEach((str) => {
      // Check if the uniqueStrings array already includes a string that includes the criteria
      const includesCriteria = uniqueStrings.some((uniqueStr) =>
        uniqueStr.includes("You may want to add a break")
      );

      // If the current string doesn't include the criteria or it's not already in uniqueStrings, add it
      if (!str.includes("You may want to add a break") || !includesCriteria) {
        uniqueStrings.push(str);
      }
    });

    return uniqueStrings;
  };

  useEffect(() => {
    if (!suggestionCleared) {
      const tailoredSuggestion = removeDuplicateStrings(createsuggestion());
      setsuggestion(tailoredSuggestion);
    }
  }, [suggestionCleared, sortedCategories]);

  //===================================to remove suggestion
  function removeSuggestion(index) {
    const newsuggestion = suggestion?.filter((_, idx) => idx !== index);
    setsuggestionCleared(newsuggestion);
    localStorage.setItem("suggestion", JSON.stringify(newsuggestion));
    localStorage.setItem("suggestionCleared", JSON.stringify(true));
    setsuggestion(newsuggestion);
    setHovered(null);
  }

  function AnalyzeSchedule() {
    localStorage.setItem("alertsCleared", JSON.stringify(false));
    localStorage.setItem("suggestionCleared", JSON.stringify(false));
    setAlertsCleared(false);
    setsuggestionCleared(false);
  }

  //=============================================================================================================to create alerts
  function createAlerts() {
    const messages = [];

    if (!sortedCategories) {
      return messages;
    }

    let foundBreak = false;
    let foundAward = false;
    let foundOveralls = false;

    for (let i = 0; i < sortedCategories?.length; i++) {
      const item = sortedCategories[i];

      if (i > 1 && item?.break_type === "break") {
        foundBreak = true;
      } else if (item?.break_type === "awards") {
        foundAward = true;
      } else if (item?.break_type?.includes("overalls")) {
        foundOveralls = true;
      }
    }

    if (!foundBreak) {
      messages.push("No Breaks have been scheduled");
    }

    if (!foundAward) {
      messages.push("No Awards have been scheduled");
    }

    if (!foundOveralls) {
      messages.push("No Overalls have been scheduled");
    }

    return [
      ...messages,
      ...fiftyEntries(),
      ...categoryWith12Entries(),
      ...dancerDancingMoreThan6Times(),
      ...dancerDancingConsecutively(),
      ...dancerWithMoreThan2Entries(),
      ...dancerCompetingAgainst(),
    ];
  }

  //=================================================================================to check for 50 entries in one session
  function fiftyEntries() {
    const messages = [];

    findSessionEntries(
      splitArrayAtSession(sortedCategories),
      allEntries
    ).forEach((item, index) => {
      if (item?.length >= 50) {
        messages.push(`You have more than 50 entries in session ${index + 1}`);
      }
    });

    return messages;
  }

  // console.log("categoryWith12Entries", categoryWith12Entries());

  //====================================================================================to check for categories with 12 entries
  function categoryWith12Entries() {
    const messages = [];

    findSessionEntries(
      splitArrayAtSession(sortedCategories),
      allEntries
    ).forEach((sessionItems, sessionIndex) => {
      const categoryCounts = {};

      sessionItems?.forEach((item) => {
        const category_name = item.category_name;

        if (!categoryCounts[category_name]) {
          categoryCounts[category_name] = 0;
        }

        categoryCounts[category_name]++;
      });

      for (const category_name in categoryCounts) {
        if (
          categoryCounts.hasOwnProperty(category_name) &&
          categoryCounts[category_name] >= 12
        ) {
          messages.push(
            `There is a category with more than 12 entries in session ${
              sessionIndex + 1
            }`
          );
        }
      }
    });

    return messages;
  }
  // console.log("dancerDancingMoreThan2Times", dancerDancingMoreThan2Times());

  //========================================================================to check for dancers dancing more than 6 times per session
  function dancerDancingMoreThan6Times() {
    const messages = [];

    findSessionEntries(
      splitArrayAtSession(sortedCategories),
      allEntries
    )?.forEach((sessionItems, sessionIndex) => {
      let allDancers = [];

      sessionItems.forEach((item, idx) => {
        const dancers_selected = item.dancers_selected;

        if (Array.isArray(dancers_selected)) {
          allDancers?.push(...dancers_selected);
        }

        if (idx === sessionItems?.length - 1) {
          function findDuplicateObjects() {
            const counts = {};
            const duplicates = {};

            allDancers.forEach((obj) => {
              const dancerUid = obj.dancer_uid;

              if (counts[dancerUid]) {
                counts[dancerUid]++;
                if (counts[dancerUid] === 6) {
                  duplicates[dancerUid] = [obj];
                } else if (counts[dancerUid] > 6) {
                  duplicates[dancerUid].push(obj);
                }
              } else {
                counts[dancerUid] = 1;
              }
            });

            return Object.values(duplicates).flat();
          }

          const appearsSixTimes = findDuplicateObjects();

          if (appearsSixTimes?.length > 0) {
            messages.push(
              `There are dancer(s) dancing more than 6 times in session ${
                sessionIndex + 1
              }`
            );
          }
        }
      });
    });

    return messages;
  }

  // console.log("dancerDancingConsecutively", dancerDancingConsecutively());

  //================================================================================to check for dancers dancing consecutively
  function dancerDancingConsecutively() {
    const messages = [];

    findSessionEntries(
      splitArrayAtSession(sortedCategories),
      allEntries
    )?.forEach((sessionItems, sessionIndex) => {
      const categories = sortedCategories?.filter(
        (l) => l?.type === "category"
      );
      const allEntriesWithLocked = arrangeEntriesByCategories(
        categories,
        sessionItems
      );
      const sessionEntries = removeDuplicates(allEntriesWithLocked);

      let previousDancers = null;
      let consecDancers = [];

      sessionEntries.forEach((item, idx) => {
        const dancers_selected = item.dancers_selected;

        if (previousDancers) {
          // Use filter to iterate through previousDancers and return objects that exist in dancers_selected
          const commonDancer = previousDancers?.filter((obj1) =>
            dancers_selected?.some(
              (obj2) => obj2.dancer_uid === obj1.dancer_uid
            )
          );

          consecDancers?.push(...commonDancer);
        }
        previousDancers = dancers_selected;

        if (idx === sessionItems?.length - 1) {
          if (consecDancers?.length > 0) {
            messages.push(
              `There are dancer(s) dancing consecutively in session ${
                sessionIndex + 1
              }`
            );
          }
          consecDancers = [];
          previousDancers = null;
        }
      });
    });

    return messages;
  }

  // console.log("dancerWithMoreThan2Entries", dancerWithMoreThan2Entries());

  //================================================================================to check for dancers competing against themselves
  function dancerCompetingAgainst() {
    const messages = [];

    findSessionEntries(
      splitArrayAtSession(sortedCategories),
      allEntries
    )?.forEach((sessionItems, sessionIndex) => {
      const categories = sortedCategories?.filter(
        (l) => l?.type === "category"
      );
      const allEntriesWithLocked = arrangeEntriesByCategories(
        categories,
        sessionItems
      );
      const sessionEntries = removeDuplicates(allEntriesWithLocked);

      sessionEntries?.forEach((item, idx, array) => {
        const currentDancers = item?.dancers_selected;

        const entriesWithoutCurrent = array?.filter(
          (x) => x !== item && x?.category_name === item?.category_name
        );

        let allDancers = [];
        entriesWithoutCurrent?.map((y) =>
          allDancers?.push(...y?.dancers_selected)
        );

        const dancersInBothArrays = currentDancers.filter((dancer) =>
          allDancers.some((d) => d.id === dancer.id)
        );

        if (
          dancersInBothArrays?.length > 0 &&
          !messages?.includes(
            `There are dancer(s) competing against themselves in the same category in session ${
              sessionIndex + 1
            }`
          )
        ) {
          messages.push(
            `There are dancer(s) competing against themselves in the same category in session ${
              sessionIndex + 1
            }`
          );
        }
      });
    });

    return messages;
  }

  // console.log("dancerCompetingAgainst", dancerCompetingAgainst());

  //========================================================================to check for pre-competivie dancer with more than 6 entries
  function dancerWithMoreThan2Entries() {
    const messages = [];

    findSessionEntries(
      splitArrayAtSession(sortedCategories),
      allEntries
    )?.forEach((sessionItems, sessionIndex) => {
      const preCompetitiveDancers = {};

      sessionItems.forEach((item) => {
        const dancers_selected = item.dancers_selected;

        if (Array.isArray(dancers_selected)) {
          dancers_selected.forEach((dancer) => {
            if (dancer.classification?.toLowerCase() === "pre-competitive") {
              const dancer_uid = dancer.dancer_uid;

              if (!preCompetitiveDancers[dancer_uid]) {
                preCompetitiveDancers[dancer_uid] = 0;
              }

              preCompetitiveDancers[dancer_uid]++;
            }
          });
        }
      });

      for (const dancer_uid in preCompetitiveDancers) {
        if (
          preCompetitiveDancers.hasOwnProperty(dancer_uid) &&
          preCompetitiveDancers[dancer_uid] > 6
        ) {
          messages.push(
            `There is a pre-competitive dancer with more than 6 registered entries in session ${
              sessionIndex + 1
            }`
          );
        }
      }
    });

    return messages;
  }

  //==================================================================================================to handle entrycount calculation
  function removeDuplicates(array) {
    const uniqueArray = [];
    const seenObjects = new Set();

    for (const obj of array) {
      // Serialize the object to JSON to use it as a unique identifier
      const serializedObj = JSON.stringify(obj);

      if (!seenObjects.has(serializedObj)) {
        seenObjects.add(serializedObj);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  }

  const categories = sortedCategories?.filter((l) => l?.type === "category");

  const allEntriesWithoutLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  )?.filter((k) => k?.locked_entry === false);
  const allEntriesWithLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  );

  function addMinutesToTime(inputTime, minutesToAdd) {
    const timeMoment = moment(inputTime, "h:mm A");
    const endTime = timeMoment.clone().add(minutesToAdd, "minutes");
    const formattedEndTime = endTime.format("h:mm A");
    return formattedEndTime;
  }

  function entryCount(itm) {
    const allEntriesBeforeLocked = allEntriesWithLocked
      ?.slice(
        0,
        findIndexByProperty(allEntriesWithLocked, "doc_id", itm?.doc_id)
      )
      ?.filter((m) => m?.locked_entry === false);

    if (itm?.locked_entry) {
      return `${
        allEntriesBeforeLocked?.length === 0
          ? 1
          : allEntriesBeforeLocked?.length
      }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
    } else {
      return (
        findIndexByProperty(allEntriesWithoutLocked, "doc_id", itm?.doc_id) + 1
      );
    }
  }

  //============================================================================================to create add break suggestion

  function createsuggestion() {
    const messages = [];

    if (!sortedCategories) {
      return messages;
    }

    let minutesCount = 0;
    const startTime = currentEvent?.days[0]?.startTime;
    let initialTime = moment(startTime).utc().format("h:mm A");

    let lastCategory = null;

    removeDuplicates(allEntriesWithoutLocked)?.forEach((item, index) => {
      const catIndex = findIndexByProperty(
        sortedCategories,
        "category_name",
        item?.category_name
      );
      // to get all categories before current category
      const categoriesBeforeCurrent =
        sortedCategories?.slice(0, catIndex) || [];

      const allBufferBeforeCurrent = categoriesBeforeCurrent?.filter(
        (cate) => cate?.type === "buffer"
      );
      const timeToAdd = allBufferBeforeCurrent?.reduce((sum, obj) => {
        if (obj.type === "buffer") {
          const bufferValue = Number(obj.buffer);
          if (!isNaN(bufferValue)) {
            return sum + bufferValue;
          }
        }
        return sum;
      }, 0);

      //to get the entry count of all entries in routine
      function entryTime(itm) {
        const idx = findIndexByProperty(
          removeDuplicates(allEntriesWithoutLocked),
          "doc_id",
          itm?.doc_id
        );
        if (idx === 0) {
          return addMinutesToTime(initialTime, timeToAdd);
        } else {
          const allEntriesBeforeCurrent = removeDuplicates(
            allEntriesWithoutLocked
          )?.slice(
            0,
            findIndexByProperty(
              removeDuplicates(allEntriesWithoutLocked),
              "doc_id",
              itm?.doc_id
            )
          );

          const allTimeBeforeCurrent = sumTimeValues(allEntriesBeforeCurrent);
          const startTime = calculateEntryStartTime(
            initialTime,
            allTimeBeforeCurrent,
            itm
          );

          return addMinutesToTime(startTime, timeToAdd);
        }
      }

      //=====
      function getDiff() {
        const momentTime1 = moment(initialTime, "h:mm A");
        const momentTime2 = moment(entryTime(item), "h:mm A").subtract(
          minutesCount,
          "minutes"
        );
        const diffInMinutes = momentTime2.diff(momentTime1, "minutes");

        return diffInMinutes;
      }

      //to find breaks in between the last category and category after current category
      const breaksInBetween = filterObjectsBetween(
        sortedCategories,
        lastCategory,
        sortedCategories[catIndex + 1]
      )?.filter((w) => w?.break_type === "break");

      if (getDiff() >= 240) {
        minutesCount = getDiff();
        lastCategory = sortedCategories[catIndex];

        if (breaksInBetween?.length === 0) {
          messages.push(
            `You may want to add a break before entry #${entryCount(item)}`
          );
        }
      }
    });

    return [
      ...messages,
      ...moveProduction(),
      ...checkAllFinishedGenre(),
      ...checkAllFinishedDivision(),
    ];
  }

  // console.log("moveProduction", moveProduction());
  //==========================================================================to check to move production to beginning or end of a session
  function moveProduction() {
    const messages = [];

    sortedCategories?.forEach((item, index) => {
      const entryType = item?.category_name?.split("~")[3]?.toLowerCase();
      if (entryType === "productions") {
        const prevCat = sortedCategories[index - 1];
        const nextCat = sortedCategories[index + 1];
        if (
          prevCat?.break_type !== "session" &&
          nextCat?.break_type !== "session"
        ) {
          messages.push(
            `You may want to move the production to the beginning or end of a session`
          );
        }
      }
    });

    return messages;
  }

  //=====================================================================================to check if genre is complete
  // console.log("checkAllFinishedGenre", checkAllFinishedGenre());

  function checkAllFinishedGenre() {
    const messages = [];

    findSessionEntries(
      splitArrayAtSession(sortedCategories),
      allEntries
    )?.forEach((sessionItems, sessionIndex) => {
      const genreGroups = {};

      sessionItems.forEach((item) => {
        const genre = item.genre;
        const performanceReady = item.performance_ready;

        if (genre) {
          if (!genreGroups[genre]) {
            genreGroups[genre] = { allTrue: true };
          }
          genreGroups[genre].allTrue =
            genreGroups[genre].allTrue && performanceReady;
        }
      });

      const overallExists = splitArrayAtSession(sortedCategories)[
        sessionIndex
      ]?.filter((x) => x?.break_type?.includes("overalls"));

      for (const genre in genreGroups) {
        if (
          genreGroups.hasOwnProperty(genre) &&
          genreGroups[genre].allTrue &&
          overallExists?.length === 0
        ) {
          messages.push(
            `All ${genre} entries finished in session ${
              sessionIndex + 1
            }, you may want to add an overall at the end of this session`
          );
        }
      }
    });

    return messages;
  }

  //=====================================================================================to check if division is complete
  // console.log("splitArrayAtSession", splitArrayAtSession(sortedCategories));

  function checkAllFinishedDivision() {
    const messages = [];

    findSessionEntries(
      splitArrayAtSession(sortedCategories),
      allEntries
    )?.forEach((sessionItems, sessionIndex) => {
      const age_divisionGroups = {};

      sessionItems.forEach((item) => {
        const age_division = item.age_division;
        const performanceReady = item.performance_ready;

        if (age_division) {
          if (!age_divisionGroups[age_division]) {
            age_divisionGroups[age_division] = { allTrue: true };
          }
          age_divisionGroups[age_division].allTrue =
            age_divisionGroups[age_division].allTrue && performanceReady;
        }
      });

      const overallExists = splitArrayAtSession(sortedCategories)[
        sessionIndex
      ]?.filter((x) => x?.break_type?.includes("overalls"));

      for (const age_division in age_divisionGroups) {
        if (
          age_divisionGroups.hasOwnProperty(age_division) &&
          age_divisionGroups[age_division].allTrue &&
          overallExists?.length === 0
        ) {
          messages.push(
            `All ${age_division} entries finished in session ${
              sessionIndex + 1
            }, you may want to add an overall at the end of this session`
          );
        }
      }
    });

    return messages;
  }

  //======================================================to scroll to category with the alert
  const scrollToSource = (id) => {
    const sessionElement = document.getElementById(id);
    if (sessionElement) {
      sessionElement.scrollIntoView({ behavior: "smooth" });
    }

    setDisplaySession(`Session ${id}`);
    setTimeout(() => {
      setDisplaySession("");
    }, 3000);
  };

  return (
    <div className="w-full min-h-[200px] mt-20 flex flex-col lg:flex-row flex-wrap lg:flex-nowrap gap-10 md:gap-14">
      <div className="w-full lg:w-full h-fit grid grid-cols-2 gap-3">
        {!currentEvent?.locked && (
          <>
            <button
              onClick={() => dispatch(toggleAddSession())}
              className="text-[.85rem] px-5 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
            >
              Add Session
            </button>
            <button
              onClick={() => dispatch(toggleAddBreak())}
              className="text-[.85rem] px-5 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
            >
              Add Break
            </button>
          </>
        )}
        <button
          onClick={() => dispatch(toggleAddEntry())}
          className="text-[.85rem] px-5 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
        >
          Add Entry
        </button>
        {!currentEvent?.locked && (
          <>
            <button
              onClick={() => dispatch(toggleAddTimeBuffer())}
              // disabled
              className="text-[.85rem] px-5 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
            >
              Add Buffer
            </button>
            <button
              onClick={() => dispatch(toggleViewDeleted())}
              className="text-[.85rem] px-5 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
            >
              View Deleted
            </button>
            <button
              onClick={toggleLockMod}
              className="text-[.85rem] px-5 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
            >
              Lock Schedule
            </button>
            <button
              onClick={() => dispatch(toggleAddScheduleOverall())}
              className="text-[.85rem] px-5 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
            >
              Add Overalls
            </button>
            <button
              onClick={() => dispatch(toggleAddScheduleAward())}
              className="text-[.85rem] px-5 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
            >
              Add Awards
            </button>
          </>
        )}
      </div>

      <div className="w-full md:min-w-[500px] flex flex-col gap-3">
        <div>
          <h3 className="mb-4">Alerts</h3>
          {alerts?.length === 0 && (
            <div className="w-full h-[100px] border border-white/30 rounded-lg flex justify-center items-center text-white/30">
              No alerts yet..
            </div>
          )}
          {alerts?.map((item, index) => {
            const sessionNum = item?.split("session ")[1];
            return (
              <div
                key={index}
                onMouseOver={() => setHovered(item)}
                onMouseOut={() => setHovered(null)}
                onClick={() => sessionNum && scrollToSource(sessionNum)}
                className="w-full md:max-w-[550px] flex gap-3 item-start mb-3 p-1 rounded-lg hover:bg-[#282929] cursor-pointer transition-all duration-300 relative"
              >
                {hovered === item && (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      removeAlert(index);
                    }}
                    className="bg-white/60 rounded-full p-1 absolute top-[5px] right-1"
                  >
                    <img
                      alt="user"
                      src="/images/icons8-close-50.png"
                      className="w-3 h-3"
                    />
                  </div>
                )}
                <img alt="user" src="/images/Bug.png" className="w-6 h-6" />
                <div>
                  <p className="text-white/80 text-[.85em]">{item}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div>
          <h3 className="mb-4">Suggestions</h3>
          {suggestion?.length === 0 && (
            <div className="w-full h-[100px] border border-white/30 rounded-lg flex justify-center items-center text-white/30">
              No suggestions yet..
            </div>
          )}
          {suggestion?.map((item, index) => {
            return (
              <div
                key={index}
                onMouseOver={() => setHovered(item)}
                onMouseOut={() => setHovered(null)}
                className="w-full md:max-w-[550px] flex gap-3 item-start mb-3 p-1 rounded-lg hover:bg-[#282929] cursor-pointer transition-all duration-300 relative"
              >
                {hovered === item && (
                  <div
                    onClick={() => removeSuggestion(index)}
                    className="bg-white/60 rounded-full p-1 absolute top-[5px] right-1"
                  >
                    <img
                      alt="user"
                      src="/images/icons8-close-50.png"
                      className="w-3 h-3"
                    />
                  </div>
                )}
                <img alt="user" src="/images/Bug.png" className="w-6 h-6" />
                <div>
                  <p className="text-white/80 text-[.85em]">{item}</p>
                </div>
              </div>
            );
          })}
        </div>

        <div className="w-full flex justify-center mt-5">
          <button
            onClick={() => AnalyzeSchedule()}
            className="w-fit text-[.85rem] px-8 py-1 rounded-full bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
          >
            Analyze Schedule
          </button>
        </div>
      </div>
    </div>
  );
};

export default ScheduleButtons;
