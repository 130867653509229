import React from "react";
// import { seedStudioOwners } from "services/seedFakeData";

// components
import FormWrap from "../UI/FormWrapper";
import ImgWrap from "../UI/ImgWrapper";

export default function Home() {
  return (
    <div className="flex flex-row flex-wrap h-[100vh]">
      <div className="lg:w-[40%] lg:flex hidden">
        {/* Left Components With Background Image */}
        <ImgWrap className="bg-[url('../public/Frame2.png')]" />
      </div>
      <div className="lg:w-[60%] w-full">
        {/* <button
          onClick={async () => {
            console.log("run button clicked");
            await seedStudioOwners(2000); // Await here
          }}
          className="px-10 py-2 bg-red-500 hover:bg-red-400 mt-10 text-white"
        >
          Run Seeding
        </button> */}
        {/* The Home right content is located here */}
        <FormWrap />
      </div>
    </div>
  );
}
