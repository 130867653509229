import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const BreadCrumb = ({ studioName }) => {
  const navigate = useNavigate();

  const location = useLocation();
  let currentPage = location.pathname;
  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem("event_name") || "";

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );

  // console.log("event_name", event_name);

  return (
    <div className="text-[.85em]">
      {/* if current path is at event selection */}
      {currentPage === "/studio/event-selection" && (
        <h1>
          Event Selection:{" "}
          {capitalizeFirstLetter(danceStudioCompData?.value?.competitionName)}
        </h1>
      )}

      {/* if current path is at competition selection */}
      {currentPage === "/studio/competition-selection" && (
        <h1>Competition Selection</h1>
      )}

      {/* if current path is at contact */}
      {currentPage === "/studio/contact" && <h1>Contact</h1>}

      {/* if current path is at settings */}
      {currentPage === "/studio/settings" && (
        <h1 className="flex gap-3 flex-wrap">
          {/* <span
            className="text-white/50 cursor-pointer"
            onClick={() => navigate("/studio/competition-selection")}
          >
            {capitalizeFirstLetter(studioName)}
          </span>
          <span>/</span> */}
          <span>Settings</span>
        </h1>
      )}

      {/* if current path is at event details */}
      {currentPage === `/studio/event/${event_name?.replace(/ /g, "-")}` && (
        <h1 className="flex gap-3 flex-wrap">
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => navigate("/studio/event-selection")}
          >
            Event Selection
          </span>
          <span>/</span>
          <span>{capitalizeFirstLetter(event_name?.replace(/-/g, " "))}</span>
        </h1>
      )}

      {/* if current path is at dancers */}
      {currentPage ===
        `/studio/event/${event_name?.replace(/ /g, "-")}/dancers` && (
        <h1 className="flex gap-3 flex-wrap">
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => navigate("/studio/competition-selection")}
          >
            {capitalizeFirstLetter(studioName)}
          </span>
          <span>/</span>
          <span>
            {capitalizeFirstLetter(danceStudioCompData?.value?.competitionName)}
          </span>
          <span>-</span>
          <span>Dancers</span>
        </h1>
      )}

      {/* if current path is at entries */}
      {currentPage ===
        `/studio/event/${event_name?.replace(/ /g, "-")}/entries` && (
        <h1 className="flex gap-3 flex-wrap">
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => navigate("/studio/event-selection")}
          >
            Event Selection
          </span>
          <span>/</span>
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => {
              navigate(`/studio/event/${event_name.replace(/ /g, "-")}`);
            }}
          >
            {capitalizeFirstLetter(event_name?.replace(/-/g, " "))}
          </span>
          <span>/</span>
          <span>Entries</span>
        </h1>
      )}

      {/* if current path is at schedule */}
      {currentPage ===
        `/studio/event/${event_name?.replace(/ /g, "-")}/schedule` && (
        <h1 className="flex gap-3 flex-wrap">
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => navigate("/studio/event-selection")}
          >
            Event Selection
          </span>
          <span>/</span>
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => {
              navigate(`/studio/event/${event_name.replace(/ /g, "-")}`);
            }}
          >
            Event: {capitalizeFirstLetter(event_name?.replace(/-/g, " "))}
          </span>
          <span>/</span>
          <span>Schedule</span>
        </h1>
      )}

      {/* if current path is at tabulator */}
      {currentPage ===
        `/studio/event/${event_name?.replace(/ /g, "-")}/scores` && (
        <h1 className="flex gap-3 flex-wrap">
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => navigate("/studio/event-selection")}
          >
            Event Selection
          </span>
          <span>/</span>
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => {
              navigate(`/studio/event/${event_name.replace(/ /g, "-")}`);
            }}
          >
            Event: {capitalizeFirstLetter(event_name?.replace(/-/g, " "))}
          </span>
          <span>/</span>
          <span>Scores</span>
        </h1>
      )}

      {/* if current path is at music */}
      {currentPage ===
        `/studio/event/${event_name?.replace(/ /g, "-")}/musics` && (
        <h1 className="flex gap-3 flex-wrap">
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => navigate("/studio/event-selection")}
          >
            Event Selection
          </span>
          <span>/</span>
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => {
              navigate(`/studio/event/${event_name.replace(/ /g, "-")}`);
            }}
          >
            Event: {capitalizeFirstLetter(event_name?.replace(/-/g, " "))}
          </span>
          <span>/</span>
          <span>Musics</span>
        </h1>
      )}

      {/* if current page is accounting overview */}
      {currentPage ===
        `/studio/event/${event_name?.replace(
          / /g,
          "-"
        )}/accounting-overview` && (
        <h1 className="flex gap-3 flex-wrap">
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => navigate("/studio/event-selection")}
          >
            Event Selection
          </span>
          <span>/</span>
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => {
              navigate(`/studio/event/${event_name.replace(/ /g, "-")}`);
            }}
          >
            Event: {capitalizeFirstLetter(event_name?.replace(/-/g, " "))}
          </span>
          <span>/</span>
          <span>Accounting Overview</span>
        </h1>
      )}
      {/* if current page is dancers fees */}
      {currentPage?.includes("dancers-fees") && (
        <h1 className="flex gap-3 flex-wrap">
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => navigate("/studio/event-selection")}
          >
            Event Selection
          </span>
          <span>/</span>
          <span
            className="text-white/50 cursor-pointer"
            onClick={() => {
              navigate(`/studio/event/${event_name.replace(/ /g, "-")}`);
            }}
          >
            Event: {capitalizeFirstLetter(event_name?.replace(/-/g, " "))}
          </span>
          <span>/</span>
          <span>Accounting Overview - Dancers</span>
        </h1>
      )}
    </div>
  );
};

export default BreadCrumb;
