import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useFormik } from "formik";
import * as Yup from "yup";

// Components
import ImgWrap from "../UI/ImgWrapper";
import Container from "../UI/Container";
import Logo from "../../assets/Logo.png";
import Button from "../Form/Button";
import ButtonLoadingSpinner from "utils/ButtonLoadingSpinner";
import { addStudioData } from "services/organizerService";
import { useSelector } from "react-redux";
import { setFormCompleted } from "redux/auth/authSlice";
import { useDispatch } from "react-redux";

const StudioInfo = () => {
  const validationSchema = Yup.object().shape({
    ownerFirstName: Yup.string()
      .required("First Name is required")
      .matches(/^[^0-9]*$/, "First Name should not contain numbers"),
    ownerLastName: Yup.string()
      .required("Last Name is required")
      .matches(/^[^0-9]*$/, "Last Name should not contain numbers"),
    addressLine1: Yup.string().required("Address Line 1 is required"),
    addressLine2: Yup.string(),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State/Province is required"),
    postalCode: Yup.string().required("Postal Code is required"),
    phone: Yup.string().required("Phone number is required"),
    website: Yup.string().required("Website is required"),
    // .matches(
    //   /^[a-zA-Z0-9]*$/,
    //   "Website should contain only letters and numbers"
    // ),
  });

  const formik = useFormik({
    initialValues: {
      ownerFirstName: "",
      ownerLastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      postalCode: "",
      phone: "",
      website: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveStudioInfo(values);
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(
    (state) => state.persistedReducer.auth.user
  );
  const [buttonText, setButtonText] = useState("Done");
  const [loading, setLoading] = useState(false);

  const saveStudioInfo = async (data) => {
    setLoading(true);

    const { user } = isAuthenticated;
    // Convert form values to lowercase
    const lowercaseData = {
      ...data,
      ownerFirstName: data.ownerFirstName.toLowerCase(),
      ownerLastName: data.ownerLastName.toLowerCase(),
      city: data.city.toLowerCase(),
      state: data.state.toLowerCase(),
    };
    const formData = { formCompleted: true, ...lowercaseData };

    try {
      const result = await addStudioData(formData, user.uid);

      if (result === "Registration Successful") {
        setButtonText("Registration Successful");
        dispatch(setFormCompleted(true));
        navigate("/studio/competition-selection");
        //
      } else {
        setButtonText("Registration Failed");
      }
    } catch (error) {
      console.error("Error saving studio info: ", error);
      setButtonText("Registration Failed");
    }

    setLoading(false);
  };

  const goToPreviousPage = () => {
    navigate("/login");
  };

  return (
    <>
      <div className="flex flex-row flex-wrap h-[100vh]">
        <div className="lg:flex hidden lg:w-[40%]">
          <ImgWrap className="bg-[url('../public/Frame9.png')]" />
        </div>
        <div className="lg:w-[60%] w-full">
          <Container className="flex flex-wrap lg:px-[6%]">
            <div className="flex flex-row justify-between w-full lg:justify-end">
              <div className="block lg:hidden">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Logo"
                    className="cursor-pointer w-[90px] h-[25px]"
                  />
                </Link>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-sm font-medium text-[#BDBDBD]">
                  STEP 02/02
                </span>
                <h4 className="text-base font-semibold text-[#8692A6]">
                  Studio Owner Registration
                </h4>
              </div>
            </div>

            <div className="flex flex-col flex-wrap pt-[5%] w-full ">
              <div className="lg:pl-[5%]">
                <h1 className="mb-3 text-3xl font-bold text-[#000000] dark:text-white">
                  Studio Info
                </h1>
                <p className="text-lg font-normal text-[#8692A6]">
                  Verifying studio
                </p>
                <div className="border-t border-base w-[60%] mt-5"></div>
              </div>
              <div className="flex flex-wrap mt-5 lg:pl-[5%]">
                <form onSubmit={formik.handleSubmit}>
                  <div className="flex flex-col w-full">
                    <div className="flex sm:flex-col md:flex-col lg:flex-row gap-4 justify-between w-full ">
                      <div className="flex flex-col w-[48%] sm:w-full">
                        <label className="mb-2 text-base font-medium text-[#8692A6] dark:text-white">
                          Studio Owner
                        </label>
                        <input
                          type="text"
                          name="ownerFirstName"
                          placeholder="First Name"
                          className="w-full h-[40px] px-3 border border-[#E5E5E5] rounded-[5px] outline-none placeholder-[#8692A6]"
                          value={formik.values.ownerFirstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.ownerFirstName &&
                        formik.errors.ownerFirstName ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.ownerFirstName}
                          </div>
                        ) : null}
                      </div>
                      <div className="flex flex-col w-[48%] sm:w-full">
                        <label className="mb-2 text-base font-medium text-transparent">
                          {"."}
                        </label>
                        <input
                          type="text"
                          name="ownerLastName"
                          placeholder="Last Name"
                          className="w-full h-[40px] px-3 border border-[#E5E5E5] rounded-[5px] outline-none placeholder-[#8692A6]"
                          value={formik.values.ownerLastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.ownerLastName &&
                        formik.errors.ownerLastName ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.ownerLastName}
                          </div>
                        ) : null}
                      </div>
                    </div>

                    {/* Address */}
                    <div className="flex flex-col w-[100%] mt-4">
                      <label className="mb-2 text-base font-medium text-[#8692A6] dark:text-white">
                        Studio Address
                      </label>
                      <input
                        type="text"
                        name="addressLine1"
                        placeholder="Street Address"
                        className="w-full h-[40px] px-3 border border-[#E5E5E5] rounded-[5px] outline-none placeholder-[#8692A6]"
                        value={formik.values.addressLine1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.addressLine1 &&
                      formik.errors.addressLine1 ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.addressLine1}
                        </div>
                      ) : null}
                      <input
                        type="text"
                        name="addressLine2"
                        placeholder="Street Address Line 2"
                        className="w-full h-[40px] px-3 border border-[#E5E5E5] rounded-[5px] outline-none placeholder-[#8692A6] mt-3"
                        value={formik.values.addressLine2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {/* City and State */}
                    <div className="flex flex-row w-[100%] mt-4 items-center gap-4 justify-between">
                      <input
                        type="text"
                        name="city"
                        placeholder="City"
                        className="w-full h-[40px] px-3 border border-[#E5E5E5] rounded-[5px] outline-none placeholder-[#8692A6] "
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.city && formik.errors.city ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.city}
                        </div>
                      ) : null}
                      <input
                        type="text"
                        name="state"
                        placeholder="State/Province"
                        className="w-full h-[40px] px-3 border border-[#E5E5E5] rounded-[5px] outline-none placeholder-[#8692A6]"
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.state && formik.errors.state ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.state}
                        </div>
                      ) : null}
                    </div>

                    <div className="flex flex-col w-[48%] mt-4 items-center gap-4 sm:w-full md:w-full">
                      <input
                        type="text"
                        name="postalCode"
                        placeholder="Postal Code"
                        className="w-full h-[40px] px-3 border border-[#E5E5E5] rounded-[5px] outline-none placeholder-[#8692A6] "
                        value={formik.values.postalCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.postalCode && formik.errors.postalCode ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.postalCode}
                        </div>
                      ) : null}
                      <input
                        type="text"
                        name="phone"
                        placeholder="Phone Number"
                        className="w-full h-[40px] px-3 border border-[#E5E5E5] rounded-[5px] outline-none placeholder-[#8692A6] "
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.phone}
                        </div>
                      ) : null}
                      <input
                        type="text"
                        name="website"
                        placeholder="Website"
                        className="w-full h-[40px] px-3 border border-[#E5E5E5] rounded-[5px] outline-none placeholder-[#8692A6]"
                        value={formik.values.website}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.website && formik.errors.website ? (
                        <div className="text-red-500 text-sm">
                          {formik.errors.website}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex flex-row justify-end mt-[25%] mb-[15px] w-full">
                    <Button
                      text="Previous"
                      handleOnClick={goToPreviousPage}
                      className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium mr-3 h-8"
                    />
                    <button
                      className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium mr-3 h-8 flex items-center justify-center"
                      type="submit"
                      disabled={loading}
                    >
                      <span className={loading ? " text-white mr-2" : ""}>
                        {loading ? "is submitting" : buttonText}
                      </span>
                      {loading && <ButtonLoadingSpinner className="ml-2" />}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default StudioInfo;
