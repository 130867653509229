/* eslint-disable react-hooks/exhaustive-deps */
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  // onSnapshot,
  startAt,
  getDocs,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "services/firebase";

export const useFetchPaginatedScheduleDocs = (
  collectionName,
  compId,
  subCollection,
  filterField,
  filterValue,
  pageSize,
  startAfterDoc,
  startBeforeDoc,
  setcurrentPage,
  setTotal,
  currentPage,
  addBreakSuccess,
  addBufferSuccess,
  addScheduleOverallMod,
  addScheduleAwardMod,
  addSessionMod,
  deleteBreakSuccess,
  setTriggerFetch,
  triggerFetch,
  splitCategorySuccess
) => {
  const [eventCategories, setEventCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      if (filterValue) {
        let ref = collection(db, collectionName, compId, subCollection);
        let dataQuery = query(
          ref,
          where(filterField, "==", filterValue || ""),
          orderBy("sort_id"),
          limit(pageSize)
        );
        let normalQuery = query(
          ref,
          where(filterField, "==", filterValue || "")
        );

        const snapshotSize = await getDocs(normalQuery);

        const totalParse = Math.ceil(snapshotSize?.size / 15);
        setTotal(snapshotSize?.size);

        if (startAfterDoc) {
          console.log("startAfterDoc", startAfterDoc);
          dataQuery = query(dataQuery, startAfter(startAfterDoc));
          triggerFetch &&
            setcurrentPage((prev) =>
              currentPage + 1 === totalParse ? totalParse : prev + 1
            );
          setTriggerFetch(false);
        } else if (startBeforeDoc) {
          console.log("startAtDoc", startBeforeDoc);
          dataQuery = query(dataQuery, startAt(startBeforeDoc));
          triggerFetch && setcurrentPage((prev) => (prev === 1 ? 1 : prev - 1));
          setTriggerFetch(false);
        }

        const snapshot = await getDocs(dataQuery);
        const data = snapshot.docs.map((doc) => doc.data());
        setEventCategories(data);
        setLoading(false);
      } else {
        setEventCategories([]);
        setLoading(false);
      }
    }
    fetch();
  }, [
    filterValue,
    startAfterDoc,
    startBeforeDoc,
    addBreakSuccess,
    addBufferSuccess,
    addScheduleOverallMod,
    addScheduleAwardMod,
    addSessionMod,
    deleteBreakSuccess,
    splitCategorySuccess
  ]);

  return { eventCategories, loading };
};
