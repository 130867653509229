/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  clearSuccess,
  combineCategory,
  deleteBreak,
  deleteCategory,
  scratchCategory,
  splitCategory,
} from "redux/routineScheduling/routineSchedulingSlice";
import { db } from "services/firebase";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import calculateEntryStartTime from "utils/calculateEntryStartTime";
import sumTimeValues from "utils/sumTimeValues";
import RoutineEntry from "./RoutineEntry";
import CategoryDropdownCont from "./CategoryDropdownCont";
import performBackgroundUpdate from "utils/performBackgroundUpdate";
import calculateFractionalIndex from "utils/calculateFractionalIndex";

// Custom droppable component to work around React StrictMode
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const RoutineCategory = ({
  category,
  index,
  allEntries,
  sortedCategories,
  currentEvent,
  loading,
  // selectedCategory,
  toggleDropdown,
  closeDropdown,
  // setShowDropdownRow,
  // showDropdownRow,
  // setSelectedCategory,
  setLoadingEntry,
  loadingEntry,
  notifyLocked,
  confrmDrag,
  showNotes,
  setShowNotes,
  setShowMoveCategory,
  showMoveCategory,
  setLoading,
  showMoveEntry,
  setShowMoveEntry,
  setAllowDrag,
  scheduling,
  closeDropdownAll,
  status,
  // setShowNames,
  // showNames,
  allowDrag,
  // setRerender,
  rerender,
  showMoveCategoryBefore,
  setShowMoveCategoryBefore,
  setSortedCategories,
  setBackgroundUpdateOutcome,
  allCats,
  affectedItems,
  setaffectedItems,
  setLastEntryTime,
  lastPageData,
  currentPage,
}) => {
  const {
    deleteCategoryPending,
    deleteCategorySuccess,
    splitCategoryPending,
    combineCategoryPending,
  } = useSelector((state) => state.routineScheduling);
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  // console.log("render");
  const [selectedCategory, setSelectedCategory] = useState(null);

  const dispatch = useDispatch();

  const myEntries = allEntries?.filter(
    (entry) => entry?.category_name === category?.category_name
  );

  let myEntriesSorted = myEntries?.sort((a, b) => a?.sort_id - b?.sort_id);

  function removeDuplicates(array) {
    const uniqueArray = [];
    const seenObjects = new Set();

    for (const obj of array) {
      // Serialize the object to JSON to use it as a unique identifier
      const serializedObj = JSON.stringify(obj);

      if (!seenObjects.has(serializedObj)) {
        seenObjects.add(serializedObj);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  }

  //===============================================================================to calc dynamic entry count of all entries in routine
  const lastPage = lastPageData?.filter((x) => x?.page === currentPage - 1)[0];

  // const entryCountFinal = entryCount(item) + (lastPage?.lastEntryCount || 0);

  const categories = sortedCategories?.filter((l) => l?.type === "category");

  const allEntriesWithoutLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  )?.filter((k) => k?.locked_entry === false);

  const allEntriesWithLocked = arrangeEntriesByCategories(
    categories,
    allEntries
  );

  // console.log("removeDuplicates", removeDuplicates(allEntriesWithoutLocked));

  function entryCount(itm) {
    const allEntriesBeforeLocked = allEntriesWithLocked
      ?.slice(
        0,
        findIndexByProperty(allEntriesWithLocked, "doc_id", itm?.doc_id)
      )
      ?.filter((m) => m?.locked_entry === false);

    if (itm?.locked_entry) {
      return `${
        allEntriesBeforeLocked?.length === 0
          ? 1
          : allEntriesBeforeLocked?.length
      }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
    } else {
      return (
        findIndexByProperty(allEntriesWithoutLocked, "doc_id", itm?.doc_id) +
        1 +
        (lastPage?.lastEntryCount || 0)
      );
    }
  }

  //============================================================================================new entry time calculation

  // to get all categories before current category
  const catIndex = findIndexByProperty(
    sortedCategories,
    "no_id",
    category?.no_id
  );
  const categoriesBeforeCurrent = sortedCategories?.slice(0, catIndex) || [];

  const allBufferBeforeCurrent = categoriesBeforeCurrent?.filter(
    (cate) => cate?.type === "buffer"
  );
  const timeToAdd = allBufferBeforeCurrent?.reduce((sum, obj) => {
    if (obj.type === "buffer") {
      const bufferValue = Number(obj.buffer);
      if (!isNaN(bufferValue)) {
        return sum + bufferValue;
      }
    }
    return sum;
  }, 0);

  function addMinutesToTime(inputTime, minutesToAdd) {
    const timeMoment = moment(inputTime, "h:mm A");
    const endTime = timeMoment.clone().add(minutesToAdd, "minutes");
    const formattedEndTime = endTime.format("h:mm A");
    return formattedEndTime;
  }

  const startTime = currentEvent?.days[0]?.startTime;

  // const initialTime = moment(startTime).utc().format("h:mm A");

  const initialTime =
    currentPage === 1
      ? moment(startTime).utc().format("h:mm A")
      : lastPage?.lastEntryTime;

  // console.log("initialTime", initialTime);

  //to get the entry count of all entries in routine
  function entryTime(itm) {
    const idx = findIndexByProperty(
      removeDuplicates(allEntriesWithoutLocked),
      "doc_id",
      itm?.doc_id
    );
    if (idx === 0 && currentPage === 1) {
      return addMinutesToTime(initialTime, timeToAdd);
    } else {
      const allEntriesBeforeCurrent = removeDuplicates(
        allEntriesWithoutLocked
      )?.slice(
        0,
        findIndexByProperty(
          removeDuplicates(allEntriesWithoutLocked),
          "doc_id",
          itm?.doc_id
        )
      );

      const allTimeBeforeCurrent = sumTimeValues(allEntriesBeforeCurrent);
      const startTime = calculateEntryStartTime(
        initialTime,
        allTimeBeforeCurrent,
        itm
      );

      return addMinutesToTime(startTime, timeToAdd);
    }
  }

  //============================================================================to handle the delete category modal
  const [deleteCategoryMod, setDeleteCategoryMod] = useState(false);
  function openDeleteMod() {
    setDeleteCategoryMod(true);
  }

  function closeMod() {
    setDeleteCategoryMod(false);
  }
  useEffect(() => {
    if (deleteCategorySuccess?.success) {
      setTimeout(() => {
        dispatch(clearSuccess());
        closeDropdown();
      }, 500);
    }
  }, [deleteCategorySuccess]);

  //===============================================================================================to handle dnd for entries
  const handleDragEnd = async (result) => {
    setLoadingEntry(true);
    closeDropdown();
    const { source, destination } = result;

    if (!destination || source?.index === destination?.index) {
      return;
    }

    const updatedEntries = Array.from(myEntriesSorted);
    const draggedRow = updatedEntries[source?.index];

    // Remove the dragged entry from its original position
    updatedEntries?.splice(source?.index, 1);

    // Insert the dragged entry at the destination index
    updatedEntries?.splice(destination?.index, 0, draggedRow);

    // Update the sort_id for all categories after the dragged entry
    const maxSortUd = updatedEntries?.reduce((maxSortId, obj) => {
      return obj.sort_id > maxSortId ? obj.sort_id : maxSortId;
    }, updatedEntries[0]?.sort_id);

    updatedEntries.forEach((category, index) => {
      if (index >= destination?.index) {
        category.sort_id = index + 1 + maxSortUd;
      }
    });

    myEntriesSorted = updatedEntries;

    //update docuents on firestore here

    myEntriesSorted?.forEach(async (entry) => {
      // setLoading(true);
      try {
        // Update the Firestore document with the new sort_id using updateDoc function
        const collectionRef = collection(
          db,
          "competitions",
          user?.uid,
          "entries"
        );
        const docRef = doc(collectionRef, entry?.doc_id);
        await updateDoc(docRef, { sort_id: entry.sort_id });
        console.log({ success: true });
      } catch (error) {
        console.error("Error updating entries sort_id:", error);
      }
    });

    setLoadingEntry(true);
    closeDropdown();
  };

  const sessionsBeforeCurrent = categoriesBeforeCurrent?.filter(
    (item) => item?.break_type === "session"
  );

  function toggleMoveCate() {
    setShowMoveCategory((prev) => !prev);
  }

  function toggleMoveCateBefore() {
    setShowMoveCategoryBefore((prev) => !prev);
  }

  //==============================================================================================functio to handle move category to after..
  const rearrangeCategories = async (id1, id2) => {
    setBackgroundUpdateOutcome("");
    // setLoading(true);

    const sourceIndex = sortedCategories?.findIndex(
      (category) => category?.id === id1
    );

    const destinationIndex = sortedCategories?.findIndex(
      (category) => category?.id === id2
    );

    const updatedCategory = [...sortedCategories];
    const draggedRow = updatedCategory[sourceIndex];

    // Remove the dragged category from its original position
    updatedCategory?.splice(sourceIndex, 1);

    // Insert the dragged category at the destination index
    updatedCategory?.splice(destinationIndex, 0, draggedRow);

    // Calculate the new sort_id using fractional indexing
    const previousSortId =
      destinationIndex === 0
        ? 0
        : updatedCategory[destinationIndex - 1].sort_id;
    const nextSortId =
      destinationIndex === updatedCategory.length - 1
        ? destinationIndex + 2
        : updatedCategory[destinationIndex + 1].sort_id;

    const newSortId = calculateFractionalIndex(previousSortId, nextSortId);
    console.log("newSortId", newSortId);

    draggedRow.sort_id = newSortId;

    setSortedCategories(updatedCategory);

    //add dragged row to the affected items
    let allAffectedItems = [...affectedItems];
    const index = allAffectedItems.findIndex(
      (item) => item.id === draggedRow.id
    );

    if (index !== -1) {
      // If the draggedRow exists, replace it in the affectedItems array
      allAffectedItems[index] = draggedRow;
    } else {
      // If the draggedRow doesn't exist, add it to the allAffectedItems array
      allAffectedItems.push(draggedRow);
    }

    localStorage.setItem("affectedItems", JSON.stringify(allAffectedItems));
    setaffectedItems(allAffectedItems);
  };

  // const rearrangeCategories = async (id1, id2) => {
  //   setBackgroundUpdateOutcome("");
  //   setLoading(true);

  //   const sourceIndex = sortedCategories?.findIndex(
  //     (category) => category?.id === id1
  //   );

  //   const destinationIndex = sortedCategories?.findIndex(
  //     (category) => category?.id === id2
  //   );

  //   console.log("sourceIndex", sourceIndex);
  //   console.log("destinationIndex", destinationIndex);

  //   const updatedCategory = [...sortedCategories];
  //   const draggedRow = updatedCategory[sourceIndex];

  //   // Remove the dragged category from its original position
  //   updatedCategory?.splice(sourceIndex, 1);

  //   // Adjust the destination index in case the dragged item is above the destination
  //   const adjustedDestinationIndex =
  //     destinationIndex > sourceIndex ? destinationIndex - 1 : destinationIndex;

  //   // Insert the dragged category after the destination index
  //   updatedCategory?.splice(adjustedDestinationIndex + 1, 0, draggedRow);

  //   updatedCategory?.forEach((category, index) => {
  //     category.sort_id = index + 1;
  //   });

  //   setSortedCategories(updatedCategory);

  //   // Store affected items in localStorage for ongoing updates
  //   localStorage.setItem("firestoreUpdates", JSON.stringify(updatedCategory));

  //   // Perform Firestore updates in the background
  //   await performBackgroundUpdate(
  //     updatedCategory,
  //     user,
  //     setLoading,
  //     setBackgroundUpdateOutcome
  //   );
  // };

  // const rearrangeCategoriesBefore = async (id1, id2) => {
  //   setBackgroundUpdateOutcome("");
  //   setLoading(true);

  //   const sourceIndex = sortedCategories?.findIndex(
  //     (category) => category?.id === id1
  //   );

  //   const destinationIndex = sortedCategories?.findIndex(
  //     (category) => category?.id === id2
  //   );

  //   const updatedCategory = [...sortedCategories];
  //   const draggedRow = updatedCategory[sourceIndex];

  //   // Remove the dragged category from its original position
  //   updatedCategory?.splice(sourceIndex, 1);

  //   // Adjust the destination index in case the dragged item is below the destination
  //   const adjustedDestinationIndex =
  //     destinationIndex < sourceIndex ? destinationIndex : destinationIndex - 1;

  //   // Insert the dragged category before the adjusted destination index
  //   updatedCategory?.splice(adjustedDestinationIndex, 0, draggedRow);

  //   updatedCategory?.forEach((category, index) => {
  //     category.sort_id = index + 1;
  //   });

  //   setSortedCategories(updatedCategory);

  //   // Store affected items in localStorage for ongoing updates
  //   localStorage.setItem("firestoreUpdates", JSON.stringify(updatedCategory));

  //   // Perform Firestore updates in the background
  //   await performBackgroundUpdate(
  //     updatedCategory,
  //     user,
  //     setLoading,
  //     setBackgroundUpdateOutcome
  //   );
  // };

  const eachEntry = myEntriesSorted?.map((item, idx) => {
    const notesWithoutChoreoAndSpecial = item?.notes?.filter(
      (note) =>
        note?.type !== "Choreography Award Note" &&
        note?.type !== "judge special"
    );

    return (
      <RoutineEntry
        key={idx}
        idx={idx}
        item={item}
        entryTime={entryTime}
        entryCount={entryCount}
        category={category}
        notesWithoutChoreoAndSpecial={notesWithoutChoreoAndSpecial}
        loading={loading}
        // showDropdownRow={showDropdownRow}
        // setShowDropdownRow={setShowDropdownRow}
        currentEvent={currentEvent}
        closeDropdown={closeDropdown}
        setSelectedCategory={setSelectedCategory}
        notifyLocked={notifyLocked}
        allEntries={allEntries}
        myEntriesSorted={myEntriesSorted}
        sortedCategories={sortedCategories}
        setShowNotes={setShowNotes}
        showNotes={showNotes}
        setShowMoveEntry={setShowMoveEntry}
        showMoveEntry={showMoveEntry}
        setLoadingEntry={setLoadingEntry}
        rearrangeCategories={rearrangeCategories}
        // setShowNames={setShowNames}
        // showNames={showNames}
        confrmDrag={confrmDrag}
        rerender={rerender}
        setLastEntryTime={setLastEntryTime}
        lastPageData={lastPageData}
        currentPage={currentPage}
      />
    );
  });
  //======================================================================================to handle split category
  const [allowSplit, setAllowSplit] = useState(false);
  const [splitError, setsplitError] = useState(false);

  useEffect(() => {
    if (myEntriesSorted?.length >= Number(scheduling?.splitCategories || "2")) {
      setAllowSplit(true);
    } else {
      setAllowSplit(false);
    }
  }, [scheduling]);

  const splitCategoryData = {
    category_name: `${category?.category_name}(B)`,
    compId: user?.uid,
    event_uid: currentEvent?.event_uid,
    main_category: category?.category_name,
    main_sort_id: category?.sort_id,
    no_id: category?.no_id,
  };

  const oldCat = sortedCategories?.filter(
    (x) => x?.category_name === category?.category_name?.replace(/[\(\)B]/g, "")
  )[0];

  const combineCategoryData = {
    splitCategoryName: `${category?.category_name?.replace(/[\(\)B]/g, "")}(B)`,
    compId: user?.uid,
    event_uid: currentEvent?.event_uid,
    newCategoryName: `${category?.category_name?.replace(/[\(\)B]/g, "")}`,
    main_no_id: oldCat?.no_id,
  };

  function handleSplitCategory() {
    if (!splitCategoryPending) {
      if (!allowSplit) {
        setsplitError(true);
        return;
      }

      dispatch(splitCategory(splitCategoryData)).then(() => {
        closeDropdownAll();
        dispatch(clearSuccess());
        // setRerender((prev) => !prev);
      });
    }
  }

  function handleCombineCategory() {
    dispatch(combineCategory(combineCategoryData)).then(() => {
      closeDropdownAll();
      // setRerender((prev) => !prev);
    });
  }

  return (
    <div
      onClick={(e) => {
        e?.stopPropagation();
        currentEvent?.locked && !confrmDrag
          ? notifyLocked()
          : setSelectedCategory((prev) => (prev ? null : category));
        // setShowNames(null);
      }}
      className={`${
        selectedCategory?.id === category?.id && !currentEvent?.locked
          ? "mb-[80px]"
          : selectedCategory?.id === category?.id &&
            currentEvent?.locked &&
            confrmDrag
          ? "mb-[80px]"
          : ""
      } relative`}
    >
      {category?.is_scratched && (
        <div className="w-full h-full bg-stone-700/40 absolute top-0 left-0"></div>
      )}
      <div
        // onMouseEnter={(e) => {
        //   e.stopPropagation();
        //   setAllowDrag(true);
        // }}
        // onMouseLeave={(e) => {
        //   e.stopPropagation();
        //   setAllowDrag(false);
        // }}
        className={`w-full min-w-[900px] p-3 lg:text-center font-bold cursor-pointer relative border-y border-gray-500/10 flex items-center ${
          category?.type === "buffer"
            ? "bg-[#4f2d54]"
            : category?.break_type === "session"
            ? "bg-[#42125f]"
            : category?.break_type?.includes("overall") ||
              category?.break_type?.includes("award")
            ? "bg-black/20"
            : "bg-[#282929]"
        } ${
          category?.break_type?.includes("overall") ||
          category?.break_type?.includes("award")
            ? "uppercase"
            : ""
        }  ${
          myEntriesSorted?.length === 0 &&
          category?.type === "category" &&
          "hidden"
        }`}
      >
        {category?.is_scratched && (
          <img alt="" src="/images/scratchCat.png" className="w-5 h-3" />
        )}
        <h2
          id={
            category?.break_type === "session"
              ? sessionsBeforeCurrent?.length +
                1 +
                (lastPage?.lastSession ? lastPage?.lastSession : 0)
              : category?.id
          }
          className={`${
            category?.is_scratched ? "text-white/50" : "text-white"
          } ${
            category?.type !== "category" && "uppercase"
          } flex gap-1 items-center lg:justify-center lg:mx-auto`}
        >
          {capitalizeFirstLetter(category?.category_name?.replace(/_/g, " "))}
          {category?.split && !category?.category_name?.includes("(") && (
            <span className="">(A)</span>
          )}
          {category?.type === "category" && (
            <span className="">({myEntriesSorted?.length})</span>
          )}
          {/*========================================================================= to dynamically render overalls or grand award tags */}
          {category?.break_type?.includes("overalls_") && (
            <span className="">
              {category?.break_type?.split("_")[1] === "overall"
                ? "OVERALLS"
                : category?.break_type?.split("_")[1] === "grand"
                ? "GRAND OVERALLS"
                : "CUSTOM REPORT"}
            </span>
          )}
          {/* to dynamically render sessions count */}
          {category?.break_type === "session" && (
            <span className="">
              {" "}
              {sessionsBeforeCurrent?.length === 0 && !lastPage?.lastSession
                ? 1
                : sessionsBeforeCurrent?.length +
                  1 +
                  (lastPage?.lastSession ? lastPage?.lastSession : 0)}
            </span>
          )}
        </h2>
        {!category?.is_scratched && (
          <div
            onClick={() => {
              console.log("clear", loadingEntry);
              setLoadingEntry(false);
            }}
            className="w-full h-full absolute top-0 left-0"
          ></div>
        )}
      </div>

      {/* entries container */}
      <div className="w-full">
        {/* thr entry rows dnd container */}
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={() => {
            console.log("loadingEntry", loadingEntry);
            closeDropdown();
          }}
          onDragUpdate={(update) => {
            const { destination } = update;
            if (!destination) return;

            const draggedElement = document.querySelector(".dragging"); // Assumes a class name for the dragged element
            if (draggedElement) {
              draggedElement.style.left = `${update?.clientOffset?.x}px`;
              draggedElement.style.top = `${update?.clientOffset?.y}px`;
            }
          }}
        >
          <StrictModeDroppable droppableId="entry-list">
            {(provided) => (
              <div
                className="w-full relative"
                ref={provided.innerRef}
                {...provided.droppableProps}
                onMouseDown={(e) => e.stopPropagation()}
              >
                {eachEntry}
                {provided.placeholder}
              </div>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </div>

      {/* the dropdown on click */}
      {selectedCategory?.id === category?.id && !loadingEntry && (
        <div
          className={`w-[270px] bg-[#282929] p-3 rounded-lg absolute top-0 left-0 font-normal shadow-lg z-[1] ${
            category?.type !== "category" && "border border-white/40"
          }`}
        >
          <CategoryDropdownCont
            showMoveCategory={showMoveCategory}
            showMoveCategoryBefore={showMoveCategoryBefore}
            toggleMoveCate={toggleMoveCate}
            sortedCategories={sortedCategories}
            selectedCategory={selectedCategory}
            category={category}
            scratchCategory={scratchCategory}
            currentEvent={currentEvent}
            openDeleteMod={openDeleteMod}
            user={user}
            rearrangeCategories={rearrangeCategories}
            loading={loading}
            handleSplitCategory={handleSplitCategory}
            splitCategoryPending={splitCategoryPending}
            combineCategoryPending={combineCategoryPending}
            handleCombineCategory={handleCombineCategory}
            toggleMoveCateBefore={toggleMoveCateBefore}
            rearrangeCategoriesBefore={rearrangeCategories}
          />

          {/* if category is a break */}
          {category?.type !== "category" &&
            !showMoveCategory &&
            !showMoveCategoryBefore && (
              <ul className="flex flex-col gap-1 mt-2">
                <li
                  onClick={() =>
                    dispatch(
                      deleteBreak({
                        compId: user?.uid,
                        doc_id: category?.doc_id,
                      })
                    )
                  }
                  className="flex gap-2 items-center py-1 px-2 rounded-lg hover:bg-[#94a4fd]/60"
                >
                  <img alt="" src="/images/deleteCat.png" className="w-3 h-3" />{" "}
                  Delete {category?.type === "buffer" ? "Buffer" : ""}
                </li>
              </ul>
            )}
        </div>
      )}

      {splitError && (
        <div className="w-screen h-screen fixed top-0 left-0 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start z-[999]">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 text-center">
              Categories can only be splitted if it contains at least{" "}
              {Number(scheduling?.splitCategories || "2")} entries!
            </p>

            <div className="mt-4 w-full text-center">
              <button
                onClick={() => setsplitError(false)}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {deleteCategoryMod && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg">
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50">
              Confirm Delete Category:
              <br />
              <span className="font-bold">{`${category?.category_name}`}</span>?
            </p>

            <p className="text-white/70">
              NOTE: Deleted categories can be viewed in the "View deleted page".
              <br />
              Are you sure you want to proceed with the changes?
            </p>

            <div className="mt-4 w-full text-center">
              <button
                onClick={closeMod}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(
                    deleteCategory({
                      compId: user?.uid,
                      doc_id: category?.category_name,
                      no_id: category?.no_id,
                      event_uid: currentEvent?.event_uid,
                      currentEvent,
                    })
                  );
                }}
                disabled={deleteCategoryPending}
                className="text-[.9rem] px-8 py-2 rounded-md bg-[#94a4fd] hover:bg-[#94a4fd]/60 text-[#1c1c1c] transition-all duration-300"
              >
                {deleteCategoryPending ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoutineCategory;
