/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import DancersFeeRow from './DancersFeeRow';
import { PulseLoader } from 'react-spinners';
import { useQueryAllSubCollectionDocs } from 'components/hooks/useQueryAllSubCollectionDocs';
import { useFirestoreQueryDoc } from 'components/hooks/useFirestoreQueryDoc';

const DancersFeeTable = ({
  allDancers,
  searchQuery,
  searchResults,
  currentEvent,
  status,
  sortDown,
}) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );
  const { compId } = danceStudioCompData.value;

  const { data: studioData } = useFirestoreQueryDoc('studio_owners', user?.uid);

  //to get all entries
  const { data: allEntries } = useQueryAllSubCollectionDocs(
    'competitions',
    compId,
    'entries'
  );

  const eventEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === currentEvent?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  let arranged = allDancers && [...allDancers];

  // eslint-disable-next-line array-callback-return
  arranged?.sort((a, b) => {
    const parseCustomDate = (dateString) => {
      // Replace the comma with an empty string to remove it
      const formattedDate = dateString.replace(',', '');

      // Parse the date using the Date constructor
      const date = new Date(formattedDate);

      // Check if the date is valid
      if (!isNaN(date)) {
        return date.getTime();
      } else {
        // Handle cases where the date couldn't be parsed
        return 0;
      }
    };

    const dateA = parseCustomDate(a.last_update);
    const dateB = parseCustomDate(b.last_update);

    if (sortDown === 'oldest') {
      return dateA - dateB; // Oldest to newest
    } else if (sortDown === 'latest') {
      return dateB - dateA; // Newest to oldest
    } else if (sortDown === 'alphabet') {
      const nameA = a.first_name.toUpperCase();
      const nameB = b.first_name.toUpperCase();

      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    }
  });

  const [dancerIndex, setdancerIndex] = useState(false);

  //to handle registrations table pagination
  // eslint-disable-next-line no-unused-vars

  const [dancersDataPag, setdancersDataPag] = useState();
  useEffect(() => {
    setdancersDataPag(arranged);
  }, [status, sortDown]);

  const [pageNumber, setPageNumber] = useState(0);

  const rowPerPage = 8;
  const pagesVisited = pageNumber * rowPerPage;

  const displayRows = dancersDataPag
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      return (
        <DancersFeeRow
          row={row}
          key={index}
          setdancerIndex={setdancerIndex}
          dancerIndex={dancerIndex}
          arranged={arranged}
          currentEvent={currentEvent}
          eventEntries={eventEntries}
        />
      );
    });

  const displaySearchedRows = searchResults
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      return (
        <DancersFeeRow
          row={row}
          key={index}
          setdancerIndex={setdancerIndex}
          dancerIndex={dancerIndex}
          arranged={arranged}
          currentEvent={currentEvent}
          eventEntries={eventEntries}
        />
      );
    });

  const pageCount = Math.ceil(dancersDataPag?.length / rowPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      {
        <div className="w-full h-full mt-5 pb-10 overflow-auto">
          {!searchQuery && (
            <table className="min-w-[600px] w-[80%] text-[.85rem] table2">
              <thead className="text-white/40 text-left border-b border-white/30">
                <tr className="whitespace-nowrap">
                  <th>Dancer Name</th>
                  <th>Total Entries</th>
                  <th>Total Fees</th>
                </tr>
              </thead>
              {arranged?.length > 8 ? (
                <tbody className="text-white/80">{displayRows}</tbody>
              ) : (
                <tbody className="transition-all duration-500">
                  {arranged?.map((row, index) => {
                    return (
                      <DancersFeeRow
                        row={row}
                        key={index}
                        setdancerIndex={setdancerIndex}
                        dancerIndex={dancerIndex}
                        arranged={arranged}
                        currentEvent={currentEvent}
                        eventEntries={eventEntries}
                      />
                    );
                  })}
                </tbody>
              )}
            </table>
          )}

          {searchQuery && searchResults && (
            <table className="min-w-[600px] w-[80%] text-[.85rem] table2">
              <thead className="text-white/40 text-left border-b border-white/30">
                <tr className="whitespace-nowrap">
                  <th>Dancer Name</th>
                  <th>Total Entries</th>
                  <th>Total Fees</th>
                </tr>
              </thead>
              {searchResults.length > 8 ? (
                <tbody className="text-white/80">{displaySearchedRows}</tbody>
              ) : (
                <tbody className="transition-all duration-500">
                  {searchResults?.map((row, index) => {
                    return (
                      <DancersFeeRow
                        row={row}
                        key={index}
                        setdancerIndex={setdancerIndex}
                        dancerIndex={dancerIndex}
                        arranged={arranged}
                        currentEvent={currentEvent}
                        eventEntries={eventEntries}
                      />
                    );
                  })}
                </tbody>
              )}
            </table>
          )}
          {searchQuery && searchResults?.length === 0 && (
            <p className="w-full py-8 text-center">No results found</p>
          )}

          {status === 'loading' && (
            <div className="bg-[#1c1c1c] w-full min-h-[260px] flex justify-center items-center">
              <PulseLoader color="#94a4fd" size={10} />
            </div>
          )}
          {arranged?.length < 1 && status === 'success' && (
            <p className="w-full h-[100px] flex items-center justify-center text-center mt-10 text-[1rem] text-white/50 border border-white/10 rounded-lg">
              Dancers are not yet registered for this competition..{' '}
            </p>
          )}
          {arranged?.length > 0 && !searchQuery && (
            <div className="text-[.75rem] text-white/40 mt-4 lg:">
              Scroll horizontally to view more, if needed
            </div>
          )}
        </div>
      }
      {/* pagination */}
      {(dancersDataPag?.length > 8 || searchResults?.length > 8) && (
        <div className="flex gap-2 mt-4 justify-end">
          <ReactPaginate
            previousLabel={
              <img
                alt="user"
                src="/images/leftArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            nextLabel={
              <img
                alt="user"
                src="/images/rightArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={
              'h-6 flex items-center gap-2 mt-4 justify-end text-[.95rem] pag'
            }
            activeClassName={'rounded-lg bg-white/10'}
          />
        </div>
      )}
    </>
  );
};

export default DancersFeeTable;
