import { useSelector } from "react-redux";
import NotifsCard from "./NotifsCard";

const NotificationsContainer = () => {
  const { notifications } = useSelector((state) => state.eventManagement);

  return (
    <div>
      {notifications.length > 0 ? (
        <ul>
          {notifications?.map((notification, index) => (
            <NotifsCard key={index} item={notification} />
          ))}
        </ul>
      ) : (
        <p className="text-[.75rem] w-full h-[100px] border border-white/20 rounded-lg justify-center items-center flex text-white/60">
          Notifications will appear here
        </p>
      )}
    </div>
  );
};

export default NotificationsContainer;
