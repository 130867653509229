/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import Spinner from "./components/Spinner";
import Header from "./components/Header";
import EmceeEventCard from "./components/EmceeEventCard";
import { useNavigate, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const EventSelectEmcee = () => {
  const { status, data } = useQueryAllDocs("competitions");

  const { competitionName } = useParams();
  const currentComp = data?.filter(
    (item) => item?.competitionName === competitionName
  )[0];

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Header />
      <main className="w-full h-screen overflow-y-auto overflow-x-hidden bg-[#1C1C1C] px-3 md:px-10 pt-[100px] md:pt-[120px] relative text-white">
        <button
          onClick={goBack}
          className="px-5 py-1 bg-white/80 hover:bg-white/50 text-black rounded-lg text-[.75rem] md:text-[1rem]"
        >
          Back
        </button>
        <div className="mb-auto">
          <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-12 mt-4">
            {status === "success" && (
              <h1 className="md:text-[1.75rem] text-[1.25rem] font-bold text-center w-full">
                {capitalizeFirstLetter(currentComp?.competitionName)} - Events
              </h1>
            )}
            {/* <img alt="arrow" src="/images/ArrowDown.png" className="w-4 h-4" /> */}
          </div>

          {/* Events container */}
          <div className="w-full md:min-w-[450px] flex flex-wrap gap-3 md:gap-4 mb-auto text-white/80 justify-center md:justify-start">
            {status === "success" ? (
              currentComp?.events?.map((item, index) => {
                return (
                  <EmceeEventCard
                    item={item}
                    key={index}
                    index={index}
                    currentComp={currentComp}
                  />
                );
              })
            ) : (
              <div className="w-full min-h-[300px] flex justify-center items-center border border-white/20 rounded-lg]">
                <Spinner />
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default EventSelectEmcee;
