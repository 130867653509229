/* eslint-disable no-useless-escape */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux";
import { Header, LeftSidebar } from ".";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { useEffect, useState } from "react";
import ScheduleButtons from "./components/ScheduleButtons";
import { PulseLoader } from "react-spinners";
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import {
  addNewBreak,
  closeAddTimeBuffer,
  // getCategory,
  toggleAddBreak,
  toggleAddTimeBuffer,
  toggleAddEntry,
  toggleViewDeleted,
  toggleAddCategory,
  // setCategories,
  lockSchedule,
  closeForms,
  unlockSchedule,
} from "redux/organizerScheduling/organizerSchedulingSlice";
import {
  toggleAddScheduleOverall,
  toggleAddScheduleAward,
  clearSuccess,
} from "redux/routineScheduling/routineSchedulingSlice";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { useFetchScheduleDocs } from "components/hooks/useFetchScheduleDocs";
// import { VariableSizeList } from "react-window";
import RoutineCategory from "./components/RoutineCategory";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import AddEventContainer from "./components/AddEventContainer";
import AddEntryContainer from "./components/AddEntryContainer";
import EditEntryContainer from "./components/EditEntryContainer";
import ViewDeletedContainer from "./components/ViewDeletedContainer";
import AddBreakContainer from "./components/AddBreakContainer";
import AddScheduleAwardContainer from "./components/AddSceduleAwardContainer";
import AddTimeBufferContainer from "./components/AddTimeBufferContainer";
import AddScheduleOverallContainer from "./components/AddScheduleOverallContainer";
import AddSessionContainer from "./components/AddSessionContainer";
import LockScheduleModCont from "./components/LockScheduleModCont";
import UnlockScheduleModCont from "./components/UnlockScheduleModCont";
import { useDispatch } from "react-redux";
import { removeDuplicateObjectsByProperty } from "utils/removeDuplicatesByProperty";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { db } from "services/firebase";
import RoutineTopBar from "./components/RoutineTopBar";
import RoutineNotifiers from "./components/RoutineNotifiers";
import performBackgroundUpdate from "utils/performBackgroundUpdate";
import updateSpecialEntries from "utils/updateSpecialEntries";
import { fetchScheduleDocsService } from "services/organizerService";
import calculateFractionalIndex from "utils/calculateFractionalIndex";
import { performBackgroundUpdate2 } from "utils/performBackgroundUpdate2";
import { useFetchPaginatedScheduleDocs } from "components/hooks/useFetchPaginatedScheduleDocs";
// import { deleteDuplicateDocs } from "utils/deleteDuplicateDocs";
import { useFetchEntries } from "components/hooks/useFecthEntries";
import { useNavigate } from "react-router-dom";

// Custom droppable component to work around React StrictMode
export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};

const RoutineScheduler2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [rerender, setRerender] = useState(false);

  const { addEvent, openNotifsBoard, currentEvent } = useSelector(
    (state) => state.eventManagement
  );

  const {
    categories,
    editID,
    editEntry,
    addBreak,
    addTimeBuffer,
    deletedCategories,
    getCategoryPending,
    addEntry,
    viewDeleted,
    scratchEntryPending,
    lockSchedulePending,
    lockScheduleSuccess,
    unlockScheduleSuccess,
    unlockSchedulePending,
  } = useSelector((state) => state.organizerScheduling);

  const {
    addBreakSuccess,
    addBufferSuccess,
    addScheduleOverallMod,
    addScheduleAwardMod,
    addSessionMod,
    deleteBreakSuccess,
    splitCategorySuccess,
  } = useSelector((state) => state.routineScheduling);

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  // // to get all entries
  // const { data: allEntries9 } = useFetchScheduleDocs(
  //   "competitions",
  //   user?.uid,
  //   "entries",
  //   "event_uid",
  //   currentEvent?.event_uid
  // );

  // // to get all categories
  // const { data: eventCategories9 } = useFetchScheduleDocs(
  //   "competitions",
  //   user?.uid,
  //   "categories",
  //   "event_uid",
  //   currentEvent?.event_uid
  // );

  // to get all entries
  // const { data: allEntries9 } = useFetchScheduleDocs(
  //   "competitions",
  //   user?.uid,
  //   "entries",
  //   "event_uid",
  //   currentEvent?.event_uid
  // );

  // console.log("allEntries9", allEntries9);

  // // to get all categories
  // const { status: entryStatus, data: eventCategories } = useFetchScheduleDocs(
  //   "competitions",
  //   user?.uid,
  //   "categories",
  //   "event_uid",
  //   currentEvent?.event_uid
  // );

  //==============================================to track last page last entryTime and entryCount
  const [lastPageData, setLastPageData] = useState([]);

  // console.log("lastPageData", lastPageData);

  // to get categories per page
  const [lastDoc, setLastDoc] = useState(null);
  const [startBeforeDoc, setStartBeforeDoc] = useState(null);
  const [triggerFetch, setTriggerFetch] = useState(true); // New state to trigger hook
  const [currentPage, setcurrentPage] = useState(1);
  const [total, setTotal] = useState(0);

  const { loading: loadingCat, eventCategories } =
    useFetchPaginatedScheduleDocs(
      "competitions",
      user?.uid,
      "categories",
      "event_uid",
      currentEvent?.event_uid,
      15,
      lastDoc,
      startBeforeDoc,
      setcurrentPage,
      setTotal,
      currentPage,
      addBreakSuccess,
      addBufferSuccess,
      addScheduleOverallMod,
      addScheduleAwardMod,
      addSessionMod,
      deleteBreakSuccess,
      setTriggerFetch,
      triggerFetch,
      splitCategorySuccess
    );

  const fetchNextPage = () => {
    addLastPageData();
    const lastCategory = sortedCategories?.[sortedCategories?.length - 1];

    setLastDoc(lastCategory?.sort_id);
    setStartBeforeDoc(null);
    setTriggerFetch(true);
  };

  const fetchPrevPage = () => {
    const firstCategory = lastPageData?.filter(
      (x) => x?.page === currentPage - 1
    )[0]?.lastFirstCategorySortId;

    setStartBeforeDoc(firstCategory);
    setLastDoc(null);
    setTriggerFetch(true);
  };

  // Use useEffect to listen for changes in lastDoc and startBeforeDoc
  useEffect(() => {
    // Whenever lastDoc or startBeforeDoc changes, toggle triggerFetch to re-run the hook
    setTriggerFetch((prev) => !prev);
  }, [lastDoc, startBeforeDoc]);

  // const category_nameArr = allEntries?.map((x) => x?.category_name);

  // const categoriesRaw = eventCategories
  //   ?.filter((y) => category_nameArr?.includes(y?.category_name))
  //   ?.concat(eventCategories?.filter((z) => z?.type !== "category"));

  let sortedCategoriesRaw = eventCategories?.sort(
    (a, b) => a?.sort_id - b?.sort_id
  );

  const [sortedCategories, setSortedCategories] = useState([]);
  const [cateNameArray, setcateNameArray] = useState([]);

  // console.log("cateNameArray", cateNameArray);

  useEffect(() => {
    if (eventCategories?.length > 0) {
      setSortedCategories(sortedCategoriesRaw);
      const category_nameArr = eventCategories
        ?.filter((z) => z?.type === "category")
        ?.map((x) => x?.category_name);
      setcateNameArray(category_nameArr);
    }
  }, [eventCategories, loadingCat]);

  // to get all entries
  const { status, data: allEntries } = useFetchEntries(
    "competitions",
    user?.uid,
    "entries",
    "event_uid",
    currentEvent?.event_uid,
    "category_name",
    cateNameArray
  );

  const [lastEntryTime, setLastEntryTime] = useState("00:00 AM");
  // console.log("lastEntryTime", lastEntryTime);
  function addLastPageData() {
    const allSessions = sortedCategories?.filter(
      (x) => x?.break_type === "session"
    );

    const prevData = lastPageData[lastPageData?.length - 1];
    const dataModel = {
      lastFirstCategorySortId: sortedCategories[0]?.sort_id,
      lastEntryTime: lastEntryTime,
      lastEntryCount: allEntries?.length + (prevData?.lastEntryCount || 0),
      lastSession: allSessions?.length + (prevData?.lastSession || 0),
      page: currentPage,
    };
    setLastPageData((prev) => {
      const hasDuplicatePage = prev.some(
        (item) => item.page === dataModel.page
      );
      if (!hasDuplicatePage) {
        return [...prev, dataModel];
      }
      return prev;
    });
  }
  // console.log("allEntries", allEntries);

  // useEffect(() => {
  //   if (!loading) {
  //     let arr = removeDuplicateObjectsByProperty(
  //       sortedCategoriesRaw,
  //       "category_name"
  //     );
  //     setSortedCategories(arr);
  //   }
  // }, [status, eventCategories]);

  //to get all reports
  const { data: allReports } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "reports"
  );

  const closeDropdown = () => {
    setShowNotes("");
    setShowMoveCategory(false);
    setShowMoveEntry(false);
  };

  const [allowDrag, setAllowDrag] = useState(true);
  // const [showNames, setShowNames] = useState(null);
  const [displaySession, setDisplaySession] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingEntry, setLoadingEntry] = useState(false);

  const [consecutiveBuffer, setConsecutiveBuffer] = useState(false);

  const [lockedSchedule, setLockedSchedule] = useState(false);

  const [confrmDrag, setConfirmDrag] = useState(false);
  const [notifyDrag, setNotifyDrag] = useState(false);

  function notifyLocked() {
    setLockedSchedule(true);
    setTimeout(() => {
      setLockedSchedule(false);
    }, 2000);
  }

  // Toggle dropdown menu
  const toggleDropdown = (categoryIndex) => {
    setShowMoveCategory(false);
  };

  const [showNotes, setShowNotes] = useState("");
  const [showMoveCategory, setShowMoveCategory] = useState(false);
  const [showMoveCategoryBefore, setShowMoveCategoryBefore] = useState(false);

  const [showMoveEntry, setShowMoveEntry] = useState(false);

  // // To close dropdown menu
  const closeDropdownAll = () => {
    setShowNotes("");
    setShowMoveCategory(false);
    setShowMoveEntry(false);
  };

  //to assign sort id
  const assignSortId = () => {
    const maxSortId = Math.max(
      ...categories?.map((obj) => obj.sort_id || 0),
      0
    );
    const newSortId = maxSortId + 1;

    return newSortId;
  };

  //to get entry to edit
  const entryToEdit = editID
    ? allEntries?.filter((item) => item?.doc_id === editID)[0]
    : null;

  // const [bufferTime, setBufferTime] = useState("");
  const [bufferTimeErr, setBufferTimeErr] = useState(false);

  // const [breakName, setBreakName] = useState("");
  const [breakNameErr, setBreakNameErr] = useState(false);

  const [awardName, setAwardName] = useState("");

  const [overallName, setOverallName] = useState("");

  // eslint-disable-next-line no-unused-vars
  const [duringDrag, setDuringDrag] = useState(false);

  const [openDancers, setOpenDancers] = useState(false);
  const [openStudios, setOpenStudios] = useState(false);

  function closeAll() {
    setOpenDancers(false);
    setOpenStudios(false);
  }

  //to generate a unique id for each new category
  function generateUniqueId() {
    const timestamp = new Date().getTime().toString();
    const randomNum = Math.floor(Math.random() * 900000000000) + 100000000000;
    const uniqueId = `${timestamp}${randomNum}`;
    return uniqueId.substring(0, 15);
  }

  const [breakExists, setBreakExists] = useState(false);
  const [bufferExists, setBufferExists] = useState(false);
  const [bothExists, setbothExists] = useState(false);

  const [lockScheduleMod, setLockScheduleMod] = useState(false);
  function toggleLockMod() {
    setLockScheduleMod((prev) => !prev);
  }

  const [unlockScheduleMod, setUnlockScheduleMod] = useState(false);
  function toggleUnlockMod() {
    setUnlockScheduleMod((prev) => !prev);
  }

  const { status: compataStatus, data: compData } = useFirestoreQueryDoc(
    "competitions",
    user?.uid
  );
  const [competitionLevel, setCompetitionLevel] = useState([]);
  const [eligibilityDivisions, seteligibilityDivisions] = useState([]);
  const [scheduling, setscheduling] = useState([]);
  const [entryTypes, setentryTypes] = useState([]);
  const [specialEntryTypes, setspecialEntryTypes] = useState([]);
  const [danceGenres, setdanceGenres] = useState([]);

  useEffect(() => {
    if (compData) {
      const {
        competitionLevel,
        eligibilityDivisions,
        scheduling,
        entryTypes,
        danceGenres,
        specialEntryTypes,
      } = compData;
      setCompetitionLevel(competitionLevel);
      seteligibilityDivisions(eligibilityDivisions);
      setscheduling(scheduling);
      setentryTypes(entryTypes);
      setdanceGenres(danceGenres);
      setspecialEntryTypes(specialEntryTypes);
    }

    return () => {
      setLastPageData([]);
    };
  }, [compataStatus]);

  //===================================================to efficiently paginate the schedule
  const [pageNumber, setPageNumber] = useState(0);

  const categoryPerPage = 15;
  const pagesVisited = pageNumber * categoryPerPage;

  const eachRoutineCategory = sortedCategories
    ?.slice(pagesVisited, pagesVisited + categoryPerPage)
    ?.map((category, index) => {
      return (
        <Draggable
          key={category?.id}
          draggableId={category?.id}
          index={index}
          isDragDisabled={!allowDrag || (currentEvent?.locked && !confrmDrag)}
        >
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps}>
              {/* Wrap RoutineCategory with a div to prevent drag event propagation */}
              <div {...provided.dragHandleProps}>
                <RoutineCategory
                  key={index}
                  index={index}
                  category={category}
                  allEntries={allEntries}
                  sortedCategories={sortedCategories}
                  currentEvent={currentEvent}
                  entryTypes={entryTypes}
                  loading={loading}
                  toggleDropdown={toggleDropdown}
                  closeDropdown={closeDropdown}
                  loadingEntry={loadingEntry}
                  setLoadingEntry={setLoadingEntry}
                  notifyLocked={notifyLocked}
                  confrmDrag={confrmDrag}
                  closeDropdownAll={closeDropdownAll}
                  showNotes={showNotes}
                  setShowNotes={setShowNotes}
                  showMoveCategory={showMoveCategory}
                  setShowMoveCategory={setShowMoveCategory}
                  setLoading={setLoading}
                  setShowMoveEntry={setShowMoveEntry}
                  showMoveEntry={showMoveEntry}
                  setAllowDrag={setAllowDrag}
                  scheduling={scheduling}
                  status={status}
                  setSortedCategories={setSortedCategories}
                  setShowMoveCategoryBefore={setShowMoveCategoryBefore}
                  showMoveCategoryBefore={showMoveCategoryBefore}
                  setBackgroundUpdateOutcome={setBackgroundUpdateOutcome}
                  // allCats={allCats}
                  // allEntriesData={allEntriesData}
                  affectedItems={affectedItems}
                  setaffectedItems={setaffectedItems}
                  setLastEntryTime={setLastEntryTime}
                  lastPageData={lastPageData}
                  currentPage={currentPage}
                />
              </div>
            </div>
          )}
        </Draggable>
      );
    });

  const pageCount = Math.ceil(sortedCategories?.length / categoryPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  //to monitor the background update outcome (success or error, if error, notify user to try again)
  const [backgroundUpdateOutcome, setBackgroundUpdateOutcome] = useState("");
  const [affectedItems, setaffectedItems] = useState(
    JSON.parse(localStorage.getItem("affectedItems")) || []
  );
  useEffect(() => {
    setaffectedItems(JSON.parse(localStorage.getItem("affectedItems")) || []);
  }, [sortedCategories]);

  // console.log("sortedCategories", sortedCategories);

  const handleDragEnd2 = async (result) => {
    const { source: sourceRaw, destination: destinationRaw } = result;
    setBackgroundUpdateOutcome("");

    // Calculate actual start and end indexes in the entire sortedCategories array
    const source = sourceRaw?.index + 15 * pageNumber;
    const destination = destinationRaw?.index + 15 * pageNumber;

    if (!destinationRaw || sourceRaw?.index === destinationRaw?.index) {
      setLoading(false);
      return;
    }

    const updatedCategory = [...sortedCategories];
    const draggedRow = updatedCategory[source];

    // Remove the dragged category from its original position
    updatedCategory?.splice(source, 1);

    // Insert the dragged category at the destination index
    updatedCategory?.splice(destination, 0, draggedRow);

    // Calculate the new sort_id using fractional indexing
    const previousSortId =
      destination === 0 ? 0 : updatedCategory[destination - 1].sort_id;
    const nextSortId =
      destination === updatedCategory.length - 1
        ? destination + 2
        : updatedCategory[destination + 1].sort_id;

    const newSortId = calculateFractionalIndex(previousSortId, nextSortId);

    draggedRow.sort_id = newSortId;

    setSortedCategories(updatedCategory);

    //add dragged row to the affected items
    let allAffectedItems = [...affectedItems];
    const index = allAffectedItems.findIndex(
      (item) => item.id === draggedRow.id
    );

    if (index !== -1) {
      // If the draggedRow exists, replace it in the affectedItems array
      allAffectedItems[index] = draggedRow;
    } else {
      // If the draggedRow doesn't exist, add it to the allAffectedItems array
      allAffectedItems.push(draggedRow);
    }

    localStorage.setItem("affectedItems", JSON.stringify(allAffectedItems));
    setaffectedItems(allAffectedItems);
  };

  const handleSaveDrag = async () => {
    setLoading(true);
    await performBackgroundUpdate2(affectedItems, user, setLoading)?.then(
      () => {
        setaffectedItems([]);
      }
    );
  };

  // useEffect(() => {
  //   if (addBufferSuccess?.success && sortedCategories?.length > 0) {
  //     dispatch(toggleAddTimeBuffer());
  //     const lastId = sortedCategories[sortedCategories?.length - 1]?.id;
  //     const destId = sortedCategories[currentPage * 15 - 1]?.id;
  //     moveCategoryOnCreate(lastId, destId);
  //   }
  // }, [addBufferSuccess]);

  useEffect(() => {
    if (addBreakSuccess?.success) {
      dispatch(clearSuccess());
    }
  }, [addBreakSuccess]);

  useEffect(() => {
    if (addBreakSuccess?.success === true && sortedCategories?.length > 0) {
      dispatch(closeAddTimeBuffer());
      setBreakExists(false);
      setBufferExists(false);
      setbothExists(false);
      // const lastId = sortedCategories[sortedCategories?.length - 1]?.id;
      // const destId = sortedCategories[pageNumber * 15 - 1]?.id;
      // moveCategoryOnCreate(lastId, destId);
    } else if (
      addBreakSuccess?.success === false &&
      addBreakSuccess?.message === "Buffer already added"
    ) {
      setBufferExists(true);
    } else {
      setBreakExists(false);
      setBufferExists(false);
      setbothExists(false);
    }

    if (deleteBreakSuccess?.success) {
      dispatch(closeAddTimeBuffer());
      setBreakExists(false);
    }

    if (lockScheduleSuccess?.success) {
      setLockScheduleMod(false);
      window.scrollTo(0, 0);
      dispatch(closeForms());
    }

    if (unlockScheduleSuccess?.success) {
      setUnlockScheduleMod(false);
      dispatch(closeForms());
    }
  }, [
    addBreakSuccess,
    breakExists,
    deleteBreakSuccess,
    dispatch,
    lockScheduleSuccess,
    unlockScheduleSuccess,
  ]);

  //=============================================================to stick schedule navigation to the top
  useEffect(() => {
    const handleScroll = () => {
      const stickyElement = document.querySelector(".stickyDiv");
      const parentContainer = document.querySelector(".parentContainer");

      if (stickyElement && parentContainer) {
        const scrollPosition = window.scrollY;
        const parentContainerTop = parentContainer.getBoundingClientRect().top;

        if (scrollPosition > parentContainerTop) {
          stickyElement.classList.add("sticky");
        } else {
          stickyElement.classList.remove("sticky");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const moveCategoryOnCreate = async (id1, id2) => {
    setBackgroundUpdateOutcome("");
    // setLoading(true);

    const sourceIndex = sortedCategories?.findIndex(
      (category) => category?.id === id1
    );

    const destinationIndex = sortedCategories?.findIndex(
      (category) => category?.id === id2
    );

    const updatedCategory = [...sortedCategories];
    const draggedRow = updatedCategory[sourceIndex];

    // Remove the dragged category from its original position
    updatedCategory?.splice(sourceIndex, 1);

    // Insert the dragged category at the destination index
    updatedCategory?.splice(destinationIndex, 0, draggedRow);

    // Calculate the new sort_id using fractional indexing
    const previousSortId =
      destinationIndex === 0
        ? 0
        : updatedCategory[destinationIndex - 1].sort_id;
    const nextSortId =
      destinationIndex === updatedCategory.length - 1
        ? destinationIndex + 2
        : updatedCategory[destinationIndex + 1].sort_id;

    const newSortId = calculateFractionalIndex(previousSortId, nextSortId);

    draggedRow.sort_id = newSortId;

    setSortedCategories(updatedCategory);

    // Store affected items in localStorage for ongoing updates
    localStorage.setItem("firestoreUpdates", JSON.stringify(updatedCategory));

    // Perform Firestore updates in the background
    await performBackgroundUpdate(
      [draggedRow],
      user,
      setLoading,
      setBackgroundUpdateOutcome
    );
  };

  // //=============================================================to refresh category names
  // // console.log("allEntries9", allEntries9);
  // const [isInitialMount, setIsInitialMount] = useState(true);

  // useEffect(() => {
  //   if (
  //     isInitialMount &&
  //     currentEvent?.event_uid &&
  //     status === "success" &&
  //     eventCategories9?.length > 0 &&
  //     allEntries9?.length > 0
  //   ) {
  //     updateSpecialEntries(
  //       allEntries9,
  //       eventCategories9,
  //       user?.uid,
  //       assignSortId,
  //       currentEvent
  //     );
  //     setIsInitialMount(false);
  //   }
  // }, [isInitialMount, currentEvent, status, allEntries9, eventCategories9]);

  return (
    <>
      <LeftSidebar />
      <Header />
      {!addEvent && !addEntry && !editEntry && !viewDeleted && (
        <div
          className={`w-full min-h-screen text-white/80 px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px]  ${
            openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
          } pt-[80px] sm:py-[110px] bg-[#1c1c1c] flex flex-col transition-all duration-700 relative`}
        >
          <div className="mb-auto">
            {/* Heading */}
            <div className="flex gap-2 items-center text-white/90 text-[1rem] mb-6">
              <h1 className="whitespace-nowrap">
                {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
                {currentEvent?.year?.split(" to ")[0]}
              </h1>

              {status === "success" && (
                <div className="w-fit mb-3 text-right flex flex-wrap gap-3 ml-auto">
                  <button
                    onClick={() =>
                      window.open(
                        `/organizer/event/${currentEvent?.event_name.replace(
                          / /g,
                          "-"
                        )}/schedule/print`,
                        "_blank"
                      )
                    }
                    className={`w-fit ml-auto text-[.85rem] px-5 py-1 mr-2 rounded-full text-[#1c1c1c] transition-all duration-300 bg-[#94a4fd] hover:bg-[#94a4fd]/60`}
                  >
                    Print
                  </button>

                  {!currentEvent?.locked && (
                    <button
                      onClick={
                        () =>
                          navigate(
                            `/organizer/event/${currentEvent?.event_name.replace(
                              / /g,
                              "-"
                            )}/schedule-move-category`
                          )
                        // window.open(
                        //   `/organizer/event/${currentEvent?.event_name.replace(
                        //     / /g,
                        //     "-"
                        //   )}/schedule-move-category`,
                        //   "_blank"
                        // )
                      }
                      className={`w-fit ml-auto text-[.85rem] px-5 py-1 mr-2 rounded-full text-[#1c1c1c] transition-all duration-300 bg-gray-300 hover:bg-[#94a4fd]/60`}
                    >
                      Move Categories
                    </button>
                  )}
                </div>
              )}
            </div>

            <section className="w-full flex flex-col gap-2 relative">
              {/* Notifiers */}
              <RoutineNotifiers
                currentEvent={currentEvent}
                confrmDrag={confrmDrag}
                consecutiveBuffer={consecutiveBuffer}
                lockedSchedule={lockedSchedule}
                notifyDrag={notifyDrag}
              />

              {status === "success" && sortedCategories?.length !== 0 && (
                <div className="w-full lg:w-[85%] text-[.9em] pb-5 pt-[60px] md:pt-0 overflow-x-auto overflow-y-hidden rounded-lg relative parentContainer">
                  {/* The schedule table */}
                  <RoutineTopBar
                    loading={loading}
                    backgroundUpdateOutcome={backgroundUpdateOutcome}
                    sortedCategories={sortedCategories}
                    allEntries={allEntries}
                    pageCount={pageCount}
                    changePage={changePage}
                    affectedItems={affectedItems}
                    handleSaveDrag={handleSaveDrag}
                    fetchNextPage={fetchNextPage}
                    fetchPrevPage={fetchPrevPage}
                    loadingCat={loadingCat}
                    currentPage={currentPage}
                    total={total}
                  />

                  {/* {eachRoutineCategory} */}
                  <DragDropContext onDragEnd={handleDragEnd2}>
                    <StrictModeDroppable droppableId="cat-list">
                      {(provided) => (
                        <div
                          className="w-full relative"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          onMouseDown={(e) => e.stopPropagation()}
                        >
                          {(consecutiveBuffer || lockedSchedule) &&
                            !confrmDrag && (
                              <div className="w-[250px] p-3 rounded-lg border border-red-500 text-[.85rem] z-[2] fixed top-[100px] left-[50%] bg-[#282929] flex gap-3 items-center scale">
                                <img
                                  alt=""
                                  src="/images/icons8-cross-30.png"
                                  className="w-6 h-6"
                                />{" "}
                                {lockedSchedule
                                  ? "Schedule is locked! Unlock or 'enable actions'"
                                  : "Consecutive time buffers!"}
                              </div>
                            )}
                          {notifyDrag && (
                            <div
                              className={`w-[250px] p-3 rounded-lg border  text-[.85rem] z-[2] fixed top-[100px] left-[50%] bg-[#282929] flex gap-3 items-center scale ${
                                confrmDrag
                                  ? "border-green-400"
                                  : "border-red-500"
                              }`}
                            >
                              <img
                                alt=""
                                src={
                                  confrmDrag
                                    ? "/images/icons8-check-green.png"
                                    : "/images/icons8-cross-30.png"
                                }
                                className="w-6 h-6"
                              />{" "}
                              {confrmDrag
                                ? "Actions enabled!"
                                : "Actions disabled!"}
                            </div>
                          )}
                          {eachRoutineCategory}
                          {provided.placeholder}
                        </div>
                      )}
                    </StrictModeDroppable>
                  </DragDropContext>
                </div>
              )}

              {sortedCategories?.length === 0 &&
                status !== "loading" &&
                !loadingCat && (
                  <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
                    <h4>No Routines Yet...</h4>
                  </div>
                )}

              {/* to show spinner on page load */}
              {sortedCategories?.length === 0 &&
                (status === "loading" || loadingCat) && (
                  <div className="w-full md:w-[85%]  text-[.9em] pt-3 pb-20 overflow-x-auto overflow-y-hidden">
                    <div className="w-full h-[200px] border border-white/10 text-white/50 rounded-xl flex justify-center items-center">
                      <PulseLoader color="#94a4fd" size={10} />
                    </div>
                  </div>
                )}

              {/* bottom section */}
              {(!getCategoryPending || scratchEntryPending) && (
                <ScheduleButtons
                  currentEvent={currentEvent}
                  toggleAddBreak={toggleAddBreak}
                  toggleAddEntry={toggleAddEntry}
                  toggleAddCategory={toggleAddCategory}
                  toggleAddTimeBuffer={toggleAddTimeBuffer}
                  toggleViewDeleted={toggleViewDeleted}
                  toggleLockMod={toggleLockMod}
                  toggleAddScheduleOverall={toggleAddScheduleOverall}
                  sortedCategories={sortedCategories}
                  allEntries={allEntries}
                  setDisplaySession={setDisplaySession}
                  displaySession={displaySession}
                />
              )}
            </section>
          </div>
        </div>
      )}

      {/* the add event pop up page */}
      {addEvent && <AddEventContainer />}

      {/* the add entry pop up page */}
      {addEntry && (
        <AddEntryContainer
          currentEvent={currentEvent}
          // eventCategories={eventCategories}
          // extractedEntries={extractedEntries}
          competitionLevel={competitionLevel}
          eligibilityDivisions={eligibilityDivisions}
          scheduling={scheduling}
          entryTypes={entryTypes}
          danceGenres={danceGenres}
          openDancers={openDancers}
          setOpenDancers={setOpenDancers}
          openStudios={openStudios}
          setOpenStudios={setOpenStudios}
          closeAll={closeAll}
          specialEntryTypes={specialEntryTypes}
          sortedCategories={sortedCategories}
        />
      )}

      {/* the add entry pop up page */}
      {editEntry && (
        <EditEntryContainer
          currentEvent={currentEvent}
          entryToEdit={entryToEdit}
          entryTypes={entryTypes}
          specialEntryTypes={specialEntryTypes}
        />
      )}

      {/* the view deleted pop up page */}
      {viewDeleted && (
        <ViewDeletedContainer
          openNotifsBoard={openNotifsBoard}
          toggleViewDeleted={toggleViewDeleted}
          currentEvent={currentEvent}
          deletedCategories={deletedCategories}
          entryTypes={entryTypes}
        />
      )}

      {/* the add break pop up */}
      {addBreak && (
        <AddBreakContainer
          setBreakExists={setBreakExists}
          setBreakNameErr={setBreakNameErr}
          toggleAddBreak={toggleAddBreak}
          breakExists={breakExists}
          bothExists={bothExists}
          // setBreakName={setBreakName}
          breakNameErr={breakNameErr}
          // breakName={breakName}
          addNewBreak={addNewBreak}
          generateUniqueId={generateUniqueId}
          user={user}
          assignSortId={assignSortId}
          currentEvent={currentEvent}
        />
      )}

      {/* the add break pop up */}
      {addScheduleAwardMod && (
        <AddScheduleAwardContainer
          setBreakExists={setBreakExists}
          setBreakNameErr={setBreakNameErr}
          toggleAddScheduleAward={toggleAddScheduleAward}
          // addBreakPending={addBreakPending}
          breakExists={breakExists}
          bothExists={bothExists}
          setAwardName={setAwardName}
          breakNameErr={breakNameErr}
          awardName={awardName}
          addNewBreak={addNewBreak}
          generateUniqueId={generateUniqueId}
          user={user}
          assignSortId={assignSortId}
          currentEvent={currentEvent}
        />
      )}

      {/* the add time buffer pop up */}
      {addTimeBuffer && (
        <AddTimeBufferContainer
          toggleAddTimeBuffer={toggleAddTimeBuffer}
          setBufferTimeErr={setBufferTimeErr}
          // addBreakPending={addBreakPending}
          bufferExists={bufferExists}
          // setBufferTime={setBufferTime}
          bufferTimeErr={bufferTimeErr}
          // bufferTime={bufferTime}
          addNewBreak={addNewBreak}
          generateUniqueId={generateUniqueId}
          user={user}
          assignSortId={assignSortId}
          currentEvent={currentEvent}
        />
      )}

      {/* the add overalls pop up */}
      {addScheduleOverallMod && (
        <AddScheduleOverallContainer
          setBreakExists={setBreakExists}
          setBreakNameErr={setBreakNameErr}
          toggleAddScheduleAward={toggleAddScheduleAward}
          // addBreakPending={addBreakPending}
          breakExists={breakExists}
          bothExists={bothExists}
          setOverallName={setOverallName}
          breakNameErr={breakNameErr}
          overallName={overallName}
          addNewBreak={addNewBreak}
          generateUniqueId={generateUniqueId}
          user={user}
          assignSortId={assignSortId}
          currentEvent={currentEvent}
          allReports={allReports}
        />
      )}

      {/* the add session pop up */}
      {addSessionMod && (
        <AddSessionContainer
          setBreakExists={setBreakExists}
          setBreakNameErr={setBreakNameErr}
          breakExists={breakExists}
          bothExists={bothExists}
          overallName={overallName}
          user={user}
          currentEvent={currentEvent}
        />
      )}

      {/* lock schedule */}
      {lockScheduleMod && (
        <LockScheduleModCont
          lockSchedulePending={lockSchedulePending}
          toggleLockMod={toggleLockMod}
          lockSchedule={lockSchedule}
          user={user}
          currentEvent={currentEvent}
        />
      )}

      {/* unlock schedule */}
      {unlockScheduleMod && (
        <UnlockScheduleModCont
          unlockSchedulePending={unlockSchedulePending}
          toggleUnlockMod={toggleUnlockMod}
          unlockSchedule={unlockSchedule}
          user={user}
          currentEvent={currentEvent}
        />
      )}
    </>
  );
};

export default RoutineScheduler2;
