import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { clearSuccess } from 'redux/eventManagement/eventManagement';
import { db } from 'services/firebase';
import {
  doc,
  updateDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
} from 'firebase/firestore';

const EntryEditForm = ({ onClose, organizerData, user, entry }) => {
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState('');

  // Initialize form fields with the data of the selected special entry
  const [formData, setFormData] = useState({
    entryTypeName: entry?.entryTypeName,
    minDancers: entry?.minDancers,
    maxDancers: entry?.maxDancers,
    maxTime: entry?.maxTime,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setFormErr('');
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update form data when inputs change
  const handleChange = (e) => {
    setFormErr('');
    dispatch(clearSuccess());
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      !formData.entryTypeName?.trim() &&
      !formData.maxTime &&
      !formData.minDancers &&
      !formData.maxDancers
    ) {
      setFormErr('Please fill all required fields');
      return;
    } else if (organizerData && formData) {
      const organizerDocRef = doc(db, 'organizers', user?.uid);
      const competitionDocRef = doc(db, 'competitions', user?.uid);
      // Get the current document data
      const organizerDocSnap = await getDoc(organizerDocRef);
      const currentData = organizerDocSnap.data();

      // Find the index of the special entry being edited
      const entryIndex = currentData.entryTypes?.findIndex(
        (itx) => itx?.entryTypeName === entry?.entryTypeName
      );

      // Get the category_name of the special entry before making changes
      const previousCategoryName = entry?.entryTypeName;

      // Update the special entry in the array
      const newOne = {
        entryTypeName: formData?.entryTypeName,
        minDancers: formData?.minDancers,
        maxDancers: formData?.maxDancers,
        maxTime:
          parseMinutesToDuration(Number(formData?.maxTime)) || '00:00:00',
      };

      const tagsFiltered = currentData.entryTypes?.filter(
        (_, ind) => ind !== entryIndex
      );
      const newTags = [...tagsFiltered, newOne];

      // Update the document with the new data
      await updateDoc(organizerDocRef, {
        entryTypes: newTags,
      });
      await updateDoc(competitionDocRef, {
        entryTypes: newTags,
      });

      // If the special entry's category_name has changed, delete the old entries
      if (previousCategoryName !== formData?.entryTypeName) {
        // Reference to the "entries" sub-collection
        const entriesRef = collection(db, 'competitions', user?.uid, 'entries');

        // Get all entries with the previous category_name
        const entriesQuery = query(
          entriesRef,
          where('category_name', '==', previousCategoryName)
        );

        // Get the documents that match the query
        const entryQuerySnapshot = await getDocs(entriesQuery);

        // Delete each entry that matches the query
        entryQuerySnapshot?.forEach((doc) => {
          deleteDoc(doc.ref);
        });
      }

      // Close the pop-up
      onClose();
    }

    // Set loading to false after the update is complete
    setLoading(false);
  };

  function parseTime(durationString) {
    // Split the duration string by colons
    const parts = durationString.split(':');

    if (parts.length === 3) {
      // Convert the hours, minutes, and seconds to integers
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      const seconds = parseInt(parts[2], 10);

      // Calculate the total duration in minutes
      const totalMinutes = hours * 60 + minutes + seconds / 60;

      return totalMinutes;
    } else {
      // Handle invalid duration string
      return null;
    }
  }

  function parseMinutesToDuration(minutes) {
    // Ensure 'minutes' is a non-negative whole number
    if (!Number.isInteger(minutes) || minutes < 0) {
      return null; // Invalid input
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = 0;

    // Format the duration string
    const formattedDuration = `${String(hours).padStart(2, '0')}:${String(
      remainingMinutes
    ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

    return formattedDuration;
  }

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
    >
      <div className="rounded-lg p-6 w-[40%] h-[410px] mx-auto md:w-1/2 max-w-[700px] min-w-[300px]">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-[#282929] rounded-lg p-6 scale"
        >
          <form onSubmit={handleSubmit}>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Entry Types</label>
              <input
                type="text"
                id="entryTypeName"
                placeholder="Eg. Superb, etc."
                value={formData?.entryTypeName}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="flex items-center gap-2">
              <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
                <label htmlFor="description">Min Dancer</label>
                <input
                  type="number"
                  id="minDancers"
                  placeholder="Eg. #1"
                  value={formData?.minDancers}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none outline-none text-white/80"
                />
              </div>
              <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
                <label htmlFor="description">Max Dancer</label>
                <input
                  type="number"
                  id="maxDancers"
                  placeholder="Eg. #10"
                  value={formData?.maxDancers}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none outline-none text-white/80"
                />
              </div>
            </div>

            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Entry Time (In minutes)</label>
              <input
                type="number"
                id="maxTime"
                placeholder="Eg. 10"
                defaultValue={parseTime(formData?.maxTime)}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>

            <div className="flex gap-3  mb-6 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-8 py-1 bg-white rounded-full text-black/80 text-[0.85rem] hover:opacity-75"
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Submit'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
            {formErr && <p className="text-red-500">{formErr}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EntryEditForm;
