import React from 'react'

function ButtonLoadingSpinner() {
  return (
    <svg
    className="animate-spin h-5 w-5 text-blue-100 dark:text-blue-300"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    />
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm12 0a8 8 0 018 8v-2c0-4.418-3.582-8-8-8v2z"
    />
  </svg>
  )
}

export default ButtonLoadingSpinner