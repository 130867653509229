import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { clearSuccess } from 'redux/eventManagement/eventManagement';
import { db } from 'services/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';

const SpecialEntryForm = ({ onClose, organizerData, user }) => {
  const [loading, setLoading] = useState(false);
  const [formErr, setFormErr] = useState('');

  const [formData, setFormData] = useState({
    specialEntryTypeName: '',
    specialMinDancers: '',
    specialMaxDancers: '',
    specialMaxTime: '',
  });

  const [specialTag, setSpecialTag] = useState({
    age: false,
    classification: false,
    genre: false,
    entry_type: false,
    scored: false,
  });

  const handleSpecialTagChange = (event) => {
    const { id, checked } = event.target;
    setSpecialTag((prevTag) => ({
      age: id === 'age' ? checked : prevTag.age,
      classification:
        id === 'classification' ? checked : prevTag.classification,
      genre: id === 'genre' ? checked : prevTag.genre,
      entry_type: id === 'entry_type' ? checked : prevTag.entry_type,
      scored: id === 'scored' ? checked : prevTag.scored,
    }));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setFormErr('');
    dispatch(clearSuccess());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update form data when inputs change
  const handleChange = (e) => {
    setFormErr('');
    dispatch(clearSuccess());
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      !formData.specialEntryTypeName?.trim() &&
      !formData.specialMaxTime &&
      !formData.specialMinDancers &&
      !formData.specialMaxDancers
    ) {
      setFormErr('Please fill all required fields');
      return;
    } else if (organizerData && formData) {
      const organizerDocRef = doc(db, 'organizers', user?.uid);
      const competitionDocRef = doc(db, 'competitions', user?.uid);
      // Get the current document data
      const organizerDocSnap = await getDoc(organizerDocRef);
      const currentData = organizerDocSnap.data();

      // Convert decimal value to the desired format (hh:mm:ss)
      const hours = Math.floor(formData.specialMaxTime / 60);
      const minutes = Math.floor(formData.specialMaxTime % 60);
      const seconds = Math.floor((formData.specialMaxTime % 1) * 60);
      formData.specialMaxTime = `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

      // Update the special entry types array
      const newOne = {
        specialEntryTypeName: formData?.specialEntryTypeName.toLowerCase(),
        specialMinDancers: formData?.specialMinDancers,
        specialMaxDancers: formData?.specialMaxDancers,
        specialMaxTime: formData?.specialMaxTime,
        tags: specialTag, // Assign the specialTag state to the tags property
      };

      const newTags = [...currentData.specialEntryTypes?.tags, newOne];

      if (formData?.specialEntryTypeName) {
        // Create a copy of the pricingCategories array from the current data
        const currentPricingCategories = [
          ...currentData.pricing.pricingCategories,
        ];

        // Add the new special entry to the pricingCategories
        currentPricingCategories.push({
          [formData.specialEntryTypeName]: '0.00',
          selectedOption: 'Flat',
        });

        // Update the "pricing" object within the document
        const updatedPricing = {
          ...currentData.pricing,
          pricingCategories: currentPricingCategories,
        };

        // Update the document with the new data
        await updateDoc(organizerDocRef, {
          specialEntryTypes: { tags: newTags },
          pricing: updatedPricing,
        });
        await updateDoc(competitionDocRef, {
          specialEntryTypes: { tags: newTags },
          pricing: updatedPricing,
        });
      }

      // Clear the input field
      setFormData({
        specialEntryTypeName: '',
        specialMinDancers: '',
        specialMaxDancers: '',
        specialMaxTime: '',
      });
      // Reset the specialTag state
      setSpecialTag({
        age: false,
        classification: false,
        genre: false,
        entry_type: false,
        scored: false,
      });
    }
    // Set loading to false after the update is complete
    setLoading(false);

    // Close the pop-up
    onClose();
  };

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 py-[100px] flex justify-center bg-[#1c1c1c]/90 z-[999]"
    >
      <div className="rounded-lg p-6 w-[40%] h-[410px] mx-auto md:w-1/2 max-w-[700px] min-w-[300px]">
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-[#282929] rounded-lg p-6 scale"
        >
          <form onSubmit={handleSubmit}>
            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Special Entry</label>
              <input
                type="text"
                id="specialEntryTypeName"
                placeholder="Eg. Superb, etc."
                value={formData.specialEntryTypeName}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            <div className="flex items-center gap-2">
              <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
                <label htmlFor="description">Min Dancer</label>
                <input
                  type="number"
                  id="specialMinDancers"
                  placeholder="Eg. #1"
                  value={formData.specialMinDancers}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none outline-none text-white/80"
                />
              </div>
              <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
                <label htmlFor="description">Max Dancer</label>
                <input
                  type="number"
                  id="specialMaxDancers"
                  placeholder="Eg. #10"
                  value={formData.specialMaxDancers}
                  onChange={handleChange}
                  className="w-full bg-transparent border-none outline-none text-white/80"
                />
              </div>
            </div>

            <div className="w-full bg-black/50 p-3 rounded-lg mb-3">
              <label htmlFor="description">Special Time</label>
              <input
                type="number"
                id="specialMaxTime"
                placeholder="Eg. 10"
                value={formData.specialMaxTime}
                onChange={handleChange}
                className="w-full bg-transparent border-none outline-none text-white/80"
              />
            </div>
            {/* tags type */}
            <div className="w-full my-4 md:mt-[1rem] flex md:flex-row flex-wrap justify-start xs:flex-col">
              <div className="flex items-center xs:mx-4">
                <input
                  type="checkbox"
                  id="age"
                  className="mr-2 w-4 h-4"
                  checked={specialTag.age}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="age"
                  className="text-white/80 text-base font-medium"
                >
                  Age
                </label>
              </div>
              <div className="flex items-center mx-4">
                <input
                  type="checkbox"
                  id="classification"
                  className="mr-2 w-4 h-4"
                  checked={specialTag.classification}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="classification"
                  className="text-white/80 text-base font-medium"
                >
                  Classification
                </label>
              </div>
              <div className="flex items-center mx-4">
                <input
                  type="checkbox"
                  id="genre"
                  className="mr-2 w-4 h-4"
                  checked={specialTag.genre}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="genre"
                  className="text-white/80 text-base font-medium"
                >
                  Genre
                </label>
              </div>
              <div className="flex items-center mx-4">
                <input
                  type="checkbox"
                  id="entry_type"
                  className="mr-2 w-4 h-4"
                  checked={specialTag.entry_type}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="entry_type"
                  className="text-white/80 text-base font-medium"
                >
                  Entry Type
                </label>
              </div>
              <div className="flex items-center mx-4">
                <input
                  type="checkbox"
                  id="scored"
                  className="mr-2 w-4 h-4"
                  checked={specialTag.scored}
                  onChange={handleSpecialTagChange}
                />
                <label
                  htmlFor="scored"
                  className="text-white/80 text-base font-medium"
                >
                  Scored
                </label>
              </div>
            </div>
            {/* tags type */}
            <div className="flex gap-3  mb-6 justify-end">
              <button
                type="button"
                onClick={onClose}
                className="px-8 py-1 bg-white rounded-full text-black/80 text-[0.85rem] hover:opacity-75"
              >
                Cancel
              </button>
              <button
                type="submit"
                className={`px-8 flex items-center rounded-full text-black/80 text-[0.85rem] hover:opacity-75 bg-[#94a4fd] cursor-pointer`}
                disabled={loading}
              >
                <span className="px-10 py-2 w-full">
                  {loading ? 'Processing...' : 'Submit'}
                </span>
                {loading && <ButtonLoadingSpinner />}
              </button>
            </div>
            {formErr && <p className="text-red-500">{formErr}</p>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SpecialEntryForm;
