import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import ImgWrap from '../UI/ImgWrapper';
import Container from '../UI/Container';
import Logo from '../../assets/Logo.png';
import Button from '../Form/Button';
import PricingDetails from './Pricing/PricingDetails';

import { setFormCompleted } from 'redux/auth/authSlice';
import ButtonLoadingSpinner from 'utils/ButtonLoadingSpinner';
import { addOrganizerData } from 'services/organizerService';
import { toast } from 'react-toastify';

const Pricing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const reduxStateData = useSelector((state) => state.persistedReducer);
  const [buttonText, setButtonText] = useState('Done');
  const [pricingCategories, setPricingCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const formData = {
    ...reduxStateData,
    pricing: {
      ...reduxStateData.pricing,
      pricingCategories,
    },
  };

  const { entryTypes, specialEntryTypes } = useSelector(
    (state) => state.persistedReducer
  );
  const combineEntries = [...entryTypes, ...specialEntryTypes?.tags];

  const keys = pricingCategories && Object.keys(pricingCategories);

  const handleDoneButtonClick = async () => {
    setLoading(true);

    let result;
    // Display error message if any pricing category value is empty or undefined
    if (keys?.length !== combineEntries?.length) {
      toast.info('Please enter values for Price and Rate');
      setLoading(false);
      return;
    } else {
      result = await addOrganizerData(formData);
    }

    if (result === 'Registration Successful') {
      setButtonText('Registration Successful');
      // update the redux state formCompleted to true
      dispatch(setFormCompleted(true));
      setTimeout(navigate('/organizer/event-selection'), 2000); // Navigate to the next page after 2 seconds
    } else {
      setButtonText('Registration Failed');
    }

    setLoading(false);
  };

  const goToPreviousPage = () => {
    navigate('/organizer/scheduling');
  };

  return (
    <>
      <div className="flex flex-row flex-wrap h-[100vh] w-full">
        <div className="lg:flex hidden lg:w-[40%]">
          <ImgWrap className="bg-[url('../public/Frame1.png')]" />
        </div>
        <div className="lg:w-[60%] md:w-[30]">
          <Container className="flex flex-wrap lg:px-[6%]">
            <div className="flex flex-row justify-between w-full lg:justify-end">
              <div className="block lg:hidden">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Logo"
                    className="cursor-pointer w-[90px] h-[25px]"
                  />
                </Link>
              </div>
              <div className="flex flex-col items-end">
                <span className="text-sm font-medium text-[#BDBDBD]">
                  STEP 06/06
                </span>
                <h4 className="text-base font-semibold text-[#8692A6]">
                  Organizer Registration
                </h4>
              </div>
            </div>

            <div className="flex flex-col flex-wrap pt-[5%] w-full">
              <div className="md:pl-[5%] xs:pl-0 mt-[3rem] md:mt-[2rem]">
                <h1 className="mb-3 text-3xl font-bold text-[#000000] dark:text-white">
                  Pricing
                </h1>
                <p className="text-lg font-normal text-[#8692A6]">
                  Determining the pricing structure.
                </p>
                <div className="border-t border-base w-[60%] mt-5"></div>
              </div>
              <div className="flex flex-wrap mt-5 md:pl-[5%]">
                {/* The segment pertaining to pricing  */}
                <PricingDetails
                  pricingCategories={pricingCategories}
                  setPricingCategories={setPricingCategories}
                  combineEntries={combineEntries}
                />
                {/* End of the segment pertaining to pricing  */}

                {/* btns */}

                <div className="flex flex-row justify-end mt-[25%] mb-[15px] w-full">
                  <Button
                    text="Previous"
                    handleOnClick={goToPreviousPage}
                    className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium mr-3 h-8"
                  />
                  <button
                    className="bg-[#1565D8] border-[#1565D8] text-white rounded-[5px] btn-sm capitalize text-base font-medium mr-3 h-8 flex items-center justify-center"
                    type="submit"
                    disabled={loading}
                    onClick={handleDoneButtonClick}
                  >
                    <span className={loading ? ' text-white mr-2' : ''}>
                      {loading ? 'is submitting' : buttonText}
                    </span>
                    {loading && <ButtonLoadingSpinner className="ml-2" />}
                  </button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default Pricing;
