import {
  toggleDropboard,
  // closeNotifsBoard,
  closeAddDancer,
  getDancers,
} from "../../../redux/danceStudio/danceStudioSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SideBarCompetition from "./SiderBarCompetition";
import { logoutUser } from "redux/auth/authSlice";
import { useFirestoreQueryDoc } from "components/hooks/useFirestoreQueryDoc";
import SpinnerTiny from "utils/SpinnerTiny";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";

const LeftSidebar = () => {
  const { openDropboard, addEvent, editEvent, addDancer } = useSelector(
    (state) => state.danceStudio
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname;
  const { event_name: name } = useParams();

  const event_name = name || localStorage.getItem("event_name") || "";
  const { danceStudioCompData } = useSelector(
    (state) => state.persistedReducer
  );

  const [eventSelect, setEventSelect] = useState(false);

  const [settingsDropdown, setSettingsDropdown] = useState(false);
  function toggleSettingsDropdown() {
    setSettingsDropdown((prev) => !prev);
  }

  const [confirmLogout, setconfirmLogout] = useState(false);
  function toggleconfirmLogout() {
    setconfirmLogout((prev) => !prev);
  }
  useEffect(() => {
    setEventSelect(false);
  }, [currentPage]);

  useEffect(() => {
    dispatch(closeAddDancer());
  }, [currentPage, dispatch]);

  //to get competition name
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { status, data } = useFirestoreQueryDoc("studio_owners", user?.uid);

  const { status: accStatus, data: allStudios } =
    useQueryAllDocs("studio_owners");

  const accountDetails = allStudios?.filter((item) => item?.uid === user?.uid);

  // console.log("user", user);

  //to check if account exists
  if (accStatus === "success" && accountDetails?.length === 0) {
    dispatch(logoutUser());
  }

  useEffect(() => {
    dispatch(getDancers({ uid: user?.uid }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* medium to large screens UI */}
      {/* medium to large screens UI */}
      {/* medium to large screens UI */}

      <div className="sm:w-[205px] lg:w-[250px] h-screen pl-3 pr-1 lg:px-6 py-7 bg-[#1c1c1c] sm:text-[.8rem] lg:text-[.85rem] fixed left-0 top-0 border-r-2 border-[#282929] z-20 sm:flex flex-col hidden overflow-y-auto">
        {/* logo */}
        <div
          onClick={() => navigate("/studio/competition-selection")}
          className="flex items-center gap-3 cursor-pointer"
        >
          <img alt="user" src="/images/ByeWind.png" className="w-6 h-6" />
          {status === "success" ? (
            <h1 className="text-white">
              {capitalizeFirstLetter(data?.studioName)}
            </h1>
          ) : status === "loading" ? (
            <SpinnerTiny />
          ) : null}
        </div>

        {/* nav */}
        {!addEvent && !editEvent && !addDancer && (
          <>
            <nav className="mt-8">
              {/* competitions select*/}
              <div
                onClick={() => {
                  navigate(`/studio/competition-selection`);
                }}
                className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-8 rounded-lg transition-all duration-300 cursor-pointer relative ${
                  currentPage === `/studio/competition-selection` &&
                  "bg-[#282929]"
                }`}
              >
                {currentPage === `/studio/competition-selection` && (
                  <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                )}
                <div className="flex gap-2 items-center">
                  <img
                    alt="building"
                    src="/images/icons8-comp.png"
                    className="w-5 h-5"
                  />

                  <p>Competitions</p>
                </div>
              </div>
            </nav>

            {/* Dashboards */}
            {(currentPage.includes("event/") ||
              danceStudioCompData?.value?.compId) &&
              event_name && (
                <nav className="mt-6">
                  {
                    <div>
                      <div className="">
                        <p className="text-white/40">Dashboards</p>
                      </div>
                    </div>
                  }

                  {/* Dashboards list */}
                  {
                    <div className="mt-3">
                      {
                        <div
                          onClick={() => {
                            navigate(`/studio/event-selection`);
                          }}
                          className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-8 rounded-lg transition-all duration-300 cursor-pointer relative ${
                            currentPage === `/studio/event-selection` &&
                            "bg-[#282929]"
                          }`}
                        >
                          {currentPage === `/studio/event-selection` && (
                            <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                          )}
                          <div className="flex gap-2 items-center">
                            <img
                              alt="building"
                              src="/images/Buildings.png"
                              className="w-5 h-5"
                            />

                            <p>Event Selection</p>
                          </div>
                        </div>
                      }

                      {event_name && (
                        <>
                          <div
                            onClick={() => {
                              navigate(
                                `/studio/event/${event_name?.replace(
                                  / /g,
                                  "-"
                                )}`
                              );
                            }}
                            className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-8 rounded-lg transition-all duration-300 cursor-pointer relative ${
                              currentPage ===
                                `/studio/event/${event_name?.replace(
                                  / /g,
                                  "-"
                                )}` && "bg-[#282929]"
                            }`}
                          >
                            {currentPage ===
                              `/studio/event/${event_name?.replace(
                                / /g,
                                "-"
                              )}` && (
                              <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                            )}
                            <div className="flex gap-2 items-center">
                              <img
                                alt="building"
                                src="/images/PokerChip.png"
                                className="w-5 h-5"
                              />

                              <p>Studio Owner</p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  }
                </nav>
              )}

            {/* Competition */}
            {<SideBarCompetition eventSelect={eventSelect} />}
          </>
        )}

        {/* global */}
        {
          <nav className="mt-6">
            {/* Pages */}
            <div>
              <div className="">
                <p className="text-white/40">Global Settings</p>
              </div>
            </div>

            {/* Pages list */}
            <div className="mt-3">
              <div
                onClick={toggleSettingsDropdown}
                className="flex gap-2 items-center text-white/80 text-[.9rem] p-1 mb-2 hover:bg-[#282929] rounded-lg transition-all duration-300 cursor-pointer"
              >
                <img
                  alt="arrow"
                  src="/images/ArrowDown.png"
                  className={`w-4 h-4 transition-all duration-300 ${
                    settingsDropdown ? "rotate-180" : "rotate-0"
                  }`}
                />
                <div className="flex gap-2 items-center ml-4">
                  <img
                    alt="building"
                    src="/images/GearSix.png"
                    className="w-5 h-5"
                  />

                  <p>Settings</p>
                </div>
              </div>
              {settingsDropdown && (
                <div
                  onClick={() => navigate("/studio/settings")}
                  className={`w-fit flex gap-2 items-center text-white/80 bg-[#282929] hover:bg-[#94a4fd]/50 px-6 py-1 ml-3 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                >
                  <div className="flex gap-2 items-center font-medium">
                    <img
                      alt="building"
                      src="/images/icons8-account-settings-58.png"
                      className="w-5 h-5"
                    />
                    <p>Account Settings</p>
                  </div>
                </div>
              )}
            </div>
          </nav>
        }

        <div
          onClick={toggleconfirmLogout}
          className={`w-full flex gap-2 items-center text-white/80 bg-inherit hover:bg-red-500/60 px-6 py-1 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
        >
          <div className="flex gap-2 items-center font-medium">
            <img
              alt="building"
              src="/images/icons8-logout-48.png"
              className="w-5 h-5"
            />
            <p>Sign Out</p>
          </div>
        </div>

        <div className="w-full h-[300px] mb-auto"></div>

        {/* logo */}
        <img
          alt="logo"
          src="/images/Logo.png"
          className="w-[130px] h-auto mt-10"
        />
      </div>

      {/* mobile and small screens UI */}
      {/* mobile and small screens UI */}
      {/* mobile and small screens UI */}
      {openDropboard && (
        <div className="w-[70%] h-screen pl-6 pr-4 py-5 bg-[#1c1c1c] text-[.85rem] fixed left-0 top-0 border-r-2 border-[#282929] z-30 flex flex-col sm:hidden slideR overflow-y-auto">
          {/* logo  */}
          <div className="mb-5 flex justify-between">
            <div
              onClick={() => dispatch(toggleDropboard())}
              className="w-6 h-6 flex justify-center items-center bg-white/80 rounded-full cursor-pointer"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-4 h-4"
              />
            </div>
            {/* <img alt="user" src="/images/Sun.png" className="w-6 h-6" /> */}
          </div>
          <div
            onClick={() => {
              dispatch(toggleDropboard());
              navigate("/studio/competition-selection");
            }}
            className="flex item-center gap-3"
          >
            <img alt="user" src="/images/ByeWind.png" className="w-6 h-6" />
            {status === "success" ? (
              <h1 className="text-white text-[.9rem] mr-auto">
                {data?.studioName}
              </h1>
            ) : status === "loading" ? (
              <SpinnerTiny />
            ) : null}
          </div>

          {/* nav  */}
          {!addEvent && !editEvent && !addDancer && (
            <>
              <nav className="mt-8">
                {/* competitions select */}
                <div
                  onClick={() => {
                    dispatch(toggleDropboard());
                    navigate(`/studio/competition-selection`);
                  }}
                  className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-5 rounded-lg transition-all duration-300 cursor-pointer relative ${
                    currentPage === `/studio/competition-selection` &&
                    "bg-[#282929]"
                  }`}
                >
                  {currentPage === `/studio/competition-selection` && (
                    <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                  )}
                  <div className="flex gap-2 items-center">
                    <img
                      alt="building"
                      src="/images/icons8-comp.png"
                      className="w-5 h-5"
                    />

                    <p>Competitions</p>
                  </div>
                </div>
              </nav>

              {/* Dashboards */}
              {(currentPage.includes("event/") ||
                danceStudioCompData?.value?.compId) &&
                event_name && (
                  <nav className="mt-6">
                    <div>
                      <div className="">
                        <p className="text-white/40">Dashboards</p>
                      </div>
                    </div>

                    {/* Dashboards list */}
                    <div className="mt-3 text-white/80">
                      <div
                        onClick={() => {
                          dispatch(toggleDropboard());
                          navigate(`/studio/event-selection`);
                        }}
                        className="flex pl-8 gap-2 items-center mb-2"
                      >
                        <div className="flex gap-2 items-center cursor-pointer">
                          <img
                            alt="building"
                            src="/images/Buildings.png"
                            className="w-5 h-5"
                          />

                          <p>Event Selection</p>
                        </div>
                      </div>

                      {event_name && (
                        <>
                          <div
                            onClick={() => {
                              dispatch(toggleDropboard());
                              navigate(
                                `/studio/event/${event_name?.replace(
                                  / /g,
                                  "-"
                                )}`
                              );
                            }}
                            className={`flex gap-2 items-center text-white/80 hover:bg-[#282929] p-1 mb-2 pl-8 rounded-lg transition-all duration-300 cursor-pointer relative ${
                              currentPage ===
                                `/studio/event/${event_name?.replace(
                                  / /g,
                                  "-"
                                )}` && "bg-[#282929]"
                            }`}
                          >
                            {currentPage ===
                              `/studio/event/${event_name?.replace(
                                / /g,
                                "-"
                              )}` && (
                              <div className="w-1 h-[60%] bg-[#94a4fd] absolute left-0 top-[50%] translate-y-[-50%] rounded-full"></div>
                            )}
                            <div className="flex gap-2 items-center">
                              <img
                                alt="building"
                                src="/images/PokerChip.png"
                                className="w-5 h-5"
                              />

                              <p>Studio Owner</p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </nav>
                )}

              {/* Competition */}
              {<SideBarCompetition eventSelect={eventSelect} />}

              {/* global */}
              {
                <nav className="mt-6">
                  {/* Pages */}
                  <div>
                    <div className="">
                      <p className="text-white/40">Global Settings</p>
                    </div>
                  </div>

                  {/* Pages list */}
                  <div className="mt-3">
                    <div
                      onClick={toggleSettingsDropdown}
                      className="flex gap-2 items-center text-white/80 text-[.9rem] p-1 mb-2 hover:bg-[#282929] rounded-lg transition-all duration-300 cursor-pointer"
                    >
                      <img
                        alt="arrow"
                        src="/images/ArrowDown.png"
                        className={`w-4 h-4 transition-all duration-300 ${
                          !settingsDropdown ? "rotate-0" : "rotate-180"
                        }`}
                      />
                      <div className="flex gap-2 items-center ml-4">
                        <img
                          alt="building"
                          src="/images/GearSix.png"
                          className="w-5 h-5"
                        />

                        <p>Settings</p>
                      </div>
                    </div>
                    {settingsDropdown && (
                      <div
                        onClick={() => {
                          navigate("/studio/settings");
                          dispatch(toggleDropboard());
                        }}
                        className={`w-fit flex gap-2 items-center text-white/80 bg-[#282929] hover:bg-[#94a4fd]/50 px-6 py-1 ml-3 mb-2 rounded-lg transition-all duration-300 cursor-pointer relative`}
                      >
                        <div className="flex gap-2 items-center font-medium">
                          <img
                            alt="building"
                            src="/images/icons8-account-settings-58.png"
                            className="w-5 h-5"
                          />
                          <p>Account Settings</p>
                        </div>
                      </div>
                    )}
                  </div>
                </nav>
              }
            </>
          )}
          {/* nav  */}

          <div
            onClick={() => {
              dispatch(toggleDropboard());
              toggleconfirmLogout();
            }}
            className={`w-full flex gap-2 mb-auto mt-3 items-center text-white/80 bg-inherit hover:bg-red-500 px-6 py-1 rounded-lg transition-all duration-300 cursor-pointer relative`}
          >
            <div className="flex gap-2 items-center font-medium">
              <img
                alt="building"
                src="/images/icons8-logout-48.png"
                className="w-5 h-5"
              />
              <p>Sign Out</p>
            </div>
          </div>

          <div className="w-full h-[200px] mb-[200px]"></div>

          {/* logo  */}
          <img
            alt="logo"
            src="/images/Logo.png"
            className="w-[130px] h-auto mt-auto"
          />
        </div>
      )}
      {openDropboard && (
        <div
          onClick={() => dispatch(toggleDropboard())}
          className="w-full h-screen block sm:hidden fixed left-0 top-0 bg-black/60 z-20 appear"
        ></div>
      )}

      {confirmLogout && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg">
            <p className="w-full text-[1rem] text-white text-center pb-3 border-b border-red-400">
              Confirm Sign Out?
            </p>

            <div className="mt-4">
              <button
                onClick={toggleconfirmLogout}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() => dispatch(logoutUser())}
                className="text-[.9rem] px-8 py-2 rounded-md bg-red-400 hover:bg-red-400/60 text-white transition-all duration-300"
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LeftSidebar;
