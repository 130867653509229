import React from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import findIndexByProperty from "utils/findIndexByProperty";
import findLockedEntryLetter from "utils/findLockedEntryLetter";
import moment from "moment";

const PrintScoresPage = ({
  currentEvent,
  divisions,
  // myEntries,
  eventScores,
  calculatePosition,
  allCategories,
  allEntries,
  awards,
  calculateTotalScore,
  studioData,
  calculateAverageScore,
  sortedCategories,
  currentComp,
}) => {
  const studioEntries = allEntries?.filter(
    (each) =>
      each?.event_uid === currentEvent?.event_uid &&
      each?.studio_selected[0] === studioData?.studioName
  );

  const checkboxData = JSON.parse(localStorage.getItem("checkboxData")) || {};

  const startTime = moment(currentEvent?.days[0].startTime)
    .local()
    .format("MMM D");

  const endTime = moment(
    currentEvent?.days[currentEvent?.days?.length - 1].endTime
  )
    .local()
    .format("MMM D");

  return (
    <>
      <PDFViewer style={{ width: "100%", minHeight: "100vh" }}>
        <Document>
          <Page size="A4" style={styles.page}>
            <Text style={styles.title}>
              {capitalizeFirstLetter(currentComp?.competitionName)}:{" "}
              {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
              {currentEvent?.year.split(" to ")[0]}
            </Text>
            <Text style={styles.title2}>
              {capitalizeFirstLetter(currentEvent?.venue)}
            </Text>
            <Text style={styles.title2}>
              {capitalizeFirstLetter(currentEvent?.address)}
            </Text>
            <Text style={styles.title2}>
              {capitalizeFirstLetter(currentEvent?.city)},{" "}
              {capitalizeFirstLetter(currentEvent?.state)}
            </Text>
            <Text style={styles.title2}>
              {currentEvent?.postal_code},{" "}
              {capitalizeFirstLetter(currentEvent?.country)}
            </Text>
            <Text style={styles.title2}>
              {currentEvent?.days?.length === 1
                ? `${startTime}, ${currentEvent?.year}`
                : `${startTime} - ${endTime} ${currentEvent?.year}`}
            </Text>

            <Text
              style={{
                textAlign: "start",
                marginBottom: "5px",
                fontSize: 12,
              }}
            >
              Medal Breakdown
            </Text>
            <View style={styles.sectionComment}>
              {awards?.map((itm, idx) => {
                return (
                  <View key={idx} style={styles.awards}>
                    <Text style={styles.awardsHeader}>
                      {capitalizeFirstLetter(itm?.awardName)}
                    </Text>
                    {/* <Text style={styles.awardsHeader}>"{itm?.code}"</Text> */}
                    <Text style={styles.awardsHeader}>
                      [{itm?.minScore} - {itm?.maxScore}]
                    </Text>
                  </View>
                );
              })}
            </View>

            <Text
              style={{
                textAlign: "start",
                marginBottom: "5px",
                marginTop: 15,
                fontSize: 12,
              }}
            >
              Legend
            </Text>
            <View style={styles.sectionComment}>
              <Text style={styles.legend}>T/F = Technique and Foundation</Text>
              <Text style={styles.legend}>
                C/O = Creativity and Originality
              </Text>
              <Text style={styles.legend}>S/D = Staging and Dynamics</Text>
              <Text style={styles.legend}>E/D = Execution and Difficulty</Text>
              <Text style={styles.legend}>
                P/E = Performance and Entertainment
              </Text>
              <Text style={styles.legend}>DED = Deduction</Text>
              <Text style={styles.legend}>DIV RANK = Division Rank</Text>
            </View>

            {divisions?.map((category, index) => {
              const categories = sortedCategories?.filter(
                (l) => l?.type === "category"
              );

              const allEntriesWithoutLocked = arrangeEntriesByCategories(
                categories,
                allEntries
              )?.filter((k) => k?.locked_entry === false);

              const allEntriesWithLocked = arrangeEntriesByCategories(
                categories,
                allEntries
              );

              const myEntries = allEntries?.filter(
                (each) => each?.event_uid === currentEvent?.event_uid
              );

              const entries = studioEntries?.filter(
                (itm) =>
                  itm?.age_division.toLowerCase() === category.toLowerCase()
              );

              const categoryEntries = myEntries?.filter(
                (itm) =>
                  itm?.age_division.toLowerCase() === category.toLowerCase()
              );

              const sortedEntries = entries?.sort(
                (a, b) => entryCount(a) - entryCount(b)
              );

              const entriesWithScores = categoryEntries?.filter(
                (y) => calculateTotalScore(y) !== "- -"
              );

              const rankedEntries = entriesWithScores?.sort(
                (a, b) =>
                  calculatePosition(a, entriesWithScores) -
                  calculatePosition(b, entriesWithScores)
              );

              function getFirst20Percent() {
                if (Array.isArray(rankedEntries)) {
                  const length = rankedEntries?.length;
                  const startIndex = 0;
                  const endIndex = Math.ceil(length * 0.2);

                  return rankedEntries?.slice(startIndex, endIndex);
                }
              }

              //============================================================entry count calc
              function entryCount(itm) {
                const allEntriesBeforeLocked = allEntriesWithLocked
                  ?.slice(
                    0,
                    findIndexByProperty(
                      allEntriesWithLocked,
                      "doc_id",
                      itm?.doc_id
                    )
                  )
                  ?.filter((m) => m?.locked_entry === false);

                if (itm?.locked_entry) {
                  return `${
                    allEntriesBeforeLocked?.length === 0
                      ? 1
                      : allEntriesBeforeLocked?.length
                  }${findLockedEntryLetter(allEntriesWithLocked, itm)}`;
                } else {
                  return (
                    findIndexByProperty(
                      allEntriesWithoutLocked,
                      "doc_id",
                      itm?.doc_id
                    ) + 1
                  );
                }
              }

              return (
                <View key={index} style={styles.sectionTable}>
                  <View style={styles.tableRow1}>
                    <Text style={styles.tableHeader}>{category}</Text>
                  </View>

                  <View style={styles.tableRow3}>
                    {checkboxData?.division_rank &&
                      currentEvent?.rank_visibility && (
                        <Text style={styles.tableHeader2}>DIV RANK</Text>
                      )}
                    {checkboxData?.entry_number && (
                      <Text style={styles.tableHeader2}>ENTRY #</Text>
                    )}
                    <Text style={styles.tableHeader2}>ENTRY NAME</Text>
                    {checkboxData?.technique && (
                      <Text style={styles.tableHeader2}>T/F</Text>
                    )}
                    {checkboxData?.creativity && (
                      <Text style={styles.tableHeader2}>C/O</Text>
                    )}
                    {checkboxData?.staging && (
                      <Text style={styles.tableHeader2}>S/D</Text>
                    )}
                    {checkboxData?.execution && (
                      <Text style={styles.tableHeader2}>E/D</Text>
                    )}
                    {checkboxData?.performance && (
                      <Text style={styles.tableHeader2}>P/E</Text>
                    )}
                    {checkboxData?.deductions && (
                      <Text style={styles.tableHeader2}>DED</Text>
                    )}
                    {checkboxData?.average_total && (
                      <Text style={styles.tableHeader2}>AVG TOTAL</Text>
                    )}
                    {checkboxData?.award && (
                      <Text style={styles.tableHeader2}>AWARD</Text>
                    )}
                    {checkboxData?.notes && (
                      <Text style={styles.tableHeader2}>NOTES</Text>
                    )}
                  </View>

                  {/* row */}
                  {sortedEntries?.map((row, uselessIdx) => {
                    const entryScores = eventScores?.filter(
                      (item) => item?.doc_id === row?.doc_id
                    )[0];

                    const entryScoresAll = eventScores?.filter(
                      (item) => item?.doc_id === row?.doc_id
                    );

                    const deductionAll = entryScoresAll?.map(
                      (item) => item?.deductions
                    );

                    const deductions = deductionAll?.filter(
                      (item) => item !== "none"
                    );

                    const myDivRankArray = getFirst20Percent()?.filter(
                      (x) => x?.id === row?.id
                    );
                    return (
                      <View key={uselessIdx} style={styles.tableRow4}>
                        {checkboxData?.division_rank &&
                          currentEvent?.rank_visibility && (
                            <Text style={styles.tableHeader2}>
                              <Text className="w-[100px]">
                                {/* {calculatePosition(row, categoryEntries)} */}
                                {myDivRankArray?.length > 0
                                  ? calculatePosition(
                                      row,
                                      rankedEntries
                                    )?.replace(/(st|nd|rd|th)/g, "")
                                  : "-"}
                              </Text>
                            </Text>
                          )}
                        {checkboxData?.entry_number && (
                          <Text style={styles.tableHeader2}>
                            {currentEvent?.locked
                              ? row?.locked_entry_count
                              : entryCount(row)}
                          </Text>
                        )}
                        <Text style={styles.tableHeader2}>
                          {capitalizeFirstLetter(row?.entry_name)}
                        </Text>
                        {checkboxData?.technique && (
                          <Text style={styles.tableHeader2}>
                            <Text className="w-[60px]">
                              {isNaN(
                                calculateAverageScore(
                                  entryScoresAll,
                                  "technique"
                                )
                              )
                                ? "- -"
                                : calculateAverageScore(
                                    entryScoresAll,
                                    "technique"
                                  )}
                            </Text>
                          </Text>
                        )}
                        {checkboxData?.creativity && (
                          <Text style={styles.tableHeader2}>
                            <Text className="w-[60px]">
                              {isNaN(
                                calculateAverageScore(
                                  entryScoresAll,
                                  "creativity"
                                )
                              )
                                ? "- -"
                                : calculateAverageScore(
                                    entryScoresAll,
                                    "creativity"
                                  )}
                            </Text>
                          </Text>
                        )}
                        {checkboxData?.staging && (
                          <Text style={styles.tableHeader2}>
                            <Text className="w-[60px]">
                              {isNaN(
                                calculateAverageScore(entryScoresAll, "staging")
                              )
                                ? "- -"
                                : calculateAverageScore(
                                    entryScoresAll,
                                    "staging"
                                  )}
                            </Text>
                          </Text>
                        )}
                        {checkboxData?.execution && (
                          <Text style={styles.tableHeader2}>
                            <Text className="w-[60px]">
                              {isNaN(
                                calculateAverageScore(
                                  entryScoresAll,
                                  "execution"
                                )
                              )
                                ? "- -"
                                : calculateAverageScore(
                                    entryScoresAll,
                                    "execution"
                                  )}
                            </Text>
                          </Text>
                        )}
                        {checkboxData?.performance && (
                          <Text style={styles.tableHeader2}>
                            <Text className="w-[60px]">
                              {isNaN(
                                calculateAverageScore(
                                  entryScoresAll,
                                  "performance"
                                )
                              )
                                ? "- -"
                                : calculateAverageScore(
                                    entryScoresAll,
                                    "performance"
                                  )}
                            </Text>
                          </Text>
                        )}
                        {checkboxData?.deductions && (
                          <Text style={styles.tableHeader2}>
                            {deductions[0]?.length > 0
                              ? deductions[0]?.length
                              : "- -"}
                          </Text>
                        )}
                        {checkboxData?.average_total && (
                          <Text style={styles.tableHeader2}>
                            {calculateTotalScore(row) === 0
                              ? "- -"
                              : calculateTotalScore(row)}
                          </Text>
                        )}
                        {checkboxData?.award && (
                          <Text style={styles.tableHeader2}>
                            <Text className="w-[100px]">
                              {capitalizeFirstLetter(entryScores?.award_name)
                                ? capitalizeFirstLetter(entryScores?.award_name)
                                : "- -"}
                            </Text>
                          </Text>
                        )}
                        {checkboxData?.notes && (
                          <Text style={styles.tableHeader2}>
                            {row?.notes?.map((note, noteIndex) => (
                              <React.Fragment key={noteIndex}>
                                {noteIndex > 0 && ", "}
                                <Text className="w-[100px]">{note?.note}</Text>
                              </React.Fragment>
                            ))}
                          </Text>
                        )}

                        {/* {checkboxData?.notes && (
                          <Image
                            style={{
                              width: '10px',
                              height: '10px',
                            }}
                            alt="arrow"
                            src="/images/NotePencil.png"
                            // className={`note w-8 h-8 cursor-pointer`}
                          />
                        )} */}
                      </View>
                    );
                  })}
                </View>
              );
            })}
          </Page>
        </Document>
      </PDFViewer>
    </>
  );
};

export default PrintScoresPage;

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: 20,
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  title2: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    textAlign: "center",
  },

  sectionTable: {
    marginTop: 10,
  },

  tableRow1: {
    flexDirection: "row",
    borderBottom: "1pt solid #000000",
    alignItems: "center",
    padding: 5,
    backgroundColor: "#282929",
    color: "white",
    marginTop: 20,
  },
  tableRow3: {
    flexDirection: "row",
    borderBottom: "1pt solid #000000",
    alignItems: "center",
    padding: 5,
    marginTop: 2,
  },
  tableRow4: {
    flexDirection: "row",
    borderBottom: "1pt solid #282929",
    alignItems: "center",
    padding: 5,
    fontSize: 8,
  },

  awards: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: 10,
  },
  awardsHeader: {
    flex: 1,
    textAlign: "start",
    letterSpacing: ".85px",
  },

  tableRow2: {
    flexDirection: "row",
    alignItems: "center",
  },

  tableHeader: {
    fontSize: 10,
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
    textTransform: "uppercase",
  },

  tableHeader2: {
    fontSize: 7,
    fontWeight: "bold",
    flex: 1,
    textAlign: "center",
  },
  sectionComment: {
    width: "200px",
    // minHeight: "100px",
    padding: "5px",
    marginBottom: 5,
    marginRight: "auto",
    border: "1px solid black",
  },
  legend: {
    fontSize: 9,
    letterSpacing: ".8px",
  },
});
