import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./animations/animation.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import {
  FirebaseAppProvider,
  FirestoreProvider,
  useFirestoreDocData,
  useFirestore,
  useFirebaseApp,
} from "reactfire";
import { db, firebaseConfig } from "services/firebase";

// sentry set-up
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://f10e6834a1e7235666a81d92b5513b04@o4505569175011328.ingest.sentry.io/4505963305500672",
  integrations: [new Integrations.BrowserTracing()],
  // integrations: [
  //   new Sentry.BrowserTracing({
  //     tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  //   }),
  //   new Sentry.Replay(),
  // ],
  // Performance Monitoring
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirestoreProvider sdk={db}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </FirestoreProvider>
    </FirebaseAppProvider>
    <ToastContainer position="top-right" />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
