import { useQueryAllDocs } from 'components/hooks/useQueryAllDocs';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { logoutUser } from 'redux/auth/authSlice';
import EmceeSearchResult from './EmceeSearchResult';
import { useState } from 'react';

const Header = ({ allEntries, sortedCategories }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data } = useQueryAllDocs('competitions');

  const { competitionName, event_name } = useParams();
  const currentComp = data?.filter(
    (item) => item?.competitionName === competitionName
  )[0];

  const currentEvent = currentComp?.events?.filter(
    (item) => item?.event_name === event_name
  )[0];

  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  function handleSearch(e) {
    let term = e.target.value;
    setSearchTerm(term);
    const filtered = allEntries?.filter((x) =>
      x?.entry_name?.toLowerCase()?.includes(term?.toLowerCase())
    );
    setSearchResults(filtered);
  }

  function clearSearch() {
    setSearchTerm('');
  }

  const [displayName, setDisplayName] = useState('');

  return (
    <>
      {/* desktop ui */}
      {/* desktop ui */}
      {/* desktop ui */}

      <header
        className={`w-full text-white/80 py-6 px-10 bg-[#1c1c1c] fixed left-0 top-0 border-b-2 border-[#282929] hidden lg:flex items-center justify-end z-[100] transition-all duration-700`}
      >
        <button
          onClick={() => {
            dispatch(logoutUser());
            navigate('/');
          }}
          className="mr-auto bg-red-400/30 px-3 py-1 rounded-lg"
        >
          Logout
        </button>

        <div className="flex gap-4 items-center">
          <div className="flex gap-4 items-center md:border lg:border-0 border-[#94a4fd]/60 px-2 py-1 rounded-full md:bg-[#94a4fd]/20 lg:bg-transparent cursor-pointer">
            {event_name && (
              <div className="h-[30px] flex mr-4 ml-auto">
                <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
                  <img
                    alt="user"
                    src="/images/Search.png"
                    className="w-5 h-5 cursor-pointer"
                  />
                </div>
                <input
                  type="text"
                  placeholder="Search Entries"
                  value={searchTerm}
                  onChange={(e) => handleSearch(e)}
                  className="md:w-[130px] lg:w-[250px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
                />
              </div>
            )}
            {currentEvent?.locked && (
              <img
                alt="arrow"
                src="/images/LockSimple.png"
                className="w-5 h-5"
              />
            )}
          </div>
        </div>
      </header>

      {/* small screens ui */}
      {/* small screens ui */}
      {/* small screens ui */}

      <header className="w-full text-white/80 py-6 md:pl-10 lg:pl-[282px] pr-[25px] lg:pr-[332px] bg-[#1c1c1c] fixed left-0 top-0 border-b-2 border-[#282929] hidden md:flex lg:hidden items-center z-10">
        <button
          onClick={() => {
            dispatch(logoutUser());
            navigate('/');
          }}
          className="mr-auto bg-red-400/30 px-3 py-1 rounded-lg"
        >
          Logout
        </button>

        {event_name && (
          <div className="h-[30px] flex mr-4 ml-auto">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Entries"
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
              className="md:w-[130px] lg:w-[250px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
            />
          </div>
        )}

        <div className="flex gap-3 items-center">
          {currentEvent?.locked && (
            <img alt="arrow" src="/images/LockSimple.png" className="w-5 h-5" />
          )}

          {/* <div
            // onClick={() => dispatch(toggleNotifsBoard())}
            className="w-7 h-7 flex justify-center items-center border border-[#94a4fd]/60 rounded-full bg-[#94a4fd]/10 cursor-pointer"
          >
            <img
              alt="user"
              src="/images/Bell.png"
              className="w-4 h-4 md:w-4 md:h-4"
            />
          </div> */}
        </div>
      </header>

      {/* Mobile UI */}
      {/* Mobile UI */}
      {/* Mobile UI */}

      <header className="w-full text-white/80 py-4 px-3 sm:pl-[235px] bg-[#1c1c1c] fixed left-0 top-0 border-b-2 border-[#282929] flex md:hidden items-center justify-between z-10 transition-all duration-300">
        <button
          onClick={() => {
            dispatch(logoutUser());
            navigate('/');
          }}
          className="mr-auto bg-red-400/30 px-3 py-1 rounded-lg"
        >
          Logout
        </button>
        {event_name && (
          <div className="h-[30px] flex mr-4 ml-auto">
            <div className="h-full w-fit p-1 bg-[#333333] rounded-l-lg">
              <img
                alt="user"
                src="/images/Search.png"
                className="w-5 h-5 cursor-pointer"
              />
            </div>
            <input
              type="text"
              placeholder="Search Entries"
              value={searchTerm}
              onChange={(e) => handleSearch(e)}
              className="w-[130px] text-[.9em] bg-[#333333] rounded-r-lg outline-none pr-2 py-1"
            />
          </div>
        )}
        {currentEvent?.locked && (
          <img alt="arrow" src="/images/LockSimple.png" className="w-5 h-5" />
        )}
      </header>

      {searchTerm && (
        <EmceeSearchResult
          searchResults={searchResults}
          searchTerm={searchTerm}
          clearSearch={clearSearch}
          currentEvent={currentEvent}
          allEntries={allEntries}
          sortedCategories={sortedCategories}
          compId={currentComp?.uid}
          setDisplayName={setDisplayName}
        />
      )}

      {displayName && (
        <div className="w-full h-screen fixed top-0 left-0 flex justify-center items-center text-white text-[1.25rem] lg:text-[2rem] font-bold bg-black/30 z-[3]">
          <p className="w-[50%] min-w-[250px] p-10 bg-black/50 rounded-xl text-center">
            {displayName}
          </p>
        </div>
      )}
      {/* <div className="w-full md:w-[450px] bg-black p-5 border border-white/30 rounded-xl text-white fixed shadow-xl top-[100px] right-10 z-[999]">
        The emcee/stage-manager dashboards are undergoing maintenance! Please
        check back later!
      </div> */}
    </>
  );
};

export default Header;
