import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useState } from "react";
import { db } from "services/firebase";

const NotifsCard = ({ item }) => {
  //==========================================================================to format timestamp
  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    const currentDate = new Date();
    const formattedDate = date.toLocaleString("default", {
      month: "short",
      day: "numeric",
    });
    const formattedTime = date.toLocaleString("default", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    if (isSameDay(date, currentDate)) {
      return `Today, ${formattedTime}`;
    } else if (isYesterday(date, currentDate)) {
      return `Yesterday, ${formattedTime}`;
    } else {
      return `${formattedDate}, ${formattedTime}`;
    }
  }

  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  function isYesterday(date1, date2) {
    const yesterday = new Date(date2);
    yesterday.setDate(date2.getDate() - 1);
    return isSameDay(date1, yesterday);
  }

  const [showDetails, setShowDetails] = useState(null);
  function openDetails(notif) {
    setShowDetails(notif);
  }
  function closeDetails(notif) {
    setShowDetails(null);
    if (!notif?.read) {
      markNotificationAsRead(notif);
    }
  }

  //=======================================================to mark notif as read
  async function markNotificationAsRead(notif) {
    const queryRef = query(
      collection(db, "notifications"),
      where("id", "==", notif?.id)
    );
    const querySnapshot = await getDocs(queryRef);

    if (!querySnapshot.empty) {
      const notificationDoc = querySnapshot.docs[0];

      try {
        const notificationRef = doc(db, "notifications", notificationDoc.id);
        await setDoc(notificationRef, { read: true }, { merge: true });
        setShowDetails(notif);
        console.log("Notification marked as read successfully!");
      } catch (error) {
        console.error("Error marking notification as read:", error);
      }
    } else {
      console.error("Notification with matching ID not found.");
    }
  }

  return (
    <>
      {" "}
      <div
        onClick={() => openDetails(item)}
        className="w-full flex gap-3 item-start mb-3 px-1 rounded-lg hover:bg-[#282929] cursor-pointer transition-all duration-300 relative"
      >
        {!item?.read && (
          <div className="w-3 h-3 bg-red-500 rounded-full absolute top-[-2px] left-[-2px]"></div>
        )}
        <img
          alt="icon"
          src={
            item?.type === "dancer"
              ? "/images/user-added.png"
              : item?.type === "entry"
              ? "/images/payment.png"
              : ""
          }
          className="w-6 h-6"
        />
        <div>
          <h3 className="text-white/80 text-[.85em] w-[200px] whitespace-nowrap truncate">
            {item?.message}
          </h3>
          <p className="text-white/30 text-[.75em] mt-[-3px]">
            {formatTimestamp(item?.timestamp)}
          </p>
        </div>
      </div>
      {showDetails === item && (
        <div
          onClick={() => closeDetails(showDetails)}
          className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col justify-start items-center scale shadow-lg relative"
          >
            <div
              onClick={() => closeDetails(showDetails)}
              className="w-5 h-5 flex justify-center items-center bg-white/80 rounded-full cursor-pointer absolute right-3"
            >
              <img
                alt="user"
                src="/images/icons8-close-50.png"
                className="w-3 h-3"
              />
            </div>
            <p className="w-full text-[1rem] text-white text-center pb-3 border-b border-white/60">
              Notification
            </p>
            <p className="text-white/60 mt-3 w-full">
              {formatTimestamp(item?.timestamp)}
            </p>
            <p className="text-white mt-4 w-full">{item?.message}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default NotifsCard;
