import { getAuth, onAuthStateChanged } from "firebase/auth";

const auth = getAuth();

export const getUser = async () => {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // console.log("User is logged in:", user);
        resolve(user);
      } else {
        // console.log("User is not logged in.");
        resolve(null); // You can also reject here if you prefer.
      }
    });
  });
};
