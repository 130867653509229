export default function removeDuplicates(array) {
  if (array) {
    const uniqueArray = [];
    const seenObjects = new Set();

    for (const obj of array) {
      // Serialize the object to JSON to use it as a unique identifier
      const serializedObj = JSON.stringify(obj);

      if (!seenObjects.has(serializedObj)) {
        seenObjects.add(serializedObj);
        uniqueArray.push(obj);
      }
    }

    return uniqueArray;
  }
}
