import Container from "./Container";
import React from "react";
import { Link } from "react-router-dom";

// import CustomBtn from '../forms/CustomBtn';
import UserIcon from "../../assets/user.png";
import BriefCase from "../../assets/briefcase.png";
import judge from "../../assets/judge.png";
import stage from "../../assets/stage.png";
import emcee from "../../assets/emcee.png";

import Logo from "../../assets/Logo.png";
import CustomBtn from "components/Form/CustomBtn";

export default function FormWrapper() {
  return (
    <Container className="flex flex-wrap">
      <div className="flex flex-row justify-between w-full lg:justify-end">
        <div className="block cursor-pointer lg:hidden">
          <Link to="/">
            <img src={Logo} alt="Logo" className="w-[90px] h-[25px]" />
          </Link>
        </div>
      </div>
      <div className="lg:pl-[63px] md:pl-[63px] pt-[6rem]">
        <div className="">
          <h1 className="mb-2 text-3xl font-bold text-[#000000] dark:text-white">
            Join Us!
          </h1>
          <p className="text-lg font-normal text-[#8692A6] dark:text-white w-[80%]">
            To begin using Scorebox, tell us what type of account you will be
            using.
          </p>
        </div>

        <div className="flex flex-col">
          <CustomBtn
            className="mt-[35px] py-[1.2rem]"
            url="/login"
            id={1}
            userIcon={UserIcon}
            title={"Dance Studio Owner"}
            text={
              "Account to manage students, entries and the events you will be attending."
            }
          />
          <CustomBtn
            userIcon={BriefCase}
            url="/login"
            id={2}
            className="mt-[20px] py-[1.2rem]"
            title={"Competition Owner"}
            text={"Create and manage events for studios to submit entries."}
          />
          <CustomBtn
            userIcon={judge}
            url="/judge-login"
            id={3}
            className="mt-[20px] py-[1.2rem]"
            title={"Judge"}
            text={"Adjudicate entries."}
          />
          <CustomBtn
            userIcon={emcee}
            url="/emcee-login"
            id={4}
            className="mt-[20px] py-[1.2rem]"
            title={"Emcee"}
            text={"Announce entries and awards."}
          />
          <CustomBtn
            userIcon={stage}
            url="/stage-login"
            id={5}
            className="mt-[20px] py-[1.2rem]"
            title={"Stage Manager"}
            text={"Keep track and manage competition entries at the event."}
          />
        </div>
      </div>
    </Container>
  );
}
