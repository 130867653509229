import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import ScrollToTop from "../ScrollToTop";
import EditDancerForm from "./EditDancerForm";
import Footer from "./Footer";

const EditDancerContainer = ({ studio_data, dancerToEdit, currentEvent }) => {
  return (
    <div className="w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] lg:pr-[330px] pt-[80px] sm:pt-[110px] bg-[#1c1c1c] flex flex-col">
      <div className="mb-auto">
        {/* Heading */}
        <div className="flex gap-2 items-center text-white/80 text-[1rem] mb-6">
          <h2>
            {capitalizeFirstLetter(studio_data?.studioName)}{" "}
            {/* {currentEvent?.year.split(" to ")[0]} */}
          </h2>{" "}
          <img alt="arrow" src="/images/ArrowDown.png" className="w-4 h-4" />
        </div>

        {/* Add event form */}
        <div className="">
          <EditDancerForm
            dancerToEdit={dancerToEdit}
            currentEvent={currentEvent}
          />
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default EditDancerContainer;
