import { useDispatch } from "react-redux";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { Footer, ScrollToTop } from "..";
import DeletedView from "./DeletedView";
import { useState } from "react";

const ViewDeletedContainer = ({
  openNotifsBoard,
  toggleViewDeleted,
  currentEvent,
  deletedCategories,
  entryTypes,
}) => {
  const dispatch = useDispatch();

  const [dropDown, setDropDown] = useState(null);
  function toggleOpen(item) {
    setDropDown((prev) => (prev ? null : item));
  }
  return (
    <div
      onClick={() => setDropDown(null)}
      className={`w-full min-h-screen px-5 sm:pl-[230px] lg:pl-[280px] sm:pr-[30px] pt-[80px] sm:pt-[110px] ${
        openNotifsBoard ? "lg:pr-[310px]" : "lg:pr-[32px]"
      } bg-[#1c1c1c] flex flex-col relative`}
    >
      <button
        onClick={() => dispatch(toggleViewDeleted())}
        className="text-[.85rem] px-5 py-1 mr-4 rounded-lg bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300 absolute top-20 md:top-[110px] right-1"
      >
        Back
      </button>
      <div className="mb-auto w-full">
        {/* Heading */}
        <div className="flex gap-2 items-center text-white/80 text-[.86rem] md:text-[1rem] mb-6">
          <h2>
            {capitalizeFirstLetter(currentEvent?.event_name)}{" "}
            {currentEvent?.year.split(" to ")[0]} (Deleted)
          </h2>
          <img alt="arrow" src="/images/ArrowDown.png" className="w-4 h-4" />
        </div>

        <div className="">
          <DeletedView
            deletedCategories={deletedCategories}
            dropDown={dropDown}
            toggleOpen={toggleOpen}
            entryTypes={entryTypes}
          />
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default ViewDeletedContainer;
