/* eslint-disable react-hooks/exhaustive-deps */
import { collection, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  clearSuccess,
  deleteEntry,
} from "redux/routineScheduling/routineSchedulingSlice";
import { db } from "services/firebase";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
import { updateEntriesAfterLock } from "utils/updateEntriesAfterLock";
import EntryDropdownCont from "./EntryDropdownCont";
import moment from "moment";
import arrangeEntriesByCategories from "utils/arrangeEntriesByCategories";

const RoutineEntry = ({
  idx,
  item,
  entryTime,
  entryCount,
  category,
  notesWithoutChoreoAndSpecial,
  loading,
  // setShowDropdownRow,
  // showDropdownRow,
  currentEvent,
  closeDropdown,
  setSelectedCategory,
  allEntries,
  sortedCategories,
  setShowNotes,
  showNotes,
  showMoveEntry,
  setShowMoveEntry,
  setLoadingEntry,
  myEntriesSorted,
  rearrangeCategories,
  // setShowNames,
  // showNames,
  confrmDrag,
  rerender,
  setLastEntryTime,
  lastPageData,
  currentPage,
}) => {
  useEffect(() => {
    function addMinutesToTime(inputTime, minutesToAdd) {
      const timeMoment = moment(inputTime, "h:mm A");
      const endTime = timeMoment.clone().add(minutesToAdd, "minutes");
      const formattedEndTime = endTime.format("h:mm A");
      return formattedEndTime;
    }

    if (item && allEntries) {
      const lastCategory = sortedCategories[sortedCategories?.length - 1];
      const lastEntry = allEntries
        ?.filter((x) => x?.category_name === lastCategory?.category_name)
        ?.sort((a, b) => b?.sort_id - a?.sort_id)[0];

      if (lastEntry?.id === item?.id) {
        setLastEntryTime(
          addMinutesToTime(entryTime(item), item?.performance_time)
        );
      }
    }
  }, [item, allEntries]);

  const { user } = useSelector((state) => state.persistedReducer.auth.user);

  const [showDropdownRow, setShowDropdownRow] = useState(null);

  const [showNamesEach, setShowNamesEach] = useState(null);

  const { deleteEntryPending, deleteEntrySuccess } = useSelector(
    (state) => state.routineScheduling
  );

  const { lockSchedulePending } = useSelector(
    (state) => state.organizerScheduling
  );

  const dispatch = useDispatch();

  const toggleDropdown = () => {
    setShowDropdownRow((prev) => (prev ? null : item));
    setSelectedCategory(null);
  };

  const toggleNotes = (entry) => {
    setShowNotes(showNotes ? "" : entry);
  };

  const [deleteEntryMod, setDeleteEntryMod] = useState(false);
  const toggleDeleteEntryMod = () => {
    setDeleteEntryMod((prev) => !prev);
    setShowDropdownRow(null);
  };

  useEffect(() => {
    if (deleteEntrySuccess?.success) {
      setDeleteEntryMod(false);
      dispatch(clearSuccess());
    }
  }, [deleteEntrySuccess]);

  //=================================================================================to handle toggle show move entry options
  function toggleMoveEntry() {
    setShowMoveEntry((prev) => !prev);
  }

  const categoryEntriesWithoutCurrent = allEntries?.filter(
    (itm) => itm?.category_name === category?.category_name && itm !== item
  );

  const rearrangeENtries = async (id1, id2) => {
    setLoadingEntry(true);
    closeDropdown();

    const sourceIndex = myEntriesSorted?.findIndex(
      (entry) => entry?.id === id1
    );
    const destinationIndex = myEntriesSorted?.findIndex(
      (entry) => entry?.id === id2
    );

    const updatedEntries = Array.from(myEntriesSorted);
    const draggedRow = updatedEntries[sourceIndex];

    // Remove the dragged entry from its original position
    updatedEntries.splice(sourceIndex, 1);

    // Insert the dragged entry at the destination index
    updatedEntries.splice(destinationIndex, 0, draggedRow);

    // Update the sort_id for all categories after the dragged entry
    const maxSortUd = updatedEntries?.reduce((maxSortId, obj) => {
      return obj.sort_id > maxSortId ? obj.sort_id : maxSortId;
    }, updatedEntries[0]?.sort_id);

    updatedEntries.forEach((entry, index) => {
      if (index >= destinationIndex) {
        entry.sort_id = index + 1 + maxSortUd;
      }
    });

    myEntriesSorted = updatedEntries;

    //update docuents on firestore here
    myEntriesSorted?.forEach(async (entry) => {
      try {
        // Update the Firestore document with the new sort_id using updateDoc function
        const collectionRef = collection(
          db,
          "competitions",
          user?.uid,
          "entries"
        );
        const docRef = doc(collectionRef, entry?.doc_id);
        await updateDoc(docRef, { sort_id: entry.sort_id });
        console.log({ success: true });
      } catch (error) {
        console.error("Error updating entries sort_id:", error);
      }
    });

    setLoadingEntry(true);
    closeDropdown();
  };

  //=======================================================================================to save entry_time and entry_count to firestore after lock

  const time = item?.locked_entry ? "AFTER LOCK" : entryTime(item);
  useEffect(() => {
    if (lockSchedulePending) {
      updateEntriesAfterLock(user?.uid, item?.doc_id, time, entryCount(item));
    }
  }, [lockSchedulePending]);

  return (
    <div
      className={`w-full min-w-[900px] flex p-3 border-b border-white/10 text-[.85rem] relative cursor-pointer ${
        category?.is_scratched ? "text-white/50" : "text-white"
      } ${showDropdownRow?.id === item?.id && "mb-[80px]"} `}
      onClick={(e) => {
        e.stopPropagation();
        if (!currentEvent?.locked) {
          toggleDropdown();
        }
      }}
    >
      {item?.is_scratched && (
        <div className="w-full h-full bg-stone-700/60 absolute top-0 left-0"></div>
      )}
      <div className="flex gap-[50px] mr-auto">
        <img
          alt="arrow"
          src={`${
            item?.is_scratched
              ? "/images/scratchCat.png"
              : item?.checkedIn
              ? "/images/CheckSquareOffset.png"
              : "/images/CheckSquareOffset2.png"
          }`}
          className={`w-5 ${item?.is_scratched ? "h-4 mt-1" : "h-5"} ${
            category?.is_scratched && "opacity-50 h-3"
          }`}
        />
        {/* {!loading ? (
          <img
            alt="arrow"
            src={`${
              item?.is_scratched
                ? "/images/scratchCat.png"
                : item?.checkedIn
                ? "/images/CheckSquareOffset.png"
                : "/images/CheckSquareOffset2.png"
            }`}
            className={`w-5 ${item?.is_scratched ? "h-4 mt-1" : "h-5"} ${
              category?.is_scratched && "opacity-50 h-3"
            }`}
          />
        ) : (
          <PulseLoader color="#94a4fd" size={5} />
        )} */}
        <p
          className={`min-w-[70px] whitespace-nowrap ${
            item?.locked_entry && "text-white/40 text-[.8em]"
          }`}
        >
          {currentEvent?.locked ? item?.locked_entry_time : time}
        </p>
        <p className="min-w-[30px]">
          {currentEvent?.locked ? item?.locked_entry_count : entryCount(item)}
        </p>
        <p className="whitespace-nowrap max-w-[200px] truncate">
          {capitalizeFirstLetter(item?.entry_name)}
        </p>
      </div>
      <div className="w-[45%] flex gap-[50px]">
        <p className="">{item?.studio_letter}</p>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShowNamesEach((prev) => (prev ? null : item));
          }}
          className={`mr-auto whitespace-nowrap w-[250px] px-2 hover:bg-[#94a4fd]/40 rounded-md relative ${
            item?.dancers_selected?.length >= 2 ? "truncate" : ""
          }`}
        >
          {item?.dancers_selected?.map((dancer, index) => (
            <React.Fragment key={index}>
              {index > 0 && ", "}
              <span>
                {capitalizeFirstLetter(dancer?.first_name)}{" "}
                {capitalizeFirstLetter(dancer?.last_name)}
              </span>
            </React.Fragment>
          ))}
          {item?.id === showNamesEach?.id &&
            showNamesEach?.dancers_selected?.length >= 2 && (
              <ul className="w-[220px] bg-[#282929] rounded-md px-2 pb-2 pt-6 flex flex-col z-[100] shadow-lg relative">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowNamesEach((prev) => (prev ? null : item));
                  }}
                  className="w-5 h-5 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-2 right-2"
                >
                  <img
                    alt="user"
                    src="/images/icons8-close-50.png"
                    className="w-3 h-3"
                  />
                </div>
                {showNamesEach?.dancers_selected?.map((x, i) => {
                  return (
                    <li key={i} className="w-full border-b border-white/10">
                      {capitalizeFirstLetter(x?.first_name)}{" "}
                      {capitalizeFirstLetter(x?.last_name)}
                    </li>
                  );
                })}
              </ul>
            )}
        </div>
        <div className="flex gap-1 items-start">
          <img
            alt="arrow"
            src={
              item?.isDancerStarts
                ? "/images/PersonGreen.png"
                : "/images/Person.png"
            }
            className={`w-5 h-5 ${category?.is_scratched && "opacity-50"}`}
          />
          <img
            alt="arrow"
            src={
              item?.isCheckedProps
                ? "/images/ClockGreen.png"
                : "/images/Clock.png"
            }
            className={`w-5 h-5 ${category?.is_scratched ? "opacity-50" : ""}`}
          />
          <img
            alt="runaround"
            src={
              item?.isRunAround
                ? "/images/runaround-green.png"
                : "/images/runaround.png"
            }
            className={`w-5 h-5 ${category?.is_scratched ? "opacity-50" : ""}`}
          />
          <img
            alt="arrow"
            onClick={(e) => {
              e.stopPropagation();
              if (item?.notes) {
                toggleNotes(item);
              }
            }}
            src={`${
              notesWithoutChoreoAndSpecial?.length > 0
                ? "/images/NotePencil-green.png"
                : "/images/NotePencil.png"
            }`}
            className={`w-5 h-5 ${category?.is_scratched ? "opacity-50" : ""}`}
          />
        </div>
      </div>

      {/* to display the notes dropdown */}
      {notesWithoutChoreoAndSpecial?.length > 0 && showNotes === item && (
        <div
          onClick={(e) => e.stopPropagation()}
          className="w-[250px] bg-[#282929] p-3 rounded-lg absolute top-0 right-0 font-normal shadow-lg z-[3] flex flex-col gap-3 scale"
        >
          <h3 className="text-[.9rem] text-center">Notes</h3>
          <div
            onClick={(e) => {
              e.stopPropagation();
              toggleNotes(item);
            }}
            className="w-4 h-4 flex justify-center items-center bg-white/60 rounded-full cursor-pointer absolute top-1 right-1"
          >
            <img
              alt="user"
              src="/images/icons8-close-50.png"
              className="w-[10px] h-[10px]"
            />
          </div>
          {notesWithoutChoreoAndSpecial?.map((note, index) => {
            return (
              <div
                key={index}
                className="flex flex-col gap-1 items-center text-[.85rem] py-1 px-2 rounded-lg hover:bg-white/20 bg-white/10"
              >
                <p className="w-full whitespace-nowrap truncate font-bold">
                  {capitalizeFirstLetter(note?.type)}
                </p>
                <p className="w-full">{capitalizeFirstLetter(note?.note)}</p>
              </div>
            );
          })}
        </div>
      )}

      {showDropdownRow === item && !category?.is_scratched && (
        <EntryDropdownCont
          showMoveEntry={showMoveEntry}
          loading={loading}
          toggleMoveEntry={toggleMoveEntry}
          categoryEntriesWithoutCurrent={categoryEntriesWithoutCurrent}
          rearrangeENtries={rearrangeENtries}
          item={item}
          category={category}
          currentEvent={currentEvent}
          user={user}
          toggleDeleteEntryMod={toggleDeleteEntryMod}
          allEntries={allEntries}
          entryCount={entryCount}
          rearrangeCategories={rearrangeCategories}
          sortedCategories={sortedCategories}
          setShowDropdownRow={setShowDropdownRow}
        />
      )}

      {/* delete entry modal */}
      {deleteEntryMod && (
        <div className="w-screen h-screen fixed top-0 left-0  px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start z-[999]">
          <div
            onClick={(e) => e.stopPropagation()}
            className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-start scale shadow-lg"
          >
            <p className="w-full text-[1rem] text-white pb-3 border-b border-[#c5c4c4]/50 text-center">
              Confirm Delete Entry:{" "}
              <span className="font-bold">{item?.entry_name}</span>?
            </p>

            <p className="text-white/70">
              NOTE: This is permanent. <br />
              Are you sure you want to delete this entry?
            </p>

            <div className="mt-4 w-full text-center">
              <button
                onClick={() => {
                  toggleDeleteEntryMod();
                  closeDropdown();
                }}
                className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  dispatch(
                    deleteEntry({
                      compId: user?.uid,
                      doc_id: item?.doc_id,
                      category_name: item?.category_name,
                      event_uid: item?.event_uid,
                      currentEvent,
                    })
                  )
                }
                className="text-[.9rem] px-8 py-2 rounded-md bg-red-400 hover:bg-red-400/60 text-[#1c1c1c] transition-all duration-300"
                disabled={deleteEntryPending}
              >
                {deleteEntryPending ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RoutineEntry;
