import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import eventsDataJson from "../../components/EventManagement/dummyData/eventsData.json";
import {
  addEventService,
  deleteEventService,
  getEventsService,
  editEventService,
  addToFavoritesService,
  removeFromFavoritesService,
  submitOverallPresetService,
  addOverallAwardService,
  submitGrandPresetService,
  addGrandAwardService,
  deletePresetService,
  editPresetService,
  submitCustomRequestService,
  submitAwardLabelService,
  deleteCustomRequestService,
  deleteAwardLabelService,
  editOverallAwardService,
  deleteOverallAwardService,
  editGrandAwardService,
  deleteGrandAwardService,
  updateJudgeSettingsService,
  toggleScoresVisibilityService,
  disableAccessService,
  enableAccessService,
  toggleStudioVisibilityService,
  saveGenreVisibilityService,
  saveLiveUpdateService,
  updateManualScoresService,
  toggleRankService,
  updateAwardNameService,
  updatePriceDataService,
  sendMusicReminderService,
} from "../../services/organizerService";

const initialState = {
  openDropboard: false,
  openSearch: false,
  searchResults: [],
  openNotifsBoard: false,
  addEvent: false,
  eventsData: [],
  eventAddedMod: false,
  addEventValidationError: true,
  editEvent: false,
  editID: null,
  eventEdittedMod: false,
  editEventValidationError: null,
  addedToFavorites: false,
  awardToEdit: null,

  getEventSuccess: null,
  getEventFail: null,
  getEventPending: false,
  addEventSuccess: null,
  addEventFail: null,
  addEventPending: false,
  deleteEventSuccess: null,
  deleteEventFail: null,
  deleteEventPending: false,
  editEventSuccess: null,
  editEventFail: null,
  editEventPending: false,
  addToFaveSuccess: null,
  addToFaveFail: null,
  addToFavePending: false,
  removeFromFaveSuccess: null,
  removeFromFaveFail: null,
  removeFromFavePending: false,

  submitOverallSuccess: null,
  submitOverallFail: null,
  submitOverallPending: false,

  submitOverallAwardSuccess: null,
  submitOverallAwardFail: null,
  submitOverallAwardPending: false,

  editOverallAwardSuccess: null,
  editOverallAwardFail: null,
  editOverallAwardPending: false,

  deleteOverallAwardSuccess: null,
  deleteOverallAwardFail: null,
  deleteOverallAwardPending: false,

  editGrandAwardSuccess: null,
  editGrandAwardFail: null,
  editGrandAwardPending: false,

  deleteGrandAwardSuccess: null,
  deleteGrandAwardFail: null,
  deleteGrandAwardPending: false,

  submitGrandSuccess: null,
  submitGrandFail: null,
  submitGrandPending: false,

  submitGrandAwardSuccess: null,
  submitGrandAwardFail: null,
  submitGrandAwardPending: false,

  deletePresetSuccess: null,
  deletePresetFail: null,
  deletePresetPending: false,

  editPresetSuccess: null,
  editPresetFail: null,
  editPresetPending: false,

  submitCustomSuccess: null,
  submitCustomFail: null,
  submitCustomPending: false,

  deleteCustomSuccess: null,
  deleteCustomFail: null,
  deleteCustomPending: false,

  submitLabelSuccess: null,
  submitLabelFail: null,
  submitLabelPending: false,

  deleteLabelSuccess: null,
  deleteLabelFail: null,
  deleteLabelPending: false,

  updateJudgeSettingsSuccess: null,
  updateJudgeSettingsFail: null,
  updateJudgeSettingsPending: false,

  updateAwardNameSuccess: null,
  updateAwardNameFail: null,
  updateAwardNamePending: false,

  updatePriceDataSuccess: null,
  updatePriceDataFail: null,
  updatePriceDataPending: false,

  enableAccessSuccess: null,
  enableAccessFail: null,
  enableAccessPending: false,

  disableAccessSuccess: null,
  disableAccessFail: null,
  disableAccessPending: false,

  updateNowSuccess: null,
  updateNowFail: null,
  updateNowPending: false,

  sendMusicReminderSuccess: null,
  sendMusicReminderFail: null,
  sendMusicReminderPending: false,

  regSearchResults: [],
  regData: [],
  currentEvent: null,

  notifications: [],
};

//===============================to handle get all events async func
export const getEvents = createAsyncThunk(
  "eventManagement/getEvents",
  async (uid) => {
    const result = await getEventsService(uid);
    return result;
  }
);

//===============================to handle add event async func
export const submitEvent = createAsyncThunk(
  "eventManagement/submitEvent",
  async (event) => {
    const result = await addEventService(event);
    return result;
  }
);

//===============================to handle delete event async func
export const deleteEvent = createAsyncThunk(
  "eventManagement/deleteEvent",
  async (name, uid) => {
    const result = await deleteEventService(name, uid);
    console.log(result);
    return result;
  }
);

//===============================to handle edit event async func
export const editEvent = createAsyncThunk(
  "eventManagement/editEvent",
  async (data) => {
    const result = await editEventService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add event to favorites async func
export const addToFavorites = createAsyncThunk(
  "eventManagement/addToFavorites",
  async (data) => {
    const result = await addToFavoritesService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle remove event from favorites async func
export const removeFromFavorites = createAsyncThunk(
  "eventManagement/removeFromFavorites",
  async (data) => {
    const result = await removeFromFavoritesService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add overalll preset async func
export const submitOverall = createAsyncThunk(
  "eventManagement/submitOverall",
  async (data) => {
    const result = await submitOverallPresetService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add overall award preset async func
export const submitOverallAward = createAsyncThunk(
  "eventManagement/submitOverallAward",
  async (data) => {
    const result = await addOverallAwardService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit overall award preset async func
export const editOverallAward = createAsyncThunk(
  "eventManagement/editOverallAward",
  async (data) => {
    const result = await editOverallAwardService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete overall award preset async func
export const deleteOverallAward = createAsyncThunk(
  "eventManagement/deleteOverallAward",
  async (data) => {
    const result = await deleteOverallAwardService(data);
    console.log(result);
    window.location.reload();
    return result;
  }
);

//===============================to handle add grand preset async func
export const submitGrand = createAsyncThunk(
  "eventManagement/submitGrand",
  async (data) => {
    const result = await submitGrandPresetService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle add grand award preset async func
export const submitGrandAward = createAsyncThunk(
  "eventManagement/submitGrandAward",
  async (data) => {
    const result = await addGrandAwardService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit overall award preset async func
export const editGrandAward = createAsyncThunk(
  "eventManagement/editGrandAward",
  async (data) => {
    const result = await editGrandAwardService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete overall award preset async func
export const deleteGrandAward = createAsyncThunk(
  "eventManagement/deleteGrandAward",
  async (data) => {
    const result = await deleteGrandAwardService(data);
    console.log(result);
    window.location.reload();
    return result;
  }
);

//===============================to handle delete preset async func
export const deletePreset = createAsyncThunk(
  "eventManagement/deletePreset",
  async (data) => {
    const result = await deletePresetService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle edit preset async func
export const editPreset = createAsyncThunk(
  "eventManagement/editPreset",
  async (data) => {
    const result = await editPresetService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle submit custom requests async func
export const submitCustom = createAsyncThunk(
  "eventManagement/submitCustom",
  async (data) => {
    const result = await submitCustomRequestService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete custom requests async func
export const deleteCustom = createAsyncThunk(
  "eventManagement/deleteCustom",
  async (data) => {
    const result = await deleteCustomRequestService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle submit award label async func
export const submitLabel = createAsyncThunk(
  "eventManagement/submitLabel",
  async (data) => {
    const result = await submitAwardLabelService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete award label async func
export const deleteLabel = createAsyncThunk(
  "eventManagement/deleteLabel",
  async (data) => {
    const result = await deleteAwardLabelService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete award label async func
export const updateJudgeSettings = createAsyncThunk(
  "eventManagement/updateJudgeSettings",
  async (data) => {
    const result = await updateJudgeSettingsService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle delete award label async func
export const updateAwardName = createAsyncThunk(
  "eventManagement/updateAwardName",
  async (data) => {
    const result = await updateAwardNameService(data);
    console.log(result);
    return result;
  }
);
//===============================to handle delete award label async func
export const updatePriceData = createAsyncThunk(
  "eventManagement/updatePriceData",
  async (data) => {
    const result = await updatePriceDataService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle toggle event scores visibility
export const toggleScoresVisibility = createAsyncThunk(
  "eventManagement/toggleScoresVisibility",
  async (data) => {
    const result = await toggleScoresVisibilityService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle toggle event scores visibility
export const toggleStudioVisibility = createAsyncThunk(
  "eventManagement/toggleStudioVisibility",
  async (data) => {
    const result = await toggleStudioVisibilityService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle toggle event scores visibility
export const toggleRank = createAsyncThunk(
  "eventManagement/toggleRank",
  async (data) => {
    const result = await toggleRankService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle toggle event genre visibility
export const saveGenreVisibility = createAsyncThunk(
  "eventManagement/saveGenreVisibility",
  async (data) => {
    const result = await saveGenreVisibilityService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle toggle live updates
export const saveLiveUpdate = createAsyncThunk(
  "eventManagement/saveLiveUpdate",
  async (data) => {
    const result = await saveLiveUpdateService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle toggle live updates
export const updateNow = createAsyncThunk(
  "eventManagement/updateNow",
  async (data) => {
    const result = await updateManualScoresService(data);
    console.log(result);
    return result;
  }
);

//===============================to handle enable access to event
export const enableAccess = createAsyncThunk(
  "eventManagement/enableAccess",
  async (data) => {
    const result = await enableAccessService(data);
    console.log(result);
    return result;
  }
);
//===============================to handle disable access to event
export const disableAccess = createAsyncThunk(
  "eventManagement/disableAccess",
  async (data) => {
    const result = await disableAccessService(data);
    console.log(result);
    return result;
  }
);
//===============================to handle send music upload reminder
export const sendMusicReminder = createAsyncThunk(
  "eventManagement/sendMusicReminder",
  async (data) => {
    const result = await sendMusicReminderService(data);
    console.log(result);
    return result;
  }
);

export const eventManagementSlice = createSlice({
  name: "eventManagement",
  initialState,
  reducers: {
    //to toggle open and close left sidebar on mobile
    toggleDropboard: (state) => {
      return { ...state, openDropboard: !state.openDropboard };
    },
    //to toggle close left sidebar on mobile
    closeDropboard: (state) => {
      return { ...state, openDropboard: false };
    },
    //to toggle open and close search field
    toggleSearch: (state) => {
      return { ...state, openSearch: !state.openSearch };
    },

    search: (state, action) => {
      const searchTerm = action.payload.toLowerCase();
      state.searchResults = state.eventsData?.filter((event) =>
        event.event_name.toLowerCase().includes(searchTerm)
      );
    },

    setRegData: (state) => {
      return { ...state, regData: false };
    },

    searchRegistrations: (state, action) => {
      const searchTerm = action.payload.toLowerCase();
      state.regSearchResults = state.regData?.filter((event) =>
        event.event_name.toLowerCase().includes(searchTerm)
      );
    },

    //to set current event
    setCurrentEvent: (state, action) => {
      return { ...state, currentEvent: action.payload };
    },
    //to toggle open and close notifs board on mobile and small screens
    toggleNotifsBoard: (state) => {
      return { ...state, openNotifsBoard: !state.openNotifsBoard };
    },
    closeNotifsBoard: (state) => {
      return { ...state, openNotifsBoard: false };
    },
    //to toggle off event added modal
    toggleOffEventAdded: (state) => {
      return { ...state, eventAddedMod: false };
    },
    //to toggle open and close add event pop up
    toggleAddEvent: (state) => {
      return {
        ...state,
        addEvent: !state.addEvent,
        addEventValidationError: null,
      };
    },
    //toclose add event pop up
    closeAddEvent: (state) => {
      return {
        ...state,
        addEvent: false,
        viewDeleted: false,
        addEntry: false,
        addCategory: false,
      };
    },
    //to toggle open and close edit event pop up
    toggleEditEvent: (state, action) => {
      return {
        ...state,
        editEvent: !state.editEvent,
        editID: action.payload,
        addEventValidationError: null,
      };
    },
    //to toggle off event added modal
    toggleOffEventEditted: (state) => {
      return { ...state, eventEdittedMod: false };
    },

    setAwardToEdit: (state, action) => {
      return { ...state, awardToEdit: action.payload };
    },

    setNotifications: (state, action) => {
      return { ...state, notifications: action.payload };
    },

    clearSuccess: (state) => {
      return {
        ...state,
        submitOverallSuccess: null,
        submitOverallAwardSuccess: null,
        submitGrandSuccess: null,
        submitGrandAwardSuccess: null,
        deletePresetSuccess: null,
        editPresetSuccess: null,
        submitCustomSuccess: null,
        submitLabelSuccess: null,
        deleteCustomSuccess: null,
        deleteLabelSuccess: null,
        editOverallAwardSuccess: null,
        deleteOverallAwardSuccess: null,
        editGrandAwardSuccess: null,
        deleteGrandAwardSuccess: null,
        updateJudgeSettingsSuccess: null,
        updateNowSuccess: null,
        updateAwardNameSuccess: null,
        updatePriceDataSuccess: null,
        sendMusicReminderSuccess: null,
      };
    },
  },
  extraReducers: (builder) => {
    //to handle cases for all async functions
    builder
      //cases for getting all event
      .addCase(getEvents.pending, (state) => {
        state.eventsData = []; // clear eventsData state when fetching starts
        state.getEventPending = true;
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        state.eventsData = action.payload;
        state.getEventPending = false;
      })
      .addCase(getEvents.rejected, (state, action) => {
        state.eventsData = [];
        state.getEventPending = false;
      })
      //cases for adding event
      .addCase(submitEvent.pending, (state) => {
        state.addEventPending = true;
      })
      .addCase(submitEvent.fulfilled, (state, action) => {
        state.addEventSuccess = action.payload;
        state.addEventPending = false;
      })
      .addCase(submitEvent.rejected, (state, action) => {
        state.addEventFail = action.payload;
        state.addEventPending = false;
      })
      //cases for deleting event
      .addCase(deleteEvent.pending, (state) => {
        state.deleteEventPending = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.deleteEventSuccess = action.payload;
        state.deleteEventPending = false;
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.deleteEventFail = action.payload;
        state.deleteEventPending = false;
      })
      //cases for editing event
      .addCase(editEvent.pending, (state) => {
        state.editEventPending = true;
      })
      .addCase(editEvent.fulfilled, (state, action) => {
        state.editEventSuccess = action.payload;
        state.editEventPending = false;
      })
      .addCase(editEvent.rejected, (state, action) => {
        state.editEventFail = action.payload;
        state.editEventPending = false;
      })
      //cases for add to favorites
      .addCase(addToFavorites.pending, (state) => {
        state.addToFavePending = true;
      })
      .addCase(addToFavorites.fulfilled, (state, action) => {
        state.addToFaveSuccess = action.payload;
        state.addToFavePending = false;
      })
      .addCase(addToFavorites.rejected, (state, action) => {
        state.addToFaveFail = action.payload;
        state.addToFavePending = false;
      })
      //cases for remove from favorites
      .addCase(removeFromFavorites.pending, (state) => {
        state.removeFromFavePending = true;
      })
      .addCase(removeFromFavorites.fulfilled, (state, action) => {
        state.removeFromFaveSuccess = action.payload;
        state.removeFromFavePending = false;
      })
      .addCase(removeFromFavorites.rejected, (state, action) => {
        state.removeFromFaveFail = action.payload;
        state.removeFromFavePending = false;
      })
      .addCase(submitOverall.pending, (state) => {
        state.submitOverallPending = true;
      })
      .addCase(submitOverall.fulfilled, (state, action) => {
        state.submitOverallSuccess = action.payload;
        state.submitOverallPending = false;
      })
      .addCase(submitOverall.rejected, (state, action) => {
        state.submitOverallFail = action.payload;
        state.submitOverallPending = false;
      })
      .addCase(submitOverallAward.pending, (state) => {
        state.submitOverallAwardPending = true;
      })
      .addCase(submitOverallAward.fulfilled, (state, action) => {
        state.submitOverallAwardSuccess = action.payload;
        state.submitOverallAwardPending = false;
      })
      .addCase(submitOverallAward.rejected, (state, action) => {
        state.submitOverallAwardFail = action.payload;
        state.submitOverallAwardPending = false;
      })
      .addCase(submitGrand.pending, (state) => {
        state.submitGrandPending = true;
      })
      .addCase(submitGrand.fulfilled, (state, action) => {
        state.submitGrandSuccess = action.payload;
        state.submitGrandPending = false;
      })
      .addCase(submitGrand.rejected, (state, action) => {
        state.submitGrandFail = action.payload;
        state.submitGrandPending = false;
      })
      .addCase(submitGrandAward.pending, (state) => {
        state.submitGrandAwardPending = true;
      })
      .addCase(submitGrandAward.fulfilled, (state, action) => {
        state.submitGrandAwardSuccess = action.payload;
        state.submitGrandAwardPending = false;
      })
      .addCase(submitGrandAward.rejected, (state, action) => {
        state.submitGrandAwardFail = action.payload;
        state.submitGrandAwardPending = false;
      })
      .addCase(deletePreset.pending, (state) => {
        state.deletePresetPending = true;
      })
      .addCase(deletePreset.fulfilled, (state, action) => {
        state.deletePresetSuccess = action.payload;
        state.deletePresetPending = false;
      })
      .addCase(deletePreset.rejected, (state, action) => {
        state.deletePresetFail = action.payload;
        state.deletePresetPending = false;
      })
      .addCase(editPreset.pending, (state) => {
        state.editPresetPending = true;
      })
      .addCase(editPreset.fulfilled, (state, action) => {
        state.editPresetSuccess = action.payload;
        state.editPresetPending = false;
      })
      .addCase(editPreset.rejected, (state, action) => {
        state.editPresetFail = action.payload;
        state.editPresetPending = false;
      })
      .addCase(submitCustom.pending, (state) => {
        state.submitCustomPending = true;
      })
      .addCase(submitCustom.fulfilled, (state, action) => {
        state.submitCustomSuccess = action.payload;
        state.submitCustomPending = false;
      })
      .addCase(submitCustom.rejected, (state, action) => {
        state.submitCustomFail = action.payload;
        state.submitCustomPending = false;
      })
      .addCase(submitLabel.pending, (state) => {
        state.submitLabelPending = true;
      })
      .addCase(submitLabel.fulfilled, (state, action) => {
        state.submitLabelSuccess = action.payload;
        state.submitLabelPending = false;
      })
      .addCase(submitLabel.rejected, (state, action) => {
        state.submitLabelFail = action.payload;
        state.submitLabelPending = false;
      })
      .addCase(deleteCustom.pending, (state) => {
        state.deleteCustomPending = true;
      })
      .addCase(deleteCustom.fulfilled, (state, action) => {
        state.deleteCustomSuccess = action.payload;
        state.deleteCustomPending = false;
      })
      .addCase(deleteCustom.rejected, (state, action) => {
        state.deleteCustomFail = action.payload;
        state.deleteCustomPending = false;
      })
      .addCase(deleteLabel.pending, (state) => {
        state.deleteLabelPending = true;
      })
      .addCase(deleteLabel.fulfilled, (state, action) => {
        state.deleteLabelSuccess = action.payload;
        state.deleteLabelPending = false;
      })
      .addCase(deleteLabel.rejected, (state, action) => {
        state.deleteLabelFail = action.payload;
        state.deleteLabelPending = false;
      })
      .addCase(editOverallAward.pending, (state) => {
        state.editOverallAwardPending = true;
      })
      .addCase(editOverallAward.fulfilled, (state, action) => {
        state.editOverallAwardSuccess = action.payload;
        state.editOverallAwardPending = false;
      })
      .addCase(editOverallAward.rejected, (state, action) => {
        state.editOverallAwardFail = action.payload;
        state.editOverallAwardPending = false;
      })
      .addCase(deleteOverallAward.pending, (state) => {
        state.deleteOverallAwardPending = true;
      })
      .addCase(deleteOverallAward.fulfilled, (state, action) => {
        state.deleteOverallAwardSuccess = action.payload;
        state.deleteOverallAwardPending = false;
      })
      .addCase(deleteOverallAward.rejected, (state, action) => {
        state.deleteOverallAwardFail = action.payload;
        state.deleteOverallAwardPending = false;
      })

      .addCase(editGrandAward.pending, (state) => {
        state.editGrandAwardPending = true;
      })
      .addCase(editGrandAward.fulfilled, (state, action) => {
        state.editGrandAwardSuccess = action.payload;
        state.editGrandAwardPending = false;
      })
      .addCase(editGrandAward.rejected, (state, action) => {
        state.editGrandAwardFail = action.payload;
        state.editGrandAwardPending = false;
      })
      .addCase(deleteGrandAward.pending, (state) => {
        state.deleteGrandAwardPending = true;
      })
      .addCase(deleteGrandAward.fulfilled, (state, action) => {
        state.deleteGrandAwardSuccess = action.payload;
        state.deleteGrandAwardPending = false;
      })
      .addCase(deleteGrandAward.rejected, (state, action) => {
        state.deleteGrandAwardFail = action.payload;
        state.deleteGrandAwardPending = false;
      })
      .addCase(updateJudgeSettings.pending, (state) => {
        state.updateJudgeSettingsPending = true;
      })
      .addCase(updateJudgeSettings.fulfilled, (state, action) => {
        state.updateJudgeSettingsSuccess = action.payload;
        state.updateJudgeSettingsPending = false;
      })
      .addCase(updateJudgeSettings.rejected, (state, action) => {
        state.updateJudgeSettingsFail = action.payload;
        state.updateJudgeSettingsPending = false;
      })
      .addCase(updateAwardName.pending, (state) => {
        state.updateAwardNamePending = true;
      })
      .addCase(updateAwardName.fulfilled, (state, action) => {
        state.updateAwardNameSuccess = action.payload;
        state.updateAwardNamePending = false;
      })
      .addCase(updateAwardName.rejected, (state, action) => {
        state.updateAwardNameFail = action.payload;
        state.updateAwardNamePending = false;
      })
      .addCase(updatePriceData.pending, (state) => {
        state.updatePriceDataPending = true;
      })
      .addCase(updatePriceData.fulfilled, (state, action) => {
        state.updatePriceDataSuccess = action.payload;
        state.updatePriceDataPending = false;
      })
      .addCase(updatePriceData.rejected, (state, action) => {
        state.updatePriceDataFail = action.payload;
        state.updatePriceDataPending = false;
      })
      .addCase(enableAccess.pending, (state) => {
        state.enableAccessPending = true;
      })
      .addCase(enableAccess.fulfilled, (state, action) => {
        state.enableAccessSuccess = action.payload;
        state.enableAccessPending = false;
      })
      .addCase(enableAccess.rejected, (state, action) => {
        state.enableAccessFail = action.payload;
        state.enableAccessPending = false;
      })
      .addCase(disableAccess.pending, (state) => {
        state.disableAccessPending = true;
      })
      .addCase(disableAccess.fulfilled, (state, action) => {
        state.disableAccessSuccess = action.payload;
        state.disableAccessPending = false;
      })
      .addCase(disableAccess.rejected, (state, action) => {
        state.disableAccessFail = action.payload;
        state.disableAccessPending = false;
      })
      .addCase(updateNow.pending, (state) => {
        state.updateNowPending = true;
      })
      .addCase(updateNow.fulfilled, (state, action) => {
        state.updateNowSuccess = action.payload;
        state.updateNowPending = false;
      })
      .addCase(updateNow.rejected, (state, action) => {
        state.updateNowFail = action.payload;
        state.updateNowPending = false;
      })
      .addCase(sendMusicReminder.pending, (state) => {
        state.sendMusicReminderPending = true;
      })
      .addCase(sendMusicReminder.fulfilled, (state, action) => {
        state.sendMusicReminderSuccess = action.payload;
        state.sendMusicReminderPending = false;
      })
      .addCase(sendMusicReminder.rejected, (state, action) => {
        state.sendMusicReminderFail = action.payload;
        state.sendMusicReminderPending = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleDropboard,
  closeDropboard,
  toggleSearch,
  toggleNotifsBoard,
  toggleAddEvent,
  toggleOffEventAdded,
  toggleEditEvent,
  toggleOffEventEditted,
  addToFave,
  removeFromFave,
  closeNotifsBoard,
  search,
  closeAddEvent,
  setRegData,
  searchRegistrations,
  setCurrentEvent,
  clearSuccess,
  setAwardToEdit,
  setNotifications,
} = eventManagementSlice.actions;

export default eventManagementSlice.reducer;
