/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useQueryAllSubCollectionDocs } from "components/hooks/useQueryAllSubCollectionDocs";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearSuccess } from "redux/danceStudio/danceStudioSlice";
import {
  deleteGrandAward,
  setAwardToEdit,
} from "redux/eventManagement/eventManagement";
import { toggleEditGrandAwards } from "redux/organizerScheduling/organizerSchedulingSlice";
import { capitalizeFirstLetter } from "utils/capitalizeFirstLetter";
// import GrandData from "../dummyData/GrandData.json";
import { PulseLoader } from "react-spinners";
import { useQueryAllDocs } from "components/hooks/useQueryAllDocs";
// OverallAwardsTable component

const OverallAwardsTable = ({
  setSelections,
  selections,
  setValidationErr,
  setHoveredRowIndex,
  hoveredRowIndex,
  searchQuery,
  sortDown,
  currentEvent,
}) => {
  const { user } = useSelector((state) => state.persistedReducer.auth.user);
  const { deleteGrandAwardPending, deleteGrandAwardSuccess } = useSelector(
    (state) => state.eventManagement
  );

  const { data: comps } = useQueryAllDocs("competitions");
  const currentComp = comps?.filter((item) => item?.compId === user?.uid)[0];

  const { data: allEntriesRaw } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "entries"
  );
  const allEntries = allEntriesRaw?.filter(
    (x) => x?.event_uid === currentEvent?.event_uid
  );

  const { status, data } = useQueryAllSubCollectionDocs(
    "competitions",
    user?.uid,
    "reports"
  );

  const overallAwards = data?.filter((item) => item?.id === "grand_preset")[0]
    ?.awards;

  const [allGrandData, setAllGrandData] = useState([]);

  useEffect(() => {
    if (status === "success" && overallAwards) {
      // setAllGrandData([...GrandData, ...overallAwards]);
      setAllGrandData([...overallAwards]);
    }
  }, [status, overallAwards]);

  const filteredData = allGrandData.filter((row) =>
    row.award_name.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  const [pageNumber, setPageNumber] = useState(0);
  const [selectAll, setSelectAll] = useState(false);

  const rowPerPage = 10;
  const pagesVisited = pageNumber * rowPerPage;

  const handleCheckboxChange = (event, row) => {
    setValidationErr("");
    if (event.target.checked) {
      setSelections((prevSelections) => [...prevSelections, row]);
    } else {
      setSelections((prevSelections) =>
        prevSelections.filter((name) => name?.award_name !== row?.award_name)
      );
    }
  };

  const handleSelectAllChange = (event) => {
    setValidationErr("");
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelections(allGrandData);
    } else {
      setSelections([]);
    }
  };

  const [showNewRow, setShowNewRow] = useState(false);
  // const [dancerIndex, setdancerIndex] = useState(false);
  const hideRowTimeoutRef = useRef(null);

  const dispatch = useDispatch();

  const handleRowMouseEnter = (index, item) => {
    if (item?.award_uid) {
      setTimeout(() => {
        setHoveredRowIndex(index);
        setShowNewRow(true);
        // setdancerIndex(index);
      }, 200);
      clearTimeout(hideRowTimeoutRef.current);
    }
  };

  const handleRowMouseLeave = (event) => {
    const isLeavingOriginalRow =
      event.relatedTarget?.parentNode?.parentNode?.nodeName !== "tr";
    const isLeavingNewRow = event.relatedTarget?.parentNode?.nodeName !== "td";

    hideRowTimeoutRef.current = setTimeout(() => {
      if (isLeavingOriginalRow || isLeavingNewRow) {
        setHoveredRowIndex(null);
        setShowNewRow(false);
      }
    }, 800); // Set a 500ms delay before hiding the row
  };

  const [deleteMod, setdeleteMod] = useState(null);
  function openDelete(row) {
    setdeleteMod(row);
  }
  function closeDelete() {
    setdeleteMod(null);
  }

  useEffect(() => {
    if (deleteGrandAwardSuccess?.success) {
      dispatch(clearSuccess());
      closeDelete();
    }
  }, [deleteGrandAwardSuccess]);

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    let arranged = [...filteredData];

    arranged?.sort((a, b) => {
      if (sortDown === "class") {
        const classA = String(a.classification).toUpperCase();
        const classB = String(b.classification).toUpperCase();

        return classA < classB ? -1 : classA > classB ? 1 : 0;
      } else if (sortDown === "entry_type") {
        const entryTypeA = String(a.entryType).toUpperCase();
        const entryTypeB = String(b.entryType).toUpperCase();

        return entryTypeA < entryTypeB ? -1 : entryTypeA > entryTypeB ? 1 : 0;
      } else if (sortDown === "genre") {
        const genreA = String(a.genre).toUpperCase();
        const genreB = String(b.genre).toUpperCase();

        return genreA < genreB ? -1 : genreA > genreB ? 1 : 0;
      } else if (sortDown === "award") {
        const awardA = String(a.award_name).toUpperCase();
        const awardB = String(b.award_name).toUpperCase();

        return awardA < awardB ? -1 : awardA > awardB ? 1 : 0;
      } else if (sortDown === "studio_tag") {
        const studioSelectedA = a.studio_selected.join(",").toUpperCase();
        const studioSelectedB = b.studio_selected.join(",").toUpperCase();
        return studioSelectedA < studioSelectedB
          ? -1
          : studioSelectedA > studioSelectedB
          ? 1
          : 0;
      }
      return 0;
    });
    setSortedData([...arranged]);
  }, [sortDown, allGrandData, searchQuery]);

  const displayRows = sortedData
    ?.slice(pagesVisited, pagesVisited + rowPerPage)
    ?.map((row, index) => {
      const {
        award_name,
        classification,
        entryType,
        genre,
        min_age,
        max_age,
        awardsNumber,
        special_tags,
        studio_selected,
      } = row;

      const eligibileEntriesRaw = allEntries?.filter((itm) => {
        const matchingClass = classification?.includes("ALL")
          ? itm?.classification
          : classification?.includes(itm?.classification);
        const matchingEntryType = entryType?.includes("ALL")
          ? itm?.entryType
          : entryType?.includes(itm?.entryType);
        const matchingGenre = genre?.includes("ALL")
          ? itm?.genre
          : genre?.includes(itm?.genre);

        return matchingClass && matchingEntryType && matchingGenre;
      });

      const ageDiv = currentComp?.eligibilityDivisions?.filter(
        (x) =>
          Number(x?.maxAge) <= Number(max_age) &&
          Number(x?.minAge) >= Number(min_age)
      );

      const myAgeDivisions = ageDiv?.map((x) => x?.divisionName?.toLowerCase());

      //================================================to filter by min and max age
      function filterEntriesByAge() {
        return eligibileEntriesRaw?.filter((entry) =>
          myAgeDivisions?.includes(entry?.age_division)
        );
      }

      const specialEntriesScored = allEntries
        ?.map((x) => {
          const specialArrLength = currentComp?.specialEntryTypes?.tags?.filter(
            (y) => y?.specialEntryTypeName === x?.entryType
          )?.length;
          return specialArrLength > 0 ? x : null;
        })
        ?.filter((z) => z !== null && z?.scored);

      const eligibleEntries =
        filterEntriesByAge()?.concat(specialEntriesScored);

      //==================================================to filter by special tags
      function filterEntriesBySpecialTag() {
        return eligibleEntries
          ?.map((entry) => {
            if (row?.special_tags?.length === 0) {
              return entry;
            } else {
              if (Array?.isArray(entry?.special_tags)) {
                const entryHasTag = entry?.special_tags?.filter((x) =>
                  row?.special_tags?.includes(x)
                );

                return entryHasTag?.length > 0 ? entry : null;
              }
            }
          })
          ?.filter((y) => y);
      }

      const eligibleTagsEntries = filterEntriesBySpecialTag();

      //================================================to filter by studio
      function filterByStudio() {
        return eligibleTagsEntries
          ?.map((entry) => {
            if (row?.studio_selected?.includes("ALL")) {
              return entry;
            } else {
              if (Array?.isArray(entry?.studio_selected)) {
                const entryHasStudio = entry?.studio_selected?.filter((x) =>
                  row?.studio_selected?.includes(x)
                );

                return entryHasStudio?.length > 0 ? entry : null;
              }
            }
          })
          ?.filter((y) => y);
      }

      const finalEntries = filterByStudio();

      return (
        <React.Fragment key={index}>
          <tr
            onClick={() => handleRowMouseEnter(index, row)}
            className={`border-b border-white/10 whitespace-nowrap ${
              row?.award_uid && "cursor-pointer hover:bg-[#94a4fd]/30"
            }`}
          >
            <td>
              <input
                type="checkbox"
                className="mr-2 cursor-pointer"
                checked={selectAll || selections?.includes(row)}
                onChange={(event) => handleCheckboxChange(event, row)}
              />
            </td>
            <td className="">{capitalizeFirstLetter(award_name)}</td>
            <td>
              {classification?.length > 1
                ? "Multiple"
                : capitalizeFirstLetter(classification[0])}
            </td>
            <td>
              {entryType?.length > 1
                ? "Multiple"
                : capitalizeFirstLetter(entryType[0])}
            </td>
            <td>{genre?.length > 1 ? "Multiple" : genre}</td>
            <td>{min_age}</td>
            <td>{max_age}</td>
            <td>{awardsNumber}</td>
            <td
              className={`${
                finalEntries?.length >= 3 ? "text-green-400" : "text-white"
              }`}
            >
              {finalEntries?.length}
            </td>
            <td>
              <p className="whitespace-nowrap truncate">
                {special_tags?.length === 0 ? "N/A" : special_tags}
              </p>
            </td>
            <td>
              <p className="whitespace-nowrap truncate">
                {studio_selected?.map((x, ind) => {
                  return `${x}${
                    ind !== studio_selected?.length - 1 ? "," : ""
                  }`;
                })}
              </p>
            </td>
          </tr>
          {showNewRow && hoveredRowIndex === index && (
            <tr
              colSpan="5"
              className={`new-row w-fit block border-x border-b border-white/20 bg-[#1c1c1c] z-10 rounded-b-lg slideDD absolute`}
              onMouseLeave={handleRowMouseLeave}
              onMouseOver={() => handleRowMouseEnter(index, row)}
            >
              <td
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setAwardToEdit(row));
                  dispatch(toggleEditGrandAwards());
                }}
                className="text-[#94a4fd] whitespace-nowrap cursor-pointer hover:bg-[#94a4fd]/20 rounded-lg"
              >
                <p className="pl-4">Edit Award</p>
              </td>
              <td
                onClick={() => {
                  openDelete(row);
                }}
                className="text-red-400 whitespace-nowrap cursor-pointer hover:bg-[#94a4fd]/20 rounded-lg"
              >
                Delete Award
              </td>
            </tr>
          )}
        </React.Fragment>
      );
    });

  const pageCount = Math.ceil(filteredData.length / rowPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <>
      <div className="w-full h-full mt-5 pb-2 overflow-auto transition-all duration-700">
        {filteredData.length > 0 && (
          <table className="min-w-[600px] w-full text-[.85rem] table2">
            <thead className="text-white/40 text-left border-b border-white/30">
              <tr className="whitespace-nowrap">
                <th>
                  <input
                    type="checkbox"
                    className="mr-2 cursor-pointer"
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                </th>
                <th>Award Name</th>
                <th>Classification</th>
                <th>Entry Type</th>
                <th>Genre</th>
                <th>Min. Age</th>
                <th>Max. Age</th>
                <th># Awards</th>
                <th># Eligible</th>
                <th>Special Tags</th>
                <th>Studios</th>
              </tr>
            </thead>
            {sortedData?.length > 8 ? (
              <tbody className="text-white/80">{displayRows}</tbody>
            ) : (
              <tbody className="text-white/80">{displayRows}</tbody>
            )}
          </table>
        )}

        {filteredData.length === 0 && allGrandData?.length > 0 && (
          <div className="w-full min-h-[200px] flex justify-center items-center text-[.85rem] text-white/60 mt-4 border border-white/10 rounded-xl">
            No matching results found.
          </div>
        )}

        {allGrandData?.length === 0 && (
          <div className="w-full min-h-[200px] flex justify-center items-center text-[.85rem] text-white/60 mt-4 border border-white/10 rounded-xl">
            No awards yet..
          </div>
        )}

        {filteredData.length > 0 && (
          <div className="text-[.75rem] text-white/40 mt-4 lg:">
            Scroll horizontally to view more, if needed
          </div>
        )}
      </div>

      {filteredData.length > 10 && (
        <div className="flex gap-2 mt-4 justify-end">
          <ReactPaginate
            previousLabel={
              <img
                alt="user"
                src="/images/leftArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            nextLabel={
              <img
                alt="user"
                src="/images/rightArrow.png"
                className="w-6 h-6 cursor-pointer rounded-lg hover:bg-white/10"
              />
            }
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={
              "h-6 flex items-center gap-2 mt-4 justify-end text-[.95rem] pag"
            }
            activeClassName={"rounded-lg bg-white/10"}
          />
        </div>
      )}

      {deleteMod && (
        <div className="w-screen h-screen fixed top-0 left-0 z-40 px-4 pt-[100px] bg-[#1c1c1c]/90 flex justify-center items-start">
          <div className="w-full sm:w-[550px] min-h-fit p-4 md:pb-6 bg-[#282929] rounded-lg flex flex-col gap-5 justify-start items-center scale shadow-lg">
            {deleteGrandAwardPending ? (
              <div className="w-full h-[100px] flex justify-center items-center">
                <PulseLoader color="#94a4fd" size={10} />{" "}
              </div>
            ) : (
              <>
                <p className="w-full text-[1rem] text-white text-center pb-3 border-b border-red-400">
                  Confirm Delete Award -{" "}
                  {capitalizeFirstLetter(deleteMod?.award_name)}?
                </p>

                <div className="mt-4">
                  <button
                    onClick={closeDelete}
                    className="text-[.85rem] px-8 py-2 mr-4 rounded-md bg-[#c5c4c4] hover:bg-[#c5c4c4]/60 text-[#1c1c1c] transition-all duration-300"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() =>
                      dispatch(
                        deleteGrandAward({
                          compId: user?.uid,
                          award_uid: deleteMod?.award_uid,
                        })
                      )
                    }
                    className="text-[.9rem] px-8 py-2 rounded-md bg-red-400 hover:bg-red-400/60 text-white transition-all duration-300"
                  >
                    Delete
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OverallAwardsTable;
